import React, { useContext, useEffect, useState } from 'react';

import { Circle, Group, Label, Line, Tag } from 'react-konva';
import { CanvasText, NumericInputContext, CanvasDataContext } from 'canvas';

import lib from 'lib';
import _ from 'lodash';
import PositionHelper from 'helpers/position-helper';
import K from 'k';

const MeasuringTapeCanvasObject = (props) => {
  const {canvasData, hideMeasuringTape, singleClickCapture, ignoreSnaps, ...rest} = props;
  const {stage, scale} = canvasData;
  const {orthoMode} = canvasData;
  const [candidateSnapPositions, setCandidateSnapPositions] = useState();
  const [lastMousePosition, setLastMousePosition] = useState();
  const [from, setFrom] = useState();
  const isFromValid = !_.isEmpty(from);

  useEffect(() => {
    setCandidateSnapPositions(canvasData.getSnapData().candidateSnapPositions);
  }, []);

  const getRealPosition = () => {
    const snappedMousePosition = lastMousePosition;
    const pointPosition = canvasData.infinitePrecision ? PositionHelper.toReal(snappedMousePosition, canvasData) :  _.mapValues(PositionHelper.toReal(snappedMousePosition, canvasData), value => lib.round(value, {toNearest: K.minPrecision}));
    const snappedDelta = {x: 0, y: 0};
    const lastPosition = from || _.clone(pointPosition);
    const {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: ignoreSnaps ? [] : candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode: (orthoMode && from)}, canvasData);
    const snappedPointDelta = lib.object.difference(snappedPosition, _.clone(pointPosition));

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    return lib.object.sum(pointPosition, snappedDelta);
  }

  const handleClick = () => {
    if (!lastMousePosition) return;

    const realPosition = getRealPosition();

    if (isFromValid) {
      hideMeasuringTape({from, to: realPosition});
      // if (props.numericInputData) props.numericInputData.toggleNumericInputVisibility(false);
    }
    else if (singleClickCapture) {
      hideMeasuringTape({from: realPosition, to: realPosition});
    }
    else {
      setFrom(realPosition);
    }
  };

  const handleMouseMove = () => {
    var {getLastMousePosition} = canvasData;

    setLastMousePosition(getLastMousePosition());
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    if (stage) {
      stage.container().style.cursor = 'none';
      stage.on('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('click', handleClick);

      if (stage) {
        stage.container().style.cursor = 'default';
        stage.off('mousemove', handleMouseMove);
      }
    };
  });

  if (!lastMousePosition) return null;

  let snappedMousePosition = PositionHelper.toCanvas(getRealPosition(), canvasData);

  const dotPositionInCanvas = lib.object.sum(snappedMousePosition, {x: 0.5, y: 0.5});
  const positionInCanvas = lastMousePosition;
  const linePositions = [positionInCanvas.x, positionInCanvas.y, positionInCanvas.x, positionInCanvas.y];

  const linePositionTransformations = {
    top: [0, -20, 0, -5],
    bottom: [0, 5, 0, 20],
    left: [-20, 0, -5, 0],
    right: [5, 0, 20, 0],
  };

  var distance = isFromValid ? lib.math.trig.distance({
    fromPoint: from,
    toPoint: PositionHelper.toReal(snappedMousePosition, canvasData),
  }) : 0;

  if (!canvasData.infinitePrecision) distance = lib.number.round(distance, {toNearest: K.minPrecision});

  const fromInCanvas = isFromValid && PositionHelper.toCanvas(from, canvasData);

  return (
    <Group listening={false} opacity={0.75} {...rest}>
      {isFromValid && (
        <>
          <Line key='measuring-tape-line' stroke='black' strokeWidth={1} points={[fromInCanvas.x, fromInCanvas.y, dotPositionInCanvas.x, dotPositionInCanvas.y]} />
          <Label {...lib.object.sum(dotPositionInCanvas, {x: 0, y: -5 * scale})}>
            <Tag stroke='black' strokeWidth={1} fill={'rgba(255, 255, 255, 0.5)'}/>
            <CanvasText
              x={0}
              y={0}
              text={lib.number.toFraction(Math.abs(lib.number.round(distance, {toNearest: 1/16})), {normalscript: true})}
              align='center'
              alignVertical='center'
              fontSize={scale * 4}
            />
          </Label>
        </>
      )}
      {/* TODO use CanvasCursor */}
      {_.map(_.values(linePositionTransformations), (transform, key) => (
        <Line key={`measuring-tape-cursor-line-${key}`} stroke={'black'} strokeWidth={1} points={_.map(linePositions, (value, index) => value + transform[index])} />
      ))}
      <Circle x={dotPositionInCanvas.x - 0.5} y={dotPositionInCanvas.y - 0.5} radius={1} fill={'red'} />
    </Group>
  );
};

export default function MeasuringTapeCanvasObjectWithContext(props) {
  let canvasData = useContext(CanvasDataContext);
  let numericInputData = useContext(NumericInputContext);

  return <MeasuringTapeCanvasObject {...props} {...{canvasData, numericInputData}} />;
}
