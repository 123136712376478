import lib from 'lib';
import _ from 'lodash';
import cleanOldDimensionsData from 'helpers/clean-old-dimensions-data';
import K from 'k';

var loadProjectData = async ({resourceActionDispatchers, match, isDrawings=false}) => {
  var {projectId, versionId} = match.params;

  versionId = parseInt(versionId);

  //HINT split request into 2 to avoid 200 error due to large response
  var [
    projectSpecificResourceResponse,
    projectResourceResponse
  ] = await Promise.all([
    lib.api.request({uri: 'de-project/get-project-part-1', body: {projectId, versionId, includeMaterialPullMedia: true}, useActualErrorMessages: true}),
    lib.api.request({uri: 'de-project/get-project-part-2', body: {projectId, versionId, includeMaterialPullMedia: true}, useActualErrorMessages: true})
  ]);

  var projectDataResponse = {
    ...projectSpecificResourceResponse.data,
    ...projectResourceResponse.data
  };

  // var projectDataResponse = (await lib.api.request({uri: 'de-project/get-project', body: {projectId, versionId, includeMaterialPullMedia: true}, useActualErrorMessages: true})).data;

  var {
    productCategories,
    models,
    materialClasses,
    materialTypes,
    materials,
    appliances,
    archetypes,
    pricingRules,
    products: productTypes,
    productOptions: productOptionTypes,
    pulls,
    containers: containerTypes,
    partInstances: parts,
    materialPresets,
    project,
    projectVersion,
    projectVersions,
    floors,
    rooms,
    volumes,
    elevations,
    walls,
    archElementInstances: archElements,
    scopes,
    containerInstances: containers,
    productInstances: products,
    productOptionInstances,
    projectGraphics,
    isEmployee,
    userType,
    userId,
    userEmail,
    userName
  } = projectDataResponse;

  _.forEach(products, productInstance => {
    if (!productInstance.customData) productInstance.customData = {};
  });

  var {discount, discounts, dimensionsData, continuousElevationAutogenerationDisabled, drawingsData, title: versionTitle, priceElementConstraintType, infinitePrecision, locked, lockedForProduction
  } = projectVersion;

  _.forEach(materialClasses, materialClass => {
    materialClass.materials = _.filter(materials, material => _.includes(material.materialClassIds, `.${materialClass.id}.`));
  });

  project.pricingDate = project.effectivePricingDate || project.created;
  //HINT discount coming from version
  if (project) {
    project.discount = project.discount || discount;
    project.discounts = project.discounts || discounts;
    project.continuousElevationAutogenerationDisabled = continuousElevationAutogenerationDisabled;
    project.drawingsData = drawingsData || {};
    project.priceElementConstraintType = priceElementConstraintType;
    project.wasLockedPreProductOptionGraphicUpdate = _.get(projectVersion, 'customData.wasLockedPreProductOptionGraphicUpdate');
  }

  project = {...project, versionId, projectVersions, isEmployee, userName, userId, userType, userEmail, versionTitle, locked, infinitePrecision, lockedForProduction};

  project.dimensionsData = cleanOldDimensionsData({dimensionsData, elevations, rooms});

  //HINT filter out PT rooms
  rooms = _.filter(rooms, room => {
    return room.floorId && _.filter(scopes, {roomId: room.id}).length > 0;
  });

  //HINT reason unknown, but elevations are sometimes not deleted for deleted rooms
  //This causes errors when not filtered out
  elevations = _.filter(elevations, elevation => {
    return _.find(rooms, {id: elevation.roomId});
  });

  //HINT disabled for now after feedback from design + approval from Bryan
  //Ideally would have a better system for showing notes like this that only appeared once a day or something like that
  // if (_.some(products, product => _.includes([1333, 1334, 1335, 1337], product.productId) || (product.productId === 1509 && _.get(_.find(products, {id: product.productInstanceId}), 'productId') === 1450))) {
  //   alert(`ALERT: outlets used in base units are prohibited based on the 2023 NEC - client to verify with their local electrical codes prior to proceeding.`);
  // }

  resourceActionDispatchers.trackProjects({projects: [project]});
  resourceActionDispatchers.trackFloors({floors});
  resourceActionDispatchers.trackRooms({rooms});
  resourceActionDispatchers.trackWalls({walls});
  resourceActionDispatchers.trackElevations({elevations});
  resourceActionDispatchers.trackScopes({scopes});
  resourceActionDispatchers.trackContainers({containers});
  resourceActionDispatchers.trackArchElements({archElements});
  resourceActionDispatchers.trackArchetypes({archetypes});
  resourceActionDispatchers.trackProducts({products});
  resourceActionDispatchers.trackProductTypes({productTypes});
  resourceActionDispatchers.trackModels({models});
  resourceActionDispatchers.trackAppliances({appliances});
  resourceActionDispatchers.trackPulls({pulls});
  resourceActionDispatchers.trackPricingRules({pricingRules});
  resourceActionDispatchers.trackContainerTypes({containerTypes});
  resourceActionDispatchers.trackMaterialClasses({materialClasses});
  resourceActionDispatchers.trackMaterials({materials});
  resourceActionDispatchers.trackMaterialTypes({materialTypes});
  resourceActionDispatchers.trackProductOptions({productOptions: productOptionInstances});
  resourceActionDispatchers.trackProductOptionTypes({productOptionTypes});
  resourceActionDispatchers.trackProjectGraphics({projectGraphics});
  resourceActionDispatchers.trackProductCategories({productCategories});
  resourceActionDispatchers.trackVolumes({volumes});
  resourceActionDispatchers.trackParts({parts});
};

export default loadProjectData;
