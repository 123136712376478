import _ from 'lodash';

import Room from 'project-helpers/room';
import DetailsHelper from './details-helper';

export default function getMaterialOptionsForArchetypeParameter({parameter, room, materialKey = undefined, ignoreCompatibleOptions=false}) {
  var {containers, products, project} = Room.get(['containers', 'products', 'project'], {room});

  var filteredInstances = _.filter(parameter.instances, (instance) => {
    var resource = instance.resourceKey === 'container' ? _.find(containers, {persistentId: instance.resourcePersistentId}) : (instance.resourceKey === 'product' ? _.find(products, {persistentId: instance.resourcePersistentId}) : undefined);

    return resource;
  });

  var compatibleMaterialOptionsForInstances = _.map(filteredInstances, (instance) => {
    var compatibleDetails;

    var materialKeys = instance.materialKeys || parameter.defaultMaterialKeys || [`${materialKey}`];

    if (instance.resourceKey === 'container') {
      var container = _.find(containers, {persistentId: instance.resourcePersistentId});
      compatibleDetails = DetailsHelper.getCompatibleDetailsFor({container});
    }
    else if (instance.resourceKey === 'product') {
      var product = _.find(products, {persistentId: instance.resourcePersistentId});
      compatibleDetails = DetailsHelper.getCompatibleDetailsFor({product});
    }

    compatibleDetails = _.filter(compatibleDetails, ({key}) => _.includes(materialKeys, key));

    return _.flatMap(compatibleDetails, 'options');
  });

  var allMaterialOptions = _.flatten(compatibleMaterialOptionsForInstances);

  var options =  _.chain(allMaterialOptions)
    .filter((option) => { //HINT option must be compatible for all instances
      return _.every(compatibleMaterialOptionsForInstances, (options) => {
        return _.find(options, {id: option.id});
      });
    })
    .filter((option) => { //HINT filter options to those set manually on the parameter
      if (ignoreCompatibleOptions) {
        return true
      }
      else {
        if (_.includes(['pull', 'pullType'], parameter.type) && materialKey === 'pullType' && parameter.compatiblePullIds && parameter.compatiblePullIds.length > 0) {
          //HINT always allow pulls by others
          return option.id === (project.companyKey === 'hb' ? 18 : 9) || _.includes(parameter.compatiblePullIds, option.id);
        }
        else if (parameter.type === 'material' && parameter.compatibleMaterialIds && parameter.compatibleMaterialIds.length > 0) {
          return _.includes(parameter.compatibleMaterialIds, option.id);
        }
        else {
          return true;
        }
      }
    })
    .uniqBy('id')
    .map(({id, title, materialTypeId, thumbnail}) => ({title, value: id, materialTypeId, thumbnail}))
    .value();
    return options;
}
