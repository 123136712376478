import React, { useContext } from 'react';
import _, { filter } from 'lodash';
import K from 'k';
import lib from 'lib';
import CanvasLine from 'canvas/canvas-line';
import PositionHelper from 'helpers/position-helper';
import CanvasProjectGraphic from '../project-components/canvas-project-graphic';

import { CanvasDataContext, CanvasErrorFallback, CanvasSelectionContext } from 'canvas';
import { resourceActions, connect } from 'redux/index.js';

import { withErrorBoundary } from 'react-error-boundary';

class CanvasFloor extends React.PureComponent {
  render() {
    var {floor, stencilVisible, backgroundsVisible, backgroundsSelectable, viewOffset} = this.props;
    var {canvasData, selectionData} = this.props;
    var filteredProjectGraphics = _.filter(this.props.projectGraphics, (projectGraphic) => !!projectGraphic.floorId && projectGraphic.floorId === floor.id);

    return (<>
      {stencilVisible && !!floor.stencil && _.map(floor.stencil.data.entities, (entity, index) => {
        if (!entity.type === 'polyline' && entity.points.length > 0) return null;

        var points = entity.points;

        points = _.map(points, point => PositionHelper.toCanvas(lib.object.sum(point, viewOffset), canvasData));

        if (entity.closed) points = [...points, points[0]];

        points = _.flatMap(points, point => [point.x, point.y]);

        return (
          <CanvasLine
            key={index}
            stroke={K.colors.stencil}
            points={points}
          />
        );
      })
      }
      {backgroundsVisible && filteredProjectGraphics && _.map(filteredProjectGraphics, projectGraphic => (
        <CanvasProjectGraphic
          {...{id: projectGraphic.id, viewOffset, firstWallXInset: {x: 0, y: 0}, selectionData, renderForDrawings: false, preventEditing: !backgroundsSelectable, isExportingSvg: false}}
          key={projectGraphic.id}
          viewKey={'top'}
        />
      ))}
    </>);
  }
}

function CanvasFloorWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  let selectionData = useContext(CanvasSelectionContext);

  return <CanvasFloor {...props} {...{canvasData, selectionData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => ({projectGraphics: state.resources.projectGraphics.byId}),
  mapDispatch: {
    ..._.pick(resourceActions.projectGraphics, ['destroyProjectGraphics']),
  }
})(CanvasFloorWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Floor DXF'})
});