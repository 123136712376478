import React, {useState, useRef, useEffect, forwardRef, useCallback} from 'react';
import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Drawings from 'project-helpers/drawings';
import Room from 'project-helpers/room';

import CanvasView from 'canvas/canvas-view';
import CanvasElevation from 'project-components/canvas-elevation';
import CanvasRoom from 'project-components/canvas-room';

import {Context} from '@symbolic/svgcanvas';

const ConfiguratorGraphic = ({
  //HINT drawings state
  containerDimensions, project, mode, visibilityLayers, activeDimensionsLayer, detailLevel, fillMode, activeElevation, onCanvasDataChange,
  //HINT graphic data
  id, size, isEmpty, position, scale = 1, type, model, floorRooms, activeRoom, upperHeightPadding=0, lowerHeightPadding=0, containerSize, isStatic, scaleFactor = 1, scaleConstraint
}) => {
  const [forceUpdateKey, setForceUpdateKey] = useState(lib.string.uuid());
  const [isLoading, setIsLoading] = useState(false);
  const [renderCanvasViewChildren, setRenderCanvasViewChildren] = useState(true);
  const [isRendered, setIsRendered] = useState(false);
  const [svgString, setSvgString] = useState(null);

  // const canvasSize = isEmpty ? lib.object.multiply(containerDimensions, 1 / 6) : Drawings.getSizeForPrintArea({printArea: {contentSize: size}, scale, project});
  // const positionInPage = Drawings.getPositionInPage({position, size: canvasSize, containerDimensions});

  const styles = {
    canvas: {
      width: `100%`,
      height: `100%`,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignSelf: 'center'
    },
    wrapper: {
      // position: 'absolute',
      // top: `${positionInPage.top}px`,
      // left: `${positionInPage.left}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      // opacity,
      opacity: 1,
      cursor: 'inherit',
    },
    error: {
      position: 'absolute',
      // top: `${positionInPage.top}px`,
      // left: `${positionInPage.left}px`,
      // width: `${canvasSize.width}px`,
      // height: `${canvasSize.height}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }
  };

  const classNames = [
    // 'graphic-image',
    // // `${type}-${id}`,
    // `${!isLoading ? '' : 'show-loader loader-dark'}`,
  ].join(' ');

  const handleForceUpdate = () => setForceUpdateKey(lib.string.uuid());
  const handleLoadingStart = () => setIsLoading(true);
  const handleLoadingEnd = () => setIsLoading(false);

  let viewOffset = {x: 0, y: 0};
  var size = {width: 0, height: 0};

  if (type === 'room' || (type === 'floor' && activeRoom)) {
    var room = activeRoom || model;
    const {min, max} = Room.getContentOutline({room});
    size = {width: max.x - min.x, height: max.y - min.y};

    viewOffset = lib.object.difference({x: -min.x - size.width * 0.5, y: -min.y - size.height * 0.5}, room.plan.position);
  }
  else if (type === 'elevation') {
    const {min, max} = Elevation.getContentOutline({elevation: model, activeDetailLevel: detailLevel});
    size = {width: max.x - min.x, height: max.y - min.y};

    viewOffset = {x: -min.x - size.width * 0.5, y: max.y - size.height * 0.5 + (upperHeightPadding - lowerHeightPadding)};
  }

  var scaleConstraint = scaleConstraint || ((type === 'room' || (type === 'floor' && activeRoom)) ? 0.4 : .9);

  var xyScale = {
    width: (containerSize.width * scaleConstraint) / (size.width),
    height: (containerSize.height * scaleConstraint) / (size.height),
  };

  scale = parseFloat(_.min([xyScale.width * scaleFactor, xyScale.height * scaleFactor]).toFixed(2));

  //HINT scale is not needed for floor
  //default scale works reasonably and can always be zoomed differently
  if (type === 'floor') scale = undefined;

  const projectData = {
    ..._.pick(project, ['dimensionsData', 'companyKey', 'versionId', 'id', 'isEmployee']),
    activeDimensionsLayer,
    isEditingDimensions: false,
    countertopsAreSelectable: false,
    pushToUndoQueue: null,
    toggleTolerancePopupShowing: false,
    showingAlignmentIndicators: false,
  };

  return (<>
    <div
      key={`standard-page-graphic-${id}`}
      style={styles.wrapper}
    >
      <CanvasView
        key={model.id}
        className={classNames}
        style={styles.canvas}
        leftClickPan
        isStatic={isStatic}
        useDefaultSizeAndScale
        {...{projectData, scale, ...(onCanvasDataChange ? {onCanvasDataChange: (canvasData) => onCanvasDataChange(canvasData)} : {})}}
      >
        {type === 'floor' ? (_.map(floorRooms, (room, roomId) => {
          var isActiveRoom = activeRoom && activeRoom.id === room.id;
          var roomVisibilityLayers = {
            ...visibilityLayers,
            elevationIndicators: isActiveRoom ? visibilityLayers.elevationIndicators : [],
          };

          return <CanvasRoom key={roomId} renderForDrawings preventEditing {...{room, visibilityLayers: roomVisibilityLayers, activeDimensionsLayer, activeDetailLevel: detailLevel, activeFillMode: fillMode, viewOffset}}/>;
        })) : (type === 'room' ? (
          <CanvasRoom key={id} renderForDrawings preventEditing {...{room: model, visibilityLayers, activeDimensionsLayer, activeDetailLevel: detailLevel, activeFillMode: fillMode, viewOffset}}/>)
          : (
            <CanvasElevation
              key={id}
              renderForDrawings
              preventEditing
              hideTitle
              {...{elevation: model, visibilityLayers, viewOffset, activeDimensionsLayer, activeDetailLevel: detailLevel, viewOffset, activeFillMode: fillMode}}
            />
          )
        )}
      </CanvasView>
    </div>
  </>);
};
export default ConfiguratorGraphic;
