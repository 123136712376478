import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import K from 'k';
import lib from 'lib';

import NumberInput from './number-input';
import ColorInput from './color-input';
import TextInput from './text-input';
import ExpandedPropertyFieldOption from './expanded-property-field-option';
import ScrollableNote from './scrollable-note';

import { resourceActions } from 'redux/index.js';
import { connect } from 'redux/index.js';

import checkIcon from '../assets/check-icon.png';
import upArrowIcon from 'assets/up-arrow-black.png';

import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';

import Elevation from 'project-helpers/elevation';

//import getContainerFieldGroups from 'properties-view-data/container-properties-view-helpers'

class Parameter extends React.PureComponent {
  state = {
    searchTerm: '',
    expandedOptionGroupIndexes: [],
  };

  handlePropertiesChange = async ({value}) => {
    var {room, parameter} = this.props;

    applyArchetypeParameterSelection({room, parameter, value, reduxActions: this.props});
  };

  handleCheckboxPress = () => {
    var {room, path, defaultTrue} = this.props;

    var value = _.get(room, path, defaultTrue ? 1 : 0) === 1 ? 0 : 1;

    this.handlePropertiesChange({value});
  };

  //TODO refactor to use parameterSelections
  render () {
    var {details, path, title, type, options, optionGroups = [], noThumbnail = true, hasOptionGroups, defaultTrue, defaultColor, inverseValue, placeholder, activeEntity, activeEntityId, activeEntityResourceKey, maxValue, minValue, step, isExpanded, isMultiline, widerOptionsDropdown} = this.props;

    var thumbnailStyles = {height: 32, width: 32, minWidth: 32, backgroundColor: 'white', borderRadius: 50, border: K.grayBorder, display: 'flex', justifyContent: 'center', alignItems: 'center'};
    options = _.cloneDeep(options);
    optionGroups = _.cloneDeep(optionGroups);

    var radioActiveOption, activeThumbnail;
    let checkboxValue = _.get(activeEntity, path, defaultTrue ? 1 : 0) ? 1 : 0;
    var isSubdued = _.includes(['checkbox'], type) && checkboxValue === (inverseValue ? 1 : 0);

    var valuePath = path;

    var value = _.get(activeEntity, valuePath);

    if (_.includes(path, 'details')) value = _.get({details}, valuePath);

    if (_.includes(path, 'details') && _.get({details}, `${_.replace(path, '.id', '')}.isMixed`)) {
      value = 0;
      if (options && options.length && options[0].title !== 'Mixed') options.unshift({title: 'Mixed', id: -1});

      if (optionGroups && optionGroups.length && _.get(optionGroups, '[0].options[0].title') !== 'Mixed') optionGroups[0].options.unshift({title: 'Mixed', id: -1});
    }

    if (activeEntityResourceKey === 'elevation' && (valuePath === 'ceilingHeight' || valuePath === 'datums' || valuePath === 'customData.pullAlignment')) {
      value = _.get(Elevation.get('room', {elevation: activeEntity}), valuePath);
    }

    if (_.includes(['radio', 'select'], type) && !hasOptionGroups) radioActiveOption = _.find(options, {id: value}) || _.find(options, {value}) || options[0];
    else if (_.includes(['radio', 'select'], type) && hasOptionGroups) {
      //HINT order of options matters for materials
      let flattenedOptions = options || _.flatMap(_.map(optionGroups, 'options'));
      radioActiveOption = _.find(flattenedOptions, {id: value}) || _.find(flattenedOptions, {value}) || flattenedOptions[0];
    }

    if (radioActiveOption) activeThumbnail = radioActiveOption.thumbnail;

    if (this.state.searchTerm && options) {
      options = _.filter(options, option => {
        return lib.string.isSearchMatch({title: option.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
      });
    }

    if (this.state.searchTerm && optionGroups) {
      optionGroups = _.map(optionGroups, optionGroup => {
        //HINT pulling optionGroup.options out causes a reference issue that mutates state
        return {...optionGroup, options: _.filter(optionGroup.options, option => {
          return lib.string.isSearchMatch({title: option.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
        })};
      });

      optionGroups = _.filter(optionGroups, optionGroup => {
        return optionGroup.options.length > 0;
      });
    }

    var popupWidth = K.paneWidth - 60 - K.spacing;
    var widthIncreaseBy = 120;

    if (widerOptionsDropdown) popupWidth += widthIncreaseBy;

    return (
      <div>
        <div onClick={(event) => {
          if (_.includes(['checkbox'], type)) {
            this.handleCheckboxPress();
          }
          else if (this.props.onClick && (!isExpanded || event.target.className === 'should-close')) {
            this.props.onClick();
          }}
        } className='should-close' style={{...(!_.includes(['number', 'size', 'text'], type) ? {cursor: 'pointer'} : {}), display: 'flex', flexDirection: 'row', marginBottom: K.spacing, justifyContent: 'flex-end', alignItems: 'center'}}>
          <div className='should-close' style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: K.spacing, position: 'relative', top: _.includes(['number', 'size', 'text'], type) ? -3 : 0}}>
            <div className='should-close' style={{textAlign: 'right', fontWeight: 'bold', opacity: isSubdued ? 0.5 : 1, marginBottom: _.includes(['number', 'size', 'text'], type) ? 3 : 0}} onClick={this.props.onClick} >{title}</div>
            {_.includes(['number', 'size'], type) && (
              <NumberInput
                {...{placeholder, value, maxValue, minValue, step}}
                style={{textAlign: 'right', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', width: 120, paddingRight: K.margin, position: 'relative', right: -5, height: 16}}
                id={`${title}-${type}-input`}
                value={value}
                onChange={({value}) => {
                  this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
                }}
              />
            )}
            {_.includes(['color'], type) && (
              <ColorInput
                {...{defaultColor, value}}
                style={{textAlign: 'right', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', width: 120, paddingRight: K.margin, position: 'relative', right: -5, height: 16}}
                id={`${title}-${type}-input`}
                value={value}
                onChange={({value}) => {
                  this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
                }}
              />
            )}
            {_.includes(['text'], type) && (<>
              {isMultiline ? (
                <ScrollableNote
                  key={`${activeEntityId}-${value}`}
                  onChange={({value}) => {
                    this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
                  }}
                  propNotes={value}
                  placeholder={placeholder}
                />
              ) : (
                <TextInput
                  {...{placeholder, value}}
                  style={{textAlign: 'right', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', paddingRight: K.margin, position: 'relative', right: -5, height: 16, width: 120}}
                  id={`${title}-${type}-input`}
                  onChange={({value}) => {
                    this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
                  }}
                />
              )}
            </>)}
            {_.includes(['radio', 'select'], type) && (<div style={{position: 'relative'}}>
              <div style={{textAlign: 'right', opacity: 0.7}}>{_.get(radioActiveOption, 'title', '')}</div>
              {isExpanded && (
                <div
                  style={{...K.shadow, borderRadius: K.borderRadius, position: 'absolute', overflowY: 'overlay', backgroundColor: 'white', flexDirection: 'column', top: 'calc(100% + 5px)', right: 0, overflowX: 'hidden', maxHeight: 300, width: popupWidth, maxWidth: popupWidth, zIndex: 1}}
                >
                  <div style={{display: 'flex', maxHeight: 250, overflowY: 'scroll', overflowX: 'hidden', flexDirection: (!hasOptionGroups && noThumbnail) ? 'column' : 'row', flexWrap: 'wrap', padding: K.spacing}}>
                    {hasOptionGroups ? (
                      _.map(optionGroups, ({title: optionGroupTitle, options, id}, index) => (
                        <div key={index}>
                          <div style={{display: 'flex', width: popupWidth, ...(noThumbnail ? {alignItems: 'center'} : {}), marginBottom: K.margin, cursor: 'pointer'}}
                            onClick={() => this.handleUpdateExpandedGroupIndexes(index)}
                          >
                            <img src={upArrowIcon} alt='arrow' style={{width: 16, height: 16, cursor: 'pointer', marginLeft: K.margin, backgroundColor: 'transparent', transform: (_.includes(this.state.expandedOptionGroupIndexes, index) || this.state.searchTerm) ? 'rotate(180deg)' : 'rotate(90deg)'}}/>
                            <div style={{marginLeft: K.margin, fontWeight: 'bold'}}>{optionGroupTitle}</div>
                          </div>

                          {(this.state.searchTerm || _.includes(this.state.expandedOptionGroupIndexes, index)) && (
                            <div style={{paddingBottom: K.spacing, paddingTop: K.spacing / 2, flexWrap: 'wrap', display: 'flex', width: popupWidth - K.spacing * 3, flexDirection: noThumbnail ? 'column' : 'row'}}>
                              {_.map(options, ({thumbnail, title, id, value, invalid, shouldHide}, optionIndex) => {
                                const isActive = (_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (id || value);

                                return (shouldHide && !isActive) ? null : (
                                  <ExpandedPropertyFieldOption
                                    key={optionIndex}
                                    {...{noThumbnail, thumbnail, id, value, title, thumbnailStyles, invalid, hasOptionGroups, radioActiveOption, optionGroupTitle}}
                                    onClick={() => this.handlePropertiesChange({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value: id || value})}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ))) : (
                      <div>
                        {
                          _.map(options, ({thumbnail, title, id, value, invalid, shouldHide}, optionIndex) => {
                            const isActive = (_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (id || value);

                            return (shouldHide && !isActive) ? null : (
                              (
                                <ExpandedPropertyFieldOption
                                  key={optionIndex}
                                  {...{id, value, isActive, title, thumbnail, noThumbnail, thumbnailStyles, invalid, radioActiveOption}}
                                  onClick={() => this.handlePropertiesChange({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value: id || value})}
                                />
                              )
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <>
                    {((options && options.length > 5) || hasOptionGroups || this.state.searchTerm) && (
                      <TextInput
                        style={{margin: K.spacing, width: popupWidth - K.spacing * 2, backgroundColor: '#f5f5f5', border: '1px solid rgba(0, 0, 0, 0.07)'}}
                        onInput={this.handleSearchInputChange}
                        placeholder='SEARCH...'
                        autoFocus={true}
                      />
                    )}
                  </>
                </div>
              )}</div>
            )}
          </div>
          {<div onClick={this.props.onClick} style={{...thumbnailStyles, opacity: isSubdued ? 0.1 : 1, ...(isExpanded ? {border: '1px solid black'} : {})}}>
            {type === 'checkbox' && (
              <img src={checkIcon} alt='check' style={{width: 20, height: 20, backgroundColor: 'transparent'}}/>
            )}
            {(type !== 'checkbox' && activeThumbnail) && (
              <img src={activeThumbnail} style={{...thumbnailStyles}}/>
            )}
          </div>
          }
        </div>
      </div>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['updateRoom']),
    ..._.pick(resourceActions.containers, ['updateContainer', 'modifyContainers']),
    ..._.pick(resourceActions.archElements, ['updateArchElement']),
    ..._.pick(resourceActions.products, ['updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.projectGraphics, ['updateProjectGraphic', 'trackProjectGraphics']),
    ..._.pick(resourceActions.elevations, ['updateElevation', 'modifyElevations']),
    ..._.pick(resourceActions.walls, ['updateWalls']),
    ..._.pick(resourceActions.volumes, ['updateVolume']),
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','updateParts']),
  }
})(Parameter);
