import _ from 'lodash';
import lib from 'lib';
import K from 'k';

// import Wall from './wall';
import Container from './container/index';
import Elevation from './elevation';
import Room from './room';
import Project from './project';
import getDependencies from 'helpers/get-dependencies';
import memo from 'helpers/memo';
import Opencase from './product-helpers/opencase';
import updateProductionIds from 'helpers/update-production-ids-helper';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import DetailsHelper from 'helpers/details-helper';
import HatchHelper from 'helpers/hatch-helper';
import Barblock from './product-helpers/barblock';
import Shelfbank from './product-helpers/shelfbank';
import DaylightIslandComponent from './product-helpers/daylight-island-component';
import setIssues from 'helpers/issues/index';
import getProductOptionIncompatibilityRules from './product-option-helpers/product-option-incompatibility-rules';
import moment from 'moment';

// import { store } from 'redux/index.js';

var Product = {
  ...Opencase,
  get(dependencyKeys, {product}) {
    return getDependencies({dependencyKeys}, ({state, useDependency}) => {
      if (useDependency('room')) {
        var scope = _.find(state.resources.scopes.byId, {id: product.scopeId});
        var room = _.find(state.resources.rooms.byId, {id: scope.roomId});
      }

      if (useDependency('childProducts')) {
        var childProducts = product.id ? state.resources.products.byFieldKeyIndex.productInstanceId[product.id] : []; //HINT drag and drop products can have an undefined id
      }

      if (useDependency('parentProduct') || useDependency('container') || useDependency('siblings')) {
        var parentProduct = state.resources.products.byId[product.productInstanceId];
      }

      if (useDependency('container') || useDependency('siblings')) {
        var container = state.resources.containers.byId[product.containerInstanceId || parentProduct?.containerInstanceId];
      }

      if (useDependency('siblings')) {
        var siblings;

        if (!_.includes(['containerInstance', 'productInstance'], product.primaryAssociationKey)) {
          siblings = [];
        }
        else {
          if (parentProduct) {
            siblings = _.filter(state.resources.products.byFieldKeyIndex.productInstanceId[parentProduct.id], p1 => p1.id !== product.id && p1.primaryAssociationKey === 'productInstance');
          }
          else if (container) {
            siblings = _.filter(state.resources.products.byFieldKeyIndex.containerInstanceId[container.id], p1 => p1.id !== product.id && p1.primaryAssociationKey === 'containerInstance');
          }
        }
      }

      return {
        project: () => state.resources.projects.byId[product.projectId],
        companyKey: () => state.resources.projects.byId[product.projectId].companyKey,
        isEmployee: () => state.resources.projects.byId[product.projectId].isEmployee,
        childProducts: () => childProducts,
        parentProduct: () => parentProduct,
        managedProductInstances: () => _.filter(childProducts, childProduct => _.get(childProduct, 'managedData.managedKey') !== undefined),
        unmanagedProductInstances: () => _.filter(childProducts, childProduct => _.get(childProduct, 'managedData.managedKey') === undefined),
        container: () => container,
        room: () => room,
        scope: () => scope,
        siblings: () => siblings,
        productType: () => state.resources.productTypes.byId[product.productId],
        model: () => _.map(state.resources.models.byFieldKeyIndex.productId[product.productId])[0],
        models: () => state.resources.models.byId,
        appliances: () => state.resources.appliances.byId,
        productOptionInstances: () => _.values(_.get(state.resources.productOptions, `byFieldKeyIndex.productInstanceId[${product.id}]`) || {}),
        dependencies: () => state.resources,
      };
    });
  },

  //TODO
  getProductData({product}) {
    return {
      productCategoryIds: () => Product.getProductCategoryIdsFor({product}),
      ...Product.get('productType', {product})
    };
  },

  getProductCategoryIdsFor({product}) {
    let productCategoryIds = [];

    const productType = Product.get('productType', {product});
    const {categoryId} = productType;

    if (Product.getHasComponents({product})) {
      const type = Product.getType({product});

      if (type === 'opencasePanel') {
        productCategoryIds = [54];
      }
      else if (type === 'applianceStackFrame') {
        productCategoryIds = [197];
      }
      else if (type === 'horizontalBarblock' || type === 'verticalBarblock') {
        productCategoryIds = [categoryId];
      }
      else if (type === 'shelfbank') {
        productCategoryIds = [20];
      }
    }

    if (Product.getCanHavePegs({product, productType})) productCategoryIds.push(62);

    return productCategoryIds;
  },

  getShelfOffsets({product}) {
    var shelfOffsets;

    if (_.includes([1379], product.productId)) {
      shelfOffsets = [product.dimensions.height - 1.5 * 2];
    }
    else if (_.includes([162, 163, 164, 165, 166, 167, 406, 1150, 1159, 1160, 1322], product.productId)) {
      var {shelfOffsets, hasOnly1Shelf, bottomShelfOffset = 0} = product.customData;
      var {dimensions} = product;

      if (shelfOffsets) shelfOffsets = _.map(_.split(shelfOffsets, ','), offset => parseFloat(offset));

      if (hasOnly1Shelf && dimensions.depth !== 18.5) shelfOffsets = [];
      //HINT default shelfoffsets

      if (!shelfOffsets) {
        var shelfHeight = 1.5;

        if (product.productId === 162) {
          var shelfCount = 2;

          if (dimensions.height - bottomShelfOffset > 24) shelfCount = 3;
          if (dimensions.height - bottomShelfOffset > 40) shelfCount = 4;

          shelfOffsets = [];

          if (shelfCount === 2) {
            shelfOffsets.push(dimensions.height - bottomShelfOffset - shelfCount * shelfHeight);
          }
          else {
            _.times(shelfCount - 1, n => {
              shelfOffsets.push((dimensions.height - bottomShelfOffset - (shelfCount * shelfHeight)) / (shelfCount - 1));
            });
          }
        }
        else {
          var shelfCount = dimensions.height >= 19 ? 3 : 2;
          shelfOffsets = [];

          if (shelfCount === 2) {
            shelfOffsets.push(dimensions.height - shelfCount * shelfHeight);
          }
          else {
            shelfOffsets.push((dimensions.height - (shelfCount * shelfHeight)) / ((shelfCount - 1)), (dimensions.height - shelfCount * shelfHeight) / ((shelfCount - 1)));
          }
        }
      }

      if (bottomShelfOffset) shelfOffsets.unshift(bottomShelfOffset);
    }

    return shelfOffsets;
  },

  getCanHavePegs({product, productType}) {
    if (!productType) productType = Product.get('productType', {product});

    //HINT wall panels, shelfbanks, opencase panels
    return _.includes([375, 376, 377, 378, 162, 163, 164, 165, 166, 165, 167, 406, 435, 451, 512, 513, 514, 1503, 1717], product.productId) || _.includes([72], productType.categoryId);
  },

  getIsVanityBay({product}) {
    return _.includes([1400, 1402, 1403, 1404, 1430, 1431, 1435, 1436, 1464, 1466, 1467, 1468, 1469, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490, 1491, 1492, 1493, 1726, 1727, 1744, 1745], product.productId);
  },

  getDefaultCustomData({product}) {
    const type = Product.getType({product});
    if (type === 'opencasePanel') {
      return {
        margin: {},
        cellCount: {},
        activeFittings: []
      };
    }
    else if (type === 'opencaseComponent') {
      return {
        gridPosition: {row: 0, column: 0}
      };
    }
    else if (type === 'horizontalBarblock' || type === 'verticalBarblock') {
      var countertopThickness = 0.5;

      if (_.get(product, 'resourceProps.dimensions.height')) countertopThickness = _.get(product, 'resourceProps.dimensions.height') - 0.75 - 4.625 - .25;

      return {
        countertopThickness,
        orientation: 'right'
      };
    }
    else if (type === 'shelfbank') {
      return {
        margin: {},
        orientation: 'right'
      };
    }

    return {};
  },

  getScript({product, elevation, viewKey, sideKey, activeDetailLevel, activeFillMode}) {
    var script;
    var {container, model, productType, parentProduct, companyKey} = Product.get(['container', 'model', 'productType', 'parentProduct', 'companyKey'], {product});

    if (viewKey === 'top' && Product.getHasSink({product})) {
      if (_.includes(['schematic'], activeDetailLevel)) {
        script = `
          var rectProps = ${activeFillMode === 'unitType'} ? {
            isFillable: true,
            fill: '#${K.designPlanningCompleteColorMap.freestandingAppliance.toString()}',
          } : {};
          var rotation = _.getRotation && _.getRotation() || 0;
          var isUpsideDown = rotation === 180;

          group({}, [
            rect({
              ...rectProps
            }),
            text({
              top: '100% - 1.5', left: '50%',
              origin: {x: 'center', y: 'bottom'},
              text: 'sink', fontSize: 11/3,
              rotation: isUpsideDown ? -rotation : 0
            }),
          ]);
        `;
      }
      else {
        script = `
          var groupChildren = [];
          var addLabel = true;

          if (_.getSinkData) {
            var {includeCutout, includeDrainfield, product, width, depth, drainfieldIsFlat, zIndex} = _.getSinkData();

            if (includeCutout) {
              var cutoutGroupChildren = [];
              var {dimensions} = product;

              var top = \`100% - \${${K.sinkMargins.front}} - \${depth}\`;
              var left = dimensions.width / 2 - width / 2;

              addLabel = false;

              if (${companyKey === 'hb'} && includeDrainfield && includeDrainfield !== 'none') {
                var drainfieldHeight = 17;
                var drainfieldLeft = lodash.includes(['left', 'both'], includeDrainfield) ? -23 : -((drainfieldHeight - depth) / 2);
                var drainfieldTop = -(drainfieldHeight - depth) / 2;
                var drainfieldWidth = includeDrainfield === 'both' ? (23 + 23 + width) : (23 + width + ((drainfieldHeight - depth) / 2));


                cutoutGroupChildren.push(
                  path({zIndex, isFillable: true, closed: true, top: drainfieldTop, height: drainfieldHeight, left: drainfieldLeft, width: drainfieldWidth}, [
                    {x: 0, y: 0.5},
                    {arc: true, flipped: true, x: 0.5, y: 0},
                    {x: drainfieldWidth - 0.5, y: 0},
                    {arc: true, flipped: true, x: drainfieldWidth, y: 0.5},
                    {x: drainfieldWidth, y: drainfieldHeight - 0.5},
                    {arc: true, flipped: true, x: drainfieldWidth - 0.5, y: drainfieldHeight},
                    {x: 0.5, y: drainfieldHeight},
                    {arc: true, flipped: true, x: 0,  y: drainfieldHeight - 0.5},
                  ])
                );
              }

              cutoutGroupChildren.push(
                //rect({zIndex})
                path({zIndex, isFillable: true, closed: true, top: 0}, [
                  {x: 0, y: 0.5},
                  {arc: true, flipped: true, x: 0.5, y: 0},
                  {x: '100% - 0.5', y: 0},
                  {arc: true, flipped: true, x: '100%', y: 0.5},
                  {x: '100%', y: '100% - 0.5'},
                  {arc: true, flipped: true, x: '100% - 0.5', y: '100%'},
                  {x: 0.5, y: '100%'},
                  {arc: true, flipped: true, x: 0,  y: '100% - 0.5'},
                ])
              );

              lodash.forEach(['left', 'right'], sideKey => {
                if (lodash.includes([sideKey, 'both'], includeDrainfield) && !drainfieldIsFlat) {
                  var width = 18;

                  cutoutGroupChildren.push(group({
                    zIndex, left: sideKey === 'right' ? '100%' : -width, width, opacity: 5
                  }, [
                    line({zIndex, x1: width, y1: '80%', x2: width + ' - 100%', y2: '80%'}),
                    line({zIndex, x1: width, y1: '70%', x2: width + ' - 100%', y2: '70%'}),
                    line({zIndex, x1: width, y1: '60%', x2: width + ' - 100%', y2: '60%'}),
                    line({zIndex, x1: width, y1: '50%', x2: width + ' - 100%', y2: '50%'}),
                    line({zIndex, x1: width, y1: '40%', x2: width + ' - 100%', y2: '40%'}),
                    line({zIndex, x1: width, y1: '30%', x2: width + ' - 100%', y2: '30%'}),
                    line({zIndex, x1: width, y1: '20%', x2: width + ' - 100%', y2: '20%'}),
                  ]));
                }
              });

              groupChildren.push(group({width, height: depth, top, left}, cutoutGroupChildren));
            }
          }

          var rotation = _.getRotation && _.getRotation() || 0;
          var isUpsideDown = _.getRotation && _.getRotation() === 180;

          //HINT just using _.models.unit for plan label
          group({}, [
            ...groupChildren,
            _.models.unit({hideFill: true}, [])
          ])
          ;
        `;
      }
    }
    else if (_.includes(['schematic'], activeDetailLevel) && Product.getHasSink({product}) && sideKey === 'front') {
      script = `
      var rectProps = ${activeFillMode === 'unitType'} ? {
        isFillable: true,
        fill: '#${K.designPlanningCompleteColorMap.freestandingAppliance.toString()}',
      } : {isHatchFillable: true};

      group({}, [
        rect({
          ...rectProps
        }),
        text({
          top: '50% - 2', left: '50%',
          originX: 'center', originY: 'center',
          text: 'sink', fontSize: 11/3,
        }),
      ]);
      `;
    }
    else {
      script = _.get(model, `sides.${sideKey}.script`);

      var shouldBeDarker = container.type !== 'countertop' && (container.position.y > 35.75 || container.dimensions.height > 35.75);

      if (!script && viewKey === 'top' && _.includes(['base', 'baseWithChase', 'floatingBase', 'tall', 'vanity'], container.type)) {
        script = `_.models.unit({}, [
          // rect({isHatchFillable: true, opacity: ${shouldBeDarker} ? 1 : 0.9})
        ]);`;
      }

      const isOpencaseComponent = Product.getIsOpencaseComponent({product});
      const isShelfbankComponent = Product.getIsShelfbankComponent({product});
      const isPeg = Product.getIsPeg({product});

      var isComponentToShowInSection = isOpencaseComponent || isShelfbankComponent || isPeg;

      if (!script && viewKey === 'top' && _.includes(['wall', 'wallUnitLiner', 'opencase'], container.type)) {
        script = '_.models.unit({}, [])';
      }
      //HINT show filled rect for all units
      // && (_.includes(['wall', 'wallUnitLiner'], container.type) || isComponentToShowInSection)
      else if (!script && elevation && viewKey === 'front' && _.includes(['left', 'right'], sideKey)) {
        script = 'rect({isFillable: true})';
      }

      if (!script && elevation && viewKey === 'front' && _.includes(['left', 'right'], sideKey) && Product.getIsComponentProduct({product})) {
        script = 'rect({isFillable: true})';
      }

      if (_.includes(['schematic'], activeDetailLevel)) {
        script = `_.models.unit({}, [
          // rect({isHatchFillable: true, opacity: ${shouldBeDarker} ? 1 : 0.9})
        ]);`;
      }
    }

    return script;
  },

  updateProjectCalculatedData({product, reduxActions}) {
    const {project, container, room} = Product.get(['project', 'container', 'room'], {product});

    //HINT if container has lighting we need to recalculate option whenever products are edited
    //usually just need to update production ids
    var updateIssues = true;
    var updateUnitNumbers = true;
    var updateContainerResources = Container.getHasLighting({container}) || Product.getIsVanityBay({product}) || Product.getHasSink({product}) || Product.getHasInternalLighting({product}) || Product.getHasLighting({product});

    Room.updateManagedResources({room, reduxActions, updateIssues, updateUnitNumbers, updateContainerResources});
  },

  async create({props, additionalUpdates, reduxActions}) {
    let {container, productType, project, parentProduct} = Product.get(['container', 'productType', 'project', 'parentProduct'], {product: props});
    let details = DetailsHelper.getCleanedOwnedDetailsFor({product: props, includeSubproductDetails: false});
    var compatibleDetails = DetailsHelper.getCompatibleDetailsFor({product: props});
    const parentDetails = DetailsHelper.getDetailsFor({container});

    //HINT set default box material to liner for kitchen base units
    if (project.companyKey === 'hb') {
      var boxMaterialDetail = details['boxMaterial'];
      var {categoryId} = productType;
      var isWallProduct = categoryId <= 23 && categoryId >= 16;
      var isWardrobeProduct = (categoryId <= 36 && categoryId >= 32) || categoryId === 48;
      var isVanityProduct = (categoryId === 37 || categoryId === 63);

      if (boxMaterialDetail) {
        var targetMaterialId = 461;

        if ((isWallProduct || isWardrobeProduct || isVanityProduct)) {
          var projectPricingDate = moment(project.pricingDate).unix();
          var clearStainedVeneerForkDate = moment('2025-02-03').unix();

          if (projectPricingDate >= clearStainedVeneerForkDate && productType.boxPricingStrategy !== 'twoTier') {
            targetMaterialId = 483; //Clear Veneer
          }
          else {
            targetMaterialId = 358; //Veneer
          }
        }

        var compatibleBoxMaterial = _.find(compatibleDetails, {key: 'boxMaterial'});

        if (compatibleBoxMaterial && _.some(compatibleBoxMaterial.options, {id: targetMaterialId})) {
          details['boxMaterial'] = {id: targetMaterialId};
        }
      }
    }

    _.forEach(parentDetails, (value, key) => {
      var compatibleDetail = _.find(compatibleDetails, {key: key});
      var isInvalidMaterial = compatibleDetail && !_.some(compatibleDetail.options, {id: _.get(value, 'id')});

      if (!isInvalidMaterial) {
        var detail = details[key];

        if (detail && _.includes(key, 'Material') && _.get(value, 'id') === 71) {
          if (!_.find(detail.options, {id: 71})) {
            //use end panel material instead
            var newValue = _.get(parentDetails, 'endPanelMaterial.id');

            if (newValue) value = newValue;
          }
        }

        if (detail && !_.includes(['leftScribeMaterial', 'rightScribeMaterial', 'topScribeMaterial'], key) && (!value.isMixed || project.materialPreset)) {
          details[key] = _.pick(value, ['id', 'settings']);
        }
      }
    });

    let materialIds = {};

    _.forEach(details, (detail, detailKey) => {
      if (_.includes(detailKey, 'Material')) {
        materialIds[_.replace(detailKey, 'Material', '')] = detail;
      }
    });

    if (_.includes([1355, 901, 691, 762, 1516], props.productId)) {
      var subcounterHeight = Container.getSubcounterHeight({container});
      var shouldAddSubcounterHeight = subcounterHeight === 0.5;

      props.productionDimensions = {...(props.productionDimensions || {}), height: props.dimensions.height + (shouldAddSubcounterHeight ? subcounterHeight : 0)};
    }
    else if (_.includes([1188], props.productId)) {
      props.productionDimensions = {...(props.productionDimensions || {}), height: 1.875};
    }

    props = {...props, details, materialIds};

    //HINT position pivot door notched panels 0.25" off the wall
    if (props.productId === 1501) props.position.z = 0.75;

    if (props.productId === 755) props.customData = {...props.customData, hasFixedPanel: true};

    if (project.companyKey === 'vp' && container.type === 'vanity' && Product.getHasSink({product: props})) props.customData = {...props.customData, isByOthers: 1};

    props = Product.constrainProps({product: props});

    let product = await lib.api.create('productInstance', {props});

    var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'create', reduxActions, isBatched: true});

    await lib.api.update('productInstance', {where: {id: product.id}, props: {customData: product.customData}});

    managedUpdatesMap.products.tracks.push(product);

    if (managedUpdatesMap.products.creations.length > 0) {
      var managedProducts = await lib.api.create('productInstances', _.map(managedUpdatesMap.products.creations, props => _.omit(props, ['id'])));

      managedUpdatesMap.products.creations = [];
      managedUpdatesMap.products.tracks.push(...managedProducts);
    }

    if (Container.getHasComplexAutofillLogic({container})) {
      let siblingAutofilledStorage = _.filter(Container.get('products', {container}), siblingCandidate => _.get(siblingCandidate, 'managedData.managedKey') === 'autofilledStorage');

      _.forEach(siblingAutofilledStorage, siblingAutofilledProduct => {
        managedUpdatesMap.products.updates.push({where: {id: siblingAutofilledProduct.id}, props: {managedData: null}});
      });
    }

    if (parentProduct && _.get(parentProduct, 'managedData.managedKey') === 'autofilledStorage') {
      managedUpdatesMap.products.updates.push({where: {id: parentProduct.id}, props: {managedData: null}});
    }

    if (additionalUpdates) {
      managedUpdatesMap = UpdatesMapsHelpers.combineUpdatesMaps(managedUpdatesMap, additionalUpdates);
    }

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap: managedUpdatesMap, reduxActions});

    if (Product.getType({product}) === 'opencaseComponent') {
      setTimeout(() => {
        let parentProduct = Product.get('parentProduct', {product});

        Opencase.updateActiveFittings({product: parentProduct, reduxActions});
      }, 10);
    }

    //if (Container.getHasLighting({container}) || Product.getIsVanityBay({product}) || Product.getHasSink({product})) {
    setTimeout(() => {
      Product.updateProjectCalculatedData({product, reduxActions});
    }, 10);
    // }

    return product;
  },

  update({id, product = {}, cachedProduct = {}, props, reduxActions, pushToUndoQueue}) {
    if (!id) id = product.id || cachedProduct.id;
    if (pushToUndoQueue && cachedProduct) pushToUndoQueue({type: 'product', eventKey: 'transformEnd', instance: cachedProduct});

    var container = Product.get('container', {product});
    if (!props.eventType) props.eventType = undefined;

    var autofilledSiblingUpdates = [];
    //when a user manually adjusts an managed unit
    //that unit is no longer managed
    //HINT need to use null so that data goes to DB
    if (product.managedData) {
      if (product.managedData.managedKey === 'autofilledStorage' && Container.getHasComplexAutofillLogic({container})) {
        let siblingAutofilledStorage = _.filter(Container.get('products', {container}), siblingCandidate => _.get(siblingCandidate, 'managedData.managedKey') === 'autofilledStorage');

        _.forEach(siblingAutofilledStorage, siblingAutofilledProduct => {
          autofilledSiblingUpdates.push({where: {id: siblingAutofilledProduct.id}, props: {managedData: null}});
        });
      }

      props.managedData = null;
    }

    if (props.dimensions && !product.customData.hasNonStandardDimensions) {
      var updatedDimensions = {...product.dimensions, ...props.dimensions};
      var constraints = Product.getConstraints({product: {...product, dimensions: updatedDimensions}});

      const constrainer = new lib.DimensionConstrainer({constraints});
      props.dimensions = constrainer.constrain({dimensions: props.dimensions});
    }

    if (_.includes([1355, 901, 691, 762, 1516], product.productId)) {
      var subcounterHeight = Container.getSubcounterHeight({container});
      var shouldAddSubcounterHeight = subcounterHeight === 0.5;

      props.productionDimensions = {
        ...(product.productionDimensions || {}), ...(props.productionDimensions || {}),
        height: (_.get(props, 'dimensions.height') || product.dimensions.height) + (shouldAddSubcounterHeight ? subcounterHeight : 0)
      };
    }
    else if (_.includes([1188], product.productId)) {
      props.productionDimensions = {
        ...(product.productionDimensions || {}), ...(props.productionDimensions || {}),
        height: 1.875
      };
    }

    let updatedProduct = {...cachedProduct, ...product, ...props};
    var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'update', reduxActions, isBatched: true});

    managedUpdatesMap.products.updates.push({where: {id}, props});

    if (autofilledSiblingUpdates.length > 0) {
      managedUpdatesMap.products.updates.push(...autofilledSiblingUpdates);
    }

    if (Product.getType({product}) === 'opencaseComponent') {
      let parentProduct = Product.get('parentProduct', {product});

      managedUpdatesMap.products.updates.push(Opencase.updateActiveFittings({product: parentProduct, isBatched: true}));
    }

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap: managedUpdatesMap, reduxActions});

    Product.updateProjectCalculatedData({product: updatedProduct, reduxActions});
  },

  destroy({product, reduxActions, pushToUndoQueue, isBatched = false}) {
    let products = [...(_.values(Product.get('childProducts', {product})) || []), product];
    let productOptions = [];

    _.forEach(products, product => {
      let childProductOptions = Product.get('productOptionInstances', {product});

      if (childProductOptions && childProductOptions.length > 0) {
        productOptions.push(...childProductOptions);
      }
    });

    if (pushToUndoQueue) {
      let objectsByType = {
        products,
        productOptions,
      };

      pushToUndoQueue({type: 'product', eventKey: 'destroy', instance: product, data: {objectsByType}});
    }

    let updatesMap = {
      productOptions: {tracks: [], creations: [], updates: [], deletedIds: _.map(productOptions, 'id')},
      products: {tracks: [], creations: [], updates: [], deletedIds: _.map(products, 'id')},
    };

    var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'destroy', reduxActions, isBatched: true});

    updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

    if (!isBatched) {
      UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions});

      if (Product.getType({product}) === 'opencaseComponent') {
        setTimeout(() => {
          let parentProduct = Product.get('parentProduct', {product});

          Opencase.updateActiveFittings({product: parentProduct, reduxActions});
        });
      }

      Product.updateProjectCalculatedData({product, reduxActions});
    }
    else {
      return {managedUpdatesMap: updatesMap};
    }
  },

  constrainProps({product}) {
    const {productType, companyKey, container} = Product.get(['productType', 'companyKey', 'container'], {product});

    if (container.customData.hasNonStandardDimensions) {
      product.customData.hasShopDrawing = 1;
      product.customData.hasNonStandardDimensions = 1;
    }

    let dropzoneSize = Container.getDropzoneSize({container, viewKey: 'front'});
    if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
      var swapDimKey = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? 'height' : 'width';

      dropzoneSize = {[swapDimKey]: dropzoneSize[swapDimKey]};
    }

    if (!product.customData.hasNonStandardDimensions) {
      var constraints = Product.getConstraints({product, productType});

      const constrainer = new lib.DimensionConstrainer({constraints});

      var minSizeBetweenConstrainerAndDropzone = {};

      _.forEach(Object.keys(dropzoneSize), dimKey => {
        minSizeBetweenConstrainerAndDropzone[dimKey] = _.max([dropzoneSize[dimKey], constrainer.props.constraints[dimKey].min]);
      });

      product.dimensions = constrainer.constrain({dimensions: product.dimensions}, {max: minSizeBetweenConstrainerAndDropzone});
    }

    var compatibleDetails = DetailsHelper.getCompatibleDetailsFor({product});

    _.forEach(product.details, (value, key) => {
      var compatibleDetail = _.find(compatibleDetails, {key});

      if (compatibleDetail && _.get(compatibleDetail, 'options.length') > 0 && !_.some(compatibleDetail.options, {id: _.get(value, 'id')})) {
        _.set(product.details, key, {id: compatibleDetail.options[0].id});

        if (_.includes(key, 'Material')) {
          _.set(product.materialIds, _.replace(key, 'Material', ''), {id: compatibleDetail.options[0].id});
        }
      }
    });

    if (Product.getHasSink({product}) && product.customData.isByOthers === undefined) {
      product.customData.isByOthers = 0;
    }

    if (productType.hasDoorActionOption && product.customData.doorAction === undefined) {
      product.customData.doorAction = 'left';
    }

    if (productType.hasOrientationOption && product.customData.orientation === undefined) {
      product.customData.orientation = 'left';
    }

    var {title} = productType;
    var applianceType = Product.getApplianceType({product});

    //HINT appliances that always get shop drawings
    if (applianceType === 'oven'
      || applianceType === 'doubleOven'
      || _.includes(_.lowerCase(title), 'rangetop')
      || _.includes([
        65, 66, 1672, //appliance short fixed panel and appliance with drawer
        1703,
        516 //prototype
      ], productType.id)
    ) {
      product.customData.hasShopDrawing = 1;
    }

    if (Product.getIsAppliance({product}) || Product.getHasSink({product})) {
      product.appliancesData = product.appliancesData || [];

      var appliancesData = product.appliancesData;

      _.forEach(Product.getApplianceInstancesData({product}), ({fit, filteredCompatibleAppliances}, index) => {
        var applianceData = _.pick(appliancesData[index], ['customModelNumber', 'customVendor', 'customHeight', ...fit === 'fitted' ? ['id', 'vendor', 'subType', 'panelType'] : ['dimensions', 'modelNumber']]);

        if (fit === 'fitted' && filteredCompatibleAppliances.length > 0) {
          var appliance = _.find(filteredCompatibleAppliances, {id: applianceData.id});

          if (!appliance) {
            appliance = filteredCompatibleAppliances[0];

            _.set(applianceData, 'id', appliance.id);
          }
        }
        else {
          applianceData = _.defaultsDeep(applianceData, {
            dimensions: {width: 0, height: 0, depth: 0},
            modelNumber: ''
          });
        }

        appliancesData[index] = applianceData;
      });
    }
    else {
      product.appliancesData = null;
    }

    return product;
  },

  getConstraints({product, productType}) {
    if (!productType) productType = Product.get('productType', {product});

    var constraints = _.cloneDeep(productType.constraints);

    //HINT allow panel to stretch to 95 in either direction, invalidation warns user
    if (product.productId !== 1135 && Product.getIsOpencasePanel({product})) constraints.width.max = 95;

    var shouldDynamicallyConstrain = productType.useLongestSidePricing;

    if (shouldDynamicallyConstrain) {
      var maxDim = _.max([constraints.width.max, constraints.height.max]);
      var smallerSideDim = _.min([constraints.width.max, constraints.height.max]);

      constraints.width.max = product.dimensions.height > smallerSideDim ? smallerSideDim : maxDim;
      constraints.height.max = product.dimensions.width > smallerSideDim ? smallerSideDim : maxDim;

      //HINT sculpted panel height constraint is 108 when vertically oriented
      if (_.includes(K.hb.ids.sculptedEndPanels, product.productId) && (constraints.height.max === maxDim && constraints.width.max !== maxDim)) {
        constraints.height.max = 108;
        constraints.width.max = lib.number.round(47, {toNearest: constraints.width.step});

        if (constraints.width.max > 47) constraints.width.max -= constraints.width.step;
      }
    }

    //HINT valet leaf door cant' be 5" wider than they are tall
    if (_.includes([1621, 1622], product.productId)) {
      var doorCount = product.productId === 1622 ? 2 : 1;

      constraints.width.max = _.min([constraints.width.max, doorCount * (product.dimensions.height + 5)]);
    }
    else if (product.productId === 443) {
      if (product.dimensions.height === 14) constraints.width.fixed = [16];
      if (product.dimensions.width === 36) constraints.height.fixed = [6.5];
    }
    else if (product.productId !== 1135 && (_.includes([1563, 435], product.productId) || Product.getIsOpencasePanel({product}))) {
      var breakingDim = 47;
      if (product.dimensions.width > breakingDim) constraints.height.max = breakingDim;
      else if (product.dimensions.height > breakingDim) constraints.width.max = breakingDim;
    }
    //HINT open shelfbank has a higher width constraint when depth is 18.5
    else if (product.productId === 162 && product.dimensions.depth === 18.5) {
      constraints.width.min = 36;
    }
    //HINT fluted panels width constraint is dependent on whether the first panel is shortened or not
    else if (_.includes([1460], product.productId) && product.customData.isFlutedAdjacent) {
      constraints.width.min += 0.875;
      constraints.width.max += 0.875;
    }
    //HINT sculpted end panels width dimension is dependent on depth dimension
    else if (_.includes(K.hb.ids.sculptedEndPanels, product.productId) && product.dimensions.depth === 2.125) {
      constraints.width.min += 1;
      constraints.width.max += 1;
    }
    else if (_.includes([1592, 1598], product.productId)) {
      //HINT constrain to valid top bay heights
      constraints.height.min = 10 + 30 + (product.customData.middleBayHeight || 36);
      constraints.height.max = _.min([constraints.height.max, 31 + 30 + (product.customData.middleBayHeight || 36)]);
    }
    //HINT pocketing pantry has depth dependent height constraints
    else if (product.productId === 990) {
      if (product.dimensions.depth === 18) {
        constraints.height.min = 13.5;
        constraints.height.max = 20;
      }
      else if (product.dimensions.depth === 24.625) {
        constraints.height.min = 20;
        constraints.height.max = 24;
      }
    }
    else if (_.includes(K.stackedProductIds, product.productId)) {
      var stackData = Product.getStackData({product});

      //HINT only relevant when 1 bay is flex
      if ((stackData.top.manuallyEntered && !stackData.bottom.manuallyEntered) || (!stackData.top.manuallyEntered && stackData.bottom.manuallyEntered)) {
        var manuallySetBay = _.find(stackData, {manuallyEntered: true});
        var flexBay = _.find(stackData, {manuallyEntered: false});

        constraints.height.min = _.max([manuallySetBay.height + flexBay.constraints.min, constraints.height.min]);
        constraints.height.max = _.min([manuallySetBay.height + flexBay.constraints.realMax, constraints.height.max]);
      }
    }

    return constraints;
  },

  getStackData({product}) {
    var productType = Product.get('productType', {product});

    var constraints = _.cloneDeep(productType.constraints);

    var bayTypeConstraintsMap = {
      flipDoor: {realMax: 24, constrainedMax: 24, min: 10},
      liftDoor: {realMax: 22, constrainedMax: 22, min: 16},
      //HINT leaf doors can be any height, just cant be 5" wider than tall
      leafDoor: {realMax: 10000, constrainedMax: 10000, min: 16}
    };

    var topBayType = 'leafDoor';

    if (_.includes([150, 395], product.productId)) topBayType = 'liftDoor';
    if (_.includes([151, 1740], product.productId)) topBayType = 'flipDoor';

    var bottomBayType = topBayType;

    if (_.includes([152, 153], product.productId)) bottomBayType = 'liftDoor';
    if (_.includes([154, 155], product.productId)) bottomBayType = 'flipDoor';

    var topBayConstraints = bayTypeConstraintsMap[topBayType];
    var bottomBayConstraints = bayTypeConstraintsMap[bottomBayType];

    var {topStackHeight, bottomStackHeight} = product.customData;

    if (!topStackHeight && bottomStackHeight) {
      topStackHeight = product.dimensions.height - bottomStackHeight;
      topBayConstraints.constrainedMax = constraints.height.max - bottomStackHeight;
    }
    else if (!bottomStackHeight && topStackHeight) {
      bottomStackHeight = product.dimensions.height - topStackHeight;
      bottomBayConstraints.constrainedMax = constraints.height.max - topStackHeight;
    }
    else if (!topStackHeight && !bottomStackHeight) {
      //from chatGPT
      var calculateHeights = ({totalHeight, topBayConstraints, bottomBayConstraints}) => {
        var topBayHeight = totalHeight / 2;
        var bottomBayHeight = totalHeight / 2;

        // Adjust top bay height to fit within constraints
        if (topBayHeight < topBayConstraints.min) {
          topBayHeight = topBayConstraints.min;
        } else if (topBayHeight > topBayConstraints.constrainedMax) {
          topBayHeight = topBayConstraints.constrainedMax;
        }

        // Assign remaining height to bottom bay, ensuring it meets constraints
        bottomBayHeight = totalHeight - topBayHeight;

        if (bottomBayHeight < bottomBayConstraints.min) {
          bottomBayHeight = bottomBayConstraints.min;
          topBayHeight = totalHeight - bottomBayHeight; // Adjust top bay height accordingly
        } else if (bottomBayHeight > bottomBayConstraints.constrainedMax) {
          bottomBayHeight = bottomBayConstraints.constrainedMax;
          topBayHeight = totalHeight - bottomBayHeight; // Adjust top bay height accordingly
        }

        return {topBayHeight, bottomBayHeight};
      };

      var defaultHeights = calculateHeights({totalHeight: product.dimensions.height, topBayConstraints, bottomBayConstraints});

      topStackHeight = defaultHeights.topBayHeight;
      bottomStackHeight = defaultHeights.bottomBayHeight;
    }

    topStackHeight = _.toNumber(topStackHeight);
    bottomStackHeight = _.toNumber(bottomStackHeight);

    if (topStackHeight > topBayConstraints.constrainedMax) topStackHeight = topBayConstraints.constrainedMax;
    if (topStackHeight < topBayConstraints.min) topStackHeight = topBayConstraints.min;
    if (bottomStackHeight > bottomBayConstraints.constrainedMax) bottomStackHeight = bottomBayConstraints.constrainedMax;
    if (bottomStackHeight < bottomBayConstraints.min) bottomStackHeight = bottomBayConstraints.min;

    return {
      top: {
        constraints: topBayConstraints,
        height: topStackHeight,
        manuallyEntered: !!product.customData.topStackHeight
      },
      bottom: {
        constraints: bottomBayConstraints,
        height: bottomStackHeight,
        manuallyEntered: !!product.customData.bottomStackHeight
      }
    };
  },

  getCountertopContainers({product}) {
    var container = Product.get('container', {product});
    var countertopContainers = _.filter(Room.get('containers', {room: Product.get('room', {product})}), {type: 'countertop'});

    return container.position.y < 30 ? _.filter(countertopContainers, countertop => Container.sharesFootprintWith({sourceContainer: countertop, product})) : [];
  },

  getCountertopContainer({product}) {
    return Product.getCountertopContainers({product})[0];
  },

  getIsCooktop({product}) {
    var {title} = Product.get('productType', {product});

    return _.includes(_.lowerCase(title), 'cooktop');
  },

  getIsRangetop({product}) {
    var {title} = Product.get('productType', {product});

    return _.includes(_.lowerCase(title), 'rangetop');
  },

  getIsHood({product}) {
    var {title} = Product.get('productType', {product});

    return _.includes(_.lowerCase(title), 'hood');
  },

  getIsManaged({product}) {
    return _.get(product, 'managedData.managedKey') !== undefined;
  },

  getIsShelfbankComponent({product}) {
    return _.includes([1159, 1160, 1179, 1322, 1564], product.productId);
  },

  getIsShelfbank({product}) {
    return _.includes([1150, 1563], product.productId);
  },

  getIsOpencasePanel({product}) {
    return _.includes([435, 451, 1058, 1135, 1131], product.productId);
  },

  getIsAnyOpencasePanel({product}) {
    return Product.getIsOpencasePanel({product}) || _.includes([1188, 1090, 1717], product.productId);
  },

  getIsApplianceStackComponent({product}) {
    const productType = Product.get('productType', {product});

    return productType.categoryId === 197;
  },

  getIsApplianceStackFrame({product}) {
    const companyKey = Product.get('companyKey', {product});

    return _.includes(K[companyKey].ids.tallCustomApplianceFrameProductIds, product.productId);
  },

  getType({product}) {
    const {companyKey, productType} = Product.get(['companyKey', 'productType'], {product});
    let type = undefined;

    //shelfbanks
    const shelfbankTypes = {1159: 'woodShelf', 1160: 'divider', 1179: 'steelShelf', 1322: 'glassPanel', 1564: 'woodShelf'};
    type = shelfbankTypes[`${product.productId}`];

    const opencaseIds = companyKey === 'hb' ? [435, 451] : [1058, 1135, 1131];

    if (_.includes(opencaseIds, productType.id)) type = 'opencasePanel';
    else if (productType.categoryId === 54) type = 'opencaseComponent';
    else if (_.includes([1144, 1145, 1509, 1146, 1147, 1382, 1383, 1407, 1384, 1419, 1522, 1524, 1525, 1538, 1568], product.productId)) type = 'barblockComponent';
    else if (productType.categoryId === 194) type = 'daylightIslandProduct';
    else if (_.includes([1142, 1148, 1450, 1610], productType.id)) type = _.includes([1142, 1450], productType.id) ? 'verticalBarblock' : 'horizontalBarblock';
    else if (_.includes(K[companyKey].ids.tallCustomApplianceFrameProductIds, productType.id)) type = 'applianceStackFrame';
    else if (_.includes([1150, 1563], productType.id)) type = 'shelfbank';

    return type;
  },

  getIsAssembly({product}) {
    return _.includes([512, 513, 514], product?.productId);
  },

  getHasComponents({product}) {
    return Product.getCanHavePegs({product}) || _.includes(['shelfbank', 'verticalBarblock', 'horizontalBarblock', 'applianceStackFrame', 'opencasePanel'], Product.getType({product}));
  },

  getIsComponentProduct({product}) {
    const isOpencaseComponent = Product.getIsOpencaseComponent({product});
    const isBarblockComponent = Product.getIsBarblockComponent({product});
    const isShelfbankComponent = Product.getIsShelfbankComponent({product});
    const isDaylightIslandProduct = Product.getIsDaylightIslandProduct({product});
    const isPeg = Product.getIsPeg({product});

    return isOpencaseComponent || isBarblockComponent || isShelfbankComponent || isDaylightIslandProduct || isPeg;
  },

  getIsPeg({product}) {
    return _.includes([524, 531, 1626], product.productId);
  },

  getPropsForViewKey({viewKey = 'front', container}) {
    return {
      front: {
        rotation: 0,
        origin: 'bottom-left'
      },
      top: {
        rotation: container.rotation,
        origin: 'top-left'
      }
    }[viewKey];
  },

  getPositionInRoom({product, isNonSpacial, nonSpacialContainerPosition, containerRealPosition}) {
    var {container, companyKey, productType: {inverseZPosition}, parentProduct} = Product.get(['container', 'companyKey', 'productType', 'parentProduct'], {product});

    //TODO some bug with this in Y axis
    var dropzoneInset = lib.trig.rotate({point: Container.getDropzoneInset({container, viewKey: 'top'}), byDegrees: container.rotation, containerRealPosition});
    var containerPosition;

    var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

    if (parentProduct) {
      alignWithBackOfContainer = parentProduct.id <= 1247053 || (Product.get('productType', {product: parentProduct}).inverseZPosition && parentProduct.id > 1324195);
    }

    if (!alignWithBackOfContainer) {
      //HINT orient front of product to front of container

      var containerDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
      var containerDropzoneDepth = (containerDropzoneSize.depth || containerDropzoneSize.height);

      if (container.type === 'baseWithChase') containerDropzoneDepth -= container.dimensions.depth - container.customData.unitDepth;
      if (container.type === 'wallPanel') containerDropzoneDepth -= container.customData.cleatDepth || 0;

      var productDepthDim = product.dimensions.depth;

      if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
        var swapDimKey = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

        productDepthDim = product.dimensions[swapDimKey];
      }

      //HINT opencase components are flush with and extend past the front of the container
      if (Product.getIsOpencaseComponent({product})) {
        productDepthDim = 0;
        containerDropzoneDepth = 0;
      }

      if (Product.getIsPeg({product})) {
        productDepthDim = Product.getIsAssembly({product: parentProduct}) ? (_.get(parentProduct, 'dimensions.depth', container.dimensions.depth) - 1.25) : 0;
      }

      var containerDepthOffset = lib.trig.rotate({point: {x: 0, y: containerDropzoneDepth - productDepthDim}, byDegrees: container.rotation});

      containerPosition = lib.object.sum(containerRealPosition ? containerRealPosition : {x: container.position.x, y: container.position.z}, dropzoneInset, containerDepthOffset);
    }
    else {
      var containerPosition = lib.object.sum(containerRealPosition ? containerRealPosition : {x: container.position.x, y: container.position.z}, dropzoneInset);
    }
    var productPosition = Product.getPosition2d({product, viewKey: 'top'});
    var position = lib.object.sum(containerPosition, productPosition);

    return position;
  },

  getPositionInElevation({product, elevation, scaleX = 1, isProjection, projectionY, isNonSpacial, nonSpacialContainerPosition, overrideSideKey, containerRealPosition}) {
    var position = {x: 0, y: 0};
    var {container, companyKey, productType: {inverseZPosition}, parentProduct} = Product.get(['container', 'companyKey', 'productType', 'parentProduct'], {product});
    var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

    if (parentProduct) {
      alignWithBackOfContainer = parentProduct.id <= 1247053 || (Product.get('productType', {product: parentProduct}).inverseZPosition && parentProduct.id > 1324195);
    }

    if (container) {
      //HINT if container is being positioned in elevation its always front
      const sideKey = containerRealPosition ? 'front' : (overrideSideKey || Product.getSideKey({product, elevation, viewKey: 'front'}));

      const containerPosition3d = {...container.position, y: isProjection ? -projectionY - container.dimensions.height : container.position.y};

      //HINT when containerRealPosition is passed, we're calculating in canvasProduct
      var containerPosition = containerRealPosition ? {x: 0, y: 0} : (isNonSpacial ? nonSpacialContainerPosition : Elevation.getPosition2d({elevation, position3d: containerPosition3d, viewKey: 'front'}));

      const productHeight = _.includes(K[companyKey].ids.horizontalHiddenPanels, product.productId) ? -product.dimensions.depth : -product.dimensions.height;
      var position = lib.object.sum(containerPosition, Product.getPosition2d({product, elevation, viewKey: 'front', scaleX, isNonSpacial, nonSpacialContainerPosition, overrideSideKey}), {y: productHeight});

      if (sideKey === 'front') {
        var containerDropzone = Container.getDropzoneInset({container, viewKey: 'front'});
        position = lib.object.sum(position, {...containerDropzone, x: containerDropzone.x * scaleX});
      }
      else if (_.includes(['left', 'right'], sideKey)) {
        var dropzoneInset = {
          y: Container.getDropzoneInset({container, viewKey: 'front', containerRealPosition}).y
        };

        if (alignWithBackOfContainer) {
          var containerDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
          var containerDropzoneDepth = (containerDropzoneSize.depth || containerDropzoneSize.height);

          if (container.type === 'baseWithChase') containerDropzoneDepth = container.customData.unitDepth;
          if (container.type === 'wallPanel') containerDropzoneDepth -= container.customData.cleatDepth || 0;

          dropzoneInset.x = (sideKey === 'left' ? 1 : -1) * (container.dimensions.depth - containerDropzoneDepth);
        }

        position = lib.object.sum(position, dropzoneInset);
      }
    }

    return position;
  },

  getPositionInView({product, viewKey, elevation, scaleX = 1, isProjection, projectionY, isNonSpacial, nonSpacialContainerPosition, overrideSideKey, containerRealPosition}) {
    const {room} = Product.get(['room'], {product});
    let position;

    if (viewKey === 'top') {
      position = lib.object.sum(Product.getPositionInRoom({product, isNonSpacial, nonSpacialContainerPosition, containerRealPosition}), containerRealPosition ? {} : room.plan.position);
    }
    else {
      position = Product.getPositionInElevation({product, elevation, scaleX, isProjection, projectionY, isNonSpacial, nonSpacialContainerPosition, overrideSideKey, containerRealPosition});
    }

    return position;
  },

  getPosition3d({product}) {
    var {container, parentProduct, productType: {inverseZPosition}, companyKey} = Product.get(['container', 'parentProduct', 'productType', 'companyKey'], {product});

    var position3d = product.position;
    var position2d;
    var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

    if (parentProduct) {
      alignWithBackOfContainer = parentProduct.id <= 1247053 || (Product.get('productType', {product: parentProduct}).inverseZPosition && parentProduct.id > 1324195);
    }

    if (!alignWithBackOfContainer) {
      position2d = {x: position3d.x, y: -position3d.z || 0};
    }
    else {
      position2d = {x: position3d.x, y: position3d.z || 0};
    }

    if (Product.getIsOpencaseComponent({product})) {
      const {gridPosition} = product.customData;
      const positionInOpencase = Opencase.getPositionFor({product, gridPosition});

      position2d.x = positionInOpencase.x;
      position2d.y += container.dimensions.depth;
    }
    else if (Product.getIsBarblockComponent({product})) {
      const wrapInset = Barblock.getWrapInset({product});

      position2d = lib.object.sum(position2d, {x: wrapInset.x});
    }

    if (parentProduct) {
      if (!alignWithBackOfContainer) {
        position2d = lib.object.sum(position2d, {x: parentProduct.position.x, y: -parentProduct.position.z || 0});
      }
      else {
        position2d = lib.object.sum(position2d, {x: parentProduct.position.x, y: parentProduct.position.z || 0});
      }

      if (Product.getIsPeg({product})) {
        var parentProductOffset = 0;

        if (parentProduct && Product.get('productType', {product: parentProduct}).categoryId === 72) {
          parentProductOffset = parentProduct.dimensions.depth - 0.75;
        }

        position2d = lib.object.sum(position2d, {x: 0, y: (!alignWithBackOfContainer ? 0 : parentProduct.dimensions.depth) - parentProductOffset});
      }
    }

    var containerDepthOffset = 0;

    if (!alignWithBackOfContainer) {
      var containerDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
      var containerDropzoneDepth = (containerDropzoneSize.depth || containerDropzoneSize.height);

      if (container.type === 'baseWithChase') containerDropzoneDepth -= container.dimensions.depth - container.customData.unitDepth;
      if (container.type === 'wallPanel') containerDropzoneDepth -= container.customData.cleatDepth || 0;

      var productDepthDim = product.dimensions.depth;

      if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
        var swapDimKey = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

        productDepthDim = product.dimensions[swapDimKey];
      }

      //HINT opencase components are flush with and extend past the front of the container
      if (Product.getIsOpencaseComponent({product})) {
        productDepthDim = 0;
        containerDropzoneDepth = 0;
      }

      if (Product.getIsPeg({product})) {
        productDepthDim = Product.getIsAssembly({product: parentProduct}) ? (_.get(parentProduct, 'dimensions.depth', container.dimensions.depth) - 1.25) : 0;
      }

      containerDepthOffset = containerDropzoneDepth - productDepthDim;
    }

    return {x: position2d.x, y: -product.position.y, z: position2d.y + containerDepthOffset};
  },

  getPosition2d({product, elevation, viewKey = 'top', scaleX = 1, isNonSpacial, nonSpacialContainerPosition, overrideSideKey}) {
    var {container, parentProduct, productType: {inverseZPosition}, companyKey} = Product.get(['container', 'parentProduct', 'productType', 'companyKey'], {product});

    var position3d = product.position;
    var position2d;
    var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

    if (parentProduct) {
      alignWithBackOfContainer = parentProduct.id <= 1247053 || (Product.get('productType', {product: parentProduct}).inverseZPosition && parentProduct.id > 1324195);
    }

    if (viewKey === 'top') {
      if (!alignWithBackOfContainer) {
        position2d = {x: position3d.x, y: -position3d.z || 0};
      }
      else {
        position2d = {x: position3d.x, y: position3d.z || 0};
      }

      if (Product.getIsOpencaseComponent({product})) {
        const {gridPosition} = product.customData;
        const positionInOpencase = Opencase.getPositionFor({product, gridPosition});

        position2d.x = positionInOpencase.x;
        position2d.y += container.dimensions.depth;
      }
      else if (Product.getIsBarblockComponent({product})) {
        const wrapInset = Barblock.getWrapInset({product});

        position2d = lib.object.sum(position2d, {x: wrapInset.x});
      }

      if (parentProduct) {
        if (!alignWithBackOfContainer) {
          position2d = lib.object.sum(position2d, {x: parentProduct.position.x, y: -parentProduct.position.z || 0});
        }
        else {
          position2d = lib.object.sum(position2d, {x: parentProduct.position.x, y: parentProduct.position.z || 0});
        }

        if (Product.getIsPeg({product})) {
          var parentProductOffset = 0;

          if (parentProduct && Product.get('productType', {product: parentProduct}).categoryId === 72) {
            parentProductOffset = parentProduct.dimensions.depth - 0.75;
          }

          position2d = lib.object.sum(position2d, {x: 0, y: (!alignWithBackOfContainer ? 0 : parentProduct.dimensions.depth) - parentProductOffset});
        }
      }

      position2d = lib.math.trig.rotate({position: position2d, byDegrees: Product.getPropsForViewKey({viewKey, container}).rotation});
    }
    else {
      position2d = {x: position3d.x * scaleX, y: position3d.y};
      var sideKey = overrideSideKey || Product.getSideKey({product, viewKey, elevation});
      var isOpencaseComponent = Product.getIsOpencaseComponent({product});
      var isPeg = Product.getIsPeg({product});

      if (isOpencaseComponent) {
        const {gridPosition} = product.customData;

        position2d = Opencase.getPositionFor({product, gridPosition});
      }
      else if (Product.getIsBarblockComponent({product})) {
        const wrapInset = Barblock.getWrapInset({product});

        position2d = lib.object.sum(position2d, wrapInset);
      }

      if (!alignWithBackOfContainer) {
        if (parentProduct) {
          position2d = lib.object.sum(position2d, {x: parentProduct.position.x, y: parentProduct.position.y});
        }

        var productDepthDim = product.dimensions.depth;

        if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
          var swapDimKey = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

          productDepthDim = product.dimensions[swapDimKey];
        }

        var parentProductOffset = 0;

        if (isPeg && parentProduct && Product.get('productType', {product: parentProduct}).categoryId === 72) {
          parentProductOffset = parentProduct.dimensions.depth - 0.75;
        }

        if (sideKey === 'left') position2d.x = container.dimensions.depth - ((isOpencaseComponent || isPeg) ? 0 : productDepthDim) - position3d.z + (parentProduct ? (-parentProduct.position.z - parentProductOffset) : 0);
        if (sideKey === 'right') position2d.x = -container.dimensions.depth + ((isOpencaseComponent || isPeg) ? -productDepthDim : 0) + position3d.z - (parentProduct ? (-parentProduct.position.z - parentProductOffset) : 0);
      }
      else {
        if (parentProduct) {
          position2d = lib.object.sum(position2d, {x: parentProduct.position.x, y: parentProduct.position.y});
        }

        var pegOffset = 0;

        if (Product.getIsPeg({product})) {
          if (sideKey === 'left') pegOffset = parentProduct.dimensions.depth;
          if (sideKey === 'right') pegOffset = -parentProduct.dimensions.depth;
        }

        var productDepthDim = product.dimensions.depth;

        if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
          var swapDimKey = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

          productDepthDim = product.dimensions[swapDimKey];
        }

        var parentProductOffset = 0;

        if (isPeg && parentProduct && Product.get('productType', {product: parentProduct}).categoryId === 72) {
          parentProductOffset = parentProduct.dimensions.depth - 0.75;
        }

        if (sideKey === 'left') position2d.x = position3d.z + pegOffset + (parentProduct ? (parentProduct.position.z - parentProductOffset) : 0);
        if (sideKey === 'right') position2d.x = -productDepthDim - position3d.z + pegOffset - (parentProduct ? (parentProduct.position.z - parentProductOffset) : 0);
      }
      if (sideKey === 'back') position2d.x = -product.dimensions.width;
    }

    return position2d;
  },

  getFootprintInRoom({product, sinkProps}) {
    var {container, productType: {inverseZPosition}, parentProduct} = Product.get(['container', 'productType', 'parentProduct'], {product});
    var parentPosition = Container.getChildrenPositionInRoom({container});
    var parentRotation = container.rotation;
    var position;
    var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

    if (parentProduct) {
      alignWithBackOfContainer = parentProduct.id <= 1247053 || (Product.get('productType', {product: parentProduct}).inverseZPosition && parentProduct.id > 1324195);
    }

    if (!alignWithBackOfContainer) position = lib.object.sum({x: product.position.x, y: -product.position.z}, parentPosition);
    else position = lib.object.sum({x: product.position.x, y: product.position.z}, parentPosition);

    var {width, depth} = product.dimensions;
    var {x, y} = position;

    var sinkInset = 0;

    if (sinkProps) {
      sinkInset = (width / 2) - (sinkProps.width / 2);
    }

    var vertices = [
      {x: x + sinkInset, y: y},
      {x: x + sinkInset, y: y + depth},
      {x: x + width - sinkInset, y: y + depth},
      {x: x + width - sinkInset, y: y}
    ];

    vertices = vertices.map(point => lib.math.trig.rotate({point, aroundOrigin: parentPosition, byDegrees: parentRotation}));

    return vertices;
  },

  getFootprintLines({product, container, includeCenterline = false}) {
    var center;
    var footprintInRoom = Product.getFootprintInRoom({product});

    if (includeCenterline) {
      if (!container) container = Product.get('container', {product});

      center = {
        from: lib.trig.translate({point: footprintInRoom[0], by: product.dimensions.width / 2, alpha: Container.getAlpha({container})}),
        to: lib.trig.translate({point: footprintInRoom[1], by: product.dimensions.width / 2, alpha: Container.getAlpha({container})}),
      };
    }

    return {
      left: {from: footprintInRoom[0], to: footprintInRoom[1]},
      front: {from: footprintInRoom[1], to: footprintInRoom[2]},
      right: {from: footprintInRoom[2], to: footprintInRoom[3]},
      back: {from: footprintInRoom[3], to: footprintInRoom[0]},
      ...(includeCenterline ? {center} : {})
    };
  },

  getWallprint({product, elevation, isProjection, projectionY}) {
    const productPositionInView = _.pick(Product.getPositionInView({product, viewKey: 'front', elevation, isProjection, projectionY}), ['x', 'y']);

    var visibleWidth = _.clone(product.dimensions.width);
    var visibleHeight = _.clone(product.dimensions.height);

    if (_.includes([...K['vp'].ids.verticalHiddenPanels, ...K['hb'].ids.verticalHiddenPanels, ...K['vp'].ids.horizontalHiddenPanels, ...K['hb'].ids.horizontalHiddenPanels], product.productId)) {
      var swapDimKey = _.includes([...K['vp'].ids.verticalHiddenPanels, ...K['hb'].ids.verticalHiddenPanels], product.productId) ? 'width' : 'height';

      if (swapDimKey === 'height') visibleHeight = product.dimensions.depth;
      if (swapDimKey === 'width') visibleWidth = product.dimensions.depth;
    }
    //starting top left going clockwise
    return [
      productPositionInView,
      lib.object.sum(productPositionInView, {x: visibleWidth}),
      lib.object.sum(productPositionInView, {x: visibleWidth, y: visibleHeight}),
      lib.object.sum(productPositionInView, {y: visibleHeight})
    ];
  },

  getWallprintLines(args) {
    var wallprint = Product.getWallprint(args);

    return {
      left: {from: wallprint[3], to: wallprint[0]},
      right: {from: wallprint[1], to: wallprint[2]},
      top: {from: wallprint[0], to: wallprint[1]},
      bottom: {from: wallprint[2], to: wallprint[3]}
    };
  },

  getXRange({product}) {
    var {position, dimensions} = product;

    return {from: position.x, to: position.x + dimensions.width};
  },

  getIntersectsCountertop({product}) {
    var {id, title} = Product.get('productType', {product});

    var isRange = _.includes([436], id);
    var isRangetop = _.includes(_.lowerCase(title), 'rangetop');
    var isCooktop = _.includes(_.lowerCase(title), 'cooktop');

    return Product.getHasSink({product}) || isRange || isRangetop || isCooktop;
  },

  getHasSink({product}) {
    const productType = Product.get('productType', {product});
    var productIds = [45, 46, 47, 48, 49, 50, 89, 90, 91, 92, 93, 1436, 1468, 1469, 1482, 1483, 1484, 1485, 1726, 1727, //HB
      911, 941, 943, 942, 944, 1395, 945, 1171, 908, 909, 910, 907, 1395, 1436, 1215, 1216, 1217, 1219, 1220, 1360, 873, 1599 //ST
    ];

    return _.includes(productIds, productType.id) || _.includes(productIds, productType.legacyId);
  },

  getSideKey({product, viewKey, elevation, container, parentProduct}) {
    var {container, parentProduct} = Product.get(['container', 'parentProduct'], {product});

    if (parentProduct) {
      container = Product.get('container', {product: parentProduct});
    }

    return !container ? 0 : Container.getSideKey({container, viewKey, elevation});
  },

  getHasDoorStorage({product}) {
    var hasDoorStorageOption = false;
    var doorStorageProductIds = [554, 555, 550, 551, 552, 553, 548, 549, 556, 557, 1412, 1413, 598, 599, 600, 603, 604, 601, 670, 602, 671, 596, 594, 593, 597, 595, 591, 592, 240, 241, 606, 605, 243, 607, 608, 242, 609, 610, 244, 245];

    if (_.includes(doorStorageProductIds, product.productId)) {
      hasDoorStorageOption = true;
    }
    else {
      var productOptionInstances = Product.get('productOptionInstances', {product});

      _.forEach(productOptionInstances, ({productOptionId}) => {
        if (_.includes([4, 14, 121, 122, 123, 124, 125, 126, 127], productOptionId)) {
          hasDoorStorageOption = true;
        }
      });
    }

    return hasDoorStorageOption;
  },

  getFlybySizes({product, viewKey}) {
    var container = Product.get('container', {product});
    var flyby = _.get(container, 'customData.flyby') || {};
    var wrap = Product.getWrapSizes({product});
    var hinge = Product.getHingeData({product});

    var data = {};

    _.forEach(['left', 'top', 'right', 'bottom'], sideKey => {
      if (!hinge[sideKey]) {
        var axis = _.includes(['left', 'right'], sideKey) ? 'end' : 'cap';
        var isCustomFlyBy = _.get(product, `customData.flyBys.${sideKey}`) === 'custom';

        if (isCustomFlyBy) {
          data[sideKey] = _.get(product, `customData.flyBys.${sideKey}Custom`, 0);
        }
        else if (_.get(product, `customData.flyBys.${sideKey}`)) {
          data[sideKey] = _.get(product, `customData.flyBys.${sideKey}`);
        }
        else if (flyby[axis] && wrap[sideKey] && Product.isOnSide({product, sideKey, viewKey})) {
          data[sideKey] = (wrap[`${sideKey}Thickness`] || wrap['thickness'] || 0);
        }
        else {
          data[sideKey] = 0;
        }
      }
      else {
        data[sideKey] = 0;
      }
    });

    return data;
  },

  getOutletPullCanBeCentered({product}) {
    var {dependencies, companyKey} = Product.get(['dependencies', 'companyKey'], {product});
    var pullType = product.details['drawer-0-pullType'] || product.details['pullType'];
    var pullIsCompatibleWithOption = false;

    var pullId = _.get(pullType, 'id');
    var pull = dependencies.pulls.byId[pullId];

    if (pull) {
      var compatibilityThreshold = pull.isMortised ? 1 : 2;

      var pullDimensions = K[companyKey].pullDimensions[pullId];

      if (pullDimensions) {
        //HINT check that pull will not be within threshold of outlet if centered, outlet is 8" wide
        pullIsCompatibleWithOption = (product.dimensions.width / 2 - pullDimensions.width / 2 - 8) >= compatibilityThreshold;
      }
    }

    return pullIsCompatibleWithOption;
  },

  //TODO handle units with orientation/door swing options
  getHingeData({product, productType}) {
    if (!productType) productType = Product.getProductData({product});

    var hingeData = _.clone(productType.hingeData || {});

    if (productType.hasDoorActionOption) {
      if (product.customData.doorAction === 'right') {
        hingeData.right = 1;
        hingeData.left = 0;
      }
      else {
        hingeData.right = 0;
        hingeData.left = 1;
      }
    }

    // if (productType.hasDoorOrientationOption) {
    //   if (product.customData.orientation === 'right') {
    //     hingeData.right = 1;
    //     hingeData.left = 0;
    //   }

    //   if (product.customData.orientation === 'left') {
    //     hingeData.right = 1;
    //     hingeData.left = 0;
    //   }
    // }

    return hingeData;
  },

  getWrapSizes({product}) {
    var container = Product.get('container', {product});

    return _.get(container, 'customData.wrap') || {};
  },

  isOnSide({product, sideKey, viewKey}) {
    var container = Product.get('container', {product});
    var {position} = product;
    var dims = product.dimensions;
    var borders = Container.getDropzoneSize({container});

    // if (viewKey === 'front') {
    //   var dropzoneSize = Container.getDropzoneSize({container});

    //   var min = {x: 0, y: -dropzoneSize.height + dims.height};
    //   var max = {x: dropzoneSize.width - dims.width, y: 0};

    //   position = {...lib.object.min(lib.object.max(position, min), max)};
    // }

    if (sideKey === 'left') {
      return position.x === 0;
    }
    if (sideKey === 'top') {
      return borders.height === dims.height - position.y;
    }
    if (sideKey === 'right') {
      return borders.width === position.x + dims.width;
    }
    if (sideKey === 'bottom') {
      return 0 === position.y;
    }
  },

  //<Appliances
  getIsAppliance({product}) {
    return Product.getIsCooktop({product}) || Product.getIsRangetop({product}) || Product.getIsHood({product}) || _.some(Product.getApplianceProductIds(), applianceId => applianceId === product.productId);
  },

  getIsOvenWithDrawersUnder({product}) {
    return _.includes([121, 122, 123, 125, 1225, 1247, 1226, 1230, 1234, 1235, 1239, 1240, 1277, 1278, 122, 123, 124, 126, 121, 1132, 1133, 759, 760, 764, 765, 1659, 1746], product.productId);
  },

  getUnderOvenShelfHeights({product}) {
    var drawerHeights = _.get(product.customData, 'ovenDrawerHeights', '').split(',').map(drawerHeight => _.toNumber(drawerHeight));

    return _.reject(drawerHeights, drawerHeight => !drawerHeight);
  },

  getAppliancesDisplayData({product}) {
    var applianceInstancesData = _.map(Product.getApplianceInstancesData({product}), ({fit, compatibleAppliances}, index) => {
      var {id, dimensions = {}, customHeight, primaryDisplayColor, secondaryDisplayColor, opacity, panelType} = _.get(product, `appliancesData.${index}`, {});

      if (customHeight) _.set(dimensions, 'height', customHeight);

      if (fit === 'fitted') {
        var defaultAppliance = _.find(compatibleAppliances, ({id}) => id !== 0);

        var appliance = (id === 0 ? defaultAppliance : _.find(compatibleAppliances, {id})) || defaultAppliance;

        //HINT need to clone appliance dimensions so we don't override the appliance data in compatibleAppliances
        //HINT causes bugs for multi-stack appliances
        if (customHeight) appliance = {...appliance, customHeight, dimensions: {..._.get(appliance, 'dimensions'), height: customHeight}};

        if (!id && panelType) appliance = {...appliance, panelType};

        appliance = {...appliance, primaryDisplayColor, secondaryDisplayColor, opacity};

        return appliance;
      }
      else {
        if (_.every(dimensions, dimension => dimension === 0)) dimensions = undefined;

        return {dimensions, primaryDisplayColor, secondaryDisplayColor, opacity};
      }
    });

    if (product.productId === 1132) {
      var {customHeight, primaryDisplayColor, secondaryDisplayColor, opacity} = _.get(product, 'appliancesData.1', {});

      applianceInstancesData.push({customHeight, primaryDisplayColor, secondaryDisplayColor, opacity});
    }

    return applianceInstancesData;
  },

  getApplianceQuantity({product}) {
    var {productId} = product;

    var quantity = 1;
    if (_.includes([123, 124, 58, 693, 760, 761, 765, 1220, 1222, 1235, 1240, 1277, 1360, 1661, 1672, 1599, 1723], productId)) quantity = 2;
    if (_.includes([125, 126, 1278, 1133], productId)) quantity = 3;

    //HINT extra large cooktop that has variable number of appliances
    if (_.includes([1586], productId)) {
      var quantity = product.customData.applianceQuantity || 1;
    }

    //HINT add extra appliance for downdraft
    if (_.includes([918, 1376, 1566], product.productId) || (_.includes([56], product.productId) && product.customData.hasDowndraft) || (_.includes([67, 65, 1230], product.productId) && product.customData.hasCooktop)) {
      quantity++;
    }

    return quantity;
  },

  //TODO this should be filtering more based on types and subtypes
  getApplianceInstancesData({product}) {
    var applianceInstancesData = [];
    var {productId} = product;

    var fit = Product.getApplianceFit({product});
    var applianceType = Product.getApplianceType({product});

    var quantity = Product.getApplianceQuantity({product});
    var productPanelQuantity = Product.getAppliancePanelQuantity({product});
    //TODO
    var compatibleAppliances = _.map(Product.get('appliances', {product}));
    var hasSink = Product.getHasSink({product});

    var isStFridgeHousingW2Fronts = product.productId === 1354;

    compatibleAppliances = _.filter(compatibleAppliances, compatibleAppliance => {
      return isStFridgeHousingW2Fronts ? _.includes([496, 497, 498], compatibleAppliance.id) : !_.includes([496, 497, 498], compatibleAppliance.id);
    });

    if (fit === 'fitted') {
      compatibleAppliances = _.filter(compatibleAppliances, appliance => {
        var isCompatible = false;
        var compatibleType = appliance.type === applianceType;

        if (hasSink) {
          var compatibleDimensions = appliance.dimensions.height <= product.dimensions.height &&
            _.every(['width', 'depth'], sizeKey => appliance.dimensions[sizeKey] <= (product.dimensions[sizeKey] - 4));

          isCompatible = compatibleType && compatibleDimensions;
        }
        else {
          var panelQuantity = 0;

          if (_.includes(['column', 'subZeroIntegrated', 'framedUndercounter', 'framedColumn'], appliance.panelType)) panelQuantity = 1;
          if (_.includes(['overUnder26.5', 'overUnder28.5', 'overUnderDoors28.5', 'overUnder30', 'doubleDrawer'], appliance.panelType)) panelQuantity = 2;
          if (_.includes(['frenchDoor28.5', 'frenchDoor30', 'overDoubleUnder', 'framedOverDoubleUnder'], appliance.panelType)) panelQuantity = 3;

          var compatiblePanelQuantity = productPanelQuantity === panelQuantity || _.includes([754, 1103, 1182], productId);

          var productFrontType = _.includes([785, 786, 787], productId) ? 'framed' : 'panel';
          var applianceFrontType = appliance.framedFront ? 'framed' : 'panel';

          var compatiblePanelType = productFrontType === applianceFrontType;

          var compatibleDimensions = _.every(['height', 'depth', 'width'], sizeKey => {
            var fits = appliance.dimensions[sizeKey] <= product.dimensions[sizeKey];
            var fitsSnuggly = true;

            if (sizeKey === 'width') {
              fitsSnuggly = (product.dimensions[sizeKey] - appliance.dimensions[sizeKey]) <= 5;
            }

            return fits && fitsSnuggly;
          });

          if (appliance.type === 'insertHood' && compatibleType) {
            //insert hoods just have min values for all dimensions
            compatibleDimensions = _.every(['height', 'width', 'depth'], sizeKey => appliance.dimensions[sizeKey] <= product.dimensions[sizeKey]);
          }

          if (appliance.type === 'slideOutHood' && compatibleType) {
            //slideouts should have matching width and depth, height has a min
            compatibleDimensions = appliance.dimensions.height <= product.dimensions.height &&
              _.every(['width', 'depth'], sizeKey => appliance.dimensions[sizeKey] === product.dimensions[sizeKey]);
          }

          isCompatible = compatiblePanelQuantity && compatibleType && compatiblePanelType && compatibleDimensions;
        }

        var isCurrentlySelected = _.some(product.appliancesData, applianceData => applianceData && applianceData.id === appliance.id);

        return isCompatible && (!appliance.isDiscontinued || isCurrentlySelected);
      });
    }

    var applianceNotSelected = {
      vendor: '',
      subType: '',
      panelType: '',
      type: '',
      modelNumber: 'TBD',
      id: 0,
      title: 'Appliance not yet selected',
      dimensions: {}
    };

    compatibleAppliances.unshift(applianceNotSelected);

    _.times(quantity, index => {
      var filteredCompatibleAppliances = _.filter(compatibleAppliances, appliance => {
        return _.every(['vendor', 'subType', 'panelType'], key => {
          var filterValue = _.get(product, `appliancesData.${index}.${key}`);

          return appliance.id !== 0 && (!filterValue || appliance[key] === filterValue);
        });
      });

      if (filteredCompatibleAppliances.length > 0) {
        filteredCompatibleAppliances.unshift(applianceNotSelected);
      }

      applianceInstancesData.push({fit, compatibleAppliances, filteredCompatibleAppliances});
    });

    return applianceInstancesData;
  },

  //TODO This function should be removed when products appliance_types have been updated
  //for now this is necessary
  getApplianceProductIds() {
    return [64, 65, 66, 67, 121, 122, 169, 170, 171, 172, 123, 124, 125, 747, 748, 755, 758, 752, 753, 754, 761, 762, 763, 777, 778, 779, 764, 765, 746, 759, 760, 749, 750, 751, 776, 777, 756, 757, 780, 785, 786, 787, 1105, 1182, 1103, 1132, 1133, 1182, 1183, 1197, 1222, 1223, 1224, 1225, 1226, 1227, 1230, 1234, 1235, 1239, 1240, 1241, 1242, 1244, 1245, 1246, 1247, 1274, 1276, 1277, 1372, 1374, 1386, 1391, 1420, 1424, 1426, 1499, 1516, 1149, 1535, 1560, 1574, 1586, 1354, 1616, 1638, 1659, 1660, 1661, 1668, 1672, 1703, 1364, 1723, 1746];
  },

  getApplianceFit({product}) {
    var fit;
    var productId = product.productId;

    if (_.includes([65, 66, 121, 123, 125, 126, 169, 170, 171, 172, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757, 758, 759, 760, 785, 786, 787, 788, 1132, 1133, 1177, 1178, 1182, 1183, 1103, 1105, 1149, 1182, 1197, 1224, 1225, 1226, 1227, 1230, 1234, 1235, 1245, 1246, 1247, 1274, 1276, 1277, 1278, 1354, 1372, 1374, 1386, 1391, 1420, 1424, 1426, 1499, 1560, 1574, 1612, 1616, 1638, 1659, 1660, 1661, 1668, 1672, 1703, 1364, 1723, 1746], productId)) fit = 'fitted';
    else if (_.includes([58, 64, 67, 122, 124, 761, 762, 763, 764, 765, 776, 777, 779, 1222, 1239, 1240, 1241, 1244, 1242, 1516, 1535], productId)) fit = 'universal';

    if (Product.getHasSink({product})) {
      var isByOthers = _.get(product, 'customData.isByOthers', 0);

      if (Product.get('companyKey', {product}) === 'hb') isByOthers = true;

      fit = isByOthers ? 'universal' : 'fitted';
    }

    return fit;
  },

  getApplianceType({product, index, showDisplayedApplianceType = false}) {
    var applianceType;
    var productId = product.productId;
    var applianceData = _.get(product, `appliancesData.${index ? index : 0}`, {});
    var displayedApplianceType = _.get(applianceData, 'displayedApplianceType', '');

    //double and triple oven don't work yet
    //if (_.includes([1133], productId)) applianceType = 'tripleOven';
    if (_.includes([1132], productId)) applianceType = 'doubleOven';
    if (_.includes([66, 67, 121, 122, 123, 124, 125, 126, 58, 755, 758, 759, 760, 788, 1133, 1230, 1234, 1235, 1239, 1240, 1277, 1278, 1391, 1426, 65, 1659, 1661, 1672, 1723], productId) || (index === 0 && _.includes([693, 761, 1222], productId))) applianceType = 'oven'; //TODO better name than applianceType?
    if (_.includes([747, 746, 749, 750, 751, 752, 753, 785, 786, 787, 1183, 1197, 1223, 1225, 1226, 1227, 1245, 1246, 1247, 1354, 1372, 1374, 1386, 1420, 1424, 1499, 1560, 1574, 1616, 1638, 1668, 1703, 1364, 1746], productId)) applianceType = 'refrigerator';
    if (_.includes([437, 748, 754, 1182, 1103, 1105, 1224, 1274, 1276, 1660, 1548], productId)) applianceType = 'dishwasher';
    if (_.includes([756, 757, 170, 172, 1149], productId)) applianceType = 'insertHood';
    if (_.includes([1177, 1178, 169, 171, 1612], productId)) applianceType = 'slideOutHood';

    if (Product.getHasSink({product})) {
      applianceType = 'sink';
    }
    else if (Product.getIsCooktop({product}) && !applianceType) {
      applianceType = 'cooktop';
    }
    else if (Product.getIsRangetop({product}) && !applianceType) {
      applianceType = 'rangetop';
    }
    else if (Product.getIsHood({product}) && !applianceType) {
      applianceType = 'hood';
    }

    if (showDisplayedApplianceType) {
      if (_.includes([918, 1376, 1566, 56], productId) && index !== 0 && index === Product.getApplianceQuantity({product}) - 1) {
        applianceType = 'downdraft';
      }

      if (_.includes([67, 65, 1230], productId) && index !== 0 && index === Product.getApplianceQuantity({product}) - 1) {
        applianceType = 'cooktop';
      }

      var appliances = Product.get('appliances', {product});
      var appliance = _.find(appliances, {id: applianceData.id});

      if (product.customData.isPreCustomApplianceUpdate) {
        if (displayedApplianceType) {
          applianceType = displayedApplianceType;
        }
        else {
          if (applianceData.isCustomAppliance !== 1) {
            if (appliance) {
              if (appliance && appliance.subType) applianceType = appliance.subType;
            }
          }
          else {
            applianceType = displayedApplianceType || applianceType;
          }
        }
      }
      else {
        if (Product.getApplianceFit({product}) === 'fitted' && applianceData.isCustomAppliance !== 1) {
          if (appliance) {
            if (appliance && appliance.subType) applianceType = appliance.subType;
          }
        }
        else {
          applianceType = displayedApplianceType || applianceType;
        }
      }
    }

    return applianceType;
  },

  getAppliancePanelQuantity({product}) {
    var panelQuantity = 0;

    if (_.includes([751, 753, 754, 785, 786, 1182, 1227, 1183, 1245, 1246, 1276], product.productId)) panelQuantity = 1;
    if (_.includes([750, 752, 1103, 1197, 1226, 1274, 1354, 1668], product.productId)) panelQuantity = 2;
    if (_.includes([749, 787, 1225, 1372, , 1386, 1420, 1424, 1560, 1574, 1638], product.productId)) panelQuantity = 3;
    if (_.includes([1374, 1499, 1616, 1746], product.productId)) panelQuantity = 4;

    return panelQuantity;
  },

  getIsOpencaseComponent({product}) {
    const productType = Product.get('productType', {product});

    return productType.categoryId === 54;
  },

  getIsHorizontalBarblockComponent({product}) {
    return _.includes([1144, 1147, 1382, 1383, 1384, 1522, 1538, 1568, 1670], product.productId);
  },

  getIsVerticalBarblockComponent({product}) {
    return _.includes([1145, 1146, 1407, 1419, 1509, 1524, 1525], product.productId);
  },

  getIsBarblockComponent({product}) {
    return Product.getIsHorizontalBarblockComponent({product}) || Product.getIsVerticalBarblockComponent({product});
  },

  getIsBarblockFrame({product}) {
    return _.includes([1142, 1148, 1450, 1610], product.productId);
  },

  getIsHorizontalBarblock({product}) {
    return product.productId === 1148 || product.productId === 1610;
  },

  getIsDaylightIslandProduct({product}) {
    const productType = Product.get('productType', {product});

    return productType.categoryId === 194;
  },

  //Appliances/>
  getOwnedCompatibleDetails({product, renderForDrawings}) {
    var {productType, companyKey, dependencies, project, room, isEmployee, productOptionInstances, parentProduct} = Product.get(['companyKey', 'productType', 'dependencies', 'isEmployee', 'project', 'room', 'parentProduct', 'productOptionInstances'], {product});
    var {materialAssociations, hasPulls, associations} = Product.get('productType', {product});
    var {pulls, materialClasses, materialTypes} = dependencies;
    var subproductData = Product.getSubproductData({product, productType});
    materialTypes = materialTypes.byId;
    pulls = pulls.byId;
    materialClasses = _.values(materialClasses.byId);
    var flattenedMaterials = _.flatMap(materialClasses, materialClass => materialClass.materials);
    var isHorizontalBarblockOrComponent = Product.getIsHorizontalBarblockComponent({product}) || Product.getIsHorizontalBarblock({product});

    var details = [];

    var framedFrontProductIds = [1245, 1246, 1247, 395, 396, 397, 398, 785, 786, 787];

    var hasAppliedBrass = Product.getHasAppliedBrass({product, productType});

    var materialAndPullDetailsFor = ({subproductComponent, isSubproductControl, key = '', n}) => {
      var detailMaterialAssociations = {...materialAssociations};

      var frontPanelType = _.get(product, 'details.frontPanelType.id', 'flat');

      if (isSubproductControl) {
        frontPanelType = _.get(product, `details.${key}-frontPanelType.id`) || frontPanelType;
      }

      if (companyKey === 'hb' && _.includes(frontPanelType, 'Glass')) {
        detailMaterialAssociations = {...detailMaterialAssociations, glass: {title: 'Glass', materialClassId: 22}};
      }

      if (companyKey === 'hb' && materialAssociations.box && !materialAssociations.boxBack && product.customData?.boxBackDifferentFromBox) {
        detailMaterialAssociations = {...detailMaterialAssociations, boxBack: {title: 'Box Back', materialClassId: 157}};
      }

      if (hasPulls) {
        var currentPullId = _.get(product, 'details.pullType.id');

        if (isSubproductControl) {
          currentPullId = _.get(product, `details.${key}-pullType.id`) || currentPullId;
        }

        if (companyKey === 'vp' && !_.includes([6, 7, 9, 10], currentPullId)) { //HINT No Pulls and Pulls by others ID) {
          var pullMaterialClassId = 67;

          //knurled knob pull from aluminum and brass
          if (currentPullId === 3) pullMaterialClassId = 143;
          //wire radius pull from brass only
          if (currentPullId === 8) pullMaterialClassId = 142;

          detailMaterialAssociations = {...detailMaterialAssociations, pull: {materialClassId: pullMaterialClassId, title: 'Pull'}};
        }
        else {
          var currentPull = _.find(pulls, {id: currentPullId});

          if (currentPull) {
            if (_.find(materialClasses, {id: currentPull.materialClassId})) {
              detailMaterialAssociations = {...detailMaterialAssociations, pull: {materialClassId: currentPull.materialClassId, title: 'Pull'}};
            }
          }
        }
      }

      var materialAssociationsOptions = [];

      if (product.productId === 512) {
        if (!product.customData.hasHooks) delete detailMaterialAssociations.hook;
        if (!product.customData.hasShoeShelf) delete detailMaterialAssociations.shoeShelf;
      }

      _.forEach(detailMaterialAssociations, ({materialClassId, title}, key) => {
        var description = {
          box: 'Many units have a back, top, bottom, and sides that consist of the same material.',
          front: 'Many units have drawer/door fronts that are a different material than the rest of the unit.'
        }[key] || '';

        var options = _.filter(_.map(lib.materials.materialsFor({materialClassId, materialClasses: _.values(dependencies.materialClasses.byId)
        }), material => {
          return _.pick(material, ['id', 'title', 'isEmployeeOnly', 'employeeOnlyMaterialClassIds', 'materialTypeId', 'companyKey', 'isDiscontinued']);
        }), material => companyKey === 'hb' ? material.companyKey === companyKey : true);

        var isPostDiscontinuation = project.versionId > 23503 && !_.includes([7653, 9184], project.id) && !product.customData.allowDiscontinuedMaterials;

        if (key === 'box') {
          var projectPricingDate = moment(project.pricingDate).unix();
          var clearStainedVeneerForkDate = moment('2025-02-03').unix();

          if (projectPricingDate >= clearStainedVeneerForkDate) {
            options = _.map(options, option => {
              return {
                ...option,
                isDiscontinued: option.isDiscontinued || (productType.boxPricingStrategy === 'twoTier' ? _.includes([483, 484, 485, 486], option.id) : _.includes([358, 235, 131], option.id))
              };
            });
          }
        }

        options = _.map(options, option => {
          var isCurrentlySelected = _.some(product.details, (detail, detailKey) => _.includes(detailKey, `${key}Material`) && _.get(detail, 'id') === option.id);

          var shouldHide = isPostDiscontinuation && !isCurrentlySelected && option.isDiscontinued;
          var invalid = option.isDiscontinued;

          return {...option, shouldHide, invalid};
        });

        if (!isEmployee) {
          var options = _.filter(options, ({isEmployeeOnly, employeeOnlyMaterialClassIds}) => !isEmployeeOnly && !_.includes(employeeOnlyMaterialClassIds, `.${materialClassId}.`));
        }

        if (_.includes(['front', 'panel'], key) && (_.includes(frontPanelType, 'FramedPanel') || (parentProduct && _.includes(_.get(parentProduct, 'details.frontPanelType.id'), 'FramedPanel'))) && project.id !== 8973) {
          options = _.filter(options, option => {
            return _.includes(K.frameAndPanelCompatibleFrontMaterials, option.id) || ((_.get(product, 'details.frontMaterial.id') === option.id || _.includes(K.projectsUsingOldFrameAndPanelStandard, project.id)) && _.includes([1, 8, 154, 158, 358,], option.id));
          });
        }

        //HINT now grouping by material type, so - color matched edge isn't needed, but production still needs it
        options = _.map(options, option => {
          return {...option,
            title: renderForDrawings ? option.title : _.replace(_.replace(option.title, ' - color matched edge', ''), ' - Color Matched Edge', ''),
            thumbnail: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/${option.id}.jpg`
          };
        });

        // if (companyKey === 'hb') {
        //   var excludedMaterialIds = [ //HINT ST material options were added to HB material classes, not sure if this was intentional
        //     239, 240, 251, 241, //duplicate ST veneers
        //     242, 243 //duplicate ST acrylic
        //   ];

        //   options = _.filter(options, ({id, isDiscontinued}) => !_.includes(excludedMaterialIds, id) && isDiscontinued === 0);
        // }

        if (_.includes([K[companyKey].ids.scribe.productIdTypeMap['recessed'], K[companyKey].ids.kick.defaultProductId], product.productId) || key === 'boxBack' || key === 'back') {
          options = _.map(options, option => {
            var stringsToRemove = [' - Black Core', ' - black core', ' - color matched edge', ' - Color Matched Edge'];

            _.forEach(stringsToRemove, string => {
              option.title = _.replace(option.title, string, '');
            });

            return option;
          });
        }

        title = title || _.startCase(key);

        //HINT the europly materials are only supposed to be applied to these products
        //HOWEVER there are old projects where we still need to allow them to be chosen
        var semiDiscontinuedEuroplyMaterialIds = [252, 256, 258, 259, 376, 377, 378, 379, 380];
        var compatibleEuroplyProductIds = [162, 163, 164, 165, 166, 167, 1379, 406, 1451, 1523, 470, 435, 451, 1090, 1058, 1070, 1370, 680, 978, 1563, 678, 1717];

        if (!_.includes(compatibleEuroplyProductIds, product.productId)) {
          options = _.map(options, option => {
            if (_.includes(semiDiscontinuedEuroplyMaterialIds, option.id)) {
              option.invalid = true;
            }

            return option;
          });
        }

        if (hasAppliedBrass && key === 'front') {
          options = _.filter(options, option => {
            return lib.materials.getIsVeneer({material: option});
          });
        }

        if (options.length > 0) {
          let optionGroups = _.map(_.groupBy(options, 'materialTypeId'), (options, materialTypeId) => {
            return {title: _.get(materialTypes[materialTypeId], 'title', materialTypeId), options};
          });

          materialAssociationsOptions.push({key, title, optionGroups, options, description});
        }
      });

      var materialAndPullDetails = [];

      _.forEach(materialAssociationsOptions, materialAssociationData => {
        //HINT need to have a flattened list of options for detail updates
        if (!(materialAssociationData.key === 'box' && isSubproductControl) && !(_.get(subproductComponent, 'type') === 'panel' && materialAssociationData.key === 'pull')) {
          details.push({
            key: `${key ? `${key}-` : ''}${materialAssociationData.key}Material`,
            type: 'material',
            titlePrefix: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: ` : '',
            title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: ${_.startCase(materialAssociationData.title)} Material` : `${_.startCase(materialAssociationData.title)} Material`,
            isSubproductControl,
            ..._.pick(materialAssociationData, ['description', 'optionGroups', 'options'])
          });
        }
      });

      if (hasPulls && _.get(subproductComponent, 'type') !== 'panel') {
        var pullDetailsFor = () => {
          var pullDetails = [];
          var pullTypeOptions = [];

          if (companyKey === 'hb') {
            var validPullTypes = _.filter(pulls, pull => {
              var defaultFrontMaterialId = _.get(_.find(details, {key: 'frontMaterial'}), 'options.0.id');
              var frontMaterialId = _.get(product, 'details.frontMaterial.id', defaultFrontMaterialId); //TODO verify

              if (isSubproductControl) {
                frontMaterialId = _.get(product, `details.${key}-frontMaterial.id`) || frontMaterialId;
              }

              var frontMaterialClass = _.find(materialClasses, {id: pull.compatibleFrontMaterialClassId});

              var frontMaterialIsCompatible = (pull.compatibleFrontMaterialClassId === 1 || (frontMaterialClass && _.find(frontMaterialClass.materials, {id: frontMaterialId})));
              var productIsCompatible = true;

              //HINT no scooped bottom for hoods
              if (pull.id === 23) {
                productIsCompatible = !_.includes([169, 170, 171, 172, 173, 439, 1149], product.productId);
              }

              //HINT only sliding glass unit uses the sliding glass pull
              if (_.includes([160], product.productId)) {
                productIsCompatible = _.includes([19], pull.id);
              }

              //HINT scooped bottom only for applied brass
              if (!_.includes([23], pull.id)) {
                productIsCompatible = productIsCompatible && (!hasAppliedBrass || product.customData.hasShopDrawing);
              }

              //HINT no cirle pulls for framed panels (knobs and i pulls are allowed, specifically the circle pull is not)
              if (_.includes([16], pull.id) && project.id !== 8973) {
                productIsCompatible = productIsCompatible && !_.includes(['beveledFramedPanel', 'squareFramedPanel'], _.get(product, 'details.frontPanelType.id', 'flat'));
              }

              if (productType.preventTouchLatchPull) {
                productIsCompatible = productIsCompatible && !_.includes([22], pull.id);
              }

              return _.includes([21, 25], pull.id) || (frontMaterialIsCompatible && productIsCompatible);
            });

            pullTypeOptions = _.sortBy(_.map(validPullTypes, pull => _.pick(pull, ['id', 'title'])), [pull => pull.id === 25 ? 0 : 1]);
          }
          else {
            var pullTypeOptions = [
              {id: 1, title: 'Radius Staple'},
              {id: 2, title: 'Radius Flat Tab'},
              {id: 8, title: 'Wire Radius Staple'},
              {id: 3, title: 'Knurled Knob'},
              {id: 4, title: 'Curved Knob'},
              {id: 5, title: 'Notched Knob'},
              {id: 7, title: 'Touch Latch'},
              {id: 6, title: 'No Pulls'},
              {id: 9, title: 'Pulls by Others'},
              //HINT conditionally pushed below
              //{id: 10, title: 'Scooped Bottom'}
            ];

            //HINT no touch latch for hoods and pocketing flip
            if (_.includes([1356, 1178, 757, 1177, 756, 1612, 990, 1191, 1673, 1674], product.productId)) {
              pullTypeOptions = _.filter(pullTypeOptions, pull => pull.id !== 7);
            }

            if (productType.preventTouchLatchPull) {
              pullTypeOptions = _.filter(pullTypeOptions, pull => pull.id !== 7);
            }

            if (_.includes([990, 1191, 1673, 1674], product.productId)) {
              var defaultFrontMaterialId = _.get(_.find(details, {key: 'frontMaterial'}), 'options.0.id');
              var frontMaterialId = _.get(product, 'details.frontMaterial.id', defaultFrontMaterialId);

              if (isSubproductControl) {
                frontMaterialId = _.get(product, `details.${key}-frontMaterial.id`) || frontMaterialId;
              }

              var frontIsColorMatchedEdge = _.get(_.find(flattenedMaterials, {id: frontMaterialId}), 'materialTypeId') === 21;

              if (!frontIsColorMatchedEdge) pullTypeOptions.unshift({id: 10, title: 'Scooped Bottom'});
            }
          }

          pullTypeOptions = _.map(pullTypeOptions, pullTypeOption => {
            var dbPullId = pullTypeOption.id;

            if (companyKey === 'vp') {
              //HINT
              //ST pulls are hardcoded in the DE and were later added to the DB
              //So the ids are not matching
              dbPullId = _.find([
                {hardcoded: 1, db: 27}, //   {id: 1, title: 'Radius Staple'},
                {hardcoded: 2, db: 28}, //   {id: 2, title: 'Radius Flat Tab'},
                {hardcoded: 3, db: 30}, //   {id: 3, title: 'Knurled Knob'},
                {hardcoded: 4, db: 31}, //   {id: 4, title: 'Curved Knob'},
                {hardcoded: 5, db: 32}, //   {id: 5, title: 'Notched Knob'},
                {hardcoded: 6, db: 34}, //   {id: 6, title: 'No Pulls'},
                {hardcoded: 7, db: 33}, //   {id: 7, title: 'Touch Latch'},
                {hardcoded: 8, db: 29}, //   {id: 8, title: 'Wire Radius Staple'},
                {hardcoded: 9, db: 35}, //   {id: 9, title: 'Pulls by Others'},
                {hardcoded: 10, db: 36}, //   //{id: 10, title: 'Scooped Bottom'}
              ], {hardcoded: pullTypeOption.id}).db;
            }

            var dbPull = pulls[dbPullId] || {};

            var hasIcon = _.some(dbPull.media, {subjectType: 'icon'});

            var pullMedia = _.find(dbPull.media, media => {
              return (hasIcon ? media.subjectType === 'icon' : true);
            });

            return {...pullTypeOption, pullMedia, thumbnail: _.get(pullMedia, 'url', 'tbd')};
          });

          pullDetails.push({
            key: `${key ? `${key}-` : ''}pullType`,
            noThumbnail: false,
            type: 'pullType',
            titlePrefix: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: ` : '',
            title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: Pull Type` : 'Pull Type',
            options: pullTypeOptions,
            isSubproductControl
          });

          //TODO x, y offset for henrybuilt
          var pullLocationOptions = [
            {id: 'centered', title: 'Centered'},
            {id: 'offsetLeft', title: 'Offset Left'},
            {id: 'offsetRight', title: 'Offset Right'}
          ];

          //HINT for products with doors default to offsetting right (door opening defaults to left)
          //HINT pull script currently overrides pull location selection for products with doors
          //HINT but we need to be able to support centered pulls on leaf doors
          if (productType.hasDoorActionOption && (isSubproductControl ? subproductComponent.type === 'leafDoor' : true)) {
            pullLocationOptions = [
              {id: 'offsetRight', title: 'Offset Right'},
              {id: 'offsetLeft', title: 'Offset Left'},
              {id: 'centered', title: 'Centered'}
            ];
          }

          if (product.productId === 976) {
            pullLocationOptions.push(
              {id: 'offsetOutside', title: 'Offset Outside'},
              {id: 'offsetInside', title: 'Offset Inside'}
            );
          }

          var pullTypeId = _.get(product, 'details.pullType.id');

          if (isSubproductControl) {
            pullTypeId = _.get(product, `details.${key}-pullType.id`) || pullTypeId;
          }

          var noPullTypeIds = companyKey === 'hb' ? [21, 22, 23, 25] : [6, 7, 9];

          if (!_.includes(noPullTypeIds, pullTypeId)) {
            if ((!isSubproductControl || (_.get(subproductComponent, 'type') === 'leafDoor')) && (_.includes([39, 42, 43, 44, 86, 88], productType.id) || productType.hasDoorActionOption || _.includes(_.toLower(productType.title), 'leaf door'))) {
              pullDetails.push({
                key: `${key ? `${key}-` : ''}leafDoorsCentered`, type: 'select',
                title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''} Pulls Centered` : 'Leaf Door Pulls Centered',
                options: [
                  {id: 'no', title: 'No'},
                  {id: 'centered', title: 'Centered'}
                ],
                isSubproductControl
              });
            }

            if (
              _.includes([13, 14], pullTypeId) &&
              _.get(product, 'details.pullOrientation.id', 'vertical') === 'horizontal'
              && (_.includes(productType.title, 'leaf door') || productType.hasDoorActionOption || productType.hasOrientationOption)
            ) {
              pullDetails.push({
                key: `${key ? `${key}-` : ''}preventPullNotch`,
                noThumbnail: true,
                options: [{id: 0, title: 'No'}, {id: 1, title: 'Yes'}],
                type: 'select',
                title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''} Prevent Notched Pull (confirm w engineering)` : 'Prevent Notched Pull  (confirm w engineering)',
                views: ['front'],
                userLenses: ['sales', 'design', 'engineering'],
                isSubproductControl
              });
            }

            pullDetails.push({
              key: `${key ? `${key}-` : ''}pullLocation`,
              type: 'pullLocation',
              options: pullLocationOptions,
              titlePrefix: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: ` : '',
              title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: Pull Location` : 'Pull Location',
              isSubproductControl,
              userLenses: ['design', 'engineering']
            });

            //and only for drawers
            if (companyKey === 'hb' && pullTypeId === 17 && (_.includes([1, 533, 540, 558, 565, 1430, 1528], productType.id) || _.get(subproductComponent, 'type') === 'drawer')) {
              pullDetails.push({
                key: `${key ? `${key}-` : ''}yPullLocation`,
                type: 'yPullLocation',
                options: [
                  {id: undefined, title: 'Not Set'},
                  {id: 'top', title: 'Top'},
                  {id: 'bottom', title: 'Bottom'},
                  {id: 'both', title: 'Both'}
                ],
                titlePrefix: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: ` : '',
                title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: I Pull Location` : 'I Pull Location',
                isSubproductControl,
                userLenses: ['design', 'engineering']
              });
            }

            var isRectangularPull = companyKey === 'hb' ? !_.includes([6, 16], pullTypeId) : _.includes([1, 2, 8], pullTypeId);

            //TODO only show for leaf door units and rectangle pulls
            if (isEmployee && isRectangularPull) {
              var pullOrientationOptions = [{id: 'horizontal', title: 'Horizontal'}, {id: 'vertical', title: 'Vertical'}];

              var leafDoorCount = 1;

              //TODO idenfity leaf door counts on products
              //this isn't a major problem because the script is already preventing horizontal pulls directly
              //when the leaf door is under 14" wide
              //_.includes([], product.productId) leafDoorCount = 2;
              if (companyKey === 'vp' && (product.dimensions.width / leafDoorCount) < 14) {
                pullOrientationOptions = [{id: 'vertical', title: 'Vertical'}];
              }

              pullDetails.push({
                key: `${key ? `${key}-` : ''}pullOrientation`,
                type: 'pullOrientation',
                title: isSubproductControl ? `${subproductComponent.detailPrefix}${subproductComponent.quantity > 1 ? ` ${n + 1}` : ''}: Pull Orientation` : 'Pull Orientation',
                options: pullOrientationOptions,
                isSubproductControl,
                userLenses: ['design', 'engineering']
              });
            }
          }

          return pullDetails;
        };

        materialAndPullDetails.push(...pullDetailsFor());
      }

      return materialAndPullDetails;
    };

    details.push(...materialAndPullDetailsFor({}));

    _.forEach(subproductData, (data, index) => {
      _.times(data.quantity, n => {
        var key = `${data.type}-${n}`;
        details.push(...materialAndPullDetailsFor({subproductComponent: data, isSubproductControl: true, key, n}));

        var frontPanelTypeOptions = [
          {id: 'flat', title: 'Flat'}
        ];

        _.forEach(associations.product_options, (value, key) => {
          var optionId = _.trim(`${key}`, 'id_');

          if (optionId === '62') frontPanelTypeOptions.push({id: 'squareFramedPanel', title: 'Framed Panel - Square'});
          if (optionId === '19') frontPanelTypeOptions.push({id: 'squareFramedGlass', title: 'Framed Glass - Square'});
          if (optionId === '61') frontPanelTypeOptions.push({id: 'beveledFramedPanel', title: 'Framed Panel - Beveled'});
          if (optionId === '60') frontPanelTypeOptions.push({id: 'beveledFramedGlass', title: 'Framed Glass - Beveled'});
        });

        if (companyKey === 'hb' && frontPanelTypeOptions.length > 1 && _.get(product, 'details.frontPanelType.id', 'flat') !== 'flat') {
          var options = _.filter(frontPanelTypeOptions, option => _.includes(['flat', _.get(product, 'details.frontPanelType.id', 'flat')], option.id));

          //HINT discontinue beveled framed fronts
          if (!_.includes(K.projectsUsingOldFrameAndPanelStandard, project.id) && !_.includes(_.get(product, `details.${key}-frontPanelType.id`), 'beveled') && !_.includes(_.get(product, 'details.frontPanelType.id'), 'beveled')) {
            options = _.filter(options, option => !_.includes(['beveledFramedPanel', 'beveledFramedGlass'], option.id));
          }

          details.push({
            key: `${key ? `${key}-` : ''}frontPanelType`,
            type: 'frontPanelType',
            titlePrefix: `${data.detailPrefix}${data.quantity > 1 ? ` ${n + 1}` : ''}: `,
            title: `${data.detailPrefix}${data.quantity > 1 ? ` ${n + 1}` : ''}: Front Panel Type`,
            isSubproductControl: true,
            options
          });
        }
      });
    });

    var frontPanelTypeOptions = [
      {id: 'flat', title: 'Flat'}
    ];

    _.forEach(associations.product_options, (value, key) => {
      var optionId = _.trim(`${key}`, 'id_');

      if (optionId === '62') frontPanelTypeOptions.push({id: 'squareFramedPanel', title: 'Framed Panel - Square'});
      if (optionId === '19') frontPanelTypeOptions.push({id: 'squareFramedGlass', title: 'Framed Glass - Square'});
      if (optionId === '61') frontPanelTypeOptions.push({id: 'beveledFramedPanel', title: 'Framed Panel - Beveled'});
      if (optionId === '60') frontPanelTypeOptions.push({id: 'beveledFramedGlass', title: 'Framed Glass - Beveled'});
    });

    if (companyKey === 'hb' && frontPanelTypeOptions.length > 1) {
      if (!_.includes(K.projectsUsingOldFrameAndPanelStandard, project.id) && !_.includes(_.get(product, 'details.frontPanelType.id'), 'beveled')) {
        frontPanelTypeOptions = _.filter(frontPanelTypeOptions, option => !_.includes(['beveledFramedPanel', 'beveledFramedGlass'], option.id));
      }

      details.push({
        key: 'frontPanelType',
        type: 'frontPanelType',
        title: 'Front Panel Type',
        options: frontPanelTypeOptions
      });
    }

    if (
      (companyKey === 'hb' || _.get(room, 'customData.grainFlowEnabled'))
      && !_.includes(K[companyKey].ids.hiddenPanels, product.productId)
      && product.productId !== 1533
      && !_.includes(_.get(product, 'details.frontPanelType.id', 'flat'), 'Glass')
    ) {
      var grainDirectionOptions = isHorizontalBarblockOrComponent ? [{id: 'horizontal', title: 'Horizontal'}, {id: 'hidden', title: 'Hidden'}] : [{id: 'vertical', title: 'Vertical'}, ...((companyKey === 'hb' || _.includes([1338, 1130, 1187, 1188, 1613, 1617, 1618, 1563, 978, 680, 1370, 1090, 1058, 1070], product.productId)) ? [{id: 'horizontal', title: 'Horizontal'}] : []), {id: 'hidden', title: 'Hidden'}];

      var shouldConstrainGrainflowOptions = Product.getShouldConstrainGrainflow({product, productType});

      if (shouldConstrainGrainflowOptions && product.dimensions.width > 47 && product.dimensions.height <= 47) {
        grainDirectionOptions = _.filter(grainDirectionOptions, ({id}) => id !== 'vertical');
      }

      if (shouldConstrainGrainflowOptions && product.dimensions.height > 47 && product.dimensions.width <= 47) {
        grainDirectionOptions = _.filter(grainDirectionOptions, ({id}) => id !== 'horizontal');
      }

      //HINT valet only allows horizontal grain
      if (_.includes([404, 262], product.productId)) grainDirectionOptions = [{id: 'horizontal', title: 'Horizontal'}];

      details.push({
        key: 'grainDirection',
        type: 'grainDirection',
        title: 'Grain Direction',
        options: grainDirectionOptions,
        userLenses: ['design', 'engineering']
      });

      details.push({
        key: 'grainContinuity',
        type: 'grainContinuity',
        title: 'Continuous Grain Direction',
        options: [{id: 'none', title: 'None'}, {id: 'from', title: 'From'}, {id: 'to', title: 'To'}, {id: 'both', title: 'Both'}],
        userLenses: ['design', 'engineering']
      });
    }

    details = _.filter(details, ({options, type}) => type === 'material' || options.length > 0);

    return details;
  },
  //Appliances/>

  getDefaultUseProductionId({product}) {
    const managedKey = _.get(product, 'managedData.managedKey');
    const productType = Product.get('productType', {product});
    let useProductionId = true;

    if (Product.getIsManaged({product})) {
      if (_.includes([1455, 1285, 856], productType.id)) useProductionId = false;
      if (_.includes(['kick', 'subcounter'], managedKey)) useProductionId = false;

      if (managedKey === 'kick' && _.get(Product.get('container', {product}), 'customData.flushKick')) useProductionId = true;
    }

    //HINT st shelfbank shelf
    if (_.includes([1564], product.productId)) {
      useProductionId = false;
    }

    //HINT ctop support ledger part
    if (product.productId === 1624) {
      useProductionId = false;
    }

    //hint recessed scribe
    if (product.productId === 1455) {
      useProductionId = false;
    }

    //HINT backstop
    if (_.includes([1736, 1737], product.productId)) {
      useProductionId = false;
    }

    //don't give appliance accommodations productionIds
    if (_.includes([746, 747, 748, 779, 777, 780, 1244, 1241, 1242, 439, 438, 440, 436, 437, 1224, 1223, 1244, 1241, 1242, 439, 438, 440, 436, 437, 1243], productType.id)) {
      useProductionId = false;
    }
    //HINT vanity bays
    if (productType.categoryId === 195) {
      useProductionId = false;
    }

    //HINT appliance frame components
    if (productType.categoryId === 197) {
      useProductionId = false;
    }

    //HINT generic appliance (ie just an oven, not a product)
    if (productType.id === 1535) {
      useProductionId = false;
    }

    //HINT barblock components
    if (_.includes([1145, 1509, 1146, 1407, 1419, 1524, 1525, 1144, 1147, 1382, 1383, 1384, 1522, 1568], productType.id)) {
      useProductionId = false;
    }

    return useProductionId;
  },

  getUseProductionId({product}) {
    const productionIdEnabled = _.get(product, 'customData.productionIdEnabled');
    var useProductionId = Product.getDefaultUseProductionId({product});

    if (productionIdEnabled === 0) useProductionId = false;

    return useProductionId;
  },

  getSubproductData({product, productType}) {
    if (!productType) productType = Product.get('productType', {product});

    var subproductData = _.get(productType, 'subproductData', []);

    var getAppliance = () => {
      var appliancesData = Product.getApplianceInstancesData({product});

      var {id} = _.get(product, 'appliancesData[0]', {});
      var defaultAppliance = _.find(appliancesData[0].compatibleAppliances, ({id}) => id !== 0);

      var appliance = (id === 0 ? defaultAppliance : _.find(appliancesData[0].compatibleAppliances, {id})) || defaultAppliance;

      return appliance || {};
    };

    //HINT ST fridge 3 fronts varies depending on configuration
    if (productType.id === 749) {
      var appliance = getAppliance();

      if (appliance.panelType === 'overDoubleUnder') {
        subproductData = [
          {type: 'leafDoor', quantity: 1, detailPrefix: 'Leaf Door'},
          {type: 'drawer', quantity: 2, detailPrefix: 'Drawer'},
        ];
      }
      else {
        subproductData = [
          {type: 'leafDoor', quantity: 1, detailPrefix: 'Leaf Door Double'},
          {type: 'drawer', quantity: 1, detailPrefix: 'Drawer'},
        ];
      }
    }
    else if (productType.id === 750) {
      var appliance = getAppliance();

      if (appliance.panelType === 'overUnderDoors28.5') {
        subproductData = [
          {type: 'leafDoor', quantity: 2, detailPrefix: 'Leaf Door'},
        ];
      }
      else {
        subproductData = [
          {type: 'leafDoor', quantity: 1, detailPrefix: 'Leaf Door'},
          {type: 'drawer', quantity: 1, detailPrefix: 'Drawer'},
        ];
      }
    }
    //TODO constrain options based on bay constraints
    else if (_.includes([1132, 1133, 759, 760, 764, 765], productType.id)) {
      subproductData = _.filter(subproductData, data => data.type === 'drawer');

      var dataType = product.customData.topBayType || (product.dimensions.width > 27 ? 'leafDoorDouble' : 'leafDoor');

      var detailPrefix = {
        leafDoor: 'Leaf Door',
        leafDoorDouble: 'Leaf Door Double',
        flipUp: 'Flip Up'
      }[dataType];

      subproductData.unshift({type: dataType === 'leafDoorDouble' ? 'leafDoor' : dataType, quantity: 1, detailPrefix});
    }
    else if (_.includes([759, 760, 764, 765, 1132], productType.id)) {
      if (product.dimensions.width > 27) {
        _.find(subproductData, {type: 'leafDoor'}).detailPrefix = 'Leaf Door Double';
      }
      else {
        _.find(subproductData, {type: 'leafDoor'}).detailPrefix = 'Leaf Door';
      }
    }
    //pocketing pantry
    else if (_.includes([1592, 1598], productType.id)) {
      //topBay can be flip up or leaf door double
      //middle bay is pocketing leaf door
      //bottom bay can be leaf door double, 2 drawer, 3 drawer, 4 drawer
      var customData = product.customData || {};
      var middleBayHeight = customData.middleBayHeight || 36;
      var bottomBayType = customData.bottomBayType || 'threeDrawer';
      var bottomBayHeight = 30;
      var topBayHeight = customData.topBayHeight || (product.dimensions.height - middleBayHeight - bottomBayHeight);
      var topBayType = customData.topBayType || 'flipUp';

      if (topBayHeight > 22) topBayType = 'leafDoorDouble';
      if (topBayHeight < 16) topBayType = 'flipUp';

      //TODO flag pocket model as separate from leafDoor, this is currently causing problems
      subproductData = [
        {type: topBayType === 'leafDoorDouble' ? 'leafDoor' : 'flipUp', quantity: 1, detailPrefix: `Top ${topBayType === 'leafDoorDouble' ? 'Leaf Door Double' : 'Flip Up'}`},
        {type: 'pocketingLeafDoor', quantity: 1, detailPrefix: 'Pocketing Leaf Door Double'},
        {type: bottomBayType === 'leafDoorDoubleWall' ? 'leafDoorDouble' : 'drawer', quantity: {'twoDrawer': 2, 'threeDrawer': 3, 'fourDrawer': 4, 'leafDoorDoubleWall': 1}[bottomBayType], detailPrefix: bottomBayType === 'leafDoorDoubleWall' ? 'Bottom Leaf Door Double' : 'Drawer'}
      ];
    }

    return subproductData;
  },

  getShouldConstrainGrainflow({product, productType}) {
    if (!productType) productType = Product.getProductData({product});

    //for now only constraining shelfbanks, open units, and panels
    return _.includes([77, 79, 50, 46, 47, 49, 80, 51, 20, 72], productType.categoryId);
  },

  getBreakingSizeKey({product}) {
    var {productType, dependencies} = Product.get(['productType', 'dependencies'], {product});
    var breakingSizeKey;

    if (productType) {
      var pricingRule = _.find(dependencies.pricingRules.byId, {id: productType.pricingRuleId});

      if (pricingRule) {
        breakingSizeKey = _.get(_.find(pricingRule.expressions, expression => expression.type === 'smallLarge' && expression.props.breakingSizeValue !== 0), 'props.breakingSizeKey');
      }
    }

    return breakingSizeKey;
  },

  getSmallLargeValueFor({product, value}) {
    var {productType, dependencies, project} = Product.get(['productType', 'dependencies', 'project'], {product});
    var dimValue;
    var breakingSizeKey = Product.getBreakingSizeKey({product});

    if (productType) {
      var pricingRule = _.find(dependencies.pricingRules.byId, {id: productType.pricingRuleId});

      if (pricingRule) {
        var expression = _.find(pricingRule.expressions, {type: 'smallLarge'});

        if (expression) {
          var breakingSizeValue = expression.props.breakingSizeValue;
          var constraints = Product.getConstraints({product, productType});

          var constrainer = new lib.DimensionConstrainer({constraints});

          if (value === 'small') {
            var breakingSizeValue = breakingSizeValue;

            //HINT ST uses <, rather than <=, so we need to find the largest value that is LESS than the breakingSizeValue
            if (project.companyKey === 'vp') {
              var step = constraints[breakingSizeKey].step || 1 / 4;
              breakingSizeValue -= step;
            }

            dimValue = constrainer.constrainDimensions(product.dimensions, {max: {[breakingSizeKey]: breakingSizeValue}})[breakingSizeKey];
          }
          else {
            dimValue = constrainer.getDimensionsFor('max')[breakingSizeKey];
          }
        }
      }
    }

    return dimValue;
  },

  getPricingSizeString({product}) {
    var {productType, project, dependencies} = Product.get(['productType', 'project', 'dependencies'], {product});
    var {dimensions} = product;

    var pricingSizeString;

    if (productType) {
      var pricingRule = _.find(dependencies.pricingRules.byId, {id: productType.pricingRuleId});

      if (pricingRule) {
        var expression = _.find(pricingRule.expressions, {type: 'smallLarge'});

        if (expression) {
          var dimensionValue = dimensions[expression.props.breakingSizeKey];

          if (productType.useLongestSidePricing) {
            dimensionValue = _.max(_.map(dimensions));
          }

          if (project && project.companyKey === 'hb') {
            pricingSizeString = dimensionValue > expression.props.breakingSizeValue ? 'large' : 'small';
          }
          else {
            pricingSizeString = dimensionValue >= expression.props.breakingSizeValue ? 'large' : 'small';
          }
        }
      }
    }

    return pricingSizeString;
  },

  getCanvasSettings({product}) {
    var canvasSettings = [];

    if (_.size(product)) {
      var {productType} = Product.get(['productType'], {product});

      if (Product.getBreakingSizeKey({product})) {
        canvasSettings.push({type: 'smallLarge', key: 'size', value: Product.getPricingSizeString({product})});
      }

      if (productType.hasDoorActionOption) {
        // canvasSettings.push({type: 'radio', key: 'customData.doorAction', position: {x: _.get(product, 'customData.doorAction') === 'left' ? -4 : product.dimensions.width + 2, y:  product.dimensions.height / 2}, value: _.get(product, 'customData.doorAction')});
        canvasSettings.push({type: 'radio', key: 'customData.doorAction', position: {x: _.get(product, 'customData.doorAction') === 'right' ? 2 : product.dimensions.width - 2, y: product.dimensions.height / 2}, value: _.get(product, 'customData.doorAction')});
      }

      if (productType.hasOrientationOption) {//TODO corner units
        // canvasSettings.push({type: 'radio', key: 'customData.orientation', position: {x: _.get(product, 'customData.orientation') === 'left' ? -4  : product.dimensions.width + 2, y: product.dimensions.height / 2}, value: _.get(product, 'customData.orientation')});
        canvasSettings.push({type: 'radio', key: 'customData.orientation', position: {x: 2, y: product.dimensions.height / 2}, value: _.get(product, 'customData.orientation')});

      }
    }

    return canvasSettings;
  },

  getDefaultDimensions({product, container, viewMode}) {
    let {companyKey, productType} = Product.get(['companyKey', 'productType'], {product});

    const constrainer = new lib.DimensionConstrainer({constraints: Product.getConstraints({product, productType})});

    let dropzoneSize = Container.getDropzoneSize({container, viewKey: 'front'});

    if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
      var swapDimKey = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? 'height' : 'width';

      dropzoneSize = {[swapDimKey]: dropzoneSize[swapDimKey]};
    }

    var topDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
    //HINT in top view the height of the dropzone is the containers depth that can be filled with product
    var dropzoneDepth = topDropzoneSize.depth ? topDropzoneSize.depth : topDropzoneSize.height;

    if (_.includes(['baseWithChase'], container.type)) dropzoneDepth = container.customData.unitDepth;
    if (_.includes(['floatingShelves'], container.type)) dropzoneDepth = container.dimensions.depth;

    dropzoneSize.depth = dropzoneDepth;

    const min = constrainer.getDimensionsFor('min');
    const max = constrainer.getDimensionsFor('max', {ideal: dropzoneSize});

    if (Product.getIsOpencaseComponent({product})) {
      return constrainer.getDimensionsFor('default');
    }
    else if (Product.getIsHorizontalBarblock({product})) {
      return container.dimensions;
    }

    var defaultHeight = max.height;

    if (_.includes(K[companyKey].ids.horizontalHiddenPanels, product.productId)) defaultHeight = container.dimensions.depth - (7 / 8);
    else if ((
      Product.getIsShelfbankComponent({product}) ||
      Product.getIsShelfbank({product}) ||
      Product.getIsOpencasePanel({product}) ||
      Product.getIsApplianceStackComponent({product}) ||
      _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ||
      _.includes(['wallPanel', 'opencase', 'cornerCounterTransition', 'wall', 'wallUnitLiner', 'floatingShelves', 'pivotDoor', 'backsplash'], container.type)
    )) {
      defaultHeight = min.height;
    }
    else if (_.includes([1399, 1620, 1621, 1622], product.productId)) {
      defaultHeight = container.dimensions.height - 7;
    }

    //HINT Door blank
    if (product.productId === 383) defaultHeight = 90;

    //HINT column refrigerators default to 80" tall
    if (_.includes([1223, 1225, 1226, 1227, 1246, 1247, 1746], product.productId)) defaultHeight = _.max([80, min.height, ...(dropzoneSize.height < 80 ? [dropzoneSize.height] : [])]);

    var defaultDepth = max.depth;

    if (_.includes([1479], product.productId)) {
      defaultDepth = min.depth;
    }

    var defaultWidth = _.includes(K[companyKey].ids.verticalHiddenPanels, product.productId) ? container.dimensions.depth - (7 / 8) : (viewMode === 'lite' ? max.width : min.width);

    if (_.includes([1452, 1453, 1454, 1455], product.productId)) defaultWidth = max.width;

    if (Product.getApplianceType({product}) === 'dishwasher') {
      defaultWidth = constrainer.constrain({dimensions: {width: 23.75}}, {max: {width: dropzoneSize.width}}).width;
    }
    else if (Product.getApplianceType({product}) === 'oven') {
      defaultWidth = constrainer.constrain({dimensions: {width: 30}}, {max: {width: dropzoneSize.width}}).width;
    }

    var defaultDimensions = {
      width: defaultWidth,
      height: defaultHeight,
      depth: defaultDepth
    };

    //TODO make panels have a nice default size

    return defaultDimensions;
  },

  updateManagedResources({product, actionKey, reduxActions, isBatched = false}) {
    var updatesMap = {
      productOptions: {tracks: [], creations: [], updates: [], deletedIds: []},
      products: {tracks: [], creations: [], updates: [], deletedIds: []},
    };
    var productCacheUpdate;

    try {
      let {managedProductInstances, childProducts, companyKey, container, project, productOptionInstances} = Product.get(['managedProductInstances', 'companyKey', 'container', 'project', 'childProducts', 'productOptionInstances'], {product});
      var productData = Product.getProductData({product});
      const hasSink = Product.getHasSink({product});
      var {materialIds} = product;

      if (project.lockedForProduction) {
        if (product.lockedForProduction) {
          if (actionKey === 'update' || actionKey === 'create') {
            return {managedUpdatesMap: updatesMap, productCacheUpdate};
          }
        }
      }

      var newProductInstances = [];
      var productInstanceUpdates = [];
      var deletedProductInstanceIds = [];

      if (actionKey === 'create') {
        var panelQuantity = Product.getAppliancePanelQuantity({product});

        if (panelQuantity > 0) {
          let props = {
            productId: companyKey === 'hb' ? 1249 : 853,
            materialIds,
            details: _.mapKeys(materialIds, (id, key) => `${key}Material`),
            productInstanceId: product.id,
            scopeId: container.scopeId,
            projectId: project.id,
            versionId: project.versionId,
            primaryAssociationKey: 'productInstance',
            managedData: {managedKey: 'appliancePanel'},
            position: {x: 0, y: 0, z: 0},
            customData: {},
          };

          props.dimensions = Product.getDefaultDimensions({product: props, container});

          newProductInstances.push(..._.times(panelQuantity, () => ({props})));
        }

        if (_.includes([1190, 1641], product.productId)) {
          var panelCount = product.dimensions.width > 47 ? 4 : 3;

          newProductInstances.push(..._.times(panelCount, n => {
            var panelMaterialIds = companyKey === 'hb' ? {
              panel: {id: _.get(product, `details.${_.includes([0, panelCount - 1], n) ? 'boxMaterial' : 'frontMaterial'}.id`, 358)}
            } : materialIds;

            let props = {
              productId: companyKey === 'hb' ? 1642 : 1613,
              materialIds: panelMaterialIds,
              details: _.mapKeys(panelMaterialIds, (id, key) => `${key}Material`),
              productInstanceId: product.id,
              scopeId: container.scopeId,
              projectId: project.id,
              versionId: project.versionId,
              primaryAssociationKey: 'productInstance',
              managedData: {managedKey: 'trimPanel'},
              position: {x: 0, y: 0, z: 0},
              customData: {},
            };

            props.dimensions = Product.getDefaultDimensions({product: props, container});

            return {props};
          }));
        }

        //pivot doors
        if (_.includes([384, 385], product.productId)) {
          //door panel
          var panelQuantity = product.productId === 384 ? 1 : 2;

          newProductInstances.push(..._.times(panelQuantity, n => {
            let props = {
              productId: 1500,
              materialIds,
              details: _.mapKeys(materialIds, (id, key) => `${key}Material`),
              productInstanceId: product.id,
              scopeId: container.scopeId,
              projectId: project.id,
              versionId: project.versionId,
              primaryAssociationKey: 'productInstance',
              managedData: {managedKey: 'pivotDoorPanel'},
              customData: {doorAction: n === 0 ? 'left' : 'right'},
              position: {x: 0, y: 0, z: 0},
            };

            trimPanelProps.dimensions = {depth: 1.75, width: panelQuantity === 2 ? ((product.dimensions.width / 2) - 1 / 16) : product.dimensions.width, height: product.dimensions.height};

            return {props};
          }));

          //trim panels
          newProductInstances.push(..._.times(3, n => {
            let trimPanelProps = {
              productId: n === 2 ? 1506 : 1502,
              materialIds,
              details: _.mapKeys(materialIds, (id, key) => `${key}Material`),
              productInstanceId: product.id,
              scopeId: container.scopeId,
              projectId: project.id,
              versionId: project.versionId,
              primaryAssociationKey: 'productInstance',
              managedData: {managedKey: n === 2 ? 'pivotDoorHeadPanel' : 'pivotDoorTrimPanel'},
              customData: {},
              position: {x: 0, y: 0, z: 0}
            };

            trimPanelProps.dimensions = {depth: 0.75, width: 2.25, height: trimPanelProps.productId === 1506 ? (product.dimensions.width + 2) : (product.dimensions.height + 2.75)};

            return {props: trimPanelProps};
          }));
        }

        if (companyKey === 'hb' && _.get(childProducts, 'length', 0) < 1 && _.includes(K[companyKey].ids.tallCustomApplianceFrameProductIds, product.productId)) {
          var newProductInstances = [];

          //generate default configurations
          //single appliance
          if (_.includes([1234, 121, 1426, 1239], product.productId)) {
            newProductInstances.push(..._.times(4, n => {
              var {productId, height, y} = {
                0: {productId: 1532, height: 16, y: -((product.dimensions.height - (16 + 13.5 + 16.5)) + 13.5 + 16.5)},
                1: {productId: 1529, height: (product.dimensions.height - (16 + 13.5 + 16.5)), y: -(13.5 + 16.5)},
                2: {productId: 1528, height: 13.5, y: -16.5},
                3: {productId: 1528, height: 16.5, y: 0}
              }[n];

              return {
                props: {
                  productId,
                  materialIds: product.materialIds,
                  details: product.details,
                  productInstanceId: product.id,
                  scopeId: container.scopeId,
                  projectId: project.id,
                  versionId: project.versionId,
                  primaryAssociationKey: 'productInstance',
                  position: {x: 0, y, z: 0},
                  dimensions: {...product.dimensions, height},
                  customData: {}
                }
              };
            }));
          }
          //double appliance
          else if (_.includes([1235, 1240, 1277, 123, 124], product.productId)) {
            newProductInstances.push(..._.times(4, n => {
              var {productId, height, y} = {
                0: {productId: 1532, height: (product.dimensions.height - (15 + 15 + 16.5)), y: -(15 + 15 + 16.5)},
                1: {productId: 1529, height: 15, y: -(15 + 16.5)},
                2: {productId: 1529, height: 15, y: -16.5},
                3: {productId: 1528, height: 16.5, y: 0}
              }[n];

              return {
                props: {
                  productId,
                  materialIds: product.materialIds,
                  details: product.details,
                  productInstanceId: product.id,
                  scopeId: container.scopeId,
                  projectId: project.id,
                  versionId: project.versionId,
                  primaryAssociationKey: 'productInstance',
                  position: {x: 0, y, z: 0},
                  dimensions: {...product.dimensions, height},
                  customData: {}
                }
              };
            }));
          }
          //triple appliance
          else if (_.includes([125, 126, 1278], product.productId)) {
            newProductInstances.push(..._.times(5, n => {
              var {productId, height, y} = {
                0: {productId: 1532, height: (product.dimensions.height - (15 + 15 + 15 + 10)), y: -(15 + 15 + 15 + 10)},
                1: {productId: 1529, height: 15, y: -(15 + 15 + 10)},
                2: {productId: 1529, height: 15, y: -(15 + 10)},
                3: {productId: 1529, height: 15, y: -10},
                4: {productId: 1528, height: 10, y: 0}
              }[n];

              return {
                props: {
                  productId,
                  materialIds: product.materialIds,
                  details: product.details,
                  productInstanceId: product.id,
                  scopeId: container.scopeId,
                  projectId: project.id,
                  versionId: project.versionId,
                  primaryAssociationKey: 'productInstance',
                  position: {x: 0, y, z: 0},
                  dimensions: {...product.dimensions, height},
                  customData: {}
                }
              };
            }));
          }
        }
      }
      else if (actionKey === 'update') {
        if (product.productId === 512) {
          var hasShoeShelf = product.customData.hasShoeShelf;
          var managedShoeShelf = _.find(managedProductInstances, productInstance => productInstance.managedData.managedKey === 'shoeShelf');
          var shoeShelfMaterialId = _.get(product, 'details.shoeShelfMaterial.id', 118);
          var materialIds = {front: {id: shoeShelfMaterialId}};

          if (hasShoeShelf && !managedShoeShelf) {

            newProductInstances.push({
              props: {
                productId: 525,
                materialIds,
                details: {frontMaterial: {id: shoeShelfMaterialId}},
                productInstanceId: product.id,
                scopeId: container.scopeId,
                projectId: project.id,
                versionId: project.versionId,
                primaryAssociationKey: 'productInstance',
                managedData: {managedKey: 'shoeShelf'},
                position: {x: 0, y: 0, z: 0},
                dimensions: {height: 3, depth: 13, width: product.dimensions.width - 3},
                customData: {}
              }
            });
          }
          else if (!hasShoeShelf && managedShoeShelf) {
            deletedProductInstanceIds.push(managedShoeShelf.id);
          }
          else if (hasShoeShelf && managedShoeShelf) {
            var materialIds = {front: {id: shoeShelfMaterialId}};

            productInstanceUpdates.push({
              where: {id: managedShoeShelf.id},
              props: {
                materialIds,
                details: {frontMaterial: {id: shoeShelfMaterialId}},
                dimensions: {height: 3, depth: 13, width: product.dimensions.width - 3}
              }
            });
          }
        }

        if (product.productId === 384) {
          var doorPanelProduct = _.find(childProducts, productInstance => productInstance.productId === 1500);

          if (doorPanelProduct) {
            //update customData so that doorAction is tracked
            _.set(doorPanelProduct, 'customData.doorAction', product.customData.doorAction);
            productInstanceUpdates.push({where: {id: doorPanelProduct.id}, props: {customData: doorPanelProduct.customData}});
          }
        }

        if (_.includes([384, 385], product.productId)) {
          var doorJambProducts = _.filter(childProducts, productInstance => _.includes([1502, 1506], productInstance.productId));

          if (doorJambProducts.length) {
            var jambWidth = product.customData.jambPanelDepths || 2.25;

            _.forEach(doorJambProducts, (productInstance, i) => {
              //HINT 1506 is the head panel, 1502 are the side panels
              var jambHeight = productInstance.id === 1506 ? (product.dimensions.width + 2) : (product.dimensions.height + 2.75);
              if (productInstance.dimensions.width !== jambWidth || productInstance.dimensions.height !== jambHeight) {
                _.set(productInstance, 'dimensions.width', jambWidth);
                _.set(productInstance, 'dimensions.height', jambHeight);
                productInstanceUpdates.push({where: {id: productInstance.id}, props: {dimensions: {...productInstance.dimensions, height: jambHeight, width: jambWidth}}});
              }
            });
          }

          var doorPanelProducts = _.filter(childProducts, productInstance => productInstance.productId === 1500);

          if (doorPanelProducts.length) {
            _.forEach(doorPanelProducts, productInstance => {
              var newHeight = product.dimensions.height;
              var newWidth = doorPanelProducts.length > 1 ? ((product.dimensions.width / 2) - 1 / 16) : product.dimensions.width;

              if (productInstance.dimensions.height !== newHeight || productInstance.dimensions.width !== newWidth) {
                _.set(productInstance, 'dimensions.height', newHeight);
                _.set(productInstance, 'dimensions.width', newWidth);

                productInstanceUpdates.push({where: {id: productInstance.id}, props: {dimensions: {...productInstance.dimensions, height: newHeight, width: newWidth}}});
              }
            });
          }
        }

        if (_.includes([1190, 1641], product.productId)) {
          var panelCount = product.dimensions.width > 47 ? 4 : 3;

          if (managedProductInstances.length !== panelCount) {
            deletedProductInstanceIds.push(..._.map(managedProductInstances, 'id'));

            newProductInstances.push(..._.times(panelCount, n => {
              var panelMaterialIds = companyKey === 'hb' ? {
                panel: {id: _.get(product, `details.${_.includes([0, panelCount - 1], n) ? 'boxMaterial' : 'frontMaterial'}.id`, 358)}
              } : materialIds;

              var existingManagedProductInstance = _.find(_.filter(managedProductInstances, ['managedData.managedKey', 'trimPanel']), (productInstance, i) => {
                var isCorrectPanelIndex = i === n;

                //HINT new panel is inserted between panels, so we should index accordingly
                if (panelCount === 4 && n === 2) {
                  isCorrectPanelIndex = false;
                }
                else if (panelCount === 4 && n === 3) {
                  isCorrectPanelIndex = i === 2;
                }
                else if (panelCount === 3 && n === 2) {
                  isCorrectPanelIndex = i === 3;
                }

                return isCorrectPanelIndex && !_.isEmpty(_.get(productInstance, 'productionDimensions') || !_.isEmpty(_.get(productInstance, 'notes')));
              });
              var productionDimensions = _.get(existingManagedProductInstance, 'productionDimensions') || {};
              var notes = _.get(existingManagedProductInstance, 'notes') || '';

              let props = {
                productId: companyKey === 'hb' ? 1642 : 1613,
                materialIds: panelMaterialIds,
                details: _.mapKeys(panelMaterialIds, (id, key) => `${key}Material`),
                productInstanceId: product.id,
                scopeId: container.scopeId,
                projectId: project.id,
                versionId: project.versionId,
                primaryAssociationKey: 'productInstance',
                managedData: {managedKey: 'trimPanel'},
                position: {x: 0, y: 0, z: 0},
                customData: {},
                productionDimensions,
                notes
              };

              props.dimensions = Product.getDefaultDimensions({product: props, container});

              return {props};
            }));
          }
          else if (companyKey === 'hb') {
            var existingTrimPanels = _.filter(managedProductInstances, ['managedData.managedKey', 'trimPanel']);

            _.forEach(existingTrimPanels, (productInstance, n) => {
              var panelMaterialIds = companyKey === 'hb' ? {
                panel: {id: _.get(product, `details.${_.includes([0, panelCount - 1], n) ? 'boxMaterial' : 'frontMaterial'}.id`, 358)}
              } : materialIds;

              if (!_.isEqual(panelMaterialIds, productInstance.materialIds)) {
                _.set(productInstance, 'materialIds', panelMaterialIds);
                _.set(productInstance, 'details', _.mapKeys(panelMaterialIds, (id, key) => `${key}Material`));
                productInstanceUpdates.push({where: {id: productInstance.id}, props: {materialIds: panelMaterialIds, details: _.mapKeys(panelMaterialIds, (id, key) => `${key}Material`)}});
              }
            });
          }
        }
      }
      else if (actionKey === 'destroy') {
        deletedProductInstanceIds.push(..._.map(managedProductInstances, 'id'));
      }

      if (_.some([productInstanceUpdates, newProductInstances, deletedProductInstanceIds], modification => modification.length > 0)) {
        if (!isBatched) {
          if (productInstanceUpdates.length) reduxActions.updateProducts({updates: productInstanceUpdates});
          if (newProductInstances.length) reduxActions.createProducts({propsSets: newProductInstances});
          if (deletedProductInstanceIds.length) reduxActions.destroyProducts({ids: _.uniq(deletedProductInstanceIds)});
        }
        else {
          updatesMap.products = {
            tracks: [],
            creations: newProductInstances,
            updates: productInstanceUpdates,
            deletedIds: deletedProductInstanceIds
          };
        }
      }

      //< product option instances
      var managedOptions = {};
      var newProductOptionInstances = [];
      var oldProductOptionInstances = [];
      var allProductOptionInstances = Product.get('productOptionInstances', {product});

      if (hasSink) {
        managedOptions.sink = {dataFor: () => {
          var productInstance = product;
          var resources = [];

          if (!_.get(product, 'customData.isByOthers')) {

            _.forEach(product.appliancesData, ({id: sinkApplianceId}) => {
              if (sinkApplianceId) {
                var productOptionId = { //applianceId: productOptionId map
                  236: 43,
                  237: 45,
                  238: 46,
                  239: 47,
                  240: 48,
                  241: 49,
                  242: 50,
                  243: 51,
                  244: 52,
                  245: 53,
                  605: 111,
                  607: 109,
                  608: 110,
                  609: 112,
                  610: 113,
                  611: 114,
                }[sinkApplianceId];

                if (productOptionId && productInstance) {
                  resources.push({
                    quantity: 1,
                    productOptionId,
                    productInstanceId: productInstance.id,
                    productInstance
                  });
                }
              }
            });
          }

          return {resources};
        }};
      }

      managedOptions.lighting = {dataFor: () => {
        var resources = [];

        if (Container.getHasLighting({container})) {
          var isManaged = Product.getIsManaged({product}) && _.get(product, 'managedData.managedKey') !== 'autofilledStorage';

          var lightingCompatibleProducts = Container.getLightingProducts({container});

          if (_.includes(_.map(lightingCompatibleProducts, 'id'), product.id)) {
            var {lightingType} = container.customData;
            var lightRanges = Container.getLightRanges({container});
            var lightPositions = lightingType === 'puck' ? Container.getLightPositions({container}) : [];

            if (companyKey === 'hb') {
              var xRange = Product.getXRange({product});

              resources.push({
                quantity: _.some(lightRanges, ({x1, x2}) => {
                  return x1 <= xRange.from && x2 >= xRange.to;
                }) ? 1 : 0,
                productOptionId: 10,
                productInstanceId: product.id,
                productInstance: product
              });
            }
            else if (lightingType === 'puck') {
              //actual lights? based on x position of products
              var xRange = Product.getXRange({product});

              resources.push({
                quantity: _.filter(lightPositions, x => {
                  //WARNING x should never fall at each end so > is ok
                  return x > xRange.from && x < xRange.to;
                }).length,
                productOptionId: 39,
                productInstanceId: product.id,
                productInstance: product
              });
            }
            else if (lightingType === 'linear') {
              var xRange = Product.getXRange({product});

              resources.push({
                quantity: _.some(lightRanges, ({x1, x2}) => {
                  return x1 <= xRange.from && x2 >= xRange.to;
                }) ? 1 : 0,
                productOptionId: product.dimensions.width >= 42 ? 73 : 72,
                productInstanceId: product.id,
                productInstance: product
              });
            }
          }
        }

        return {resources};
      }};

      managedOptions.oversizedPanel = {dataFor: () => {
        var resources = [];
        var productOptionId = 16;
        var productInstance = product;

        if (productInstance && productInstance.id && _.get(productData, `associations.product_options.id_${productOptionId}`)) {
          var oversizeHeight = 0;

          if (_.get(product, 'managedData.managedKey') === 'islandBackPanels') {
            oversizeHeight = 1;
          }
          else if (Product.getIsManaged({product})) {
            oversizeHeight = container ? Container.getPanelOversizeHeight({container}) : 1;
          }

          //HINT want to charge if visible panel is 95"
          //managed panels are already oversized
          //currently by 1", but in the future potentially by other amounts
          var dimensionCutoff = 95 + oversizeHeight;

          if (_.some(productInstance.dimensions, dimension => dimension > dimensionCutoff)) {
            resources.push({
              quantity: 1,
              productOptionId,
              productInstanceId: productInstance.id,
              productInstance
            });
          }
        }

        return {resources};
      }};

      managedOptions.frontPanelType = {dataFor: () => {
        var frontPanelType = _.get(product, 'details.frontPanelType.id');
        var productInstance = product;
        var resources = [];

        var productOptionId = {
          'squareFramedPanel': 62,
          'squareFramedGlass': 19,
          'beveledFramedGlass': 60,
          'beveledFramedPanel': 61
        }[frontPanelType];

        if (productOptionId && productInstance && product.id && _.get(productData, `associations.product_options.id_${productOptionId}`)) {
          var quantity = 1;

          if (productData.framedGlassFrontCount) {
            quantity = productData.framedGlassFrontCount;
          }

          var subproductData = Product.getSubproductData({product});

          //HINT check if the user toggled off the framed front for some portion of the unit and decreasing quantity
          _.forEach(subproductData, (data, index) => {
            _.times(data.quantity, n => {
              var key = `${data.type}-${n}`;

              if (_.get(product, `details.${key}-frontPanelType.id`) === 'flat') {
                var quantityDecrease = (data.type === 'leafDoorDouble' || _.includes(_.lowerCase(data.detailPrefix), 'double')) ? 2 : 1;

                quantity -= quantityDecrease;
              }
            });
          });

          resources.push({
            quantity,
            productOptionId,
            productInstanceId: productInstance.id,
            productInstance
          });
        }

        return {resources};
      }};

      managedOptions.eoraType = {dataFor: () => {
        var productInstance = product;
        var eoraType = _.get(productInstance, 'customData.eoraType', 'none');
        var resources = [];

        if (eoraType !== 'none') {
          var productOptionId = _.toNumber(_.trim(eoraType, 'id_'));

          resources.push({
            quantity: 1,
            productOptionId,
            productInstanceId: productInstance.id,
            productInstance
          });
        }

        return {resources};
      }};

      managedOptions.thirtyDeepBase = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        if (productInstance && productInstance.id && _.get(productInstance, 'dimensions.depth') === 30) {
          var thirtyDeepOptionKey = _.findKey(_.get(productData, 'associations.product_options'), (value, key)=> _.includes(['id_21', 'id_22', 'id_77'], key));

          if (thirtyDeepOptionKey) {
            var productOptionId = _.toNumber(_.trim(thirtyDeepOptionKey, 'id_'));

            resources.push({
              quantity: 1,
              productOptionId,
              productInstanceId: productInstance.id,
              productInstance
            });
          }
        }

        return {resources};
      }};

      var calculatePullQuantity = ({product, quantity = 0, pullIdToCompare, getIsAppliedToSubproduct, getShouldIgnoreSubproduct, useMaterialCondition, materialConditonData: {materialPullIdToCompare, materialIdToCompare} = {}, isAppliedToOverallProduct}) => {
        var subproductData = Product.getSubproductData({product});

        _.forEach(subproductData, data => {
          _.times(data.quantity, n => {
            var key = `${data.type}-${n}`;

            //HINT: these are applied on subproduct || to the whole unit
            var pullTypeId = _.get(product, `details.${key}-pullType.id`) || _.get(product, 'details.pullType.id');
            var materialId = _.get(product, `details.${key}-pullMaterial.id`) || _.get(product, 'details.pullMaterial.id');

            getIsAppliedToSubproduct = getIsAppliedToSubproduct || (({pullTypeId, materialId}) => pullTypeId === pullIdToCompare);
            var isAppliedToSubproduct = getIsAppliedToSubproduct({data, pullTypeId, materialId});

            //increase quantity if pull is brass, but overall unit is not
            //decrease quantity if pull is not brass, but overall unit is

            getShouldIgnoreSubproduct = getShouldIgnoreSubproduct || (() => false);
            var shouldIgnoreSubproduct = getShouldIgnoreSubproduct({data, pullTypeId, materialId});

            if (!shouldIgnoreSubproduct) {
              var quantityChange = (data.type === 'leafDoorDouble' || _.includes(_.lowerCase(data.detailPrefix), 'double')) ? 2 : 1;

              if (isAppliedToOverallProduct && !isAppliedToSubproduct) quantity -= quantityChange;
              if (!isAppliedToOverallProduct && isAppliedToSubproduct) quantity += quantityChange;
            }
          });
        });

        return quantity;
      };

      managedOptions.fullLengthBentPull = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        if (companyKey === 'hb') {
          if (productInstance && productInstance.id) {
            var pullProductOptionId = 'id_31';
            var pullId = 13;

            var fullLengthOptionKey = _.findKey(_.get(productData, 'associations.product_options'), (value, key) => {
              return key === pullProductOptionId;
            });

            if (fullLengthOptionKey) {
              var productOptionId = Number(fullLengthOptionKey.split('_')[1]);

              var isAppliedToOverallProduct = _.get(productInstance.details, 'pullType.id') === pullId;

              //use pull type: unknown part quantity
              var quantity = isAppliedToOverallProduct ? _.get(productData, 'associations.parts.id_253.quantity', 1) : 0;

              quantity = calculatePullQuantity({
                product,
                quantity,
                pullIdToCompare: pullId,
                isAppliedToOverallProduct,
              });

              if (quantity) {
                resources.push({
                  quantity,
                  productOptionId,
                  productInstanceId: productInstance.id,
                  productInstance
                });
              }
            }
          }
        }

        return {resources};
      }};

      managedOptions.fullLengthWoodPull = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        if (companyKey === 'hb') {
          if (productInstance && productInstance.id) {
            var pullProductOptionId = 'id_3';
            var pullId = 14;

            var fullLengthOptionKey = _.findKey(_.get(productData, 'associations.product_options'), (value, key) => {
              return key === pullProductOptionId;
            });

            if (fullLengthOptionKey) {
              var productOptionId = Number(fullLengthOptionKey.split('_')[1]);

              var isAppliedToOverallProduct = _.get(productInstance.details, 'pullType.id') === pullId;

              //use pull type: unknown part quantity
              var quantity = isAppliedToOverallProduct ? _.get(productData, 'associations.parts.id_253.quantity', 1) : 0;

              quantity = calculatePullQuantity({
                product,
                quantity,
                pullIdToCompare: pullId,
                isAppliedToOverallProduct,
              });

              if (quantity) {
                resources.push({
                  quantity,
                  productOptionId,
                  productInstanceId: productInstance.id,
                  productInstance
                });
              }
            }
          }
        }

        return {resources};
      }};

      managedOptions.brassPullOption = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        if (companyKey === 'vp' && productInstance && productInstance.id) {
          var brassPullOptionKey = _.findKey(_.get(productData, 'associations.product_options'), (value, key) => {
            return _.includes(['id_87', 'id_88', 'id_89', 'id_90', 'id_91', 'id_92', 'id_93'], key);
          });

          if (brassPullOptionKey) {
            var productOptionId = _.toNumber(_.trim(brassPullOptionKey, 'id_'));

            var optionIdToPullQuantityMap = {
              87: 1,
              88: 2,
              89: 3,
              90: 4,
              91: 5,
              92: 8,
              93: 7,
              120: 6,
            };

            var pullQuantityToOptionIdMap = {
              1: 87,
              2: 88,
              3: 89,
              4: 90,
              5: 91,
              8: 92,
              7: 93,
              6: 120
            };

            var wireRadiusStaplePullId = 8;
            var knurledKnobPullId = 3;
            var brassPullMaterialId = 404;

            var isAppliedToOverallProduct = _.get(product, 'details.pullType.id') === wireRadiusStaplePullId || (_.get(product, 'details.pullType.id') === knurledKnobPullId && _.get(product, 'details.pullMaterial.id') === brassPullMaterialId);

            var brassPullQuantity = isAppliedToOverallProduct ? optionIdToPullQuantityMap[productOptionId] : 0;

            brassPullQuantity = calculatePullQuantity({
              product,
              quantity: brassPullQuantity,
              isAppliedToOverallProduct,
              getIsAppliedToSubproduct: ({pullTypeId, materialId}) => pullTypeId === wireRadiusStaplePullId || (pullTypeId === knurledKnobPullId && materialId === brassPullMaterialId),
            });

            productOptionId = pullQuantityToOptionIdMap[brassPullQuantity];

            if (brassPullQuantity && productOptionId) {
              resources.push({
                quantity: 1,
                productOptionId,
                productInstanceId: productInstance.id,
                productInstance
              });
            }
          }
        }

        return {resources};
      }};

      managedOptions.tipOnDrawer = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        //HINT applies to touch latch drawers
        if (companyKey === 'vp' && productInstance && productInstance.id) {
          var tipOnPullOptionKey = _.findKey(_.get(productData, 'associations.product_options'), (value, key) => {
            return _.includes(['id_56', 'id_57', 'id_58', 'id_59'], key);
          });

          if (tipOnPullOptionKey) {
            var productOptionId = _.toNumber(_.trim(tipOnPullOptionKey, 'id_'));

            var optionIdToPullQuantityMap = {
              56: 1,
              57: 2,
              58: 3,
              59: 4,
              116: 5,
              117: 6,
              118: 7,
              119: 8
            };

            var pullQuantityToOptionIdMap = {
              1: 56,
              2: 57,
              3: 58,
              4: 59,
              5: 116,
              6: 117,
              7: 118,
              8: 119
            };

            var tipOnPullId = 7;

            var isAppliedToOverallProduct = _.get(product, 'details.pullType.id') === tipOnPullId;

            var tipOnPullQuantity = isAppliedToOverallProduct ? optionIdToPullQuantityMap[productOptionId] : 0;

            tipOnPullQuantity = calculatePullQuantity({
              product,
              quantity: tipOnPullQuantity,
              pullIdToCompare: tipOnPullId,
              isAppliedToOverallProduct,
              //HINT ST only charges for drawers, not leaf doors, for units with a mix of both, this makes sure we're only charging for drawers
              getIsAppliedToSubproduct: (({data, pullTypeId, materialId}) => {
                return data.type === 'drawer' && pullTypeId === tipOnPullId;
              }),
              //HINT those products are setup to charge for the pull on the drawer, not the leaf door
              //so we don't want to subtract for the leaf doors
              getShouldIgnoreSubproduct: (({data, pullTypeId, materialId}) => {
                return data.type !== 'drawer';
              }),
            });

            if (!_.get(product, `customData.productOptions.${productOptionId}.enabled`) && tipOnPullQuantity) {
              productOptionId = pullQuantityToOptionIdMap[tipOnPullQuantity];

              resources.push({
                quantity: 1,
                productOptionId,
                productInstanceId: productInstance.id,
                productInstance
              });
            }
          }
        }

        return {resources};
      }};

      managedOptions.pushToOpenServoDrive = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        //HINT applies to touch latch drawers
        if (companyKey === 'hb' && productInstance && productInstance.id) {
          var pushToOpenServoDriveKey = _.findKey(_.get(productData, 'associations.product_options'), (value, key) => {
            return 'id_115' === key;
          });

          if (pushToOpenServoDriveKey) {
            var productOptionId = 115;

            var isPTOorTLPull = _.includes([22, 24], _.get(product, 'details.pullType.id'));

            if (!_.get(product, `customData.productOptions.${productOptionId}.enabled`) && isPTOorTLPull) {
              resources.push({
                quantity: 1,
                productOptionId,
                productInstanceId: productInstance.id,
                productInstance
              });
            }
          }
        }

        return {resources};
      }};

      managedOptions.isOversizedAssembly = {dataFor: () => {
        var resources = [];
        var productInstance = product;

        if (productInstance && productInstance.id) {
          if (_.includes(_.keys(_.get(productData, 'associations.product_options')), 'id_24')) {
            if (productInstance.dimensions.height > 95) {
              resources.push({
                quantity: 1,
                productOptionId: 24,
                productInstanceId: productInstance.id,
                productInstance
              });
            }
          }

          if (_.includes(_.keys(_.get(productData, 'associations.product_options')), 'id_25')) {
            if (productInstance.dimensions.height > 119) {
              resources.push({
                quantity: 1,
                productOptionId: 25,
                productInstanceId: productInstance.id,
                productInstance
              });
            }
          }
        }

        return {resources};
      }};

      _.forEach(_.get(product, 'customData.productOptions'), (productOptionData, productOptionId) => {
        productOptionId = _.toNumber(productOptionId);

        managedOptions[`option_${productOptionId}`] = {dataFor: () => {
          var resources = [];
          var productInstance = product;
          var quantity = productOptionData.quantity || 1;

          var isAssociatedWithProduct = _.find(Product.getCompatibleProductOptions({product}).currentlyCompatibleProductOptions, {id: productOptionId});

          if (isAssociatedWithProduct && productOptionData && productInstance && productInstance.id && productOptionData.enabled) {
            // check width and determine right

            resources.push({
              quantity,
              productOptionId,
              productInstanceId: productInstance.id,
              productInstance
            });
          }

          return {resources};
        }};
      });

      _.forEach(managedOptions, (managedOption, managedKey) => {
        if (_.includes(['create', 'update'], actionKey)) {
          var resources = _.filter(managedOption.dataFor().resources, r => r.quantity > 0);
          var plainData = {resources: _.map(resources, r => _.omit(r, ['productInstance']))};

          var cachedData = {resources: _.map(_.filter(allProductOptionInstances, ['managedData.managedKey', managedKey]), existingManagedOption => {
            return {
              quantity: existingManagedOption.quantity,
              productOptionId: existingManagedOption.productOptionId,
              productInstanceId: existingManagedOption.productInstanceId
            };
          })};

          var dataChanged = actionKey === 'update' && !_.isEqual(cachedData, plainData) || resources.length !== _.filter(allProductOptionInstances, ['managedData.managedKey', managedKey]).length;
        }

        if (actionKey === 'destroy' || dataChanged) {
          oldProductOptionInstances.push(..._.filter(allProductOptionInstances, ['managedData.managedKey', managedKey]));
        }

        if (actionKey === 'create' || dataChanged) {
          _.forEach(resources, ({quantity, productOptionId, productInstance}) => {
            newProductOptionInstances.push({
              props: {
                quantity,
                productOptionId,
                productInstanceId: productInstance.id,
                projectId: project.id,
                versionId: project.versionId,
                scopeId: container.scopeId,
                managedData: {managedKey}
              }
            });
          });
        }
      });

      if (!isBatched) {
        setTimeout(() => reduxActions.updateProduct({id: product.id, props: {customData: product.customData}}));
      }

      if (oldProductOptionInstances.length > 0) {
        if (isBatched) {
          updatesMap.productOptions.deletedIds = _.map(oldProductOptionInstances, 'id');
        }
        else {
          reduxActions.destroyProductOptions({ids: _.map(oldProductOptionInstances, 'id')});
        }
      }
      if (newProductOptionInstances.length > 0) {
        if (isBatched) {
          updatesMap.productOptions.creations = newProductOptionInstances;
        }
        else {
          reduxActions.createProductOptions({propsSets: newProductOptionInstances});
        }
      }
      //> product option instances
    }
    catch (error) {
      console.error(error); //eslint-disable-line
    }

    return {managedUpdatesMap: updatesMap, productCacheUpdate};
  },
  getZIndex: memo(({product, companyKey, container, sideKey, elevation, viewKey, isNonSpacial}) => {
    if (!companyKey) companyKey = Product.get('companyKey', {product});
    if (!container) container = Product.get('container', {product});

    var {inverseZPosition} = Product.get('productType', {product});
    var zIndex = isNonSpacial ? 0 : (Container.getZIndex({container, elevation, viewKey}) + 0.01);

    var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

    if (sideKey === 'front') {
      if (!alignWithBackOfContainer) zIndex -= product.position.z;
      else zIndex += product.position.z;

      //HINT position valet hamper behind hamper bays
      if (product.productId === 404) zIndex -= product.dimensions.depth;

      //HINT position accessory shelf, heatshield, knife block, and steel shelf on top of panels
      if (Product.get('container', {product}).type === 'backsplash') {
        if (Product.getIsSnapToTopBacksplashComponent({product}) || _.includes([442], product.productId)) zIndex += 5;
      }

      if (product.customData.isDashed) zIndex += 10;
    }
    else if (sideKey === 'top') {
      //HINT draw sink on top of other products to show countertop cutout and drain field
      if (Product.getHasSink({product})) zIndex += 10;

      //HINT position valet hamper below hamper bays
      if (product.productId === 404) zIndex -= container.dimensions.height;
      //HINT y position is inversed
      if (!alignWithBackOfContainer) zIndex -= product.position.y;
      else zIndex += product.position.y;
    }
    else if (sideKey !== 'front') {
      zIndex += (sideKey === 'left' ? -product.position.x : product.position.x);
    }

    //HINT make sure pivot door door entry dotted line show over adjacent panels
    if (_.includes([384, 385], product.productId)) zIndex += 2;

    //HINT always show hidden panels in front of other products
    if (_.includes(K[companyKey].ids.hiddenPanels, product.productId)) {
      zIndex += 100;
    }

    //HINT show floating shelves in front of other products
    if (_.includes([354, 356, 357, 358, 359, 353, 355, 352, 168, 1015, 1016], product.productId)) zIndex += 2;

    return zIndex;
  }),

  getSnapToLines({product, elevation, room, viewKey}) {
    const snapToLines = [];

    if (Product.getIsSnapToTopBacksplashComponent({product}) && viewKey === 'front') {
      const container = Product.get('container', {product});
      const dropzoneInset = Product.getDropzoneInset({product, viewKey, elevation});
      const dropzoneSize = Product.getDropzoneSize({product, viewKey, container});
      const snapToY = dropzoneInset.y + product.dimensions.height;

      snapToLines.push({
        from: {x: dropzoneInset.x, y: snapToY},
        to: {x: dropzoneInset.x + dropzoneSize.width, y: snapToY}
      });
    }

    return snapToLines;
  },

  getDropzoneInset({product, viewKey, container, elevation, parentProduct, isNonSpacial, nonSpacialContainerPosition, containerRealPosition}) {
    if (!container) {
      container = Product.get('container', {product});
    }

    if (!parentProduct) {
      parentProduct = Product.get('parentProduct', {product});
    }

    let dropzoneInset = {};

    if (parentProduct) {
      const parentType = Product.getType({product: parentProduct});

      if (Product.getIsBarblockComponent({product})) {
        const {wrapThickness} = K.barblockConstants;
        const {dimensions} = parentProduct;

        dropzoneInset = {x: wrapThickness, y: parentType === 'verticalBarblock' ? -wrapThickness : -((_.get(dimensions, 'height') || 6.125) - 4.625 - 0.75)};
      }
    }

    if (container) {
      if (viewKey === 'front' && _.includes([1223, 1242, 1244, 1439, 1479, 1480, 1521, 1198, 1199, 1200, 1201, 1440, 1526, 1682, 1687, 1689, 1691, 1619, 1481], product.productId)) {
        dropzoneInset.y = Container.getKickHeight({container});

        var scribesData = Container.getScribesData({container});

        if (scribesData.left && scribesData.left.distance > 0) {
          var wrapSizes = Container.getWrapSizes({container});

          dropzoneInset.x = -scribesData.left.distance - wrapSizes.left;
        }
      }

      dropzoneInset = lib.object.sum(dropzoneInset, Container.getDropzoneInset({container, viewKey}));
    }

    const dropzoneSize = Product.getDropzoneSize({product, viewKey, container, parentProduct});

    if (viewKey === 'front') {
      dropzoneInset = lib.object.sum(dropzoneInset, ...(containerRealPosition ? [] : [(isNonSpacial ? nonSpacialContainerPosition : Elevation.getPosition2d({elevation, position3d: container.position})), {y: -dropzoneSize.height}]), parentProduct?.position);
    }

    return dropzoneInset;
  },

  getDropzoneSize({product, viewKey, container, parentProduct}) {
    if (viewKey !== 'front') return {};

    if (!container) {
      container = Product.get('container', {product});
    }

    if (!parentProduct) {
      parentProduct = Product.get('parentProduct', {product});
    }

    let dropzoneSize = {};

    if (parentProduct) {
      const type = Product.getType({product: parentProduct});

      if (type === 'applianceStackFrame') {
        dropzoneSize = parentProduct.dimensions;
      }
      else if (type === 'verticalBarblock' || type === 'horizontalBarblock') {
        const {dimensions} = parentProduct;
        const {wrapThickness} = K.barblockConstants;

        const margin = {
          left: wrapThickness,
          right: wrapThickness,
          top: wrapThickness,
          bottom: type === 'verticalBarblock' ? wrapThickness : ((_.get(dimensions, 'height') || 6.125) - 4.625 - 0.75),
        };

        dropzoneSize = {
          width: dimensions.width - margin.left - margin.right,
          height: dimensions.height - margin.top - margin.bottom
        };
      }
      else {
        dropzoneSize = parentProduct.dimensions;
      }
    }
    else if (container) {
      dropzoneSize = Container.getDropzoneSize({container, viewKey});

      if (_.includes([1223, 1242, 1244, 1439, 1479, 1480, 1521, 1198, 1199, 1200, 1201, 1440, 1526, 1682, 1687, 1689, 1691], product.productId)) {
        dropzoneSize.height += Container.getKickHeight({container}) + Container.getSubcounterHeight({container});

        if (viewKey === 'front') {
          var scribesData = Container.getScribesData({container});

          if (_.some(scribesData, scribeData => scribeData && scribeData.distance && scribeData.distance > 0)) {
            var wrapSizes = Container.getWrapSizes({container});
            var leftScribeDistance = _.get(scribesData, 'left.distance', 0);
            var rightScribeDistance = _.get(scribesData, 'right.distance', 0);

            dropzoneSize.width += leftScribeDistance + rightScribeDistance + (leftScribeDistance > 0 ? wrapSizes.left : 0) + (rightScribeDistance > 0 ? wrapSizes.right : 0);
          }
        }
      }
    }

    if (_.isEmpty(dropzoneSize)) {
      dropzoneSize = container.dimensions;
    }

    return dropzoneSize;
  },

  getSize({product, viewKey, elevation, isNonSpacial, overrideSideKey, sideKey}) {
    let size;

    var dimensions = _.clone(product.dimensions);

    if (_.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels, ...K.hb.ids.horizontalHiddenPanels, ...K.vp.ids.horizontalHiddenPanels], product.productId)) {
      var swapDimKey = _.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels], product.productId) ? 'width' : 'height';
      dimensions = {...product.dimensions, [swapDimKey]: product.dimensions.depth, depth: product.dimensions[swapDimKey]};
    }

    if (viewKey === 'top') {
      size = {width: dimensions.width, height: dimensions.depth};
    }
    else {
      size = {width: dimensions.width, height: dimensions.height};

      sideKey = sideKey || (overrideSideKey || Product.getSideKey({product, viewKey, elevation}));

      if (_.includes(['left', 'right'], sideKey)) size.width = dimensions.depth;
    }

    return size;
  },

  getParentGrid({product}) {
    const type = Product.getType({product});

    if (type === 'opencaseComponent') {
      const {parentProduct} = Product.get(['parentProduct'], {product});

      return Opencase.getCellCounts({product: parentProduct});
    }

    return undefined;
  },

  getCustomOnMove({product}) {
    let customOnMove = null;
    let parentType;

    const type = Product.getType({product});
    const {parentProduct, container} = Product.get(['parentProduct', 'container'], {product});

    if (parentProduct) {
      parentType = Product.getType({product: parentProduct});
    }

    if (type === 'opencaseComponent') customOnMove = Opencase.getCustomOnMove({product});
    else if (parentType === 'verticalBarblock') customOnMove = Barblock.getCustomMove({product});

    return customOnMove;
  },

  getCustomDragBoundFunc({product, elevation, viewOffset, viewKey, nonSpacialContainerPosition, overrideSideKey}) {
    let customDragBoundFunc = null;
    let parentType;

    const {parentProduct, container} = Product.get(['parentProduct', 'container'], {product});

    if (parentProduct) {
      parentType = Product.getType({product: parentProduct});
    }

    if (viewKey !== 'front') return null;

    if (Product.getIsOpencaseComponent({product})) {
      customDragBoundFunc = Opencase.getCustomDragBoundFunc({product, elevation, viewOffset, nonSpacialContainerPosition, overrideSideKey});
    }
    else if (parentType === 'verticalBarblock') customDragBoundFunc = Barblock.getCustomDragBoundFunc({product, elevation, viewOffset, nonSpacialContainerPosition, overrideSideKey});
    else if (container.type === 'daylightIsland') customDragBoundFunc = DaylightIslandComponent.getCustomDragBoundFunc({product, elevation, viewOffset, nonSpacialContainerPosition, overrideSideKey});

    return customDragBoundFunc;
  },

  getIsScalable({product}) {
    return !Product.getIsOpencaseComponent({product}) && !Product.getIsPeg({product});
  },

  getFill({product, container, elevation, activeDetailLevel, activeFillMode}) {
    let fill = '';

    if (Product.getIsComponentProduct({product}) && !_.includes([1564], product.productId)) {
      fill = K.componentProductAppearancePropsByTheme().fill.light;
    }
    else if (_.includes(['valet', 'floatingShelves', 'wallPanel', 'backsplash', 'wall', 'wallUnitLiner', 'pivotDoor', 'hbIslandExtension', 'opencase', 'assembly'], container.type) || Product.getHasComponents({product})) {
      fill = Container.getFill({container, elevation, activeFillMode, activeDetailLevel});
    }

    if (_.includes(['unitType', 'grayscale'], activeFillMode)) { //HINT: dpc => unitType and schematic => grayscale
      fill = Container.getFill({container, elevation, activeDetailLevel, activeFillMode});
    }

    return fill;
  },
  getIsInvalid({product, viewKey, isNonSpacial, room, issuesData}) {
    var isInvalid = false;

    //HINT not showing invalid for products in plan
    if (viewKey === 'top') return false;
    //HINT product is being added
    if (!product.id) return false;

    if (!room) room = Product.get('room', {product});

    if (issuesData && issuesData.issues) {
      isInvalid = _.some(_.get(issuesData.issues, `[${room.id}]`), issue => {
        var {resourceKey, resourceId, level, isResolved} = issue;

        return !isResolved && resourceKey === 'product' && resourceId === product.id && level === 1;
      });
    }

    return isInvalid;
  },

  getIsTBDAppliance({product, viewKey, isNonSpacial}) {
    //HINT not showing invalid for products in plan
    if (viewKey === 'top') return false;
    let isTBDAppliance = false;

    if ((Product.getIsAppliance({product}) || Product.getHasSink({product})) && product.eventType !== 'transform' && !isNonSpacial) {
      const hasSink = Product.getHasSink({product});
      var applianceQuantity = Product.getApplianceQuantity({product});

      _.forEach(Array(applianceQuantity), (filler, index) => {
        var applianceInstanceData = _.get(product, `appliancesData[${index}]`);

        const applianceId = _.get(applianceInstanceData, 'id', 0);
        const modelNumber = _.get(applianceInstanceData, 'customModelNumber') || _.get(applianceInstanceData, 'modelNumber');
        const vendor = _.get(applianceInstanceData, 'customVendor') || _.get(applianceInstanceData, 'vendor');

        if (!(applianceId || (modelNumber && vendor))) {
          isTBDAppliance = true;
        }
      });
    }

    return isTBDAppliance;
  },

  getProductIdsThatIgnoreOverlap({product, companyKey}) {
    if (!companyKey) companyKey = Product.get('companyKey', {product});
    //Hidden panels and backsplash knife block
    return [
      ...K[companyKey].ids.hiddenPanels, ...K[companyKey].ids.notchedWallPanels, 525, 1102, 1186, 168, 1579, 1015, 1016, 1014, 354, 356, 357, 358, 359, 353, 355, 352, 525, 517,
      444, 442, 185, 443, 1015, 1016
    ];
  },

  //HINT these are backsplash options that attach to the top of the backsplash panel, and thus need their position fixed
  getIsSnapToTopBacksplashComponent({product}) {
    return _.includes([443, 444, 1186], product.productId);
  },

  getHatchFillData: memo(({product, elevation, viewKey, container, parentProduct, activeFillMode, includeAll, activeDetailLevel}) => {
    var {project, productOptionInstances, dependencies} = Product.get(['project', 'productOptionInstances', 'dependencies'], {product});
    var productData = Product.getProductData({product});
    var details = DetailsHelper.getDetailsFor({product});
    var flattenedMaterials = _.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials);
    var hatchData = {};
    var fills = {};
    var shouldInvertStroke = false;
    var shouldInvertStrokeByMaterialKey = {};
    var hasSubproductMaterials = false;
    var isBarblockComponent = Product.getIsBarblockComponent({product});

    var sideKey = 'top';
    var isShowingSectionCut = false;

    if (viewKey === 'front' && elevation && container) {
      sideKey = Product.getSideKey({product, viewKey, elevation, container});

      if (_.includes(['left', 'right'], sideKey) && lib.math.linesIntersect({l1: elevation.lineInRoom, l2: Container.getFootprintLines({container}).front})) {
        isShowingSectionCut = true;
      }
    }

    if (_.includes(['materialHatches', 'materialColors'], activeFillMode) && !isShowingSectionCut) {
      var hatchDetails = includeAll ? Product.getOwnedCompatibleDetails({product}) : _.filter(Product.getOwnedCompatibleDetails({product}), ({key}) => {
        return Project.getShouldShowHatch({project, detailKey: key, productData, activeFillMode, sideKey});
      });

      if (hatchDetails) {
        _.forEach(hatchDetails, detail => {
          var detailValue = _.get(details, `[${detail.key}].id`);

          var isSubproductDetail = _.split(detail.key, '-').length > 1;

          if (isSubproductDetail) hasSubproductMaterials = true;

          if (isSubproductDetail && !detailValue) detailValue = _.get(details, `[${_.split(detail.key, '-')[2]}].id`);

          //HINT box material is default for box back
          //HINT also important because modeler will have many models with boxBackMaterial that most of the time will be using boxMaterial
          if (detail.key === 'boxBackMaterial' && ((!product.customData?.boxBackDifferentFromBox && !productData.materialAssociations.boxBack) || !detailValue)) detailValue = _.get(details, 'boxMaterial.id');

          var material = _.find(flattenedMaterials, {id: detailValue});

          var hasAppliedBrass = Product.getHasAppliedBrass({product, productType: productData});

          if (material) {
            fills[detail.key.replace('Material', '')] =
              activeFillMode === 'materialColors'
                ? (hasAppliedBrass ? '#967F72' : material.color)
                : HatchHelper.forHatch({key: Project.getHatchKeyFor({project, detailValue, hasAppliedBrass})});

            //HINT barblock components invert based on parent frame fill
            if (activeFillMode === 'materialColors' && !isBarblockComponent) {
              shouldInvertStrokeByMaterialKey[detail.key.replace('Material', '')] = material.isDark;

              var shouldConsiderMaterial = _.includes(['front', 'panel'], detail.key.replace('Material', ''));

              var exposedBoxProductIds = [
                37, 691, 85, 699, 120, 694, 119, 174, 175, 183, 1148, 1610,
                262, 404, //valets
                960, 1400, 1486, 611, 612, 176, 987, 992, //open units
                977, 160, //sliding glass
                //assemblies?
                1188, //opencase trim cap, not sure why this is using box materialKey,
                986, 1581, 1519, 1587, 901, 938, 960, 961, 985, 986, 1355, //ST bookcases
                1564, //ST shelfbank shelf
                1643, 1663, //ST functional wood boxes
                1407, 1524, 1525, 1419, //vbb spice drawer, using box material key
                1149 //HB hood freestanding shroud
              ];

              if (_.includes(exposedBoxProductIds, product.productId) || _.includes([64, 72], productData.categoryId)) shouldConsiderMaterial = _.includes(['box'], detail.key.replace('Material', ''));

              if (_.includes([1452, 1453, 1454, 1455], product.productId)) shouldConsiderMaterial = _.includes(['scribe'], detail.key.replace('Material', ''));

              if (shouldConsiderMaterial) {
                shouldInvertStroke = shouldInvertStroke || material.isDark;
              }
            }
          }

          if (activeDetailLevel === 'rendering' && activeFillMode === 'materialColors' && product.customData.renderingOverrideFill) {
            fills[detail.key.replace('Material', '')] = product.customData.renderingOverrideFill;
          }
        });
      }
    }

    //HINT barblock components use parent box material
    if (activeFillMode === 'materialColors' && isBarblockComponent && !isShowingSectionCut) {
      if (parentProduct) {
        var parentBoxMaterialId = _.get(parentProduct.details, 'boxMaterial.id');

        var parentBoxMaterial = _.find(flattenedMaterials, {id: parentBoxMaterialId});

        if (parentBoxMaterial) shouldInvertStroke = parentBoxMaterial.isDark;
      }
    }

    var defaultFillKey = _.find(['front', 'panel', 'box'], fillKey => {
      return fills[fillKey] && Project.getShouldShowHatch({project, detailKey: `${fillKey}Material`, productData, activeFillMode, sideKey});
    });

    var defaultFill = fills[defaultFillKey];

    hatchData = {hatchFills: fills, hatchFill: defaultFill, shouldInvertStroke, shouldInvertStrokeByMaterialKey};

    //TODO correct models
    var framedFrontProductIds = [];//1245, 1246, 1247, 395, 396, 397, 398, 785, 786, 787];

    //NOTE when using hatchfills, remove fill so that unit doesn't fill the entire product and we get partial hatches
    //HINT all products using subproductData to hatch should use this
    if (fills.glass || _.includes(framedFrontProductIds, product.productId) || Product.getIsOpencaseComponent({product}) || hasSubproductMaterials) {
      hatchData.hatchFill = undefined;
    }

    if (fills.glass) {
      var glassMaterialId = _.get(details, 'glassMaterial.id');
      var glassMaterial = _.find(flattenedMaterials, {id: glassMaterialId});
      hatchData.hatchFills.glassOpacity = 0.5;

      if (!hatchData.hatchFills.boxBack) hatchData.hatchFills.boxBack = hatchData.hatchFills.box;

      //HINT opacity only matters when we're filling with colors
      if (activeFillMode === 'materialHatches') hatchData.hatchFills.glassOpacity = 1;
      //HINT non-glass materials being used in a 'glass' key slot
      //for instance hpl shelfbank sliders
      else if (glassMaterial && glassMaterial.materialTypeId !== 11) {
        hatchData.hatchFills.glassOpacity = 1;
      }
      //HINT polar white
      else if (glassMaterialId === 102) hatchData.hatchFills.glassOpacity = 0.90;
      //HINT diffused white
      else if (glassMaterialId === 100) hatchData.hatchFills.glassOpacity = 0.7;
      //HINT clear glass
      else if (glassMaterialId === 101) hatchData.hatchFills.glassOpacity = 0.1;
      //HINT light grey glass
      else if (glassMaterialId === 278) hatchData.hatchFills.glassOpacity = 0.4;
      //HINT dark grey glass
      else if (glassMaterialId === 278) hatchData.hatchFills.glassOpacity = 0.5;
    }

    return hatchData;
  }),

  getHasBox({product}) {
    const container = Product.get('container', {product});
    const productData = Product.getProductData({product});
    const parentTypeHasBox = _.includes(['base', 'wall', 'wallUnitLiner', 'tall', 'floatingBase', 'baseWithChase', 'islandExtension', 'hbIslandExtension', 'vanity', 'valet', 'cornerCounterTransition', 'horizontalBarblock', 'rearFacingBarblock'], container?.type);
    const isApplianceWithoutBox = _.includes([752, 753, 754, 778, 779, 746, 749, 750, 751, 776, 777, 780, 785, 786, 787, 1182, 1197, 1227, 1241], productData.id);
    const isManaged = Product.getIsManaged({product});

    return !isManaged && parentTypeHasBox && !isApplianceWithoutBox;
  },

  getHasLighting({product}) {
    return _.some(Product.get('productOptionInstances', {product}), productOptionInstance => {
      return _.includes([10, 39, 72, 73], productOptionInstance.productOptionId);
    });
  },

  getHasInternalLighting({product}) {
    return _.includes([587, 606, 584, 605], product.productId) || _.some(Product.get('productOptionInstances', {product}), productOptionInstance => {
      return _.includes([78], productOptionInstance.productOptionId);
    });
  },

  getIsPanel({product}) {
    const productData = Product.getProductData({product});
    //WARNING intentionally not including kick to avoid throwing off more important site access dims
    return _.includes([46, 47, 49, 53, 77, 80], productData.categoryId) || _.includes([1187, 1130, 1338, 1421], productData.id);
  },

  getIsSculptedPanel({product}) {
    const productData = Product.getProductData({product});

    return _.includes([77], productData.categoryId);
  },

  getProducibleDimensions({product}) {
    const productData = Product.getProductData({product});
    const container = Product.get('container', {product});

    let dimensions = _.cloneDeep(product.dimensions);

    if (productData.categoryId === 46) {
      const sizes = _.values(_.pick(dimensions, ['width', 'height']));

      dimensions = {
        ...dimensions,
        ...lib.object.min({width: 143, height: 143}, {width: _.min(sizes), height: _.max(sizes)})
      };
    }

    if (Product.getIsPanel({product})) {
      //Panel allowances
      dimensions.depth = 5;

      _.forEach(['height', 'width'], dimensionKey => {
        dimensions[dimensionKey] += 6;
      });
    }
    else {
      //leveler feet, NOTE TALL NOT WALL
      if (_.includes(['base', 'baseWithChase', 'floatingBase', 'vanity', 'tall'], container.type)) {
        dimensions.height += 4;
      }

      //packaging, NOTE WALL NOT TALL
      if (_.includes(['base', 'baseWithChase', 'floatingBase', 'vanity', 'tall', 'wall', 'wallUnitLiner'], container.type)) {
        _.forEach(['height', 'width', 'depth'], dimensionKey => {
          dimensions[dimensionKey] += 4;
        });
      }
    }

    return dimensions;
  },

  getBayWidths({product}) {
    var {bayWidths} = product.customData;
    var productData = Product.getProductData({product});

    var bayCount = productData.bayCount || 0;
    var netWidth = product.dimensions.width;

    if (!bayWidths || _.some(bayWidths, width => width < 12) || _.sum(bayWidths) > netWidth || bayCount !== bayWidths.length + 1) {
      bayWidths = _.times(bayCount - 1, () => K.round(netWidth / bayCount));
    }

    return [...bayWidths, netWidth - _.sum(bayWidths)];
  },

  getCompatibleProductOptions({product}) {
    var {dependencies, companyKey, productType, project} = Product.get(['dependencies', 'productType', 'companyKey', 'project'], {product});

    var productOptionTypes = _.values(dependencies.productOptionTypes.byId);
    var pricingRules = _.values(dependencies.pricingRules.byId);
    var productOptionAssociationKeys = _.keys(productType.associations.product_options); //formatted 'id_${po.id}'
    //HINT these options have been automated. Users should be allowed to toggle them off for legacy projects, but not on
    var includedWhenOnProductOptionIds = [56, 57, 58, 59];
    var nonStandardProductOptionIds = companyKey === 'hb' ? [106, 107] : [];

    var allPotentialProductOptions = _.filter(productOptionTypes, po => {
      //HINT all products have the non-standard product option
      return (_.includes(nonStandardProductOptionIds, po.id)
      || (_.includes(productOptionAssociationKeys, `id_${po.id}`)));
    });

    //HINT non-standard use of brass fronts approved for julianna's project only
    if (_.includes([4, 1641], product.productId) && !_.includes([6529], project.id)) {
      _.remove(allPotentialProductOptions, (option) => (_.includes([80, 81, 82, 83], option.id)));
    }

    // HINT remove sales specific product options
    _.remove(allPotentialProductOptions, option => _.includes([13, 15, 86], option.id));

    if (product.customData.hasNonStandardBrassFronts) {
      allPotentialProductOptions.push(_.find(productOptionTypes, {id: 80}));
      allPotentialProductOptions.push(_.find(productOptionTypes, {id: 81}));
      allPotentialProductOptions.push(_.find(productOptionTypes, {id: 82}));
      allPotentialProductOptions.push(_.find(productOptionTypes, {id: 83}));
    }
    // if (product.productId === 1350 && _.includes([6827, 9792], project.id)) {
    //   allPotentialProductOptions.push(_.find(productOptionTypes, {id: 82}));
    // }

    // if (product.productId === 170 && _.includes([6827, 9792], project.id)) {
    //   allPotentialProductOptions.push(_.find(productOptionTypes, {id: 81}));
    // }

    //HINT framed panels incompatible with hanging file unit, door storage, and applied brass
    if (_.includes(['beveledFramedPanel', 'squareFramedPanel', 'beveledFramedGlass', 'squareFramedGlass'], _.get(product, 'details.frontPanelType.id', 'flat'))) {
      _.remove(allPotentialProductOptions, (option) => (_.includes([12, 4, 14, 80, 81, 82, 83], option.id)));
    }

    allPotentialProductOptions = _.map(allPotentialProductOptions, po => ({...po, ...(_.includes(K.autoManagedProductOptionIds, po.id) ? {isAutoManaged: true} : {isAutoManaged: false})}));

    var {incompatibleProductOptions, currentlyCompatibleProductOptions} = getProductOptionIncompatibilityRules({allPotentialProductOptions, product, dependencies});

    return {
      allPotentialProductOptions,
      currentlyCompatibleProductOptions,
      incompatibleProductOptions,
      nonStandardProductOptionIds,
      pricingRules,
      productOptionTypes
    };
  },
  getEnabledOptionsLiteMode({product}) {
    var {productOptionInstances} = Product.get(['productType', 'productOptionInstances', 'dependencies'], {product});

    var {currentlyCompatibleProductOptions} = Product.getCompatibleProductOptions({product});

    var productOptionInstancesIds = new Set(_.map(productOptionInstances, 'productOptionId'));

    var enabledOptions = _.filter(currentlyCompatibleProductOptions, (option) => {
      if (option.isAutoManaged === true) {
        return productOptionInstancesIds.has(option.id);
      }
      else {
        return false;
      }
    });

    return enabledOptions = [...enabledOptions, ..._.chain(currentlyCompatibleProductOptions).filter({isAutoManaged: false}).map(po => productOptionInstancesIds.has(po.id) ? po : null).filter(null).value()];
  },
  getDetailApplications({products}) {
    var detailApplications = [];

    _.forEach(products, (product) => {
      var details = DetailsHelper.getCompatibleDetailsFor({product});

      _.forEach(details, (detail) => {
        if (!detail.isSubproductControl && detail.key !== 'pullMaterial' && detail.type === 'material') {
          if (!_.find(detailApplications, {key: detail.key, title: detail.title})) {
            detailApplications.push(detail);
          }
        }
      });
    });

    return detailApplications;
  },
  //HINT there are 4 applied brass product options
  //if any are applied the unit has applied brass
  //need to look out for swap products that have the wrong option enabled
  getHasAppliedBrass({product, productType}) {
    if (!productType) productType = Product.get('productType', {product});

    var appliedBrassOptionKey = _.findKey(_.get(productType, 'associations.product_options'), (value, key) => {
      return _.includes(['id_80', 'id_81', 'id_82', 'id_83'], key);
    });

    var hasAppliedBrass = false;

    if (appliedBrassOptionKey || product.customData.hasNonStandardBrassFronts) {
      var optionIds = product.customData.hasNonStandardBrassFronts ? [80, 81, 82, 83] : [parseInt(appliedBrassOptionKey.split('_')[1])];

      if (_.some(optionIds, optionId => _.get(product, `customData.productOptions.${optionId}.enabled`))) hasAppliedBrass = true;
    }

    return hasAppliedBrass;
  }
};

export default Product;
