import React, { useContext } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Wall from 'project-helpers/wall';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container/';
import ArchElement from 'project-helpers/arch-element';
import ProjectGraphic from 'project-helpers/project-graphic';
import Volume from 'project-helpers/volume';
import Product from 'project-helpers/product';

// import Room from 'project-helpers/room';
import CanvasLine from 'canvas/canvas-line';
import CanvasPath from 'canvas/canvas-path';
import CanvasContainer from './canvas-container';
import CanvasArchElement from './canvas-arch-element';
import CanvasDatum from './canvas-datum';
import CanvasVolume from './canvas-volume';
import PositionHelper from 'helpers/position-helper';
import CanvasDimensionLine from 'canvas/dimensions/canvas-dimension-line';
import CanvasLineItems from 'canvas/canvas-line-items';

import CanvasProjectGraphic from '../project-components/canvas-project-graphic';
import getElevationDimensionSets from 'dimensions/elevation/getElevationDimensionSets';
import { Circle, Image } from 'react-konva';
import useImage from 'use-image';
import Noise from 'assets/noise.png';
import Color from 'color';

import {withErrorBoundary} from 'react-error-boundary';

import { EditableCanvasPolyline, CanvasDataContext, CanvasText, CanvasSelectionContext, EditableCanvasLine, CanvasErrorFallback } from 'canvas';
import ProjectDataContext from 'contexts/project-data-context';
import { connect, resourceActions, issuesDataActions } from 'redux/index.js';
import Room from 'project-helpers/room';

const NoiseImage = (props = {}) => {
  const [image] = useImage(Noise);

  return <Image image={image} {...props}/>;
};

class CanvasElevation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeWallId: undefined
    };

    this.shouldUpdateDimensionSets = props.visibilityLayers.dimensions;
  }

  shouldComponentUpdate(nextProps, nextState) {
    var {renderForDrawings, canvasData, visibilityLayers, viewOffset} = nextProps;
    var showDimensions = visibilityLayers.dimensions;

    var selectedEntitiesChanged = nextProps.selectionData?.activeEntities && this.props.selectionData?.activeEntities && (_.size(nextProps.selectionData.activeEntities) !== _.size(this.props.selectionData.activeEntities) || this.difference(nextProps.selectionData.activeEntities, this.props.selectionData.activeEntities).length > 0);

    this.shouldUpdateDimensionSets = showDimensions && !selectedEntitiesChanged && (!global.dimensionsByEntityType.elevation[_.get(this.props, 'elevation.id')] || (canvasData?.scale === this.props.canvasData?.scale && !canvasData.isDragging) || renderForDrawings);

    if (!this.props.elevation) return true;

    if (this.state.activeWallId !== nextState.activeWallId) return true;

    if (this.props.renderForDrawings || nextProps.renderForDrawings) return true;

    var preventUpdate = false;

    const hasElevationChanged = this.difference(nextProps.elevation, this.props.elevation).length > 0;
    var hasRoomChanged = this.difference(nextProps.room, this.props.room).length > 0;
    var hasDatumsChanged = nextProps.datums !== this.props.datums;
    var haveDimensionsUpdated = nextProps.projectData.activeDimensionsLayer !== this.props.projectData.activeDimensionsLayer || _.some(nextProps.projectData.dimensionsData[this.props.projectData.activeDimensionsLayer], (dimensionData, dimensionDataKey) => {
      var currentDimensionData = this.props.projectData.dimensionsData[this.props.projectData.activeDimensionsLayer][dimensionDataKey];
      var shouldCauseRerender = false;

      if (dimensionDataKey === 'additiveDimensionsEditing') {
        shouldCauseRerender = currentDimensionData !== dimensionData;
      }
      else {
        var dataDifferent = false;

        if (_.isArray(dimensionData) || _.isArray(currentDimensionData)) {
          dataDifferent = _.flatMap(dimensionData, (data, index) => this.difference(data, _.get(currentDimensionData, `[${index}]`))).length > 0;
        }
        else {
          dataDifferent = this.difference(dimensionData, currentDimensionData).length > 0;
        }

        shouldCauseRerender = _.size(dimensionData) !== _.size(currentDimensionData) || dataDifferent;
      }

      return shouldCauseRerender;
    });

    if (hasElevationChanged || hasRoomChanged || hasDatumsChanged || haveDimensionsUpdated || this.props.elevation.eventType === 'transform') return true;

    var hasReduxStateUpdated = false;

    var manuallyHandledPropKeys = ['elevation', 'volumes', 'containers', 'archElements', 'walls', 'allWalls', 'room', 'projectGraphics', 'xzDatums', 'datums', 'products'];
    var manuallyHandledObjectPropKeys = ['volumes', 'containers', 'archElements', 'walls', 'allWalls', 'projectGraphics', 'xzDatums', 'products'];

    _.forEach(manuallyHandledObjectPropKeys, key => {
      if (!hasReduxStateUpdated) {
        var sizeDifferent = _.size(nextProps[key]) !== _.size(this.props[key]);
        var resourcesDifferent = _.flatMap(nextProps[key], (resource, index) => this.difference(resource, _.get(this.props, `${key}[${index}]`))).length > 0;

        if (sizeDifferent || resourcesDifferent) {
          hasReduxStateUpdated = true;
        }
      }
    });

    const haveNonReduxPropsUpdated = !_.isEqual(_.omit(nextProps, manuallyHandledPropKeys), _.omit(this.props, manuallyHandledPropKeys));

    return (haveNonReduxPropsUpdated || hasReduxStateUpdated) && !preventUpdate;
  }

  difference = (obj1, obj2) => {
    if (!obj2) return [undefined];

    const diffProperties = _.reduce(obj1, (result, value, key) => {
      return _.isEqual(value, obj2[key]) ?
        result : result.concat(key);
    }, []);

    return diffProperties || [];
  };

  handleSelect = ({wall, showingCompleteOutline}) => () => {
    var {elevation, selectionData} = this.props;
    var {getIsActiveEntity, setActiveEntities} = selectionData;

    var shouldConsiderSelectingElevation = true;

    if (wall) {
      if (!showingCompleteOutline) {
        shouldConsiderSelectingElevation = false;
        // alert('Only part of the wall is visible in this elevation. To make edits, please extend the elevation to capture the entire wall.');
        this.setState({activeWallId: undefined});

        if (this.props.selectionData) this.props.selectionData.onDeselect();
      }
      else {
        this.setState({activeWallId: wall.id});
      }
    }

    if (shouldConsiderSelectingElevation && !getIsActiveEntity({resourceKey: 'elevation', id: elevation.id})) {
      setActiveEntities({entities: [{id: elevation.id, resourceKey: 'elevation'}]});
    }
  };

  handleWallOutlineChange = ({wall, showingCompleteOutline, points, hitApi = true}) => {
    if (!showingCompleteOutline) {
      alert('Only part of the wall is visible in this elevation. To make edits, please extend the elevation to capture the entire wall.');
    }
    else {
      points = _.map(points, point => ({x: lib.round(point.x, {toNearest: 1 / 32}), y: lib.round(point.y, {toNearest: 1 / 32})}));

      this.props.updateWall({id: wall.id, hitApi, props: {outline: {...wall.outline, points}}});

      if (hitApi) this.props.projectData.pushToUndoQueue({type: 'wall', eventKey: 'transformEnd', instance: wall});
    }
  };

  handlePointDragEnd = ({wall, showingCompleteOutline}) => (points) => {
    this.handleWallOutlineChange({wall, showingCompleteOutline, points});
  };

  handleAddPoint = ({wall, showingCompleteOutline}) => ({points, inAddMode}) => {
    this.handleWallOutlineChange({wall, showingCompleteOutline, points, hitApi: !inAddMode});
  };

  handleDeleteWallPoint = ({wall, showingCompleteOutline}) => (points) => {
    this.handleWallOutlineChange({wall, showingCompleteOutline, points});
  };

  render() {
    var {elevation, canvasData, viewOffset, selectionData, containers, volumes, walls, allWalls, room, archElements, projectGraphics, datums, xzDatums, activeDetailLevel, activeFillMode, activeUserLense, projectData, visibilityLayers, hideTitle = false, renderForDrawings, preventEditing, isExportingSvg} = this.props;
    var {showingAlignmentIndicators} = projectData;
    var {activeWallId} = this.state;
    var isSection = Elevation.getIsSection({elevation});
    var elevationWidth = Elevation.getWidth({elevation});

    var {
      reveals: showRevealSymbols,
      unitNumbers: showUnitNumbers,
      grainFlow: showGrainFlow,
      projections: showProjections,
      projectGraphics: showProjectGraphics,
      wallsAndArchElements: showWallsAndArchElements,
      dimensions: showDimensions,
      bindingDimensions: showBindingDimensions,
      datums: showDatums,
      perspective: showPerspective,
      canvasSettings: showCanvasSettings,
      canvasSettingsProductDetails: showProductDetails,
    } = visibilityLayers;

    showGrainFlow = projectData.companyKey === 'hb' ? showGrainFlow : (_.get(room, 'customData.grainFlowEnabled') && showGrainFlow);

    var wallOutlines = _.filter(_.map(walls, wall => Wall.getOutlinePoints({elevation, wall})), wallOutline => !_.isEmpty(wallOutline));

    if (this.shouldUpdateDimensionSets) {
      global.dimensionsByEntityType.elevation[elevation.id] = getElevationDimensionSets({elevation, canvasData, projectData, showProjections, activeDetailLevel, cachedDimensionSets: global.dimensionsByEntityType.elevation[elevation.id], considerSummarizationChange: !renderForDrawings, reduxActions: this.props});
    }

    var outline = Elevation.getOutline({elevation, wallOutlines});
    var isSelected = _.size(selectionData) ? selectionData.getIsActiveEntity({resourceKey: 'elevation', id: elevation.id}) : false;
    var alignmentIndicatorLines;

    if (showingAlignmentIndicators) {
      alignmentIndicatorLines = Elevation.getAlignmentIndicatorLines({elevation});

      alignmentIndicatorLines = _.map(alignmentIndicatorLines, alignmentIndicatorLine => ({...alignmentIndicatorLine, offset: lib.object.sum(alignmentIndicatorLine.offset, viewOffset)}));
    }

    var wallsData = _.filter(_.orderBy(_.map(walls, wall => {
      var wallX = Elevation.getWallX({elevation, wall});
      var outlinePoints = Wall.getOutlinePoints({elevation, wall});

      var showingCompleteOutline = !(Wall.getXOffsetInElevation({wall, elevation}) < 0 || (wallX + _.max(_.map(wall.outline.points, 'x'))) > elevationWidth);

      //HINT pseudoWalls should appear behind everything else
      return {wall, wallX, outlinePoints, showingCompleteOutline, zIndex: wall.isPseudoWall ? -10000 : Wall.getZIndexFor({wall, elevation})};
    }), wallData => wallData.zIndex), wallData => !_.isEmpty(wallData.outlinePoints) && !lib.trig.anglesAreEqual({a1: Elevation.getWallTheta({elevation, wall: wallData.wall}), a2: Math.PI}));

    var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}));
    var sectionCutLinesData = _.map(wallsWithSectionCuts, wall => {
      var positionInRoom = lib.trig.nearestPoint({point: Wall.getLine({wall}).inRoom.from, onLine: elevation.lineInRoom});
      var positionInElevation = Elevation.getPosition2d({elevation, position3d: {y: 0, x: positionInRoom.x, z: positionInRoom.y}});
      var heightCandidates = _.filter(_.map(outline, (point, i) => ({from: point, to: lib.array.next(outline, i)})), (line, i) => {
        return lib.math.linesIntersectInclusive({l1: {from: {x: positionInElevation.x, y: 0}, to: {x: positionInElevation.x, y: -10000}}, l2: line});
      });

      var height = _.min(_.map(heightCandidates, (line, i) => {
        return lib.math.intersectionPoint({l1: {from: {x: positionInElevation.x, y: 0}, to: {x: positionInElevation.x, y: -10000}}, l2: line}).y;
      }));

      return {wall, height: height || -wall.outline.height, x: positionInElevation.x};
    });

    if (isSection && !wallsData.length && sectionCutLinesData.length === 1) {
      var wallData = sectionCutLinesData[0];
      var {wall} = wallData;

      wallsData = [
        {wall, wallX: Elevation.getWallX({elevation, wall}), zIndex: -10000, outlinePoints: [{x: wallData.x, y: wallData.height}, {x: wallData.x, y: 0}, {x: wallData.x, y: 0}, {x: wallData.x, y: wallData.height}]}
      ];
    }

    var leftMostWallData = _.minBy(wallsData, wallData => _.min(_.map(wallData.outlinePoints, 'x')));

    const fromIsInRoom = lib.polygon.pointInsidePolygon({point: elevation.lineInRoom.from, polygon: room.plan.points});

    var firstWallXInset = fromIsInRoom ? 0 : (_.min(_.map(leftMostWallData?.outlinePoints, 'x')) || 0);

    var firstWallOrigin = viewOffset;
    var strokeWidth = K.drawingsStrokeWidths.light * canvasData.scale;
    var shouldScaleStrokeWidth = activeDetailLevel !== 'rendering' && !renderForDrawings;
    var drawingsArchitectureStrokeWidth = (K.drawingsStrokeWidths.heavy + 0.75) * 2;

    //HINT this strokewidth is used by floor/ceiling lines for sections and islands
    //for other walls half of the stroke is obstructed by wall fill
    if (renderForDrawings) strokeWidth = drawingsArchitectureStrokeWidth / 2;

    if (isSection && wallsData.length) {
      firstWallOrigin = lib.object.sum(viewOffset, {x: firstWallXInset});

      var sideKey = Elevation.getWallTheta({elevation, wall: walls[0]}) > Math.PI ? 'left' : 'right';
      var sideKeyScalar = sideKey === 'right' ? -1 : 1;
      var widthOutsideRoom = 0;

      const toIsInRoom = lib.polygon.pointInsidePolygon({point: elevation.lineInRoom.to, polygon: room.plan.points});

      if (!toIsInRoom) {
        widthOutsideRoom = elevationWidth - _.max(_.map(wallsData, wallData => _.min(_.map(wallData.outlinePoints, 'x'))));
      }

      var floorCeilingWidth = (elevationWidth - firstWallXInset - widthOutsideRoom);
    }

    var strokeWidthFloorTransform = {x: sideKeyScalar * strokeWidth, y: strokeWidth / 2};
    var strokeWidthCeilingTransform = {x: sideKeyScalar * strokeWidth, y: -strokeWidth / 2};
    var ceilingHeight = wallsData.length ? Wall.getHeight({wall: walls[0]}) : room.ceilingHeight;

    var datumYValues = _.map(_.split(datums, ','), datum => {
      try {
        return parseFloat(eval(datum));
      }
      catch (e) {
        return undefined;
      }
    });

    datumYValues = _.filter(datumYValues, datumYValue => datumYValue && datumYValue > 0);

    var titleY = 60;

    if (showProjections) {
      //HINT projection margin + dimension height + unit heights
      titleY = (Elevation.bottomProjectionHeightFor({elevation, drawingsMode: 'production'}) || 30) + 30;
    }

    var roomTitle = _.get(room, 'title', '');

    var floorPoints, ceilingPoints;
    var renderingXOffset = 45;
    var renderingYOffset = _.get(elevation, 'customData.floorRenderingGradientHeight', 30);

    if (activeDetailLevel === 'rendering') {
      var sectionOrigin = {x: firstWallXInset, y: 0};

      if (showWallsAndArchElements && isSection && wallsData.length > 0) {
        var floorPoints = [
          sectionOrigin,
          lib.object.sum(sectionOrigin, {x: floorCeilingWidth, y: 0})
        ];

        var ceilingPoints = [
          lib.object.sum(sectionOrigin, {y: -ceilingHeight}),
          lib.object.sum(sectionOrigin, {x: floorCeilingWidth, y: -ceilingHeight})
        ];
      }
      else if (wallsData.length === 0 || !showWallsAndArchElements) {
        floorPoints = [
          lib.object.sum(sectionOrigin, {x: (wallsData.length === 0 ? -20 : 0)}),
          lib.object.sum(sectionOrigin, {x: Elevation.getWidth({elevation}) + (wallsData.length === 0 ? 20 : 0)})
        ];

        ceilingPoints = _.map(floorPoints, point => ({...point, y: -room.ceilingHeight || K.defaultWallHeight}));
      }
      else {
        floorPoints = _.orderBy(_.filter(outline, point => point.y > -30), 'x');
        ceilingPoints = _.orderBy(_.filter(outline, point => point.y < -30), 'x');
      }
    }

    var filteredContainers = _.filter(containers, container => !!Container.getScript({container, elevation, viewKey: 'front'}));

    var sectionTrapezoids = [];

    //walls, arch elements, containers, project graphics
    var orderedEntities = _.orderBy([
      //TODO walls and section lines
      ..._.map(volumes, volume => ({...volume, resourceKey: 'volume', zIndex: Volume.getZIndex({volume, elevation, viewKey: 'front'})})), ..._.map(filteredContainers, container => ({...container, resourceKey: 'container', zIndex: Container.getZIndex({container, elevation, viewKey: 'front'})})),
      ...(showWallsAndArchElements ? _.map(archElements, archElement => ({...archElement, resourceKey: 'archElement', zIndex: ArchElement.getZIndex({archElement, elevation, viewKey: 'front'})})) : []),
      ..._.map(_.reject(projectGraphics, {type: 'background'}), projectGraphic => ({...projectGraphic, resourceKey: 'projectGraphic', zIndex: ProjectGraphic.getZIndex({projectGraphic, elevation, viewKey: 'front'})})),
      ..._.map(wallsData, wallData => ({...wallData, resourceKey: 'wall'}))
    ], 'zIndex');

    // if (activeDetailLevel === 'rendering' && showPerspective) {
    //   var minZIndex = _.first([..._.filter(orderedEntities, entity => entity.resourceKey !== 'projectGraphic'), ..._.map(walls, wall => {
    //     return {...wall, zIndex: Wall.getZIndexFor({wall, elevation})};
    //   })])?.zIndex;
    //   var maxZIndex = _.min([0, _.last(_.filter(orderedEntities, entity => entity.resourceKey !== 'projectGraphic'))?.zIndex]);

    //   var totalDepthVariation = Math.abs(maxZIndex - minZIndex);
    //   var xOrigin = (Math.abs(_.last(floorPoints).x - _.first(floorPoints).x) / 2) - firstWallXInset;
    //   var yOrigin = 35;
    //   //HINT should scale so that 25" off back wall is ~5 pixels
    //   var scaleVariationFactor = totalDepthVariation * (0.2) / 115;

    //   orderedEntities = _.map(orderedEntities, entity => {
    //     if (entity.resourceKey !== 'projectGraphic') {
    //       var entityRelativeDepth = Math.abs(_.min([0, entity.zIndex]) - minZIndex);

    //       var depthVariationRatio = (((totalDepthVariation + entityRelativeDepth) / totalDepthVariation) - 1) * scaleVariationFactor + 1;
    //       entity.scaleX = 1;//depthVariationRatio;
    //       entity.scaleY = depthVariationRatio;

    //       //getOffsetDirection
    //       //if left of centerline move left
    //       //if right of cetnerline move right
    //       //if overlapping with center move half offset to left
    //       //

    //       var size, positionInElevation;

    //       if (_.includes(['volume', 'container'], entity.resourceKey)) {
    //         var Resource = entity.resourceKey === 'container' ? Container : Volume;
    //         var sideKey = Resource.getSideKey({[entity.resourceKey]: entity, elevation, viewKey: 'front'});

    //         size = {width: entity.dimensions[K.sideSizeMap[sideKey].width], height: entity.dimensions[K.sideSizeMap[sideKey].height]};
    //         positionInElevation = lib.object.sum(Resource.getPositionInElevation({[entity.resourceKey]: entity, elevation}));
    //       }
    //       else if (entity.resourceKey === 'archElement') {
    //         size = ArchElement.getSize({archElement: entity, viewKey: 'front'});
    //         var wall = _.find(allWalls, {id: entity.wallId});
    //         var wallPosition = {x: Wall.getXOffsetInElevation({wall, elevation})};

    //         positionInElevation = lib.object.sum(wallPosition, {x: entity.position.x, y: -entity.position.y - size.height});
    //       }

    //       var fromXDistanceFromOrigin = positionInElevation.x - xOrigin;
    //       var toXDistanceFromOrigin = positionInElevation.x + size.width - xOrigin;

    //       var midpointXDistanceFromCenter = (fromXDistanceFromOrigin + toXDistanceFromOrigin) / 2;

    //       var xSizeDelta = -(size.width * (entity.scaleX - 1)) / 2;

    //       var fromYDistanceFromOrigin = positionInElevation.y + yOrigin;
    //       var toYDistanceFromOrigin = positionInElevation.y - size.height + yOrigin;

    //       var midpointYDistanceFromCenter = (fromYDistanceFromOrigin + toYDistanceFromOrigin) / 2;

    //       var ySizeDelta = (size.height * (entity.scaleY - 1)) / 2;

    //       entity.scaleOffset = {
    //         x: xSizeDelta + midpointXDistanceFromCenter * (entity.scaleX - 1),
    //         y: ySizeDelta + midpointYDistanceFromCenter * (entity.scaleY - 1),
    //       };

    //       var newPositionInElevation = lib.object.sum(positionInElevation, entity.scaleOffset);

    //       var backZIndex = entity.zIndex;

    //       if (_.includes(['container', 'volume'], entity.resourceKey)) {
    //         var depthOffset = 0;

    //         if (sideKey === 'front') depthOffset = -entity.dimensions.depth;
    //         else if (_.includes(['left', 'right'], sideKey)) depthOffset = -entity.dimensions.width;

    //         backZIndex = backZIndex + depthOffset;
    //       }

    //       if (entity.resourceKey === 'container' && !Container.getTypeDataFor({container: entity}).isOrnament) {
    //         var fill = Container.getFill({container: entity, elevation, activeDetailLevel, activeFillMode});
    //         var hatchFillData = Container.getHatchFillData({container: entity, viewKey: 'front', activeFillMode, activeDetailLevel});
    //         var {hatchFills} = hatchFillData;

    //         sectionTrapezoids.push(
    //           {
    //             entity,
    //             zIndex: backZIndex,
    //             resourceKey: 'sectionTrapezoid',
    //             id: `top-trapezoid-${entity.id}`,
    //             points: [
    //               lib.object.sum(newPositionInElevation, {y: size.height * entity.scaleY}),
    //               lib.object.sum(positionInElevation, {y: size.height}),
    //               lib.object.sum(positionInElevation, {x: size.width, y: size.height}),
    //               lib.object.sum(newPositionInElevation, {x: size.width * entity.scaleX, y: size.height * entity.scaleY}),
    //             ],
    //             fill: hatchFills.topCapPanelMaterial || hatchFills.boxMaterial || hatchFills.countertopMaterial || fill
    //           },
    //           {
    //             entity,
    //             zIndex: backZIndex,
    //             resourceKey: 'sectionTrapezoid',
    //             id: `bottom-trapezoid-${entity.id}`,
    //             points: [
    //               newPositionInElevation,
    //               lib.object.sum(positionInElevation),
    //               lib.object.sum(positionInElevation, {x: size.width}),
    //               lib.object.sum(newPositionInElevation, {x: size.width * entity.scaleX}),
    //             ],
    //             fill: hatchFills.bottomCapPanelMaterial || hatchFills.boxMaterial || hatchFills.countertopMaterial || fill
    //           },
    //           // {
    //           //   entity,
    //           //   zIndex: backZIndex,
    //           //   resourceKey: 'sectionTrapezoid',
    //           //   id: `left-trapezoid-${entity.id}`,
    //           //   points: [
    //           //     lib.object.sum(newPositionInElevation, {y: size.height * entity.scaleY}),
    //           //     lib.object.sum(positionInElevation, {y: size.height}),
    //           //     positionInElevation,
    //           //     lib.object.sum(newPositionInElevation),
    //           //   ],
    //           //   fill: hatchFills.endPanelMaterial || hatchFills.boxMaterial || hatchFills.countertopMaterial || fill
    //           // },
    //           // {
    //           //   entity,
    //           //   zIndex: backZIndex,
    //           //   resourceKey: 'sectionTrapezoid',
    //           //   id: `right-trapezoid-${entity.id}`,
    //           //   points: [
    //           //     lib.object.sum(newPositionInElevation, {x: size.width * entity.scaleX, y: size.height * entity.scaleY}),
    //           //     lib.object.sum(positionInElevation, {x: size.width, y: size.height}),
    //           //     lib.object.sum(positionInElevation, {x: size.width}),
    //           //     lib.object.sum(newPositionInElevation, {x: size.width * entity.scaleX}),
    //           //   ],
    //           //   fill: hatchFills.endPanelMaterial || hatchFills.boxMaterial || hatchFills.countertopMaterial || fill
    //           // },
    //         );
    //       }
    //     }

    //     return entity;
    //   });

    //   orderedEntities = _.orderBy([...orderedEntities, ...sectionTrapezoids], 'zIndex');
    // }

    var isIsle = wallsData.length === 0 && sectionCutLinesData.length === 2;

    var floorLineFrom = {x: 0, y: firstWallOrigin.y};
    var floorLineTo = {x: 0, y: firstWallOrigin.y};
    var ceilingLineFrom = {x: 0, y: viewOffset.y};
    var ceilingLineTo = {x: 0, y: viewOffset.y};

    var floorLineStrokeWidth = strokeWidth;

    var floorLineShowing = wallsData.length === 0 || !showWallsAndArchElements;
    var ceilingLineShowing = floorLineShowing && sectionCutLinesData.length === 2 && !_.get(elevation, 'customData.hideIsleCeilingLine');

    if (floorLineShowing) {
      floorLineFrom.x = firstWallOrigin.x + (wallsData.length === 0 ? -20 : 0);
      floorLineTo.x = firstWallOrigin.x + Elevation.getWidth({elevation}) + (wallsData.length === 0 ? 20 : 0);

      if (isIsle) {
        floorLineFrom.x = viewOffset.x + _.min([sectionCutLinesData[0].x, sectionCutLinesData[1].x]);
        floorLineTo.x = viewOffset.x + _.max([sectionCutLinesData[0].x, sectionCutLinesData[1].x]);
        floorLineStrokeWidth = (activeDetailLevel === 'rendering' ? 1 : ((renderForDrawings ? drawingsArchitectureStrokeWidth : K.drawingsStrokeWidths.heavy)) / 2);

        if (!visibilityLayers.isExportingDxf) {
          //HINT interaction between section cut wall lines and floor line
          floorLineFrom.y += !renderForDrawings ? 1/8 : 0;
          floorLineTo.y += !renderForDrawings ? 1/8 : 0;
          ceilingLineFrom.y -= !renderForDrawings ? 3/8 : 3/4;
          ceilingLineTo.y -= !renderForDrawings ? 3/8 : 3/4;
        }

        ceilingLineFrom = PositionHelper.toCanvas({x: floorLineFrom.x, y: ceilingLineFrom.y + _.minBy(sectionCutLinesData, 'x').height}, canvasData);
        ceilingLineTo = PositionHelper.toCanvas({x: floorLineTo.x, y: ceilingLineTo.y + _.maxBy(sectionCutLinesData, 'x').height}, canvasData);

        if (shouldScaleStrokeWidth) floorLineStrokeWidth *= canvasData.scale;
      }
    }

    var hideWallFill = !renderForDrawings && visibilityLayers.backgroundsVisible && _.filter(projectGraphics, {type: 'background'}).length > 0;

    return (<>
      {visibilityLayers.backgroundsVisible && _.map(_.filter(projectGraphics, {type: 'background'}), projectGraphic => {
        return (<CanvasProjectGraphic
          key={projectGraphic.id}
          viewKey={'front'}
          preventEditing={preventEditing || !visibilityLayers.backgroundsSelectable}
          {...{projectGraphic, room, elevation, viewOffset, firstWallXInset, renderForDrawings, isExportingSvg}}
        />);
      })}
      {(showWallsAndArchElements && isSection && wallsData.length > 0) && (<>
        {/* floor - only shown in section */}
        <CanvasLine
          from={lib.object.sum(PositionHelper.toCanvas(firstWallOrigin, canvasData), strokeWidthFloorTransform)}
          to={lib.object.sum(PositionHelper.toCanvas(lib.object.sum(firstWallOrigin, {x: floorCeilingWidth}), canvasData), strokeWidthFloorTransform)}
          stroke={'black'}
          strokeWidth={strokeWidth}
          listening={false}
        />
        {/* ceiling - only shown in section */}
        <CanvasLine
          from={lib.object.sum(PositionHelper.toCanvas(lib.object.sum(firstWallOrigin, {y: -ceilingHeight}), canvasData), strokeWidthCeilingTransform)}
          to={lib.object.sum(PositionHelper.toCanvas(lib.object.sum(firstWallOrigin, {x: floorCeilingWidth, y: -ceilingHeight}), canvasData), strokeWidthCeilingTransform)}
          stroke={'black'}
          strokeWidth={strokeWidth}
          listening={false}
        />
        {/* wall - only shown in section */}
        <CanvasLine
          from={lib.object.sum(PositionHelper.toCanvas(lib.object.sum(firstWallOrigin, {x: (sideKeyScalar === 1 ? 1 : 0) * floorCeilingWidth}), canvasData), strokeWidthFloorTransform, {x: -strokeWidth / 2 * sideKeyScalar})}
          to={lib.object.sum(PositionHelper.toCanvas(lib.object.sum(firstWallOrigin, {x: (sideKeyScalar === 1 ? 1 : 0) * floorCeilingWidth, y: -ceilingHeight}), canvasData), strokeWidthCeilingTransform, {x: -strokeWidth / 2 * sideKeyScalar})}
          stroke={'black'}
          strokeWidth={strokeWidth}
          listening={false}
        />
      </>)}
      {floorLineShowing && (<>
        {/* floor - only shown for island elevations */}
        <CanvasLine
          from={lib.object.sum(PositionHelper.toCanvas(floorLineFrom, canvasData), strokeWidthFloorTransform)}
          to={lib.object.sum(PositionHelper.toCanvas(floorLineTo, canvasData), strokeWidthFloorTransform)}
          stroke={'#333333'}
          strokeWidth={floorLineStrokeWidth}
          listening={false}
        />
      </>)}
      {ceilingLineShowing && (<>
        {/* floor - only shown for island elevations */}
        <CanvasLine
          from={ceilingLineFrom}
          to={ceilingLineTo}
          stroke={'#333333'}
          strokeWidth={floorLineStrokeWidth}
          listening={false}
        />
      </>)}
      {showWallsAndArchElements && !isSection && (
        _.map(sectionCutLinesData, ({x, height, wall}, i) => {
          var strokeWidth = activeDetailLevel === 'rendering' ? 1 : ((renderForDrawings ? drawingsArchitectureStrokeWidth : K.drawingsStrokeWidths.heavy));
          var strokeWidthOffset = shouldScaleStrokeWidth ? strokeWidth : (strokeWidth / canvasData.scale);

          //HINT isle elevation is an elevation with 2 section cut walls, but no real walls
          //HINT this is a bandaid until we have the ceiling tool to accurately draw lines
          if (isIsle) {
            //HINT still want strokeWidthOffset to be double stroke to grow past floor and ceiling line
            strokeWidth = strokeWidth / 2;

            //HINT in isle elevations there are only 2 walls
            var isLeftWall = x === _.minBy(sectionCutLinesData, 'x').x;

            //HINT want to move by half of the new strokewidth, so 1/4th of the y offset
            x = x + (strokeWidthOffset / 4 * (isLeftWall ? -1 : 1));
          }

          if (visibilityLayers.isExportingDxf) strokeWidthOffset = 0;

          if (hideWallFill) {
            strokeWidth = strokeWidth / 2;

            var isLeftWall = x === _.minBy(sectionCutLinesData, 'x').x;

            x = x + (strokeWidthOffset / 4 * (isLeftWall ? -1 : 1));
          }

          return <CanvasLine
            from={PositionHelper.toCanvas(lib.object.sum(viewOffset, {x, y: 0 + (strokeWidthOffset / 2)}), canvasData)}
            to={PositionHelper.toCanvas(lib.object.sum(viewOffset, {x, y: height - strokeWidthOffset / 2}), canvasData)}
            key={`${x}-${wall.id}-${i}-section-cut`}
            stroke={activeDetailLevel === 'rendering' ? 'transparent' : '#333333'}
            strokeWidth={strokeWidth * (shouldScaleStrokeWidth ? canvasData.scale : 1)}
          />;
        })
      )}
      {/* {showWallsAndArchElements && !isSection && _.map(wallsData, ({wall, wallX, outlinePoints}) => {
        var position = {x: viewOffset.x + wallX, y: viewOffset.y};
        var outlineLines = _.map(outlinePoints, (point, i) => ({from: point , to: lib.array.next(outlinePoints, i)}));
        var outlineLinesByVerticality = _.groupBy(outlineLines, line => {
          //HINT line is a floor or ceiling line
          return _.some(line, point => point.y > -10) && _.some(line, point => point.y < -50);
        });
        var minWallX = _.min(_.map(outlinePoints, 'x'));
        var maxWallX = _.max(_.map(outlinePoints, 'x'));

        return (
          <React.Fragment key={`${elevation.id}-${wall.id}-outline-fill`}>
            {_.map(outlineLinesByVerticality.false, (line, i) => {
              var offsetLine = _.mapValues(line, point => lib.object.sum(point, viewOffset));
              var strokeWidth = activeDetailLevel === 'rendering' ? 1 : K.drawingsStrokeWidths.heavy;
              var shouldScaleStrokeWidth = !renderForDrawings && activeDetailLevel !== 'rendering';

              //HINT extend lines so there aren't cutouts on corners
              if (!_.some(line, point => {
                return _.includes([minWallX], point.x);
              }) && !visibilityLayers.isExportingDxf) {
                var strokeWidthOffset = shouldScaleStrokeWidth ? strokeWidth : (strokeWidth / canvasData.scale);
                var isVertical = _.every(offsetLine, point => point.x === offsetLine.from.x);
                var isHorizontal = _.every(offsetLine, point => point.y === offsetLine.from.y);

                if (isVertical || isHorizontal) {
                  var axisKey = isVertical ? 'y' : 'x';

                  _.find(offsetLine, {[axisKey]: _.min(_.map(offsetLine, axisKey))})[axisKey] -= strokeWidthOffset / 2;
                }
                else {
                  var rangeKey = _.findKey(offsetLine, {y: _.min(_.map(offsetLine, 'y'))});

                  offsetLine = lib.trig.extend({line: offsetLine, by: Math.sin(lib.trig.alpha({p1: offsetLine.from, p2: offsetLine.to})) * strokeWidthOffset, rangeKey})
                }
              }

              if (shouldScaleStrokeWidth) strokeWidth *= canvasData.scale;

              return (
                <CanvasLine
                  from={PositionHelper.toCanvas(offsetLine.from, canvasData)}
                  to={PositionHelper.toCanvas(offsetLine.to, canvasData)}
                  key={`${elevation.id}-${wall.id}-${i}-stroke`}
                  stroke={activeDetailLevel === 'rendering' ? 'transparent' : '#333333'}
                  strokeWidth={strokeWidth}
                />
              );
            })}
            <EditableCanvasPolyline
              fill={'#f0f0f0'}
              preventFillSelect
              {...{isSelected: isSelected && (!activeWallId || activeWallId === wall.id), strokeWidth: activeDetailLevel === 'rendering' ? 1 : 0.5}}
              stroke={'transparent'}
              key={wall.id}
              position={position}
              points={_.map(outlinePoints, point => ({x: point.x - wallX, y: point.y}))}
              isEnabled={!isSection && !canvasData.isStatic && wallX >= 0 && _.max(_.map(outlinePoints, 'x')) <= elevationWidth}
              isDraggable={false}
              onSelect={this.handleSelect(wall)}
              onAddPoint={this.handleAddPoint(wall)}
              onDeletePoint={this.handleDeleteWallPoint(wall)}
              onPointDragEnd={this.handlePointDragEnd(wall)}
              closed
            />
            {_.map(outlineLinesByVerticality.true, (line, i) => {
              var shouldRender = false;
              var offsetLine = _.mapValues(line, point => lib.object.sum(point, viewOffset));

              //HINT is extrema line of wall
              if (_.every(_.map(line, point => lib.object.sum(point, {x: -Wall.getXOffsetInElevation({wall, elevation})})), point => {
                return _.some(wall.outline.points, wallPoint => wallPoint.x === point.x && wallPoint.y === point.y);
              })) {
                shouldRender = true;
              }

              return shouldRender ? (
                <CanvasLine
                  from={PositionHelper.toCanvas(offsetLine.from, canvasData)}
                  to={PositionHelper.toCanvas(offsetLine.to, canvasData)}
                  key={`${elevation.id}-${wall.id}-${i}-stroke`}
                  stroke={activeDetailLevel === 'rendering' ? '#BBBBBB' : '#333333'}
                  listening={false}
                />
              ) : null;
            })}
          </React.Fragment>
        );
      })} */}
      {activeDetailLevel === 'rendering' && (
        <>
          <NoiseImage {...{
            opacity: 0.5,
            width: (Elevation.getWidth({elevation}) + 50) * canvasData.scale,
            height: (Elevation.getHeight({elevation}) + 10) * canvasData.scale,
            ...lib.object.sum(PositionHelper.toCanvas(lib.object.sum(viewOffset, {x: -25, y: -Elevation.getHeight({elevation})}), canvasData)),
          }}
          />
          {!isExportingSvg && (<EditableCanvasPolyline
            stroke={'transparent'}
            fillProps={{
              fillPriority: 'linear-gradient',
              fillLinearGradientStartPoint: { x: (_.last(floorPoints).x + _.first(floorPoints).x) / 2, y: 0 },
              fillLinearGradientEndPoint: {x: (_.last(floorPoints).x + _.first(floorPoints).x) / 2, y: renderingYOffset * canvasData.scale },
              fillLinearGradientColorStops: [
                0,
                _.get(elevation, 'customData.floorRenderingGradientFill') ? Color(elevation.customData.floorRenderingGradientFill).alpha(elevation.customData.floorRenderingGradientFromOpacity || 0.1).hexa() : 'rgba(0, 0, 0, 0.1)',
                1,
                _.get(elevation, 'customData.floorRenderingGradientFill') ? Color(elevation.customData.floorRenderingGradientFill).alpha(0).hexa() : 'rgba(0, 0, 0, 0)',
              ]
            }}
            position={lib.object.sum(viewOffset, {x: 0, y: 0})}
            points={[
              ...floorPoints,
              ..._.map(_.reverse(floorPoints), (point, i) => {
                var xOffset = i === 0 ? renderingXOffset : ((i === floorPoints.length - 1) ? -renderingXOffset : 0);

                return lib.object.sum(point, {x: xOffset, y: renderingYOffset});
              }),
              _.last(floorPoints)
            ]}
            listening={false}
            closed
          />)}
          {/* {_.map(ceilingPoints, (point, i) => {
            var nextPoint = ceilingPoints[i + 1];

            if (!nextPoint) return null;

            var xOffset = i === 0 ? renderingXOffset : ((i === ceilingPoints.length - 1) ? -renderingXOffset : 0);

            return (<EditableCanvasPolyline
              stroke={'transparent'}
              fillProps={{
                fillPriority: "linear-gradient",
                fillLinearGradientStartPoint: { x: (point.x + nextPoint.x) / 2, y: _.maxBy([point, nextPoint], 'y').y * canvasData.scale},
                fillLinearGradientEndPoint: {x: (point.x + nextPoint.x) / 2, y: ((point.y - renderingYOffset + nextPoint.y - renderingYOffset) / 2) * canvasData.scale},
                fillLinearGradientColorStops: [
                  0,
                  "rgba(0, 0, 0, 0.2)",
                  1,
                  "rgba(0, 0, 0, 0)",
                ]
              }}
              position={lib.object.sum(viewOffset, {x: 0, y: 0})}
              points={[
                point,
                nextPoint,
                lib.object.sum(nextPoint, {x: 0, y: -renderingYOffset}),
                lib.object.sum(point, {x: 0, y: -renderingYOffset}),
                point
              ]}
              listening={false}
              closed
            />)
          })} */}
          {/* {false && (<EditableCanvasPolyline
            stroke={'transparent'}
            fillProps={{
              fillPriority: "linear-gradient",
              fillLinearGradientStartPoint: { x: (_.last(ceilingPoints).x + _.first(ceilingPoints).x) / 2, y: _.maxBy(ceilingPoints, 'y').y * canvasData.scale},
              fillLinearGradientEndPoint: {x: (_.last(ceilingPoints).x + _.first(ceilingPoints).x) / 2, y: (_.minBy(ceilingPoints, 'y').y - renderingYOffset) * canvasData.scale},
              fillLinearGradientColorStops: [
                0,
                "rgba(0, 0, 0, 0.2)",
                1,
                "rgba(0, 0, 0, 0)",
              ]
            }}
            position={lib.object.sum(viewOffset, {x: 0, y: 0})}
            points={[
              ...ceilingPoints,
              ..._.map(_.reverse(ceilingPoints), (point, i) => {
                var xOffset = i === 0 ? renderingXOffset : ((i === ceilingPoints.length - 1) ? -renderingXOffset : 0);

                return lib.object.sum(point, {x: xOffset, y: -renderingYOffset});
              }),
              _.last(ceilingPoints)
            ]}
            listening={false}
            closed
          />)} */}
        </>
      )}
      {_.map(orderedEntities, entity => {
        if (entity.resourceKey === 'wall') {
          var {wall, wallX, outlinePoints, showingCompleteOutline} = entity;
          var position = {x: viewOffset.x + wallX, y: viewOffset.y};
          var outlineLines = _.map(outlinePoints, (point, i) => ({from: point, to: lib.array.next(outlinePoints, i)}));
          var outlineLinesByVerticality = _.groupBy(outlineLines, line => {
            //HINT line is a floor or ceiling line
            return _.some(line, point => point.y > -10) && _.some(line, point => point.y < -50);
          });
          var minWallX = _.min(_.map(outlinePoints, 'x'));
          var maxWallX = _.max(_.map(outlinePoints, 'x'));

          return showWallsAndArchElements && !isSection ? (
            <React.Fragment key={`${elevation.id}-${wall.id}-outline-fill`}>
              {_.map(outlineLinesByVerticality.false, (line, i) => {
                var offsetLine = _.mapValues(line, point => lib.object.sum(point, viewOffset));
                var strokeWidth = activeDetailLevel === 'rendering' ? 1 : (renderForDrawings ? drawingsArchitectureStrokeWidth : K.drawingsStrokeWidths.heavy);

                //HINT extend lines so there aren't cutouts on corners
                if (!_.some(line, point => {
                  return _.includes([minWallX], point.x);
                }) && !visibilityLayers.isExportingDxf) {
                  var strokeWidthOffset = shouldScaleStrokeWidth ? strokeWidth : (strokeWidth / canvasData.scale);
                  var isVertical = _.every(offsetLine, point => point.x === offsetLine.from.x);
                  var isHorizontal = _.every(offsetLine, point => point.y === offsetLine.from.y);

                  if (isVertical || isHorizontal) {
                    var axisKey = isVertical ? 'y' : 'x';

                    _.find(offsetLine, {[axisKey]: _.min(_.map(offsetLine, axisKey))})[axisKey] -= strokeWidthOffset / 2;
                  }
                  else {
                    var rangeKey = _.findKey(offsetLine, {y: _.min(_.map(offsetLine, 'y'))});

                    offsetLine = lib.trig.extend({line: offsetLine, by: Math.sin(lib.trig.alpha({p1: offsetLine.from, p2: offsetLine.to})) * strokeWidthOffset, rangeKey});
                  }
                }

                if (hideWallFill) {
                  strokeWidth = strokeWidth / 2;

                  var alpha = lib.trig.normalize({radians: lib.trig.alpha({p1: offsetLine.from, p2: offsetLine.to}) + (Math.PI / 2)});
                  offsetLine = _.mapValues(offsetLine, point => lib.object.sum(point, lib.trig.rotate({point: {y: 0, x: -1 * strokeWidth / 2}, byRadians: alpha})));
                }

                if (shouldScaleStrokeWidth) strokeWidth *= canvasData.scale;

                return (
                  <CanvasLine
                    from={PositionHelper.toCanvas(offsetLine.from, canvasData)}
                    to={PositionHelper.toCanvas(offsetLine.to, canvasData)}
                    key={`${elevation.id}-${wall.id}-${i}-stroke`}
                    stroke={activeDetailLevel === 'rendering' ? 'transparent' : '#333333'}
                    strokeWidth={strokeWidth}
                  />
                );
              })}
              <EditableCanvasPolyline
                fill={'#f0f0f0'}
                fillOpacity={hideWallFill ? 0.3 : undefined}
                {...{isSelected: isSelected && showingCompleteOutline && (!activeWallId || activeWallId === wall.id)}}
                stroke={'transparent'}
                strokeWidth={strokeWidth}
                key={wall.id}
                position={position}
                points={_.map(outlinePoints, point => ({x: point.x - wallX, y: point.y}))}
                isEnabled={!isSection && !canvasData.isStatic && !renderForDrawings && !preventEditing}
                isDraggable={false}
                onSelect={this.handleSelect({wall, showingCompleteOutline})}
                onAddPoint={this.handleAddPoint({wall, showingCompleteOutline})}
                onDeletePoint={this.handleDeleteWallPoint({wall, showingCompleteOutline})}
                onPointDragEnd={this.handlePointDragEnd({wall, showingCompleteOutline})}
                closed
              />
              {_.map(outlineLinesByVerticality.true, (line, i) => {
                var shouldRender = false;
                var offsetLine = _.mapValues(line, point => lib.object.sum(point, viewOffset));
                var strokeWidth = (activeDetailLevel === 'rendering' ? 1 : (K.drawingsStrokeWidths.heavy / 2));

                //HINT is extrema line of wall and line is not already drawn by sectionCutLinesData
                if (_.every(_.map(line, point => lib.object.sum(point, {x: -Wall.getXOffsetInElevation({wall, elevation})})), point => {
                  return _.some(wall.outline.points, wallPoint => wallPoint.x === point.x && wallPoint.y === point.y);
                }) && !_.some(sectionCutLinesData, sectionCut => {
                  return sectionCut.x === line.from.x && sectionCut.x === line.to.x && (sectionCut.height === line.from.y || sectionCut.height === line.to.y);
                })) {
                  shouldRender = true;
                }

                return shouldRender ? (
                  <CanvasLine
                    from={PositionHelper.toCanvas(offsetLine.from, canvasData)}
                    to={PositionHelper.toCanvas(offsetLine.to, canvasData)}
                    key={`${elevation.id}-${wall.id}-${i}-stroke`}
                    stroke={activeDetailLevel === 'rendering' ? '#BBBBBB' : '#333333'}
                    strokeWidth={strokeWidth}
                    listening={false}
                  />
                ) : null;
              })}
            </React.Fragment>
          ) : null;
        }
        else {
          var EntityComponent = {
            volume: <CanvasVolume
              key={entity.id}
              viewKey={'front'}
              {...{id: entity.id, elevation, viewOffset, activeDetailLevel, activeFillMode, showPerspective, renderForDrawings, preventEditing, ..._.pick(entity, ['scaleX', 'scaleY', 'scaleOffset'])}}
            />,
            container: visibilityLayers.architectureOnly ? null : <CanvasContainer
              key={entity.id}
              viewKey={'front'}
              {...{id: entity.id, elevation, viewOffset, showRevealSymbols, showUnitNumbers, showGrainFlow, showProjections, showPerspective, room,
                activeDetailLevel, activeFillMode, activeUserLense, showWallsAndArchElements, showCanvasSettings, showProductDetails, renderForDrawings, preventEditing, ..._.pick(entity, ['scaleX', 'scaleY', 'scaleOffset'])
              }}
            />,
            archElement: <CanvasArchElement
              key={entity.id}
              viewKey={'front'}
              {...{id: entity.id, elevation, viewOffset, activeDetailLevel, showPerspective, renderForDrawings, preventEditing, ..._.pick(entity, ['scaleX', 'scaleY', 'scaleOffset'])}}
              {..._.pick(this.props, ['canvasDeps'])}
            />,
            projectGraphic: <CanvasProjectGraphic
              key={entity.id}
              viewKey={'front'}
              {...{projectGraphic: entity, room, elevation, viewOffset, firstWallXInset, renderForDrawings, preventEditing, isExportingSvg}}
            />,
            sectionTrapezoid: <CanvasPath key={entity.id} points={entity.points} stroke={'black'} strokeWidth={0.25} {...PositionHelper.toCanvas(viewOffset, canvasData)} fill={entity.fill} closed/>
          }[entity.resourceKey];

          return (
            <React.Fragment key={`${entity.id}-${entity.resourceKey}`}>
              {EntityComponent}
            </React.Fragment>
          );
        }
      })}
      {showingAlignmentIndicators && _.map(alignmentIndicatorLines, (alignmentIndicatorLine, index) => (
        <EditableCanvasLine key={index} {...alignmentIndicatorLine} />
      ))}

      {/* {showDimensions && (
        <CanvasDimensionGroup lines={dimensionLines} elevation={elevation} offset={viewOffset} firstWallXInset={firstWallXInset} parentOrigin={lib.object.sum({x: (isSection && wallsData.length) ? 0 : (Elevation.getMinWallX({elevation}) || 0)}, firstWallOrigin)} {...{showBindingDimensions}}/>
      )} */}
      {showDimensions && !visibilityLayers.isExportingDxf && (
        _.map(global.dimensionsByEntityType.elevation[elevation.id], dimensionSet => (
          <CanvasDimensionLine key={dimensionSet.id} {...{elevation, viewOffset, viewKey: 'front', dimensionSet, showBindingDimensions, renderForDrawings, isExportingSvg}}/>
        ))
      )}
      {showDatums && (
        _.map(datumYValues, (datum, index) => {
          return (
            <CanvasDatum
              key={index}
              {...{datum, room, viewOffset, elevation}}
              type={'y'}
              viewKey={'front'}
            />
          );
        })
      )}
      {showDatums && (
        _.map(xzDatums, (datum, index) => {
          return (
            <CanvasDatum
              key={index}
              {...{datum, room, viewOffset, elevation}}
              type={'xz'}
              viewKey={'front'}
            />
          );
        })
      )}
      {/* {!hideTitle && !visibilityLayers.canvasSettingsProductDetails && <CanvasText
        text={`${roomTitle ? `${roomTitle} - ` : ''} ${Elevation.getTitle({elevation})}`}
        fontSize={canvasData.scale * 8}
        verticalAlign='top'
        fontStyle='bold'
        align='center'
        listening={false}
        {...lib.object.sum(PositionHelper.toCanvas(lib.object.sum(viewOffset, {x: elevationWidth / 2, y: titleY}), canvasData))}
      />} */}
      {/* {showWallsAndArchElements && (
        <CanvasLine
          stroke='black'
          points={_.flatMap(_.map(_.filter([...outline || [], _.get(outline, '[0]')]), point => PositionHelper.toCanvas(lib.object.sum(viewOffset, point), this.props.canvasData)), point => [point.x, point.y])}
          strokeWidth={strokeWidth}
          listening={false}
          closed
          />
        )} */}
      {visibilityLayers.canvasSettingsProductDetails && (
        <CanvasLineItems
          elevation={elevation}
          position={lib.object.sum(PositionHelper.toCanvas(lib.object.sum(viewOffset, {x: (elevationWidth / 2 - (160 / 2)), y: titleY}), canvasData))}
        />
      )
      }
    </>);
  }
}

function CanvasElevationWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  let selectionData = useContext(CanvasSelectionContext);
  let projectData = useContext(ProjectDataContext);

  if (props.renderForContextCanvas) {
    projectData = _.omit(projectData, ['dimensionsData']);
    selectionData = {};
  }
  else if (props.renderForDrawings) {
    selectionData = {};
  }

  return <CanvasElevation {...props} {...{canvasData, selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {elevation, visibilityLayers, activeDetailLevel} = ownProps;

    var {containers, volumes, walls, allWalls, room, archElements, projectGraphics, datums, xzDatums, products} = Elevation.get(['containers', 'volumes', 'walls', 'allWalls', 'wallSets', 'room', 'archElements', 'projectGraphics', 'datums', 'xzDatums', 'products'], {elevation, state});

    containers = _.filter(containers, container => container.position && !_.isEqual(container.position, {}));
    projectGraphics = Elevation.getFilteredProjectGraphicsFor({projectGraphics, elevation, visibilityLayers, activeDetailLevel});

    return {containers, volumes, walls, allWalls, room, archElements, projectGraphics, datums, xzDatums, products};
  },
  mapDispatch: {
    ..._.pick(resourceActions.walls, ['updateWall']),
    ..._.pick(resourceActions.projects, ['updateProject']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
  }
})(CanvasElevationWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasElevation'})
});
