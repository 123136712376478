import React, {memo} from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Project from 'project-helpers/project';
import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Hatch from 'components/hatch';
import BindingDimensionsTolerances from '../../../assets/bindingdimensionstolerances.png';

import ErrorFallback from 'components/error-fallback';
import {withErrorBoundary} from 'react-error-boundary';

const StandardPageSidePanelSection = ({sectionKey, elevations, project, fillMode, showUnitNumbers, materialFrequencyGroups, sidePanelProjectLevelNotes, handleUpdateProjectLevelNotes}) => {
  const frontContainers = _.flatMap(elevations, elevation => {
    const containers = Elevation.get('containers', {elevation});

    return _.filter(containers, container => {
      return _.includes([0, 360], lib.round(Container.getElevationTheta({container, elevation}), {toNearest: 1})) || (container.type === 'ocSolidCorner' && _.includes(['left', 'front'], Container.getSideKey({elevation, container, viewKey: 'front'})));
    });
  });

  const backContainers = _.flatMap(elevations, elevation => {
    const containers = Elevation.get('containers', {elevation});

    return _.filter(containers, container => {
      const containerAlpha = Container.getAlpha({container});
      const elevationAlpha = Elevation.getAlpha({elevation});

      return lib.trig.anglesAreEqual({a1: containerAlpha, a2: elevationAlpha});
    });
  });

  const countertopContainers = _.flatMap(elevations, elevation => {
    const containers = Elevation.get('containers', {elevation});

    return _.filter(containers, {type: 'countertop'});
  });

  const containers = _.uniq([...frontContainers, ...countertopContainers]);

  const frontProductInstances = _.flatMap(containers, container => {
    const products = Container.get('allProducts', {container});
    //TODO what to do with managed product instances
    return [..._.map(products, product => ({...product, sideKey: 'front'}))];
  });

  const backEndPanelProductInstances = _.flatMap(backContainers, container => {
    const products = Container.get('allProducts', {container});

    return _.map(_.filter(products, productInstance => {
      return container.type === 'endPanel' || _.includes(['leftWrapPanel', 'rightWrapPanel'], _.get(productInstance, 'managedData.managedKey'));
    }), product => ({...product, sideKey: 'back'}));
  });

  var productInstances = [...frontProductInstances, ...backEndPanelProductInstances];

  productInstances = _.filter(productInstances, productInstance => !_.includes([1624], productInstance.productId));

  let sectionContentJSX = null;

  if (sectionKey === 'details') {
    const applications = [];
    // const materialFrequencyGroups = Project.getMaterialFrequencyGroups({project, activeFillMode: fillMode});
    const {materialClasses} = Project.get('dependencies', {project});
    const materialHatchKeysJSX = [];
    const detailCategoriesJSX = [];

    _.forEach(productInstances, productInstance => {
      var {container, dependencies, productType, productOptionInstances} = Product.get(['container', 'productType', 'dependencies', 'productOptionInstances'], {product: productInstance});

      let ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product: productInstance, renderForDrawings: true});
      var subproductData = Product.getSubproductData({product: productInstance});
      var hasAppliedBrass = Product.getHasAppliedBrass({product: productInstance, productType});
      var hasWoodDrawers = _.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 1);


      const {details} = productInstance;
      const compatibleDetailGroups = [];

      ownedCompatibleDetails = _.reject(ownedCompatibleDetails, detail => _.includes(['pullLocation', 'yPullLocation'], detail.key));

      if (hasWoodDrawers && !_.some(ownedCompatibleDetails, detail => _.includes(['drawerMaterial', 'woodDrawerMaterial'], detail.key))) {
        if (container.customData.hasWoodDrawers && _.get(container, 'details.drawerMaterial.id')) {
          var materialClasses = _.values(dependencies.materialClasses.byId);
          var flattenedMaterials = _.flatMap(materialClasses, materialClass => materialClass.materials);

          var material = _.find(flattenedMaterials, {id: container.details.drawerMaterial.id});

          if (material) {
            ownedCompatibleDetails.push({key: 'drawerMaterial', type: 'material', title: 'Wood Drawer Material', options: [
              {id: material.id, title: material.title}
            ]});
          }
        }
      }

      _.forEach(ownedCompatibleDetails, ownedCompatibleDetail => {
        if (hasAppliedBrass && ownedCompatibleDetail.key === 'frontMaterial') {
          compatibleDetailGroups.push([{...ownedCompatibleDetail, hasAppliedBrass}]);
        }
        else if (!_.includes(ownedCompatibleDetail.key, 'pull')) {
          compatibleDetailGroups.push([ownedCompatibleDetail]);
        }
      });

      if (subproductData && subproductData.length > 0) {
        _.forEach(subproductData, (data, index) => {
          _.times(data.quantity, n => {
            var pullTypeDetail = _.find(ownedCompatibleDetails, {key: `${data.type}-${n}-pullType`});
            var pullMaterialDetail = _.find(ownedCompatibleDetails, {key: `${data.type}-${n}-pullMaterial`});

            if (pullTypeDetail && pullMaterialDetail) {
              compatibleDetailGroups.push([pullTypeDetail, pullMaterialDetail]);
            }
            else if (pullTypeDetail && !pullMaterialDetail) {
              compatibleDetailGroups.push([pullTypeDetail]);
            }
          });
        });
      }
      else {
        const pullTypeDetail = _.find(ownedCompatibleDetails, {key: 'pullType'});
        const pullMaterialDetail = _.find(ownedCompatibleDetails, {key: 'pullMaterial'});

        if (pullTypeDetail && pullMaterialDetail) {
          compatibleDetailGroups.push([pullTypeDetail, pullMaterialDetail]);
        }
        else if (pullTypeDetail && !pullMaterialDetail) {
          compatibleDetailGroups.push([pullTypeDetail]);
        }
      }

      if (Product.getHasLighting({product: productInstance})) {
        var currentLightingType = _.get(Product.get('container', {product: productInstance}), `customData.lightingType`, 'puck');

        compatibleDetailGroups.push([
          {key: 'lightingType', type: 'lighting', title: 'Lighting Type', options: [{id: 'puck', title: 'Puck'}, {id: 'linear', title: 'Linear'}]},
          //HINT setting only relevant when lighting type is puck
          ...((project.companyKey === 'hb' && currentLightingType === 'puck') ? [{key: 'puckLightColor', type: 'lighting', title: 'Puck Light Color', options: [{id: 'matteSilver', title: 'Matte Silver'}, {id: 'matteBlack', title: 'Matte Black'}]}] : []),
          ...(project.companyKey === 'hb' ? [{key: 'lightingTemp', type: 'lighting', title: 'Lighting Temp', options: [{id: '2700', title: '2700K'}, {id: '3000', title: '3000K'}]}] : []),
        ]);
      }

      //HINT specific to products with internal linear lighting
      //HINT products never have external lighting and internal lighting
      var isInternallyLit = Product.getHasInternalLighting({product: productInstance});

      if (isInternallyLit) {
        compatibleDetailGroups.push([
          {key: 'lightingType', type: 'lighting', title: 'Lighting Type', options: [{id: 'linear', title: 'Linear'}]},
          ...(project.companyKey === 'hb' ? [{key: 'lightingTemp', type: 'lighting', title: 'Lighting Temp', options: [{id: '3000', title: '3000K'}, {id: '2700', title: '2700K'}]}] : [])
        ])
      }

      _.forEach(compatibleDetailGroups, (compatibleDetails) => {
        var appliedDetails = _.map(compatibleDetails, compatibleDetail => {
          var isSubproductDetail = _.split(compatibleDetail.key, '-').length > 1;
          var value = details[compatibleDetail.key];

          if (!value) {
            if (isSubproductDetail) {
              var subproductDetailKey = _.split(compatibleDetail.key, '-')[2];

              var parentValueIsValid = details[subproductDetailKey] && _.find(compatibleDetail.options, {id: details[subproductDetailKey].id});

              value = parentValueIsValid ? details[subproductDetailKey] : {id: _.get(compatibleDetail, 'options')[0].id};
            }
            else {
              value = {id: _.get(compatibleDetail, 'options')[0].id};
            }
          }

          if (_.includes(['lightingType', 'puckLightColor', 'lightingTemp'], compatibleDetail.key)) {
            if (isInternallyLit) {
              value = {id: _.get(productInstance, `customData.${compatibleDetail.key}`, compatibleDetail.options[0].id)}
            }
            else {
              value = {id: _.get(Product.get('container', {product: productInstance}), `customData.${compatibleDetail.key}`, compatibleDetail.options[0].id)};
            }
          }

          var activeDetailOption = _.find(_.get(compatibleDetail, 'options'), {id: value.id});
          var title = _.get(activeDetailOption, 'title', '');

          return {key: isSubproductDetail ? _.split(compatibleDetail.key, '-')[2] : compatibleDetail.key, value, compatibleDetail, title, hasAppliedBrass: compatibleDetail.hasAppliedBrass};
        });

        if (_.every(appliedDetails, ({value}) => value !== undefined)) {
          const appliedDetailsForComparison = _.map(appliedDetails, appliedDetail => {

            return {key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.title, hasAppliedBrass: appliedDetail.hasAppliedBrass};
          });

          let application = _.find(applications, application => {
            const applicationForComparison = _.map(application.appliedDetails, appliedDetail => ({key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.title, hasAppliedBrass: appliedDetail.hasAppliedBrass}));

            return _.isEqual(applicationForComparison, appliedDetailsForComparison);
          });

          if (!application) {
            application = {appliedDetails, productInstances: []};

            applications.push(application);
          }

          if (!_.includes(application.productInstances, productInstance)) {
            application.productInstances.push(productInstance);
          }
        }
      });
    });

    //HINT builds materialHatchKeysJSX
    _.forEach(materialFrequencyGroups.wHatches, ({materialId, hasAppliedBrass, hatchKey}) => {
      const hatchAppearsInElevation = _.some(applications, application => {
        if (application.appliedDetails.length === 1) {
          const {key, value, hasAppliedBrass: applicationHasAppliedBrass=false} = application.appliedDetails[0];

          return value.id === materialId && hasAppliedBrass === applicationHasAppliedBrass && _.some(application.productInstances, productInstance => {
            const productData = Product.getProductData({product: productInstance});
            return Project.getShouldShowHatch({detailKey: key, productData, activeFillMode: fillMode});
          });
        }

        return false;
      });

      if (hatchAppearsInElevation) {
        const material = _.find(_.flatMap(_.values(materialClasses.byId), materialClass => materialClass.materials), {id: materialId});

        if (material) {
          materialHatchKeysJSX.push(
            <div key={`hatch-${hatchKey}-${material.color}`} className="detail-item-with-hatch">
              {fillMode === 'materialColors' ? (
                <div className="detail-item-hatch"
                  style={{padding: 0, backgroundColor: (hasAppliedBrass ? '#967F72' : material.color) || K.containerAppearancePropsByTheme().fill.light}}
                />
              ) : (
                <div className="detail-item-hatch" style={{backgroundColor: K.containerAppearancePropsByTheme().fill.light}}>
                  <Hatch {...{hatchKey}} width={40} height={40} style={{padding: 0}}/>
                </div>
              )}
              <div className="detail-item-title">{hasAppliedBrass ? 'Applied Brass: ' : ''}{material.title}</div>
            </div>
          );
        }
        else {
          console.error('missing material', materialId); //eslint-disable-line
        }
      }
    });

    if (project.companyKey === 'hb') {
      _.forEach(frontContainers, container => {
        var hasSubcounter = Container.getHasSubcounter({container});

        if (hasSubcounter) {
          var subcounterDetail = Container.getSubcounterDetail({container});

          if (subcounterDetail) {
            var value = container.details.subcounterMaterial || subcounterDetail.options[0];

            var activeDetailOption = _.find(_.get(subcounterDetail, 'options'), {id: value.id});
            var title = _.get(activeDetailOption, 'title', '');

            var appliedDetail = {key: 'subcounterMaterial', value, compatibleDetail: subcounterDetail, title};

            const appliedDetailForComparison = [{key: appliedDetail.key, title: appliedDetail.title, value: appliedDetail.value.id}];

            let application = _.find(applications, application => {
              const applicationForComparison = _.map(application.appliedDetails, appliedDetail => ({key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.title}));

              return _.isEqual(applicationForComparison, appliedDetailForComparison);
            });

            if (!application) {
              application = {appliedDetails: [appliedDetail], containers: [], productInstances: []};

              applications.push(application);
            }

            application.containers.push(container);
          }
        }
      });
    }

    //HINT builds detailCategoriesJSX
    _.forEach([
      {
        groupKey: 'wVisibleIds',
        applicationCategories: [
          {title: 'Fronts', detailKeys: ['frontMaterial', 'brassFront']},
          {title: 'Panels', detailKeys: ['panelMaterial']},
          {title: 'Interiors', detailKeys: ['boxMaterial', 'boxBackMaterial']},
          {title: 'Wood Drawers', detailKeys: ['drawerMaterial', 'woodDrawerMaterial']},
          {title: 'Countertops', detailKeys: ['countertopMaterial']},
          {title: 'Pulls', detailKeys: ['pullType', 'pullMaterial']},
          {title: 'Metal', detailKeys: ['rodMaterial', 'frameMaterial']},
          {title: 'Lighting', detailKeys: ['lightingType', 'puckLightColor', 'lightingTemp']}
        ]
      },
      {
        groupKey: 'woVisibleIds',
        applicationCategories: [
          {title: 'Opencase', detailKeys: ['ocMetalMaterial', 'ocWoodMaterial', 'ocRodMaterial', 'ocCapMaterial', 'ocLeatherMaterial', 'ocPocketMaterial', 'ocPaperstoneMaterial', 'ocMetal2Material']},
          {title: 'Trim', detailKeys: ['scribeMaterial', 'kickMaterial', 'subcounterMaterial']}
        ]
      }
    ], ({groupKey, applicationCategories}) => {
      _.forEach(applicationCategories, applicationCategory => {
        const filterMethod = groupKey === 'wVisibleIds' ? 'filter' : 'reject';

        const categoryApplications = _.filter(applications, application => {
          const matchesDetailKey = _.includes(applicationCategory.detailKeys, application.appliedDetails[0].key);
          const productInstances = _[filterMethod](application.productInstances, productInstance => {
            let isCounted = !_.includes([54, 79], Product.getProductData({product: productInstance}).categoryId); //HINT categoryIds for drawer inserts and kicks

            if (applicationCategory.title === 'Opencase') isCounted = isCounted && productInstance.sideKey === 'front';

            return isCounted;
          });

          return matchesDetailKey && (productInstances.length > 0 || _.get(application, 'containers.length') > 0);
        });

        if (categoryApplications.length > 0) {
          let showCategory = false;

          const detailCategoryItems = [];

          const sortedApplications = _.orderBy(categoryApplications, application => _.max([_.get(application, 'productInstances.length'), _.get(application, 'containers.length')]), 'desc');

          _.forEach(sortedApplications, application => {
            const productInstances = _.filter(application.productInstances, productInstance => {
              const {key: detailKey} = application.appliedDetails[0];
              var productData = Product.getProductData({product: productInstance});

              //HINT was previously incorrectly always showing, but seems like that is preferred
              //seems like productInstances are already filtered
              let isCounted = true//Project.getShouldShowHatch({productData, detailKey, activeFillMode: fillMode}) || _.includes(['scribeData', 'kickMaterial'], detailKey);

              if (applicationCategory.title === 'Opencase') isCounted = isCounted && productInstance.sideKey === 'front';

              return isCounted;
            });

            if (productInstances.length > 0 || _.get(application, 'containers.length') > 0) {
              let title;

              const detailValueTitles = [];

              showCategory = true;

              let instanceTitles = [];

              if (groupKey === 'wVisibleIds') {
                if (!!showUnitNumbers) {
                  instanceTitles = _.filter(_.uniq(_.map(application.productInstances, product => {
                    var {productionId} = product;
                    var {container, parentProduct} = Product.get(['container', 'parentProduct'], {product});

                    if (Product.getIsApplianceStackComponent({product})) {
                      productionId = _.get(_.split(productionId, '.'), '[0]', parentProduct?.productionId);
                    }

                    if (project.companyKey === 'hb' && container.type === 'vanity' && !productionId) {
                      var containerProducts = Container.get('managedProductInstances', {container});
                      var productionIdProduct = _.find(containerProducts, product => _.get(product, 'managedData.managedKey') === 'vanity');

                      if (productionIdProduct) {
                        productionId = productionIdProduct.productionId;
                      }
                    }

                    return productionId;
                  })));
                }
                // else {
                //   instanceTitles = _.filter(_.uniq(_.map(application.productInstances, product => {
                //     var {parentProduct, productType} = Product.get(['parentProduct', 'productType'], {product});
                //     var proposalAlias = productType.proposalAlias;

                //     if (Product.getIsApplianceStackComponent({product}) && parentProduct) {
                //       proposalAlias = Product.get('productType', {product: parentProduct}).proposalAlias;
                //     }

                //     return proposalAlias;
                //   })));
                // }
              }
              else if (groupKey === 'woVisibleIds') {
                if (application.containers) {
                  instanceTitles = [`(${application.containers.length}) subcounter`];
                }
                else {
                  let productInstanceGroups = {};

                  if (applicationCategory.title === 'Opencase') {
                    productInstanceGroups = _.groupBy(_.filter(application.productInstances, productInstance => productInstance.sideKey === 'front'), product => Product.getProductData({product}).id);
                  }
                  else {
                    productInstanceGroups = _.groupBy(application.productInstances, 'productData.id');
                  }

                  instanceTitles = _.map(productInstanceGroups, productInstances => {
                    return `(${productInstances.length}) ${Product.getProductData({product: productInstances[0]}).title}`;
                  });
                }
              }

              _.forEach(application.appliedDetails, appliedDetail => {
                var compatibleDetail = appliedDetail.compatibleDetail;

                var detailValueTitle = _.get(
                    _.find(compatibleDetail.options, ['id', appliedDetail.value.id]) ||
                    _.find(compatibleDetail.options, ['value', appliedDetail.value]) ||
                    _.get(compatibleDetail, 'options[0]'),
                  'title', '?'
                )

                if(appliedDetail.hasAppliedBrass) detailValueTitle = `Applied Brass: ${detailValueTitle}`

                if (application.appliedDetails.length > 1) {
                  detailValueTitles.push(detailValueTitle);
                }
                else if (applicationCategory.title === 'Trim') {
                  title = `${compatibleDetail.title || compatibleDetail.key}: ${detailValueTitle}${instanceTitles.length > 0 ? ' - ' : ''}`;
                }
                else if (applicationCategory.title === 'Opencase') {
                  title = `${_.replace(`${compatibleDetail.title}`, ' Material', '')}: ${detailValueTitle}${instanceTitles.length > 0 ? ' - ' : ''}`;
                }
                else if (applicationCategory.title === 'Interiors') {
                  if (appliedDetail.key === 'boxBackMaterial') detailValueTitle = detailValueTitle + ' (back)';

                  title = `${detailValueTitle}${instanceTitles.length > 0 ? ' - ' : ''}`;
                }
                else {
                  title = `${detailValueTitle}${instanceTitles.length > 0 ? ' - ' : ''}`;
                }
              });

              if (detailValueTitles.length > 0) title = `${_.join(detailValueTitles, ': ')}${instanceTitles.length > 0 ? ' - ' : ''}`;

              instanceTitles = _.join(lib.array.sortByAlphanumeric(instanceTitles), ', ');

              const detailItem = (
                <div key={`detail-item-${title}-${instanceTitles}`} className="detail-item">
                  <span className="detail-item-title">{title}</span>
                  <span className="detail-item-instance-titles">{instanceTitles}</span>
                </div>
              );

              detailCategoryItems.push(detailItem);
            }
          });

          if (showCategory) {
            detailCategoriesJSX.push(
              <div key={`detail-category-${applicationCategory.title}`} className="detail-category">
                <div className="detail-category-title">{applicationCategory.title}</div>
                <div className="detail-category-items">{detailCategoryItems}</div>
              </div>
            );
          }
        }
      });
    });

    sectionContentJSX = (
      <>
        {materialHatchKeysJSX}
        <br />
        {detailCategoriesJSX}
      </>
    );
  }
  else if (sectionKey === 'notes') {
    let countertopThicknesses = [];
    const notes = [];

    _.forEach(containers, container => {
      if (container.type === 'countertop' && container.customData.isByOthers) {
        countertopThicknesses.push(container.dimensions.height);
      }
    });

    if (countertopThicknesses.length > 0) {
      countertopThicknesses = _.uniq(countertopThicknesses).concat().sort();

      notes.push({id: 'countertop-thickness-note', note: <div><b>CT B/O = Countertops by others.</b> Assumes {lib.string.toSentence(_.map(countertopThicknesses, ct => `${ct}"`))} thickness as shown.</div>});
    }

    if (_.some(containers, ['customData.hasLighting', true])) {
      const companyName = Project.getCompanyName({project});

      const lightingContainers = _.filter(containers, container => Container.getHasLighting({container}));

      const hasLinearLighting = _.some(lightingContainers, ['.customData.lightingType', 'linear']);
      const hasPuckLighting = _.some(lightingContainers, ['customData.lightingType', 'puck']) || !hasLinearLighting;

      const lightingMessage = hasLinearLighting ? (hasPuckLighting ? 'Linear LED and LED Puck Lighting' : 'Linear LED Lighting') : 'LED Puck Lighting';

      notes.push({id: 'lighting-note', note: `${lightingMessage} by ${companyName}`});
    }

    notes.push({id: 'wood-grain-note', note: 'All wood grain is vertically oriented unless otherwise noted'});

    sectionContentJSX = _.map(notes, ({id, note}) => <div key={id} className="side-panel-list-item">{note}</div>);
  }
  else if (sectionKey === 'bindingDimensions') {
    sectionContentJSX = (
      <>
        <div className="detail-category">
          <div className="detail-category-title">Attention</div>
          <div>These drawings supersede any and all previous drawings, specification, invoice notes, or other prior communication regarding this project.<br/><br/>
        Dimensions in red are to be held by contractor during construction to within 1/4” tolerance.<br/><br/>
        All dimensions noted here are finish to finish.<br/><br/>
        Any architectural or field conditions not noted here including, but not limited to soffits, beams, windows of relevance should be called to the attention of your Henrybuilt designer.<br/><br/>
        Please review accordingly.<br/><br/>
        Upon approval, a record set of this sheet should be provided to your contractor.<br/> </div>
        </div>
        <div className="detail-category">
          <div className="detail-category-title">Key</div>
          <div className="binding-dimensions-key-image" style={{backgroundImage: 'url(https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/media/binding+dimensions+key.png)'}}/>
        </div>
      </>
    );
  }

  return sectionContentJSX ? (
    <div className={`side-panel-section side-panel-${sectionKey}`}>
      <div className="side-panel-section-content">{sectionContentJSX}</div>
    </div>
  ) : null;
};

const StandardPageSidePanel = ({project, page, isBindingDimensionsPage, materialFrequencyGroups, bindingDimensionsShowing, fillMode, showUnitNumbers}) => {
  const {layout} = page;

  const {rooms: allRooms, elevations: allElevations} = Project.get(['rooms', 'elevations'], {project});

  const elevations = _.filter(_.map(layout, ({elevationId}) => _.find(allElevations, {id: elevationId})), e => e);
  const rooms = _.filter(_.map(layout, ({roomId}) => _.find(allRooms, {id: roomId})), r => r);

  if (fillMode === 'unitType') {
    const containers = [];

    if (elevations?.length > 0) {
      _.forEach(elevations, (elevation => {
        const elevationContainers = Elevation.get('containers', {elevation});

        containers.push(..._.uniq(elevationContainers));
      }));
    }

    if (rooms?.length > 0) {
      _.forEach(rooms, (room => {
        const roomContainers = Room.get('containers', {room});

        containers.push(..._.uniq(roomContainers));
      }));
    }

    let containerTypes = _.uniq(_.map(containers, 'type'));

    _.pullAll(containerTypes, ['capPanel', 'wallPanel', 'endPanel', 'baseFreestandingAppliance', 'wallFreestandingAppliance', 'valet']);

    return (
      <div className="drawings-side-panel">
        <div className='drawings-side-panel-content'>
          <div className="drawings-color-keys">
            <div className="drawings-color-key" style={{padding: 25}}>
              {_.map(_.filter(containerTypes, type => type !== 'countertop'), type => {
                return (
                  <div key={`color-key-${type}`} className={`drawings-color-key-${type}-row`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12}}>
                    <div className={`drawings-color-key-${type}-box`} style={{width: 20, height: 20, backgroundColor: `#${K.designPlanningCompleteColorMap[type]}`}}></div>
                    <div className={`drawings-color-key-${type}`} style={{marginLeft: 5}}>{_.lowerCase(type)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sections = [isBindingDimensionsPage ? 'bindingDimensions' : 'details', 'notes'];

  return (
    <div className="drawings-side-panel">
      <div className='drawings-side-panel-content'>
        {_.map(sections, (sectionKey, index) => <StandardPageSidePanelSection key={`standard-page-key-${sectionKey}-${index}`} {...{sectionKey, elevations, rooms, page, project, fillMode, materialFrequencyGroups, showUnitNumbers}} />)}
      </div>
      <div className="signature-container">
        <div className="signature-content">
          <div className="signature-label">CLIENT SIGNATURE</div>
          <div className="date-label">DATE</div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(memo(StandardPageSidePanel, (prevProps, nextProps) => {
  return prevProps.fillMode === nextProps.fillMode && prevProps.bindingDimensionsShowing === nextProps.bindingDimensionsShowing &&
    prevProps.isBindingDimensionsPage === nextProps.isBindingDimensionsPage && prevProps.showUnitNumbers === nextProps.showUnitNumbers &&
    //HINT check that layout has the same elevations/rooms, ignoring scale and position since they don't impact the sidebar
    _.isEqual(_.map(prevProps.page.layout, graphic => _.omit(graphic, ['scale', 'position'])), _.map(nextProps.page.layout, graphic => _.omit(graphic, ['scale', 'position'])));
}), {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Page Side Panel'})
});
