import React from 'react';
import K from 'k';
import lib from 'lib';

export default class Popup extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    var escPressed = lib.event.keyPressed(event, 'esc');

    if (this.props.onClose && escPressed) this.props.onClose();
  };

  render() {
    return (
      <div
        ref={ref => this.wrapperRef = ref}
        onClick={event => event.target === this.wrapperRef && this.props.onClose && this.props.onClose()}
        style={{position: 'fixed', backgroundColor: 'rgba(0, 0, 0, 0.4)', display: 'flex', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10000, alignItems: 'center', justifyContent: 'center', ...this.props.wrapperStyle}}
      >
        <div style={{backgroundColor: 'white', padding: K.spacing * 2, ...this.props.style}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
