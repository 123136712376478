import React from 'react';
import memo from 'helpers/memo';

import _ from 'lodash';
import K from 'k';
import lib from 'lib';
import { toCanvas } from 'helpers/position-helper';
import { CanvasPortal, CanvasLine, CanvasText, CanvasScriptObject, CanvasPath, CanvasCircle, CanvasRect, EditableCanvasLine } from 'canvas';
import CanvasErrorFallback from 'canvas/canvas-error-fallback';

import { withErrorBoundary } from 'react-error-boundary';

class CanvasDimensionLineDistance extends React.PureComponent {
  handleLabelMouseDown = (event) => {
    //HINT ignore right click
    if (event.evt.button === 2) return;

    var {isSelected, isEditingDimensions, prevDistance, distance, isCustomDimension, target, canvasData, tolerance, shouldHoldTo} = this.props;
    let {isShifting} = canvasData;

    event.evt.stopPropagation();

    // var allowSelection = false; //_.some(distance.positions, p => p.props.onChange !== undefined) && prevDistance && _.some(prevDistance.positions, p => p.props.onChange !== undefined);
    //TODO

    if (isEditingDimensions) {
      if (isShifting) {
        this.props.toggleTolerancePopupShowing({updateDimensionsData: this.props.updateDimensionsData, targetId: target.id, tolerance, shouldHoldTo});
      }
      else if (isCustomDimension) {
        this.props.onSelect({dimensionSetId: this.props.dimensionSetId});
      }
      else if (event.evt.altKey) {
        if (!isSelected) this.props.onSelect({targetId: target.id});
      }
      else if (!this.props.isCustomDimension) {
        this.props.updateDimensionsData((dimensionsData) => {
          return {
            ...dimensionsData,
            disabledLineIds: {
              ...dimensionsData.disabledLineIds,
              [target.id]: !dimensionsData.disabledLineIds[target.id] //HINT important not to use target.lineIsDisable since its inverted in additive dims mode
            }
          };
        });
      }
    }
    // else if (allowSelection) {
    //   this.activeDistance = distance;

    //   // if (this.canvasView.props.selectedStroke) {
    //   //   label.set({fill: this.canvasView.props.selectedStroke});
    //   // }

    //   // if (!this.isSelected) this.select();
    // }
  };

  handleLabelContextMenu = (event) => {
    var {isEditingDimensions, target} = this.props;

    event.evt.stopPropagation();
    event.evt.preventDefault();

    if (isEditingDimensions) {
      this.props.updateDimensionsData((dimensionsData) => {
        return {
          ...dimensionsData,
          swapShortDimSideById: {
            ...dimensionsData.swapShortDimSideById,
            [target.id]: !dimensionsData.swapShortDimSideById[target.id]
          }
        };
      });
    }
  };



  render() {
    var {color, alpha, canvasData, target, customOffset, viewOffset, showLabelBorder, isEditingDimensions, lineIsHorizontal, tolerance, showBindingDimensions, isSelected, isCustomDimension, renderForDrawings, isExportingSvg} = this.props;
    var {nextEnabledTarget, previousEnabledTarget, distance, textSize, label, isSinkCl, isShort, yOffset=0, shortScalar} = target;

    textSize = _.cloneDeep(textSize);
    var isWindows = window.navigator.userAgent.indexOf("Win") != -1;

    var targetCanvasPosition = toCanvas(lib.object.sum(target.position, viewOffset), canvasData);
    var position = lib.object.sum(target.offsetPositionOnOriginLine, viewOffset);
    var canvasPosition = toCanvas(position, canvasData);
    var dotCanvasPosition = toCanvas(lib.object.sum(target.offsetDotPositionOnOriginLine, viewOffset), canvasData);

    var needsConfirmation = tolerance === 'tbd';

    var getIsBinding = (tolerance) => {
      return tolerance && tolerance !== 0 && tolerance !== 'eq';
    }

    var isBindingDimension = getIsBinding(tolerance);
    var neighborIsBindingDimension = (nextEnabledTarget && getIsBinding(nextEnabledTarget.tolerance)) || (previousEnabledTarget && getIsBinding(previousEnabledTarget.tolerance));
    var isBold = isBindingDimension;

    //HINT we shrink text size in getProcessedDimensionSets because our fractions are shown with subscript and are usually shorter than normal characters
    //but we aren't accounting for bold text, so sometimes the box wasn't wide enough
    if (isBold) {
      var fractionLength = label.split(' ')[1]?.length || 0;

      if (fractionLength && !isSinkCl) {
        if (isWindows) {
          textSize.width += 1;
        }
        else {
          textSize.width += fractionLength <= 3 ? 0.5 : 0;
        }
      }

      textSize.height += 0.1;
    }

    if (nextEnabledTarget) {
      var nextPosition = lib.object.sum(target.offsetNextPositionOnOriginLine, viewOffset);
      var nextCanvasPosition = toCanvas(nextPosition, canvasData);
      var hasFraction = Math.round(distance) !== distance;

      //HINT don't want to offset dims that are already short
      if (isShort) yOffset = 0;

      var dimBoxHeight = (isWindows ? 4.4 : 4.2) + (isBold ? 0.1 : 0);

      var macXOffset = (hasFraction && !(isShort && !lineIsHorizontal) ? ((0.5) * (canvasData.scale - 3)) : 0);
      var macYOffset = (hasFraction ? .08 : -.08) * canvasData.scale;

      var windowsXOffset = hasFraction ? -0.5 * canvasData.scale : 0;
      var windowsYOffset = hasFraction ? (textSize.height / dimBoxHeight) * canvasData.scale : (0.25 * canvasData.scale);

      var labelXOffset = isWindows ? windowsXOffset : macXOffset;
      var labelYOffset = isWindows ? windowsYOffset : macYOffset;

      var offsetBase = lib.trig.rotate({point: {x: isShort ? (shortScalar * ((!lineIsHorizontal ? (textSize.width / 2 - 1.2) : 0) + 5) * canvasData.scale) : 0, y: 0}, byRadians: alpha + Math.PI / 2});

      var labelOffset = lib.object.sum(offsetBase, {x: labelXOffset, y: labelYOffset});

      var labelPosition = lib.object.sum(lib.math.midpoint({p1: position, p2: nextPosition}), lib.trig.rotate({point: {x: 0, y: yOffset}, byRadians: alpha + Math.PI/2}));

      var labelCanvasPosition = toCanvas(labelPosition, canvasData);

      var nextPositionDelta = lib.object.difference(nextPosition, position);

      var offsetBy = (position, delta) => lib.object.sum(position, lib.trig.rotate({point: delta, byRadians: alpha + Math.PI / 2}));

      var defaultPadding = (4.5 / canvasData.scale);
      var usedWidth = textSize.width + ((isShort && !lineIsHorizontal) ? 2 : defaultPadding);

      var textBoxWidthWhenHorizontal = lineIsHorizontal ? usedWidth : dimBoxHeight;
      var textBoxHeightWhenHorizontal = lineIsHorizontal ? dimBoxHeight : usedWidth;
      var textOffsetFromLine = isShort ? 3.2 : (lineIsHorizontal ? -2 : -(usedWidth) / 2);
    }

    var enabledAdjacentLines = _.filter([
      target,
      ...(target.previousTarget ? (
        target.previousEnabledTarget ? [target.previousEnabledTarget] : []
      ) : (
        target.nextEnabledTarget && target.previousTarget ? [target.nextEnabledTarget] : []
      ))
    ], target => !target.lineIsDisabled && target.distance);

    if (tolerance) var toleranceSymbolPosition = lib.object.sum(labelPosition, {x: 0, y: alpha === 0 ? 5 : -5});

    // var isCustomDimension = false;

    if (target.nextEnabledTarget) {
      var line = {
        from: lib.object.sum(target.positionOnOriginLine, viewOffset),
        to: lib.object.sum(target.nextEnabledTarget.positionOnOriginLine, viewOffset)
      };
    }

    var labelTheta = alpha - Math.round(lib.trig.normalize({radians: alpha}) / (Math.PI / 2)) * (Math.PI / 2);

    var shouldHide = (showBindingDimensions === 'only' && !isBindingDimension);
    var shouldHideTrackingLines = shouldHide && !neighborIsBindingDimension;

    return (<>
      {nextEnabledTarget && (!shouldHide || isEditingDimensions) && (<>
        {/* Main line */}
        {!target.positionIsDisabled && !target.lineIsDisabled && (
          <CanvasLine
            from={nextCanvasPosition}
            to={canvasPosition}
            stroke={color ? color : 'rgba(0, 0, 0, 0.2)'}
            strokeWidth={1}
          />
        )}

        <CanvasPortal portalSelector={".top-layer"} {...{isExportingSvg}}>
          {/* Short dim background */}
          {!isSinkCl && !target.positionIsDisabled && (!shouldHide || isEditingDimensions) && (!target.lineIsDisabled || isEditingDimensions) && (
            <CanvasPath
              {...canvasPosition}
              points={[ //HINT points are in this order to make use of the path not being closed and therefore not having a border on one side intentionally
                offsetBy({x: 0, y: 0}, {x: shortScalar * (textOffsetFromLine + textBoxHeightWhenHorizontal), y: (textBoxWidthWhenHorizontal - distance) / 2 + yOffset}),
                offsetBy({x: 0, y: 0}, {x: shortScalar * textOffsetFromLine, y: (textBoxWidthWhenHorizontal - distance) / 2 + yOffset}),

                ...(isShort ? [
                  offsetBy({x: 0, y: 0}, {x: shortScalar * textOffsetFromLine / 2, y: 0}),
                  {x: 0, y: 0},
                  nextPositionDelta,
                  offsetBy(nextPositionDelta, {x: shortScalar * textOffsetFromLine / 2, y: 0}),
                ] : []),

                offsetBy(nextPositionDelta, {x: shortScalar * textOffsetFromLine, y: -(textBoxWidthWhenHorizontal - distance) / 2 + yOffset}),
                offsetBy(nextPositionDelta, {x: shortScalar * (textOffsetFromLine + textBoxHeightWhenHorizontal), y: -(textBoxWidthWhenHorizontal - distance) / 2 + yOffset})
              ]}
              fill='white'
              stroke={color ? color : (needsConfirmation) ? 'red' : (isShort || showLabelBorder ? (showLabelBorder ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.09)') : undefined)}
              strokeWidth={(needsConfirmation && !isEditingDimensions) ? 2 : 1}
              opacity={target.lineIsDisabled ? 0.5 : 1}
              closed
              listening={false}
            />
          )}
          {/* Label */}
          {!target.positionIsDisabled && (!shouldHide || isEditingDimensions) && (!target.lineIsDisabled || isEditingDimensions) && (
            <CanvasText
              text={label}
              fill={color ? color : ((isCustomDimension && isEditingDimensions) ? K.colors.customDimensions : (tolerance && tolerance !== 0 && tolerance !== 'eq'
              ? needsConfirmation && !isEditingDimensions ? 'transparent' : 'red'
              : 'rgba(0, 0, 0, 0.6)'))}
              align='center'
              alignVertical='center'
              fontSize={canvasData.scale * 3.5}
              fontWeight={isBold ? 'bold' : undefined}
              listening={!canvasData.isStatic && isEditingDimensions}
              onClick={this.handleLabelMouseDown}
              onContextMenu={this.handleLabelContextMenu}
              textSize={lib.object.multiply(textSize, canvasData.scale)}
              opacity={target.lineIsDisabled ? 0.5 : 1}
              rotation={lib.trig.radiansToDegrees(labelTheta)}
              {...(isSinkCl ? {
                preventPaddingScale: true,
                padding: 2,
                backgroundColor: 'white',
                backgroundStroke: (needsConfirmation) ? 'red' : (isShort || showLabelBorder ? (showLabelBorder ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.09)') : undefined),
              } : {})}
              {...lib.object.sum(labelCanvasPosition, labelOffset)}
            />
          )}
        </CanvasPortal>
        {/* Tolerance symbol */}
        {showBindingDimensions && !target.positionIsDisabled && (!target.lineIsDisabled || isEditingDimensions) && !!tolerance && tolerance !== -1 && !needsConfirmation && tolerance !== 'eq' && (
          <CanvasScriptObject
            script={`
          var radius = 1.5;

          group({origin: {x: 'center', y: 'center'}}, [
            ${tolerance === 2 ? `
              circle({radius, fill: 'red', stroke: 'red'})
            ` : `
              circle({radius, stroke: 'red'}),
              line({stroke: 'red', top: -radius, x1: 0, x2: 0, y1: 0, y2: 2 * radius}),
              ${tolerance === 0.25 || tolerance === 0.5 ? 'path({closed: true, fill: \'red\', stroke: \'red\', isFillable: true, points: [{x: -radius, y: 0, arc: true}, {x: 0, y: radius, arc: true}, {x: 0, y: 0}]}),' : ''}
              ${tolerance === 0.5 ? 'path({closed: true, fill: \'red\', stroke: \'red\', isFillable: true, points: [{x: 0, y: -radius, arc: true}, {x: -radius, y: 0, arc: true}, {x: 0, y: radius, arc: true}, {x: 0, y: 0}]}),' : ''}
            `}
          ])
        `}
            position={toleranceSymbolPosition}
            size={{width: 5, height: 5}}
            renderForDrawings={renderForDrawings}
            listening={false}
          />
        )}
      </>)}

      {/* HINT these are coming afterward because they need to have higher zindex */}

      {/* Tracking line */}
      {(enabledAdjacentLines.length > 0 && !target.positionIsDisabled) && (!shouldHideTrackingLines || isEditingDimensions) && (
        <CanvasLine
          from={targetCanvasPosition}
          to={dotCanvasPosition}
          stroke='rgba(0, 0, 0, 0.09)'
          strokeWidth={1}
        />
      )}

      {!target.hideDot && ((enabledAdjacentLines.length > 0 && !target.positionIsDisabled && !shouldHideTrackingLines) || isEditingDimensions) && (
        <CanvasPortal portalSelector={".dimension-dots-layer"} {...{isExportingSvg}}>
          {/* Circle */}
          <CanvasCircle
            fill='white'
            radius={(((target.distance < 1) || (target.previousEnabledTarget?.distance < 1)) ? 0.25 : 0.5) * canvasData.scale}
            strokeWidth={(isEditingDimensions ? 0.5 : 0.5) * canvasData.scale < 1 ? 0.5 : 1}
            stroke={showLabelBorder && !target.positionIsDisabled ? 'rgba(0, 0, 0, 0.9)' : '#bbb'}
            opacity={target.positionIsDisabled  ? 0.5 : 1}
            listening={!canvasData.isStatic && isEditingDimensions}
            onClick={() => isEditingDimensions && this.props.updateDimensionsData((dimensionsData) => {
              return {
                ...dimensionsData,
                disabledPositionIds: {
                  ...dimensionsData.disabledPositionIds,
                  ...lib.object.fromKeys(target.ids, () => !target.positionIsDisabled)
                }
              };
            })}
            {...dotCanvasPosition}
          />
        </CanvasPortal>
      )}

      {isEditingDimensions && isSelected && target.nextEnabledTarget && (
        <CanvasPortal portalSelector={".hud-layer"} {...{isExportingSvg}}>
          <EditableCanvasLine
            stroke={color ? color : 'black'}
            viewDepth={customOffset}
            hideLine
            isSelected
            centerDepthHandle
            portalSelector={'.hud-layer'}
            onTransformEnd={(props) => this.props.onOffsetChange({props, line, targetId: target.id})}
            {...line}
          />
        </CanvasPortal>
      )}
    </>);
  }
}

export default withErrorBoundary(CanvasDimensionLineDistance, {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Canvas Dimension Distance'})
});
// export default CanvasDimensionLineDistance;