import _ from 'lodash';
import React, {useState, useRef} from 'react';
import K from '../k';
import useClickOutside from '../hooks/click-outside';
import upArrowBlack from '../assets/up-arrow-black.png';
// import Icon from './icon';

const Dropdown = ({label, options = [], style, contentContainerStyle={}, labelStyle, listItemStyle={}, value, collapseOnChange = true, isControlled=false, disabled = false, onChange, right, hasFixedHeight, placeholder, isCollapsedDefault = true, showHeader = true, isLiteMode = false, onCollapse, showCaret = true, overrideLabel, truncateTextBy = 0}) => {
  const wrapperRef = useRef(null);

  isControlled = isControlled || value !== undefined;
  const [selectedValue, setSelectedValue] = isControlled ? [value, null] : useState(null);
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedDefault);
  const [hoveringOptionValue, setHoveringOptionValue] = useState(null);

  useClickOutside(wrapperRef, () => {
    if (onCollapse) onCollapse();
    setIsCollapsed?.(true)
  });

  const selectedIndex = _.findIndex(options, {value: selectedValue});
  const selected = selectedIndex !== -1 ? options[selectedIndex] : {};

  const lastOptionIsSelected = selectedIndex === options.length - 1;

  const styles = {
    container: {
      position: 'relative',
      userSelect: 'none',
      ...style
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: 'fit-content'
    },
    dropdownLabel: {
      ...(labelStyle ? {
        ...labelStyle,
        ...(_.has(labelStyle, 'borderRadius') ? {borderBottomLeftRadius: labelStyle.borderRadius, borderBottomRightRadius: labelStyle.borderRadius, borderTopLeftRadius: labelStyle.borderRadius, borderTopRightRadius: labelStyle.borderRadius} : {}),
        ...(!isCollapsed ? {borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: labelStyle.borderTopLeftRadius || 14, borderTopRightRadius: labelStyle.borderTopRightRadius || 14} : {})
      } : {
        ...K.fonts.label,
        lineHeight: 'auto',
      }),
    },
    icon: {
      width: 6.07,
      height: 12.35,
      transform: isCollapsed ? 'rotate(90deg)' : 'rotate(180deg)',
      margin: `0 ${!right ? K.spacing : '0'}px 0 ${right ? K.spacing : '0'}px`,
    },
    listItem: ({isSelected, isHovering, isInvalid, isLastOption}) => ({
      ...K.fonts.standard,
      display: 'block',
      padding: '4px 5px 4px 5px',
      borderRadius: isLastOption ? '0px 0px 7px 7px' : 0,
      ...(isSelected ? {
        fontWeight: 'bold',
        backgroundColor: K.colors.doubleGray,
      } : {}),
      ...(isHovering && !isInvalid) ? {backgroundColor: 'rgb(245, 245, 245)'} : {},
      ...(isInvalid ? {opacity: 0.5, cursor: 'default'} : {}),
      ...listItemStyle
    }),
    contentContainer: {
      cursor: 'pointer',
      position: 'fixed',
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      marginTop: 4,
      backgroundColor: K.colors.base,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '0px 0px 7px 7px',
      width: 'fit-content',
      minWidth: '100px',
      color: 'black',
      userSelect: 'none',
      zIndex: 3,
      maxHeight: hasFixedHeight ? '200px' : undefined,
      overflow: 'auto',
      ...contentContainerStyle
    },
  };

  const toggleCollapsed = () => {
    if (onCollapse) onCollapse();
    setIsCollapsed?.(!isCollapsed);
  }

  const handleOptionSelect = (option) => () => {
    if (!isControlled) setSelectedValue(option.value);

    if (onChange) onChange(option.value || option);

    if (collapseOnChange) toggleCollapsed();
  };

  var displayedLabel = overrideLabel || selected.label || selected.title || placeholder || 'Select';

  if (truncateTextBy !== 0) {
    displayedLabel = _.truncate(displayedLabel, {'length': truncateTextBy})
  }

  return (
    <div style={styles.container} ref={wrapperRef}>
      {isLiteMode ? null : showHeader && (<div style={styles.labelContainer} onClick={disabled ? () => {} : toggleCollapsed}>
        {(!right && showCaret) && <img src={upArrowBlack} name='rightArrow' style={styles.icon}/>}
        <span style={styles.dropdownLabel}>{displayedLabel}</span>
        {(right && showCaret) && <img src={upArrowBlack} name='rightArrow' style={styles.icon}/>}
      </div>)}
      {isLiteMode && <div onClick={disabled ? () => {} : toggleCollapsed} style={styles.dropdownLabel}>{placeholder}</div>}
      {!isCollapsed && (
        <ul style={styles.contentContainer}>
          {_.map(options, (option, index) => (
            <li
              key={option.value || option.title}
              style={styles.listItem({isSelected: selectedValue === (option.value || option.title), isInvalid: option.isInvalid, isLastOption: index === options.length - 1, isHovering: hoveringOptionValue === option.value})}
              onClick={!option.isInvalid ? handleOptionSelect(option) : () => {}}
              onMouseEnter={() => setHoveringOptionValue(option.value)}
              onMouseLeave={() => setHoveringOptionValue(null)}
            >
              {option.label || option.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
