import oldUpdateProductionIds from './old-update-production-ids-helper';
import lib from 'lib';
import Project from 'project-helpers/project';
import Floor from 'project-helpers/floor';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';

var updateProductionIds = ({project, reduxActions, isBatched}) => {
  //HINT continue to support the old productionID method for projects currently in production
  var projectsToUseOldMethod = [4006, 4037, 3623, 3766, 4064, 4197, 4271, 3620];

  if (_.includes(projectsToUseOldMethod, project.id)) {
    oldUpdateProductionIds({project, reduxActions});
  }
  else {
    let rank = 0;
    let scopeIndex = 1;
    var productUpdates = [];

    var spacialSort = ({collection, yScalar = 1, collectionKey, elevation}) => {
      var collectionWithData = collection;

      if (collectionKey === 'containers') {
        collectionWithData = _.map(collection, container => {
          return {
            ...container,
            wallPrintInElevation: Container.getWallprintInElevation({container, elevation})
          }
        });
      }

      var sorted = _.sortBy(collectionWithData, [
        item => _.get(item, 'customData.productionIdIndex', 0), //forced sort
        item => collectionKey === 'containers' ? (item.wallPrintInElevation[0].y > 10 ? 1 : 0) : ((yScalar * _.get(item, 'position.y', 10000)) > 10 ? 1 : 0),
        item => collectionKey === 'containers' ? item.wallPrintInElevation[0].x : _.get(item, 'position.x', 10000),
        item => collectionKey === 'containers' ? (item.wallPrintInElevation[0].y) : ((yScalar * _.get(item, 'position.y', 10000))),
      ]);

      return sorted;
    };

    var updateProductProductionIdsForScope = ({elevation, productionLetter, productIndex, rank, currentProductionIds, updatedContainers}) => {
      var containerParent = elevation;

      var {containers} = containerParent;

      var sortedContainers = elevation.id ? spacialSort({collection: containers, collectionKey: 'containers', elevation}) : _.map(containers);

      var sortedContainers = _.map(sortedContainers, container => {
        return {
          ...container,
          products: Container.get('products', {container})
        };
      });

      _.forEach(sortedContainers, container => {
        if (!_.find(updatedContainers, {id: container.id}) && ((elevation.id ? _.includes([0, 360], lib.round(Container.getElevationTheta({container, elevation}), {toNearest: 1})) : true) || container.type === 'countertop' || (container.type === 'ocSolidCorner' && _.includes(['left', 'front'], Container.getSideKey({elevation, container, viewKey: 'front'}))))) {
          var containerProducts = container.products;

          //Manually handle scribes because dimension data isn't stored on the product
          var scribeProducts = _.filter(containerProducts, product => {
            return Product.getUseProductionId({product}) && _.get(product, 'customData.sideKey');
          });

          var productsToSort = _.reject(containerProducts, product => {
            return Product.getUseProductionId({product}) && _.get(product, 'customData.sideKey');
          });

          var products = [
            ..._.filter(scribeProducts, product => _.get(product, 'customData.sideKey') === 'left'),
            ...spacialSort({collection: productsToSort, collectionKey: 'products', yScalar: -1, elevation}),
            ..._.filter(scribeProducts, product => _.get(product, 'customData.sideKey') === 'top'),
            ..._.filter(scribeProducts, product => _.get(product, 'customData.sideKey') === 'right')
          ];

          _.forEach(products, product => {
            let productionId;

            if (Product.getUseProductionId({product})) {
              if (!(project.lockedForProduction && product.lockedForProduction)) {
                var placeholder = _.get(product, 'customData.productionIdOffset');

                productionId = `${productionLetter}${productIndex}`;
                productIndex += 1;

                if (placeholder) {
                  productIndex += _.parseInt(placeholder);
                }
                rank += 1;
              }
            }
            else {
              productionId = '';

              if (_.get(product, 'customData.productionIdEnabled') === 0) {
                productUpdates.push({where: {id: product.id}, props: {productionId}});
              }
            }

            if (project.lockedForProduction && !product.lockedForProduction && Product.getUseProductionId({product})) {
              let productionIndex = productIndex - 1;

              _.forEach(currentProductionIds, pId2 => {
                if (pId2 && productionLetter === pId2.replace(/[\d.]/g, '') && parseFloat(pId2.replace(/[^\d.]/g, '')) >= productionIndex) {
                  productionIndex = parseFloat(pId2.replace(/[^\d.]/g, '')) + 1;
                }
              });

              productionId = `${productionLetter}${productionIndex}`;
              rank = productionIndex;
            }

            var shouldUpdateProduct = !(project.lockedForProduction && product.lockedForProduction);
            var productionIdHasChanges = product.productionId !== productionId || product.rank !== rank;

            if (Product.getUseProductionId({product}) && shouldUpdateProduct && product.id) {
              if (productionIdHasChanges) {
                productUpdates.push({where: {id: product.id}, props: {productionId, rank}});
              }

              currentProductionIds.push(productionId);
            }

            let childProducts = _.values(Product.get('childProducts', {product}));

            var isSingleAppliancePanel = Product.getIsAppliance({product}) && childProducts.length <= 1;

            _.forEach(childProducts, (product, subIndex) => {
              var updates = {productionId, rank};

              if (!isSingleAppliancePanel) updates.productionId += `.${subIndex + 1}`;

              var shouldUpdateProduct = !project.lockedForProduction && !product.lockedForProduction;
              var productionIdHasChanges = product.productionId !== updates.productionId || product.rank !== updates.rank;

              if (project.lockedForProduction && !product.lockedForProduction && !isSingleAppliancePanel) {
                let productionIndex = subIndex + 1;
                const nestedChildProducts = Product.get('childProducts', {product});

                _.forEach(nestedChildProducts, p2 => {
                  var productProductionId = p2.productionId;

                  if (productProductionId && parseFloat(productProductionId.replace(/[^\d.]/g, '')) >= productionIndex) {
                    productionIndex = parseFloat(productProductionId.replace(/[^\d.]/g, '')) + 1;
                  }
                });

                updates.productionId = `${productionId}.${productionIndex}`;
              }

              if (shouldUpdateProduct && productionIdHasChanges && product.id) {
                productUpdates.push({where: {id: product.id}, props: updates});
              }
            });
          });

          updatedContainers.push(container);
        }
      });

      return {productIndex, updatedContainers};
    };

    let floors = Project.get('floors', {project});

    _.forEach(_.sortBy(floors, 'rank'), floor => {
      let rooms = _.reject(Floor.get('rooms', {floor}), {isTemporaryArchetype: 1});

      _.forEach(_.sortBy(rooms, 'rank'), room => {
        var productIndex = 1;
        var updatedContainers = [];
        var elevations = [..._.map(Room.get('sortedElevations', {room}), elevation => ({...elevation, containers: Elevation.get('containers', {elevation})}))];

        var roomContainers = Room.get('containers', {room});
        var elevationContainers = _.uniq(_.flatMap(elevations, elevation => {
          return Elevation.get('containers', {elevation});
        }));
        var leftoverContainers = _.filter(roomContainers, container => !_.find(elevationContainers, container));

        elevations.push({containers: leftoverContainers});

        //HINT these are only used when the file is locked so that we properly insert unlocked newly inserted things at the end of the list
        var currentProductionIds = [];

        if (project.lockedForProduction) {
          currentProductionIds = _.map(_.filter(_.flatMap(roomContainers, container => Container.get('products', {container})), product => product.lockedForProduction), 'productionId');
        }

        _.forEach(elevations, elevation => {
          var productionLetter = room.productionId || lib.string.numberToLetters(scopeIndex);

          var updatesData = updateProductProductionIdsForScope({elevation, productionLetter, productIndex, rank, currentProductionIds, updatedContainers});

          productIndex = updatesData.productIndex;

          rank += productIndex;
          updatedContainers = _.uniq([...updatedContainers, ...updatesData.updatedContainers]);
        });

        if (updatedContainers.length !== roomContainers.length) {
          var nonNumberedContainers = _.filter(roomContainers, container => !_.find(updatedContainers, {id: container.id}));
          var productionLetter = room.productionId || lib.string.numberToLetters(scopeIndex);
          var updatesData = updateProductProductionIdsForScope({elevation: {containers: nonNumberedContainers}, productionLetter, productIndex, rank, currentProductionIds, updatedContainers});

          productIndex = updatesData.productIndex;

          rank += productIndex;
          updatedContainers = _.uniq([...updatedContainers, ...updatesData.updatedContainers]);
        }

        scopeIndex += 1;
      });
    });

    if (productUpdates.length > 0) {
      if (isBatched) {
        return {
          products: {
            updates: productUpdates
          }
        };
      }
      else {
        reduxActions.updateProducts({updates: productUpdates});
      }
    }
  }
};

export default updateProductionIds;