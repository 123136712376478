import _ from 'lodash';
import CFG from 'k';
import lib from 'lib';
import Container from 'project-helpers/container/index';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import Project from 'project-helpers/project';
import DetailsHelper from 'helpers/details-helper';
import HatchHelper from 'helpers/hatch-helper';

var CanvasContainerHelper = {
  getMetaProps({elevation, viewKey, container, showUnitNumbers, showGrainFlow, isSelected, activeDetailLevel, activeFillMode, renderForDrawings, hatchFillData, nonSpacialSideKey, isNonSpacial, countertopsAreSelectable, isProjection}) {
    var resources = Container.get(['computedWalls', 'dependencies', 'unmanagedProductInstances', 'companyKey', 'project', 'volumes', 'siblings', 'walls', 'wallSets'], {container})
    var {companyKey, project, dependencies, siblings, wallSets} = resources;
    var isSection = isNonSpacial ? false : Container.getIsSection({container, elevation});
    var containerSideKey = isNonSpacial ? nonSpacialSideKey : Container.getSideKey({elevation, container, viewKey});
    var showColors = _.includes(['materialColors'], activeFillMode);
    var showHatches = _.includes(['materialHatches', 'materialColors'], activeFillMode);
    var scribesData = Container.getScribesData({container, elevation, resources});
    var meldedContainersData = Container.getMeldedContainersData({container, siblings});
    var endPanelHeights = Container.getEndPanelHeights({container, scribesData, companyKey, dependencies});

    var metaProps = {
      showColors,
      activeDetailLevel,
      props: container,
      dimensions: container.dimensions,
      getIsSelected: () => isSelected,
      getBackPanelWidths: () => Container.getBackPanelWidths({container, companyKey}),
      getCustomScript: () => _.get(container, `customData.scripts.${containerSideKey}`, ''),
      getGrainData: ({width, endPanelSideKey}={}) => {
        return CanvasContainerHelper.getGrainData({container, width, endPanelSideKey, showGrainFlow, viewKey})
      },
      getGrainScript: ({grainGroups = [{}], width, endPanelSideKey}={}) => {
        return CanvasContainerHelper.getGrainScript({container, sideKey: containerSideKey, dependencies, grainGroups, width, endPanelSideKey})
      },
      getMeldedContainersData: () => meldedContainersData,
      getWrapPanelWidths: () => Container.getWrapPanelWidths({container}),
      getWrapBackExtensions: () => Container.getWrapBackExtensions({container}),
      getIslandBackPanelMaxPanelSize: () => Container.getIslandBackPanelMaxPanelSize({container, companyKey}),
      getScribesData: () => scribesData,
      getWrapSizes: () => Container.getWrapSizes({container}),
      getFlybyData: () => Container.getFlybyData({container}),
      getInsetKickData: () => Container.getInsetKickData({container}),
      getKickInset: () => Container.getKickInset({container}),
      getFrontViewMode: () => isSection ? 'section' : 'elevation',
      getEndPanelHeights: () => endPanelHeights,
      getNetCapPanelWidth: () => Container.getNetCapPanelWidth({container}),
      getCountertopMaskingData: () => Container.getCountertopMaskingData({container, siblings, wallSets}),
      endPanelPathPointsFor: ({sideKey}) => CanvasContainerHelper.getEndPanelPathPointsFor({sideKey, containerSideKey, container, elevation, scribesData, endPanelHeights, companyKey, dependencies}),
      capPanelPathPointsFor: ({side}) => CanvasContainerHelper.getCapPanelPathPointsFor({side, containerSideKey, container, elevation}),
      getCapPanelWidths: () => Container.getCapPanelWidths({container}),
      getCapPanelLongestSideMaxDim: () => Container.getCapPanelLongestSideMaxDim({container}),
      getCountertopsSelectable: () => countertopsAreSelectable,
      getHasProducts: () => {
        return _.values(resources.unmanagedProductInstances).length > 0;
      },
      getAllProductsHidden: () => {
        return _.every(resources.unmanagedProductInstances, product => product.customData.hideInPlan);
      },
      //TODO
      getTheme: () => 'light', //this.props.project.theme || 'light',
      defaultFontSize: CFG.defaultFontSize,
      //TODO
      getIsRenderingDrawings: () => renderForDrawings,
      getDrawingsType: () => {
        return activeDetailLevel;
        // if(.elevation) {
        //   return this.elevation.drawingsMode;
        // }
        // else if(this.room){
        //   return this.room.drawingsMode;
        // }
      },
      getLabelTextFor: ({key, index = 0, scribeData}) => {
        var labelText = '';

        var isCtopByOthers = key === 'countertop' && _.get(container, 'customData.isByOthers');
        var isCapPanelByOthers = key === 'topWrapPanel' && _.get(container.customData, 'wrap.topByOthers', 0) === 1;
        var isEndPanelByOthers = _.get(container.customData, `wrap.${key === 'leftWrapPanel' ? 'left' : 'right'}ByOthers`, 0) === 1 && _.includes(['leftWrapPanel', 'rightWrapPanel'], key);

        var shouldShowLabel = (showUnitNumbers || (!_.includes(['rendering', 'intermediate', 'schematic'], activeDetailLevel) && (isCtopByOthers || isCapPanelByOthers || isEndPanelByOthers))) && !isSection && !isProjection;

        if (shouldShowLabel) {
          if (viewKey === 'front' && (container.type === 'countertop' || containerSideKey === 'front' || (container.type === 'ocSolidCorner' && _.includes(['left', 'front'], containerSideKey)))) {
            var productCandidates = Container.get('managedProductInstances', {container});
            var sideKey;

            if (_.includes(['leftWrapPanel', 'rightWrapPanel'], key)) {
              sideKey = key === 'leftWrapPanel' ? 'left' : 'right';
              var meldedContainerData = meldedContainersData[sideKey];

              if (meldedContainerData && !meldedContainerData.isPrimaryContainer) {
                productCandidates = Container.get('managedProductInstances', {container: meldedContainerData.container});
                key = `${sideKey === 'left' ? 'right' : 'left'}WrapPanel`;
              }
            }

            var products = lib.waterfall(productCandidates, [
              [_.filter, ['managedData.managedKey', key]],
              [_.sortBy, 'rank']
            ]);

            if (_.includes(['leftWrapPanel', 'rightWrapPanel', 'scribe', 'kick'], key)) {
              if (key === 'scribe' && scribeData) {
                products = _.filter(products, product => {
                  var scribeSideKey = _.get(product, 'customData.sideKey');

                  //HINT support old products without sideKey
                  return scribeSideKey === scribeData.sideKey || !scribeSideKey;
                });
              }

              //HINT stacking unit numbers, correctly show the top panel as on top
              if (_.includes(['leftWrapPanel', 'rightWrapPanel'], key)) {
                _.reverse(products);
              }

              if (key === 'scribe' && scribeData.sideKey === 'top') {
                labelText = _.get(products[index], 'productionId');
              }
              else {
                labelText = _.join(_.map(_.filter(products, 'productionId'), product => product.productionId), ', ');
              }

              if (_.get(container.customData, `wrap.${sideKey}ByOthers`, 0) === 1 && _.includes(['leftWrapPanel', 'rightWrapPanel'], key)) {
                if (!_.get(container.customData, `wrap.${sideKey}HideLabel`)) {
                  labelText = 'B/O';

                  var customThickness = Container.getWrapSizes({container})[sideKey];

                  if (customThickness) labelText += ` - ${customThickness}"`;
                }
              }
            }
            else if (key === 'seatingSupportRod') {
              var labelText = _.join(_.map(_.filter(products, 'productionId'), product => product.productionId), ', ');

              labelText = `${labelText}${labelText ? ' - ' : ''}${companyKey === 'hb' ? 'CT support' : 'support rod'}`

              if (container.customData.countertopSupportDisabled) labelText = '';
            }
            else {
              var product = products[index];

              if (key === 'topWrapPanel') {
                if (isCapPanelByOthers) {
                  if (!_.get(container.customData, 'wrap.topHideLabel')) {
                    labelText = 'B/O';

                    var customThickness = Container.getWrapSizes({container})[sideKey];

                    if (customThickness) labelText += ` - ${customThickness}"`;
                  }
                }
                else {
                  var widths = Container.getCapPanelWidths({container});

                  if (products.length > widths.length) {
                    //HINT this means that we have 2 panels up against eachother back to back
                    //each index will have 2 panels
                    labelText = _.join(_.map(_.filter(products, (p, i) => _.includes([index * 2, index * 2 + 1], i)), product => product.productionId), ', ')
                  }
                  else if (product) {
                    labelText = product.productionId
                  }
                }
              }
              else if (product || key === 'countertop') {
                if (product) {
                  labelText = product.productionId;
                }

                var isByOthers = _.get(container, 'customData.isByOthers');

                var note = {
                  countertop: isByOthers ? 'CT B/O' : ''
                }[key];

                if (note) labelText = `${labelText}${labelText ? ' - ' : ''}${note}`;

                //TODO bug related to section messing with canvasObject width
                //don't want to do this when there is a wall and countertop isn't facing the long way
                if (key === 'countertop') {
                  // var relevantCountertopDimensions = [container.dimensions.depth, container.dimensions.width];
                  //TODO
                  // var visibleCountertopDimensions = container.dimensions.depth;//this.canvasObject.size.width;

                  // if (this.wall && _.max(relevantCountertopDimensions) !== visibleCountertopDimensions) {
                  //   //labelText = '';
                  // }
                }
              }
            }
          }
          else {
            if (key === 'islandExtension') labelText = 'island\nextension';
          }
        }

        if (key === 'islandSeating') labelText = 'seating';
        if (_.includes(['chase', 'baseWithChase'], key)) labelText = 'chase';

        return labelText;
      },
      getShouldShowCenterline: () => {
        let shouldShowCenterline = false;

        if (!_.includes(['schematic', 'rendering'], activeDetailLevel) && elevation && viewKey === 'front' && containerSideKey === 'front' && !isSection) {
          var products = Elevation.get('products', {elevation});
          const centerX = Container.getPositionInElevation({container, elevation}).x + container.dimensions.width / 2;

          shouldShowCenterline = _.some(products, product => {
            let shouldShow = false;

            if (Product.getSideKey({product, elevation, viewKey}) === 'front') {
              const productPositionInElevation = Product.getPositionInElevation({product, elevation});

              shouldShow = _.includes([
                productPositionInElevation.x,
                productPositionInElevation.x + product.dimensions.width / 2,
                productPositionInElevation.x + product.dimensions.width
              ], centerX);
            }

            return shouldShow;
          });
        }

        return shouldShowCenterline;
      },
      childFrontPathPoints: () => {
        var childFrontPathPoints = [];

        if (container.type === 'wall' && viewKey === 'top') {
          var relevantProducts = _.sortBy(_.filter(Container.get('unmanagedProductInstances', {container}), product => product.position.y === 0), 'position.x');

          if (relevantProducts.length) {
            _.forEach(relevantProducts, (product, index) => {
              var {inverseZPosition} = Product.get('productType', {product});
              var {dimensions, position} = product;

              dimensions = _.clone(dimensions);

              if (_.includes([...CFG[companyKey].ids.verticalHiddenPanels, ...CFG[companyKey].ids.horizontalHiddenPanels], product.productId)) {
                var swapDimKey = _.includes(CFG[companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

                dimensions = {...dimensions, [swapDimKey]: dimensions.depth, depth: dimensions[swapDimKey]};
              }

              var points = [];

              if (index === 0) {
                points.push({x: position.x, y: 0});
              }

              var depthOffset = 0;
              var zScalar = 1;

              var alignWithBackOfContainer = product.id <= 1247053 || (inverseZPosition && product.id > 1324195);

              if (!alignWithBackOfContainer) {
                var containerDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
                var containerDropzoneDepth = (containerDropzoneSize.depth || containerDropzoneSize.height);

                var productDepthDim = product.dimensions.depth;

                //HINT opencase components are flush with and extend past the front of the container
                if (Product.getIsOpencaseComponent({product})) {
                  productDepthDim = 0;
                  containerDropzoneDepth = 0;
                }

                if (_.includes([...CFG[companyKey].ids.verticalHiddenPanels, ...CFG[companyKey].ids.horizontalHiddenPanels], product.productId)) {
                  var swapDimKey = _.includes(CFG[companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

                  productDepthDim = product.dimensions[swapDimKey];
                }

                depthOffset = containerDropzoneDepth - productDepthDim;
                zScalar = -1
              }

              points.push(
                {x: position.x, y: (position.z * zScalar) + dimensions.depth + depthOffset},
                {x: position.x + dimensions.width, y: (position.z * zScalar) + dimensions.depth + depthOffset}
              );

              if (index === relevantProducts.length - 1) points.push({x: position.x + dimensions.width, y: 0});

              childFrontPathPoints.push(points);
            });

            childFrontPathPoints = _.flatMap(childFrontPathPoints);

            childFrontPathPoints.push({x: _.last(childFrontPathPoints).x, y: 0});
          }
        }

        return childFrontPathPoints;
      },
      //hint laminate doesn't need to break
      getShouldBreakDeepBaseEndPanels: (sideKey) => {
        var endPanelMaterialId = _.get(container, 'details.endPanelMaterial.id');
        var material = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: endPanelMaterialId});
        var isVeneer = _.includes(CFG[companyKey].materialIds.endPanelVeneer, endPanelMaterialId) || (_.includes([1, 22], _.get(material, 'materialTypeId')));

        return isVeneer || Container.getWrapSizes({container})[sideKey] === 4;
      },
      getShouldInvertStroke: ({materialKey}={}) => {
        var shouldInvertStroke = _.get(hatchFillData, `shouldInvertStrokeByMaterialKey.${materialKey}`);

        return shouldInvertStroke;
      },
    };

    if (Container.getSupportsLighting({container})
      && (Container.getLightingProducts({container}).length > 0 || resources.unmanagedProductInstances.length <= 0)
    ) {
      _.extend(metaProps, {
        getLightPositions: () => _.get(container, 'customData.lightingType') === 'puck' ? Container.getLightPositions({container}) : [],
        lightingDepth: Container.getLightingDepth({container}),
        getLightRanges: () => Container.getLightRanges({container})
      });
    }

    if (container.type === 'pivotDoor') {
      _.extend(metaProps, {
        getPivotDoorHasSurroundingPanels: () => {
          return /*this.drawingsMode || */_.some(Container.get('products', {container}), productInstance => {
            return !_.includes([384, 385], productInstance.productId);
          });
        }
      });
    }

    return metaProps;
  },

  getGrainData({container, width, viewKey, endPanelSideKey, showGrainFlow}) {
    var {grainDirection, grainContinuity} = container.details;
    //TODO
    var isShowing = showGrainFlow && viewKey === 'front' && _.get(grainDirection, 'id', 'vertical') !== 'hidden';//(viewKey === 'front' && this.editorStepKey === 'details') || ((this.renderForDrawings || _.get(this, 'parent.renderForDrawings')) && !exportingDxf);
    var direction = _.get(grainDirection, 'id', 'vertical');

    //TODO get wrap sidekey and enforce vertical flow for radius end panels
    if (direction === 'horizontal' && Container.getWrapSizes({container})[endPanelSideKey] === 1.5 && _.get(container, `customData.wrap.${endPanelSideKey}PanelRoundedSides`, 'none') !== 'none') {
      direction = 'vertical';
    }

    //TODO handle split panels, not handling properly right now
    // var widthDim = width || container.dimensions.depth;

    // if (widthDim > 47 && container.dimensions.height <= 47) direction = 'horizontal';
    // else if (container.dimensions.height > 47 && widthDim <= 47) direction = 'vertical';
    // else if (_.get(grainDirection, 'id')) direction = _.get(grainDirection, 'id');

    var continuity = _.get(grainContinuity, 'id', 'none');

    return {isShowing, direction, continuity};
  },

  getGrainScript({container, sideKey, dependencies, width, endPanelSideKey, grainGroups}) {
    grainGroups = grainGroups || [{}];

    var frontMaterialData = _.get(container, 'details.frontMaterial');

    if (_.includes(['islandSeating', 'islandBackPanel'], container.type)) {
      frontMaterialData = _.get(container, 'details.islandBackPanelMaterial');
    }

    if (['left', 'right'].includes(sideKey) && _.get(container, `customData.wrap[${sideKey}]`)) {
      frontMaterialData = _.get(container, 'details.endPanelMaterial');
    }

    var isEndPanelByOthers = false;

    if (container.type === 'endPanel') {
      isEndPanelByOthers = _.get(container, 'details.endPanelMaterial.id') === 331 || _.get(container.customData, `wrap.${sideKey}ByOthers`, 0) === 1;
    }
    else if (['left', 'right'].includes(sideKey) && _.get(container, `customData.wrap[${sideKey}]`)) {
      isEndPanelByOthers = _.get(container, 'details.endPanelMaterial.id') === 331 || _.get(container.customData, `wrap.${sideKey}ByOthers`, 0) === 1;
    }

    var frontMaterial = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: _.get(frontMaterialData, 'id')});

    return (
      //only show grain direction for wood fronts
      !isEndPanelByOthers && frontMaterialData && frontMaterial && frontMaterialData.id !== 331 && (_.includes([
        191, 194, 1, 158, 166, 176, 103, 104, 105, 239, 1, 3, 4, 5, 6, 8,
        135, 151, 152, 153, 154, 155, 170, 171, 172, 173, 174,
        175, 176, 177, 178, 180, 279, 303, 8, 9, 136, 143, 144, 145, 146, 147, 148, 149, 150, 158,
        160, 161, 162, 163, 164, 165, 166, 167, 168, 179, 191, 192, 193, 194, 240, 249, 250, 251,
        262, 304, 316, 317, 319, 320, 321, 325, 329, 330, 332, 337, 346, 349, 339, 350, 351, 352, 353, 358, 368, 369, 370, 405, 410, 412, 413, 431
      ], frontMaterialData.id) || _.includes([1, 3, 12], frontMaterial.materialTypeId)) ? `
      (() => {
        var grainGroups = ${JSON.stringify(grainGroups)};
        var lineGroups = [];
        var arrowWidth = 3;
        var useDepth = ${['left', 'right'].includes(sideKey)};

        lodash.forEach(grainGroups, ({height: groupHeight, top}, groupIndex) => {
          var {direction, continuity, isShowing} = _.getGrainData ? _.getGrainData({width: ${width}, endPanelSideKey: '${endPanelSideKey}'}) : {};
          var {width, height} = _.dimensions;

          if (useDepth) {
            var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};
            width = wrapPanelWidths['${sideKey}'] || _.dimensions.depth;
          }

          var shouldPositionCenter = direction === 'vertical' ? (width <= arrowWidth * 2) : (height <= arrowWidth * 2);

          if (shouldPositionCenter) {
            arrowWidth = Math.min(width / 2, height / 2);
          }

          var pos = {flush: {from: arrowWidth / 2, to: '100%'.concat('-', arrowWidth / 2)}, inset: {from: arrowWidth, to: '100%'.concat('-', arrowWidth)}};
          var hasNoneOrToContinuty = lodash.includes(['none', 'to'], continuity) || groupIndex > 0;
          var hasNoneOrFromContinuty = lodash.includes(['none', 'from'], continuity) || (groupIndex < grainGroups.length - 1);
          var offset = arrowWidth / 2;
          var lines = [];

          var shouldPositionLeft = direction === 'vertical' && ${_.get(container.customData, `wrap.${container.type === 'endPanel' ? 'left' : sideKey}GrainIndicatorSide`) === 'left'};

          if (shouldPositionLeft) {
            pos.inset.to = '' + arrowWidth;
          }

          if (isShowing && direction !== 'none') {
            var mainLineProps = direction === 'vertical' ?
            {
              x1: pos.inset.to, x2: pos.inset.to,
              y1: hasNoneOrToContinuty ? pos.flush.from : 0, y2: hasNoneOrFromContinuty ? pos.flush.to : '100%',
              stroke: 'red'
            } :
            {
              x1: hasNoneOrFromContinuty ? pos.flush.to : '100%', x2: hasNoneOrToContinuty ? pos.flush.from : 0,
              y1: pos.inset.from, y2: pos.inset.from,
              stroke: 'red'
            };

            if (hasNoneOrFromContinuty) {
              if (direction === 'vertical') {
                lines.push(line({
                  x1: pos.inset.to,
                  x2: shouldPositionLeft ? arrowWidth * 3/2 : pos.flush.to,
                  y1: pos.flush.to,
                  y2: shouldPositionLeft ? '100%'.concat('-', arrowWidth) : pos.inset.to,
                  stroke: 'red'
                }));
              }
              else {
                lines.push(line({
                  x1: pos.flush.to,
                  x2: pos.inset.to,
                  y1: pos.inset.from,
                  y2: pos.flush.from,
                  stroke: 'red'
                }));
              }
            }

            if (hasNoneOrToContinuty) {
              if (direction === 'vertical') {
                lines.push(line({
                  x1: pos.inset.to,
                  x2: pos.inset.to.concat('-', offset),
                  y1: pos.flush.from,
                  y2: pos.inset.from,
                  stroke: 'red'}));
              }
              else {
                lines.push(line({
                  x1: pos.flush.from,
                  x2: pos.inset.from,
                  y1: pos.flush.from + offset,
                  y2: pos.inset.from + offset,
                  stroke: 'red'
                }));
              }
            }

            lines.push(line(mainLineProps));
          }

          lineGroups.push(group({top: top || 0, height: groupHeight || '100%'}, lines));
        });

        return group({}, lineGroups);
      })()
    ` : 'group({}, [])');
  },

  getEndPanelPathPointsFor({sideKey, container, elevation, scribesData, endPanelHeights, companyKey, dependencies}) {
    return lib.memo(CanvasContainerHelper.calcEndPanelPathPointsFor, {
      data: {
        sideKey,
        wallprint: Container.getWallprint({container, elevation}),
        wrapSizes: Container.getWrapSizes({container}),
        endPanelHeights: endPanelHeights,
        resourceProps: container,
        elevation
      }
    });
  },

  calcEndPanelPathPointsFor({data}) {
    var {sideKey, wrapSizes, wallprint, endPanelHeights, elevation, resourceProps} = data;

    var archPoints = Container.getArchPoints({container: resourceProps, elevation});
    var heightData = endPanelHeights[sideKey] || {};
    var visibleHeight = _.sum(heightData.visible);
    var wrapSize = wrapSizes[sideKey];
    var xInside = sideKey === 'left' ? wrapSize : 0;
    var xOutside = sideKey === 'left' ? 0 : wrapSize;

    var points = [
      {x: xInside, y: 0},
      {x: xOutside, y: 0},
      {x: xOutside, y: -visibleHeight},
      {x: xInside, y: -visibleHeight},
      {x: xInside, y: -(resourceProps.dimensions.height - heightData.insetKickHeight)},
      ...(resourceProps.type === 'endPanel' ? [{x: xInside, y: 0}] : [])
    ];

    var positionInWallTransform = {
      x: wallprint[0].x + (sideKey === 'right' ? resourceProps.dimensions.width - wrapSizes.x : 0),
      y: resourceProps.position.y + heightData.insetKickHeight
    };

    var pointsInWall = _.map(points, point => {
      return lib.object.sum({x: point.x, y: -point.y}, positionInWallTransform);
    });

    if (archPoints.length > 0) {
      var maskedPathPointPolygons = lib.math.polygon.overlappingPolygonFrom({
        p1: pointsInWall, p2: _.map(archPoints, point => ({x: point.x, y: -point.y}))
      });

      if (maskedPathPointPolygons.length > 0) {
        points = _.map(maskedPathPointPolygons[0], point => {
          return lib.object.difference({x: point.x, y: -point.y}, positionInWallTransform);
        });

        var maxPointY = _.max(_.map(points, 'y'));

        points = _.map(points, point => ({x: point.x, y: point.y - maxPointY}));

        //HINT close the path if end panel
        if (resourceProps.type === 'endPanel' && resourceProps.position.y !== 0) points = [...points, _.first(points)];
      }
    }

    return points;
  },
  getCapPanelPathPointsFor({side, container, containerSideKey}) {
    var wrapSize = container.customData.wrap[`${side}Thickness`];
    var yInside = side === 'bottom' ? 0 : wrapSize;
    var yOutside = side === 'bottom' ? wrapSize : 0;

    var points = [
      {x: 0, y: yInside},
      {x: 0, y: yOutside},
      {x: '100%', y: yOutside},
      {x: '100%', y: yInside},
      ...(container.type === 'capPanel' ? [{x: 0, y: yInside}] : [])
    ];

    return points;
  }
};

export default CanvasContainerHelper;
