import React from 'react';
import { resourceActions, connect, issuesDataActions } from 'redux/index.js';
import TextInput from 'components/text-input';
import Popup from 'components/popup';
import lib from 'lib';
import K from 'k';
import _ from 'lodash';
import Room from 'project-helpers/room';

import upArrowIcon from 'assets/up-arrow-black.png';
import xIconBlack from 'assets/x-icon-black.png';

import {SearchBar, Tag, Button, Icon} from '@henrybuilt/react-lib'

class ArchetypeBrowseMenu extends React.PureComponent {
  state = {isLoading: true, activeArchetype: undefined, activeSearchTerm: '', activeFilters: [], mediaByArchetypeId: {}, activeMediaIndex: 0, tags: [], tagCategories: [], filters: {}};

  async componentDidMount() {
    //TODO track in redux once initially loaded and don't load again
    var {tags, tagCategories, media} = await lib.api.get({
      media: {where: {archetypeId: _.map(this.props.archetypes, 'id'), isPublic: 1}, order: ['rank']},
      tags: {},
      tagCategories: {}
    });

    var mediaByArchetypeId = {};

    _.forEach(media, (medium) => {
      if (_.get(medium, 'associations.archetypes')) {
        _.forEach(medium.associations.archetypes, (value, key) => {
          var archetypeId = _.replace(key, 'id_', '');

          if (!mediaByArchetypeId[archetypeId]) mediaByArchetypeId[archetypeId] = [];

          mediaByArchetypeId[archetypeId].push(medium);
        });
      }
    });

    this.setState({mediaByArchetypeId, tags, tagCategories, isLoading: false});
  }

  handleFilterChange = ({key, value, reset}) => {
    let updatedFilters = _.cloneDeep(this.state.filters);

    if (key === 'title') {
      if (reset) delete updatedFilters[key];
      else {
        updatedFilters[key] = [value];
      }
    }
    else if (updatedFilters[key]) {
      if (_.includes(updatedFilters[key], value)) {
        updatedFilters[key] = _.reject(updatedFilters[key], currentValue => currentValue === value);

        if (updatedFilters[key].length === 0) delete updatedFilters[key];
      }
      else {
        updatedFilters[key].push(value);
      }
    }
    else {
      updatedFilters[key] = [value];
    }

    this.setState({filters: updatedFilters});
  };

  handleActiveArchetypeIterator = (direction) => {
    var {activeArchetype, activeMediaIndex} = this.state;
    var {mediaByArchetypeId} = this.state;

    var media = _.filter(_.get(mediaByArchetypeId, `${activeArchetype.id}`, []), medium => medium.subjectType !== 'thumbnail');

    if (direction === 'next') {
      if (activeMediaIndex < media.length - 1) {
        this.setState({activeMediaIndex: activeMediaIndex + 1});
      }
      else {
        this.setState({activeMediaIndex: 0});
      }
    }
    else if (direction === 'previous') {
      if (activeMediaIndex > 0) {
        this.setState({activeMediaIndex: activeMediaIndex - 1});
      }
      else {
        this.setState({activeMediaIndex: media.length - 1});
      }
    }
  }

  handleMoveToSchematicDecisionsClick = async () => {
    if (!this.state.activeArchetype) return;

    this.setState({isLoadingArchetype: true});

    var {activeArchetype} = this.state;

    var {project} = this.props

    const apiResponse = await lib.api.request({uri: 'de-project/create-archetype', body: {projectId: project.id, versionId: project.versionId, archetypeId: activeArchetype.id, roomProps: {floorId: this.props.floorId, plan: {position: {x: -100000, y: -100000}}, isTemporaryArchetype: 1}}});
    const {
      room,
      scopes,
      elevations,
      walls,
      volumes,
      archElementInstances,
      containerInstances,
      productInstances,
      productOptionInstances,
      projectGraphics
    } = apiResponse.data;

    this.props.trackScopes({scopes});
    this.props.trackElevations({elevations});
    this.props.trackWalls({walls});
    this.props.trackVolumes({volumes});
    this.props.trackArchElements({archElements: archElementInstances});
    this.props.trackContainers({containers: containerInstances});
    this.props.trackProducts({products: productInstances});
    this.props.trackProductOptions({productOptions: productOptionInstances});
    this.props.trackProjectGraphics({projectGraphics});
    this.props.trackRooms({rooms: [room]});

    setTimeout(() => {
      //TODO figure out not impacting overall project stuff
      //trim kit + unit numbers
      Room.updateManagedResources({room, reduxActions: this.props});

      this.setState({isLoadingArchetype: false});

      this.props.onMoveToSchematicDecisionsClick({newRoom: room, newArchetypeId: activeArchetype.id});
    });
  }

  render() {
    var {activeArchetype, activeFilters, activeSearchTerm, mediaByArchetypeId, activeMediaIndex, tags, tagCategories, filters, activeSearchTerm, isLoading, isLoadingArchetype} = this.state;
    var {archetypes, category} = this.props;

    var usedTagIds = _.uniq(_.flatMap(_.filter(archetypes, {type: category}), 'tagIds'));

    tags = _.filter(tags, tag => _.includes(usedTagIds, tag.id));

    var filtersData = _.map([
      ...(activeSearchTerm ? [
        {key: 'title', label: 'Title', options: [{label: activeSearchTerm, value: activeSearchTerm}]},
      ]: []),
      ..._.map(tagCategories, tagCategory => {
        return {
          key: `${tagCategory.id}`,
          label: tagCategory.title,
          options: _.map(_.filter(tags, {tagCategoryId: tagCategory.id}), tag => {
            return {label: tag.aliasesByContext.archetypes || tag.aliasesByContext.media, value: tag.id};
          })
        };
      })
    ], filterData => ({
      ...filterData,
      activeOptions: filters[filterData.key],
    }));

    filtersData = _.filter(filtersData, filterData => !_.isEmpty(filterData.options));

    var filteredArchetypes = _.filter(archetypes, archetype => {
      var archetypeTags = archetype.tagIds;

      return archetype.type === category && _.every(filters, (filterValues, filterKey) => {
        if (filterKey === 'title') {
          return _.includes(_.toLower(archetype.title), _.toLower(filterValues[0]));
        }
        else {
          return _.some(filterValues, filterValue => _.includes(archetypeTags, filterValue));
        }
      });
    });

    return (
      <Popup onClose={this.props.onClose} style={{padding: '0'}}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          width: "calc(100vw - 3.5rem)",
          height: "calc(100vh - 3.5rem)",
          background: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          overflow: "hidden"
        }}>
          {isLoading ? (
            <div style={{position: 'absolute', zIndex: 10, display: 'flex', flexDirection: 'column', height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{zIndex: 10, display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: K.margin * 2, alignItems: 'center'}}>
                <div style={{fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em', textTransform: 'uppercase', marginBottom: '0.5rem'}}>
                  Loading...
                </div>
                <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
              </div>
            </div>
          ) : (
            <>
              {this.state.isLoadingArchetype && (
                <div style={{position: 'absolute', zIndex: 10, display: 'flex', flexDirection: 'column', height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{zIndex: 10, display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: K.margin * 2, alignItems: 'center'}}>
                    <div style={{fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em', textTransform: 'uppercase', marginBottom: '0.5rem'}}>
                      Loading Archetype...
                    </div>
                    <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
                  </div>
                </div>
              )}
              {/* Filters & Search */}
              <div style={{ display: "flex", padding: "16px", flexDirection: 'column', borderBottom: "1px solid #ccc" }}>
                {!_.isEmpty(filtersData) && <SearchBar
                  wrapperStyle={{
                    marginRight: K.spacing,
                    height: 40,
                    flexGrow: 1,
                    zIndex: 2
                  }}
                  style={{
                    paddingLeft: K.spacing,
                    borderRadius: 5,
                    border: '1px solid gray',
                  }}
                  onSearchInput={(e) => this.setState({activeSearchTerm: e.target.value})}
                  filters={filtersData}
                  onFilterChange={this.handleFilterChange}
                />}
                {!_.isEmpty(filters) && (<div style={{marginTop: K.spacing, display: 'flex'}}>
                    <Button style={{cursor: 'pointer', width: 36, height: 36, justifyContent: 'center', alignItems: 'center', borderRadius: '75px', marginRight: K.spacing}} onClick={() => this.setState({filters: {}})} icon={<Icon style={{...K.defaultIconSize}}src={xIconBlack}/>}/>
                    {_.map(filters, (filterData, filterKey) => {
                      var filter = _.find(filtersData, {key: filterKey});

                      if (filterKey === 'title') filter = {label: 'Title', key: 'title', options: [{value: filterData[0], label: filterData[0]}]};

                      return _.map(filterData, activeFilterOption => {
                        return(
                          <Tag
                            style={{marginRight: 1}}
                            onClose={() => this.handleFilterChange({key: filter.key, value: activeFilterOption, reset: true})}
                            closable={true}
                            key={`${filter.key}-${activeFilterOption}`}>
                            <span>{filter.label}: {_.find(filter.options, {value: activeFilterOption}).label}</span>
                          </Tag>
                        );
                      });
                    })}
                </div>)}
              </div>

              {/* Main Content */}
              <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
                {/* Sidebar */}
                <div style={{ width: "33%", borderRight: "1px solid #ccc", overflowY: "auto", padding: "16px" }}>
                  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "16px" }}>
                    {filteredArchetypes.map((archetype, index) => (
                      <img
                        key={index}
                        src={_.get(mediaByArchetypeId, `${archetype.id}[0].url`, "")}
                        alt={archetype.title}
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          height: '10rem',
                          // backgroundColor: '#f0f0f0',
                          objectFit: 'contain',
                          border: activeArchetype === archetype ? "2px solid black" : "2px solid transparent",
                        }}
                        onClick={() => this.setState({activeArchetype: archetype, activeMediaIndex: 0})}
                      />
                    ))}
                  </div>
                </div>

                {/* Main Content Area */}
                <div style={{ width: "67%", padding: "24px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{width: '90%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', overflowY: "auto"}}>
                    {/* Top section with diagrams */}
                    <div style={{display: "flex", justifyContent: "center", gap: "20px", marginBottom: "30px", height: '25%', width: '100%'}}>
                      {activeArchetype && (
                        <>
                          <div style={{height: '90%'}}>
                            <img src={_.get(_.filter(mediaByArchetypeId[activeArchetype.id], {subjectType: 'thumbnail'}), `[0].url`, "")} alt={activeArchetype.title} style={{width: "100%", height: '100%', objectFit: 'contain'}} />
                          </div>
                          <div style={{height: '90%'}}>
                            <img src={_.get(_.filter(mediaByArchetypeId[activeArchetype.id], {subjectType: 'thumbnail'}), `[1].url`, "")} alt={activeArchetype.title} style={{width: "100%", height: '100%', objectFit: 'contain'}} />
                          </div>
                        </>
                      )}
                    </div>

                    {/* Selected Image */}
                    {activeArchetype && (
                      <div style={{height: '50%'}}>
                        <img src={_.get(_.filter(mediaByArchetypeId[activeArchetype.id], medium => medium.subjectType !== 'thumbnail'), `[${activeMediaIndex || 0}].url`, "")} alt={activeArchetype.title} style={{width: "100%", height: '100%', objectFit: 'contain'}} />
                      </div>
                    )}

                    {/* Description and Navigation */}
                    {activeArchetype && (
                      <div style={{width: '100%', marginTop: "16px", display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', minHeight: 23}}>
                          <div>
                            <div style={{fontSize: "16px", textTransform: 'uppercase', fontWeight: '350', letterSpacing: '1.6px', opacity: 0.8}}>{activeArchetype.title}</div>
                            {!!activeArchetype.description && <div style={{opacity: 0.8, fontSize: '10px', marginTop: K.spacing}}>{activeArchetype.description}</div>}
                          </div>
                          <div style={{flexGrow: 1}}></div>
                          <div style={{justifyContent: 'flexEnd'}}>
                            {_.filter(mediaByArchetypeId[activeArchetype.id], medium => medium.subjectType !== 'thumbnail').length > 1 && (<div style={{display: 'flex', flexDirection: 'row'}}>
                              <div style={{cursor: 'pointer', marginRight: '2rem'}} onClick={() => this.handleActiveArchetypeIterator('previous')}>
                                <img src={upArrowIcon} style={{height: 20, width: 20, transform: 'rotate(270deg)', position: 'relative', top: '2px'}} />
                              </div>
                              <div style={{cursor: 'pointer'}} onClick={() => this.handleActiveArchetypeIterator('next')}>
                                <img
                                  src={upArrowIcon}
                                  style={{height: 20, width: 20, transform: 'rotate(90deg)'}}
                                />
                              </div>
                            </div>)}
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
                          <div style={{cursor: 'pointer', fontSize: '11px', textTransform: 'uppercase', opacity: 0.8, fontWeight: '400', letterSpacing: '1.5px'}} onClick={this.handleMoveToSchematicDecisionsClick}>NEXT STEP: {_.size(activeArchetype.parameters) > 0 ? 'SCHEMATIC DECISIONS →' : 'ADD TO FLOOR PLAN →'}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Popup>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    return {
      archetypes: _.map(state.resources.archetypes.byId),//_.filter(state.resources.archetypes.byId, {type: ownProps.category}),
      project: _.values(state.resources.projects.byId)[0],
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.elevations, ['trackElevations', 'createElevation', 'createElevations', 'updateElevations', 'updateElevation', 'destroyElevation', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'updateContainer', 'updateContainers', 'createContainers', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'updateRooms', 'destroyRoom', 'destroyRooms', 'modifyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'createScopes', 'updateScope', 'updateScopes', 'destroyScopes', 'destroyScope']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'destroyArchElements']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'destroyWall', 'destroyWalls', 'updateWall', 'updateWalls', 'createWall', 'createWalls', 'modifyWalls']),
    ..._.pick(resourceActions.projectGraphics, ['createProjectGraphic', 'trackProjectGraphics', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'destroyVolumes']),
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','updateParts']),
    ..._.pick(resourceActions.floors, ['createFloor', 'trackFloors', 'updateFloor', 'updateFloors', 'destroyFloor']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
    ..._.pick(resourceActions.media, ['createMedium', 'updateMedium'])
  }
})(ArchetypeBrowseMenu);