import React, { useContext, useState } from 'react';
import { connect } from 'redux/index.js';
import { resourceActions, issuesDataActions } from 'redux/index.js';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';

import ProjectDataContext from 'contexts/project-data-context';

import { withErrorBoundary } from 'react-error-boundary';

import Room from 'project-helpers/room';
import Scope from 'project-helpers/scope';

import { EditableCanvasPolyline, EditableCanvasLine, CanvasDataContext, CanvasSelectionContext, CanvasElevationIndicator, CanvasLine, CanvasErrorFallback } from 'canvas';

function CanvasScope({scope, room, isDisabled, isSelected, isDraggable = true, selectionData, projectData, viewOffset, ...reduxActions}) {
  const [cachedScope, setCachedScope] = useState(null);
  const [isTransforming, setIsTransforming] = useState(false);

  var handleChange = async ({hitApi = true, props}) => {//eslint-ignore-line
    if (!hitApi && !isTransforming) {
      setCachedScope(scope);
      setIsTransforming(true);
    }
    else if (hitApi) {
      projectData.pushToUndoQueue({type: 'scope', eventKey: 'transformEnd', instance: cachedScope || scope});

      setCachedScope(undefined);
      setIsTransforming(false);
    }

    reduxActions.updateScope({id: scope.id, hitApi: false, props: {plan: {...scope.plan, ...props}}});

    if (hitApi) {
      Scope.updateComponents({room, scope: {...scope, plan: {...scope.plan, ...props}}, reduxActions});

      await lib.api.update('scope', {where: {id: scope.id}, props: {plan: {...scope.plan, ...props}}});
    }
  };

  var handlePointsChange = (points, hitApi = true) => {//eslint-ignore-line
    handleChange({props: {points}, hitApi});
  };

  var handleAddPoint = ({points}) => {//eslint-ignore-error
    handleChange({props: {points}});
  };

  var handleOpen = () => {//eslint-ignore-error
    const points = _.cloneDeep(scope.plan.points);

    points.pop();

    handleChange({props: {points, closed: false}});
  };

  var handleClose = ({position, points}) => {//eslint-ignore-error
    //TODO if scope is tentative and has no ID, make api request to create
    handleChange({props: {closed: true, position, points}});
  };

  var handleDelete = () => {

    if (!_.find(Room.get('scopes', {room}), s1 => s1.id !== scope.id && s1.roomId === scope.roomId)) {
      alert('You must keep at least one scope.');

      return;
    }

    if (confirm('Are you sure you want to delete this scope? This can\'t be undone')) {
      selectionData.setActiveEntities({entities: []});

      Scope.updateComponents({room, reduxActions, excludedScopeIds: [scope.id]});

      reduxActions.destroyScope({id: scope.id});
    }
  };

  var handleDragEnd = (position) => {//eslint-ignore-error
    handleChange({props: {position: _.mapValues(position, point => lib.round(point, {toNearest: K.minPrecision}))}});
  };

  var handleDeletePoint = (points) => {//eslint-ignore-error
    handlePointsChange(points);
  };

  var handlePointDragMove = (points) => {
    handlePointsChange(points, false);
  };

  var handlePointDragEnd = (points) => {//eslint-ignore-error
    handlePointsChange(points);
  };

  var handleSelect = () => {//eslint-ignore-error
    var {activeEntities, setActiveEntities} = selectionData;

    if (_.get(activeEntities, '0.id') !== scope.id) {
      setActiveEntities({entities: [{id: scope.id, resourceKey: 'scope'}], isMultiSelect: false});
    }
  };

  return (
    <EditableCanvasPolyline
      {...{...scope.plan}}
      stroke={'#f2c983'}
      fill = {'#f2c983'}
      fillOpacity={0.4}
      offset={lib.object.sum(viewOffset, room.plan.position)}
      permitPseudoPoints={true}
      isSelected={isSelected}
      isDraggable={isDraggable}
      isEnabled={!isDisabled} //TODO set using state
      onPointsChange={handlePointsChange}
      onAddPoint={handleAddPoint}
      onOpen={handleOpen}
      onClose={handleClose}
      onDelete={handleDelete}
      onDragEnd={handleDragEnd}
      onDeletePoint={handleDeletePoint}
      onPointDragMove={handlePointDragMove}
      onPointDragEnd={handlePointDragEnd}
      onSelect={handleSelect}
    />
  );
}

function CanvasScopeWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  var selectionData = useContext(CanvasSelectionContext);
  var projectData = useContext(ProjectDataContext);

  if (props.renderForContextCanvas) {
    selectionData = {};
  }
  else if (props.renderForDrawings) {
    selectionData = {};
  }

  var isSelected = props.scope && _.size(selectionData) ? selectionData.getIsActiveEntity({id: props.scope.id, resourceKey: 'scope'}) : false;
  selectionData = _.omit(selectionData, ['activeDimensionData', 'activeDatumData']);

  return <CanvasScope {...props} {...{isSelected, canvasData: _.pick(canvasData, ['isStatic']), selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapDispatch: {
    ..._.pick(resourceActions.scopes, ['updateScope', 'destroyScope']),
    ..._.pick(resourceActions.products, ['modifyProducts']),
    ..._.pick(resourceActions.containers, ['modifyContainers']),
    ..._.pick(resourceActions.productOptions, ['modifyProductOptions']),
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','updateParts']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
  }
})(CanvasScopeWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasScope'})
});
