import React, {useState} from 'react';
import _ from 'lodash';
import K from 'k';
import Popup from '../../../components/popup';
import Dropdown from '../../../components/dropdown';
import Elevation from 'project-helpers/elevation';

const AddGraphicPopup = ({onClose, onChange, elevations, rooms, floors}) => {
  const [selection, setSelection] = useState(null);
  const [hoveredKey, setHoveredKey] = useState(null);

  const styles = {
    popup: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 3,
    },
    button: (key) => ({
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      ...K.fonts.label,
      height: 20,
      opacity: selection === null && key === 'insert' ? 0.5 : hoveredKey === key ? 0.7 : 0.9
    }),
    buttonContainer: {
      marginTop: K.spacing,
      display: 'flex',
      gap: K.spacing,
      justifyContent: 'flex-end',
      flex: 1,
      alignItems: 'flex-end'
    },
  };

  const options = _.flatMap(floors, floor => {
    var floorOption = {value: 'floor-' + floor.id, title: `Floor - ${floor.title}`};
    var floorRooms = _.filter(rooms, room => room.floorId === floor.id);

    var floorRoomOptions = _.flatMap(floorRooms, ({id, title}) => {
      var roomOption = {value: 'room-' + id, title: `Room - ${title}`};
      var roomOptionElevations = _.sortBy(_.filter(elevations, elevation => elevation.roomId === id), roomOptionElevation => roomOptionElevation.rank);
      var roomOptionElevationsOptions = _.map(roomOptionElevations, elevation => ({value: 'elevation-' + elevation.id, title: `${_.get(rooms, `${elevation.roomId}.title`)} - ${Elevation.getTitle({elevation})}`}));

      return [roomOption, ...roomOptionElevationsOptions];
    });

    return [floorOption, ...floorRoomOptions];
  });

  const handleInsertClick = async () => {
    if (selection !== null) {
      await onChange(selection);

      onClose();
    }
  };

  const handleSelectionChange = (option) => setSelection(option);
  const handleMouseEnter = (key) => () => setHoveredKey(key);
  const handleMouseLeave = () => setHoveredKey(null);

  return (
    <Popup {...{onClose}} style={styles.popup}>
      <Dropdown options={options} onChange={handleSelectionChange} overrideLabel={'Graphics'} hasFixedHeight/>
      <div style={styles.buttonContainer}>
        <button style={styles.button('cancel')} onClick={onClose} onMouseEnter={handleMouseEnter('cancel')} onMouseLeave={handleMouseLeave}>Cancel</button>
        <button disabled={selection === null} style={styles.button('insert')} onClick={handleInsertClick} onMouseEnter={handleMouseEnter('insert')} onMouseLeave={handleMouseLeave}>Insert graphics</button>
      </div>
    </Popup>
  );
};

export default AddGraphicPopup;