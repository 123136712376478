import React, { useContext } from 'react';

import _ from 'lodash';
import lib from 'lib';
import Product from 'project-helpers/product';
import Container from 'project-helpers/container/index';
import Elevation from 'project-helpers/elevation';
import K from 'k';

import { resourceActions, connect, issuesDataActions } from 'redux/index.js';

import { withErrorBoundary } from 'react-error-boundary';

import { CanvasScriptObject, CanvasShiftingStaticContext, CanvasSelectionContext, CanvasSettingsGroup, CanvasErrorFallback } from 'canvas';
import CanvasProductHelper from 'project-component-helpers/canvas-product-helper';
import Opencase from 'project-helpers/product-helpers/opencase';
import ProjectDataContext from 'contexts/project-data-context';
import Barblock from 'project-helpers/product-helpers/barblock';
import { handleProductPropertyChange } from 'properties-view-data/product-properties-view-helpers';

class CanvasProduct extends React.Component {
  state = {
    isTransforming: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.container || !this.props.product) return true;
    else {
      var shouldRerender = false;

      shouldRerender = _.find(_.uniq([..._.keys(nextProps), ..._.keys(this.props)]), key => {
        var valueChanged = false;

        if (this.props[key] !== nextProps[key]) {
          if (_.includes(['canvasData', 'selectionData'], key) && _.isEqual(this.props[key], nextProps[key])) {
            valueChanged = false;
          }
          else {
            valueChanged = true;
          }
        }

        return valueChanged;
      });

      return shouldRerender ? true : false;
    }
  }

  handleSelect = () => {
    const {product, selectionData, canvasData, isSelected} = this.props;
    const {setActiveEntities} = selectionData;
    const {isShifting} = canvasData;

    if (isShifting) {
      setActiveEntities({entities: [{resourceKey: 'product', id: product.id}], isMultiSelect: true});
    }
    else if (!isSelected) {
      setActiveEntities({entities: [{resourceKey: 'product', id: product.id}], isMultiSelect: false});
    }
  };

  handleDelete = () => {
    const {product, projectData, selectionData} = this.props;

    selectionData.onDeselect();

    Product.destroy({product, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});
  };

  handleSettingsChange = ({key, value}) => {
    var {product} = this.props;

    if (key === 'size') {
      var productType = Product.get('productType', {product});
      var breakingSizeKey = Product.getBreakingSizeKey({product});

      var constrainedBreakingDimension = Product.getSmallLargeValueFor({product, value});

      var path = `dimensions.${breakingSizeKey}`;
      var updatedValue = constrainedBreakingDimension;

      if (productType.useLongestSidePricing) {
        //if orientation is different than the breaking size key, change the key
        //if it's becoming small, might need to update both dims
        var constraints = Product.getConstraints({product, productType});

        if (value === 'small') {
          var oppositeBreakingSizeKey = breakingSizeKey === 'width' ? 'height' : 'width';
          var breakingSideCurrentlyLarge = product.dimensions[breakingSizeKey] > constrainedBreakingDimension;
          var oppositeSideCurrentlyLarge = product.dimensions[oppositeBreakingSizeKey] > constrainedBreakingDimension;

          if (oppositeSideCurrentlyLarge && !breakingSideCurrentlyLarge) {
            breakingSizeKey = oppositeBreakingSizeKey;
            path = `dimensions.${oppositeBreakingSizeKey}`;
          }
          else if (breakingSideCurrentlyLarge && oppositeSideCurrentlyLarge) {
            path = 'dimensions';
            updatedValue = {
              ...product.dimensions,
              width: constrainedBreakingDimension,
              height: constrainedBreakingDimension
            }

          }
        }
        else {
          if (product.dimensions[breakingSizeKey === 'width' ? 'height' : 'width'] > constraints[breakingSizeKey].max) {
            breakingSizeKey = breakingSizeKey === 'width' ? 'height' : 'width';
            path = `dimensions.${breakingSizeKey}`;
          }
        }
      }

      if (Product.getPricingSizeString({product}) !== value) {
        handleProductPropertyChange({activeEntity: product, activeEntityId: product.id, path, value: updatedValue, reduxActions: this.props});
      }
    }
    else if (_.includes(['customData.doorAction', 'customData.orientation'], key)) {
      handleProductPropertyChange({activeEntity: product, activeEntityId: product.id, path: key, value, reduxActions: this.props});
    }
  }

  getUpdatedPropsForTransformerProps = ({transformerProps, roundToMinPrecision = false}) => {
    const {product, viewKey, elevation, container, viewOffset, projectData, canvasData, isNonSpacial, nonSpacialContainerPosition, overrideSideKey} = this.props;
    const {companyKey} = projectData;

    //HINT renormalize
    if (viewKey === 'front') {
      const containerPosition = lib.object.sum(viewOffset, isNonSpacial ? nonSpacialContainerPosition : Elevation.getPosition2d({elevation, position3d: container.position}), {y: -transformerProps.size.height});
      const parentProduct = Product.get('parentProduct', {product});

      transformerProps.position = lib.object.difference(transformerProps.position, containerPosition, parentProduct?.position, Container.getDropzoneInset({container, viewKey}));

      if (roundToMinPrecision) transformerProps.position = _.mapValues(transformerProps.position, value => lib.round(value, {toNearest: K.minPrecision}));

      //HINT need to calculate gridPosition
      if (Product.getIsOpencaseComponent({product})) {
        const gridPosition = Opencase.gridPositionFor({product, position: transformerProps.position});

        if (!_.isEqual(product.customData.gridPosition, gridPosition)) {
          transformerProps.customData = {gridPosition};
        }
      }
      else if (Product.getIsBarblockComponent({product})) {
        const wrapInset = Barblock.getWrapInset({product});

        transformerProps.position = lib.object.difference(transformerProps.position, wrapInset);
      }
      else if (_.includes([1439, 1479], product.productId)) {
        transformerProps.position.y = Container.getKickHeight({container});
      }
    }

    transformerProps.size = _.mapKeys(transformerProps.size, (size, sizeKey) => (K.sideSizeMap[overrideSideKey || Product.getSideKey({product, elevation, viewKey})][sizeKey]));

    if (_.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels], product.productId)) {
      if (_.includes(K[companyKey].ids.horizontalHiddenPanels, product.productId)) {
        transformerProps.size = {
          ...product.dimensions,
          width: transformerProps.size.width,
          [viewKey === 'front' ? 'depth' : 'height']: transformerProps.size.height
        };
      }
      else if (_.includes(K[companyKey].ids.verticalHiddenPanels, product.productId)) {
        transformerProps.size = {
          ...product.dimensions,
          depth: transformerProps.size.width,
          [viewKey === 'front' ? 'height' : 'width']: transformerProps.size.height
        };
      }
    }

    const updatedProps = {
      customData: {...product.customData, ...transformerProps.customData},
      position: {...product.position, ..._.pick(transformerProps.position, ['x', 'y'])},
      dimensions: {...product.dimensions, ...transformerProps.size},
    };

    return updatedProps;
  };

  handleTransform = (transformerProps) => {
    if (!this.props.multipleEntitiesSelected) {
      if (!this.state.isTransforming) {
        this.setState({cachedProduct: this.props.product, isTransforming: true});
      }

      const {product} = this.props;

      const updatedProps = this.getUpdatedPropsForTransformerProps({transformerProps});

      this.props.updateProduct({id: product.id, props: {...updatedProps, eventType: 'transform'}, hitApi: false});
    }
  };

  handleTransformEnd = (transformerProps) => {
    if (!this.props.multipleEntitiesSelected) {
      var product = _.omit(this.props.product, ['eventType']);
      const {projectData} = this.props;
      const type = Product.getType({product});
      var cachedProduct;

      if (transformerProps) {
        cachedProduct = _.cloneDeep(product);

        let updatedProps = this.getUpdatedPropsForTransformerProps({transformerProps, roundToMinPrecision: true});

        product = {...product, ...updatedProps};
      }
      else {
        cachedProduct = this.state.cachedProduct;
      }

      Product.update({cachedProduct, product, props: product, reduxActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue, setIssuesData: this.props.setIssuesData});

      this.setState({isTransforming: false, cachedProduct: undefined});

      if (type === 'opencasePanel') {
        // Opencase.removeInvalidProducts({product, reduxActions: this.props});
      }

      if (projectData.lockedForProduction && product.lockedForProduction) {
        alert(`You just modified a product that is locked. That means your edit won't impact parts and managed children (IE appliance panels) in the project manager. If this is intended, great! If not, please undo your change and confirm that the project manager is correct.`);
      }
    }
  };

  render() {
    const {viewKey, product, elevation, room, viewOffset, realPosition, selectionData, multipleEntitiesSelected, canvasData, projectData, activeDetailLevel, activeFillMode, activeUserLense, renderForDrawings, preventEditing, containerIsSelected,
      childProducts, container, productType, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, parentProduct, isProjection, projectionY, isSelected, showPerspective, scaleX=1, scaleY=1, scaleOffset,
      isNonSpacial, overrideSideKey, nonSpacialContainerPosition, containerRealPosition, showCanvasSettings, showProductDetails, maskingPolygons, maskPosition
    } = this.props;

    if (!product || !container) return null;

    const type = Product.getType({product});
    var shouldShowProduct = !Product.getIsManaged({product}) || _.get(product, 'managedData.managedKey') === 'autofilledStorage';
    var shouldShowChildProducts = !isProjection && shouldShowProduct && (viewKey === 'front' || (viewKey !== 'front' && (!_.includes(['verticalBarblock', 'horizontalBarblock', 'rearFacingBarblock'], type) && !Product.getIsApplianceStackFrame({product}) && !Product.getIsAssembly({product}))));

    var dropzoneSize = Product.getDropzoneSize({product, viewKey, container, parentProduct});
    const isInvalid = Product.getIsInvalid({product, viewKey, isNonSpacial, room, issuesData: this.props.issuesData});
    const isTBDAppliance = Product.getIsTBDAppliance({product, viewKey, isNonSpacial});
    const isScalable = viewKey !== 'top' && Product.getIsScalable({product});
    const customDragBoundFunc = Product.getCustomDragBoundFunc({product, elevation, viewOffset, viewKey, nonSpacialContainerPosition, overrideSideKey});

    const isDisabled = isProjection || canvasData.isStatic || renderForDrawings || preventEditing;
    var fill;

    // if (viewKey === 'top' && containerIsSelected && multipleEntitiesSelected) {
    //   fill = 'transparent';
    // }
    // else {
    fill = Product.getFill({product, container, elevation, activeDetailLevel, activeFillMode});
    // }
    var hatchFillData = Product.getHatchFillData({product, elevation, viewKey, container, parentProduct, activeFillMode, includeAll: true, activeDetailLevel});
    const {hatchFill, hatchFills, shouldInvertStroke} = hatchFillData;

    let sideKey = 'top';
    let position, metaPropsPosition, size, snapToLines;
    let script = 'rect({})';
    let dropzoneInset = Product.getDropzoneInset({product, viewKey, elevation, isNonSpacial, nonSpacialContainerPosition, containerRealPosition});

    dropzoneInset = lib.object.sum({...dropzoneInset, x: dropzoneInset.x * scaleX}, viewOffset);
    // dropzoneInset = lib.object.sum(dropzoneInset, viewOffset);

    let constraints;

    if (!product.customData.hasNonStandardDimensions) {
      constraints = Product.getConstraints({product, productType});

      if (_.includes([...K[projectData.companyKey].ids.verticalHiddenPanels, ...K[projectData.companyKey].ids.horizontalHiddenPanels], product.productId)) {
        const swapDimKey = _.includes(K[projectData.companyKey].ids.verticalHiddenPanels, product.productId) ? 'width' : 'height';

        constraints = {
          ...constraints,
          [swapDimKey]: constraints.depth,
          depth: constraints[swapDimKey]
        };
      }
    }

    if (shouldShowProduct) {
      sideKey = containerRealPosition ? viewKey : (overrideSideKey || Product.getSideKey({product, viewKey, elevation, container, parentProduct}));
      var productPositionInView = Product.getPositionInView({product, viewKey, elevation, scaleX, isProjection, projectionY, isNonSpacial, nonSpacialContainerPosition, overrideSideKey, containerRealPosition});

      if (_.includes(['left', 'right', 'top'], sideKey) && (productType.categoryId === 72 || _.includes(['verticalBarblock', 'horizontalBarblock'], type))) shouldShowChildProducts = false;

      if (product.customData.hideInSection && !_.includes(['front', 'top'], sideKey)) shouldShowProduct = false;
      if (product.customData.hideInPlan && sideKey === 'top') shouldShowProduct = false;

      position = realPosition || lib.object.sum(productPositionInView, viewOffset, scaleOffset);

      if (containerRealPosition) {
        if (viewKey === 'front') {
          position = lib.object.sum(containerRealPosition, productPositionInView, {y: container.dimensions.height}, viewOffset, scaleOffset);
        }
      }

      script = Product.getScript({product, elevation, viewKey, activeDetailLevel, activeFillMode, sideKey});
      size = Product.getSize({product, viewKey, elevation, isNonSpacial, overrideSideKey, sideKey});

      if (showPerspective) {
        var containerDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});

        var topOrigin = (container.dimensions.height - dropzoneSize.height + containerDropzoneInset.y);
        var leftOrigin = containerDropzoneInset.x;

        var distanceFromTop = topOrigin - (product.position.y - size.height) - (container.dimensions.height + containerDropzoneInset.y);
        var distanceFromLeft = product.position.x;

        var yOffset = (-distanceFromTop + topOrigin) * (scaleY - 1);
        var xOffset = (distanceFromLeft + leftOrigin) * (scaleX - 1);

        position = lib.object.sum(position, {
          x: xOffset, y: yOffset
        });
      }

      if (realPosition) {
        if (type === 'barblockComponent') {
          const customOnMove = Product.getCustomOnMove({product});

          const containerDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});
          const parentProductPosition = parentProduct.position;
          const containerPosition = lib.object.sum(viewOffset, Elevation.getPosition2d({elevation, position3d: container.position}));

          metaPropsPosition = lib.object.difference(realPosition, containerDropzoneInset, containerPosition, parentProductPosition, {y: -size.height});

          if (customOnMove) metaPropsPosition = customOnMove(metaPropsPosition);
        }
      }

      snapToLines = isNonSpacial ? [] : _.map(Product.getSnapToLines({product, elevation, viewKey}), line => _.mapValues(line, value => lib.object.sum(value, viewOffset)));
    }

    var stroke = (isInvalid && !renderForDrawings) ? 'red' : 'black';
    if (isTBDAppliance && !renderForDrawings) stroke = 'purple';

    if (_.includes(['schematic'], activeDetailLevel)) stroke = '#999999'
    else if (_.includes(['rendering'], activeDetailLevel)) stroke = 'rgba(0, 0, 0, 0.3)'

    //HINT these are hidden panels that sometimes extend outside of the container allowing complete control for positioning
    if (_.includes([1439, 1479, 1480, 1481, 1521, 1619, 1452, 1453, 1454, 1455], product.productId)) {
      dropzoneSize = undefined;
      dropzoneInset = undefined;
    }

    var ProductComponent = (shouldShowProduct && <CanvasScriptObject
      {...{stroke, isSelected, multipleEntitiesSelected, constraints, maskingPolygons, maskPosition, dropzoneInset, dropzoneSize, script, position, size, scaleX, scaleY, isScalable, snapToLines, customDragBoundFunc, fill, hatchFill, hatchFills, shouldInvertStroke, renderForDrawings, activeDetailLevel}}
      locked={product.customData.isLocked}
      rotation={viewKey === 'top' ? container.rotation : 0}
      onSelect={this.handleSelect}
      onTransform={this.handleTransform}
      onTransformEnd={this.handleTransformEnd}
      onDelete={this.handleDelete}
      isDisabled={isDisabled || sideKey !== 'front' || _.includes(['schematic'], activeDetailLevel)}
      isRotatable={false}
      strokeWidth={renderForDrawings ? 0.25 : 0.5}
      hideText={activeDetailLevel === 'rendering'}
      preventInfinitePrecision={true}
      metaProps={
        {props: {...product}, ...CanvasProductHelper.getMetaProps({product: metaPropsPosition ? {...product, position: metaPropsPosition} : product, isNonSpacial, overrideSideKey, hatchFillData, isSelected, renderForDrawings, viewKey, elevation, room, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, isProjection, activeDetailLevel, activeFillMode})}
      }
      {..._.pick(this.props, ['viewKey'])}
    />);

    var ChildProductComponents = (shouldShowChildProducts && _.map(_.filter(_.orderBy(childProducts, [function(c) { return c.position.y; }], ['desc']), childProduct => !Product.getIsManaged({product: childProduct})), childProduct => (
      <ConnectedCanvasProductWithContext
        key={childProduct.id}
        id={childProduct.id}
        {...{viewKey, elevation, viewOffset, isDisabled, activeDetailLevel, activeFillMode, scaleX, scaleY, scaleOffset, renderForDrawings, preventEditing, showGrainFlow, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, isNonSpacial, nonSpacialContainerPosition, overrideSideKey}}
        {..._.pick(this.props, ['canvasDeps'])}
      />
    )));

    //HINT render appliance frames after their components so unit number is visible
    var renderProductFirst = true;//!Product.getIsApplianceStackFrame({product});

    shouldShowProduct = shouldShowProduct && (!!size.width && !!size.height);

    return (
      <>
        {ProductComponent}
        {ChildProductComponents}
        {(shouldShowProduct && Product.getIsApplianceStackFrame({product}) && sideKey === 'front') && (
          <CanvasScriptObject
            isDisabled={true}
            strokeWidth={renderForDrawings ? 0.25 : 0.5}
            metaProps={
              {props: {...product}, ...CanvasProductHelper.getMetaProps({product: metaPropsPosition ? {...product, position: metaPropsPosition} : product, hatchFillData, renderForDrawings, viewKey, elevation, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, isProjection, activeDetailLevel, activeFillMode})}
            }
            script={`
            var children = [];

            if (_.getProductionId && _.getProductionId().length > 0) {
              var labelOrigin = _.getLabelOrigin ? _.getLabelOrigin() : {x: 'left', y: 'bottom'};
              var labelLeft = _.getLabelLeft ? _.getLabelLeft() : 2;
              var productionIdLength = _.getProductionId().length;
              var hasShop = productionIdLength && _.getCustomData && (_.getCustomData().hasShopDrawing || _.getCustomData().hasNonStandardDimensions);
              var isRenderingDrawings = (_.getDrawingsMode && _.getDrawingsMode());

              children.push(text({
                text: _.getProductionId(),
                origin: labelOrigin,
                fontSize: 11/3,
                top: '100% - 1.25',
                fontWeight: 'bold',
                left: labelLeft,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                padding: 0.25,
                ...(hasShop ? {backgroundStroke: 'yellow', backgroundStrokeWidth: isRenderingDrawings ? 1 : 2} : {})
              }));
            }

            if (_.getProductOptionsLabel && _.getProductOptionsLabel().length > 0) {
              children.push(text({
                text: _.getProductOptionsLabel(),
                origin: {x: 'left', y: 'top'},
                fontSize: 8/3,
                top: '5',
                left: '1',
                width: _.dimensions.width - 2,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                padding: 0.25
              }));
            }

            group({}, children);
            `}
            {...{position, size, renderForDrawings, viewKey: 'front'}}
          />
        )}
        {(showCanvasSettings && shouldShowProduct && !renderForDrawings && (sideKey === 'front' || overrideSideKey === 'front')) && (
          <CanvasSettingsGroup
            resourceKey='product'
            resourceId={product.id}
            resource={product}
            position={position}
            size={size}
            onSettingsChange={({key, value}) => this.handleSettingsChange({key, value})}
          />
        )}
      </>
    );
  }
}

function CanvasProductWithContext(props) {
  const canvasShiftingStaticData = useContext(CanvasShiftingStaticContext);
  let selectionData = useContext(CanvasSelectionContext);
  const projectData = useContext(ProjectDataContext);

  var isSelected = props.product && _.size(selectionData) ? selectionData.getIsActiveEntity({resourceKey: 'product', id: props.product.id}) : false;
  var containerIsSelected = false//props.container && _.size(selectionData) ? selectionData.getIsActiveEntity({resourceKey: 'container', id: props.container.id}) : false;
  var multipleEntitiesSelected = _.size(selectionData) ? selectionData.activeEntities.length > 1 : false;

  selectionData = _.omit(selectionData, ['activeEntities', 'activeDimensionData', 'activeDatumData', 'activeProjectGraphicData']);

  return <CanvasProduct {...props} {...{isSelected, containerIsSelected, multipleEntitiesSelected, canvasData: canvasShiftingStaticData, selectionData, projectData}}/>;
}

const ConnectedCanvasProductWithContext = withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {product} = ownProps;
    var props = {};

    if (ownProps.id) {
      product = props.product = state.resources.products.byId[ownProps.id];
    }

    props = product ? {product, ...Product.get(['childProducts', 'container', 'productType', 'parentProduct'], {product})} : {};

    //TODO maybe filter down to just issues relevant to the product?
    props.issuesData = state.issuesData;

    return props;
  },
  mapDispatch: {
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ...resourceActions.elevations,
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','updateParts']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(issuesDataActions, ['setIssuesData'])
  }
})(CanvasProductWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasProduct'})
});

export default ConnectedCanvasProductWithContext;
