import _ from 'lodash';
import lib from 'lib';

export default function addRoomCountertopProductionDimensions({container, footprintLines, containerAlpha, dimensionSets, getIsTrapped, room}) {
  if (container.type === 'countertop' && !_.get(container, 'customData.isByOthers')) {
    _.forEach(['depth', 'width'], dimKey => {
      var candidateLines = _.filter(dimKey === 'depth' ? [footprintLines.left, footprintLines.right] : [footprintLines.front, footprintLines.back], line => !getIsTrapped({line, entityType: 'container', entityId: container.id}));

      var line = candidateLines[0] || (dimKey === 'depth' ? footprintLines.left : footprintLines.front);

      line = _.mapValues(line, point => lib.object.sum(point, room.plan.position));

      dimensionSets.push({
        type: 'standalone',
        id: `room-container-${container.id}-countertop-production-${dimKey}`,
        //TODO
        alpha: dimKey === 'depth' ? containerAlpha + Math.PI / 2 : containerAlpha,
        offset: 5,
        showLabelBorder: true,
        color: 'blue',
        targets: [
          {
            position: line.from,
            id: `room-container-${container.id}-countertop-production-${dimKey}-from`
          },
          {
            position: line.to,
            id: `room-container-${container.id}-countertop-production-${dimKey}-to`
          }
        ]
      });
    });
  }
};