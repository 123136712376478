import K from 'k';
import lib from 'lib';
import _ from 'lodash';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';

import apiKeyFor from 'helpers/api-key-for-helper';
import { pluralize } from 'inflection';

var ManagedDataHelper = {};

ManagedDataHelper.applyBatchUpdates = async ({updatesMap, state}) => {
  var mutateStateFor = ({resourceKey, updatesMap}) => {
    var relevantUpdatesMap = updatesMap[resourceKey];
    var pKey = pluralize(resourceKey);

    _.forEach(['tracks', 'updates', 'deletedIds'], (actionKey) => {
      var modifications = _.get(relevantUpdatesMap, actionKey, []);

      if (modifications.length > 0) {
        if (actionKey === 'tracks') {
          var modificationsById = _.keyBy(modifications, 'id');

          state[resourceKey].byId = {...state[resourceKey].byId, ...modificationsById};

          //TODO get indexedFieldKeys, safely push to the end of the list
          //need to get indexedFieldKeys in case this is the first thing on the list
          //haven't done it yet because there were no issues while testing and it's almost impossible we would need to
          //create a product or container in a situation where one doesn't exist
          _.forEach(state[resourceKey].byFieldKeyIndex, (prevStateByFieldIndexValue, fieldIndexKey) => {
            //HINT end result should look like {[scopeId]: {[productId]: {...product}, [productId]: ...}};
            var modificationsByFieldIndexKey = _.mapValues(_.groupBy(modificationsById, fieldIndexKey), modifiedGroup => {
              return _.keyBy(modifiedGroup, 'id');
            });

            _.forEach(modificationsByFieldIndexKey, (values, fieldKeyValue) => {
              state[resourceKey].byFieldKeyIndex[fieldIndexKey][fieldKeyValue] = {
                ...(state[resourceKey].byFieldKeyIndex[fieldIndexKey][fieldKeyValue] || {}),
                ...values
              };
            });
          });
        }
        else if (actionKey === 'updates') {
          _.forEach(modifications, modification => {
            var instance = state[resourceKey].byId[modification.where.id];
            //HINT handle cases where we're trying to update cachedManagedProductsData
            //after the container/product is already deleted

            if (instance) {
              state[resourceKey].byId[modification.where.id] = {...state[resourceKey].byId[modification.where.id], ...modification.props};

              _.forEach(state[resourceKey].byFieldKeyIndex, (prevStateByFieldIndexValue, fieldIndexKey) => {
                state[resourceKey].byFieldKeyIndex[fieldIndexKey][instance[fieldIndexKey]] = {
                  ...state[resourceKey].byFieldKeyIndex[fieldIndexKey][instance[fieldIndexKey]],
                  [instance.id]: {...instance, ...modification.props}
                };
              });
            }
          });
        }
        else if (actionKey === 'deletedIds') {
          _.forEach(modifications, deletedId => {
            var instance = state[resourceKey].byId[deletedId];

            _.forEach(state[resourceKey].byFieldKeyIndex, (prevStateByFieldIndexValue, fieldIndexKey) => {
              if (instance && _.get(state, `[${resourceKey}].byFieldKeyIndex[${fieldIndexKey}][${_.get(instance, fieldIndexKey)}]`)) {
                delete state[resourceKey].byFieldKeyIndex[fieldIndexKey][instance[fieldIndexKey]][deletedId];
              }
            });

            delete state[resourceKey].byId[deletedId];
          });
        }
      }
    });
  };

  if (updatesMap) {
    await lib.async.forEach(updatesMap, async (updateMap, resourceKey) => {
      if (_.some(updateMap, changes => changes && changes.length > 0)) {
        var pKey = pluralize(resourceKey);
        var apiPKey = apiKeyFor({key: pKey});
        var createdResources;

        let requestResources = {};

        if ((updatesMap[resourceKey].creations || []).length > 0) {
          requestResources.create = {
            [apiPKey]: _.map(updatesMap[resourceKey].creations, props => _.omit(props, ['id']))
          };
        }

        if ((updatesMap[resourceKey].updates || []).length > 0) {
          requestResources.update = {
            [apiPKey]: updatesMap[resourceKey].updates
          };
        }

        if ((updatesMap[resourceKey].deletedIds || []).length > 0) {
          requestResources.destroy = {
            [apiPKey]: [{where: {id: updatesMap[resourceKey].deletedIds}}]
          };
        }

        //HINT only care about awaiting if we're creating things
        if ((updatesMap[resourceKey].creations || []).length > 0) {
          let response = await lib.api.request({uri: 'resources', body: {resources: requestResources}});

          createdResources = _.get(response, `data.resources.create.${[apiPKey]}`) || [];

          updatesMap[resourceKey].creations = [];

          if (!updatesMap[resourceKey].tracks) updatesMap[resourceKey].tracks = [];
          updatesMap[resourceKey].tracks.push(...createdResources);
        }
        else {
          lib.api.request({uri: 'resources', body: {resources: requestResources}});
        }

        await mutateStateFor({resourceKey, updatesMap});
      }
    });
  }

  return updatesMap;
};

ManagedDataHelper.syncManagedCountertopContainers = async ({room, state, updatesMap}) => {
  // <update countertops
  var countertopUpdatesMap = Room.combineUpdatesMapWithCountertopUpdates({room, updatesMap});

  //request to update countertops
  countertopUpdatesMap = await ManagedDataHelper.applyBatchUpdates({updatesMap: countertopUpdatesMap, state});
  // update countertops/>

  updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, countertopUpdatesMap);

  return {updatesMap, state};
};

ManagedDataHelper.syncContainerManagedProducts = async ({room, state, updatesMap}) => {
  var containerManagedProductsUpdatesMap = {
    productOptions: {creations: [], updates: [], deletedIds: []},
    products: {creations: [], updates: [], deletedIds: []},
    containers: {creations: [], updates: [], deletedIds: []},
  };
  // <managed resources for containers

  var containers = _.filter(state.containers.byId, container => {
    //HINT keys are strings
    return _.includes(_.keys(state.scopes.byFieldKeyIndex.roomId[room.id]), `${container.scopeId}`);
  })

  _.forEach(containers, container => {
    let {managedUpdatesMap} = Container.updateManagedResources({container, actionKey: 'update', isBatched: true});

    containerManagedProductsUpdatesMap = UpdatesMapsHelpers.combineUpdatesMaps(containerManagedProductsUpdatesMap, managedUpdatesMap);
  });

  //request to update managed resources for containers
  containerManagedProductsUpdatesMap = await ManagedDataHelper.applyBatchUpdates({updatesMap: containerManagedProductsUpdatesMap, state});
  // managed resources for containers/>

  updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, containerManagedProductsUpdatesMap);

  return {updatesMap, state};
};

ManagedDataHelper.syncProductManagedProducts = async ({room, state, updatesMap, reduxActions}) => {
  var productManagedProductUpdates = {
    productOptions: {creations: [], updates: [], deletedIds: []},
    products: {creations: [], updates: [], deletedIds: []},
    containers: {creations: [], updates: [], deletedIds: []},
  };

  // <newly created managed products
  _.forEach(updatesMap.products, (modifications, modificationKey) => {
    var actionKey = {
      tracks: 'create',
      updates: 'update',
      deletedIds: 'destroy',
    }[modificationKey];

    //HINT deletes are handled in updateManagedProducts
    //HINT updates never occur because managed products are always deleted and recreated
    if (actionKey === 'create') {
      _.forEach(modifications, product => {
        var {managedUpdatesMap, productCacheUpdate} = Product.updateManagedResources({product, actionKey, isBatched: true, reduxActions});

        productManagedProductUpdates = UpdatesMapsHelpers.combineUpdatesMaps(productManagedProductUpdates, managedUpdatesMap);
      });
    }
  });

  var potentialLightingContainers = _.filter(state.containers.byId, container => {
    //HINT keys are strings
    return Container.getSupportsLighting({container}) && _.includes(_.keys(state.scopes.byFieldKeyIndex.roomId[room.id]), `${container.scopeId}`);
  })

  //HINT lighting is the only product option controlled by container settings
  //but generated on products, need to update those units managed resources here
  _.forEach(potentialLightingContainers, potentialLightingContainer => {
    //call update managed resources on potential lighting products
    var products = _.get(state.products, `byFieldKeyIndex.containerInstanceId[${potentialLightingContainer.id}]`);

    _.forEach(products, product => {
      if (!_.includes(updatesMap.products.deletedIds, product.id) && !_.includes(updatesMap.products.tracks, {id: product.id})) {
        var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'update', isBatched: true, reduxActions});

        productManagedProductUpdates = UpdatesMapsHelpers.combineUpdatesMaps(productManagedProductUpdates, managedUpdatesMap);

      }
    });
  });

  //request to updated managed resources for newly created products
  productManagedProductUpdates = await ManagedDataHelper.applyBatchUpdates({updatesMap: productManagedProductUpdates, state});
  // newly created managed products/>

  updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, productManagedProductUpdates);

  return {updatesMap, state};
};


export default ManagedDataHelper;