import React, { createRef } from 'react';
import { resourceActions, connect, issuesDataActions } from 'redux/index.js';
import TextInput from 'components/text-input';
import Popup from 'components/popup';
import lib from 'lib';
import K from 'k';
import _ from 'lodash';
import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';

import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';

import ConfiguratorGraphic from 'pages/configurator/components/configurator-graphic';
// import ConfiguratorSelectorPropertyField from 'pages/configurator/components/configurator-selector-property-field';
// import ConfiguratorOptionSelector from 'pages/configurator/components/configurator-option-selector';
import {ConnectedPropertyField} from 'components/property-field';

//Takes in a room and archetype id
//render relevant parameters and the room in elevation and plan
class RoomParameterApplicationPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      graphicContainerSize: {width: 0, height: 0},
    };

    this.graphicContainerRef = createRef();

    this.debouncedHandleWindowResize = _.debounce(this.handleWindowResize, 100);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.debouncedHandleWindowResize);

    this.handleWindowResize();
    // var media = await lib.api.get('media', {where: {archetypeId: _.map(this.props.archetypes, 'id'), isPublic: 1}, order: ['rank']});

    // var mediaByArchetypeId = {};

    // _.forEach(media, (medium) => {
    //   if (_.get(medium, 'associations.archetypes')) {
    //     _.forEach(medium.associations.archetypes, (value, key) => {
    //       var archetypeId = _.replace(key, 'id_', '');

    //       if (!mediaByArchetypeId[archetypeId]) mediaByArchetypeId[archetypeId] = [];

    //       mediaByArchetypeId[archetypeId].push(medium);
    //     });
    //   }
    // });
    this.updateSummaryParameterData();

    // this.setState({mediaByArchetypeId});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleWindowResize);
  }

  handleAddToFloorPlanClick = () => {
    this.props.onAddToFloorPlanClick();
  }

  roomArchetypeParameters() {

    var {room, archetypeId} = this.props;
    var roomParameters = _.flatMap(_.get(room, `parameters.${archetypeId}`, []));

    return roomParameters;
  }

  updateSummaryParameterData = () => {
    this.setState({summaryParameterData: this.getSummaryParameterData()});
  }

  get summaryParameterData() {
    return this.getSummaryParameterData();
  }

  getSummaryParameterData = () => {
    var {room, archetypeId} = this.props;
    var roomParameters = this.roomArchetypeParameters();
    var {containers, products} = Room.get(['containers', 'products'], {room: room});
    var {pulls, materialClasses} = this.props;

    var summaryParameterData = {totalParameterFieldCount: 0, remainingParameterFields: [], requiredParameterFields: []};

    _.forEach(roomParameters, roomParameter => {
      var {type, instances} = roomParameter;
      //HINT parameter required unless it is being applied to products/containers that are all deleted
      //IE island material selections when island is toggled off
      //OR pull material selection when pull type doesn't require a material selection
      var parameterIsApplicable = true;

      if (type === 'pull' || type === 'material') {
        var containerInstancesPresent = _.some(instances, instance => instance.resourceKey === 'container' && _.find(containers, {persistentId: instance.resourcePersistentId}));
        var productInstancesPresent = _.some(instances, instance => instance.resourceKey === 'product' && _.find(products, {persistentId: instance.resourcePersistentId}));

        if (!containerInstancesPresent && !productInstancesPresent) {
          parameterIsApplicable = false;
        }
      }

      if (parameterIsApplicable) {
        var isPullParameterWithPullMaterialRequired = false;

        if (parameterIsApplicable && type === 'pull') {
          var selectedPullTypeId = _.get(room, `selectedParameters.${archetypeId}.${roomParameter.id}.pullType`);// || _.get(pullTypeOptions, '[0].value');

          if (selectedPullTypeId) {
            var selectedPull = _.find(pulls, {id: selectedPullTypeId});
            var compatibleMaterials = _.get(materialClasses, `${selectedPull.materialClassId}.materials`);

            if (parameter.compatibleMaterialIds) { //HINT filter compatible materials to those set manually on the parameter
              compatibleMaterials = _.filter(compatibleMaterials, ({id}) => {
                return _.includes(parameter.compatibleMaterialIds, id);
              });
            }

            if (compatibleMaterials.length > 0) isPullParameterWithPullMaterialRequired = true;
          }
        }

        var parameterFieldCount = isPullParameterWithPullMaterialRequired ? 2 : 1;

        _.times(parameterFieldCount, n => {
          var currentValue = _.get(room, `selectedParameters.${archetypeId}.${roomParameter.id}${type === 'pull' ? (n === 0 ? '.pullType' : '.pullMaterial'): ''}`);

          if (!currentValue) {
            summaryParameterData.remainingParameterFields.push({...roomParameter, ...(type === 'pull' ? {path: n === 0 ? 'pullType' : 'pullMaterial'} : {})});
          }

          summaryParameterData.requiredParameterFields.push({...roomParameter, ...(type === 'pull' ? {path: n === 0 ? 'pullType' : 'pullMaterial'} : {})})
          summaryParameterData.totalParameterFieldCount++;
        });
      }
    });

    return summaryParameterData;
  }

  handlePropertyFieldChange = async ({id, value, path, activePropertyData, appliedOption}) => {
    var {room, archetypeId} = this.props

    await applyArchetypeParameterSelection({
      room,
      archetypeId,
      parameter: _.find(this.roomArchetypeParameters(), {id}),
      value, path,
      reduxActions: this.props
    });

    this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null, activePropertyRoomId: null});

    setTimeout(() => {
      this.updateSummaryParameterData();
    })
  };

  archetypeParameterGroups() {
    var archetypeParameterGroups = [];

    var {room, archetypeId} = this.props;
    var roomId = room.id;

    var roomSummaryParameterData = this.state.summaryParameterData || {requiredParameterFields: []};

    _.forEach(this.roomArchetypeParameters(), (parameter) => {
      var type = parameter.type === 'pull' ? 'pullType' : parameter.type;
      var parameterGroup = _.find(archetypeParameterGroups, {key: type});

      if (_.find(roomSummaryParameterData.requiredParameterFields, {id: parameter.id, type: parameter.type})) {
        var TypeToTitleMap = {
          material: 'Finishes',
          pullType: 'Pull Types',
          pullMaterial: 'Pull Finishes',
          conditionalResources: 'Appliances & Fixtures'
        };

        if (!parameterGroup) {
          archetypeParameterGroups.push({
            key: type,
            title: TypeToTitleMap[type],
            parameters: [{...parameter, type}]
          });

          if (parameter.type === 'pull' && _.find(roomSummaryParameterData.requiredParameterFields, {id: parameter.id, type: parameter.type, path: 'pullMaterial'})) {
            archetypeParameterGroups.push({
              key: 'pullMaterial',
              title: 'Pull Finishes',
              parameters: [{...parameter, type: 'pullMaterial'}]
            });
          }
        }
        else {
          parameterGroup.parameters.push({...parameter, type});

          if (parameter.type === 'pull' && _.find(roomSummaryParameterData.requiredParameterFields, {id: parameter.id, type: parameter.type, path: 'pullMaterial'})) {
            _.find(archetypeParameterGroups, {key: 'pullMaterial'}).parameters.push({...parameter, type: 'pullMaterial'});
          }
        }
      }
    });

    //HINT manually sort parameter groups by type
    archetypeParameterGroups = _.sortBy(archetypeParameterGroups, ({key}) => _.indexOf([
      'material', 'pullType', 'pullMaterial', 'conditionalResources'
    ], key));

    return archetypeParameterGroups;
  }

  onClose = () => {
    //TODO delete the room if closed, or begin adding to room if relevant button was clicked
    //Todo also figure out whether adding to existing room or not
    //delete room if temporary

    var {room} = this.props;

    if (this.props.isTemporaryArchetype) {
      Room.destroy({room, reduxActions: this.props});
    }

    this.props.onClose();
  }

  handleSelectorPropertyFieldOnClick = ({activePropertyKey, activePropertyData}) => {
    if (this.state.activePropertyKey === activePropertyKey) {
      this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null, activePropertyRoomId: null});
    }
    else {
      this.setState({activePropertyKey, activePropertyData});
    }
  };

  handleWindowResize = () => {
    if (this.graphicContainerRef) {
      var graphicContainerBoundingRect = this.graphicContainerRef.getBoundingClientRect();

      var containerSize = {
        width: graphicContainerBoundingRect.right - graphicContainerBoundingRect.left,
        height: graphicContainerBoundingRect.bottom - graphicContainerBoundingRect.top
      };

      if (containerSize.width && containerSize.height) this.setState({graphicContainerSize: containerSize});
    }
  };

  getGraphicContainerRef = (ref) => this.graphicContainerRef = ref;

  //HINT improving quality of graphic
  handleCanvasDataChange = (canvasData) => {
    if (canvasData && canvasData.layer) {
      try {
        canvasData.layer.canvas.pixelRatio = 2;
      }
      catch (e) {
        console.log(e, canvasData);
      }
    }
  }


  render() {
    var {room, archetypeId, project, isTemporaryArchetype} = this.props;
    var {activePropertyKey, activePropertyData} = this.state;

    var archetype = _.find(this.props.archetypes, {id: archetypeId});

    return (
      <>
        <Popup onClose={this.onClose} style={{padding: '0'}}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100vw - 3.5rem)",
            height: "calc(100vh - 3.5rem)",
            background: "white",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            overflow: "hidden",
            padding: "16px"
          }}>
            {/* Main Content */}
            <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
              {/* Sidebar */}
              <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', padding: "16px" }}>
                <div>
                  <div onClick={this.onClose} style={{cursor: 'pointer', fontSize: '11px', textTransform: 'uppercase', opacity: 0.8, fontWeight: '400', letterSpacing: '1.5px'}}>← Back</div>
                </div>
                <div style={{height: '100%', width: '100%', justifyContent: 'space-around', alignItems: 'center', display: 'flex'}}>
                  <div ref={this.getGraphicContainerRef} style={{width: '40%', height: '80%', border: "1px solid #ccc"}}>
                    {_.every(this.state.graphicContainerSize, size => size > 0) ? <ConfiguratorGraphic
                      type={'room'}
                      scaleConstraint={0.8}
                      onCanvasDataChange={this.handleCanvasDataChange}
                      {...{containerSize: this.state.graphicContainerSize, model: room, project, visibilityLayers: {wallsAndArchElements: true, dimensions: false}, detailLevel: 'rendering', fillMode: 'materialColors'}}
                    /> : <></>}
                  </div>
                  <div style={{width: '40%', height: '80%', border: "1px solid #ccc"}}>
                    {_.every(this.state.graphicContainerSize, size => size > 0) ? <ConfiguratorGraphic
                      type={'elevation'}
                      scaleConstraint={0.9}
                      onCanvasDataChange={this.handleCanvasDataChange}
                      {...{containerSize: this.state.graphicContainerSize, model: this.props.elevations[0], project, visibilityLayers: {wallsAndArchElements: true, dimensions: false}, activeDimensionsLayer: 'client', detailLevel: 'rendering', fillMode: 'materialColors'}}
                    /> : <></>}
                  </div>
                </div>
              </div>
              <div style={{ padding: "16px" }}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                  <div>
                    <div style={{fontSize: "16px", textTransform: 'uppercase', fontWeight: '350', letterSpacing: '1.6px', opacity: 0.8, textAlign: 'end'}}>{archetype.title}</div>
                  </div>
                  <div style={{flexGrow: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                    {/* <div style={{ height: '100%'}}> */}
                      {_.map(_.filter(this.archetypeParameterGroups(), {key: 'conditionalResources'}), ({key, title, parameters}) => {
                        return (
                          <div key={key} style={{display: 'flex', flexDirection: 'column', marginBottom: '1rem'}}>
                            {/* <div style={{fontWeight: 500, letterSpacing: '0.09em', textTransform: 'uppercase', marginBottom: '0.5rem', fontSize: '0.7rem'}}>{title}</div> */}
                            {_.map(parameters, (parameter, index) =>{
                              var {id, title, options=[]} = parameter;

                              if (_.get(room, `selectedParameters.${archetypeId}.${id}`) === undefined) {
                                options = [{value: undefined, title: 'select'}, ...options];
                              }

                              //WARNING WARNING WARNING
                              //If expanding this to show more than just conditional resource parameters will need code here to get the relevant options for pulls/materials
                              return (
                                // <ConfiguratorSelectorPropertyField
                                //   isEditable={true}
                                //   key={`configurator-selector-property-field-${index}-${parameter.id}`}
                                //   handlePropertyFieldChange={({id, value, path}) => this.handlePropertyFieldChange({roomId: room.id, id, value, path})}
                                //   handleClick={this.handleSelectorPropertyFieldOnClick}
                                //   {...{parameter, room, index, activePropertyKey: this.state.activePropertyKey, project, archetypeParameterGroup: {key, title}}}
                                // />
                                <ConnectedPropertyField
                                  key={id}
                                  {...{id, title, type: 'select', options,
                                  views: ['top', 'front'],
                                  path: `selectedParameters.${archetypeId}.${id}`, widerOptionsDropdown: true,
                                  project, parameter, noThumbnail: true, index, activeEntity: room, activeEntityId: room.id, activeEntityResourceKey: 'room',
                                  ...(options[0].value === undefined ? {inputStyle: {color: '#626FD0'}} : {}),}}
                                  onClick={() => {this.setState({activePropertyKey: this.state.activePropertyKey === `${id}` ? null : `${id}`});}}
                                  isExpanded={this.state.activePropertyKey === `${id}`}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    {/* </div> */}
                  </div>
                  <div onClick={this.handleAddToFloorPlanClick} style={{cursor: 'pointer', fontSize: '11px', textTransform: 'uppercase', opacity: 0.8, fontWeight: '400', letterSpacing: '1.5px', textAlign: 'end'}}>Next Step: Add to Floor Plan →</div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        {/* {!!activePropertyKey && !!activePropertyData && (
          <ConfiguratorOptionSelector
            key={`configurator-option-selector-${activePropertyKey}`}
            onBackClick={() => this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null, activePropertyRoomId: null})}
            onApplyClick={this.handlePropertyFieldChange}
            {...{activePropertyKey, activePropertyData, room, project}}
          />
        )} */}
      </>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    return {
      archetypes: _.map(state.resources.archetypes.byId),//_.filter(state.resources.archetypes.byId, {type: ownProps.category}),
      materialTypes: state.resources.materialTypes.byId,
      materials: state.resources.materials.byId,
      materialClasses: state.resources.materialClasses.byId,
      pulls: state.resources.pulls.byId,
      project: _.values(state.resources.projects.byId)[0],
      elevations: _.sortBy(_.filter(state.resources.elevations.byId, elevation => {return elevation.roomId === ownProps.room.id && (Elevation.getIsSection({elevation}) || Elevation.getSameAngleContainers({elevation}).length > 0)}), 'rank')
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.elevations, ['trackElevations', 'createElevation', 'createElevations', 'updateElevations', 'updateElevation', 'destroyElevation', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'updateContainer', 'updateContainers', 'createContainers', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'updateRooms', 'destroyRoom', 'destroyRooms', 'modifyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'createScopes', 'updateScope', 'updateScopes', 'destroyScopes', 'destroyScope']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'destroyArchElements']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'destroyWall', 'destroyWalls', 'updateWall', 'updateWalls', 'createWall', 'createWalls', 'modifyWalls']),
    ..._.pick(resourceActions.projectGraphics, ['createProjectGraphic', 'trackProjectGraphics', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'destroyVolumes']),
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','updateParts']),
    ..._.pick(resourceActions.floors, ['createFloor', 'trackFloors', 'updateFloor', 'updateFloors', 'destroyFloor']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
    ..._.pick(resourceActions.media, ['createMedium', 'updateMedium'])
  }
})(RoomParameterApplicationPopup);