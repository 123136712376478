import Floor from 'project-helpers/floor';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import ArchElement from 'project-helpers/arch-element';
import Volume from 'project-helpers/volume';
import Wall from 'project-helpers/wall';
import lib from 'lib';
import K from 'k';

var SnapDataHelper = {
  calculateSnapData: ({nearestEntity, sourceEntityResourceKey, sourceEntity, multipleEntitiesSelected, activeDimensionData, isAddingCustomDimension, activeEntities, viewMode, dependencies, activeEntity, viewOffset}) => {
    var candidateSnapAngles = [0, 90, 180, 270];

    let candidateSnapPositions = [];

    try {
      const {visibleEntitiesDataByResourceKey, floor, visibilityLayers} = dependencies;
      if (!sourceEntity && !multipleEntitiesSelected) sourceEntity = activeEntity;

      if (nearestEntity === undefined) {
        return {
          candidateSnapPositions,
          candidateSnapAngles,
        };
      }

      let offset = {x: 0, y: 0};

      function midPoint(from, to, index) {
        return {
          x: (from.x + to.x) / 2,
          y: (from.y + to.y) / 2,
        };
      }

      if (viewMode === 'lite') {
        if (!(sourceEntityResourceKey === 'product' && Product.getIsDaylightIslandProduct({product: sourceEntity}))) {
          const {offset: nearestEntityViewOffset, position: scopePosition, entity: scope} = nearestEntity;

          let {container, siblings, parentProduct, products} = dependencies;

          offset = lib.object.sum(nearestEntityViewOffset, viewOffset);

          if (sourceEntityResourceKey === 'container') {
            //HINT won't work until we can handle snapping to not the edge of the transformer
            // var containerPosition = Container.getScopeGridPosition({container: sourceEntity});
            // products = _.filter(products, product => (_.get(product, 'managedData.managedKey') === undefined || _.get(product, 'managedData.managedKey') === 'autofilledStorage') && product.position);

            // _.forEach(products, candidate => {
            //   const position = Product.getPositionInElevation({product: candidate, parentProduct, container: sourceEntity, isNonSpacial: true, nonSpacialContainerPosition: containerPosition, overrideSideKey: 'front'});
            //   const {width, height} = candidate.dimensions;

            //   let productSnapPositions = [
            //     {x: 0, y: 0}, //top-left
            //     {x: width, y: 0}, //top-right
            //     {x: width / 2, y: height / 2}, //center
            //     {x: width, y: height}, //bottom-right
            //     {x: 0, y: height} //bottom-left
            //   ];

            //   productSnapPositions = _.map(productSnapPositions, point => lib.object.sum(point, position));

            //   candidateSnapPositions.push(...productSnapPositions);
            // });
          }
          else if (sourceEntityResourceKey === 'product') {
            const product = sourceEntity;
            const isBarblockComponent = Product.getIsBarblockComponent({product});
            const isShelfbankComponent = Product.getIsShelfbankComponent({product});
            const isComponentProduct = Product.getIsComponentProduct({product});
            const isPeg = Product.getIsPeg({product});

            if ((!isComponentProduct || isPeg)) {
              if (container) {
                var containerPosition = Container.getScopeGridPosition({container});
                const dropzoneInset = Product.getDropzoneInset({product, viewKey: 'front', isNonSpacial: true, nonSpacialContainerPosition: containerPosition});
                const {width, height} = Product.getDropzoneSize({product, viewKey: 'front'});

                let parentSnapPositions = [
                  {x: 0, y: 0}, //top-left
                  {x: width, y: 0}, //top-right
                  {x: width / 2, y: height / 2}, //center
                  {x: width, y: height}, //bottom-right
                  {x: 0, y: height} //bottom-left
                ];

                parentSnapPositions = _.map(parentSnapPositions, point => lib.object.sum(point, dropzoneInset));

                candidateSnapPositions.push(...parentSnapPositions);

                siblings = _.filter(siblings, sibling => {
                  const siblingParentProduct = Product.get('parentProduct', {product: sibling});

                  return _.get(siblingParentProduct, 'id') === _.get(parentProduct, 'id') && sibling.id !== product.id && _.get(sibling, 'managedData.managedKey') === undefined && sibling.position;
                });

                _.forEach(siblings, candidate => {
                  try {
                    const position = Product.getPositionInElevation({product: candidate, parentProduct, container, isNonSpacial: true, nonSpacialContainerPosition: containerPosition, overrideSideKey: 'front'});
                    const {width, height} = candidate.dimensions;

                    let productSnapPositions = [
                      {x: 0, y: 0}, //top-left
                      {x: width, y: 0}, //top-right
                      {x: width / 2, y: height / 2}, //center
                      {x: width, y: height}, //bottom-right
                      {x: 0, y: height} //bottom-left
                    ];

                    if (Product.getIsPeg({product: candidate})) {
                      productSnapPositions = [{x: width / 2, y: height / 2}];
                    }

                    productSnapPositions = _.map(productSnapPositions, point => lib.object.sum(point, position));

                    candidateSnapPositions.push(...productSnapPositions);
                  }
                  catch (error) {
                    global.handleError({error, info: {componentStack: error.stack}, message: `product id ${candidate.id} snap`});
                  }
                });
              }
            }
            else if (isBarblockComponent || isShelfbankComponent) {
              var containerPosition = Container.getScopeGridPosition({container});
              const dropzoneInset = Product.getDropzoneInset({product, viewKey: 'front', isNonSpacial: true, nonSpacialContainerPosition: containerPosition});
              const {width, height} = Product.getDropzoneSize({product, viewKey: 'front'});

              let parentSnapPositions = [
                {x: 0, y: 0}, //top-left
                {x: width, y: 0}, //top-right
                {x: width / 2, y: height / 2}, //center
                {x: width, y: height}, //bottom-right
                {x: 0, y: height} //bottom-left
              ];

              parentSnapPositions = _.map(parentSnapPositions, point => lib.object.sum(point, dropzoneInset));

              candidateSnapPositions.push(...parentSnapPositions);

              // Products
              // let siblings = Product.get('childProducts', {product: parentProduct});

              siblings = _.filter(siblings, sibling => sibling.id !== product.id && _.get(sibling, 'managedData.managedKey') === undefined && sibling.position);

              _.forEach(siblings, candidate => {
                try {
                  const position = Product.getPositionInElevation({product: candidate, parentProduct, container, isNonSpacial: true, nonSpacialContainerPosition: containerPosition, overrideSideKey: 'front'});
                  const {width, height} = candidate.dimensions;

                  let productSnapPositions = [
                    {x: 0 - (isBarblockComponent ? 3 / 4 : 0), y: 0}, //top-left
                    {x: width + (isBarblockComponent ? 3 / 4 : 0), y: 0}, //top-right
                    {x: width / 2, y: height / 2}, //center
                    {x: width + (isBarblockComponent ? 3 / 4 : 0), y: height}, //bottom-right
                    {x: 0 - (isBarblockComponent ? 3 / 4 : 0), y: height} //bottom-left
                  ];

                  productSnapPositions = _.map(productSnapPositions, point => lib.object.sum(point, position));

                  candidateSnapPositions.push(...productSnapPositions);
                }
                catch (error) {
                  global.handleError({error, info: {componentStack: error.stack}, message: `product id ${candidate.id} snap`});
                }
              });
            }
          }
        }
      }
      else if (sourceEntityResourceKey === 'room') {
        const rooms = _.map(visibleEntitiesDataByResourceKey.rooms, ({entity, position, offset}) => ({room: entity, position, offset}));
        const room = sourceEntity;
        const candidateRooms = _.filter(_.values(rooms), ({room}) => room.id !== sourceEntity.id);

        // Add the Stencil points if DXF layer is enabled
        if (visibilityLayers.stencil) {
          if (floor.stencil !== undefined && floor.stencil !== null) {
            try {
              let stencilPoints = [];

              stencilPoints = _.map(floor.stencil.data.entities, (entity) => {
                if (!entity.type === 'polyline' && entity.points.length > 0) return null;
                let {points} = entity;

                points = _.map(points, point => lib.object.sum(point, viewOffset));

                if (entity.closed) points = [...points, points[0]];

                return points;
              });

              if (stencilPoints.length > 0) {
                candidateSnapPositions.push(..._.flatMap(stencilPoints));
              }
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: 'generating dxf snaps'});
            }
          }
        }

        _.forEach(candidateRooms, ({room, offset, position}) => {
          try {
            let roomSnapPositions = room.plan.points;
            let roomMidPoints = [];

            _.forEach(room.plan.points, (point, index) => {
              if (!point.isPseudoPoint) {
                roomMidPoints.push(midPoint(point, lib.array.next(room.plan.points, index)));
              }
            });

            roomSnapPositions = _.map([...roomSnapPositions, ...roomMidPoints], point => lib.object.sum(point, offset, position, viewOffset));

            candidateSnapPositions.push(...roomSnapPositions);
          }
          catch (error) {
            global.handleError({error, info: {componentStack: error.stack}, message: 'generating room snaps'});
          }
        });
      }
      else if (nearestEntity.resourceKey === 'room') {
        const {offset: nearestEntityViewOffset, position: roomPosition, entity: room} = nearestEntity;

        offset = lib.object.sum(nearestEntityViewOffset, roomPosition, viewOffset);

        if (dependencies.dimensions && activeDimensionData) {
          try {
            _.forEach(dependencies.dimensions, dimensionsData => {
              _.forEach(dimensionsData.targets, target => {
                candidateSnapPositions.push(lib.object.difference(target.offsetDotPositionOnOriginLine, roomPosition));
              });
            });
          }
          catch (error) {
            global.handleError({error, info: {componentStack: error.stack}, message: 'generating dimension snaps'});
          }
        }

        let {archElements, containers, wallSets, volumes} = dependencies;

        volumes = _.filter(_.values(volumes), volume => !_.find(activeEntities, {resourceKey: 'volume', id: volume.id}));

        const {xzDatums} = room;

        // Datums
        candidateSnapPositions.push(..._.flatMap(xzDatums, (xzDatum) => {
          if (xzDatum) {
            var {from, to} = xzDatum;

            return [from, to];
          }
          else {
            return [];
          }
        }));

        // Room
        if (visibilityLayers.wallsAndArchElements) {
          try {
            var roomMidPoints = [];

            _.forEach(room.plan.points, (point, index) => {
              if (!point.isPseudoPoint) {
                roomMidPoints.push(midPoint(point, lib.array.next(room.plan.points, index)));
              }
            });

            candidateSnapPositions.push(...[...room.plan.points, ...roomMidPoints]);
          }
          catch (error) {
            global.handleError({error, info: {componentStack: error.stack}, message: 'generating room snaps'});
          }
        }

        _.forEach(volumes, candidate => {
          try {
            let volumeSnapPositions = Volume.getFootprintInRoom({volume: candidate});

            volumeSnapPositions.push(lib.math.polygon.center({points: volumeSnapPositions}));

            candidateSnapPositions.push(...volumeSnapPositions);
          }
          catch (error) {
            global.handleError({error, info: {componentStack: error.stack}, message: `volume ${_.get(candidate, 'id')} snaps`});
          }
        });

        if (visibilityLayers.wallsAndArchElements) {
          _.forEach(wallSets, ({walls}) => {
            _.forEach(walls, wall => {
              try {
                var wallAlpha = Wall.getAlpha({wall});

                _.times(4, n => candidateSnapAngles.push(lib.trig.radiansToDegrees(lib.trig.normalize({radians: wallAlpha})) % 90 + (90 * n)));
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `wall ${_.get(wall, 'id')} rotation snaps`});
              }
            });
          });
        }

        _.forEach(_.filter(containers, container => container.type !== 'countertop' && !(sourceEntityResourceKey === 'container' && sourceEntity.id === container.id)), container => {
          candidateSnapAngles.push(container.rotation);
          candidateSnapAngles.push(container.rotation % 90);
          _.times(4, n => candidateSnapAngles.push(container.rotation % 90 + (90 * n)));
        });

        _.forEach(_.filter(volumes, volume => !(sourceEntityResourceKey === 'volume' && sourceEntity.id === volume.id)), volume => {
          candidateSnapAngles.push(volume.rotation);
          candidateSnapAngles.push(volume.rotation % 90);
          _.times(3, n => candidateSnapAngles.push(volume.rotation % 90 + (90 * (n + 1))));
        });

        candidateSnapAngles = _.uniq(candidateSnapAngles);

        if (sourceEntityResourceKey === 'container' || sourceEntityResourceKey === 'volume' || _.some(_.map(activeEntities, 'resourceKey'), resourceKey => resourceKey === 'container')) {
          // Sibling containers
          containers = _.filter(_.values(containers), candidate => !_.find(activeEntities, {resourceKey: 'container', id: candidate.id}) && candidate.type !== 'countertop' && candidate.position && !_.isEqual({}, candidate.position));

          _.forEach(containers, candidate => {
            try {
              let containerSnapPositions = Container.getFootprintInRoom({container: candidate, includeCenterPoints: true});

              candidateSnapPositions.push(...containerSnapPositions);
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `generating container ${_.get(candidate, 'id')} snaps`});
            }
          });

          // Arch Elements
          if (visibilityLayers.wallsAndArchElements) {
            _.forEach(_.values(archElements), archElement => {
              try {
                const candidateSize = ArchElement.getSize({archElement, viewKey: 'top'});
                const {wallSets, room} = dependencies;//ArchElement.get(['wall', 'wallSets', 'room'], {archElement});
                const wall = _.find(dependencies.walls, {id: archElement.wallId});
                const position = ArchElement.getPositionInRoom({archElement, room, wall, wallSets});
                const rotation = ArchElement.getRotationInRoom({archElement, room, wall, wallSets});
                let archElementSnapPositions;

                if (_.includes(['window', 'door'], archElement.type)) {
                  archElementSnapPositions = [
                    {x: 0, y: 0}, //top-left
                    {x: 0, y: candidateSize.height / 2}, //center
                    {x: 0, y: candidateSize.height} //bottom-left
                  ];
                }
                else {
                  archElementSnapPositions = [
                    {x: 0, y: 0}, //top-left
                    {x: candidateSize.width, y: 0}, //top-right
                    {x: candidateSize.width / 2, y: candidateSize.height / 2}, //center
                    {x: candidateSize.width, y: candidateSize.height}, //bottom-right
                    {x: 0, y: candidateSize.height} //bottom-left
                  ];
                }

                archElementSnapPositions = lib.waterfall(archElementSnapPositions, [
                  [_.map, point => lib.trig.rotate({point, byDegrees: rotation})],
                  [_.map, point => lib.object.sum(point, position)],
                ]);

                candidateSnapPositions.push(...archElementSnapPositions);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `generating arch element ${_.get(archElement, 'id')} snaps`});
              }
            });
          }
        }
        else if (sourceEntityResourceKey === 'archElement') {
          const archElement = sourceEntity;

          // Containers
          _.forEach(containers, candidate => {
            try {
              let containerSnapPositions = Container.getFootprintInRoom({container: candidate, includeCenterPoints: true});

              candidateSnapPositions.push(...containerSnapPositions);
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `generating container ${_.get(candidate, 'id')} snaps`});
            }
          });

          // Arch Elements
          archElements = _.filter(_.values(archElements), element => element.id !== archElement.id);

          _.forEach(archElements, archElementCandidate => {
            try {
              const candidateSize = ArchElement.getSize({archElement: archElementCandidate, viewKey: 'top'});
              const {wallSets, room} = dependencies;//ArchElement.get(['wall', 'wallSets', 'room'], {archElement});
              const wall = _.find(dependencies.walls, {id: archElement.wallId});
              const position = ArchElement.getPositionInRoom({archElement: archElementCandidate, room, wall, wallSets});
              const rotation = ArchElement.getRotationInRoom({archElement: archElementCandidate, room, wall, wallSets});
              let archElementSnapPositions;

              if (_.includes(['window', 'door'], archElementCandidate.type)) {
                archElementSnapPositions = [
                  {x: 0, y: 0}, //top-left
                  {x: 0, y: candidateSize.height / 2}, //center
                  {x: 0, y: candidateSize.height} //bottom-left
                ];
              }
              else {
                archElementSnapPositions = [
                  {x: 0, y: 0}, //top-left
                  {x: candidateSize.width, y: 0}, //top-right
                  {x: candidateSize.width / 2, y: candidateSize.height / 2}, //center
                  {x: candidateSize.width, y: candidateSize.height}, //bottom-right
                  {x: 0, y: candidateSize.height} //bottom-left
                ];
              }

              archElementSnapPositions = lib.waterfall(archElementSnapPositions, [
                [_.map, point => lib.trig.rotate({point, byDegrees: rotation})],
                [_.map, point => lib.object.sum(point, position)],
              ]);

              candidateSnapPositions.push(...archElementSnapPositions);
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `generating arch element ${_.get(archElementCandidate, 'id')} snaps`});
            }
          });
        }
        else if (sourceEntity === undefined || sourceEntityResourceKey === 'projectGraphic') {
          _.forEach(containers, candidate => {
            try {
              let containerSnapPositions = Container.getFootprintInRoom({container: candidate, includeCenterPoints: true});

              candidateSnapPositions.push(...containerSnapPositions);
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `generating arch element ${_.get(candidate, 'id')} snaps`});
            }
          });

          // Arch Elements
          if (visibilityLayers.wallsAndArchElements) {
            _.forEach(_.values(archElements), archElement => {
              try {
                const candidateSize = ArchElement.getSize({archElement, viewKey: 'top'});
                const {wall, wallSets, room} = ArchElement.get(['wall', 'wallSets', 'room'], {archElement});
                const position = ArchElement.getPositionInRoom({archElement, room, wall, wallSets});
                const rotation = ArchElement.getRotationInRoom({archElement, room, wall, wallSets});
                let archElementSnapPositions;

                if (_.includes(['window', 'door'], archElement.type)) {
                  archElementSnapPositions = [
                    {x: 0, y: 0}, //top-left
                    {x: 0, y: candidateSize.height / 2}, //center
                    {x: 0, y: candidateSize.height} //bottom-left
                  ];
                }
                else {
                  archElementSnapPositions = [
                    {x: 0, y: 0}, //top-left
                    {x: candidateSize.width, y: 0}, //top-right
                    {x: candidateSize.width / 2, y: candidateSize.height / 2}, //center
                    {x: candidateSize.width, y: candidateSize.height}, //bottom-right
                    {x: 0, y: candidateSize.height} //bottom-left
                  ];
                }

                archElementSnapPositions = lib.waterfall(archElementSnapPositions, [
                  [_.map, point => lib.trig.rotate({point, byDegrees: rotation})],
                  [_.map, point => lib.object.sum(point, position)],
                ]);

                candidateSnapPositions.push(...archElementSnapPositions);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `generating arch element ${_.get(archElement, 'id')} snaps`});
              }
            });
          }
        }
      }
      //HINT elevation
      else if (nearestEntity.resourceKey === 'elevation') {
        const {offset: nearestEntityViewOffset, position: elevationPosition, entity: elevation} = nearestEntity;

        offset = lib.object.sum(nearestEntityViewOffset, elevationPosition, viewOffset);

        if (dependencies.dimensions && activeDimensionData) {
          try {
            _.forEach(dependencies.dimensions, dimensionsData => {
              _.forEach(dimensionsData.targets, target => {
                candidateSnapPositions.push(target.offsetDotPositionOnOriginLine);
              });
            });
          }
          catch (error) {
            global.handleError({error, info: {componentStack: error.stack}, message: 'generating dimension snaps'});
          }
        }

        if (!(sourceEntityResourceKey === 'product' && Product.getIsDaylightIslandProduct({product: sourceEntity}))) {
          let {archElements, containers, walls, datums, xzDatums, volumes} = dependencies;//Elevation.get(['archElements', 'containers', 'walls', 'datums', 'xzDatums', 'volumes'], {elevation});

          volumes = _.filter(_.values(volumes), volume => !(sourceEntityResourceKey === 'volume' && volume.id === sourceEntity.id));

          _.forEach(volumes, volume => {
            try {
              var position = Volume.getPositionInElevation({volume, elevation});
              var points = Volume.getPoints({volume, elevation, viewKey: 'front', sideKey: Volume.getSideKey({volume, elevation, viewKey: 'front'})});

              points = _.map(points, point => {
                return {x: point.x, y: -point.y + volume.dimensions.height};
              });

              candidateSnapPositions.push(
                ..._.map(points, point => lib.object.sum(point, position))
              );
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `generating volume ${_.get(volume, 'id')} snaps`});
            }
          });

          // Datums
          var datumYValues = _.map(_.split(datums, ','), datum => {
            try {
              return parseFloat(eval(datum));
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: 'generating datum y snaps, please confirm all datums are valid'});
            }
          });

          datumYValues = _.filter(datumYValues, datumYValue => datumYValue && datumYValue > 0);

          if (datumYValues.length > 0) {
            let margin = 35, x = 0, width = 0, height = 0;

            if (Elevation.getIsSection({elevation})) margin = 13;

            x = -margin;
            width = Elevation.getWidth({elevation}) + margin * 2;
            height = Elevation.getHeight({elevation}) + margin * 2;

            candidateSnapPositions.push(..._.flatMap(datumYValues, yValue => [{x, y: -yValue}, {x: x + width, y: -yValue}]));
          }

          // xzDatums
          _.forEach(xzDatums, (xzDatum) => {
            try {
              var {from, to} = xzDatum;
              let margin = 50, width = 0, height = 0;

              const intersection = lib.math.intersectionPoint({l1: {from, to}, l2: elevation.lineInRoom});
              const x = lib.trig.distance({fromPoint: elevation.lineInRoom.from, toPoint: intersection});

              candidateSnapPositions.push({x, y: margin}, {x, y: -height + margin});
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: 'generating datum xz snaps, please confirm all datums are valid'});
            }
          });

          if (visibilityLayers.wallsAndArchElements) {
            _.forEach(_.values(walls), wall => {
              try {
                const outlinePoints = Wall.getOutlinePoints({elevation, wall});
                const midPoints = _.map(outlinePoints, (point, index) => midPoint(point, lib.array.next(outlinePoints, index)));

                candidateSnapPositions.push(...[...outlinePoints, ...midPoints]);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `generating ${_.get(wall, 'id')} snaps`});
              }
            });
          }

          // Arch Elements
          if (visibilityLayers.wallsAndArchElements) {
            if (sourceEntityResourceKey === 'archElement') archElements = _.filter(_.values(archElements), element => element.id !== sourceEntity.id);

            _.forEach(_.values(archElements), archElement => {
              try {
                let archElementSnapPositions = ArchElement.getWallprintInElevation({archElement, elevation});

                archElementSnapPositions = _.map(archElementSnapPositions, point => ({x: point.x, y: -point.y}));
                archElementSnapPositions.push(lib.math.polygon.center({points: archElementSnapPositions}));

                candidateSnapPositions.push(...archElementSnapPositions);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `generating arch element ${_.get(archElement, 'id')} snaps`});
              }
            });
          }

          if (sourceEntityResourceKey === 'container') {
            // Sibling containers
            const siblings = _.filter(_.values(containers), candidate => candidate.id !== sourceEntity.id && candidate.type !== 'countertop' && candidate.position && !_.isEqual({}, candidate.position));

            _.forEach(siblings, candidate => {
              try {
                var elevationTheta = Container.getElevationTheta({container: candidate, elevation});
                const sideKey = Container.getSideKey({container: candidate, viewKey: 'front', elevation});
                const sideKeyOffset = {
                  front: 0,
                  left: candidate.type === 'countertop' ? 0 : -lib.trig.rotate({point: {x: candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).x,
                  right: lib.trig.rotate({point: {x: candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).y,
                  back: lib.trig.rotate({point: {x: candidate.type === 'countertop' ? candidate.dimensions.width : candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).x
                }[sideKey];

                const position = lib.object.sum(Elevation.getPosition2d({elevation, position3d: candidate.position}), {y: -candidate.dimensions.height, x: sideKeyOffset});

                const width = candidate.dimensions[K.sideSizeMap[sideKey].width];
                const height = candidate.dimensions[K.sideSizeMap[sideKey].height];

                let containerSnapPositions = [
                  {x: 0, y: 0}, //top-left
                  {x: width, y: 0}, //top-right
                  {x: width / 2, y: height / 2}, //center
                  {x: width, y: height}, //bottom-right
                  {x: 0, y: height} //bottom-left
                ];

                containerSnapPositions = _.map(containerSnapPositions, point => lib.object.sum(point, position));

                candidateSnapPositions.push(...containerSnapPositions);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `generating container ${_.get(candidate, 'id')} snaps`});
              }
            });
          }
          else if (sourceEntityResourceKey === 'product') {
            const product = sourceEntity;
            const isBarblockComponent = Product.getIsBarblockComponent({product});
            const isShelfbankComponent = Product.getIsShelfbankComponent({product});
            const isComponentProduct = Product.getIsComponentProduct({product});
            const isPeg = Product.getIsPeg({product});

            if ((!isComponentProduct || isPeg)) {
              // Containers
              const {container} = dependencies;//_.find(_.values(containers), candidate => candidate.id === product.containerInstanceId);

              if (container) {
                // containers = _.filter(_.values(containers), candidate => candidate.id !== product.containerInstanceId);

                // _.forEach(containers, candidate => {
                //   var elevationTheta = Container.getElevationTheta({container: candidate, elevation});
                //   const sideKey = Container.getSideKey({container: candidate, viewKey: viewMode, elevation});
                //   const sideKeyOffset = {
                //     front: 0,
                //     left: candidate.type === 'countertop' ? 0 : -lib.trig.rotate({point: {x: candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).x,
                //     right: lib.trig.rotate({point: {x: candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).y,
                //     back: lib.trig.rotate({point: {x: candidate.type === 'countertop' ? candidate.dimensions.width : candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).x
                //   }[sideKey];

                //   const position = lib.object.sum(viewOffset, Elevation.getPosition2d({elevation, position3d: candidate.position}), {y: -candidate.dimensions.height, x: sideKeyOffset});
                //   const {width, height} = candidate.dimensions;

                //   let containerSnapPositions = [
                //     {x: 0, y: 0}, //top-left
                //     {x: width, y: 0}, //top-right
                //     {x: width / 2, y: height / 2}, //center
                //     {x: width, y: height}, //bottom-right
                //     {x: 0, y: height} //bottom-left
                //   ];

                //   containerSnapPositions = _.map(containerSnapPositions, point => lib.object.sum(point, position));

                //   candidateSnapPositions.push(...containerSnapPositions);
                // });

                // Parent container
                try {
                  const dropzoneInset = Product.getDropzoneInset({product, viewKey: 'front', elevation});
                  const {width, height} = Product.getDropzoneSize({product, viewKey: 'front'});

                  let parentSnapPositions = [
                    {x: 0, y: 0}, //top-left
                    {x: width, y: 0}, //top-right
                    {x: width / 2, y: height / 2}, //center
                    {x: width, y: height}, //bottom-right
                    {x: 0, y: height} //bottom-left
                  ];

                  parentSnapPositions = _.map(parentSnapPositions, point => lib.object.sum(point, dropzoneInset));

                  candidateSnapPositions.push(...parentSnapPositions);
                }
                catch (error) {
                  global.handleError({error, info: {componentStack: error.stack}, message: 'generating dropzone snaps'});
                }

                // Products
                let {siblings, parentProduct} = dependencies;//Elevation.get('products', {elevation});

                siblings = _.filter(siblings, sibling => {
                  const siblingParentProduct = Product.get('parentProduct', {product: sibling});

                  return _.get(siblingParentProduct, 'id') === _.get(parentProduct, 'id') && sibling.id !== product.id && _.get(sibling, 'managedData.managedKey') === undefined && sibling.position;
                });

                _.forEach(siblings, candidate => {
                  try {
                    const position = Product.getPositionInElevation({product: candidate, parentProduct, container, elevation});
                    const {width, height} = candidate.dimensions;

                    let productSnapPositions = [
                      {x: 0, y: 0}, //top-left
                      {x: width, y: 0}, //top-right
                      {x: width / 2, y: height / 2}, //center
                      {x: width, y: height}, //bottom-right
                      {x: 0, y: height} //bottom-left
                    ];

                    if (Product.getIsPeg({product: candidate})) {
                      productSnapPositions = [{x: width / 2, y: height / 2}];
                    }

                    productSnapPositions = _.map(productSnapPositions, point => lib.object.sum(point, position));

                    candidateSnapPositions.push(...productSnapPositions);
                  }
                  catch (error) {
                    global.handleError({error, info: {componentStack: error.stack}, message: `generating sibling product ${_.get(candidate, 'id')} snaps`});
                  }
                });
              }
            }
            else if (isBarblockComponent || isShelfbankComponent) {
              const {container, parentProduct} = dependencies;//Product.get(['container', 'parentProduct'], {product});
              let {siblings} = dependencies;

              try {
                // Parent container
                const dropzoneInset = Product.getDropzoneInset({product, viewKey: 'front', elevation});
                const {width, height} = Product.getDropzoneSize({product, viewKey: 'front'});

                let parentSnapPositions = [
                  {x: 0, y: 0}, //top-left
                  {x: width, y: 0}, //top-right
                  {x: width / 2, y: height / 2}, //center
                  {x: width, y: height}, //bottom-right
                  {x: 0, y: height} //bottom-left
                ];

                parentSnapPositions = _.map(parentSnapPositions, point => lib.object.sum(point, dropzoneInset));

                candidateSnapPositions.push(...parentSnapPositions);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: 'generating dropzone snaps'});
              }

              // Products
              // let siblings = Product.get('childProducts', {product: parentProduct});

              siblings = _.filter(siblings, sibling => sibling.id !== product.id && _.get(sibling, 'managedData.managedKey') === undefined && sibling.position);

              _.forEach(siblings, candidate => {
                try {
                  const position = Product.getPositionInElevation({product: candidate, parentProduct, container, elevation});
                  const {width, height} = candidate.dimensions;

                  let productSnapPositions = [
                    {x: 0 - (isBarblockComponent ? 3 / 4 : 0), y: 0}, //top-left
                    {x: width + (isBarblockComponent ? 3 / 4 : 0), y: 0}, //top-right
                    {x: width / 2, y: height / 2}, //center
                    {x: width + (isBarblockComponent ? 3 / 4 : 0), y: height}, //bottom-right
                    {x: 0 - (isBarblockComponent ? 3 / 4 : 0), y: height} //bottom-left
                  ];

                  productSnapPositions = _.map(productSnapPositions, point => lib.object.sum(point, position));

                  candidateSnapPositions.push(...productSnapPositions);
                }
                catch (error) {
                  global.handleError({error, info: {componentStack: error.stack}, message: `generating sibling product ${_.get(candidate, 'id')} snaps`});
                }
              });
            }
          }
          else if (sourceEntity === undefined || sourceEntityResourceKey === 'projectGraphic') {
            _.forEach(_.values(containers), candidate => {
              try {
                let parent = candidate;

                var elevationTheta = Container.getElevationTheta({container: candidate, elevation});
                const sideKey = Container.getSideKey({container: candidate, viewKey: 'front', elevation});
                const sideKeyOffset = {
                  front: 0,
                  left: candidate.type === 'countertop' ? 0 : -lib.trig.rotate({point: {x: candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).x,
                  right: lib.trig.rotate({point: {x: candidate.dimensions.depth, y: 0}, byDegrees: elevationTheta}).y,
                  back: lib.trig.rotate({point: {x: candidate.dimensions.width, y: 0}, byDegrees: elevationTheta}).x
                }[sideKey];

                const width = candidate.dimensions[K.sideSizeMap[sideKey].width];
                const height = candidate.dimensions[K.sideSizeMap[sideKey].height];

                let containerSnapPositions = [
                  {x: 0, y: 0}, //top-left
                  {x: width, y: 0}, //top-right
                  {x: width / 2, y: height / 2}, //center
                  {x: width, y: height}, //bottom-right
                  {x: 0, y: height} //bottom-left
                ];

                const candidatePosition = lib.object.sum(nearestEntityViewOffset, Elevation.getPosition2d({elevation, position3d: candidate.position}), {y: -candidate.dimensions.height, x: sideKeyOffset});

                candidateSnapPositions.push(..._.map(containerSnapPositions, point => lib.object.sum(point, candidatePosition)));

                const unmanagedProductInstances = _.filter(dependencies.products, {containerInstanceId: candidate.id});

                _.forEach(unmanagedProductInstances, candidate => {
                  try {
                    const position = lib.object.sum(Product.getPositionInElevation({product: candidate, container: parent, elevation}));
                    const {width, height} = candidate.dimensions;

                    let productSnapPositions = [
                      {x: 0, y: 0}, //top-left
                      {x: width, y: 0}, //top-right
                      {x: width / 2, y: height / 2}, //center
                      {x: width, y: height}, //bottom-right
                      {x: 0, y: height} //bottom-left
                    ];

                    productSnapPositions = _.map(productSnapPositions, point => lib.object.sum(point, position));

                    candidateSnapPositions.push(...productSnapPositions);
                  }
                  catch (error) {
                    global.handleError({error, info: {componentStack: error.stack}, message: `generating product ${_.get(candidate, 'id')} snaps`});
                  }
                });
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `generating container ${_.get(candidate, 'id')} snaps`});
              }
            });
          }
        }
      }

      //HINT adds parent offset to all snap positions
      candidateSnapPositions = _.map(candidateSnapPositions, point => lib.object.sum(point, offset));
    }
    catch (error) {
      global.handleError({error, info: {componentStack: error.stack}, message: 'snapping'});
    }

    return {
      candidateSnapPositions,
      candidateSnapAngles,
    };
  },
  calculateSourceSnapData: ({nearestEntity, activeEntities, sourceEntityResourceKey, sourceEntity, viewMode, viewOffset, getMultiSelectTransformerProps}) => {
    const sourceSnapPositions = [];
    var debugModeSourceSnapPositions = [];
    let size = {};

    try {
      if (viewMode === 'lite') {
        if (sourceEntityResourceKey === 'product') {
          const product = sourceEntity;
          const isBarblockComponent = Product.getIsBarblockComponent({product});
          const isShelfbankComponent = Product.getIsShelfbankComponent({product});
          const isComponentProduct = Product.getIsComponentProduct({product});
          const isPeg = Product.getIsPeg({product});

          if (isPeg) {
            sourceSnapPositions.push({x: size.width / 2, y: size.height / 2}); //center
          }
          if (!isComponentProduct) {
            size = {width: product.dimensions.width, height: product.dimensions.height};
          }
          else if (isBarblockComponent || isShelfbankComponent) {
            size = {width: product.dimensions.width, height: product.dimensions.height};
          }
        }
        else if (sourceEntityResourceKey === 'container') {
          const sideKey = 'front';

          var sizeKey = [K.sideSizeMap[sideKey].width, K.sideSizeMap[sideKey].height];
          size = {width: sourceEntity.dimensions[sizeKey[0]], height: sourceEntity.dimensions[sizeKey[1]]};

          //TODO make it work for containers
          //hard to get it to snap correctly to not the edge of the transformer
          // var dropzoneInset = Container.getDropzoneInset({container: sourceEntity, viewKey: 'front'});
          // var dropzoneSize = Container.getDropzoneSize({container: sourceEntity, viewKey: 'front'});

          // var dropzoneSnapPositions = [
          //   {x: 0, y: 0}, //top-left
          //   {x: dropzoneSize.width, y: 0}, //top-right
          //   {x: dropzoneSize.width / 2, y: dropzoneSize.height / 2}, //center
          //   {x: dropzoneSize.width, y: dropzoneSize.height}, //bottom-right
          //   {x: 0, y: dropzoneSize.height} //bottom-left
          // ];

          // sourceSnapPositions.push(
          //   _.map(dropzoneSnapPositions, point => lib.object.sum(point, dropzoneInset))
          // );
        }
      }
      else if (nearestEntity === undefined) {
        return {
          sourceSnapPositions,
        };
      }

      if (sourceEntityResourceKey === 'room') {
        const room = sourceEntity;

        if (_.get(room, 'plan.closed')) {
          const {width, height} = Room.getSize({room});

          size = {width, height};
        }
      }
      else if (sourceEntityResourceKey === 'projectGraphic') {
        if (_.includes(['circle', 'rectangle'], sourceEntity.type)) {
          size = sourceEntity.data.size;

          if (sourceEntity.type === 'circle') {
            size.width = sourceEntity.data.size.radius * 2;
            size.height = sourceEntity.data.size.radius * 2;
          }
        }
      }
      else if (activeEntities.length > 1) {
        size = getMultiSelectTransformerProps().shapeProps.size;
      }
      else if (nearestEntity.resourceKey === 'room') {
        const {offset: nearestEntityViewOffset, position: roomPosition} = nearestEntity;
        const offset = lib.object.sum(nearestEntityViewOffset, roomPosition, viewOffset);

        var sizeKey = ['width', 'depth'];

        if (sourceEntityResourceKey === 'container') {
          size = {width: sourceEntity.dimensions[sizeKey[0]], height: sourceEntity.dimensions[sizeKey[1]]};

          debugModeSourceSnapPositions = _.map([
            {x: 0, y: 0}, //top-left
            {x: size.width, y: 0}, //top-right
            {x: size.width / 2, y: size.height / 2}, //center
            {x: size.width, y: size.height}, //bottom-right
            {x: 0, y: size.height}, //bottom-left

          ], (point, index) => lib.trig.rotate({point: lib.object.sum(point, offset, {x: sourceEntity.position.x, y: sourceEntity.position.z}), byDegrees: sourceEntity.rotation, aroundOrigin: lib.object.sum(offset, {x: sourceEntity.position.x, y: sourceEntity.position.z})}));
        }
        if (sourceEntityResourceKey === 'volume') {
          size = {width: sourceEntity.dimensions[sizeKey[0]], height: sourceEntity.dimensions[sizeKey[1]]};
        }
        else if (sourceEntityResourceKey === 'archElement') {
          const archElement = sourceEntity;

          const {width, height} = ArchElement.getSize({archElement, viewKey: 'top'});

          size = {width, height};

            // const {wall, wallSets, room} = ArchElement.get(['wall', 'wallSets', 'room'], {archElement});
            // const position = ArchElement.getPositionInRoom({archElement, room, wall, wallSets});
            // const rotation = ArchElement.getRotationInRoom({archElement, room, wall, wallSets});
            // let archElementSnapPositions;

            // if (_.includes(['window', 'door'], archElement.type)) {
            //   archElementSnapPositions = [
            //     {x: 0, y: 0}, //top-left
            //     {x: 0, y: size.height / 2}, //center
            //     {x: 0, y: size.height} //bottom-left
            //   ];
            // }
            // else {
            //   archElementSnapPositions = [
            //     {x: 0, y: 0}, //top-left
            //     {x: size.width, y: 0}, //top-right
            //     {x: size.width / 2, y: size.height / 2}, //center
            //     {x: size.width, y: size.height}, //bottom-right
            //     {x: 0, y: size.height} //bottom-left
            //   ];
            // }

            // debugModeSourceSnapPositions = lib.waterfall(archElementSnapPositions, [
            //   [_.map, point => lib.trig.rotate({point, byDegrees: rotation})],
            //   [_.map, point => lib.object.sum(point, position, offset)],
            // ]);
        }
      }
      //HINT elevation
      else if (nearestEntity.resourceKey === 'elevation') {
        if (!(sourceEntityResourceKey === 'product' && Product.getIsDaylightIslandProduct({product: sourceEntity}))) {
          var sizeKey = ['width', 'height'];

          if (sourceEntityResourceKey === 'container') {
            const elevation = nearestEntity.entity;
            const {offset: nearestEntityViewOffset, position: elevationPosition} = nearestEntity;
            const offset = lib.object.sum(nearestEntityViewOffset, elevationPosition, viewOffset);
            const sideKey = Container.getSideKey({container: sourceEntity, viewKey: 'front', elevation});

            sizeKey = [K.sideSizeMap[sideKey].width, K.sideSizeMap[sideKey].height];
            size = {width: sourceEntity.dimensions[sizeKey[0]], height: sourceEntity.dimensions[sizeKey[1]]};

            var elevationTheta = Container.getElevationTheta({container: sourceEntity, elevation});
            const sideKeyOffset = {
              front: 0,
              left: sourceEntity.type === 'countertop' ? 0 : -lib.trig.rotate({point: {x: sourceEntity.dimensions.depth, y: 0}, byDegrees: elevationTheta}).x,
              right: lib.trig.rotate({point: {x: sourceEntity.dimensions.depth, y: 0}, byDegrees: elevationTheta}).y,
              back: lib.trig.rotate({point: {x: sourceEntity.dimensions.width, y: 0}, byDegrees: elevationTheta}).x
            }[sideKey];

            const width = sourceEntity.dimensions[K.sideSizeMap[sideKey].width];
            const height = sourceEntity.dimensions[K.sideSizeMap[sideKey].height];

            let containerSnapPositions = [
              {x: 0, y: 0}, //top-left
              {x: width, y: 0}, //top-right
              {x: width / 2, y: height / 2}, //center
              {x: width, y: height}, //bottom-right
              {x: 0, y: height}, //bottom-left,
            ];

            const containerPosition = lib.object.sum(nearestEntityViewOffset, Elevation.getPosition2d({elevation, position3d: sourceEntity.position}), {y: -sourceEntity.dimensions.height, x: sideKeyOffset});

            var updatedContainerSnapPositions = _.map(containerSnapPositions, point => lib.object.sum(point, containerPosition));

            debugModeSourceSnapPositions = _.map(updatedContainerSnapPositions, (point, index) => lib.object.sum(point, offset));
          }
          else if (sourceEntityResourceKey === 'volume') {
            //HINT normalize
            var points = Volume.getPoints({volume: sourceEntity, sideKey: 'front', elevation: nearestEntity.entity});

            points = _.map(points, point => {
              return {x: point.x, y: -point.y + sourceEntity.dimensions.height};
            });

            sourceSnapPositions.push(
              ...points
            );
          }
          else if (sourceEntityResourceKey === 'product') {
            const product = sourceEntity;
            const isBarblockComponent = Product.getIsBarblockComponent({product});
            const isShelfbankComponent = Product.getIsShelfbankComponent({product});
            const isComponentProduct = Product.getIsComponentProduct({product});
            const isPeg = Product.getIsPeg({product});

            if (isPeg) {
              sourceSnapPositions.push({x: size.width / 2, y: size.height / 2}); //center
            }
            if (!isComponentProduct) {
              size = {width: product.dimensions.width, height: product.dimensions.height};
            }
            else if (isBarblockComponent || isShelfbankComponent) {
              size = {width: product.dimensions.width, height: product.dimensions.height};
            }
          }
          else if (sourceEntityResourceKey === 'archElement') {
            const archElement = sourceEntity;

            const {width, height} = ArchElement.getSize({archElement, viewKey: 'front'});

            size = {width, height};
          }
        }
      }

      if (!_.isEmpty(size)) {
        sourceSnapPositions.push(
          {x: 0, y: 0}, //top-left
          {x: size.width, y: 0}, //top-right
          {x: size.width / 2, y: size.height / 2}, //center
          {x: size.width, y: size.height}, //bottom-right
          {x: 0, y: size.height} //bottom-left
        );
      }
    }
    catch (error) {
      global.handleError({error, info: {componentStack: error.stack}, message: 'source snap'});
    }

    return {sourceSnapPositions, debugModeSourceSnapPositions};
  }
};

export default SnapDataHelper