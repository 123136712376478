import _ from 'lodash';
import lib from 'lib';
import Room from 'project-helpers/room';
import Product from 'project-helpers/product';
import DetailsHelper from 'helpers/details-helper';
import HatchHelper from 'helpers/hatch-helper';
import getDependencies from 'helpers/get-dependencies';
import K from 'k';

import updateProductionIds from 'helpers/update-production-ids-helper';
import Container from './container';

var Project = {
  get(dependencyKeys, {project}) {
    return getDependencies({dependencyKeys}, ({state}) => {
      if (state.resources.rooms.byFieldKeyIndex) {
        const rooms = _.values(state.resources.rooms.byFieldKeyIndex.projectId[project.id]);

        return {
          appliances: () => state.resources.appliances.byId,
          products: () => _.flatMap(rooms, room => _.values(Room.get('products', {room}))),
          containers: () => _.flatMap(rooms, room => _.values(Room.get('containers', {room}))),
          elevations: () => _.flatMap(rooms, room => _.values(Room.get('elevations', {room}))),
          rooms: () => rooms,
          floors: () => state.resources.floors.byFieldKeyIndex.projectId[project.id],
          dependencies: () => state.resources,
          parts: () => state.resources.parts.byId
        };
      }
    });
  },

  getMaterialFrequencyGroups({project, activeFillMode}) {
    var products = Project.get('products', {project});

    var materialFrequencyGroups = {wHatches: {}, woVisibleIds: {}, wVisibleIds: {}};

    _.forEach(products, product => {
      var productData = Product.getProductData({product});
      var details = DetailsHelper.getDetailsFor({product});
      var ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product});

      var incrementMaterialCount = ({groupKey, materialId}) => {
        materialFrequencyGroups[groupKey][materialId] = (materialFrequencyGroups[groupKey][materialId] || 0) + 1;
      };

      _.forEach(ownedCompatibleDetails, ({key, type}) => {
        if (type === 'material') {
          var detail = details[key];

          if (detail) {
            if (Project.getShouldShowHatch({detailKey: key, productData, activeFillMode})) {
              var hasAppliedBrass = Product.getHasAppliedBrass({product, productType: productData});

              if (hasAppliedBrass && key === 'frontMaterial') {
                incrementMaterialCount({groupKey: 'wHatches', materialId: `${detail.id}brass`});
              }
              else {
                incrementMaterialCount({groupKey: 'wHatches', materialId: detail.id});
              }
            }
            else {
              incrementMaterialCount({groupKey: productData.categoryId === 54 ? 'woVisibleIds' : 'wVisibleIds', materialId: detail.id});
            }
          }
        }
      });
    });

    materialFrequencyGroups = _.mapValues(materialFrequencyGroups, (materialFrequencies, groupKey) => {
      materialFrequencies = _.orderBy(_.map(materialFrequencies, (count, materialId) => ({count, materialId: parseInt(materialId), hasAppliedBrass: _.includes(materialId, 'brass')})), 'count', 'desc');

      if (groupKey === 'wHatches') {
        _.forEach(materialFrequencies, materialFrequency => {
          materialFrequency.hatchKey = Project.getHatchKeyFor({project, detailValue: materialFrequency.materialId, hasAppliedBrass: materialFrequency.hasAppliedBrass, useMaterialFrequencyGroups: false});
        });
      }

      return materialFrequencies;
    });

    return materialFrequencyGroups;
  },

  getShouldShowHatch: ({detailKey, productData, sideKey, activeFillMode}) => {
    var isSubproductDetail = _.split(detailKey, '-').length > 1;

    //HINT should show hatch is determined based on the core detailKey, IE
    //frontMaterial, not drawer-2-frontMaterial
    if (isSubproductDetail) detailKey = _.split(detailKey, '-')[2];

    var isFront = (detailKey === 'frontMaterial' && !_.includes([53, 54], productData.categoryId));
    var isPanel = (detailKey === 'panelMaterial' && (_.includes([53, 77, 79, 50, 46, 49, 47], productData.categoryId) || _.includes([728], productData.id)));

    var isKick = (detailKey === 'kickMaterial');
    var isScribe = (detailKey === 'scribeMaterial') || _.includes(detailKey, 'ScribeMaterial');
    var isEndPanel = (detailKey === 'panelMaterial');
    var isPull = (activeFillMode === 'materialColors' && detailKey === 'pullMaterial');
    var isSubproductDetail = _.split(detailKey, '-').length > 1;

    var exposedBoxProductIds = [
      37, 691, 85, 699, 120, 694, 119, 174, 175, 183, 1148, 1610,
      262, 404, //valets
      960, 1400, 1486, 611, 612, 176, 987, 992, //open units
      977, 160, //sliding glass
      //assemblies?
      1188, 1712, 1713, //opencase trim cap, not sure why this is using box materialKey,
      986, 1581, 1519, 1587, 901, 938, 960, 961, 985, 986, 1355,//ST bookcases
      1564,//ST shelfbank shelf
      1643, 1663, //ST functional wood boxes
      1407,1524, 1525, 1419, //vbb spice drawer, using box material key
      1149, //HB hood freestanding shroud
      1716, //top storage open hanging
      185 //backsplash w wood shelf
    ];

    var isSideOfBox = _.includes(['left', 'right'], sideKey) && detailKey === 'boxMaterial';

    var isBenchAssemblyMaterial = false;

    var isBenchAssemblyMaterial = productData.id === 512 && _.includes(['endPanelMaterial', 'wallPanelMaterial', 'hookMaterial', 'shoeShelfMaterial', 'steelShelfMaterial', 'benchTopMaterial', 'benchBaseMaterial', 'benchBackMaterial', 'benchBracketMaterial'], detailKey);

    var isVBB = _.includes([64], productData.categoryId);
    var isOpenCategory = _.includes([72], productData.categoryId);
    var isExposedBox = ((detailKey === 'boxMaterial' || detailKey === 'boxBackMaterial') && (isOpenCategory || _.includes(exposedBoxProductIds, productData.id) || isVBB));
    var isFramedGlass = (detailKey === 'glassMaterial'); //TODO filter by products?

    var isShelfbankBoxMaterial = detailKey === 'boxMaterial' && _.includes([162, 163, 164, 165, 166, 165, 167, 1379], productData.id);

    var isOpencaseShelfBoxMaterial = detailKey === 'boxMaterial' && _.includes([1476, 1477, 1478], productData.id);
    var isOpencaseShelfFrontMaterial = detailKey === 'frontMaterial' && _.includes([1476, 1477, 1478], productData.id);

    var isOpencaseComponent = activeFillMode === 'materialColors' && _.includes(['ocRodMaterial', 'ocMetalMaterial', 'ocWoodMaterial', 'ocCapMaterial', 'ocLeatherMaterial', 'ocPaperstoneMaterial', 'ocPocketMaterial', 'ocMetal2Material'], detailKey) && productData.categoryId === 54;
    var isPeg = activeFillMode === 'materialColors' && detailKey === 'pegMaterial';
    var isSTPocketingFlipBox = _.includes([990, 1191, 1673, 1674], productData.id) && detailKey === 'boxMaterial';
    var isMicrowaveUpper = _.includes([762, 64], productData.id)  && detailKey === 'upperBoxMaterial';

    //HINT open unit wood drawers are visible
    var isExposedBoxWoodDrawer = detailKey === 'woodDrawerMaterial' && (isOpenCategory || _.includes(exposedBoxProductIds, productData.id) || isVBB);

    return isFront || isPanel || isKick || isScribe || isEndPanel || isExposedBox || isPeg || isSideOfBox ||
      isFramedGlass || isOpencaseComponent || isPull || isShelfbankBoxMaterial || isMicrowaveUpper || isBenchAssemblyMaterial ||
      isOpencaseShelfBoxMaterial || isOpencaseShelfFrontMaterial || isSTPocketingFlipBox || isExposedBoxWoodDrawer;
  },

  getHatchKeyFor: ({project, detailValue, hasAppliedBrass=false, useMaterialFrequencyGroups = true}) => {
    var key;

    if (!K.usedHatches) K.usedHatches = {};

    _.forEach(K.usedHatches, (value, hatchKey) => {
      if (parseInt(value) === detailValue && _.includes(value, 'brass') === hasAppliedBrass) key = hatchKey;
    });

    if (!key && useMaterialFrequencyGroups) {
      key = _.get(_.find(Project.getMaterialFrequencyGroups({project}).wHatches, hatch => hatch.materialId === detailValue && hasAppliedBrass === hatch.hasAppliedBrass), 'hatchKey');
    }

    if (!key) {
      var unusedKeys = _.filter(HatchHelper.getHatchKeys(), key => !_.includes(_.keys(K.usedHatches), key));

      key = unusedKeys[0] || 'invalid';

      K.usedHatches[key] = `${detailValue}${hasAppliedBrass ? 'brass' : ''}`;
    }

    return key;
  },

  autogenerateElevations({project, roomId, reduxActions, isBatched}) {
    //HINT no longer autogenerating
    //just generating a few elevations and designers position themselves
    return false;

    const {continuousElevationAutogenerationDisabled} = project;

    if (continuousElevationAutogenerationDisabled) return false;

    var rooms = Project.get('rooms', {project});
    var allMatchedElevations = [];
    var elevationPropsSetsToCreate = [], elevationUpdates = [], elevationIdsToDestroy = [];

    if (roomId) rooms = _.filter(rooms, room => room.id === roomId);

    _.forEach(rooms, room => {
      if (_.get(room, 'plan.closed')) {
        var oldElevations = Room.get('elevations', {room});
        var matchedElevations = [], newElevationsData = [];
        var matchDistanceThreshold = 36;
        var computedElevationsData = Room.getElevations({room, onlyFronts: {walls: false, island: false}, includeSections: true});

        _.forEach(computedElevationsData, elevationData => {
          var matchedElevation = _.find(oldElevations, oldElevation => {
            var l1 = oldElevation.lineInRoom, l2 = elevationData.lineInRoom;
            var a1 = lib.trig.alpha({p1: l1.from, p2: l1.to}), a2 = lib.trig.alpha({p1: l2.from, p2: l2.to});
            var theta = lib.trig.normalize({radians: Math.abs(a1 - a2)});

            return theta < Math.PI / 2
              && lib.trig.distance({fromPoint: l1.from, toPoint: l2.from}) < matchDistanceThreshold
              && lib.trig.distance({fromPoint: l1.to, toPoint: l2.to}) < matchDistanceThreshold;
          });

          if (matchedElevation) {
            matchedElevations.push(matchedElevation);
            allMatchedElevations.push(matchedElevation);

            newElevationsData.push({actionKey: 'update', elevationData, matchedElevation});
          }
          else {
            newElevationsData.push({actionKey: 'create', elevationData});
          }
        });

        elevationIdsToDestroy.push(..._.map(_.filter(oldElevations, oldElevation => !_.includes(matchedElevations, oldElevation)), 'id'));

        _.forEach(newElevationsData, ({elevationData, matchedElevation, actionKey}, rank) => {
          var {viewDepth = 50, lineInRoom} = elevationData;

          var generatedId = elevationData.id || _.join(_.concat(_.map(lineInRoom, point => {
            return _.join(_.map(_.values(point), value => _.replace(value, '.', '-')), '-');
          }), room.id), '-');

          lineInRoom = lib.trig.extend({line: lineInRoom, by: 7}); //HINT extending after creating id to avoid breaking old ids

          if (actionKey === 'update') {
            var updatedProps = {rank, generatedId, lineInRoom, viewDepth};
            var oldProps = _.pick(matchedElevation, ['rank', 'generatedId', 'lineInRoom', 'viewDepth']);

            if (!_.isEqual(updatedProps, oldProps)) {
              elevationUpdates.push({where: {id: matchedElevation.id}, props: updatedProps});
            }
          }
          else if (actionKey === 'create') {
            elevationPropsSetsToCreate.push({props: {
              roomId: room.id, projectId: project.id, versionId: project.versionId,
              lineInRoom, generatedId, rank, viewDepth
            }});
          }
        });
      }
    });

    if (_.some([elevationPropsSetsToCreate, elevationUpdates, elevationIdsToDestroy], modificationData => modificationData.length > 0)) {
      if (isBatched) {
        return {
          elevations: {
            creations: elevationPropsSetsToCreate,
            updates: elevationUpdates,
            deletedIds: elevationIdsToDestroy
          }
        };
      }
      else {
        reduxActions.modifyElevations({
          creations: elevationPropsSetsToCreate,
          updates: elevationUpdates,
          destructions: elevationIdsToDestroy
        });
      }
    }

    if (!isBatched) updateProductionIds({project, reduxActions});
  },

  getCompanyName({project}) {
    return project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt';
  },

  updateManagedParts({project, reduxActions, state, isBatched=false}) {
    const {companyKey} = project;
    var parts, rooms, containers;

    if (state) {
      parts = state.parts.byId;
      rooms = _.values(state.rooms.byFieldKeyIndex.projectId[project.id]);
      containers = state.containers.byId;
    }
    else {
      var data = Project.get(['parts', 'containers', 'rooms'], {project});

      parts = data.parts;
      rooms = data.rooms;
      containers = data.containers;
    }

    var partInstances = _.sortBy(parts, ['id']);

    if (!project.lockedForProduction) {
      var newParts = [];
      var oldPartInstanceIds = [];

      //HINT part instances in since deleted rooms
      _.forEach(partInstances, partInstance => {
        if (_.get(partInstance, 'managedData.managedKey') && !_.find(rooms, {id: _.get(partInstance, 'customData.roomId')})) {
          oldPartInstanceIds.push(partInstance.id);
        }
      });

      var managePart = ({partId, scopeId, roomId, partQuantity, generatePartW0Quantity, managedKey, versionId, materialId}) => {
        var oldPartInstances = _.filter(partInstances, partInstance => {
          return partId === partInstance.partId && partInstance.primaryAssociationKey === 'scope' && _.get(partInstance, 'customData.roomId') === roomId && scopeId === partInstance.scopeId && _.get(partInstance, 'managedData.managedKey') === managedKey && (materialId ? _.get(partInstance, 'materialIds.primary') === _.toNumber(materialId) : true);
        });

        //HINT only updating if something has changed, for generate with 0 quantity, only update if there is a change to the presence of the part, don't want to override manual engineer inputs
        if (generatePartW0Quantity ? oldPartInstances.length > 0 : (partQuantity > 0 && _.sum(_.map(oldPartInstances, 'quantity')) === partQuantity)) return;

        _.forEach(partInstances, partInstance => {
          if (
            partInstance.partId === partId &&
            partInstance.primaryAssociationKey === 'scope' &&
            _.get(partInstance, 'customData.roomId') === roomId &&
            _.get(partInstance, 'managedData.managedKey') === managedKey
          ) {
            oldPartInstanceIds.push(partInstance.id);
          }
        });

        var props = {
          projectId: project.id,
          versionId,
          partId,
          scopeId,
          productionId: _.get(oldPartInstances, '[0].productionId', ''),
          customData: {..._.get(oldPartInstances, '[0].customData', {}), roomId},
          primaryAssociationKey: 'scope',
          managedData: {managedKey}
        }

        if (materialId) props.materialIds = {primary: _.toNumber(materialId)};

        var partSplitThreshold;

        if (_.includes(['threeQuarterSubcounterTrim', 'threeQuarterSubcounterSpacer'], managedKey)) partSplitThreshold = 5;
        if (_.includes(['oneQuarterSubcounterTrim', 'oneHalfSubcounterTrim', 'oneQuarterSubcounterSpacer', 'oneHalfSubcounterSpacer'], managedKey)) partSplitThreshold = 10;

        if (partSplitThreshold && partQuantity > partSplitThreshold) {
          _.times(_.ceil(partQuantity / partSplitThreshold), (oldPartIndex) => {
            var isLastInstance = oldPartIndex === (_.ceil(partQuantity / partSplitThreshold) - 1);

            if (oldPartInstances.length > 0 && oldPartInstances[oldPartIndex]) {
              props.productionId = _.get(oldPartInstances[oldPartIndex], 'productionId');
              props.customData = {..._.get(oldPartInstances[oldPartIndex], 'customData', {}), roomId};
            }

            newParts.push({props: {...props, quantity: isLastInstance ? (partQuantity - ((_.ceil(partQuantity / partSplitThreshold) - 1)* partSplitThreshold)) : partSplitThreshold}});
          });
        }
        else {
          if (partQuantity > 0 || generatePartW0Quantity) {
            if (generatePartW0Quantity && oldPartInstances.length > 0) partQuantity = oldPartInstances[0].quantity;

            props.quantity = partQuantity;

            newParts.push({props});
          }
        }
      };

      _.forEach(rooms, room => {
        const scope = Room.get('scope', {room});
        const scopeId = scope.id;
        //< cleat and cleat spacer parts
        var totalCleatWidth = 0;

        const roomContainers = _.filter(containers, {scopeId});

        _.forEach(roomContainers, container => {
          if (container.type === 'wall') totalCleatWidth += container.dimensions.width;
        });

        var cleatCount = _.ceil(totalCleatWidth / 95);

        managePart({roomId: room.id, versionId: room.versionId, partId: 275, partQuantity: (cleatCount ? cleatCount + 1 : 0), scopeId, managedKey: 'cleat'}); //cleat
        //#HINT Seth said unneeded currently
        //managePart({roomId: room.id, versionId: room.versionId, partId: 286, partQuantity: cleatCount ? cleatCount + 3 : 0}); //cleat spacer
        // />

        // lighting parts
        var products = Room.get('products', {room});
        var hasPuckLighting = false;
        var hasLinearLighting = false;

        _.forEach(products, product => {
          var hasExternalLighting = Product.getHasLighting({product});
          var {productType, productOptionInstances} = Product.get(['productOptionInstances', 'productType'], {product});
          var hasInternalLightingOption = _.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 78);
          var hasBuiltInInternalLighting = productType.category === 73 || _.includes([605, 606], product.productId);

          if (hasExternalLighting && _.get(Product.get('container', {product}), `customData.lightingType`, 'puck') === 'puck') {
            hasPuckLighting = true;
          }

          if (
            hasInternalLightingOption ||
            hasBuiltInInternalLighting ||
            (hasExternalLighting && _.get(Product.get('container', {product}), `customData.lightingType`, 'puck') === 'linear')
          ) {
            hasLinearLighting = true;
          }
        });

        //< puck lighting parts
        managePart({
          roomId: room.id, versionId: room.versionId,
          partId: 73754, generatePartW0Quantity: hasPuckLighting,
          partQuantity: 0, scopeId, managedKey: 'twentyWattTransformer'
        });
        // puck lighting parts />

        //< linear lighting parts
        managePart({
          roomId: room.id, versionId: room.versionId,
          partId: 73747, generatePartW0Quantity: hasLinearLighting,
          partQuantity: 0, scopeId, managedKey: 'thirtyWattDriver'
        });
        managePart({
          roomId: room.id, versionId: room.versionId,
          partId: 73748, generatePartW0Quantity: hasLinearLighting,
          partQuantity: 0, scopeId, managedKey: 'sixtyWattDriver'
        });
        managePart({
          roomId: room.id, versionId: room.versionId,
          partId: 73749, generatePartW0Quantity: hasLinearLighting,
          partQuantity: 0, scopeId, managedKey: 'nintySixWattDriver'
        });
        // linear lighting parts/>

        //< subcounter trim and spacer
        var subcounterTrimCountByMaterial = {};
        var subcounterSpacerCount = {
          quarterInchSubcounterCount: 0,
          halfInchSubcounterCount: 0,
          threeQuarterInchSubcounterCount: 0
        };

        _.forEach(roomContainers, container => {
          if (Container.getHasSubcounter({container})) {
            var subcounterHeight = Container.getSubcounterHeight({container});
            var subcounterMaterial = DetailsHelper.getDetailsFor({container}).subcounterMaterial;

            if (subcounterHeight === 0.75) {
              var prevCount = _.get(subcounterTrimCountByMaterial, `${subcounterMaterial.id}.threeQuarterInchSubcounterCount`, 0);
              var updatedCount = prevCount += _.ceil(container.dimensions.width / 95);

              subcounterSpacerCount.threeQuarterInchSubcounterCount += _.ceil(container.dimensions.width / 95);

              subcounterTrimCountByMaterial[subcounterMaterial.id] = {
                ...subcounterTrimCountByMaterial[subcounterMaterial.id],
                threeQuarterInchSubcounterCount: updatedCount
              };
            }
            else if (subcounterHeight === 0.25) {
              var prevCount = _.get(subcounterTrimCountByMaterial, `${subcounterMaterial.id}.quarterInchSubcounterCount`, 0);
              var updatedCount = prevCount += _.ceil(container.dimensions.width / 95);

              subcounterSpacerCount.quarterInchSubcounterCount += _.ceil(container.dimensions.width / 95);

              subcounterTrimCountByMaterial[subcounterMaterial.id] = {
                ...subcounterTrimCountByMaterial[subcounterMaterial.id],
                quarterInchSubcounterCount: updatedCount
              };
            }
            else {
              var prevCount = _.get(subcounterTrimCountByMaterial, `${subcounterMaterial.id}.halfInchSubcounterCount`, 0);
              var updatedCount = prevCount += _.ceil(container.dimensions.width / 95);

              subcounterSpacerCount.halfInchSubcounterCount += _.ceil(container.dimensions.width / 95);

              subcounterTrimCountByMaterial[subcounterMaterial.id] = {
                ...subcounterTrimCountByMaterial[subcounterMaterial.id],
                halfInchSubcounterCount: updatedCount
              };
            }
          }
        });

        //subcounter parts
        if (subcounterSpacerCount.threeQuarterInchSubcounterCount <= 0) {
          oldPartInstanceIds.push(..._.map(_.filter(partInstances, partInstance => (partInstance.partId === 271 || partInstance.partId === 272) && _.get(partInstance, 'managedData.managedKey') && _.get(partInstance, 'customData.roomId') === room.id), 'id'));
        }
        if (subcounterSpacerCount.quarterInchSubcounterCount <= 0) {
          oldPartInstanceIds.push(..._.map(_.filter(partInstances, partInstance => (partInstance.partId === 273 || partInstance.partId === 274) && _.get(partInstance, 'managedData.managedKey') && _.get(partInstance, 'customData.roomId') === room.id), 'id'));
        }
        if (subcounterSpacerCount.halfInchSubcounterCount <= 0) {
          oldPartInstanceIds.push(..._.map(_.filter(partInstances, partInstance => (partInstance.partId === 333 || partInstance.partId === 334) && _.get(partInstance, 'managedData.managedKey') && _.get(partInstance, 'customData.roomId') === room.id), 'id'));
        }

        _.forEach(subcounterSpacerCount, (quantity, key) => {
          if (key === 'quarterInchSubcounterCount') {
            managePart({roomId: room.id, versionId: room.versionId, partId: 274, partQuantity: quantity ? quantity + 3 : 0, scopeId, managedKey: 'oneQuarterSubcounterSpacer'});
          }

          if (key === 'halfInchSubcounterCount') {
            managePart({roomId: room.id, versionId: room.versionId, partId: 334, partQuantity: quantity ? quantity + 3 : 0, scopeId, managedKey: 'oneHalfSubcounterSpacer'});
          }

          if (key === 'threeQuarterInchSubcounterCount') {
            managePart({roomId: room.id, versionId: room.versionId, partId: 272, partQuantity: quantity ? quantity + 3 : 0, scopeId, managedKey: 'threeQuarterSubcounterSpacer'});
          }
        });

        _.forEach(subcounterTrimCountByMaterial, (counts, materialId) => {
          _.forEach(counts, (quantity, key) => {
            if (key === 'quarterInchSubcounterCount') {
              managePart({roomId: room.id, versionId: room.versionId, partId: 273, partQuantity: (quantity ? quantity + 1 : 0), scopeId, materialId, managedKey: 'oneQuarterSubcounterTrim'});
            }

            if (key === 'halfInchSubcounterCount') {
              managePart({roomId: room.id, versionId: room.versionId, partId: 333, partQuantity: (quantity ? quantity + 1 : 0), scopeId, materialId, managedKey: 'oneHalfSubcounterTrim'});
            }

            if (key === 'threeQuarterInchSubcounterCount') {
              managePart({roomId: room.id, versionId: room.versionId, partId: 271, partQuantity: (quantity ? quantity + 1 : 0), scopeId, materialId, managedKey: 'threeQuarterSubcounterTrim'});
            }
          })
        })
      });

      if (isBatched) {
        return {parts: {creations: newParts, updates: [], deletedIds: _.uniq(oldPartInstanceIds), tracks: []}};
      }
      else {
        if (oldPartInstanceIds.length > 0) {
          reduxActions.destroyParts({ids: oldPartInstanceIds});
        }

        if (newParts.length > 0) {
          reduxActions.createParts({propsSets: newParts})
        }
      }
    }
  },
};

export default Project;
