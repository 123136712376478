import React, { useContext } from 'react';

import _ from 'lodash';
import lib from 'lib';
import CanvasScriptNode from './canvas-script-node';
import BKey from 'helpers/b-key';

import { CanvasDataContext } from 'canvas';
import CanvasTransformer from './canvas-transformer';
import CanvasMask from './canvas-mask';
import { Group } from 'react-konva';
import PositionHelper from 'helpers/position-helper';

var lodash = _;

class CanvasScriptObject extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      updatedRootNode: false
    };

    this.id = lib.string.uuid();

    this.setRootNode(false);
  }

  handleTransform = (props) => {
    if (this.props.onTransform) this.props.onTransform(props);
  };

  handleTransformEnd = (props) => {
    if (this.props.onTransformEnd) this.props.onTransformEnd(props);
  };

  handleKeyDown = (event) => {
    if (this.props.isSelected && !this.props.multipleEntitiesSelected && !this.props.isDisabled && document.activeElement.tagName === 'BODY') {
      if (lib.event.keyPressed(event, 'delete')) {
        this.handleDelete();
      }
    }
  };

  handleDelete() {
    if (this.props.onDelete) this.props.onDelete();
  }

  handleBeginScaleTool() {
    if (this.props.onBeginScaleTool) this.props.onBeginScaleTool();
  }

  handleSelect = (event) => {
    if (event.evt.button === 0 && this.props.onSelect && !this.props.isDisabled) this.props.onSelect(event);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.alwaysUpdate || (this.state.updatedRootNode === prevState.updatedRootNode && prevProps.canvasData.scale === this.props.canvasData.scale && prevProps.canvasData.offset === this.props.canvasData.offset)) {
      this.setRootNode();
    }
  }

  setRootNode(forceRerender = true) {
    const rootNode = parseScript(this.props.script, this.props.metaProps || {}, {sideKey: 'front', passthroughProps: {}});

    this.rootNode = _.defaults(rootNode, {type: 'group'});

    if (forceRerender && !this.props.alwaysUpdate) this.setState({updatedRootNode: !this.state.updatedRootNode});
  }

  render() {
    const {isSelected, multipleEntitiesSelected, canvasData, isDisabled, maskingPolygons, isScalable = true, isRotatable = true, isDraggable = true, hideText, renderForDrawings, scaleX=1, scaleY=1} = this.props;
    const {rootNode} = this;
    const objectProps = _.pick(this.props, ['name', 'resourceData', 'fill', 'size', 'position', 'maskPosition', 'rotation', 'hatchFill', 'hatchFills', 'shouldInvertStroke', 'stroke', 'strokeWidth', 'opacity']);

    var scaledMaskingPolygons;

    if (maskingPolygons) {
      scaledMaskingPolygons = _.map(maskingPolygons, polygon => {
        return _.map(polygon, (point) => PositionHelper.toCanvas(lib.object.sum(point, objectProps.maskPosition || objectProps.position), canvasData));
      });
    }

    var ScriptGroup = <Group listening={!isDisabled} {...{rotation: objectProps.rotation, ...PositionHelper.toCanvas(objectProps.position, canvasData), scaleX, scaleY}}>
      <CanvasScriptNode
        isTopLevel={true}
        parentOffset={{top: 0, left: 0}}
        parentSize={_.clone(objectProps.size)}
        listening={this.props.onSelect && !this.props.isDisabled}
        onClick={(this.props.onSelect && !this.props.isDisabled) ? this.handleSelect : null}
        {...rootNode}
        {...{objectProps: _.omit(objectProps, ['rotation', 'position']), canvasData: _.pick(canvasData, ['scale']), hideText, objectScaleX: scaleX, objectScaleY: scaleY, renderForDrawings}}
      />
    </Group>;

    return (
      <>
        {_.get(scaledMaskingPolygons, 'length') ? (
          <CanvasMask {...{maskingPolygons: scaledMaskingPolygons}}>
            {ScriptGroup}
          </CanvasMask>
        ) : (
          ScriptGroup
        )}
        {<CanvasTransformer {...{
          shapeProps: objectProps,
          isSelected, multipleEntitiesSelected,
          isScalable: !this.props.locked && isScalable,
          isDraggable: !this.props.locked && isDraggable,
          isRotatable: !this.props.locked && isRotatable,
          onClick: this.handleSelect,
          onTransform: this.handleTransform,
          onTransformEnd: this.handleTransformEnd,
          isDisabled,
          ..._.pick(this.props, ['transformerOffset', 'includeOriginalPosition', 'constraints', 'dropzoneInset', 'dropzoneSize', 'customAnchorDragBoundFunc', 'customDragBoundFunc', 'snapToLines', 'disabledAnchors', 'preventInfinitePrecision'])
        }}/>}
      </>
    );
  }
}

export default function CanvasScriptObjectWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CanvasScriptObject {...props} {...{canvasData: _.pick(canvasData, ['scale', 'containerSize', 'offset', 'stage'])}}/>;
}

function parseScript(transformedScript, metaProps = {}, {sideKey = 'front', passthroughProps} = {}) {
  var _ = lodash.defaultsDeep(metaProps, {children: [], props: {...passthroughProps}});

  var sideSizeMap = {
    front: {width: 'width', height: 'height'},
    back: {width: 'width', height: 'height'},
    left: {width: 'depth', height: 'height'},
    right: {width: 'depth', height: 'height'},
    top: {width: 'width', height: 'depth'},
    bottom: {width: 'width', height: 'depth'}
  };

  var sizeDimensionsMap = sideSizeMap[sideKey];
  var calc, group, stack, rect, circle, line, array, path, text, tree = {}; // eslint-disable-line

  //allows for components e.g. _.models.flipdownDrawer({}, []);
  if (_.modelerModels && _.sideKey) {
    _.models = lodash.mapValues(_.modelerModels, (model) => {
      return (props = {}, children = []) => {
        return parseScript(model[_.sideKey], {..._, props, children});
      };
    });
  }

  calc = props => { //eslint-disable-line
    var size = {width: _.dimensions[sizeDimensionsMap.width], height: _.dimensions[sizeDimensionsMap.height]};

    return calcProps(props, {size});
  };

  group = (props = {}, children = []) => ({type: 'group', nodeProps: {groupType: 'group', ...props}, children}); //eslint-disable-line
  stack = (props = {}, children = []) => ({type: 'group', nodeProps: {groupType: 'stack', axis: 'y', spacing: 0, ...props}, children}); //eslint-disable-line

  //WARNING path only works when canvasObject has a size
  path = (props = {}, points = []) => ({type: 'shape', nodeProps: {shape: 'path', closed: false, points, ...props}}); //eslint-disable-line
  rect = (props) => ({type: 'shape', nodeProps: {shape: 'rect', ...props}}); //eslint-disable-line
  circle = (props) => ({type: 'shape', nodeProps: {shape: 'circle', ...props}}); //eslint-disable-line
  line = (props) => ({type: 'shape', nodeProps: {shape: 'line', ...props}}); //eslint-disable-line
  text = (props) => ({type: 'shape', nodeProps: {shape: 'text', ...props}}); //eslint-disable-line

  array = (props, fn) => { //eslint-disable-line
    props = lodash.defaults(props, {count: 0, axis: 'x', spacing: 0});

    var {count, axis, spacing} = props;
    var side = axis === 'x' ? 'left' : 'top';
    var children = [];

    lodash.times(count, n => children.push(fn({[side]: n * spacing, index: n})));

    return group(props, children);
  };

  try {
    tree = eval(transformedScript) || {}; // eslint-disable-line
  }
  catch (e) {
    console.log(e, transformedScript); // eslint-disable-line
  }

  return tree;
}

function calcScriptValue(formula, {size = 0, scale = 1, parentSize={}}) {
  if (typeof(formula) === 'string') {
    //Percentages
    //Match any number or decimal number or negative number preceding %
    var percentages = formula.match(/[-+]?\d+(\.\d+)?(?=%)/g);
    if (percentages) {
      percentages.forEach(percentage => {
        var value = ((parseFloat(percentage) || 0) / 100) * size;

        formula = formula.replace(`${percentage}%`, value);
      });
    }

    //Match any number or decimal number or negative number preceding in
    var points = formula.match(/[-+]?\d+(\.\d+)?(?=in|pt)/g);
    if (points) {
      points.forEach(point => {
        var value = (parseFloat(point) || 0);

        formula = formula.replace(`${point}in`, value).replace(`${point}pt`, value);
      });
    }

    //Match any number or decimal number or negative number preceding in
    var pixels = formula.match(/[-+]?\d+(\.\d+)?(?=px)/g);

    if (pixels) {
      pixels.forEach(pixel => {
        var value = (parseFloat(pixel) || 0) / scale;

        formula = formula.replace(`${pixel}px`, value);
      });
    }
  }

  var value = 0;

  try {
    value = eval(formula); // eslint-disable-line
  }
  catch (err) {
    console.log(formula, err); // eslint-disable-line
  }

  return value;
}

function calcProps(props, {size = {width: 0, height: 0}, scale = 1} = {}) {
  props = lib.object.clone(props);

  _.forEach(props, (prop, propKey) => {
    ['x', 'y'].forEach(axisKey => {
      if (_.includes(numericPropKeys[axisKey], propKey)) {
        props[propKey] = calcScriptValue(prop, {size: size[axisKey === 'x' ? 'width' : 'height'], scale});
      }
    });
  });

  return props;
}

const numericPropKeys = {
  x: ['width', 'left', 'right', 'x1', 'x2', 'rx', 'radius'],
  y: ['height', 'top', 'bottom', 'y1', 'y2', 'ry']
};

export { parseScript, calcScriptValue, calcProps, numericPropKeys };
