import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Container from 'project-helpers/container';
import Elevation from 'project-helpers/elevation';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addElevationContainerDimensions({allPoints, containers, containersData, getIsTrapped, elevation, outsideDimensionSets, room, isSection, dimensionSets}) {
  var localTrappedGroups = [];
  var accountedForYDims = [];

  var addLineForCandidateSideKeys = ({wallprintLines, sideKeys, containerData, container, idPrefix, alwaysStandalone, noStandalone, shouldGroup}) => {
    var candidateTrappedData = _.map(sideKeys, (lineSideKey) => {
      var line = wallprintLines[lineSideKey];

      return {line, trappedData: containerData.trappedDataBySide[lineSideKey], lineSideKey};
    });

    var untrappedCandidates = _.filter(candidateTrappedData, data => !data.trappedData.isTrapped);

    if (untrappedCandidates.length && !alwaysStandalone) {
      addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines: _.map(untrappedCandidates, 'line'), idPrefix});
    }
    else {
      var lineData = _.find(candidateTrappedData, candidateLineData => !candidateLineData.trappedData.isTrappedLocally);

      if (!lineData) lineData = candidateTrappedData[0];

      if (!noStandalone) {
        if (shouldGroup) {
          if (!_.includes(accountedForYDims, `${lineData.line.from.y}-${lineData.line.to.y}`)) {
            accountedForYDims.push(`${lineData.line.from.y}-${lineData.line.to.y}`);
            var group = _.find(localTrappedGroups, group => {
              return group.alpha === lib.trig.alpha({p1: lineData.line.from, p2: lineData.line.to}) && (
                _.some(group.targets, target => {
                  return Math.abs(target.position.x - lineData.line.from.x) < 5;
                })
              );
            });

            if (group) {
              group.targets.push({position: lineData.line.from, id: `${idPrefix}-from`});
              group.targets.push({position: lineData.line.to, id: `${idPrefix}-to`});
            }
            else {
              localTrappedGroups.push({
                alpha: lib.trig.alpha({p1: lineData.line.from, p2: lineData.line.to}),
                offset: lineData.trappedData.isTrappedLocally ? -5 : 5,
                type: 'extrudeLocally',
                id: `${idPrefix}`,
                showLabelBorder: true,
                targets: [
                  {position: lineData.line.from, id: `${idPrefix}-standalone-from`},
                  {position: lineData.line.to, id: `${idPrefix}-standalone-to`}
                ]
              });
            }
          }
        }
        else {
          var perpendicularDistance = lib.trig.distance({fromPoint: wallprintLines[_.includes(['left', 'right'], lineData.lineSideKey) ? 'top' : 'left'].from, toPoint: wallprintLines[_.includes(['left', 'right'], lineData.lineSideKey) ? 'top' : 'left'].to});

          dimensionSets.push({
            alpha: lib.trig.alpha({p1: lineData.line.from, p2: lineData.line.to}),
            offset: lineData.trappedData.isTrappedLocally ? (_.includes(['endPanel', 'capPanel'], container.type) ? ((_.includes(['left', 'top'], lineData.lineSideKey) ? -1 : 1) * (perpendicularDistance / 2)) : -5) : 5,
            type: 'standalone',
            id: `${idPrefix}`,
            showLabelBorder: true,
            targets: [
              {position: lineData.line.from, id: `${idPrefix}-standalone-from`},
              {position: lineData.line.to, id: `${idPrefix}-standalone-to`}
            ]
          });
        }
      }
    }
  }

  _.forEach(containersData, containerData => {
    var container = containerData;
    var {wallprint, scribesData} = containerData;

    var wallprintLines = {
      left: {to: wallprint[0], from: wallprint[1]},
      right: {to: wallprint[2], from: wallprint[3]},
      top: {to: wallprint[1], from: wallprint[2]},
      bottom: {to: wallprint[3], from: wallprint[0]}
    };

    var isProjection = false; //TODO
    var projectionY = 0; //TODO

    var sideKey = Container.getSideKey({container, elevation, viewKey: 'front'});
    var containerPosition = Container.getPositionInElevation({container, elevation});

    if (!isSection || (isSection && !_.get(room, 'customData.preventVariousSectionDimensions'))) {
      if (sideKey === 'front' || container.type === 'countertop') {
        if (!_.includes(['endPanel', 'capPanel'], container.type)) {
          if (container.type !== 'countertop') {
            addLineForCandidateSideKeys({shouldGroup: true, wallprintLines, sideKeys: ['top', 'bottom'], containerData, container, idPrefix: `elevation-container-${container.id}-width`});
          }

          var dimId = `elevation-container-${container.id}-height`;
          if (container.type === 'countertop') dimId = container.id > 70804 ? `elevation-${elevation.id}-container-${container.id}-height` : `elevation-container-${container.id}-height`;

          addLineForCandidateSideKeys({shouldGroup: true, wallprintLines, sideKeys: ['left', 'right'], containerData, container, idPrefix: dimId});
        }

        if (_.includes(['vanity'], container.type)) {
          var addVanityWidthDimension = true;
          var children = Container.get('unmanagedProductInstances', {container});
          if (children) {
            _.forEach(children, child => {
              if (child.dimensions.width === container.dimensions.width) addVanityWidthDimension = false;
            })
          }

          if (addVanityWidthDimension) {
            dimensionSets.push({
              type: 'standalone',
              id: `elevation-vanity-container-${container.id}-width`,
              alpha: Math.PI,
              offset: -3,
              showLabelBorder: true,
              targets: [
                {position: lib.object.sum(Container.getPositionInElevation({container, elevation}), {x: 0, y: container.dimensions.height}), id: `elevation-container-${container.id}-vanity-width-from`},
                {position: lib.object.sum(Container.getPositionInElevation({container, elevation}), {x: container.dimensions.width, y: container.dimensions.height}), id: `elevation-container-${container.id}-vanity-width-to`}
              ]
            })
          }
        }

        var endPanelSideKeys = _.filter(['left', 'right'], sideKey => _.get(container, `customData.wrap.${sideKey}`));
        var wrapSizes = Container.getWrapSizes({container});

        if (container.type === 'endPanel') endPanelSideKeys = ['left'];

        var endPanelHeights = Container.getEndPanelHeights({container, scribesData});

        _.forEach(endPanelSideKeys, sideKey => { //end panel widths
          var wrapThickness = wrapSizes[sideKey];
          var panelHeight = _.sum(endPanelHeights[sideKey].visible);

          var x = sideKey === 'right' ? container.dimensions.width - wrapThickness : 0;
          var y = 0;

          var panelNodePoints = _.map([
            {x, y: y + panelHeight},
            {x, y},
            {x: x + wrapThickness, y},
            {x: x + wrapThickness, y: y + panelHeight},
          ], point => lib.object.sum(point, containerPosition, {y: container.dimensions.height - panelHeight}));

          var panelWallPrintLines = {
            left: {from: panelNodePoints[1], to: panelNodePoints[0]},
            top: {from: panelNodePoints[2], to: panelNodePoints[1]},
            right: {from: panelNodePoints[3], to: panelNodePoints[2]},
            bottom: {from: panelNodePoints[0], to: panelNodePoints[3]},
          };

          //HINT end panel dims are always standalone on their long side
          addLineForCandidateSideKeys({wallprintLines: panelWallPrintLines, sideKeys: _.includes(['floatingShelves', 'wall', 'cornerCounterTransition', 'wallFreestandingAppliance', 'wallPanel', 'wallUnitLiner'], container.type) ? ['top', 'bottom'] : ['bottom', 'top'], containerData, container, idPrefix: `elevation-container-${container.id}-end-panel-${sideKey}-width`});
          addLineForCandidateSideKeys({alwaysStandalone: container.type === 'endPanel', wallprintLines: panelWallPrintLines, sideKeys: ['left', 'right'], containerData, container, idPrefix: `elevation-container-${container.id}-end-panel-${sideKey}-height`});
        });

        if (_.get(container.customData, 'wrap.top') === 1) {
          var x, y, wrapHeight, wrapWidth;
          wrapHeight = wrapSizes['top'];
          x = (scribesData.left && !_.get(container.customData, 'wrap.left')) ? -scribesData.left.distance : wrapSizes.left;
          y = 0;
          wrapWidth = _.sum(Container.getCapPanelWidths({container}));

          var panelNodePoints = _.map([
            {x, y: y + wrapHeight},
            {x, y},
            {x: x + wrapWidth, y},
            {x: x + wrapWidth, y: y + wrapHeight},
          ], point => lib.object.sum(point, containerPosition));

          var capPanelWallPrintLines = {
            left: {from: panelNodePoints[1], to: panelNodePoints[0]},
            top: {from: panelNodePoints[2], to: panelNodePoints[1]},
            right: {from: panelNodePoints[3], to: panelNodePoints[2]},
            bottom: {from: panelNodePoints[0], to: panelNodePoints[3]},
          };

          addLineForCandidateSideKeys({alwaysStandalone: container.type === 'capPanel', wallprintLines: capPanelWallPrintLines, sideKeys: ['top', 'bottom'], containerData, container, idPrefix: `elevation-container-${container.id}-cap-panel-width${container.type === 'capPanel' ? '' : '-new'}`});
          addLineForCandidateSideKeys({shouldGroup: true, wallprintLines: capPanelWallPrintLines, sideKeys: ['left', 'right'], containerData, container, idPrefix: `elevation-container-${container.id}-cap-panel-height${container.type === 'capPanel' ? '' : '-new'}`});
        }

        var containerPositionInView = wallprint[0];

        var subcounterHeight = Container.getSubcounterHeight({container});

        //HINT expressed box vanities have 3/4" wrap that should be dimensioned like subcounter
        if (container.type === 'vanity' && container.customData.hasExpressedBox) subcounterHeight = 0.75;

        if (subcounterHeight) {
          var subcounterNodePoints = _.map([
            {x: 0, y: -container.dimensions.height + subcounterHeight},
            {x: 0, y: -container.dimensions.height},
            {x: container.dimensions.width, y: -container.dimensions.height},
            {x: container.dimensions.width, y: -container.dimensions.height + subcounterHeight},
          ], point => lib.object.sum(point, containerPositionInView));

          var subcounterWallPrintLines = {
            left: {from: subcounterNodePoints[1], to: subcounterNodePoints[0]},
            top: {from: subcounterNodePoints[2], to: subcounterNodePoints[1]},
            right: {from: subcounterNodePoints[3], to: subcounterNodePoints[2]},
            bottom: {from: subcounterNodePoints[0], to: subcounterNodePoints[3]},
          };

          addLineForCandidateSideKeys({shouldGroup: true, wallprintLines: subcounterWallPrintLines, sideKeys: ['left', 'right'], containerData, container, idPrefix: `elevation-container-${container.id}-subcounterHeight-height`});
        }

        var kickHeight = Container.getKickHeight({container});
        if (kickHeight && sideKey !== 'back') {
          var kickNodePoints = _.map([
            {x: 0, y: 0},
            {x: 0, y: -kickHeight},
            {x: container.dimensions.width, y: -kickHeight},
            {x: container.dimensions.width, y: 0},
          ], point => lib.object.sum(point, containerPositionInView));

          var kickWallPrintLines = {
            left: {from: kickNodePoints[1], to: kickNodePoints[0]},
            top: {from: kickNodePoints[2], to: kickNodePoints[1]},
            right: {from: kickNodePoints[3], to: kickNodePoints[2]},
            bottom: {from: kickNodePoints[0], to: kickNodePoints[3]},
          };

          addLineForCandidateSideKeys({shouldGroup: true, wallprintLines: kickWallPrintLines, sideKeys: ['left', 'right'], containerData, container, idPrefix: `elevation-container-${container.id}-kickHeight-height`});
        }
      }
    }

    //HINT will never be trapped since its on the floor
    if (!_.includes(['front', 'back'], sideKey) && container.type !== 'countertop') {
      if (container.position.y === 0) {
        addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines: [wallprintLines.bottom, wallprintLines.top], idPrefix: `${container.id}-width-section-${sideKey}`});
      }
      else {
        addLineForCandidateSideKeys({wallprintLines, sideKeys: ['bottom', 'top'], containerData, container, idPrefix: `${container.id}-width-section-${sideKey}`});
      }
    }

    if (_.includes(['islandSeating', 'islandBackPanel'], container.type) && sideKey === 'front') {
      var maxWidth = Container.getIslandBackPanelMaxPanelSize({container}) || 45;
      var widths = lib.number.evenlyDistributedDistancesFor({distance: Container.getInnerWidth({container}), maxDistance: maxWidth, precision: 1/16});
      var isSculpted = _.get(container, 'customData.panelStyle', 'panel') !== 'panel';

      if (!container.customData.panelsAreDisabled) {
        if (isSculpted) {
          //TODO
        }
        else {
          var y = 0;
          var x = Container.getWrapSizes({container}).left;
          var panelHeight = container.dimensions.height - ((container.type === 'islandSeating' && !container.customData.countertopSupportDisabled) ? 2 : 0);

          //HINT won't ever be trapped since its on the floor
          _.forEach(widths, (width, index) => {
            addLineToNearestSide({
              allPoints,
              outsideDimensionSets,
              idPrefix: `island-backpanel-${container.id}-panelwidth-${index}`,
              candidateLines: [
                {
                  from: lib.object.sum(Container.getPositionInElevation({container, elevation}), {x, y}),
                  to: lib.object.sum(Container.getPositionInElevation({container, elevation}), {x: x + width, y})
                }
              ]
            })

            x += width;
          });

        }
      }
    }
  });

  dimensionSets.push(...localTrappedGroups);
}
