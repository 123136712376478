import React from 'react';
import _ from 'lodash';
import K from 'k';

import Room from 'project-helpers/room';

import getMaterialOptionsForArchetypeParameter from 'helpers/get-material-options-for-archetype-parameter';
import { connect } from 'redux/index.js';

import ConfiguratorOptionSelector from './configurator-option-selector';
import Thumbnail from './thumbnail';

class ConfiguratorSelectorPropertyField extends React.PureComponent {
  getSelectedOption({value, path, type, options, hasOptionGroups}) {
    var {parameter, room} = this.props;
    var {id} = parameter;

    var selectedOption;

    var calcValue = _.get(room, `selectedParameters.${room.archetypeId}.${id}${path ? `.${path}` : ''}`) || _.cloneDeep(value);

    if (_.includes(['material', 'pullType', 'pullMaterial', 'conditionalResources'], type) && !hasOptionGroups) {
      selectedOption = _.find(options, {id: calcValue}) || _.find(options, {value: calcValue}) //|| options[0];
    }

    return selectedOption;
  }

  getOptionGroups({options}) {
    var {materialTypes} = this.props;

    return _.map(_.groupBy(options, 'materialTypeId'), (options, materialTypeId) => {
      return {title: _.get(materialTypes[materialTypeId], 'title', materialTypeId), options};
    });
  }

  getPullPropertyFields = ({inputType, noThumbnail, hasOptionGroups}) => {
    var {materialClasses, pulls, room, parameter} = this.props;
    var {id, title, type, options = []} = parameter;

    var pullTypeOptions = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullType'});

    //HINT render propertyFields for 'pullType' and 'pullMaterial' paths
    return _.map(['pullType', 'pullMaterial'], (path, index) => {
      if (path === 'pullType') {
        //TODO default pullType?
        return {
          id, path: 'pullType',
          title: `${title !== 'Pulls' ? `${title} ` : ''} Type`,
          type, options: pullTypeOptions,
          inputType, noThumbnail, hasOptionGroups
        };
      }
      else if (path === 'pullMaterial') {
        //HINT get compatible materials based on selected pull type
        var selectedPullTypeId = _.get(room, `selectedParameters.${room.archetypeId}.${id}.pullType`);// || _.get(pullTypeOptions, '[0].value');

        if (!selectedPullTypeId) return; //HINT prevents error when instances are empty

        var selectedPull = _.find(pulls, {id: selectedPullTypeId});
        var compatibleMaterials = _.get(materialClasses, `${selectedPull.materialClassId}.materials`);

        if (parameter.compatibleMaterialIds) { //HINT filter compatible materials to those set manually on the parameter
          compatibleMaterials = _.filter(compatibleMaterials, ({id}) => {
            return _.includes(parameter.compatibleMaterialIds, id);
          });
        }

        //HINT only show property field if there are compatible materials
        if (compatibleMaterials.length > 1) {
          options = _.map(compatibleMaterials, ({id, title, materialTypeId}) => {
            return {
              value: id, title,
              thumbnail: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/${id}.jpg`,
              materialTypeId
            };
          });

          return {
            id, path: 'pullMaterial',
            title: `${parameter.title !== 'Pulls' ? `${parameter.title} ` : ''} Material`,
            type, options, inputType, hasOptionGroups, noThumbnail: false
          };
        }
      }
    });
  };

  handlePropertyFieldOnClick = ({activePropertyKey, activePropertyData, appliedOption, archetypeParameterGroup}) => () => {
    this.props.handleClick({roomId: this.props.room.id, activePropertyKey, activePropertyData: {...activePropertyData, archetypeParameterGroup}, appliedOption});
  };

  render() {
    var {room, parameter, project, containers, products, activePropertyKey, isReviewing, alignLeft, archetypeParameterGroup} = this.props;
    var {id, title, type, options = [], instances} = parameter;

    var inputType = 'select'; //TODO handle other input types
    var noThumbnail = true;
    var hasOptionGroups = false;
    var propertyFields = [];

    //HINT don't render if this is a pull or material parameter applying to nothing in the room
    if (type === 'pullType' || type === 'pullMaterial' || type === 'material') {
      var containerInstancesPresent = _.some(instances, instance => instance.resourceKey === 'container' && _.find(containers, {persistentId: instance.resourcePersistentId}));
      var productInstancesPresent = _.some(instances, instance => instance.resourceKey === 'product' && _.find(products, {persistentId: instance.resourcePersistentId}));

      if (!containerInstancesPresent && !productInstancesPresent) {
        return null;
      }
    }

    if (type === 'pullType') {
      propertyFields = [this.getPullPropertyFields({inputType, noThumbnail, hasOptionGroups})[0]];
    }
    else if (type === 'pullMaterial') {
      propertyFields = [this.getPullPropertyFields({inputType, noThumbnail, hasOptionGroups})[1]];
    }
    else {
      if (type === 'material') {
        hasOptionGroups = true;
        noThumbnail = false;

        options = getMaterialOptionsForArchetypeParameter({parameter, room});
        propertyFields.push({
          id,
          title,
          type, options,
          inputType, noThumbnail, hasOptionGroups
        });
      }
      else if (type === 'conditionalResources') {
        noThumbnail = true;
        hasOptionGroups = false;

        propertyFields.push({
          id,
          title,
          type,
          options,
          inputType, noThumbnail, hasOptionGroups
        });
      }
    }

    var {room, isEditable} = this.props;

    var optionGroups = [];
    if (hasOptionGroups) {
      if (options.length < 7) {
        hasOptionGroups = false;
      }
      else {
        optionGroups = this.getOptionGroups({options});
      }
    }

    return _.map(propertyFields, (pf, i) => {
      if (!pf) return;

      var {path, type, options, title: pfTitle, id: pfId} = pf;
      var selectedOption = this.getSelectedOption({path, type, options, hasOptionGroups, optionGroups});
      var selectionMade = !!selectedOption;
      var thumbnailSrc = _.get(selectedOption, 'thumbnail');
      var thumbSize = 30;

      return (
        <div
          key={`propertyField-${type}-${i}-${path}`}
          className='hoverable-element'
          style={{display: 'flex', paddingRight: 0, borderRadius: 100, marginBottom: K.spacing, alignItems: 'center', ...(isEditable ? {cursor: 'pointer'} : {opacity: 0.5})}}
          onClick={isEditable ? this.handlePropertyFieldOnClick({
            activePropertyKey: pfId,
            activePropertyData: propertyFields.length > 1 ? _.find(propertyFields, {path}) : propertyFields[0],
            appliedOption: selectedOption,
            archetypeParameterGroup
          }) : () => alert('This file has been finalized.')}>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, ...((isReviewing || alignLeft) ? {} : {alignItems: 'flex-end'})}}>
            <div
              style={{marginBottom: 2, ...K.fonts.label, fontSize: '0.8em', textTransform: 'none', opacity: 0.5, letterSpacing: '0.02em', fontWeight: 500}}
            >
              {pfTitle}
            </div>
            <div
              style={{...K.fonts.label, fontSize: '0.8em', ...(selectionMade ? {} : {color: '#626FD0'}), textTransform: 'none', opacity: 1, letterSpacing: '0.02em', fontWeight: 500}}
            >{selectionMade ? _.get(selectedOption, 'title', '') : 'Select'}</div>
          </div>
          {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: thumbSize, width: thumbSize, minWidth: thumbSize, borderRadius: thumbSize / 2, boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.5)', overflow: 'hidden'}}>
            <Thumbnail key={`propertyField-${type}-${i}-${path}-thumbnail`} src={thumbnailSrc} style={{height: thumbSize, width: thumbSize, minWidth: thumbSize, backgroundColor: 'white', borderRadius: 20}}/>
          </div> */}
        </div>
      );
    });

  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {containers, products} = Room.get(['containers', 'products'], {room: ownProps.room});

    return {
      materialClasses: state.resources.materialClasses.byId,
      pulls: state.resources.pulls.byId,
      materialTypes: state.resources.materialTypes.byId,
      containers, products
    };
  },
  mapDispatch: {}
})(ConfiguratorSelectorPropertyField);
