import Product from 'project-helpers/product';
import K from 'k';

const getProductOptionIssues = ({products, project}) => {
  var issues = [];

  _.forEach(products, product => {
    var {allPotentialProductOptions = [], incompatibleProductOptions = [], currentlyCompatibleProductOptions = []} = Product.getCompatibleProductOptions({product});

    var enabledProductOptionsWithIssues = [];

    _.forEach(product.customData.productOptions, (value, key) => {
      var {enabled} = value;
      var excludedTrayDividerOptionIds = product.dimensions.width > 18 ? [128, 129] : [130, 131];

      if (enabled === 1) {
        var productOptionId = _.toNumber(key.replace('id_', ''));

        if (_.find(allPotentialProductOptions, {id: productOptionId})) {
            var invalidProductOption = !_.includes(excludedTrayDividerOptionIds, productOptionId) && _.find(incompatibleProductOptions, {id: productOptionId});

            if (invalidProductOption) {
              enabledProductOptionsWithIssues.push(invalidProductOption);
            }
        }
      }
    })

    _.forEach(enabledProductOptionsWithIssues, productOption => {
      //HINT automanaged product options handle compatibility automatically
      if (!_.includes(K.autoManagedProductOptionIds, productOption.productOptionId)) {
        var message = _.get(_.find(incompatibleProductOptions, {id: productOption.id}), 'message', 'product option incompatible with product');

        issues.push({
          id: `product-${product.id}-${_.join(_.split(_.lowerCase(message), ' '), '-')}`,
          message: `${product.productionId || Product.get('productType', {product}).title} - ${message}`,
          resourceKey: 'product',
          resourceId: product.id,
          type: 'product-option-compatibility',
          level: 1
        })
      }
    });
  })

  return issues;
};

export default getProductOptionIssues;
