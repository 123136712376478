import _ from 'lodash';
import K from 'k';
import CFG from 'k';
import lib from 'lib';
import getOrnamentTypes from './ornaments/ornaments.js';
import getArchElementTypes from './arch-elements/arch-elements.js';
import ContainerTypesManagedHelpersFor from './container-managed-products';
import ContainerTypesScriptHelpersFor from './container-scripts-helpers';
import Container from 'project-helpers/container/index';

var TypesFor = ({companyKey, isEmployee}) => {
  var sct = companyKey === 'hb' ? 0.75 : 0.5; //countertop, subcountertop, panel thickness
  var backsplashTopRailHeight = companyKey === 'hb' ? 11/16 : 0;
  //TODO flyby fronts
  //TODO materials on backs of islands`

  var {managedProducts} = ContainerTypesManagedHelpersFor({companyKey});

  var {script, countertopScriptFor} = ContainerTypesScriptHelpersFor({companyKey, isEmployee});

  var managedProductsForKeys = keys => _.pick(managedProducts, keys);

  //< resourceProps values and fields
  var defaultWrapValuesForSides = sides => {
    var defaultThickness = 3 / 4;
    var defaultWrapValue = {thickness: defaultThickness, topThickness: defaultThickness, bottomThickness: defaultThickness};

    _.forEach(sides, side => defaultWrapValue[side] = 1);

    return defaultWrapValue;
  };

  var wrapFieldsForSides = (sides, containerType = '') => {
    // var sculptedBoardOptions = [
    //   {value: 'panel', title: 'Panel'},
    //   {value: 'coved', title: 'Coved'},
    //   {value: 'singleGroove', title: 'Single Groove'},
    //   {value: 'doubleGroove', title: 'Double Groove'},
    //   {value: 'chamfered', title: 'Chamfered'},
    //   {value: 'fluted', title: 'Fluted'}
    // ];

    var fields = _.map(sides, sideKey => {
      var title = {
        left: 'Left End Panel',
        right: 'Right End Panel',
        bottom: 'Bottom Cap Panel',
        top: 'Top Cap Panel'
      }[sideKey];

      return {
        key: `customData.wrap.${sideKey}`,
        type: 'checkbox',
        title,
        options: [{value: 1, title: 'On'}, {value: 0, title: 'Off'}],
        views: _.includes(['bottom', 'top'], sideKey) ? ['front'] : ['top', 'front', `${sideKey}`],
        userLenses: ['sales', 'design', 'engineering']
      };
    });

    var employeeOnlyThicknesses = isEmployee ? [{value: 1.5, title: '1 1/2'}] : [];

    if (companyKey === 'vp' && containerType !== 'tall') employeeOnlyThicknesses.push({value: 3, title: '3'})

    fields.push(...[
      {
        key: 'customData.wrap.thickness',
        title: 'End Panel Thickness',
        type: 'select',
        noThumbnail: true,
        options: _.sortBy([
          {value: 0.25, title: '1/4'},
          {value: 0.50, title: '1/2'},
          {value: 0.75, title: '3/4'},
          ...(companyKey === 'vp' ? employeeOnlyThicknesses : []),
          ...(companyKey === 'hb' ? [{value: 0.375, title: '3/8'}, {value: 1.5, title: '1 1/2'}, {value: 4, title: 'bold'}] : [])
        ], 'value'),
        views: ['top', 'front'],
        userLenses: ['sales', 'design', 'engineering']
      },
      ...(companyKey === 'hb' ? [
        // {
        //   key: 'customData.wrap.overhang',
        //   title: 'Wrap Overhang',
        //   type: 'select',
        //   options: [{value: -0.75, title: 'Flyby'}, {value: 1/8, title: '1/8'}, {value: 1 + 3/8, title: '1 3/8'}],
        //   views: ['top']
        // },
        {
          key: 'customData.wrap.overhang',
          title: 'Wrap Overhang',
          type: 'size',
          minValue: -0.75,
          //options: [{value: -0.75, title: 'Flyby'}, {value: 1/8, title: '1/8'}, {value: 1 + 3/8, title: '1 3/8'}],
          views: ['top']
        }] : [{
        key: 'customData.wrap.overhang',
        title: 'Wrap Overhang',
        type: 'size',
        minValue: -0.75,
        //options: [{value: -0.75, title: 'Flyby'}, {value: 1/8, title: '1/8'}, {value: 1 + 3/8, title: '1 3/8'}],
        views: ['top']
      }]),
      ..._.map(sides, sideKey => {
        return {
          key: `customData.wrap.depths.${sideKey}`,
          type: 'size',
          title: `${_.startCase(sideKey)} ${_.includes(['top', 'bottom'], sideKey) ? 'Cap' : 'End'} Panel Depth`,
          views: ['top', sideKey],
          userLenses: ['sales', 'design', 'engineering']
        };
      }),
      {key: `customData.wrap.isSTMetalEndPanel`, title: `Panels are Metal`,
        type: 'checkbox', views: ['front', 'top', 'left', 'right', 'back'], userLenses: ['sales', 'design', 'engineering'],
      }
    ]);

    // if (!_.includes(['islandBackPanel', 'islandSeating'], containerType)) {
      _.forEach(['left', 'right'], side => {
        // fields.push({
        //   key: `customData.wrap.style.${side}`,
        //   title: `${_.startCase(side)} End Panel Style`,
        //   type: 'select',
        //   options: sculptedBoardOptions,
        //   views: ['front']
        // });

        fields.push({
          key: `customData.wrap.boldStyle.${side}`,
          title: `${_.startCase(side)} Bold Type`,
          type: 'radio',
          options: [{value: 'bold', title: 'bold'}, {value: 'radius', title: 'bold w radius'}],
          views: ['top', 'front'],
          userLenses: ['sales', 'design', 'engineering']
        });
      });

      // fields.push({
      //   key: 'customData.sculptedPanel.bottomCasingHeight',
      //   title: 'Sculpted Bottom Casing Height',
      //   type: 'size',
      //   views: ['front']
      // });

      // fields.push({
      //   key: 'customData.sculptedPanel.topRailHeight',
      //   title: 'Sculpted Top Rail Height',
      //   type: 'size',
      //   views: ['front']
      // });
    // }

    if (_.includes(['islandBackPanel', 'islandSeating'], containerType)) {
      // fields.push({
      //   key: 'customData.panelStyle',
      //   type: 'select',
      //   title: 'Back Panel Style',
      //   options: sculptedBoardOptions,
      //   views: ['front']
      // });

      // fields.push({
      //   key: 'customData.sculptedPanel.bottomCasingHeight',
      //   title: 'Sculpted Back Panel Bottom Casing Height',
      //   type: 'size',
      //   views: ['front']
      // });

      // fields.push({
      //   key: 'customData.sculptedPanel.topRailHeight',
      //   title: 'Sculpted Back Panel Top Rail Height',
      //   type: 'size',
      //   views: ['front']
      // });

      // fields.push({
      //   key: 'customData.sculptedPanel.isFlutedAdjacent',
      //   title: 'Is Fluted Adjacent Panel',
      //   type: 'checkbox',
      //   views: ['front']
      // });
    }

    if (_.includes(sides, 'top')) {
      fields.push({
        key: 'customData.wrap.topThickness',
        title: 'Top Panel Thickness',
        type: 'select',
        noThumbnail: true,
        options: [{value: 0.50, title: '1/2'}, {value: 0.75, title: '3/4'}, {value: 2.25, title: '2 1/4'}, ...employeeOnlyThicknesses],
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      });
    }

    if (_.includes(sides, 'bottom')) {
      fields.push({
        key: 'customData.wrap.bottomThickness',
        title: 'Bottom Panel Thickness',
        type: 'select',
        noThumbnail: true,
        options: [{value: 0.50, title: '1/2'}, {value: 0.75, title: '3/4'}, ...employeeOnlyThicknesses],
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      });
    }

    fields.push(..._.map(sides, sideKey => {
      return {
        key: `customData.wrap.isDashed.${sideKey}`,
        type: 'checkbox',
        title: `${_.startCase(sideKey)} ${_.includes(['top', 'bottom'], sideKey) ? 'Cap' : 'End'} Panel Is Dashed`,
        views: ['top', 'front', sideKey]
      };
    }));

    if (_.includes(sides, 'top') || _.includes(sides, 'bottom')) {
      fields.push({key: 'customData.wrap.capPanelHeights', title: 'Cap Panel Widths', type: 'text', views: ['front']});
    }

    if (_.includes(sides, 'left')) {
      fields.push({key: 'customData.customWrapBreaks.left', title: 'Left End Panel Breaks', type: 'text', views: ['front']});
      //HINT only an option for 1 1/2" end panels for HB

      if (companyKey === 'hb') {
        fields.push({key: 'customData.wrap.leftPanelRoundedSides', title: 'Left Rounded Sides',
          type: 'select', views: ['front', 'top'], userLenses: ['sales', 'design', 'engineering'],
          options: [{value: 'none', title: 'None'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}, {value: 'both', title: 'Both'}],
        });
        fields.push({key: 'customData.wrap.boldStyle.leftPanelRoundedSides', title: 'Left Rounded Sides',
          type: 'select', views: ['front', 'top'], userLenses: ['sales', 'design', 'engineering'],
          options: [{value: 'both', title: 'Both'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
        });
      }
    }

    if (_.includes(sides, 'right')) {
      fields.push({key: 'customData.customWrapBreaks.right', title: 'Right End Panel Breaks', type: 'text', views: ['front']});
      //HINT only an option for 1 1/2" end panels for HB
      if (companyKey === 'hb') {
        fields.push({key: 'customData.wrap.rightPanelRoundedSides', title: 'Right Rounded Sides',
          type: 'select', views: ['front', 'top'], userLenses: ['sales', 'design', 'engineering'],
          options: [{value: 'none', title: 'None'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}, {value: 'both', title: 'Both'}],
        });
        fields.push({key: 'customData.wrap.boldStyle.rightPanelRoundedSides', title: 'Right Rounded Sides',
          type: 'select', views: ['front', 'top'], userLenses: ['sales', 'design', 'engineering'],
          options: [{value: 'both', title: 'Both'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
        });
      }
    }

    return fields;
  };

  var flybyFrontFieldsFor = (wrapAxes) => {
    var defaultFlybyValue = {};

    _.forEach(wrapAxes, wrapAxis => defaultFlybyValue[wrapAxis] = 0);

    return defaultFlybyValue;
  };

  var flybyFronts = (wrapAxes) => {
    var fields = _.map(wrapAxes, (wrapAxisKey) => {
      var title = {
        end: 'End Flyby Fronts',
        cap: 'Cap Flyby Fronts'
      }[wrapAxisKey];

      return {
        key: `customData.flyby.${wrapAxisKey}`,
        type: 'checkbox',
        title,
        // options: [{value: 1, title: 'On'}, {value: 0, title: 'Off'}],
        views: ['front'], // TODO
        tooltipData: CFG.editorTooltipData.flybyFronts
      };
    });
    return fields;
  };

  var insetKickFieldsFor = (sides) => {
    var defaultInsetKickValues = {};

    _.forEach(sides, side => defaultInsetKickValues[side] = 0);

    return defaultInsetKickValues;
  };

  var insetKick = (sides) => {
    var fields = _.map(sides, (sideKey) => {
      var title = {
        left: 'Left Inset Kick',
        right: 'Right Inset Kick'
      }[sideKey];

      return {
        key: `customData.insetKick.${sideKey}`,
        type: 'checkbox',
        title,
        views: ['front'],
        tooltipData: CFG.editorTooltipData.insetKick
      };
    });

    return fields;
  };

  var curriedConstraintsFor = ({withVariedUnitDepth = false, hasKick = false, hasSubcounter = false, constraints: cachedConstraints = {}, addWallSpacing = false, shouldDynamicallyConstrain}) => {
    return ({container}) => {
      var kickHeight = Container.getKickHeight({container});
      var noWallSpacing = _.get(container, 'customData.noWallSpacing', 0) || _.get(container, 'customData.wallSpacing') === 'none';
      var subcounterHeight = Container.getSubcounterHeight({container});
      var defaultConstraints = {};
      var wrapSizes = Container.getWrapSizes({container});
      var hasWallOrVolume = container.scopeId ? (Container.getWall({container}) || Container.getVolume({container})) : undefined;
      var unitDepth = _.toNumber(_.get(container, 'customData.unitDepth', 0));

      if (shouldDynamicallyConstrain && container.dimensions) {
        var maxDim = _.max([cachedConstraints.width.max, cachedConstraints.height.max]);
        var smallerSideDim = _.min([cachedConstraints.width.max, cachedConstraints.height.max]);

        cachedConstraints.width.max = container.dimensions.height > smallerSideDim ? smallerSideDim : maxDim;
        cachedConstraints.height.max = container.dimensions.width > smallerSideDim ? smallerSideDim : maxDim;
      }

      //TODO support 3/4 subcounter for non expressed box vanities
      if (companyKey === 'hb' && container.type === 'vanity') {
        if (_.get(container, 'customData.hasExpressedBox')) {
          cachedConstraints.height = {fixed: [16.5625, 21.625, 25.375]};
          cachedConstraints.depth = {fixed: [19.875, 24, 26.5]};
        }
        else {
          cachedConstraints.height = {fixed: [5.8125 + subcounterHeight, 14.9375 + subcounterHeight, 20 + subcounterHeight, 23.75 + subcounterHeight,]};
          cachedConstraints.depth = {fixed: [18.5, 22.625, 25.125]};
        }

        subcounterHeight = 0;
      }

      var constraints = _.mapValues(cachedConstraints, (constraint, sizeKey) => {
        var newConstraint;

        if (sizeKey === 'height' && container.type === 'islandSeating' && _.get(container, 'customData.panelsAreDisabled')) {
          newConstraint = {min: 1, max: 100, step: 0.125};
        }
        else if (companyKey === 'vp' && kickHeight === 0.75 && _.includes(['base', 'baseWithChase'], container.type) && sizeKey === 'height') {
          newConstraint = {min: 13, max: 40, step: 0.25};
        }
        else {
          newConstraint = _.mapValues(constraint, (value, key) => {
            if (sizeKey === 'height') {
              if (key === 'fixed') value = _.map(value, v => v + kickHeight + subcounterHeight);
              if (_.includes(['min', 'max'], key)) value += kickHeight + subcounterHeight;
            }

            var adjustValue = value => {
              if (sizeKey === 'height') value += wrapSizes.y;
              if (sizeKey === 'width') value += wrapSizes.x;
              if (key === 'min' && sizeKey === 'depth' && withVariedUnitDepth) value = _.max([value, unitDepth]);

              return value;
            };

            if (key === 'fixed') value = _.map(value, adjustValue);
            if (_.includes(['min', 'max'], key)) value = adjustValue(value);

            return value;
          });
        }

        return newConstraint;
      });

      if (companyKey === 'hb' && _.includes(['tall', 'base'], container.type) && container.customData.containerConstraintType === 'wardrobe') {
        constraints.depth.fixed = _.filter(constraints.depth.fixed, value => value !== 9.25);
      }

      if (addWallSpacing && !noWallSpacing) {
        var hasWallSpacing = _.get(container, 'customData.wallSpacing') === 'cleat' || hasWallOrVolume;

        var spacing = hasWallSpacing ? 0.5 : 0;

        constraints.depth.fixed = _.map(constraints.depth.fixed, v => v + spacing);
      }

      return _.defaultsDeep(constraints, defaultConstraints);
    };
  };

  var getDropzoneFunctions = ({hasKick = false, hasSct = false, getCustomInsets}) => {
    return {
      getDropzoneInset: ({container, viewKey}) => {
        var customInsets = {left: 0, bottom: 0, right: 0, top: 0};
        var y;

        if (getCustomInsets) customInsets = {...customInsets, ...getCustomInsets({viewKey, container})};

        // var s = hasSct ? (_.get(container, 'customData.customSubcounterHeight') || _.get(container, 'customData.subcounterHeight', sct)) : 0;
        var noWallSpacing = _.get(container, 'customData.noWallSpacing', 0) || _.get(container, 'customData.wallSpacing') === 'none';

        var wrapSizes = Container.getWrapSizes({container});
        var kickHeight = Container.getKickHeight({container});

        if (viewKey === 'front') {
          y = -1 * (kickHeight + wrapSizes.bottom);
        }
        else {
          y = (_.get(container, 'customData.wallSpacing') === 'cleat' || (Container.getHasCleat({container}) && !noWallSpacing)) ? 0.5 : 0;

          if (container.type === 'baseWithChase') y = container.dimensions.depth - container.customData.unitDepth;

          if (container.type === 'wallPanel') y = container.customData.cleatDepth || 0;
        }

        return {
          x: container.type === 'backsplash' ? 0 : wrapSizes.left + customInsets.left,
          y: container.type === 'backsplash' ? 0 : y - customInsets.bottom
        };
      },
      getDropzoneSize: ({container, viewKey}) => {
        var customInsets = {left: 0, bottom: 0, right: 0, top: 0};

        if (getCustomInsets) customInsets = {...customInsets, ...getCustomInsets({viewKey, container})};

        var s = hasSct ? (_.get(container, 'customData.customSubcounterHeight') || _.get(container, 'customData.subcounterHeight', sct)) : 0;
        var wrapSizes = Container.getWrapSizes({container});
        var kickHeight = Container.getKickHeight({container});
        var noWallSpacing = _.get(container, 'customData.noWallSpacing', 0) || _.get(container, 'customData.wallSpacing') === 'none';
        var hasWallSpacing = _.get(container, 'customData.wallSpacing') === 'cleat' || (Container.getHasCleat({container}) && !noWallSpacing);
        var dims = container.dimensions;
        var height;

        if (viewKey === 'front') {
          height = container.type === 'backsplash' ?
            dims.height - backsplashTopRailHeight :
            dims.height - kickHeight - s - wrapSizes.y;
        }
        else {
          height = dims.depth - (hasWallSpacing ? 0.5 : 0);
        }

        return {width: dims.width - wrapSizes.x - customInsets.left - customInsets.right, height: height - customInsets.top - customInsets.bottom};
      }
    };
  };

  //> resourceProps values and fields
  var types = {
    custom: {
      title: 'Custom',
      type: 'custom',
      thumbnail: require('assets/container-icons/custom-icon.png'),
      companies: ['hb'],
      productCategoryIds: () => [],
      viewKeys: ['top', 'front'],

      constraintsFor: curriedConstraintsFor({
        height: {min: 0.125, max: 500, step: 0.125},
        width: {min: 0.125, max: 500, step: 0.125},
        depth: {min: 0.125, max: 500, step: 0.125}
      }),
      ...getDropzoneFunctions({}),
      resourceProps: {
        dimensions: {width: 10, height: 10, depth: 10},
        customData: {scripts: lib.object.fromKeys(['front', 'top', 'back', 'left', 'right'], () => `group({}, [
rect({isFillable: true, isDimensioned: true})
])`)}
      },
      scripts: lib.object.fromKeys(['front', 'top', 'back', 'left', 'right'], (sideKey) => {
        return `
          var customScript = _.getCustomScript && _.getCustomScript();

          var children = [];

          if (customScript) {
            try {
              children.push(eval(customScript));
            }
            catch (error) {
              console.error(error);
            }
          }

          ${sideKey === 'top' ? `
            ${script.frontIndicator}

            children.push(...frontIndicator());
          ` : ''}

          group({}, children);
        `;
      }),
      editablePropFieldSets: [

      ],
      wrappableSides: []
    },
    base: {
      title: 'Base Units',
      type: 'base',
      categoryPriorityKeywords: ['base', 'wardrobe'],
      thumbnail: require('assets/container-icons/base-container-icon.png'),
      snapToFloor: true,
      productCategoryIds: ({project}) => {
        var ids = [1, 2, 3, 4, 5, 69, 57, 35, 64];

        if (project.companyKey === 'hb') {
          ids.push(...[16, 17, 18, 19, 20, 21, 22, 23, 49, 52, 80, 79]);

          //HINT floating base units
          ids.push(...[6, 7, 8, 9, 10, 70]);
        }
        else if (isEmployee) {
          ids.push(49);
        }

        return ids;
      },
      topView: false,
      excludedProductIds: [1642],
      constraintsFor: curriedConstraintsFor({hasKick: true, hasSubcounter: true, addWallSpacing: true, constraints: {
        height: {...(companyKey === 'hb' ? {min: 5.875, max: 60, step: 0.125} : {fixed: [13, 16, 28, 30]})}, //fixed: companyKey === 'hb' ? [13, 21, 24, 27, 28, 30, 33, 33.25, 33.75, 36, 39, 42] : [28, 30]},
        width: {min: companyKey === 'hb' ? 6.125 : 8, max: 500, step: companyKey === 'hb' ? 0.125 : 0.25},
        depth: {fixed: [24.625, 14.375, 12.875, 18, 9.25, ...(companyKey === 'hb' ? [30] : [])]}
      }}),
      ...getDropzoneFunctions({hasKick: true, hasSct: true}),
      resourceProps: {
        customData: {preventAutoGeneration: true, wrap: defaultWrapValuesForSides(['left', 'right']), flyby: flybyFrontFieldsFor(['end']), insetKick: insetKickFieldsFor(['left', 'right']), kickHeight: 4.5, subcounterHeight: companyKey === 'hb' ? 0.75 : 0.5},
        dimensions: {width: 30, height: companyKey === 'hb' ? 35.25 : 35, depth: 24.625}
      },
      scripts: {
        front: script.forWrappedContainer({containerType: 'base', hasKick: true, hasSubcounter: true}),
        top: script.forWrappedContainer({containerType: 'base', sideKey: 'top'}),
        back: script.forWrappedContainer({containerType: 'base', sideKey: 'back', hasKick: true}),
        left: script.forWrappedContainer({containerType: 'base', sideKey: 'left', hasKick: true, hasSubcounter: true}),
        right: script.forWrappedContainer({containerType: 'base', sideKey: 'right', hasKick: true, hasSubcounter: true})
      },
      managedProducts: managedProductsForKeys(['leftWrapPanel', 'rightWrapPanel', 'kick', 'scribe', 'autofilledStorage']),
      editablePropFieldSets: [wrapFieldsForSides(['left', 'right'], 'base'), flybyFronts(['end']), insetKick(['left', 'right'])],
      wrappableSides: ['left', 'right'],
    },
    baseWithChase: {
      title: 'Base Unit with Chase',
      type: 'baseWithChase',
      categoryPriorityKeywords: ['base', 'wardrobe'],
      thumbnail: require('assets/container-icons/base-container-icon.png'),
      snapToFloor: true,
      productCategoryIds: ({project}) => {
        var ids = [1, 2, 3, 4, 5, 69, 57, 35, 64];

        if (project.companyKey === 'hb') {
          ids.push(...[16, 17, 18, 19, 20, 21, 22, 23, 49, 52, 79, 80]);

          //HINT floating base units
          ids.push(...[6, 7, 8, 9, 10, 70]);
        }
        else if (isEmployee) {
          ids.push(49);
        }

        return ids;
      },
      topView: false,
      excludedProductIds: [1642],
      constraintsFor: curriedConstraintsFor({withVariedUnitDepth: true, hasKick: true, hasSubcounter: true, addWallSpacing: true, constraints: {
        height: {...(companyKey === 'hb' ? {min: 5.875, max: 60, step: 0.125} : {fixed: [28, 30]})}, //fixed: companyKey === 'hb' ? [13, 21, 24, 27, 28, 30, 33, 33.25, 33.75, 36, 39, 42] : [28, 30]},
        width: {min: 12, max: 500, step: 0.125},
        depth: {min: 9.25, max: 500, step: 0.125}
      }}),
      ...getDropzoneFunctions({hasKick: true, hasSct: true}),
      resourceProps: {
        customData: {
          wrap: defaultWrapValuesForSides(['left', 'right']),
          flyby: flybyFrontFieldsFor(['end']),
          insetKick: insetKickFieldsFor(['left', 'right']),
          kickHeight: 4.5,
          unitDepth: 24.625,
          preventAutoGeneration: true,
        },
        dimensions: {width: 12, height: companyKey === 'hb' ? 35.25 : 35, depth: 28.625}
      },
      scripts: {
        front: script.forWrappedContainer({containerType: 'baseWithChase', hasKick: true, hasSubcounter: true}),
        top: script.forWrappedContainer({containerType: 'baseWithChase', withChase: true, sideKey: 'top'}),
        back: script.forWrappedContainer({containerType: 'baseWithChase', sideKey: 'back', hasKick: true}),
        left: script.forWrappedContainer({containerType: 'baseWithChase', withChase: true, sideKey: 'left', hasKick: true, hasSubcounter: true}),
        right: script.forWrappedContainer({containerType: 'baseWithChase', withChase: true, sideKey: 'right', hasKick: true, hasSubcounter: true})
      },
      managedProducts: managedProductsForKeys(['leftWrapPanel', 'rightWrapPanel', 'kick', 'scribe', 'autofilledStorage']),
      editablePropFieldSets: [
        wrapFieldsForSides(['left', 'right'], 'baseWithChase'),
        flybyFronts(['end']),
        insetKick(['left', 'right']),
        [{key: 'customData.unitDepth', title: 'Unit Depth', type: 'radio', noThumbnail: true, views: ['top'], userLenses: ['design', 'sales', 'engineering'], options: [{value: '9.25', title: '9.25'}, {value: '12.875', title: '12.875'}, {value: '14.375', title: '14.375'}, {value: '18', title: '18'}, {value: '24.625', title: '24.625'}, ...(companyKey === 'hb' ? [{value: '30', title: '30'}] : [])]}]
      ],
      wrappableSides: ['left', 'right'],
    },
    floatingBase: {
      title: 'Floating Base Units',
      type: 'floatingBase',
      categoryPriorityKeywords: ['floating base', 'wardrobe'],
      thumbnail: require('assets/container-icons/floating-base-icon.png'),
      snapToWall: true,
      productCategoryIds: ({project}) => {
        var ids = [6, 7, 8, 9, 10, 70, 35];

        if (project.companyKey === 'hb') {
          ids.push(...[16, 17, 18, 19, 20, 21, 22, 23, 49, 79, 52, 80]);
        }

        return ids;
      },
      resourceProps: {
        // dimensions: {width: 31, height: box + sct, depth: 25.125},
        customData: {preventAutoGeneration: true, wrap: defaultWrapValuesForSides([]), flyby: flybyFrontFieldsFor(['end', 'cap']), insetKick: insetKickFieldsFor(['left', 'right'])}
      },
      constraintsFor: curriedConstraintsFor({addWallSpacing: true, hasSubcounter: true, constraints: {
        height: companyKey === 'hb' ? [12, 13, 21, 23.75, 24, 27, 28, 30, 33, 36, 39, 42] : {fixed: [23.75]},
        width: {min: 12, max: 500, step: companyKey === 'hb' ? 0.125 : 0.25},
        depth: companyKey === 'hb' ? {fixed: [14.375, 18, 24.625, 30]} : {fixed: [24.625, 14.375, 18, 9.25, 12.875]}
      }}),
      scripts: {
        front: script.forWrappedContainer({containerType: 'floatingBase', sideKey: 'front', hasSubcounter: true}),
        top: script.forWrappedContainer({containerType: 'floatingBase', sideKey: 'top'}),
        left: script.forWrappedContainer({containerType: 'floatingBase', sideKey: 'left', hasSubcounter: true}),
        right: script.forWrappedContainer({containerType: 'floatingBase', sideKey: 'right', hasSubcounter: true})
      },
      ...getDropzoneFunctions({hasKick: false, hasSct: true}),
      managedProducts: managedProductsForKeys(['leftWrapPanel', 'rightWrapPanel', 'bottomWrapPanel', 'scribe', 'autofilledStorage']),
      editablePropFieldSets: [wrapFieldsForSides(['left', 'right', 'bottom']), flybyFronts(['end', 'cap'])],
      wrappableSides: ['left', 'right', 'bottom']
    },
    vanity: {
      title: 'Vanity',
      type: 'vanity',
      categoryPriorityKeywords: ['vanity'],
      thumbnail: require('assets/container-icons/vanity-container-icon.png'),
      snapToWall: true,
      productCategoryIds: () => companyKey === 'vp' ? [37] : [195, 49, 79],
      resourceProps: {
        ...(companyKey === 'vp' ? {
          dimensions: {width: 12, height: 24.25, depth: 22.625}
        } : {}),
        // dimensions: {width: 31, height: companyKey === 'vp' ? (23.75 + 0.75) : 15.1875, depth: 25.125},
        customData: {
          wrap: defaultWrapValuesForSides([]),
          flyby: flybyFrontFieldsFor(['end', 'cap']),
          insetKick: insetKickFieldsFor(['left', 'right']),
          subcounterHeight: companyKey === 'vp' ? 1/2 : 1 / 4,
          hasWoodDrawers: 0,
          hasExpressedBox: 0,
          expressedBoxThickness: 3 / 4,
          bayCount: 5
        }
      },
      constraintsFor: curriedConstraintsFor({hasSubcounter: companyKey === 'vp', constraints: companyKey === 'vp' ? ({
        height: {fixed: [23.75]},
        width: {min: 12, max: 500, step: 0.25},
        depth: {fixed: [18.5, 22.625]}
      }) : ({
        height: {fixed: [5.8125, 14.9375, 16.5625, 20, 21.625, 23.75, 25.375]},
        width: {min: 18, max: 95, step: 0.125},
        depth: {fixed: [18.5, 19.875, 22.625, 24, 25.125, 26.5]}
      })}),
      scripts: {
        front: script.forWrappedContainer({containerType: 'vanity', sideKey: 'front', hasSubcounter: companyKey === 'vp', innerChildrenScript: companyKey === 'vp' ? null : `
          (() => {
            var children = [];
            var {hasWoodDrawers, hasExpressedBox, expressedBoxThickness=0.75} = _.props.customData;

            //Rendered separately
            // if (hasWoodDrawers) {
            //   children.push(text({text: 'WD', top: '100% - 3', left: '100% - 3', fontSize: 11/3, opacity: 0.5, origin: {x: 'right', y: 'bottom'}}));
            // }

            if (hasExpressedBox) {
              children.push(group({top: 0}, [
                rect({isHatchFillable: true, hatchKey: 'boxMaterial', height: expressedBoxThickness, width: '100% - ' + expressedBoxThickness * 2, top: 0, left: expressedBoxThickness}),
                rect({isHatchFillable: true, hatchKey: 'boxMaterial', height: '100%', width: expressedBoxThickness}),
                rect({isHatchFillable: true, hatchKey: 'boxMaterial', height: '100%', width: expressedBoxThickness, left: '100% - ' + expressedBoxThickness}),
                rect({isHatchFillable: true, hatchKey: 'boxMaterial', height: expressedBoxThickness, width: '100% - ' + expressedBoxThickness * 2, top: '100% - ' + expressedBoxThickness, left: expressedBoxThickness})
              ]));
            }
            else {
              children.push(
                ${script.subcounter()}
              );
            }

            return children;
          })()
        `}),
        top: script.forWrappedContainer({containerType: 'vanity', sideKey: 'top'}),
        left: script.forWrappedContainer({containerType: 'vanity', sideKey: 'left', hasSubcounter: true}),
        right: script.forWrappedContainer({containerType: 'vanity', sideKey: 'right', hasSubcounter: true})
      },
      ...getDropzoneFunctions({hasKick: false, hasSct: companyKey === 'vp', getCustomInsets: ({container, viewKey}) => {
        var {expressedBoxThickness, hasExpressedBox = false} = container.customData;

        return companyKey === 'vp' ? {} : (hasExpressedBox ? {left: expressedBoxThickness, bottom: viewKey === 'top' ? 0 : expressedBoxThickness, right: expressedBoxThickness, top: viewKey === 'top' ? 0 : expressedBoxThickness} : {top: viewKey === 'front' ? Container.getSubcounterHeight({container}) : 0});
      }}),
      managedProducts: managedProductsForKeys(['scribe', 'vanity', ...(companyKey === 'vp' ? ['leftWrapPanel', 'rightWrapPanel', 'bottomWrapPanel'] : [])]),
      editablePropFieldSets: [
        ...(companyKey === 'vp' ? [
          wrapFieldsForSides(['left', 'right', 'bottom']),
          flybyFronts(['end', 'cap']),
        ] : []),
        [
          {
            key: 'customData.hasWoodDrawers',
            type: 'checkbox',
            title: 'Has Wood Drawers',
            views: ['front', 'top'],
            userLenses: ['design', 'sales', 'engineering'],
          },
          {
            key: 'customData.hasExpressedBox',
            type: 'checkbox',
            title: 'Has Expressed Box',
            views: ['front', 'top'],
            userLenses: ['design', 'sales', 'engineering'],
          },
          // {
          //   key: 'customData.expressedBoxThickness',
          //   type: 'radio',
          //   title: 'Expressed Box Thickness',
          //   options: [{value: 3 / 4, title: '3/4'}],
          //   views: ['front', 'top']
          // },
        ]
      ],
      wrappableSides: ['left', 'right', 'bottom']
    },
    countertop: {
      title: 'Countertop',
      type: 'countertop',
      thumbnail: require('assets/container-icons/countertop-container-icon.png'),
      resourceProps: {
        dimensions: {width: 31, height: 0.5, depth: 25.125},
        customData: {
          isByOthers: 0,
          inManualMode: true,
          material: 'acrylic',
          sinks: {}
        }
      },
      constraintsFor: () => ({
        width: {min: 0.75, max: 500, step: 0.125},
        depth: {min: 0.75, max: 500, step: 0.125},
        height: {min: 0.5, max: 10, step: companyKey === 'hb' ? 0.125 : 0.25}
      }),
      scripts: {
        left: countertopScriptFor({sideKey: 'left'}),
        right: countertopScriptFor({sideKey: 'right'}),
        front: countertopScriptFor({sideKey: 'front'}),
        back: countertopScriptFor({sideKey: 'back'}),
        top: countertopScriptFor({sideKey: 'top'})
      },
      managedProducts: managedProductsForKeys(['countertop']),
      wrappableSides: []
    },
    tall: {
      title: 'Tall Units',
      type: 'tall',
      categoryPriorityKeywords: ['tall', 'wardrobe'],
      thumbnail: require('assets/container-icons/tall-container-icon.png'),
      viewKeys: ['front'],
      excludedProductIds: [1642],
      snapToFloor: true,
      productCategoryIds: ({project}) => {
        var ids = [11, 12, 13, 55, 56, 58, 64, 67, 68];

        if (project.companyKey === 'hb' || isEmployee) {
          ids.push(1, 2, 3, 4, 5, 69, 57); //HINT include base units
          ids.push(32, 33, 67, 71, 72, 73, 35, 48); //HINT wardrobe
          ids.push(16, 17, 18, 19, 20, 22, 23);
          ids.push(49, 80); //HINT wall panels

          if (project.companyKey === 'hb') ids.push(79, 6) //scribes and floating base drawer
        }

        return ids;
      },
      resourceProps: {
        dimensions: {width: 31, height: 94.5, depth: 25.125},
        customData: {preventAutoGeneration: true, wrap: defaultWrapValuesForSides(['left', 'right', 'top']), flyby: flybyFrontFieldsFor(['end', 'cap']), insetKick: insetKickFieldsFor(['left', 'right']), kickHeight: 4.5}
      },
      constraintsFor: curriedConstraintsFor({hasKick: true, addWallSpacing: true, constraints: {
        height: {min: companyKey === 'hb' ? 50 : 60, max: companyKey === 'hb' ? 150 : 140, step: companyKey === 'hb' ? 0.125 : 0.25},
        width: {min: companyKey === 'hb' ? 6.125 : 12, max: 500, step: companyKey === 'hb' ? 0.125 : 0.25},
        depth: {fixed: [9.25, 12 + 7 / 8, 14 + 3 / 8, 18, 24 + 5 / 8, 26.1875, ...(companyKey === 'hb' ? [30] : [])]}
      }}),
      ...getDropzoneFunctions({hasKick: true}),
      scripts: {
        front: script.forWrappedContainer({containerType: 'tall', sideKey: 'front', hasKick: true}),
        top: script.forWrappedContainer({containerType: 'tall', sideKey: 'top'}),
        back: script.forWrappedContainer({containerType: 'tall', sideKey: 'back', hasKick: true}),
        left: script.forWrappedContainer({containerType: 'tall', sideKey: 'left', hasKick: true}),
        right: script.forWrappedContainer({containerType: 'tall', sideKey: 'right', hasKick: true})
      },
      managedProducts: managedProductsForKeys(['leftWrapPanel', 'rightWrapPanel', 'topWrapPanel', 'kick', 'scribe', 'autofilledStorage']),
      editablePropFieldSets: [wrapFieldsForSides(['left', 'right', 'top']), flybyFronts(['end', 'cap']), insetKick(['left', 'right']),
        [{key: 'customData.noWallSpacing', title: 'Wall Spacing', type: 'radio', views: ['top'], options: [{title: 'Normal', value: 0}, {title: 'None', value: 1}]}]
      ],
      wrappableSides: ['left', 'right', 'top']
    },
    wall: {
      title: 'Wall Units',
      type: 'wall',
      categoryPriorityKeywords: ['wall', 'wardrobe'],
      thumbnail: require('assets/container-icons/wall-container-icon.png'),
      snapToWall: true,
      excludedProductIds: [1642],
      viewKeys: ['front'],
      managedProducts: managedProductsForKeys(['scribe', 'autofilledStorage']),
      scripts: {
        top: `
          var wrapSizes = {left: 0, right: 0, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'top', containerType: 'wall'})}

          var productFrontPathPoints = _.childFrontPathPoints();

          var hasProducts = productFrontPathPoints.length > 0;

          var isSchematic = _.activeDetailLevel === 'schematic';

          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({stroke: ''}),
            (!hasProducts || isSchematic) ? rect({dashed: true, isFillable: isSchematic, className: 'dropzone'}) : path({dashed: true}, productFrontPathPoints),
            ...scribes,
            ...indicatorShapes
          ])`,
        front: `
          var lights = [];
          var wrapSizes = {left: 0, right: 0, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'front', containerType: 'wall'})}

          ${script.lightingFor({sideKey: 'front'})}

          group({}, [rect({isFillable: _.activeDetailLevel === 'schematic', dashed: true, className: 'dropzone'}), ...lights, ...scribes])`,
        left: `
          var lights = [];
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'left'})}

          ${script.lightingFor({sideKey: 'left'})}

          group({}, [rect({...(_.getHasProducts() ? {stroke: ''} : {})}), ...lights, ...scribes])
        `,
        right: `
          var lights = [];
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'right'})}

          ${script.lightingFor({sideKey: 'right'})}

          group({}, [rect({...(_.getHasProducts() ? {stroke: ''} : {})}), ...lights, ...scribes])
        `
      },
      productCategoryIds: ({project}) => {
        var ids = [16, 17, 18, 19, 20, 21, 22, 23, 52];

        if (project.companyKey === 'hb' || isEmployee) {
          ids.push(48, 49, 52, 55, 56, 68, 79, 80); //HINT 49 = wall panels, others = top pantry units, scribes
        }

        return ids;
      },
      constraintsFor: () => ({
        height: {min: companyKey === 'hb' ? 2.5 : 8, max: 500, step: companyKey === 'hb' ? CFG.productPrecision : 1 / 4},
        width: {min: 8, max: 500, step: companyKey === 'hb' ? CFG.productPrecision : 1 / 4},
        //HINT updated to allow for deep cap panels
        depth: {...(companyKey === 'hb' ? {min: 1.5, max: 47/*25 + 1.8*/, step: 1 / 16} : {fixed: [8.5, 9.25, 13.25, 14 + 7 / 8, 18.5, 25 + 1 / 8]})}
      }),
      resourceProps: {
        customData: {preventAutoGeneration: true, hasLighting: 0, lightingType: 'linear'},
        dimensions: {width: 60, height: 30, depth: 14 + 7 / 8}
      },
      editablePropFieldSets: [
      ],
      wrappableSides: []
    },
    cornerCounterTransition: {
      title: 'Corner Counter Transition',
      type: 'cornerCounterTransition',
      categoryPriorityKeywords: ['corner'],
      thumbnail: require('assets/container-icons/counter-corner-transition-icon.png'),
      snapToWall: true,
      viewKeys: ['front'],
      managedProducts: managedProductsForKeys(['scribe', 'autofilledStorage']),
      scripts: {
        top: `
          var wrapSizes = {left: 0, right: 0, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'top', containerType: 'wall'})}

          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({dashed: true, isFillable: true}),
            ...scribes,
            ...indicatorShapes
          ])`,
        front: `
          var wrapSizes = {left: 0, right: 0, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'front', containerType: 'wall'})}

          group({}, [rect({isFillable: true}), ...scribes])`,
        left: `
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'left'})}

          group({}, [rect({isFillable: true}), ...scribes])
        `,
        right: `
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'right'})}

          group({}, [rect({isFillable: true}), ...scribes])
        `
      },
      productCategoryIds: ({project}) => {
        var ids = [16, 17, 18, 19, 20, 22, 23];

        if (project.companyKey === 'hb' || isEmployee) {
          ids.push(49, 79, 80); //HINT wall panels
        }

        return ids;
      },
      constraintsFor: () => ({
        height: {min: 16, max: 95, step: CFG.productPrecision},
        width: {min: 12, max: 42, step: CFG.productPrecision},
        depth: {fixed: [12 + 7 / 8 + 1 / 2, 14 + 3 / 8 + 1 / 2, 18 + 1 / 2, 24 + 5 / 8 + 1 / 2]}
      }),
      resourceProps: {
        customData: {preventAutoGeneration: true, hasKick: 0}
      },
      editablePropFieldSets: [
      ]
    },
    floatingShelves: {
      title: 'Floating Shelves',
      type: 'floatingShelves',
      categoryPriorityKeywords: ['floating shelf'],
      thumbnail: require('assets/container-icons/floating-shelf-icon.png'),
      snapToWall: true,
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      productCategoryIds: () => [21, 49, 52],
      viewKeys: ['front'],
      resourceProps: {
        // dimensions: {width: 20, height: 20, depth: 12}
        customData: {hasLighting: 0, lightingType: 'linear'}
      },
      editablePropFieldSets: [
      ],
      constraintsFor: () => ({
        width: {min: 12, max: 500, step: companyKey === 'hb' ? 0.125 : 0.25},
        height: {min: 1.5, max: 500, step: companyKey === 'hb' ? 0.125 : 0.25},
        depth: {fixed: [4, 8, 12, ...(companyKey === 'hb' ? [13, 14] : [])]}
      }),
      scripts: {
        front: `
          var lights = [];

          ${script.lightingFor({sideKey: 'front'})}

          group({}, [rect({...((_.getHasProducts() && _.activeDetailLevel === 'production') ? {stroke: ''} : {dashed: true}), isFillable: _.activeDetailLevel === 'schematic', className: 'dropzone'}), ...lights])
        `,
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({isFillable: !_.getHasProducts(), dashed: true, className: \'dropzone\'}),
            ...indicatorShapes
          ])
        `,
        left: `
          var lights = [];

          ${script.lightingFor({sideKey: 'left'})}

          group({}, [rect({...(_.getHasProducts() ? {stroke: ''} : {}), isFillable: _.activeDetailLevel === 'schematic'}), ...lights]);
        `,
        right: `
          var lights = [];

          ${script.lightingFor({sideKey: 'right'})}

          group({}, [rect({...(_.getHasProducts() ? {stroke: ''} : {}), isFillable: _.activeDetailLevel === 'schematic'}), ...lights]);
        `,
        back: `
        var lights = [];

        ${script.lightingFor({sideKey: 'front'})}

        group({}, [...lights])
        `
      },
      wrappableSides: []
    },
    opencase: {
      title: 'Opencase Panels',
      type: 'opencase',
      categoryPriorityKeywords: ['opencase'],
      thumbnail: require('assets/container-icons/panel-container-icon.png'),
      managedProducts: managedProductsForKeys(['autofilledStorage', 'backstop']),
      snapToWall: true,
      viewKeys: ['front'],
      productCategoryIds: () => [53],
      resourceProps: {
        dimensions: {width: 6, height: 6, depth: 1.25}
      },
      constraintsFor: () => ({
        width: {min: 0.5, max: 500, step: companyKey === 'hb' ? 0.0625 : 0.25},
        height: {min: 0.5, max: 500, step: companyKey === 'hb' ? 0.0625 : 0.25},
        depth: {fixed: [1.25]}
      }),
      scripts: {
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [...indicatorShapes, rect({...(_.getHasProducts() ? {stroke: ''} : {dashed: true})})]);
        `,
        front: `
        var backstop = [];

        ${script.backstopFor({sideKey: 'front'})}

        group({}, [
        rect({isFillable: _.activeDetailLevel === \'schematic\', dashed: true, className: \'dropzone\'}), ...backstop
        ])`
      },
      getDropzoneInset: ({container, viewKey}) => {
        return {
          x: 0,
          y: (_.get(container, 'customData.backstop', 0) === 1 && viewKey === 'front') ? -0.5 : 0
        };
      },
      getDropzoneSize: ({container, viewKey}) => {
        var frontHeight = container.dimensions.height - (_.get(container, 'customData.backstop', 0) === 1 ? 0.5 : 0);

        return {
          width: container.dimensions.width,
          height: viewKey === 'front' ? frontHeight : container.dimensions.depth
        };
      },
    },
    islandSeating: {
      title: 'Island Seating',
      type: 'islandSeating',
      thumbnail: require('assets/container-icons/island-seating-icon.png'),
      snapToFloor: true,
      viewKeys: ['front', 'top'],
      resourceProps: {
        customData: {seatingDepth: 6, wrap: defaultWrapValuesForSides(['left', 'right']), flyby: flybyFrontFieldsFor(['end']), insetKick: insetKickFieldsFor(['left', 'right'])},
        dimensions: {width: 12, height: companyKey === 'hb' ? 35.25 : 35, depth: 10.75}
      },
      constraintsFor: curriedConstraintsFor({constraints: {
        width: {min: 12, max: 500, step: companyKey === 'hb' ? 0.0625 : 0.125},
        height: {min: 24, max: 35.5, step: companyKey === 'hb' ? 0.0625 : 0.125},
        depth: {min: 6, max: 30, step: companyKey === 'hb' ? 0.0625 : 0.125}
      }}),
      scripts: {
        front: script.forIslandBackPanel({sideKey: 'front', withChase: true, containerType: 'islandSeating'}),
        top: script.forIslandBackPanel({sideKey: 'top', withChase: true, containerType: 'islandSeating'}),
        back: script.forIslandBackPanel({sideKey: 'back', withChase: true}),
        left: script.forWrappedContainer({
          containerType: 'islandSeating',
          sideKey: 'left',
          innerChildrenScript: `
          (_.props.customData.panelsAreDisabled ? [] : [
            line({
              dashed: true,
              x1: '100% - ' + _.props.customData.seatingDepth, y1: 0,
              x2: '100% - ' + _.props.customData.seatingDepth, y2: '100%'
            }),
            line({
              dashed: true,
              x1: '100% - 0.75 - ' + _.props.customData.seatingDepth, y1: 0,
              x2: '100% - 0.75 - ' + _.props.customData.seatingDepth, y2: '100%'
            })
          ])`
        }),
        right: script.forWrappedContainer({
          containerType: 'islandSeating', sideKey: 'right',
          innerChildrenScript: `
            (_.props.customData.panelsAreDisabled ? [] : [
              line({dashed: true, x1: _.props.customData.seatingDepth, y1: 0, x2: _.props.customData.seatingDepth, y2: '100%'}),
              line({dashed: true, x1: _.props.customData.seatingDepth - 0.75, y1: 0, x2: _.props.customData.seatingDepth - 0.75, y2: '100%'})
            ])`
          })
      },
      managedProducts: managedProductsForKeys(['islandBackPanels', 'leftWrapPanel', 'rightWrapPanel', 'seatingSupportRod']),
      editablePropFieldSets: [
        wrapFieldsForSides(['left', 'right'], 'islandSeating'),
        flybyFronts(['end']),
        insetKick(['left', 'right']),
        [
          {key: 'customData.seatingDepth', title: 'Seating Depth', type: 'size', views: ['top']},
          {key: 'customData.countertopSupportDisabled', title: 'Countertop Support Disabled', type: 'checkbox', views: ['top', 'front']},
          {key: 'customData.panelsAreDisabled', title: 'Panels Disabled', type: 'checkbox', views: ['top', 'front']}
        ]
      ],
      wrappableSides: ['left', 'right']
    },
    islandBackPanel: {
      // title: 'Island Back Panel without Chase Space',
      title: 'Island Back Panel',
      type: 'islandBackPanel',
      thumbnail: require('assets/container-icons/island-back-panel-icon.png'),
      snapToFloor: true,
      viewKeys: ['front', 'top'],
      resourceProps: {
        dimensions: {width: 4, height: companyKey === 'hb' ? 35.25 : 35, depth: 0.75},
        customData: {
          wrap: defaultWrapValuesForSides(['left', 'right']),
          flyby: flybyFrontFieldsFor(['end', 'cap']),
          insetKick: insetKickFieldsFor(['left', 'right'])
        }
      },
      constraintsFor: curriedConstraintsFor({hasKick: true, hasSubcounter: true, constraints: {
        width: {min: 4, max: 500, step: companyKey === 'hb' ? 0.0625 : 0.125},
        height: {min: 24, max: 47, step: companyKey === 'hb' ? 0.0625 : 0.125},
        depth: {min: 0.75, max: 40, step: companyKey === 'hb' ? 0.0625 : 0.125}
      }}),
      scripts: {
        front: script.forIslandBackPanel({sideKey: 'front', withChase: true}),
        top: script.forIslandBackPanel({sideKey: 'top', withChase: true}),
        back: script.forIslandBackPanel({sideKey: 'back', withChase: false}),
        left: script.forWrappedContainer({containerType: 'islandBackPanel', sideKey: 'left'}),
        right: script.forWrappedContainer({containerType: 'islandBackPanel', sideKey: 'right'})
      },
      managedProducts: managedProductsForKeys(['islandBackPanels', 'leftWrapPanel', 'rightWrapPanel']),
      editablePropFieldSets: [
        wrapFieldsForSides(['left', 'right'], 'islandBackPanel'),
        flybyFronts(['end']), insetKick(['left', 'right'])
      ],
      wrappableSides: ['left', 'right']
    },
    hbIslandExtension: {
      title: 'Island Extension',
      type: 'hbIslandExtension',
      categoryPriorityKeywords: ['island extension'],
      thumbnail: require('assets/container-icons/island-extension-icon.png'),
      snapToFloor: true,
      companies: ['hb'],
      viewKeys: ['front', 'top', 'back', 'left', 'right'],
      resourceProps: {dimensions: {width: 12, height: 35.25, depth: 24}},
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      productCategoryIds: () => [26],
      constraintsFor: curriedConstraintsFor({constraints: {
        width: {min: 18, max: 54, step: 0.125},
        height: {min: 30, max: 36, step: companyKey === 'hb' ? 0.125 : 0.25},
        depth: {min: 36, max: 120, step: 0.125}
      }}),
      scripts: {
        front: 'rect({dashed: true, isFillable: lodash.includes([\'schematic\'], _.activeDetailLevel), className: \'dropzone\'});',
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group ({}, [
            rect({dashed: true, isFillable: lodash.includes([\'schematic\'], _.activeDetailLevel), className: \'dropzone\'}),
            ...indicatorShapes
          ])
        `,
        left: 'rect({dashed: true, isFillable: lodash.includes([\'schematic\'], _.activeDetailLevel), className: \'dropzone\'});',
        right: 'rect({dashed: true, isFillable: lodash.includes([\'schematic\'], _.activeDetailLevel), className: \'dropzone\'});'
      },
      getDropzoneInset: ({container, viewKey}) => {
        return {
          x: 0,
          y: viewKey === 'front' ? -0.25 : 0
        };
      },
      getDropzoneSize: ({container, viewKey}) => {
        return {
          width: container.dimensions.width,
          height: viewKey === 'front' ? container.dimensions.height - 0.25 : container.dimensions.depth
        };
      },
    },
    islandExtension: {
      title: 'Island Extension',
      type: 'islandExtension',
      thumbnail: require('assets/container-icons/island-extension-icon.png'),
      companies: ['vp'],
      snapToFloor: true,
      viewKeys: ['front', 'top', 'back', 'left', 'right'],
      resourceProps: {dimensions: {width: 12, height: 35, depth: 24}},
      constraintsFor: curriedConstraintsFor({constraints: {
        width: {min: 18, max: 47, step: 0.125},
        height: {min: 32.25, max: companyKey === 'hb' ? 36 : 35.25, step: companyKey === 'hb' ? 0.125 : 0.25},
        depth: {min: 36, max: 72, step: 0.125}
      }}),
      scripts: {
        front: `group({}, [
          path({isFillable: true, closed: true, top: 0}, [
            {x: 0, y: 0},
            {x: '100%', y: 0},
            {x: '100%', y: '100%'},
            {x: '100% - 1.5', y: '100%'},
            {x: '100% - 1.5', y: 1.5},
            {x: 1.5, y: 1.5},
            {x: 1.5, y: '100%'},
            {x: 0,  y: '100%'},
          ]),
          ${script.productLabelFor({key: 'islandExtensionFrame'})}
        ])`,
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({isFillable: false, height: 1.5, width: 1.5, left: '100% - 1.5', top: '100% - 1.5'}),
            rect({isFillable: false, width: 1.5, height: 1.5, left: 0, top: '100% - 1.5'}),
            rect({isFillable: false, height: '100%', width: '100% - 3', top: 0, left: 1.5}),
            rect({isFillable: false, height: '100% - 3', width: '100% - 6', top: 1.5, left: 3}),
            ${script.productLabelFor({key: 'islandExtensionFrame'})},
            ${script.productLabelFor({key: 'islandExtension'})},
            ...indicatorShapes
          ])`,
        back: `group({}, [
          rect({isFillable: true, height: 1.5})
        ])`,
        left: `group({}, [
          rect({isFillable: true, width: '100% - 1.5', height: 1.5}),
          rect({isFillable: true, width: '1.5', height: '100%', left: '100% - 1.5'}),
          ${script.productLabelFor({key: 'islandExtensionFrame'})}
        ])`,
        right: `group({}, [
          rect({isFillable: true, width: '100% - 1.5', height: 1.5, left: 1.5}),
          rect({isFillable: true, width: 1.5, height: '100%'}),
          ${script.productLabelFor({key: 'islandExtensionFrame'})}
        ])`
      },
      managedProducts: managedProductsForKeys(['islandExtensionFrame'])
    },
    tallFreestandingAppliance: {
      title: 'Tall Freestanding Appliance',
      type: 'tallFreestandingAppliance',
      thumbnail: require('assets/container-icons/tall-freestanding-appliance-icon.png'),
      snapToFloor: true,
      resourceProps: {dimensions: {width: 18, height: 80, depth: 25 + 1 / 8}},
      scripts: {
        front: script.freestandingAppliance({text: 'refer', sideKey: 'front'}),
        top: script.freestandingAppliance({text: 'refer', sideKey: 'top'})
      },
      editablePropFieldSets: [[
        {key: 'customData.graphicLabel', title: 'Display Text', type: 'text', views: ['top', 'front']},
        {key: 'customData.label', title: 'Custom Title (used for appliance list drawings)', type: 'text', views: ['top', 'front']},
      ]],
      constraintsFor: () => ({
        width: {min: 12, max: 80, step: companyKey === 'hb' ? 1/16 : 0.125},
        height: {min: 30, max: 120, step: companyKey === 'hb' ? 1/16 : 0.125},
        depth: {min: 8, max: 48, step: companyKey === 'hb' ? 1/16 : 0.125}
      })
    },
    baseFreestandingAppliance: {
      title: 'Base Freestanding Appliance',
      type: 'baseFreestandingAppliance',
      thumbnail: require('assets/container-icons/base-freestanding-appliance-icon.png'),
      snapToFloor: true,
      resourceProps: {dimensions: {width: 18, height: 36, depth: 25 + 1 / 8}},
      scripts: {
        front: script.freestandingAppliance({text: 'range', sideKey: 'front'}),
        top: script.freestandingAppliance({text: 'range', sideKey: 'top'})
      },
      editablePropFieldSets: [[
        {key: 'customData.graphicLabel', title: 'Display Text', type: 'text', views: ['top', 'front']},
        {key: 'customData.label', title: 'Custom Title (used for appliance list drawings)', type: 'text', views: ['top', 'front']},
      ]],
      constraintsFor: () => ({
        width: {min: 12, max: 80, step: companyKey === 'hb' ? 1/16 : 0.125},
        height: {min: 5, max: 120, step: companyKey === 'hb' ? 1/16 : 0.125},
        depth: {min: 6, max: 48, step: companyKey === 'hb' ? 1/16 : 0.125}
      })
    },
    wallFreestandingAppliance: {
      title: 'Wall Freestanding Appliance',
      type: 'wallFreestandingAppliance',
      thumbnail: require('assets/container-icons/wall-freestanding-appliance-icon.png'),
      snapToWall: true,
      resourceProps: {dimensions: {width: 12, height: 20, depth: 18}},
      scripts: {
        front: script.freestandingAppliance({text: 'hood', sideKey: 'front'}),
        top: script.freestandingAppliance({text: 'hood', sideKey: 'top'})
      },
      editablePropFieldSets: [[
        {key: 'customData.graphicLabel', title: 'Display Text', type: 'text', views: ['top', 'front']},
        {key: 'customData.label', title: 'Custom Title (used for appliance list drawings)', type: 'text', views: ['top', 'front']},
      ]],
      constraintsFor: () => ({
        width: {min: 2, max: 80, step: companyKey === 'hb' ? 1/16 : 0.125},
        height: {min: 0.5, max: 84, step: companyKey === 'hb' ? 1/16 : 0.125},
        depth: {min: 4, max: 48, step: companyKey === 'hb' ? 1/16 : 0.125}
      })
    },
    backsplash: {
      snapToWall: true,
      companies: ['vp', 'hb'],
      title: 'Backsplash',
      type: 'backsplash',
      categoryPriorityKeywords: ['backsplash'],
      thumbnail: require('assets/container-icons/backsplash-container-icon.png'),
      viewKey: ['front'],
      scopeTypes: ['wall'],
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      productCategoryIds: () => [53, 24],
      resourceProps: {
        dimensions: {width: 6, height: companyKey === 'hb' ? (8 + 0.25 + 0.4375) : 2 + backsplashTopRailHeight, depth: companyKey === 'hb' ? 1 : 0.5}
      },
      scripts: {
        front: `
        if (lodash.includes(['schematic', 'rendering'], _.activeDetailLevel)) {
          group({}, [
            rect({isFillable: true})
          ]);
        }
        else {
          group({}, [
            rect({dashed: true}),
            ${companyKey === 'hb' ? `
              line({dashed: true, x1: 0, x2: '100%', y1: 0.25, y2: 0.25}),
              line({dashed: true, x1: 0, x2: '100%', y1: 0.25 + 0.4375, y2: 0.25 + 0.4375}),
              line({dashed: true, x1: 0, x2: '100%', y1: '100% + 0.5', y2: '100% + 0.5'}),
              line({dashed: true, x1: 0, x2: 0, y1: '100%', y2: '100% + 0.5'}),
              line({dashed: true, x1: '100%', x2: '100%', y1: '100%', y2: '100% + 0.5'}),
              rect({stroke: '', isFillable: true, height: 0.5, top: '100%'}),
              rect({stroke: '', isHatchFillable: true, hatchKey: 'backstopMaterial', height: 0.5, top: '100%'}),
            ` : `
              line({dashed: true, x1: 0, x2: '100%', y1: 0, y2: 0}),
            `}
          ]);
        }`,
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group ({}, [
            rect({isFillable: true, isFillable: lodash.includes([\'schematic\'], _.activeDetailLevel)}),
            ...indicatorShapes
          ])`,
        left: `group({}, [
          rect({dashed: true, isFillable: true}),
          ${companyKey === 'hb' ? `
            line({dashed: true, x1: 0, x2: '100%', y1: 0.25, y2: 0.25}),
            line({dashed: true, x1: 0, x2: '100%', y1: 0.25 + 0.4375, y2: 0.25 + 0.4375})
          ` : `
            line({dashed: true, x1: 0, x2: '100%', y1: 0, y2: 0}),
          `}
        ]);`,
        right: `group({}, [
          rect({dashed: true, isFillable: true}),
          ${companyKey === 'hb' ? `
            line({dashed: true, x1: 0, x2: '100%', y1: 0.25, y2: 0.25}),
            line({dashed: true, x1: 0, x2: '100%', y1: 0.25 + 0.4375, y2: 0.25 + 0.4375})
          ` : `
            line({dashed: true, x1: 0, x2: '100%', y1: 0, y2: 0}),
          `}
        ]);`,
        back: 'rect({isFillable: true});'
      },
      ...getDropzoneFunctions({}),
      constraintsFor: () => ({
        width: {min: companyKey === 'hb' ? 12 : 6, max: companyKey === 'hb' ? 240 : 144, step: companyKey === 'hb' ? 0.125 : 0.25},
        height: {min: companyKey === 'hb' ? (8 + 0.4375 + 0.25) : (2 + backsplashTopRailHeight), max: companyKey === 'hb' ? (29.5 + 0.4375 + 0.25) : 30 + backsplashTopRailHeight, step: companyKey === 'hb' ? 0.0625 : 0.25},
        depth: {fixed: companyKey === 'hb' ? [1, 1.25] : [0.5]}
      })
    },
    wallPanel: {
      title: companyKey === 'hb' ? 'Wall Panel' : 'Trim Panel',
      type: 'wallPanel',
      categoryPriorityKeywords: ['panel'],
      thumbnail: require('assets/container-icons/panel-container-icon.png'),
      snapToWall: true,
      companies: ['hb', 'vp'],
      excludedProductIds: [1642],
      viewKey: ['front', 'top', 'left', 'right'],
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      productCategoryIds: () => companyKey === 'vp' ? [49] : [49, 50],
      //HINT exclude pivot door products, only want to allow door blank
      excludedProductIds: [1500, 1501, 384, 385],
      //HINT if productIds is defined productCategoryIds aren't used
      productIds: companyKey === 'vp' ? [1617, 1618] : undefined,
      scripts: {
        front: 'rect({dashed: true, className: \'dropzone\'});',
        top: `
        var shouldHide = _.props.customData.cleatDepth === 0 && _.getHasProducts() && _.getAllProductsHidden();
        var indicatorShapes = [];

        ${script.frontIndicator}

        indicatorShapes.push(...frontIndicator());

        group({}, [
          rect({stroke: ''}),
          rect({dashed: true, ...(shouldHide ? {stroke: ''} : {}), className: \'dropzone\', height: 0.75 + (_.props.customData.cleatDepth || 0), width: '100%'}),
          ...(_.props.customData.cleatDepth !== 0 ? [line({dashed: true, x1: 0, y1: _.props.customData.cleatDepth, x2: '100%', y2: _.props.customData.cleatDepth})] : []),
          ...indicatorShapes
        ])`,
        left: 'rect({dashed: true, className: \'dropzone\', width: 0.75 + (_.props.customData.cleatDepth || 0)});',
        right: 'rect({dashed: true, className: \'dropzone\', width: 0.75 + (_.props.customData.cleatDepth || 0)});'
      },
      ...getDropzoneFunctions({}),
      constraintsFor: ({container}) => ({//TODO
        width: {min: 1, max: 500, step: companyKey === 'hb' ? 0.0625 : 0.25},
        height: {min: 1, max: 500, step: companyKey === 'hb' ? 0.0625 : 0.25},
        depth: {fixed: [3 / 4 + _.get(container, 'customData.cleatDepth')]}
      }),
      resourceProps: {
        dimensions: {height: 42, width: 42, depth: 0.75},
        customData: {cleatDepth: companyKey === 'hb' ? 0.25 : 0}
      },
      editablePropFieldSets: companyKey === 'hb' ? [
        [{key: 'customData.cleatDepth', title: 'Cleat Depth', type: 'radio', noThumbnail: true,
          options: [{value: 0, title: 'None'}, {value: 0.25, title: '1/4"'}, {value: 0.5, title: '1/2"'}], views: ['top', 'front', 'left', 'right']}]
      ] : []
    },
    horizontalBarblock: {
      title: 'Horizontal Barblock',
      type: 'horizontalBarblock',
      categoryPriorityKeywords: ['bar block'],
      thumbnail: require('assets/container-icons/horizontal-barblock-icon.png'),
      companies: ['hb'],
      viewKey: ['front', 'top', 'left', 'right', 'back'],
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      productCategoryIds: () => [25],
      scripts: {
        front: 'rect({isFillable: true, className: \'dropzone\'});',
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({isFillable: true, className: \'dropzone\'}),
            ...indicatorShapes
          ])`,
        left: 'rect({isFillable: true, className: \'dropzone\'});',
        right: 'rect({isFillable: true, className: \'dropzone\'});',
        back: 'rect({isFillable: true, className: \'dropzone\'});'
      },
      constraintsFor: () => ({
        width: {min: 24, max: 108, step: 0.125},
        height: {min: 6, max: 8.125, step: 0.0625},
        depth: {fixed: [14 + 7 / 8]}
      })
    },
    rearFacingBarblock: {
      title: 'Rear Facing Barblock',
      type: 'rearFacingBarblock',
      categoryPriorityKeywords: ['bar block'],
      thumbnail: require('assets/container-icons/horizontal-barblock-icon.png'),
      companies: ['hb'],
      viewKey: ['front', 'top', 'left', 'right', 'back'],
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      productCategoryIds: () => [25],
      scripts: {
        front: 'rect({isFillable: true, className: \'dropzone\'});',
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({isFillable: true, className: \'dropzone\'}),
            ...indicatorShapes
          ])`,
        left: 'rect({isFillable: true, className: \'dropzone\'});',
        right: 'rect({isFillable: true, className: \'dropzone\'});',
        back: 'rect({isFillable: true, className: \'dropzone\'});'
      },
      constraintsFor: () => ({
        width: {min: 24, max: 108, step: 0.125},
        height: {fixed: [6 + 1 / 8]},
        depth: {fixed: [14 + 7 / 8]}
      })
    },
    endPanel: {
      title: 'End Panel',
      type: 'endPanel',
      thumbnail: require('assets/container-icons/end-panel-container-icon.png'),
      viewKeys: ['front', 'top'],
      // productCategoryIds: () => [80],
      constraintsFor: curriedConstraintsFor({constraints: {
        height: {min: 3, max: 500, step: 0.0625},
        depth: {min: 3, max: 500, step: 0.0625}, //#HINT width since product is on its side
        width: {fixed: [0.25, 0.5, 0.75, 1.5, ...(companyKey === 'vp' ? [3] : [0.375, 4])]} //#HINT depth
      }}),
      resourceProps: {
        dimensions: {width: 0.75, height: companyKey === 'hb' ? 35.25 : 35, depth: 25.25},
        customData: {wrap: {left: 1, right: 1, thickness: 0.75, topThickness: 0.75, bottomThickness: 0.75, overhang: 0}},
      },
      scripts: {
        front: script.forWrappedContainer({containerType: 'endPanel', sideKey: 'front'}),
        top: script.forWrappedContainer({containerType: 'endPanel', sideKey: 'top'}),
        back: script.forWrappedContainer({containerType: 'endPanel', sideKey: 'back'}),
        left: script.forWrappedContainer({containerType: 'endPanel', sideKey: 'left'}),
        right: script.forWrappedContainer({containerType: 'endPanel', sideKey: 'right'})
      },
      managedProducts: managedProductsForKeys(['leftWrapPanel', 'scribe']),
      editablePropFieldSets: [[{
        key: 'customData.wrap.thickness',
        title: 'End Panel Thickness',
        type: 'select',
        noThumbnail: true,
        options: _.sortBy([
          {value: 0.25, title: '1/4'},
          {value: 0.50, title: '1/2'},
          {value: 0.75, title: '3/4'},
          ...(companyKey === 'vp' ? [{value: 1.5, title: '1 1/2'}, {value: 3, title: '3'}] : []),
          ...(companyKey === 'hb' ? [{value: 0.375, title: '3/8'}, {value: 1.5, title: '1 1/2'}, {value: 4, title: 'bold'}] : [])
        ], 'value'),
        views: ['top', 'front']
      },
      {
        key: 'customData.wrap.isDashed.left',
        type: 'checkbox',
        title: 'End Panel Is Dashed',
        views: ['top', 'front', 'left', 'right']
      },
      {
        key: 'customData.customWrapBreaks.left',
        title: 'End Panel Breaks',
        type: 'text',
        views: ['front']
      },
      {
        key: 'customData.wrap.leftPanelRoundedSides', title: 'Rounded Sides',
        type: 'select', views: ['front', 'top'],
        options: [{value: 'none', title: 'None'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}, {value: 'both', title: 'Both'}],
      },
      {
        key: 'customData.wrap.boldStyle.leftPanelRoundedSides', title: 'Rounded Sides',
        type: 'select', views: ['front', 'top'],
        options: [{value: 'both', title: 'Both'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
      },
      {
        key: `customData.wrap.boldStyle.left`,
        title: `Bold Type`,
        type: 'radio',
        options: [{value: 'bold', title: 'bold'}, {value: 'radius', title: 'bold w radius'}],
        views: ['top', 'front']
      },
      //HINT for end panel containers they could be representing the right end panel
      {
        key: `customData.wrap.boldDirection.left`,
        title: `Bold Direction`,
        type: 'radio',
        options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
        views: ['top', 'front']
      },
      {
        key: `customData.wrap.leftPanelRoundedDirection`,
        title: `Radius Direction`,
        type: 'radio',
        options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
        views: ['top', 'front']
      },
      {
        key: 'customData.wrap.nonStandard.left',
        type: 'checkbox',
        title: 'Non-Standard / Has Shop',
        views: ['front', 'top', 'left', 'right', 'back']
      }
    ]]
    },
    capPanel: {
      title: 'Cap Panel',
      type: 'capPanel',
      thumbnail: require('assets/container-icons/cap-panel-icon.png'),
      viewKeys: ['front', 'top'],
      // productCategoryIds: () => [80],
      constraintsFor: curriedConstraintsFor({constraints: {
        height: {fixed: [0.25, 0.5, 0.75, 1.5]},
        depth: {min: 3, max: 500, step: 0.0625}, //#HINT width since product is on its side
        width: {min: 3, max: 500, step: 0.0625} //#HINT depth
      }}),
      resourceProps: {
        dimensions: {height: 0.75, width: 35, depth: 25.25},
        customData: {wrap: {top: 1, thickness: 0.75, topThickness: 0.75, bottomThickness: 0.75, overhang: 0}},
      },
      scripts: {
        front: script.forWrappedContainer({containerType: 'capPanel', sideKey: 'front'}),
        top: script.forWrappedContainer({containerType: 'capPanel', sideKey: 'top'}),
        back: script.forWrappedContainer({containerType: 'capPanel', sideKey: 'back'}),
        left: script.forWrappedContainer({containerType: 'capPanel', sideKey: 'left'}),
        right: script.forWrappedContainer({containerType: 'capPanel', sideKey: 'right'})
      },
      managedProducts: managedProductsForKeys(['topWrapPanel', 'scribe']),
      editablePropFieldSets: [[{
        key: 'customData.wrap.topThickness',
        title: 'Cap Panel Thickness',
        type: 'select',
        noThumbnail: true,
        options: [
          {value: 0.25, title: '1/4'},
          {value: 0.50, title: '1/2'},
          {value: 0.75, title: '3/4'},
          {value: 1.5, title: '1 1/2'},
          {value: 2.25, title: '2 1/4'}],
        views: ['front', 'top', 'left', 'right', 'back']
      },
      {
        key: 'customData.wrap.isDashed.top',
        type: 'checkbox',
        title: 'Cap Panel Is Dashed',
        views: ['front', 'top', 'left', 'right', 'back']
      },
      {
        key: 'customData.wrap.capPanelHeights',
        title: 'Cap Panel Widths',
        type: 'text',
        views: ['front']
      },
      {
        key: 'customData.wrap.nonStandard.top',
        type: 'checkbox',
        title: 'Non-Standard / Has Shop',
        views: ['front', 'top', 'left', 'right', 'back']
      }
      ]],
    },
    assembly: {
      title: 'Assembly',
      type: 'assembly',
      categoryPriorityKeywords: ['assemblies'],
      thumbnail: require('assets/container-icons/tall-container-icon.png'),
      viewKeys: ['front', 'top', 'left', 'right'],
      snapToFloor: true,
      companies: ['hb'],
      resourceProps: {dimensions: {width: 30, height: 72, depth: 12.5}},
      managedProducts: managedProductsForKeys(['autofilledStorage', 'scribe']),
      productCategoryIds: () => [58],
      constraintsFor: curriedConstraintsFor({constraints: {
        height: {min: 36, max: 119, step: 0.125},
        depth: {fixed: [12.5, 20.5]},
        width: {fixed: [24, 30, 45, 63]}
      }}),
      scripts: {
        front: `
        var wrapSizes = {left: 1, right: 1, top: 0, bottom: 0};
        var scribesData = (_.getScribesData) ? _.getScribesData() : {};

        ${script.scribesFor({sideKey: 'front', containerType: 'assembly'})}

        group({}, [
          rect({isFillable: true, className: \'dropzone\'}),
          ...scribes
        ]);
        `,
        top: `
          var indicatorShapes = [];

          var wrapSizes = {left: 1, right: 1, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'top', containerType: 'assembly'})}

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            ...(!_.getHasProducts() ? [rect({isFillable: true, className: \'dropzone\'})] : []),
            ...scribes,
            ...indicatorShapes,
          ])`,
        left: 'if (!_.getHasProducts()) rect({isFillable: true, className: \'dropzone\'});',
        right: 'if (!_.getHasProducts()) rect({isFillable: true, className: \'dropzone\'});'
      },
    },
    valet: {
      title: 'Valet',
      type: 'valet',
      thumbnail: require('assets/container-icons/valet-container-icon.png'),
      viewKeys: ['front', 'top', 'left', 'right', 'back'],
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      companies: ['hb'],
      resourceProps: {
        position: {y: 35},
        dimensions: {width: 42, height: 7, depth: 21.125}
      },
      productCategoryIds: () => [52],
      productIds: [262, 404, 1399, 1620, 1621, 1622],
      constraintsFor: curriedConstraintsFor({constraints: {
        //HINT 7" or 25 1/4 - 43 1/4 at 3" step
        height: {fixed: [7, ..._.times(7, n => 7 + (n * 3) + (25.25 - 7))]},
        depth: {fixed: [18.5, 25.125]},
        width: {min: 20, max: 95, step: 0.125}
      }}),
      scripts: {
        front: 'rect({isFillable: _.activeDetailLevel === \'schematic\', dashed: true, className: \'dropzone\'});',
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({isFillable: _.activeDetailLevel === \'schematic\', dashed: true, className: \'dropzone\'}),
            ...indicatorShapes
          ])`,
        left: 'rect({isFillable: true, className: \'dropzone\'});',
        right: 'rect({isFillable: true, className: \'dropzone\'});'
      },
    },
    daylightIsland: {
      title: 'Daylight Island',
      type: 'daylightIsland',
      thumbnail: require('assets/container-icons/daylight-island-object-icon.png'),
      viewKey: ['front', 'top', 'left', 'right', 'back'],
      companies: ['vp'],
      snapToFloor: true,
      productCategoryIds: () => {
        return [194];
      },
      managedProducts: managedProductsForKeys(['daylightIsland', 'autofilledStorage']),
      resourceProps: {},
      constraintsFor: curriedConstraintsFor({constraints: {
        height: {fixed: [34.5]},
        width: {fixed: [51, 76, 101, 126]},
        depth: {fixed: [28]}
      }}),
      getDropzoneInset: ({container, viewKey}) => {
        var y;

        if (viewKey === 'front') {
          y = -1 * (9.5);
        }
        else {
          y = 0;
        }

        return {
          x: 1,
          y: y
        };
      },
      getDropzoneSize: ({container, viewKey}) => {
        var dims = container.dimensions;
        var height;

        if (viewKey === 'front') {
          height = 24;
        }
        else {
          height = dims.depth;
        }

        return {width: dims.width - 1.5, height};
      },
      scripts: {
        front: `
        var width = _.dimensions.width || 51;
        var bayCount = {
          51: 2,
          76: 3,
          101: 4,
          126: 5
        }[width];

        var outlinePathFill = path({isFillable: true, stroke: ''}, [
          {x: 0, y: 0},
          {x: 0, y: 34.5},
          {x: 1, y: 34.5},
          {x: 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5},
          {x: width, y: 34.5},
          {x: width, y: 0},
          {x: 0, y: 0}
        ]);

        var outlinePathMaterialFill = path({isHatchFillable: true, hatchKey: 'frameMaterial'}, [
          {x: 0, y: 0},
          {x: 0, y: 34.5},
          {x: 1, y: 34.5},
          {x: 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5},
          {x: width, y: 34.5},
          {x: width, y: 0},
          {x: 0, y: 0}
        ]);

        var feet = [];
        var bayDividers = []

        lodash.times(bayCount - 1, n => {
          n++;
          feet.push(rect({isFillable: true, stroke: '', left: 25 * n, width: 1, height: 8.5, top: 34.5 - 8.5}));
          feet.push(rect({isHatchFillable: true, hatchKey: 'frameMaterial', stroke: '', left: 25 * n, width: 1, height: 8.5, top: 34.5 - 8.5}));
          feet.push(line({left: 25 * n, x1: 0, x2: 0, y1: 0, y2: 8.5, top: 34.5 - 8.5}));
          feet.push(line({left: 25 * n, x1: 0, x2: 1, y1: 0, y2: 0, top: 34.5}));
          feet.push(line({left: 25 * n + 1, x1: 0, x2: 0, y1: 0, y2: 8.5, top: 34.5 - 8.5}));

          // bayDividers.push(rect({left: 25 * n, width: 1, height: 24, top: 1}));
        });

        group({}, [
          ${script.productLabelFor({key: 'daylightIsland'})},
          rect({height: 24, top: 1, left: 1, width: '100% - 2', isFillable: true, className: 'dropzone'}),
          outlinePathFill,
          outlinePathMaterialFill,
          ...feet,
          ...bayDividers
        ]);`,
        top: `
        var width = _.dimensions.width || 51;
        var bayCount = {
          51: 2,
          76: 3,
          101: 4,
          126: 5
        }[width];

        var innerBays = [rect({left: 1, width: 24, height: 28 - 2, top: 1})];

        lodash.times(bayCount - 1, n => {
          n++;
          innerBays.push(rect({left: 1 + 24 * n + 1 * n, width: 24, height: 28 - 2, top: 1}));
        });

        var indicatorShapes = [];

        ${script.frontIndicator}

        indicatorShapes.push(...frontIndicator());

        group({}, [
          rect({className: 'dropzone', isFillable: true}),
          ...innerBays,
          ...indicatorShapes
        ]);`,
        left: `
        var width = 28;
        var outlinePath = path({}, [
          {x: 0, y: 0},
          {x: 0, y: 34.5},
          {x: 1, y: 34.5},
          {x: 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5},
          {x: width, y: 34.5},
          {x: width, y: 0},
          {x: 0, y: 0}
        ]);

        group({}, [
          rect({height: 24, top: 1, left: 1, width: '100% - 2', isFillable: true, className: 'dropzone'}),
          outlinePath,
          rect({top: 24 + 1.5, left: 1 + 12.875, height: 8.5, width: 1})
        ]);`,
        right: `var width = 28;
        var outlinePath = path({}, [
          {x: 0, y: 0},
          {x: 0, y: 34.5},
          {x: 1, y: 34.5},
          {x: 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5},
          {x: width, y: 34.5},
          {x: width, y: 0},
          {x: 0, y: 0}
        ]);

        group({}, [
          rect({height: 24, top: 1, left: 1, width: '100% - 2', isFillable: true, className: 'dropzone'}),
          outlinePath,
          rect({top: 24 + 1.5, left: 1 + 12.875, height: 8.5, width: 1})
        ]);`,
        back: `
        var width = _.dimensions.width || 50.25;
        var bayCount = {
          51: 2,
          76: 3,
          101: 4,
          126: 5
        }[width];

        var outlinePath = path({}, [
          {x: 0, y: 0},
          {x: 0, y: 34.5},
          {x: 1, y: 34.5},
          {x: 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5 - 8.5},
          {x: width - 1, y: 34.5},
          {x: width, y: 34.5},
          {x: width, y: 0},
          {x: 0, y: 0}
        ]);

        var feet = [];
        var bayDividers = []

        lodash.times(bayCount - 1, n => {
          n++;
          feet.push(rect({left: 25 * n, width: 1, height: 8.5, top: 34.5 - 8.5}));
          bayDividers.push(rect({left: 25 * n, width: 1, height: 24, top: 1}));
        });

        group({}, [
          rect({height: 24, top: 1, left: 1, width: '100% - 1.5', isFillable: true, className: 'dropzone'}),
          outlinePath,
          ...feet,
          ...bayDividers
        ]);`
      }
    },
    generic: {
      title: 'Generic Object',
      type: 'generic',
      thumbnail: require('assets/container-icons/custom-icon.png'),
      viewKeys: ['front', 'top', 'left', 'right', 'back'],
      resourceProps: {
        dimensions: {width: 12, height: companyKey === 'hb' ? 35.25 : 35, depth: 24.625}
      },
      constraintsFor: curriedConstraintsFor({constraints: {
        height: {min: 1, max: 300, step: 1/16},
        depth: {min: 1, max: 300, step: 1/16},
        width: {min: 1, max: 300, step: 1/16}
      }}),
      productCategoryIds: () => [],
      productIds: [],
      scripts: {
        front: 'rect({className: \'dropzone\', isFillable: true});',
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({className: \'dropzone\', isFillable: true}),
            ...indicatorShapes
          ])`,
        left: 'rect({className: \'dropzone\', isFillable: true});',
        right: 'rect({className: \'dropzone\', isFillable: true});'
      }
    },
    pivotDoor: {
      title: 'Pivot Door',
      type: 'pivotDoor',
      categoryPriorityKeywords: ['door'],
      thumbnail: require('assets/container-icons/panel-container-icon.png'),
      viewKeys: ['front', 'top'],
      companies: ['hb'],
      snapToFloor: true,
      snapToWall: true,
      managedProducts: managedProductsForKeys(['autofilledStorage']),
      getDropzoneSize: ({container, viewKey}) => {
        return {
          width: container.dimensions.width,
          height: viewKey === 'front' ? container.dimensions.height - 0.75 : container.dimensions.depth
        };
      },
      getDropzoneInset: ({container, viewKey}) => {
        return {
          x: 0,
          y: viewKey === 'front' ? -0.75 : 0
        };
      },
      productCategoryIds: ({project}) => {
        //wall panels and pivot doors
        return [49, 50, 78, 79];
      },
      resourceProps: {
        dimensions: {width: 38, height: 94.5, depth: 1},
        customData: {}
      },
      constraintsFor: curriedConstraintsFor({constraints: {
        height: {min: 80, max: 140, step: 0.125},
        width: {min: 38, max: 500, step: 0.125},
        depth: {fixed: [1]}
      }}),
      //...getDropzoneFunctions({}),
      scripts: {
        front: `
          group({}, [
            rect({dashed: true, className: 'dropzone'}),
            ...((_.getPivotDoorHasSurroundingPanels && _.getPivotDoorHasSurroundingPanels()) ? [] : [text({
              text: 'Door must have panels on all sides',
              top: '20%',
              fontSize: 3,
              left: '50%',
              opacity: 1,
              fontWeight: 'lighter',
              origin: {y: 'top', x: 'center'},
            })])
          ])
        `,
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({className: \'dropzone\', stroke: ''}),
            ...indicatorShapes
          ])`,
        back: 'rect({})',
        left: 'rect({})',
        right: 'rect({})'
      },
    },
    sculptedPanel: {
      title: 'Sculpted Panel',
      type: 'sculptedPanel',
      categoryPriorityKeywords: ['panel'],
      thumbnail: require('assets/container-icons/wall-container-icon.png'),
      snapToFloor: true,
      companies: ['hb'],
      // viewKey: ['front', 'top', 'left', 'right'],
      productCategoryIds: () => [77],
      scripts: {
        front: 'rect({className: \'dropzone\', isFillable: true});',
        top: 'if (!_.getHasProducts()) rect({className: \'dropzone\', isFillable: true});',
        left: 'rect({className: \'dropzone\', isFillable: true});',
        right: 'rect({className: \'dropzone\', isFillable: true});'
      },
      constraintsFor: () => ({
        width: {min: 11.375, max: 500, step: 0.0625},
        height: {min: 28, max: 500, step: 0.0625},
        depth: {fixed: [1.625, 2.125]}
      }),
      resourceProps: {
        customData: {},
      },
      managedProducts: managedProductsForKeys(['autofilledStorage']),
    },
    countertopSupport: {
      title: 'Countertop Support',
      type: 'countertopSupport',
      thumbnail: require('assets/container-icons/wall-container-icon.png'),
      companies: ['hb', 'vp'],
      managedProducts: managedProductsForKeys(['seatingSupportRod']),
      constraintsFor: () => ({
        width: {min: 12, max: 120, step: 0.0625},
        height: {fixed: [2.5]},
        depth: {min: 2.5, max: 42, step: 0.0625}
      }),
      scripts: {
        front: `group({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'seatingSupportRodMaterial'})}, [
          rect({isFillable: true, stroke: ''}),
          rect({isHatchFillable: true, hatchKey: 'seatingSupportRodMaterial'}),
          ${script.productLabelFor({key: 'seatingSupportRod'})}
        ])`,
        top: `
        var indicatorShapes = [];

        ${script.frontIndicator}

        indicatorShapes.push(...frontIndicator());

        group({}, [
          rect({isFillable: false, dashed: true}),
          rect({isFillable: false, dashed: true, depth: 2.5, height: 2.5, top: _.props.dimensions.depth < 4.5 ? '100% - 2.5' : '100% - 2 - 2.5'}),
          ...(_.props.dimensions.depth >= 5.5 ? [rect({height: 1, depth: 1, isFillable: false, dashed: true})] : []),
          ...(_.props.dimensions.depth >= 27 ? [rect({height: 2.5, depth: 2.5, top: '(100% - 2 - 2.5 - 1) / 2', isFillable: false, dashed: true}), ] : []),
          ...indicatorShapes
        ]);
        `,
        left: `
        group({}, [
          group({left: _.props.dimensions.depth >= 4.5 ? '100% - 2.5 - 2' : '100% - 2.5'}, [
            path({closed: true, isFillable: true, points: [{x: 0, y: 0}, {x: 2.5, y: 0}, {x: 2.5, y: 2.5}, {x: 2.5 - 0.1875, y: 2.5}, {x: 2.5 - 0.1875, y: 0.1875}, {x: 0, y: 0.1875}, {x: 0, y: 0}]}),
            // path({closed: false, isFillable: true, points: [{x: 0.815, y: 0}, {x: 1.0025, y: 0.1875}, {x: 1.3775, y: 0.1875}, {x: 1.5625, y: 0}]}),
            group({top: 0.1875, left: 2.5 - 1 - 5/8 - 0.1875 - 0.1875}, [
              rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 0.3}),
              rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 1 + 5/8}),
              circle({radius:  (3/8) / 2, left: 0.5, top: 0.7}),
              circle({radius: (3/8) / 2, left: 1.2, top: 1.2})
            ])
          ]),
          ...(_.props.dimensions.depth >= 5.5 ? [path({left: 1, isFillable: true, points: [{x: 0, y: 0}, {x: -1, y: 0}, {x: -1, y: 1}, {x: -0.8, y: 1}, {x: -0.8, y: 0.181}, {x: 0, y: 0.181}, {x: 0, y: 0}]})] : []),
          ...(_.props.dimensions.depth >= 27 ? [
            group({left: '100% - 4.5 - ((100% - 4.5 - 2.5 - 1) / 2)'}, [
              path({closed: true, isFillable: true, points: [{x: 0, y: 0}, {x: 2.5, y: 0}, {x: 2.5, y: 2.5}, {x: 2.5 - 0.1875, y: 2.5}, {x: 2.5 - 0.1875, y: 0.1875}, {x: 0, y: 0.1875}, {x: 0, y: 0}]}),
              // path({closed: false, isFillable: true, points: [{x: 2.815 - 2, y: 0}, {x: 3.0025 - 2, y: 0.1875}, {x: 3.3775 - 2, y: 0.1875}, {x: 3.5625 - 2, y: 0}]}),
              group({top: 0.1875, left: 2.5 - 1 - 5/8 - 0.1875 - 0.1875}, [
                rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 0.3}),
                rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 1 + 5/8}),
                circle({radius:  (3/8) / 2, left: 0.5, top: 0.7}),
                circle({radius: (3/8) / 2, left: 1.2, top: 1.2})
              ]),
            ]),
          ] : [])
        ]);
        `,
        right: `
          group({}, [
            group({left: _.props.dimensions.depth >= 4.5 ? 2 : 0}, [
              path({closed: true, isFillable: true, points: [{x: 0, y: 0}, {x: 0, y: 2.5}, {x: 0.1875, y: 2.5}, {x: 0.1875, y: 0.1875}, {x: 0.1875 + 2.3125, y: 0.1875}, {x: 0.1875 + 2.3125, y: 0}, {x: 0, y: 0}]}),
              // path({closed: false, points: [{x: 0.815, y: 0}, {x: 1.0025, y: 0.1875}, {x: 1.3775, y: 0.1875}, {x: 1.5625, y: 0}]}),
              group({top: 0.1875, left: 0.1875 + 0.1875}, [
                rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 0.3}),
                rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 1 + 5/8}),
                circle({radius:  (3/8) / 2, left: 0.5, top: 0.7}),
                circle({radius: (3/8) / 2, left: 1.2, top: 1.2})
              ])
            ]),
            ...(_.props.dimensions.depth >= 27 ? [
              group({left: '4.5 + ((100% - 4.5 - 2.5 - 1) / 2)'}, [
                path({closed: true, isFillable: true, points: [{x: 0, y: 0}, {x: 0, y: 2.5}, {x: 0.1875, y: 2.5}, {x: 0.1875, y: 0.1875}, {x: 0.1875 + 2.3125, y: 0.1875}, {x: 0.1875 + 2.3125, y: 0}, {x: 2, y: 0}]}),
                // path({closed: false, points: [{x: 2.815 - 2, y: 0}, {x: 3.0025 - 2, y: 0.1875}, {x: 3.3775 - 2, y: 0.1875}, {x: 3.5625 - 2, y: 0}]}),
                group({top: 0.1875, left: 0.1875 + 0.1875}, [
                  rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 0.3}),
                  rect({className: \'dropzone\', isFillable: true, width: 1 + 5/8, height: 1 + 5/8}),
                  circle({radius:  (3/8) / 2, left: 0.5, top: 0.7}),
                  circle({radius: (3/8) / 2, left: 1.2, top: 1.2})
                ])
              ])
            ] : []),
            ...(_.props.dimensions.depth >= 5.5 ? [path({left: '100% - 1', isFillable: true, points: [{x: 0, y: 0}, {x: 1, y: 0}, {x: 1, y: 1}, {x: 0.8, y: 1}, {x: 0.8, y: 0.181}, {x: 0, y: 0.181}, {x: 0, y: 0}]})] : []),
          ]);
        `
      },
      resourceProps: {
        dimensions: {width: 50, height: 2.5, depth: 25.125}
      }
    },
    wallUnitLiner: {
      title: 'Wall Unit Liner',
      type: 'wallUnitLiner',
      categoryPriorityKeywords: ['wall'],
      snapToWall: true,
      companies: ['vp'],
      thumbnail: require('assets/container-icons/wall-container-icon.png'),
      managedProducts: managedProductsForKeys(['scribe', 'autofilledStorage']),
      scripts: {
        top: `
          var wrapSizes = {left: 0, right: 0, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'top', containerType: 'wall'})}

          var productFrontPathPoints = _.childFrontPathPoints();

          var hasProducts = productFrontPathPoints.length > 0;

          var isSchematic = _.activeDetailLevel === 'schematic';

          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            (!hasProducts || isSchematic) ? rect({dashed: true, isFillable: isSchematic, className: 'dropzone'}) : path({dashed: true}, productFrontPathPoints),
            ...scribes,
            ...indicatorShapes
          ])`,
        front: `
          var lights = [];
          var wrapSizes = {left: 0, right: 0, top: 0, bottom: 0};
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'front', containerType: 'wall'})}

          ${script.lightingFor({sideKey: 'front'})}

          group({}, [rect({isFillable: _.activeDetailLevel === 'schematic', dashed: true, className: 'dropzone'}), ...lights, ...scribes])`,
        left: `
          var lights = [];
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'left'})}

          ${script.lightingFor({sideKey: 'left'})}

          group({}, [rect({isFillable: true}), ...lights, ...scribes])
        `,
        right: `
          var lights = [];
          var scribesData = (_.getScribesData) ? _.getScribesData() : {};

          ${script.scribesFor({sideKey: 'right'})}

          ${script.lightingFor({sideKey: 'right'})}

          group({}, [rect({isFillable: true}), ...lights, ...scribes])
        `
      },
      productCategoryIds: ({project}) => {
        var ids = [11];

        return ids;
      },
      productIds: [874, 876, 877, 1192, 1193, 1368],
      constraintsFor: () => ({
        height: {min: 8, max: 95, step: 1/4},
        width: {min: 8, max: 500, step: 1/4},
        //HINT updated to allow for deep cap panels
        depth: {fixed: [14 + 7 / 8, 18.5, 25 + 1 / 8]}
      }),
      resourceProps: {
        customData: {preventAutoGeneration: true, hasLighting: 0, lightingType: 'linear'},
        dimensions: {width: 10, height: 10, depth: 14 + 7 / 8}
      },
      editablePropFieldSets: [
      ],
      wrappableSides: []
    },
    kick: {
      snapToFloor: true,
      title: 'Kick',
      type: 'kick',
      thumbnail: require('assets/container-icons/custom-icon.png'),
      companies: ['hb'],
      managedProducts: managedProductsForKeys(['kick']),
      //TODO if kick is flush max height is 8
      constraintsFor: ({container}) => {
        var heightMax = 5.5;

        if (_.get(container, 'customData.flushKick')) heightMax = 8;

        return {
          width: {min: 1, max: 300, step: 0.0625},
          height: {min: 0.75, max: heightMax, step: 0.0625},
          depth: {min: 0.75, max: 42, step: 0.0625}
        }
      },
      scripts: {
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          group({}, [
            rect({dashed: true}),
            rect({isFillable: true, top: '100% - 0.75', height: 0.75}),
            ...indicatorShapes
          ])
        `,
        front: script.kickFor('front'),
        left: `
          rect({isFillable: true, left: '100% - 0.75', width: 0.75})
        `,
        right: `
          rect({isFillable: true, width: 0.75})
        `
      },
      resourceProps: {
        customData: {kickHeight: 4.5},
        dimensions: {width: 12, height: 4.5, depth: 24.625}
      },
      editablePropFieldSets: []
    },
     cornerScribe: {
      title: 'Corner Scribe',
      type: 'cornerScribe',
      // thumbnail,
      managedProducts: managedProductsForKeys(['standaloneScribe']),
      companies: ['hb'],
      viewKeys: ['front', 'top'],
      productCategoryIds: () => [],
      resourceProps: {
        dimensions: {width: 3, height: 96, depth: 0.75},
        customData: {backerSide: 'right'}
      },
      constraintsFor: curriedConstraintsFor({
        shouldDynamicallyConstrain: true,
        constraints: {
          height: {min: 0.75, max: 96, step: 1/16},
          width: {min: 0.75, max: 6, step: 1/16},
          depth: {fixed: [0.75]}
        }
      }),
      scripts: {
        front: `
          group({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'scribeMaterial'})}, [
            rect({isFillable: true, stroke: ''}),
            rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
            ...[${script.productLabelFor({key: 'standaloneCornerScribe'})}]
          ])
        `,
        left: `
          group({}, [
            rect({isFillable: true}),
            // rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
          ])
        `,
        right: `
          group({}, [
            rect({isFillable: true}),
            // rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
          ])
        `,
        back: `
          group({}, [
            rect({isFillable: true}),
            // rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
          ])
        `,
        top: `
          var {backerSide} = _.props.customData;
          group({}, [
            rect({
              isFillable: true,
              width: '100%',
              height: 0.75,
            }),
            ...((_.dimensions.width <= 6 && !lodash.includes(['top', 'bottom'], backerSide)) ? [rect({
              isFillable: true,
              width: 0.75, height: 4,
              left: backerSide === 'left' ? 0 : _.props.dimensions.width - 0.75,
              top: '100% - 4.75'
            })] : [])
          ])
        `,
      }
    },
    recessedScribe: {
      title: 'Recessed Scribe',
      type: 'recessedScribe',
      // thumbnail,
      managedProducts: managedProductsForKeys(['standaloneScribe']),
      companies: ['hb'],
      viewKeys: ['front', 'top'],
      productCategoryIds: () => [],
      resourceProps: {
        dimensions: {width: 3, height: 96, depth: 0.75}
      },
      constraintsFor: curriedConstraintsFor({
        shouldDynamicallyConstrain: true,
        constraints: {
          height: {min: 1.25, max: 96, step: 1/16},
          width: {min: 0.25, max: 3, step: 1/16},
          depth: {fixed: [0.75]}
        }
      }),
      scripts: {
        front: `
          group({shouldInvertStroke: _.getShouldInvertStroke({materialKey: 'scribeMaterial'})}, [
            rect({isFillable: true, stroke: ''}),
            rect({isHatchFillable: true, hatchKey: 'scribeMaterial'})
          ])
        `,
        left: `
          group({}, [
            rect({isFillable: true}),
            // rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
          ])
        `,
        right: `
          group({}, [
            rect({isFillable: true}),
            // rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
          ])
        `,
        back: `
          group({}, [
            rect({isFillable: true}),
            // rect({isHatchFillable: true, hatchKey: 'scribeMaterial'}),
          ])
        `,

        top: `
          var {backerSide} = _.props.customData;
          group({}, [
            rect({
              isFillable: true,
              width: '100%',
              height: .75,
            })
          ])
        `,
      }
    },
    ocSolidCorner: {
      title: 'OC Solid Corner',
      type: 'ocSolidCorner',
      thumbnail: require('assets/container-icons/wall-container-icon.png'),
      managedProducts: managedProductsForKeys(['ocSolidCorner']),
      companies: ['vp'],
      viewKeys: ['front', 'top'],
      productCategoryIds: () => [],
      resourceProps: {
        dimensions: {width: 7, height: 35, depth: 7}
      },
      constraintsFor: () => ({
        width: {fixed: [7]},
        height: {min: 12, max: 95, step: 0.25},
        depth: {fixed: [7]}
      }),
      scripts: {
        front: `
          group({}, [
            rect({isFillable: true, stroke: ''}),
            rect({isHatchFillable: true, hatchKey: 'ocSolidCornerMaterial'}),
            ${script.productLabelFor({key: 'ocSolidCorner'})}
          ])
        `,
        left: `
          group({}, [
            rect({isFillable: true, stroke: ''}),
            rect({isHatchFillable: true, hatchKey: 'ocSolidCornerMaterial'}),
            ${script.productLabelFor({key: 'ocSolidCorner'})}
          ])
        `,
        right: `
          group({width: 1.25}, [
            rect({isFillable: true, stroke: ''}),
            rect({isHatchFillable: true, hatchKey: 'ocSolidCornerMaterial'}),
          ])
        `,
        back: `
          group({width: 1.25, left: '100% - 1.25'}, [
            rect({isFillable: true, stroke: ''}),
            rect({isHatchFillable: true, hatchKey: 'ocSolidCornerMaterial'}),
          ])
        `,
        top: `
          var indicatorShapes = [];

          ${script.frontIndicator}

          indicatorShapes.push(...frontIndicator());

          var panelDepth = 1.25;
          var pathPoints = [
            {x: 0, y: 0},
            {x: 0, y: 7},
            {x: 7, y: 7},
            {x: 7, y: 7 - panelDepth},
            {x: panelDepth, y: 7 - panelDepth},
            {x: panelDepth, y: 0},
            // {x: 0, y: 0},
          ];

          group({}, [
            path({isFillable: true, closed: true}, pathPoints),
            ...indicatorShapes
          ])
        `,
      }
    },
  };

  var ornamentTypes = getOrnamentTypes();
  var archElementTypes = [];//getArchElementTypes();

  return {...types, ...ornamentTypes, ...archElementTypes};
};

var HybridContainerTypes = [
  'base', 'tall', 'opencase', 'endPanel', 'capPanel', 'valet', 'wallPanel', 'ocSolidCorner',
  'baseFreestandingAppliance', 'tallFreestandingAppliance', 'countertop', 'sculptedPanel', 'kick'
];

var WallDependentContainerTypes = [
  'floatingBase', 'floatingShelves', 'opencase', 'wall', 'wallFreestandingAppliance',
  'cornerCounterTransition', 'backsplash', 'wallPanel', 'vanity', 'pivotDoor'
];

var initializeTypes = ({types, companyKey}) => {
  return _.mapValues(types, (typeData, type) => {
    var constrainer = new lib.DimensionConstrainer({constraints: typeData.constraintsFor({container: {
      parent: {type: 'wall'},
      wrapSizes: {left: 0, bottom: 0, x: 0, y: 0}
    }})});

    var defaultDimensions = constrainer.getDimensionsFor('min');

    if (type === 'base') {
      defaultDimensions.height = companyKey === 'hb' ? 35.25 : 35;
      defaultDimensions.width = 12;
    }
    else if (type === 'tall') {
      defaultDimensions.depth = 24 + 5 / 8;
    }
    else if (type === 'floatingShelves') {
      defaultDimensions.depth = 12;
    }
    else if (type === 'cornerCounterTransition') {
      defaultDimensions.depth = 25.125;
    }
    else if (type === 'vanity') {
      defaultDimensions.height = 15.1875;
    }
    if (!typeData.companies) typeData.companies = ['hb', 'vp'];

    return _.defaultsDeep(typeData, {
      type,
      title: lib.string.title({forKey: type}),
      productCategoryIds: () => [],
      excludedProductIds: [],
      getDropzoneSize: ({container}) => lib.object.clone(container.dimensions),
      getDropzoneInset: () => ({x: 0, y: 0, z: 0}),
      scripts: {
        front: 'rect({isFillable: true})',
        top: 'rect({isFillable: true})',
        left: 'rect({isFillable: true})',
        right: 'rect({isFillable: true})',
        back: 'rect({isFillable: true})'
      },
      resourceProps: {
        rotation: 0,
        dimensions: defaultDimensions,
        position: {x: 0, y: type === 'capPanel' ? 68 : _.includes(['countertop', 'horizontalBarblock', 'rearFacingBarblock'], type) ? 35.25 : 0, z: 0}
      },
      snapToFloor: false
    });
  });
};

// var InitializedTypesByCompanyKey = {
//   hb: {
//     employee: initializeTypes({types: TypesFor({companyKey: 'hb', isEmployee: true})}),
//     nonEmployee: initializeTypes({types: TypesFor({companyKey: 'hb', isEmployee: false})})
//   },
//   vp: {
//     employee: initializeTypes({types: TypesFor({companyKey: 'vp', isEmployee: true})}),
//     nonEmployee: initializeTypes({types: TypesFor({companyKey: 'vp', isEmployee: false})})
//   }
// };

var ContainerTypeData = {TypesFor, HybridContainerTypes, WallDependentContainerTypes, /*InitializedTypesByCompanyKey,*/ initializeTypes};

export default ContainerTypeData;
