import _ from 'lodash';
import K from 'k';
import CFG from 'k';
import lib from 'lib';
import Container from 'project-helpers/container/index';
import Product from 'project-helpers/product';
import getProductsByCategoryFor from 'helpers/product-categories-helper';
import DetailsHelper from 'helpers/details-helper';

var ContainerTypesManagedHelpersFor = ({companyKey}) => {
  var productionIdIndices = {
    leftWrapPanel: -100,
    //HINT all normal products: 0
    kick: 5,
    scribe: 8,
    capPanel: 10,
    islandBackPanel: 20,
    seatingSupportRod: 50,
    rightWrapPanel: 100
  };

  //< managed products
  var managedBackstopProductFor = ({side}) => {
    return ({props, container, project}) => {
      var hasBackstop = _.get(props, `customData.backstop`, 0) === 1;
      var shouldExist = hasBackstop;
      var data = {shouldExist};

      if (data.shouldExist) {
        var materialIds = {backstop: _.clone(props.details.backstopMaterial)};

        data.resources = [{productId: project.companyKey === 'vp' ? 1736 : 1737, dimensions: {width: container.dimensions.width, height: 0.5, depth: .75}, materialIds}];
      }

      return data;
    }
  }

  var managedEndPanelProductFor = ({side}) => {
    return ({props, container, project}) => {
      var shouldWrap = _.get(props, `customData.wrap.${side}`, 1) === 1;
      var meldedContainersData = Container.getMeldedContainersData({container});
      var meldedContainerData = meldedContainersData[side];
      var panelMaterial = _.clone(props.details.endPanelMaterial);
      var shouldExist = shouldWrap;

      if (meldedContainerData && shouldExist) shouldExist = meldedContainerData.isPrimaryContainer;
      if (meldedContainersData.isCenterContainer) shouldExist = false;

      var isByOthers = _.get(panelMaterial, 'id') === 331 || _.get(props.customData, `wrap.${side}ByOthers`, 0) === 1; //by other panel material

      shouldExist = shouldExist && !isByOthers; //Important this is last

      var data = {shouldExist};

      if (data.shouldExist) {
        var {companyKey} = project;
        var dependencies = Container.get('dependencies', {container});
        var useIslandPanels = false; //TODO
        var thickness = _.get(props, 'customData.wrap.thickness', 0.5);
        var productIdMap = useIslandPanels ? CFG[companyKey].ids.islandEndPanel.productIdDepthMap : CFG[companyKey].ids.endPanel.productIdDepthMap;
        var productId = productIdMap[thickness];
        var materialIds = {panel: panelMaterial};
        var customData = {productionIdIndex: productionIdIndices[`${side}WrapPanel`], hasShopDrawing: _.get(props, `customData.wrap.nonStandard.${side}`) ? 1 : 0};

        if (thickness === 1.5 && _.get(props, `customData.wrap.${side}ProductVariation`, 'none') !== 'none') {
          customData.productVariation = _.get(props, `customData.wrap.${side}ProductVariation`);
        }

        var panelStyle = _.get(props, `customData.wrap.style.${side}`, 'panel');
        var isSculpted = panelStyle !== 'panel';
        var isRadiused = thickness === 1.5 && _.get(props, `customData.wrap.${side}PanelRoundedSides`, 'none') !== 'none';
        var isHorizontallyOrientedEndPanel = container.type === 'endPanel' && _.get(props.details, 'grainDirection.id', 'vertical') === 'horizontal' && !isRadiused;
        var isSTMetalEndPanel = companyKey === 'vp' && thickness === 3 && _.get(props, `customData.wrap.isSTMetalEndPanel`);

        if (panelStyle !== 'panel') {
          //use relevant sculpted panel product
          productId = CFG[companyKey].ids.managedSculptedPanels.productIdSculptedTypeMap[panelStyle];
        }

        if (thickness === 4 && _.get(props, `customData.wrap.boldStyle.${side}`) === 'radius') {
          productId = 1377;
        }

        if (isSTMetalEndPanel) {
          productId = 1702;
        }

        if (isRadiused) {
          productId = 1637;
        }

        var heights = Container.getEndPanelHeights({container, companyKey, dependencies})[side].actual;
        var depth = Container.getWrapSizes({container})[side];
        var endPanelMaterialId = _.get(container, 'details.endPanelMaterial.id');
        var material = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: endPanelMaterialId});
        var isVeneer = _.includes(CFG[companyKey].materialIds.endPanelVeneer, endPanelMaterialId) || (_.includes([1, 22], _.get(material, 'materialTypeId')));
        var netEndPanelWidth = meldedContainerData ? _.get(meldedContainerData, 'netMeldedDepth', 0) : Container.getWrapPanelWidths({container})[side];

        var maxEndPanelHeight = (depth >= 0.75 && isVeneer) ? 119 : 95;

        if (isSTMetalEndPanel) {
          maxEndPanelHeight = 47;
        }

        if (companyKey === 'hb') {
          maxEndPanelHeight = 95;

          if (isSculpted) {
            maxEndPanelHeight = 108;
          }
          else if (depth === 4) {
            maxEndPanelHeight = 48.5;
          }
          else if (depth === 0.25) {
            maxEndPanelHeight = 47;
          }
          else if (depth === 0.375) {
            maxEndPanelHeight = 119;
          }
          else if (depth === 0.5) {
            //TODO different rules for island end panels
            maxEndPanelHeight = 119;
          }
          else if (_.includes([0.75, 1.5], depth) && isVeneer) {
            maxEndPanelHeight = 119;
          }
       }

       var standardBreakingDim = depth === 4 ? 48.5 : (isRadiused ? 48 : 47);

        var maxPanelWidth = (isHorizontallyOrientedEndPanel && _.sum(heights) <= standardBreakingDim) ? maxEndPanelHeight : standardBreakingDim;
        var width = netEndPanelWidth > maxPanelWidth ? netEndPanelWidth / 2 : netEndPanelWidth;
        var swapWidthHeight = false;

        //HINT handle long base units
        //Usually want to have vertical grainflow
        //TODO let people choose
        if (heights.length === 1 && netEndPanelWidth > maxPanelWidth) {

          //hint laminate doesn't need to break
          if ((isVeneer || depth === 4 || isRadiused) && !(isHorizontallyOrientedEndPanel && _.sum(heights) <= standardBreakingDim)) {
            width = heights[0];
            heights = [netEndPanelWidth / 2, netEndPanelWidth / 2];
            swapWidthHeight = true;
          }
        }

        data.resources = _.flatMap(heights, height => {
          var panelProductId = productId;

          //price panels under 15" in any dim (other than thickness) as trim panels
          if (panelStyle === 'panel' && _.some([width, height], dim => dim < 15) && _.get(props, `customData.wrap.${side}PanelRoundedSides`, 'none') === 'none' && !isSTMetalEndPanel) {
            if (CFG[companyKey].ids.endPanel.trimPanelProductHeightMap[thickness]) {
              panelProductId = CFG[companyKey].ids.endPanel.trimPanelProductHeightMap[thickness];
            }
          }

          return _.cloneDeep({productId: panelProductId, customData,
            dimensions: {[swapWidthHeight ? 'height' : 'width']: width, [swapWidthHeight ? 'width' : 'height']: height, depth},
            materialIds
          });
        });
      }

      if (data && _.some(data.resources, resource => !resource.productId)) data.shouldExist = false;

      return data;
    };
  };

  var innerWidthFor = ({props, container}) => {
    var {width} = props.dimensions;
    var wrapSizes = Container.getWrapSizes({container});
    var leftThickness = wrapSizes.left;
    var rightThickness = wrapSizes.right;

    var innerWidth = width - (_.get(props, 'customData.wrap.left', 1) * leftThickness + _.get(props, 'customData.wrap.right', 1)) * rightThickness;

    return innerWidth;
  };

  var managedCapPanelProductFor = ({side}) => {
    return ({props, container, project}) => {
      var panelMaterial = _.clone(props.details[`${side}CapPanelMaterial`]);
      var isByOthers = _.get(panelMaterial, 'id') === 331 || _.get(props.customData, `wrap.${side}ByOthers`, 0) === 1; //by other panel material
      var shouldExist = _.get(props, `customData.wrap.${side}`, 1) === 1 && !isByOthers;

      var data = {shouldExist};
      var meldedContainersData = Container.getMeldedContainersData({container});

      if (data.shouldExist) {
        var {companyKey} = project;
        var thickness = _.get(props, `customData.wrap.${side}Thickness`, 0.5);

        var capPanelHeights = Container.getCapPanelWidths({container});
        var productIdMap = CFG[companyKey].ids.capPanel.productIdHeightMap;
        var productId = productId = productIdMap[thickness];
        var materialIds = {panel: panelMaterial};
        var width = (meldedContainersData.left || meldedContainersData.right) ? _.first(_.values(meldedContainersData)).netMeldedDepth : Container.getWrapPanelWidths({container})[side];
        var depth = Container.getWrapSizes({container})[side];
        var maxLongestSideDim = Container.getCapPanelLongestSideMaxDim({container});

        //TODO handle cap panel wider than 47 - need to use widths, with additional loop on resources
        data.resources = [];

        _.forEach(capPanelHeights, (height, cph) => {
          var panelProductId = productId;
          var customData = {productionIdIndex: productionIdIndices.capPanel + cph, hasShopDrawing: _.get(props, `customData.wrap.nonStandard.${side}`) ? 1 : 0};

          if (thickness === 1.5 && _.get(props.customData, 'wrap.topProductVariation')) {
            customData.productVariation = _.get(props.customData, 'wrap.topProductVariation');
          }

          //price panels under 15" in any dim (other than thickness) as trim panels
          if (_.some([width, height], dim => dim < 15)) {
            if (CFG[companyKey].ids.capPanel.trimPanelProductHeightMap[thickness]) {
              panelProductId = CFG[companyKey].ids.capPanel.trimPanelProductHeightMap[thickness];
            }
          }

          //if width is over max dim split into 2 panels
          //heights are already automatically split
          if ((width > maxLongestSideDim || height > maxLongestSideDim) || (width > 47 && height > 47)) {
            var splitWidth = width / 2;

            data.resources.push({productId: panelProductId, customData, dimensions: {width: splitWidth, height, depth}, materialIds});
            data.resources.push({productId: panelProductId, customData: {...customData, productionIdIndex: productionIdIndices.capPanel + cph + 0.5}, dimensions: {width: splitWidth, height, depth}, materialIds});
          }
          else {
            data.resources.push({productId: panelProductId, customData, dimensions: {width, height, depth}, materialIds});
          }
        });
      }

      return data;
    };
  };

  var managedProducts = {
    standaloneScribe: {dataFor: ({props, project}) => {
      var {type, customData, dimensions} = props;
      var {companyKey} = project;
      var scribeConstruction = props.customData.scribeConstruction || 'base';
      var data = {shouldExist: true};
      var isNonStandard = customData.hasShopDrawing || customData.hasNonStandardDimensions;
      var materialIds = {scribe: props.details.scribeMaterial};

      data.resources = [{
        productId: K[companyKey].ids.scribe.productIdTypeMap[type === 'recessedScribe' ? 'recessed' : scribeConstruction],
        customData: {hasShopDrawing: isNonStandard}, dimensions, materialIds,
        ...(type === 'recessedScribe' ? {productionDimensions: {width: 3, height: 95, depth: 0.75}} : {})
      }];

      return data;
    }},
    backstop: {dataFor: managedBackstopProductFor({side: 'front'})},
    leftWrapPanel: {dataFor: managedEndPanelProductFor({side: 'left'})},
    rightWrapPanel: {dataFor: managedEndPanelProductFor({side: 'right'})},
    topWrapPanel: {dataFor: managedCapPanelProductFor({side: 'top'})},
    bottomWrapPanel: {dataFor: managedCapPanelProductFor({side: 'bottom'})},
    seatingSupportRod: {dataFor: ({props, project}) => {
      var data = {shouldExist: !props.customData.countertopSupportDisabled};

      if (data.shouldExist) {
        var materialIds = {rod: props.details.seatingSupportRodMaterial};
        var customData = {productionIdIndex: productionIdIndices.seatingSupportRod};
        var rodCount = 1;
        var supportLedgerCount = 0;
        var spanWidth = Container.getInnerWidth({container: props});

        if (props.type === 'countertopSupport' && props.dimensions.depth >= 27) rodCount = 2;

        data.resources = _.times(rodCount, n => {
          return {productId: CFG[project.companyKey].ids.seatingSupportRod.defaultProductId, customData, dimensions: {width: spanWidth, height: 2.5, depth: 2.5}, materialIds};
        });

        if (props.type === 'countertopSupport' && props.dimensions.depth >= 5.5) {
          supportLedgerCount = _.ceil(props.dimensions.width / 72);
        }

        //HINT entered ledger count overrides calculated count
        //HINT ledger count can be '', in which case we want to use calculated value, but can also be 0, in which case we want to use 0
        var aluminumLedgerCountOverride = _.get(props, 'customData.aluminumLedgerCount');
        supportLedgerCount = (aluminumLedgerCountOverride || aluminumLedgerCountOverride === 0) ? aluminumLedgerCountOverride : supportLedgerCount;

        _.times(supportLedgerCount, n => {
          //ledger dims tbd
          data.resources.push({productId: project.companyKey === 'vp' ? 1721 : 1624, customData: {}, dimensions: {}, materialIds});
        });
      }

      return data;
    }},
    islandExtensionFrame: {dataFor: ({props, project}) => {
      var data = {shouldExist: true};
      var materialIds = {frame: props.details.frameMaterial};
      var dimensions = props.dimensions; //WARNING these are not production-dims

      data.resources = [{productId: CFG[project.companyKey].ids.islandExtensionFrame.defaultProductId, dimensions, materialIds}];

      return data;
    }},
    kick: {dataFor: ({props, container, project}) => {
      var kickHeight = Container.getKickHeight({container});

      var data = {shouldExist: kickHeight !== 0};

      if (data.shouldExist) {
        var materialIds = {kick: props.details.kickMaterial};
        var customData = {productionIdIndex: productionIdIndices.kick};
        var kickCount = _.ceil(props.dimensions.width / 95);
        var isFlushKick = _.get(props, 'customData.flushKick');
        var productId = isFlushKick ? CFG[project.companyKey].ids.kick.flush : CFG[project.companyKey].ids.kick.defaultProductId;

        if (isFlushKick) {
          var dependencies = Container.get('dependencies', {container});
          var material = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: materialIds.kick.id});
          var isVeneer = _.includes(CFG[project.companyKey].materialIds.endPanelVeneer, materialIds.kick.id) || (_.includes([1, 22], _.get(material, 'materialTypeId')));

          if (isVeneer) kickCount = _.ceil(props.dimensions.width / 47);

          if (_.get(container, 'customData.flushKickCount')) kickCount = container.customData.flushKickCount;
        }

        data.resources = _.times(kickCount, () => {
          return {productId, customData, dimensions: {width: 95, height: 5.5}, materialIds};
        });
      }

      return data;
    }},
    scribe: {dataFor: ({props, container, scribesData, project}) => {
      var data = {shouldExist: _.size(scribesData) > 0};

      if (data.shouldExist) {
        data.resources = [];

        _.forEach(_.flatMap(scribesData, scribe => scribe.productInstancesData), scribe => {
          //HINT use defined scribe material for that side, or use overall scribeMaterial
          var sideScribeMaterial = props.details[`${scribe.sideKey}ScribeMaterial`] ? {scribe: props.details[`${scribe.sideKey}ScribeMaterial`]} : undefined;
          var defaultScribeMaterial = props.details.scribeMaterial ? {scribe: props.details.scribeMaterial} : {};

          var scribeMaterial = sideScribeMaterial || defaultScribeMaterial;

          if (scribe.lengths) {
            _.times(scribe.lengths.length, n => {
              var height, width;

              if (scribe.type !== 'recessed') {
                height = scribe.lengths[n];
                width = scribe.dimensions.width;
              }

              data.resources.push({
                productId: scribe.productId,
                dimensions: scribe.type === 'recessed' ? {width: 95, height: 5.5} : {height, width},
                customData: {productionIdIndex: productionIdIndices.scribe, sideKey: scribe.sideKey},
                materialIds: scribeMaterial
              });
            });
          }
          else {
            var height, width;

            if (scribe.dimensions && scribe.type !== 'recessed') {
              height = scribe.dimensions.height;
              width = scribe.dimensions.width;
            }

            data.resources.push({
              productId: scribe.productId,
              dimensions: scribe.type === 'panel' ? {height, width} : (scribe.type === 'recessed' ? {width: 95, height: 5.5} : {height, width}),
              materialIds: scribeMaterial,
              customData: {productionIdIndex: productionIdIndices.scribe, sideKey: scribe.sideKey}
            });
          }
        });
      }

      return data;
    }},
    countertop: {dataFor: ({props, project}) => {
      var data = {shouldExist: _.get(props, 'customData.isByOthers', 0) === 0};

      if (data.shouldExist) {
        var productId = _.get(props, 'customData.productId') || CFG[project.companyKey].ids.countertop.defaultProductId;
        var materialIds = {countertop: props.details.countertopMaterial};
        var dimensions = {width: props.dimensions.width, height: props.dimensions.depth, depth: props.dimensions.height}; //WARNING these are not production-dims
        var customData = {hasShopDrawing: _.get(props, 'customData.hasShopDrawing') ? 1 : 0};

        data.resources = [{productId, dimensions, materialIds, customData}];
      }

      return data;
    }},
    islandBackPanels: {dataFor: ({props, project, container}) => {
      var data = {shouldExist: !_.get(props, 'customData.panelsAreDisabled')};

      data.resources = [];

      if (data.shouldExist) {
        var grainDirection = _.get(props.details, 'grainDirection.id', 'vertical');
        var materialIds = {panel: props.details.islandBackPanelMaterial};

        var panelWidths = Container.getBackPanelWidths({container, companyKey: project.companyKey});

        //HINT adjusting for reveal
        panelWidths = _.map(panelWidths, (width, index) => {
          if (panelWidths.length > 1) {
            var isFirstOrLast = (index === 0 || index === panelWidths.length - 1);
            var adjustment = isFirstOrLast ? 1 / 16 : 1 / 8;

            width -= adjustment;
          }

          return width;
        });

        var productId = CFG[project.companyKey].ids.islandBackPanel.defaultProductId;
        var panelStyle = _.get(props, 'customData.panelStyle', 'panel');

        if (panelStyle !== 'panel') {
          //use relevant sculpted panel product
          productId = CFG[project.companyKey].ids.managedSculptedPanels.productIdSculptedTypeMap[panelStyle];
        }

        data.resources = _.map(panelWidths, (width, index) => {
          var heightKey = grainDirection === 'vertical' ? 'height' : 'width';
          var widthKey = grainDirection === 'vertical' ? 'width' : 'height';
          var dimensions = {[heightKey]: props.dimensions.height + 1, depth: 3 / 4, [widthKey]: width};
          var customData = {productionIdIndex: productionIdIndices.islandBackPanel + index};

          return {productId, customData, dimensions, materialIds};
        });
      }

      return data;
    }},
    daylightIsland: {dataFor: ({props, project}) => {
      var data = {shouldExist: true};
      // var materialIds = {frame: props.details.frameMaterial};
      var dimensions = props.dimensions; //WARNING these are not production-dims

      data.resources = [{productId: 1387, dimensions}];//, materialIds}];

      return data;
    }},
    ocSolidCorner: {dataFor: ({props, project}) => {
      var data = {shouldExist: true};
      // var materialIds = {frame: props.details.frameMaterial};
      var dimensions = props.dimensions; //WARNING these are not production-dims
      var materialIds = {front: props.details.ocSolidCornerMaterial};

      data.resources = [{productId: 1666, dimensions, materialIds, details: {frontMaterial: props.details.ocSolidCornerMaterial}}];//, materialIds}];

      return data;
    }},
    vanity: {dataFor: ({props}) => {
      if (companyKey === 'vp') return {shouldExist: false};

      var data = {shouldExist: true};

      var propsToProductIdMap = {
        0: { //not expressed
          1: 263, //bay count
          2: 264,
          3: 265,
          4: 266,
          5: 267
        },
        1: { //expressed
          1: 268,
          2: 269,
          3: 270,
          4: 271,
          5: 272
        }
      };

      var bayCount = 0;
      var {products} = Container.get(['products'], {container: props});

      _.forEach(products, product => {
        if (Product.getIsVanityBay({product})) bayCount++;
      })

      bayCount = bayCount || 5;

      //hint don't have vanities with more than 5 products
      if (bayCount > 5 || bayCount < 1) bayCount = 5;

      var {hasExpressedBox = 0} = props.customData;

      var productId = propsToProductIdMap[hasExpressedBox][bayCount];

      var dimensions = {
        ...props.dimensions,
        height: hasExpressedBox ? props.dimensions.height : (props.dimensions.height - Container.getSubcounterHeight({container: props}))
      };

      var boxMaterial = _.get(props, 'details.boxMaterial', {id: 358});
      var frontMaterial = _.get(props, 'details.frontMaterial', {id: 358});

      data.resources = [{
        customData: {hasShopDrawing: _.get(props, 'customData.hasShopDrawing') ? 1 : 0, ..._.pick(props.customData, ['productionIdEnabled'])},
        productId,
        dimensions,
        details: {boxMaterial, frontMaterial},
        materialIds: {box: boxMaterial, front: frontMaterial}
      }];

      return data;
    }},
    autofilledStorage: {dataFor: ({props, container, project}) => {
      var shouldExist = false;
      var autofilledContainerTypes = ['base', 'baseWithChase', 'tall', 'wall', 'floatingBase', 'cornerCounterTransition', 'floatingShelves', 'hbIslandExtension', 'opencase',
        'backsplash', 'wallPanel', 'horizontalBarblock', 'rearFacingBarblock', 'assembly', 'daylightIsland', 'valet', 'sculptedPanel', 'pivotDoor', 'wallUnitLiner'
      ];
      const parentDetails = DetailsHelper.getDetailsFor({container});

      var getDetailsAndMaterialIdsForProps = (props) => {
        let details = DetailsHelper.getCleanedOwnedDetailsFor({product: {
          ...props,
          scopeId: container.scopeId,
          projectId: container.projectId,
          versionId: container.versionId,
          containerInstanceId: container.id,
          primaryAssociationKey: 'containerInstance',
        }, includeSubproductDetails: false});

        _.forEach(parentDetails, (value, key) => {
          var detail = details[key];

          if (detail && _.includes(detail.key, 'Material') && _.get(value, 'id') === 71) {
            if (!_.find(detail.options, {id: 71})) {
              //use end panel material instead
              var newValue = _.get(parentDetails, 'endPanelMaterial.id');

              if (newValue) value = newValue;
            }
          }

          if (detail && !_.includes(['leftScribeMaterial', 'rightScribeMaterial', 'topScribeMaterial'], key) && (!value.isMixed || project.materialPreset)) {
            details[key] = _.pick(value, ['id', 'settings']);
          }
        });

        let materialIds = {};

        _.forEach(details, (detail, detailKey) => {
          if (_.includes(detailKey, 'Material')) {
            materialIds[_.replace(detailKey, 'Material', '')] = detail;
          }
        });

        return {details, materialIds};
      };

      var data = {shouldExist};

      if (_.includes(autofilledContainerTypes, container.type)) {
        var room = Container.get('room', {container});

        shouldExist =  !_.get(container, 'customData.preventAutoGeneration') && !_.get(room, 'customData.preventAutoGeneration');

        var isComplexAutofilledContainer = Container.getHasComplexAutofillLogic({container});
        var hasUnmanagedChildProducts = Container.get('unmanagedProductInstances', {container}).length > 0;

        if (isComplexAutofilledContainer && hasUnmanagedChildProducts) shouldExist = false;

        if (shouldExist) {
          data = {
            shouldExist,
            resources: []
          };

          var addProductForProps = (productProps) => {
            productProps = {
              ...productProps,
              ...getDetailsAndMaterialIdsForProps(productProps)
            };

            data.resources.push(productProps);
          }

          if (container.type === 'pivotDoor') {
            var dropzoneSize = Container.getDropzoneSize({container, viewKey: 'front'});
            var hasTopPanels = dropzoneSize.height > 86;
            var isDoubleDoor = dropzoneSize.width > 52;
            var doorProductId = isDoubleDoor ? 385 : 384;
            var doorProductType = Container.get('dependencies', {container}).productTypes.byId[doorProductId];
            var notchedPanelProductId = 1501;

            var doorHeight = _.min([dropzoneSize.height, 84]);
            var doorWidth = _.min([doorProductType.constraints.width.max, dropzoneSize.width - 12]);
            var notchedPanelAvailableWidth = dropzoneSize.width - doorWidth;
            var notchedPanelWidth = notchedPanelAvailableWidth / 2;

            addProductForProps({
              productId: notchedPanelProductId,
              position: {x: 0, y: 0, z: 0},
              dimensions: {width: notchedPanelWidth, height: doorHeight, depth: 0.75},
              details: container.details,
              materialIds: {front: container.details.frontMaterial},
              customData: {notchType: 'right'},
            });
            addProductForProps({
              productId: doorProductId,
              position: {x: notchedPanelWidth, y: 0, z: 0},
              dimensions: {width: doorWidth, height: doorHeight, depth: 1.75},
              details: container.details,
              materialIds: {front: container.details.frontMaterial, hinge: container.details.hingeMaterial, solidTrim: container.details.solidTrimMaterial},
              customData: {},
            });
            addProductForProps({
              productId: notchedPanelProductId,
              position: {x: dropzoneSize.width - notchedPanelWidth, y: 0, z: 0},
              dimensions: {width: notchedPanelWidth, height: doorHeight, depth: 0.75},
              details: container.details,
              materialIds: {front: container.details.frontMaterial},
              customData: {notchType: 'left'},
            });

            if (hasTopPanels) {
              var topPanelCount = 2 + (isDoubleDoor ? 2 : 1);
              var xPosition = 0;

              _.times(topPanelCount, n => {
                var topPanelWidth = {
                  0: notchedPanelWidth,
                  1: isDoubleDoor ? doorWidth / 2 : doorWidth,
                  2: isDoubleDoor ? doorWidth / 2 : notchedPanelWidth,
                  3: notchedPanelWidth
                }[n];

                addProductForProps({
                  productId: 1503, //1/4" aluminum cleat wall panel
                  position: {x: xPosition, y: -doorHeight, z: 0},
                  dimensions: {width: topPanelWidth, height: dropzoneSize.height - doorHeight, depth: 0.75},
                  details: container.details,
                  materialIds: {front: container.details.frontMaterial},
                  customData: {notchType: 'left'},
                });

                xPosition += topPanelWidth;
              });
            }
          }
          else if (container.type === 'valet') {
            var isValetHamper = container.dimensions.height !== 7;

            addProductForProps({
              productId: isValetHamper ? 404 : 262,
              position: {x: 0, y: -(container.dimensions.height - 7), z: 0},
              dimensions: {...container.dimensions, height: 7}
            });

            if (isValetHamper) {
              //TODO generate hamper bays below
              //not a priority because it doesn't impact pricing
            }
          }
          else if (container.type === 'daylightIsland') {
            var width = container.dimensions.width || 51;
            var bayCount = {
              51: 2,
              76: 3,
              101: 4,
              126: 5
            }[width];

            var productIds = {
              2: [1390, 1390],
              3: [1390, 1388, 1390],
              4: [1390, 1388, 1391, 1390],
              5: [1394, 1390, 1390, 1391, 1388]
            }[bayCount];

            _.forEach(productIds, (productId, index) => {
              addProductForProps({
                productId,
                position: {x: index * 25, y: 0, z: 0},
                dimensions: {width: 24, height: 24, depth: 28},
                details: container.details,
                materialIds: {pull: container.details.pullMaterial, box: container.details.boxMaterial, front: container.details.frontMaterial},
              });
            });
          }
          else if (container.type === 'assembly') {
            var productCategories = getProductsByCategoryFor({container});

            var productType = _.find(_.find(productCategories, {id: 58})?.productTypes, productType => {
              //find product type where constraints fit container size
              var dimensionConstrainer = new lib.DimensionConstrainer({constraints: productType.constraints});

              return _.isEqual(dimensionConstrainer.constrain({dimensions: container.dimensions}), container.dimensions);
            });

            if (productType) {
              addProductForProps({
                productId: productType.id,
                position: {x: 0, y: 0, z: 0},
                dimensions: container.dimensions,
                details: {},
                materialIds: {pull: container.details.pullMaterial, box: container.details.boxMaterial, front: container.details.frontMaterial},
                customData: {},
              });
            }
          }
          else if (container.type === 'hbIslandExtension') {
            var productCategories = getProductsByCategoryFor({container});

            var productType = _.find(_.find(productCategories, {id: 26})?.productTypes, productType => {
              //find product type where constraints fit container size
              var dimensionConstrainer = new lib.DimensionConstrainer({constraints: productType.constraints});

              return _.isEqual(dimensionConstrainer.constrain({dimensions: container.dimensions}), container.dimensions);
            });

            if (productType) {
              addProductForProps({
                productId: productType.id,
                position: {x: 0, y: 0, z: 0},
                dimensions: {...container.dimensions, height: container.dimensions.height - 0.25},
                details: {},
                materialIds: {pull: container.details.pullMaterial, box: container.details.boxMaterial, front: container.details.frontMaterial},
                customData: {},
              });
            }
          }
          else {
            function findAvailableSpaces(maxRangeTo, ranges) {
              let available = [];
              let currentTo = 0;

              for (var range of ranges) {
                if (currentTo < range.from) {
                  available.push({from: currentTo, to: range.from});
                }
                currentTo = Math.max(currentTo, range.to);
              }

              // If there's space between the last range's to and maxRangeTo
              if (currentTo < maxRangeTo) {
                available.push({from: currentTo, to: maxRangeTo});
              }

              return available;
            }

            var precision = companyKey === 'vp' ? 1 / 4 : 1 / 8;

            var dropzoneSize = Container.getDropzoneSize({container, viewKey: 'front'});
            var topDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
            dropzoneSize.depth = _.includes(['baseWithChase'], container.type) ? container.customData.unitDepth : (topDropzoneSize.depth || topDropzoneSize.height);

            var productId = {
              base: companyKey === 'vp' ? 880 : 4,
              baseWithChase: companyKey === 'vp' ? 880 : 4,
              floatingBase: companyKey === 'vp' ? 928 : 70,
              tall: companyKey === 'vp' ? 1096 : 710,
              wall: companyKey === 'vp' ? 1033 : 394,
              cornerCounterTransition: companyKey === 'vp' ? 991 : 181,
              floatingShelves: companyKey === 'vp' ? 1579 : 168,
              opencase: companyKey === 'vp' ? 1058 : 435,
              backsplash: companyKey === 'vp' ? 1161 : 184,
              wallPanel: companyKey === 'vp' ? 1617 : 1503,
              horizontalBarblock: 1148,
              rearFacingBarblock: 1610,
              sculptedPanel: dropzoneSize.height > 46 ? 1597 : 1460,
              wallUnitLiner: 876
            }[container.type];

            if (dropzoneSize.depth < 18 && _.includes(['base', 'baseWithChase', 'floatingBase'], container.type)) {
              if (companyKey === 'hb') {
                var productId = {
                  base: dropzoneSize.width >= 27 ? 22 : 6,
                  baseWithChase: dropzoneSize.width >= 27 ? 22 : 6,
                  floatingBase: dropzoneSize.width >= 27 ? 76 : 72,
                }[container.type];
              }
              else if (companyKey === 'vp') {
                var productId = {
                  base: dropzoneSize.width >= 27 ? 890 : 882,
                  baseWithChase: dropzoneSize.width >= 27 ? 890 : 882,
                  floatingBase: dropzoneSize.width >= 27 ? 933 : 930,
                }[container.type];
              }
            }

            var products = Container.get('unmanagedProductInstances', {container});
            var productType = Container.get('dependencies', {container}).productTypes.byId[productId];

            var constraintWidth = _.includes(['opencase', 'wallPanel', 'sculptedPanel'], container.type) ? (dropzoneSize.height > 47 ? 45 : 95) : (dropzoneSize.width);

            var constraints = Product.getConstraints({productType, product: {productId: productType.id, dimensions: {
              height: dropzoneSize.height, width: constraintWidth, depth: container.dimensions.depth
            }, customData: {}}});

            if (container.type === 'wallPanel') {
              constraints = {
                depth: constraints.depth,
                height: {min: 2, max: dropzoneSize.height > 47 ? 95 : 47, step: constraints.height.step},
                width: {min: 2, max: dropzoneSize.height <= 47 ? 95 : 47, step: constraints.width.step}
              };
            }

            var dimensionConstrainer = new lib.DimensionConstrainer({constraints});

            var productXranges = _.sortBy( _.map(products, product => Product.getXRange({product})), 'from');

            //calculate how many products we need to generate, where they should be positioned
            //what their size should be
            //HINT getting available x ranges, xRanges = [{from, to}, {from, to}];
            var xRanges = findAvailableSpaces(dropzoneSize.width, productXranges);

            _.forEach(xRanges, xRange => {
              var minConstraints = dimensionConstrainer.getDimensionsFor('min');
              var maxConstraints = dimensionConstrainer.getDimensionsFor('max');
              var maxWidth = maxConstraints.width;
              var minWidth = minConstraints.width;
              var maxHeight = maxConstraints.height;
              var minHeight = minConstraints.height;

              var xRangeWidth = xRange.to - xRange.from;
              if (xRangeWidth >= minWidth) {
                //split into widths for products
                var productCount = _.ceil(xRangeWidth / maxWidth);
                var optimalWidth = xRangeWidth / productCount;
                var productWidth = dimensionConstrainer.constrain({dimensions: {width: optimalWidth}}).width;

                //WARNING total needs to add up to innerWidth, despite rounding, so last width is relative
                var productWidths = _.times(productCount, index => {
                  var isMiddle = index === Math.floor(productCount / 2);
                  var middleContainerWidth = _.clone(productWidth);
                  var hasRemainder = productWidth * productCount !== xRangeWidth;

                  if (isMiddle && hasRemainder) {
                    var optimalMiddleContainerWidth = xRangeWidth - (productWidth * (productCount - 1));

                    middleContainerWidth = dimensionConstrainer.constrain({dimensions: {width: optimalMiddleContainerWidth}}).width;

                    //HINT if we rounded up, round down, because we don't want to oversize in the container
                    if (middleContainerWidth > optimalMiddleContainerWidth) {
                      middleContainerWidth = dimensionConstrainer.constrain({dimensions: {width: optimalMiddleContainerWidth - _.get(constraints, 'width.step', precision)}}).width;
                    }
                  }

                  var width = isMiddle ? middleContainerWidth : productWidth;

                  if (productId === 1460 && index !== 0) {
                    width = width + 0.875;
                  }

                  return width;
                });

                var productHeights = [];

                if (dropzoneSize.height >= minHeight) {
                  if (container.type === 'tall' && dropzoneSize.height > 95 && dropzoneSize.height < 120) {
                    productHeights = [dropzoneSize.height - 30, 30];
                  }
                  else {
                    var productCountByHeight = container.type === 'floatingShelves' ? _.ceil(dropzoneSize.height / 10) : _.ceil(dropzoneSize.height / maxHeight);
                    var productHeight = dimensionConstrainer.constrain({dimensions: {height: dropzoneSize.height / productCountByHeight}}).height;

                    if (dropzoneSize.height / productCountByHeight < minHeight) {
                      productCountByHeight = _.floor(dropzoneSize.height / minHeight);
                      productHeight = minHeight;
                    }

                    productHeights = _.times(productCountByHeight, index => {
                      var isMiddle = index === Math.floor(productCountByHeight / 2);
                      var middleContainerHeight = _.clone(productHeight);
                      var hasRemainder = productHeight * productCountByHeight !== dropzoneSize.height;

                      if (isMiddle && hasRemainder) {
                        var optimalMiddleContainerHeight = dropzoneSize.height - (productHeight * (productCountByHeight - 1));

                        middleContainerHeight = dimensionConstrainer.constrain({dimensions: {height: optimalMiddleContainerHeight}}).height;

                        //HINT if we rounded up, round down, because we don't want to oversize in the container
                        if (middleContainerHeight > optimalMiddleContainerHeight) {
                          middleContainerHeight = dimensionConstrainer.constrain({dimensions: {height: middleContainerHeight - _.get(constraints, 'height.step', precision)}}).height;
                        }
                      }

                      return isMiddle ? middleContainerHeight : productHeight;
                    });
                  }
                }

                var productX = xRange.from || 0;

                _.forEach(productWidths, (productWidth, widthIndex) => {
                  if (container.type === 'floatingShelves') {
                    _.times(productCountByHeight, shelfIndex => {
                      var dimensions = {
                        depth: dimensionConstrainer.getDimensionsFor('max', {ideal: dropzoneSize}).depth,
                        width: productWidth,
                        height: productHeight
                      };

                      var y = (-(shelfIndex / (productCountByHeight - 1)) * dropzoneSize.height) || 0;

                      if (shelfIndex !== 0 && shelfIndex === productCountByHeight - 1) y = y + 1.5;
                      if (shelfIndex !== 0 && shelfIndex !== productCountByHeight - 1) y = y + 1.5 / 2;

                      addProductForProps({
                        productId,
                        position: {x: productX || 0, y, z: 0},
                        dimensions,
                        details: {},
                        materialIds: {pull: container.details.pullMaterial, box: container.details.boxMaterial, front: container.details.frontMaterial},
                        customData: {},
                      });
                    });
                  }
                  else {
                    var productY = 0;

                    _.forEach(productHeights, (productHeight, index) => {
                      var specificProductId = productId;

                      var dimensions = {
                        depth: dimensionConstrainer.getDimensionsFor('max', {ideal: dropzoneSize}).depth,
                        width: productWidth,
                        height: productHeight
                      };

                      if (companyKey === 'vp' && index !==0 && container.type === 'cornerCounterTransition') {
                        specificProductId = 1221;
                      }
                      else if (container.type === 'wallPanel') {
                        var hbWideProductId = 1503;

                        if (container.type === 'wallPanel' && companyKey === 'hb') {
                          if (container.customData.cleatDepth === 0.25) {
                            hbWideProductId = 1503;
                          }
                          else if (container.customData.cleatDepth === 0.50) {
                            hbWideProductId = 1667;
                          }
                        }

                        var narrowProductId = companyKey === 'vp' ? 1617 : 378;
                        var wideProductId = companyKey === 'vp' ? 1618 : hbWideProductId;
                        var isNarrow = dimensions.width <= 15 || dimensions.height <= 15;

                        specificProductId = isNarrow ? narrowProductId : wideProductId;
                      }
                      else if (container.type === 'tall') {
                        if (dimensions.height < 60) specificProductId = companyKey === 'vp' ? 876 : 136;
                      }

                      addProductForProps({
                        productId: specificProductId,
                        position: {x: productX || 0, y: productY || 0, z: 0},
                        dimensions,
                        details: {},
                        materialIds: {pull: container.details.pullMaterial, box: container.details.boxMaterial, front: container.details.frontMaterial},
                        customData: {
                          ...(widthIndex !== 0 && productId === 1460 ? {isFlutedAdjacent: true} : {})
                        },
                      });

                      //HINT Y is inverted
                      productY -= productHeight;
                    });
                  }

                  productX += productWidth;
                });
              }
            });
          }
        }
      }

      return data;
    }}
  };
  //> managed products

  return {managedEndPanelProductFor, managedCapPanelProductFor, innerWidthFor, managedProducts};
};

export default ContainerTypesManagedHelpersFor;