import React from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import memo from 'helpers/memo';

import { EditableCanvasPolyline, CanvasRect } from 'canvas';

import Header from './header/header';
import CanvasView from 'canvas/canvas-view';
import PositionHelper from 'helpers/position-helper';
import MeasuringTapeCanvasObject from 'canvas/canvas-measuring-tape';
import DimensionCreatorCanvasObject from 'canvas/dimensions/canvas-dimension-creator';

import Room from 'project-helpers/room';
import Wall from 'project-helpers/wall';
import Floor from 'project-helpers/floor';
import Elevation from 'project-helpers/elevation';
import ArchElement from 'project-helpers/arch-element';
import ProjectGraphic from 'project-helpers/project-graphic';
import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import Product from 'project-helpers/product';
import Project from 'project-helpers/project';
import Scope from 'project-helpers/scope';

import CanvasRoom from 'project-components/canvas-room';
import CanvasRoomGrid from 'project-components/canvas-room-grid';
import CanvasScopeGrid from 'project-components/canvas-scope-grid';
import CanvasElevation from 'project-components/canvas-elevation';
import CanvasContainer from 'project-components/canvas-container';
import CanvasProduct from 'project-components/canvas-product';
import CanvasArchElement from 'project-components/canvas-arch-element';
import CanvasFloor from 'project-components/canvas-floor';
import CanvasEntities3D from 'project-components/canvas-entities-3d';
import CanvasVolume from 'project-components/canvas-volume';
import { CanvasMultiselectTransformer, CanvasScriptObject } from 'canvas';

import AccessoriesView from './accessories-view/accessories-view';

import AddProjectTreeNodePopup from './popups/add-project-tree-node-popup';
import ColorKeyPopup from './popups/color-key-popup.js';
import MaterialFactorsPopup from './popups/material-factors-popup';
import AddDatumPopup from './popups/add-datum-popup';
import ToleranceSelectPopup from './popups/tolerance-popup';
import ShareableLinkPopup from './popups/shareable-link-popup';
import CopyDimEditsPopup from './popups/copy-dim-edits-popup';

import EditorMenu from './hud-elements/editor-menu';
import CanvasControlsHudElement from './hud-elements/canvas-controls-hud-element';
import ParameterEditorHudElement from './hud-elements/parameter-editor-hud-element';
import PropertiesViewHudElement from './hud-elements/properties-view-hud-element';
import ActiveItemHudElement from './hud-elements/active-item-hud-element';
import VisibilityLayersHudElement from './hud-elements/visibility-layers-hud-element';
import ContextHudElement from './hud-elements/context-hud-element';
import NumberInput from 'components/number-input';

import loadProjectData from 'helpers/load-project-data';
import updateProductionIds from 'helpers/update-production-ids-helper';
import getProductsByCategoryFor from 'helpers/product-categories-helper';
import apiKeyFor from 'helpers/api-key-for-helper';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import memoObject from 'helpers/memo-object';
import getDependencies from 'helpers/get-dependencies';
import getDimensionsLayerForDetailLevel from 'helpers/get-dimensions-layer-for-detail-level';
import SnapDataHelper from 'helpers/snap-data-helper';
import setIssues from 'helpers/issues/index';

import createIcon from 'assets/create-icon-white.png';

import ProductInfoHudElement from './hud-elements/product-info-hud-element';
import Barblock from 'project-helpers/product-helpers/barblock';
import withUseParams from 'hooks/with-use-params';
import PriceElement from './header/price-element';

import { connect, resourceActions, issuesDataActions } from 'redux/index.js';
import { pluralize } from 'inflection';
import { v4 as uuid } from 'uuid';

var settingsKeys = ['viewMode', 'activeDimensionsLayer', 'activeDetailLevel', 'activeFillMode', 'activeViewEntityResourceKey', 'activeViewEntityId', 'visibilityLayers', 'showingPriceData', 'bothIsShowingElevations', 'isOrthographicLockEnabled', 'activeUserLense'];
global.visibilityLayers = {};
global.dimensionsByEntityType = {
  room: {},
  elevation: {}
};

class EditorPage extends React.Component {
  constructor(props) {
    super(props);

    this.calculateSnapData = memo(this.calculateSnapData, {name: 'calculateSnapData'});
    this.calculateSourceSnapData = memo(this.calculateSourceSnapData, {name: 'calculateSourceSnapData'});

    this.numericInputValueRef = React.createRef();
    this.numericInputRef = React.createRef();

    this.state = {
      activeProjectGraphicData: undefined,
      isLoaded: false,
      loadingMessage: 'Getting Project Data...',
      isCopy: false,
      isOrthographicLockEnabled: true,
      isAddingElevation: false,
      isAddingProjectGraphic: false,
      addingProjectGraphicType: null,
      showAccessoriesView: false,
      showMaterialFactorsPopup: false,
      precision: 1 / 8,
      datumPopupShowing: false,
      infoPopupShowing: false,
      tolerancePopupShowing: false,
      showingAlignmentIndicators: false,
      undoQueue: [],
      editingDimensions: false,
      showNumericInput: false,
      isNumericInputSubmitted: false,
      isAddingScope: true,
      countertopsAreSelectable: false,
      bothIsShowingElevations: false,
      tentativeScope: null,
      drawingScopeData: {isDrawing: false, isShowingPopup: false, roomId: undefined, floorId: undefined, position: undefined, points: []},
      activeEntities: [],
      activeDetailLevel: 'fullDetail',
      activeFillMode: 'default',
      activeUserLense: 'design',
      colorKeyPopupIsVisible: false,
      showingPriceData: true,
      focusMenuSearch: false,
      showIssues: false,
      threeDMode: false
    };

    this.undoQueue = [];
  }

  freehandSelectionRectRef = React.createRef();
  freehandSelection = {visible: false, x1: 0, y1: 0, x2: 0, y2: 0};
  freehandSelectionOldPos = {x: 0, y: 0};

  async componentDidMount() {
    var {match} = this.props; //TODO convert match to params

    if (window.location.hash) {
      global.apiToken = _.trimStart(window.location.hash, '#');
    }

    await loadProjectData({match, resourceActionDispatchers: this.props});
    this.setState({loadingMessage: 'Project Data Loaded, Drawing Scene...'})

    setTimeout(async () => {
      let floor, activeEntity, projectTreeIsShowing;

      if (this.props.project.companyKey === 'vp') {
        var link = document.querySelector('link[rel~=\'icon\']');
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }
        link.href = 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/st-favicon.ico';
      }

      if (!_.values(this.props.floors).length || !_.values(this.props.rooms).length) {
        var sharedProps = {
          projectId: this.props.project.id,
          versionId: this.props.project.versionId,
          details: {},
        };

        floor = _.values(this.props.floors)[0] ? _.values(this.props.floors)[0] : await lib.api.create('floor', {props: {...sharedProps, title: '', stencil: undefined}});
        var room = await lib.api.create('room', {props: {...sharedProps, floorId: floor.id, title: '', plan: {points: [], closed: false}}}); //, customData: {volumesOnly: false}
        var scope = await lib.api.create('scope', {props: {...sharedProps, roomId: room.id, rotation: {z: 0}, position: {x: 0, y: 0}}});

        await this.props.trackFloors({floors: [floor]});
        await this.props.trackRooms({rooms: [room]});
        await this.props.trackScopes({scopes: [scope]});

        activeEntity = {id: room.id, resourceKey: 'room'};

        projectTreeIsShowing = true;
      }

      if (!floor) floor = _.values(this.props.floors)[0];

      setTimeout(() => {
        var settings = _.pick(_.defaultsDeep(lib.cookie.get({scope: 'cfg', key: 'editor-settings'}), {
          viewMode: 'top',
          activeDimensionsLayer: 'client',
          activeDetailLevel: 'fullDetail',
          activeFillMode: 'default',
          visibilityLayers: {dimensions: true, projectGraphics: true, stencil: true, elevationLines: false, scopes: false, unitNumbers: false, grainFlow: false, wallsAndArchElements: true, bindingDimensions: true, ornamentTopIndicators: false, backgroundsVisible: true, backgroundsSelectable: true},
          bothIsShowingElevations: false,
          showingPriceData: true,
          isOrthographicLockEnabled: true,
          // topMode: 'floors',
          // pricingEstimateHidden: false
          // installationsMode: false,
          // stepKey: 'dimensions',
        }), settingsKeys);

        settings.activeDimensionsLayer = getDimensionsLayerForDetailLevel({activeDetailLevel: settings.activeDetailLevel, project: this.props.project});
        global.activeDimensionsLayer = settings.activeDimensionsLayer;

        settings.visibilityLayers.bindingDimensions = true;

        if (this.props.project.companyKey === 'vp') settings.activeDimensionsLayer = 'client';

        if (settings.viewMode === 'front' && _.size(this.props.elevations) === 0) {
          settings.viewMode = 'top';
        }

        if (!_.includes(['top', 'both', 'front', 'threeD', 'lite'], settings.viewMode)) settings.viewMode = 'top';

        this.setViewMode(settings.viewMode, settings.activeViewEntityId, settings.activeViewEntityResourceKey);

        if (this.state.viewMode === 'top' && _.values(this.props.floors).length === 1) {
          if (_.values(this.props.rooms).length === 1) {
            activeEntity = {id: _.values(this.props.rooms)[0].id, resourceKey: 'room'};
          }
        }

        global.visibilityLayers = settings.visibilityLayers;

        document.title = `Layout | ${this.props.project.title} - ${this.props.project.versionTitle}`;

        if (this.props.onIsClientFacingChange) this.props.onIsClientFacingChange(!settings.showingPriceData);

        this.setState({
          ...this.state,
          ...settings,
          isLoaded: true,
          activeEntities: _.size(activeEntity) ? [activeEntity] : [],
          projectTreeIsShowing,
        });

        setIssues({project: this.props.project, reduxActions: this.props});
      });
    });

    document.addEventListener('mousemove', this.handleMouseMove);

    document.addEventListener('keydown', (event) => {
      //TODO disable key listeners while working in popups
      if (document.activeElement.tagName === 'BODY') { // && !this.state.isViewingArchetypePopup
        if (event.key === 'Escape') {
          this.setState({projectTreeIsShowing: false, isAdding: false, isAddingCustomDimension: false, isAddingProjectGraphic: false, addingProjectGraphicType: '', isMeasuringTapeVisible: false, isAddingElevation: false});
          this.handleDeselect({override: true});
        }
        else if (lib.event.keyPressed(event, 'ctrlcmd') && this.state.editingDimensions) {// && lib.event.keyPressed(event, 'd')) {
          event.preventDefault();

          this.setState({isAddingCustomDimension: true});
        }
        else if (event.key === 'f' && lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'shift')) {
          event.preventDefault();

          if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
          }
          else if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
        else if (event.key === 'h' && lib.event.keyPressed(event, 'ctrlcmd')) {
          this.setState({hudIsHidden: !this.state.hudIsHidden});

          event.preventDefault();
          //cookie
        }
      }
    });
  }

  async componentDidUpdate(_prevProps, prevState) {
    if (_.some(settingsKeys, key => prevState[key] !== this.state[key])) {
      lib.cookie.set({scope: 'cfg', key: 'editor-settings', value: _.pick(this.state, settingsKeys), domain: window.location.href.includes('localhost') ? 'localhost' : 'henrybuilt.com'});
    }

    // if (!_.isEmpty(this.props.productTypes) && _.isEmpty(this.state.media) && !this.state.requestedMedia) {
    //   this.setState({requestedMedia: true});

    //   let accessoriesProductsTypes = _
    //     .chain(this.props.productTypes)
    //     .filter({isStandalone: 1, isSellable: 1, isDiscontinued: 0})
    //     .sortBy([({categoryId}) => !_.includes([81], categoryId), 'categoryId'])
    //     .value();

    //   // const productInstances = this.props.products;
    //   const productTypeIds = _.map(accessoriesProductsTypes, 'id');

    //   let productTypesWithMedia;

    //   productTypesWithMedia = await lib.api.get('products', {
    //     fields: ['media', 'id'], where: {id: productTypeIds}, include: {media: {where: {subjectType: 'thumbnail'}}}
    //   });

    //   const mediaThumbnailsByProductTypeId = {};
    //   _.forEach(productTypeIds, productTypeId => {
    //     mediaThumbnailsByProductTypeId[productTypeId] = _.get(_.find(productTypesWithMedia, {id: productTypeId}), 'media[0]');
    //   });
    //   this.setState({
    //     media: mediaThumbnailsByProductTypeId
    //   });
    // }

    if (this.state.showNumericInput && !_.isEmpty(this.numericInputRef.current)) {
      this.numericInputRef.current.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove = (event) => {
    this.lastMouseEvent = event;

    if (this.state.isDragging) {
      const state = {lastMouseEvent: event};
      const isDraggingOnCanvas = event.target.tagName === 'CANVAS' && !event.target.closest('.add-menu-list');

      if (isDraggingOnCanvas !== this.state.isDraggingOnCanvas) state.isDraggingOnCanvas = isDraggingOnCanvas;

      this.setDragData(state);
    }
  };

  handleKeyPress = (event) => {
    // if (lib.event.keyPressed(event, 'ctrlcmd')) {
    //   this.isPressingCtrlCmd = true;

    //   if (this.views.canvas.isEditingDimensions) {
    //     this.toggleAddCustomDimensionIndicator(true);
    //   }
    // }
    if (document.activeElement.tagName === 'BODY') {
      if (lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'z')) {
        event.preventDefault();

        if (this.undoQueue.length) {
          let prevUndoQueue = [...this.undoQueue];

          this.undo(prevUndoQueue.pop());
          this.undoQueue = [...prevUndoQueue];
        }
        else {
          alert('Nothing to undo!');
        }
      }

      if (lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'c')) {
        event.preventDefault();

        // Do nothing if there are no selections
        if (_.every(this.state.activeEntities, ({resourceKey}) => _.includes(['product', 'container', 'archElement', 'projectGraphic', 'volume', 'room'], resourceKey))) {
          this.handleClipboardEvent({action: 'copy'});
        }
      }

      if (lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'x')) {
        event.preventDefault();

        // Do nothing if there are no selections
        if (_.every(this.state.activeEntities, ({resourceKey}) => _.includes(['product', 'container', 'archElement', 'projectGraphic', 'volume'], resourceKey))) {
          this.handleClipboardEvent({action: 'cut'});
        }
      }

      if (lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'v')) {
        event.preventDefault();

        if (!_.isEmpty(this.state.copiedObjects)) {
          this.handleClipboardEvent({action: 'paste'});
        }
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'd')) {
        event.preventDefault();

        this.updateVisibilityLayers({key: 'dimensions', isVisible: !this.state.visibilityLayers.dimensions});
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 't') && this.state.viewMode !== 'threeD') {
        event.preventDefault();

        var newViewMode = {
          front: 'both',
          top: 'both',
          both: 'top'
        }[this.state.viewMode];

        this.setViewMode(newViewMode);
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'v')) {
        event.preventDefault();

        var newViewMode = {
          front: 'both',
          top: 'front',
          both: 'front'
        }[this.state.viewMode];

        this.setViewMode(newViewMode);
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'o')) {
        event.preventDefault();

        this.toggleIsOrthographicLockEnabled();
      }

      var isPressingArrowKey = lib.event.keyPressed(event, 'right') || lib.event.keyPressed(event, 'left') || lib.event.keyPressed(event, 'up') || lib.event.keyPressed(event, 'down');

      var roomOrElevationActive = this.state.activeEntities.length === 1 && _.includes(['room', 'elevation'], this.state.activeEntities[0].resourceKey);
      if ((this.state.activeEntities.length === 0 || roomOrElevationActive) && lib.event.keyPressed(event, 'alt') && isPressingArrowKey) {
        event.preventDefault();

        if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'right')) {
          this.handleActiveHudElementIteration('next');
        }

        if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'left')) {
          this.handleActiveHudElementIteration('prev');
        }
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'm')) {
        event.preventDefault();

        this.toggleMeasuringTapeVisibility();
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'e')) {
        event.preventDefault();

        this.toggleDimensionEditing();
      }

      if (lib.event.keyPressed(event, 'alt') && lib.event.keyPressed(event, 'a')) {
        event.preventDefault();

        this.toggleAlignmentIndicators();
      }

      if (
        _.includes('abcdefghijklmnopqrstuvwxyz1234567890', event.key)
        && !lib.event.keyPressed(event, 'alt')
        && !lib.event.keyPressed(event, 'ctrlcmd')
        && !lib.event.keyPressed(event, 'shift')
        && !this.state.datumPopupShowing && !this.state.infoPopupShowing && !this.state.tolerancePopupShowing
      ) {
        this.handleSetFocusMenuSearch(true);
      }

      // if (lib.event.keyPressed(event, 'shift')) {
      //   if (isPressingArrowKey) {
      //     if (this.views.canvas.isObjectSelected) {
      //       this.views.canvas.isApplyingCustomTransform = true;
      //       this.views.canvas.arrowKeyEvent = event;

      //       this.views.canvas.toggleNumericInputVisibility(true);
      //     }
      //   }
      // }
    }
  };

  handleSetFocusMenuSearch =(value) => {
    this.setState({focusMenuSearch: value});
  }

  handleActiveHudElementIteration = (actionType) => {
    let newActiveViewEntityId;
    let {activeViewEntityResourceKey} = this.state;
    let hudListArray = this.hudListArray;

    const index = _.findIndex(hudListArray, this.activeViewEntity);

    if (actionType === 'prev') {
      newActiveViewEntityId = lib.array.prev(hudListArray, index).id;
    }
    else {
      newActiveViewEntityId = lib.array.next(hudListArray, index).id;
    }

    this.setActiveViewEntityId(activeViewEntityResourceKey, newActiveViewEntityId);
  };

  get hudListArray() {
    var hudListArray = [];

    var floorsUsingRank = _.some(this.props.floors, floor => floor.rank);

    var orderedFloors = _.orderBy(this.props.floors, [floorsUsingRank ? 'rank' : 'id'], ['asc']);

    if (this.state.activeViewEntityResourceKey === 'scope') {
      _.forEach(orderedFloors, floor => {
        var floorRooms = _.filter(this.props.rooms, {floorId: floor.id});
        var roomsUsingRank = _.some(floorRooms, room => room.rank);

        floorRooms = _.orderBy(floorRooms, [roomsUsingRank ? 'rank' : 'id'], ['asc']);
        hudListArray.push(..._.flatMap(floorRooms, r => _.values(Room.get('scopes', {room: r}))));
      });
    }
    else if (this.state.activeViewEntityResourceKey === 'elevation') {
      _.forEach(orderedFloors, floor => {
        var floorRooms = _.filter(this.props.rooms, {floorId: floor.id});
        var roomsUsingRank = _.some(floorRooms, room => room.rank);

        floorRooms = _.orderBy(floorRooms, [roomsUsingRank ? 'rank' : 'id'], ['asc']);
        hudListArray.push(..._.flatMap(floorRooms, r => Room.get('sortedElevations', {room: r})));
      });
    }
    else {
      if (this.state.activeViewEntityResourceKey === 'room') {
        _.forEach(orderedFloors, floor => {
          var floorRooms = _.filter(this.props.rooms, {floorId: floor.id});
          var roomsUsingRank = _.some(floorRooms, room => room.rank);

          floorRooms = _.orderBy(floorRooms, [roomsUsingRank ? 'rank' : 'id'], ['asc']);
          hudListArray.push(...floorRooms);
        });
      }
      else {
        hudListArray = orderedFloors;
      }
    }

    return hudListArray;
  }

  setThreeDMode = ({value}) => {
    this.setState({threeDMode: value});
  }

  setViewMode = (viewMode, activeViewEntityId, activeViewEntityResourceKey) => {
    if (!activeViewEntityId || !activeViewEntityResourceKey || !this.getActiveViewEntity({activeViewEntityResourceKey, activeViewEntityId})) {
      if (viewMode === 'front') activeViewEntityResourceKey = 'elevation';
      if (viewMode === 'top' || viewMode === 'threeD') activeViewEntityResourceKey = 'floor';
      if (viewMode === 'both') activeViewEntityResourceKey = 'room';
      if (viewMode === 'lite') activeViewEntityResourceKey = 'scope';

      activeViewEntityId = _.values(this.props[pluralize(activeViewEntityResourceKey)])[0]?.id;

      if (viewMode === 'front') {
        var elevationInRoom;
        const activeEntityId = _.get(_.first(this.state.activeEntities), 'id');

        if (this.getIsActiveEntity({resourceKey: 'elevation', id: activeEntityId})) {
          elevationInRoom = _.find(this.props[pluralize(activeViewEntityResourceKey)], {id: activeEntityId});
        }
        else {
          elevationInRoom = _.find(_.sortBy(this.props[pluralize(activeViewEntityResourceKey)], 'rank'), {roomId: _.get(this.room, 'id')});
        }

        if (elevationInRoom) activeViewEntityId = elevationInRoom.id;
      }
      else if (viewMode === 'lite') {
        var scopeInRoom;
        const activeEntityId = _.get(_.first(this.state.activeEntities), 'id');

        if (this.getIsActiveEntity({resourceKey: 'scope', id: activeEntityId})) {
          scopeInRoom = _.find(this.props[pluralize(activeViewEntityResourceKey)], {id: activeEntityId});
        }
        else {
          scopeInRoom = _.find(_.sortBy(this.props[pluralize(activeViewEntityResourceKey)], 'rank'), {roomId: _.get(this.room, 'id')});
        }

        if (scopeInRoom) activeViewEntityId = scopeInRoom.id;

      }
      else if (viewMode === 'both') {
        var roomInFloor = this.room;

        if (roomInFloor) activeViewEntityId = roomInFloor.id;
      }
      else if (viewMode === 'top') {
        var roomInFloor = this.room;

        if (roomInFloor) {
          var floorInProject = _.find(this.props.floors, {id: roomInFloor.floorId});

          if (floorInProject) activeViewEntityId = floorInProject.id;
        }
      }
    }

    if (activeViewEntityId) {
      this.setState({...this.state, viewMode, activeViewEntityId, activeViewEntityResourceKey, readyForViewOffsetUpdate: true});
    }
  };

  setActiveViewEntityId = (activeViewEntityResourceKey, activeViewEntityId) => {
    //HINT reset multi-select transformer
    this.freehandSelection = {
      visible: false,
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0
    };

    //HINT lite mode requested to be hidden for design
    if (activeViewEntityResourceKey === 'scope' && this.state.activeUserLense !== 'sales' && this.state.viewMode !== 'lite') {
      activeViewEntityResourceKey = 'room';

      var attemptedScope = _.find(this.props.scopes, {id: activeViewEntityId});

      if (attemptedScope) {
        activeViewEntityId = attemptedScope.roomId;
      }
    }

    if (activeViewEntityResourceKey === 'elevation') {
      this.setViewMode('front', activeViewEntityId, activeViewEntityResourceKey);
    }
    else if (activeViewEntityResourceKey === 'floor') {
      this.setViewMode(_.includes(['top', 'threeD'], this.state.viewMode) ? this.state.viewMode : 'top', activeViewEntityId, activeViewEntityResourceKey);
    }
    else if (activeViewEntityResourceKey === 'scope') {
      this.setViewMode('lite', activeViewEntityId, activeViewEntityResourceKey);
    }
    else if (activeViewEntityResourceKey === 'room') {
      if (this.state.viewMode === 'lite' && activeViewEntityId) {
        var scopeInRoom = _.find(_.sortBy(this.props.scopes, 'rank'), {roomId: activeViewEntityId});

        if (scopeInRoom) {
          this.setViewMode('lite', scopeInRoom.id, 'scope');
        }
        else {
          this.setViewMode('both', activeViewEntityId, activeViewEntityResourceKey);
        }
      }
      else {
        this.setViewMode('both', activeViewEntityId, activeViewEntityResourceKey);
      }
    }
  };

  setActiveEntities = ({entities = [], isMultiSelect = false}) => {
    let nextState = {
      ...this.state,
      activeDimensionData: null, activeDatumData: null, activeScalingToolData: null, activeProjectGraphicData: null, isDrawingScalingLine: false,
      activeEntities: [],
      preventVisibleEntitiesUpdate: _.some(entities, ['resourceKey', 'room']) ? false : true
    };

    var unclosedPolygonProjectGraphicSelected = this.activeEntities && this.activeEntities.length === 1 &&
      this.state.activeEntities[0].resourceKey === 'projectGraphic' && this.activeEntities[0].type === 'polygon' && !this.activeEntities[0].data.closed;
    var unclosedRoomSelected = this.activeEntities && this.activeEntities.length === 1 && this.state.activeEntities[0].resourceKey === 'room' && !this.activeEntities[0].plan.closed;

    if (!this.state.isMeasuringTapeVisible && !this.state.isAddingProjectGraphic && !this.state.isDrawingScalingLine && !this.state.drawingScopeData.isDrawing && !this.state.isAddingCustomDimension && !unclosedPolygonProjectGraphicSelected && !unclosedRoomSelected && !this.canvasData.isSpacePressed) {
      var multiSelectAllowed = _.every([...entities, ...this.state.activeEntities], entity => _.includes(['container', 'volume'], entity.resourceKey));

      if (multiSelectAllowed && isMultiSelect) {
        let leftEntities = [...this.state.activeEntities];
        let rightEntities = entities;

        let leftDuplicateIndexes = [];
        let rightDuplicateIndexes = [];

        _.forEach(rightEntities, (rightEntity, rightEntityIndex) => {
          const leftDuplicateIndex = _.findIndex(leftEntities, leftEntity => leftEntity.resourceKey === rightEntity.resourceKey && leftEntity.id === rightEntity.id);

          if (leftDuplicateIndex !== -1) {
            leftDuplicateIndexes.push(leftDuplicateIndex);
            rightDuplicateIndexes.push(rightEntityIndex);
          }
        });

        _.forEach(leftDuplicateIndexes, leftDuplicateEntityIndex => leftEntities[leftDuplicateEntityIndex] = null);
        _.forEach(rightDuplicateIndexes, rightDuplicateEntityIndex => rightEntities[rightDuplicateEntityIndex] = null);

        leftEntities = _.filter(leftEntities, leftEntity => _.size(leftEntity));
        rightEntities = _.filter(rightEntities, rightEntity => _.size(rightEntity));

        nextState.activeEntities = [...leftEntities, ...rightEntities];
      }
      else {
        if (_.size(entities)) {
          nextState.activeEntities = entities;
        }
      }

      if ((!nextState.activeEntities || nextState.activeEntities.length === 0) && _.get(this.canvasData, 'stage')) {
        var container = this.canvasData.stage.container();

        container.style.cursor = 'inherit';
      }

      this.setState(nextState);
    }
  };

  getIsActiveEntity = ({resourceKey, id}) => {
    const entityIndex = _.findIndex(this.state.activeEntities, {resourceKey, id});

    return entityIndex === -1 ? false : true;
  };

  getIncludesActiveEntity = ({resourceKey, id}) => {
    let entityIndex = -1;

    if (resourceKey && id) {
      entityIndex = _.findIndex(this.state.activeEntities, {resourceKey, id});
    }
    else if (resourceKey) {
      entityIndex = _.findIndex(this.state.activeEntities, {resourceKey});
    }
    else if (id) {
      entityIndex = _.findIndex(this.state.activeEntities, {id});
    }

    return entityIndex === -1 ? false : true;
  };

  setActiveDimensionData = (activeDimensionData) => {
    if (!this.state.isMeasuringTapeVisible) {
      this.setState({
        ...this.state,
        activeDimensionData, activeDatumData: null, activeProjectGraphicData: null, activeScalingToolData: null, isDrawingScalingLine: false,
        activeEntities: [], preventVisibleEntitiesUpdate: true
      });
    }
  };

  setActiveScalingToolData = (activeScalingToolData={}) => {
    var {projectGraphic} = activeScalingToolData;

    this.setState({
      ...this.state,
      activeDimensionData: null, activeDatumData: null, activeProjectGraphicData: null, activeScalingToolData, isDrawingScalingLine: true,
      preventVisibleEntitiesUpdate: true
    });
  };

  handleScalingToolLineDraw = (scalingLine) => {
    this.setState({...this.state, isDrawingScalingLine: false, scalingToolPopupShowing: true, showNumericInput: true, activeScalingToolData: {...this.state.activeScalingToolData, scalingLine}});
  };

  handleFinishScalingTool({value}) {
    var {projectGraphic, scalingLine} = this.state.activeScalingToolData;
    var {from, to} = scalingLine;

    // Calculate the Euclidean distance (pixel length of the drawn line)
    let pixelLength = Math.sqrt(Math.pow(to.x - from.x, 2) + Math.pow(to.y - from.y, 2));

    // Compute the scale factor
    let scaleFactor = value / pixelLength;

    this.pushToUndoQueue({type: 'projectGraphic', eventKey: 'transformEnd', instance: projectGraphic});

    this.props.updateProjectGraphic({id: projectGraphic.id, props: {data: {...projectGraphic.data, size: lib.object.multiply(projectGraphic.data.size, scaleFactor)}}});

    this.setState({...this.state, activeScalingToolData: null, showNumericInput: false, isDrawingScalingLine: false});
  }

  setActiveDatumData = (activeDatumData) => {
    if (!this.state.isMeasuringTapeVisible) {
      this.setState({
        ...this.state,
        activeDatumData, activeDimensionData: null, activeProjectGraphicData: null, activeScalingToolData: null, isDrawingScalingLine: false,
        activeEntities: [], preventVisibleEntitiesUpdate: true
      });
    }
  };

  setActiveProjectGraphicData = (activeProjectGraphicData) => {
    if (!this.state.isMeasuringTapeVisibile) {
      this.setState({
        ...this.state,
        activeProjectGraphicData, activeDimensionData: null, activeEntityResourceKey: null, activeEntityId: null, preventVisibleEntitiesUpdate: true, isDrawingScalingLine: false,
      });
    }
  };

  handleDeselect = ({override = false, updateIssues = true} = {}) => {
    if (this.state.showNumericInput) this.toggleNumericInputVisibility(false);

    var {activeEntities, activeDimensionData, activeDatumData, activeScalingToolData,
      drawingScopeData, activeProjectGraphicData, isDrawingScalingLine
    } = this.state;

    if (override || (!isDrawingScalingLine && (_.size(activeEntities) !== 0 || activeDimensionData || activeDatumData || activeProjectGraphicData || activeScalingToolData || drawingScopeData?.isDrawing))) {
      this.setState({...this.state, activeEntities: [], activeDimensionData: null, activeDatumData: null, isDrawingScalingLine: false, activeScalingToolData: null, activeProjectGraphicData: null, preventVisibleEntitiesUpdate: true, drawingScopeData: {...this.state.drawingScopeData, isDrawing: false}});
    }

    if (updateIssues) this.setState({showIssues: false});
  };

  handleCanvasMouseDown = ({event, inAddMode}) => {
    var unclosedPolygonProjectGraphicSelected = this.activeEntities && this.activeEntities.length === 1 &&
    this.state.activeEntities[0].resourceKey === 'projectGraphic' && this.activeEntities[0].type === 'polygon' && !this.activeEntities[0].data.closed;

    if (_.includes(['top', 'both'], this.state.viewMode) && !inAddMode && !this.state.isMeasuringTapeVisible && !unclosedPolygonProjectGraphicSelected && this.canvasData.isShifting && event.target === event.target.getStage() && this.freehandSelectionRectRef.current) {
      this.setState({multiSelectRectShowing: true});

      var pos = event.target.getStage().getPointerPosition();

      this.freehandSelection.visible = true;
      this.freehandSelection.x1 = pos.x;
      this.freehandSelection.y1 = pos.y;
      this.freehandSelection.x2 = pos.x;
      this.freehandSelection.y2 = pos.y;

      this.updateSelectionRect();
    }
  };

  handleCanvasMouseUp = (e) => {
    if (this.state.multiSelectRectShowing) {
      this.freehandSelectionOldPos = null;
      this.freehandSelection.visible = false;

      var { x1, x2, y1, y2 } = this.freehandSelection;
      var moved = x1 !== x2 || y1 !== y2;

      if (!moved) {
        this.updateSelectionRect();
      }

      this.freehandSelection = {
        visible: false,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      };

      var containers = this.canvasData.layer.find('.container');
      var volumes = this.canvasData.layer.find('.volume');
      var selectedEntities = [
        ..._.filter(this.state.activeEntities, activeEntity => _.includes(['container', 'volume'], activeEntity.resourceKey))
      ];

      var selBox = this.freehandSelectionRectRef.current.getClientRect();
      var elements = [...containers, ...volumes];

      elements.forEach((elementNode) => {
        const elBox = elementNode.getClientRect();

        if (Konva.Util.haveIntersection(selBox, elBox)) {
          selectedEntities.push(elementNode.attrs.resourceData);
        }
      });

      //HINT: we are selecting canvas-transformer and it renders 2 transformers. so we get two same objects or rects.

      this.setActiveEntities({entities: _.uniqBy(selectedEntities, 'id')});

      this.updateSelectionRect();
      this.setState({multiSelectRectShowing: false});
    }
  };

  handleCanvasMouseMove = (e) => {
    if (this.state.multiSelectRectShowing) {
      if (!this.freehandSelection.visible) {
        return;
      }
      var pos = e.target.getStage().getPointerPosition();

      this.freehandSelection.x2 = pos.x;
      this.freehandSelection.y2 = pos.y;

      this.updateSelectionRect();
    }
  };

  updateSelectionRect = () => {
    var node = this.freehandSelectionRectRef.current;

    node.setAttrs({
      visible: this.freehandSelection.visible,
      x: Math.min(this.freehandSelection.x1, this.freehandSelection.x2),
      y: Math.min(this.freehandSelection.y1, this.freehandSelection.y2),
      width: Math.abs(this.freehandSelection.x1 - this.freehandSelection.x2),
      height: Math.abs(this.freehandSelection.y1 - this.freehandSelection.y2),
      fill: '#9BCCE1',
      opacity: 0.5
    });

    node.getLayer().batchDraw();
  };

  getActiveViewEntity({activeViewEntityResourceKey, activeViewEntityId}) {
    return activeViewEntityResourceKey && this.props[pluralize(activeViewEntityResourceKey)][activeViewEntityId];
  }

  get activeEntities() {
    const {activeEntities} = this.state;
    let activeEntitiesData;

    if (_.size(activeEntities)) {
      activeEntitiesData = _.map(activeEntities, (entity) => {
        if (_.includes(['elevation', 'room', 'floor'], entity.resourceKey)) {
          return this.props[pluralize(entity.resourceKey)][entity.id];
        }
        else {
          return getDependencies({dependencyKeys: [entity.resourceKey]}, ({state, useDependency}) => {
            return {
              [entity.resourceKey]: () => _.get(state.resources, `[${pluralize(entity.resourceKey)}].byId[${entity.id}]`)
            };
          })[entity.resourceKey];
        }
      });
    }

    return activeEntitiesData;
  }

  get activeViewEntity() {
    var {activeViewEntityResourceKey, activeViewEntityId} = this.state;

    return this.getActiveViewEntity({activeViewEntityResourceKey, activeViewEntityId});
  }

  toggleDatumPopup = (showDatums = false) => {
    this.setState({datumPopupShowing: !this.state.datumPopupShowing});

    if (!this.state.visibilityLayers.datums && showDatums) {
      this.updateVisibilityLayers({key: 'datums', isVisible: true});
    }
  };

  toggleDimensionEditing = () => {
    const editingDimensions = !this.state.editingDimensions;
    let {isAddingCustomDimension} = this.state;

    if (!editingDimensions) isAddingCustomDimension = false;

    this.setState({editingDimensions, isAddingCustomDimension});

    if (!this.state.visibilityLayers.dimensions && editingDimensions) {
      this.updateVisibilityLayers({key: 'dimensions', isVisible: true});
    }
  };

  toggleInfoPopupShowing = ({entity, entityResourceKey} = {}) => {
    this.setState({infoPopupShowing: !this.state.infoPopupShowing, productInfoTarget: entity, productInfoResourceKey: entityResourceKey});
  };

  toggleTolerancePopupShowing = ({updateDimensionsData, targetId: activeDimensionTargetId, tolerance, shouldHoldTo}) => {
    this.setState({tolerancePopupShowing: true, updateDimensionsData, activeDimensionTargetId, tolerance, shouldHoldTo});
  };

  toggleDimEditsCopyPopupShowing = ({dimTransferFrom=undefined, dimTransferTo=undefined}={}) => {
    this.setState({copyDimEditsPopupShowing: !this.state.copyDimEditsPopupShowing, dimTransferFrom, dimTransferTo});
  };

  toggleAlignmentIndicators = () => {
    this.setState({showingAlignmentIndicators: !this.state.showingAlignmentIndicators});
  };

  toggleIsOrthographicLockEnabled = () => this.setState({isOrthographicLockEnabled: !this.state.isOrthographicLockEnabled});

  handleDimensionToleranceChange = ({tolerance, shouldHoldTo}) => {
    if (this.state.updateDimensionsData) {
      this.state.updateDimensionsData(dimensionsData => ({
        ...dimensionsData,
        tolerancesById: {...dimensionsData.tolerancesById, [this.state.activeDimensionTargetId]: tolerance},
        shouldHoldTosById: {...dimensionsData.shouldHoldTosById, [this.state.activeDimensionTargetId]: shouldHoldTo}
      }));

      this.setState({
        tolerancePopupShowing: false,
        tolerance: undefined,
        shouldHoldTo: false,
        activeDimensionTargetId: undefined,
        updateDimensionsData: undefined
      });

      if (!this.state.visibilityLayers.bindingDimensions) {
        this.updateVisibilityLayers({key: 'bindingDimensions', isVisible: true});
      }
    }
  };

  get floor() {
    return Room.get('floor', {room: this.room});
  }

  get room() {
    let {viewMode} = this.state;
    let room;

    if (_.values(this.props.rooms).length === 1) {
      room = _.values(this.props.rooms)[0];
    }
    else if (viewMode === 'front') {
      room = Elevation.get('room', {elevation: this.activeViewEntity});
    }
    else if (viewMode === 'lite') {
      var roomId;
      if (this.state.activeViewEntityResourceKey === 'scope') {
        roomId = this.activeViewEntity.roomId;
      }
      if (this.state.activeViewEntityResourceKey === 'room') {
        roomId = this.activeViewEntity.id;
      }
      else if (_.size(this.state.activeEntities) === 1 && this.getIncludesActiveEntity({resourceKey: 'room'})) {
        roomId = _.get(this.activeEntities, '[0].id');
      }
      else {
        roomId = _.values(this.props.rooms)[0].id;
      }

      room = this.props.rooms[roomId];
    }
    else if (viewMode === 'top' || viewMode === 'threeD') {
      if (_.size(this.state.activeEntities) === 1 && this.getIncludesActiveEntity({resourceKey: 'room'})) {
        room = _.get(this.activeEntities, '0');
      }
      else {
        let roomId;
        const nearestEntity = this.getNearestEntity({});
        if (nearestEntity) {
          const {entity, resourceKey} = nearestEntity;
          roomId = resourceKey === 'room' ? entity.id : entity.roomId;
        }
        else {
          roomId = _.values(this.props.rooms)[0].id;
        }

        room = this.props.rooms[roomId];
      }
    }
    else if (viewMode === 'both') {
      room = this.activeViewEntity;
    }

    return room;
  }

  setDragData = (dragData) => {
    let nextState = {...this.state, ...dragData};
    let dragEntityRotation = 0;

    if (nextState.isDragging && this.canvasData) {
      const lastMouseEvent = dragData?.lastMouseEvent || this.lastMouseEvent || {layerX: 0, layerY: 0};
      const {dragItem, activeEntities} = nextState;

      let dragEntityPosition = _.mapValues(PositionHelper.toReal({x: lastMouseEvent.layerX, y: lastMouseEvent.layerY}, this.canvasData), point => lib.round(point, {toNearest: this.state.precision}));

      if (dragItem.resourceKey === 'archetype') {
        var minX = _.minBy(dragItem.props.plan.points, 'x').x;
        var minY = _.minBy(dragItem.props.plan.points, 'y').y;
        var maxX = _.maxBy(dragItem.props.plan.points, 'x').x;
        var maxY = _.maxBy(dragItem.props.plan.points, 'y').y;

        let updatedDragItem = {
          ...dragItem,
          props: {
            ...dragItem.props,
            plan: {...dragItem.props.plan, position: lib.object.difference(dragEntityPosition, {x: (maxX + minX) / 2, y: (maxY + minY) / 2})}
          }
        };

        dragData = {...nextState, dragItem: updatedDragItem, dragEntityPosition};
      }
      else {
        let snapToWall = true;
        let snapToFloor = true;

        const nearestEntity = this.getNearestEntity({position: dragEntityPosition});

        if (dragItem.resourceKey === 'container') snapToFloor = Container.getTypeDataFor({container: dragItem.props}).snapToFloor;
        if (dragItem.resourceKey === 'archElement') {
          const typeData = ArchElement.getTypeData({archElement: dragItem.props});
          snapToWall = typeData.classification !== 'floor';
          snapToFloor = typeData.snapToFloor;
        }
        if (dragItem.resourceKey === 'volume') snapToFloor = false;

        //HINT Snapping
        const snappedDelta = {x: 0, y: 0};
        const {candidateSnapPositions} = this.getSnapData();
        const leastSnapDistance = {x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER};

        let dimensions, sizeKeys, size;

        if (dragItem.resourceKey !== 'archElement') {
          dimensions = dragItem.props.dimensions;
          sizeKeys = this.state.viewMode === 'front' ? ['width', 'height'] : nearestEntity?.resourceKey === 'room' ? ['width', 'depth'] : ['width', 'height'];
          size = {width: dimensions[sizeKeys[0]], height: dimensions[sizeKeys[1]]};
        }
        else {
          size = ArchElement.getSize({archElement: dragItem.props, viewKey: nearestEntity.resourceKey === 'room' ? 'top' : 'front'});
        }

        const pointPositions = [
          {x: 0, y: 0}, //top-left
          {x: size.width, y: 0}, //top-right
          {x: size.width / 2, y: size.height / 2}, //center
          {x: size.width, y: size.height}, //bottom-right
          {x: 0, y: size.height} //bottom-left
        ];
        //Iterate through points and check if any of them snap
        //If so, update the transform to be the snapped transform
        _.map(pointPositions, pointPosition => lib.object.sum(pointPosition, dragEntityPosition)).forEach(pointPosition => {
          const lastPosition = _.clone(pointPosition);

          const {position: snappedPosition, snapped, snapData} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode: this.canvasData.isShifting}, this.canvasData);
          const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

          //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
          if (snapped.x !== undefined && snapData.x.candidateData?.distance < leastSnapDistance.x) {
            leastSnapDistance.x = snapData.x.candidateData.distance;
            snappedDelta.x = snappedPointDelta.x;
          }
          if (snapped.y !== undefined && snapData.y.candidateData?.distance < leastSnapDistance.y) {
            leastSnapDistance.y = snapData.y.candidateData.distance;
            snappedDelta.y = snappedPointDelta.y;
          }
        });

        dragEntityPosition = lib.object.sum(dragEntityPosition, snappedDelta);

        if (nearestEntity.resourceKey === 'room' && dragItem.resourceKey !== 'product') {
          if (dragItem.resourceKey === 'archElement' && snapToWall) {
            const walls = Room.get('walls', {room: nearestEntity.entity});
            const dragEntityPositionInRoom = lib.object.difference(dragEntityPosition, nearestEntity.offset, nearestEntity.position, this.state.viewOffset);

            const nearestWall = _.minBy(_.values(walls), wall => lib.trig.distance({fromPoint: dragEntityPositionInRoom, toLine: Wall.getLine({wall}).inRoom}));
            const positionOnWall = lib.trig.nearestPoint({point: dragEntityPositionInRoom, onLine: Wall.getLine({wall: nearestWall}).inRoom});

            dragEntityPosition = lib.object.sum(positionOnWall, nearestEntity.offset, nearestEntity.entity.plan.position, this.state.viewOffset);
            dragEntityRotation = lib.trig.radiansToDegrees(lib.trig.normalize({radians: Wall.getAlpha({wall: nearestWall})})) + 90;
          }
        }
        else {
          if (dragItem.resourceKey === 'product') {
            const customOnMove = Product.getCustomOnMove({product: dragItem.props});
            var isAddingInBoth = this.state.viewMode === 'both';
            var elevation, container, parentProduct;

            if (_.size(activeEntities) === 1 && this.getIncludesActiveEntity({resourceKey: 'container'})) {
              container = this.activeEntities[0];
            }
            else if (_.size(activeEntities) === 1 && this.getIncludesActiveEntity({resourceKey: 'product'})) {
              parentProduct = this.activeEntities[0];

              container = Product.get('container', {product: parentProduct});
            }

            if (isAddingInBoth) {
              if (nearestEntity.resourceKey === 'elevation') {
                elevation = nearestEntity.entity;
              }
              else {
                var elevationCandidates = _.filter(_.values(this.props.elevations), {roomId: this.activeViewEntity.id});
                elevation = _.find(elevationCandidates, elevationCandidate => {
                  return Container.isShowingFrontFor({container, elevation: elevationCandidate});
                }) || elevationCandidates[0];
              }
            }
            else {
              elevation = this.activeViewEntity;
            }

            var elevationOffset = _.find(this.state.visibleEntitiesDataByResourceKey.elevations, entityData => entityData.entity.id === elevation.id).offset;
            var viewOffset = elevationOffset;
            var offset = elevationOffset;

            if (_.size(activeEntities) === 1 && this.getIncludesActiveEntity({resourceKey: 'product'}) && customOnMove) {
              // const viewOffset = nearestEntity.offset;
              const containerDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});
              const parentPosition = parentProduct.position;
              const containerPosition = lib.object.sum(viewOffset, Elevation.getPosition2d({elevation, position3d: container.position}));

              let relativeOffset = lib.object.sum(this.state.viewOffset, containerPosition, parentPosition, containerDropzoneInset);

              const dragEntityRelativePosition = lib.object.difference(dragEntityPosition, relativeOffset);

              let position = dragEntityRelativePosition;

              if (Product.getIsOpencaseComponent({product: dragItem.props})) {
                const {gridPosition, position: customPosition} = customOnMove(dragEntityRelativePosition);

                dragItem.props.customData = {...dragItem.props.customData, gridPosition};

                position = customPosition;
              }
              else if (Product.getIsBarblockComponent({product: dragItem.props})) {
                const wrapInset = Barblock.getWrapInset({product: dragItem.props});

                relativeOffset = lib.object.sum(this.state.viewOffset, containerPosition, parentPosition, containerDropzoneInset, wrapInset);

                const dragEntityRelativePosition = lib.object.difference(dragEntityPosition, relativeOffset);

                position = customOnMove(dragEntityRelativePosition);
              }

              dragEntityPosition = lib.object.sum(position, relativeOffset, {y: -size.height});
            }

            // const offset = nearestEntity.offset;
            const containerPosition = Elevation.getPosition2d({elevation, position3d: container.position});
            const parentProductPosition = parentProduct?.position;
            const containerDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});

            const dropzoneInset = Product.getDropzoneInset({product: dragItem.props, viewKey: 'front', elevation, container, parentProduct});
            const dropzoneSize = Product.getDropzoneSize({product: dragItem.props, viewKey: 'front', container, parentProduct});

            const dropzone = {position: dropzoneInset, size: dropzoneSize};

            const dragEntityRelativePosition = lib.object.difference(dragEntityPosition, this.state.viewOffset, offset);

            var parentOrigin = lib.object.sum(containerPosition, parentProductPosition, containerDropzoneInset, offset);

            const maxX = dropzone.size.width + dropzone.position.x - dimensions.width;
            const minX = dropzone.position.x;
            const maxY = dropzone.size.height + dropzone.position.y - dimensions.height;
            const minY = dropzone.position.y;

            dragEntityPosition = {
              x: lib.number.constrain(dragEntityRelativePosition.x, {min: minX, max: maxX}),
              y: lib.number.constrain(dragEntityRelativePosition.y, {min: minY, max: maxY}),
            };

            const type = Product.getType({product: dragItem.props});

            if (type === 'horizontalBarblock' || type === 'rearFacingBarblock') {
              dragEntityPosition = lib.object.sum(Elevation.getPosition2d({elevation, position3d: container.position}), parentProduct?.position, {y: -dropzoneSize.height});

              dragItem.props.dimensions = container.dimensions;
            }
            else if (Product.getIsBarblockComponent({product: dragItem.props})) {
              const wrapInset = Barblock.getWrapInset({product: dragItem.props});

              parentOrigin = lib.object.sum(parentOrigin, wrapInset);
            }
            else if (container.type === 'daylightIsland') {
              const elevation = this.activeViewEntity;
              const viewOffset = nearestEntity.offset;
              const containerDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});
              const containerPosition = lib.object.sum(viewOffset, Elevation.getPosition2d({elevation, position3d: container.position}));

              let relativeOffset = lib.object.sum(this.state.viewOffset, containerPosition, containerDropzoneInset);

              const bayCount = {
                51: 2,
                76: 3,
                101: 4,
                126: 5
              }[container.dimensions.width || 51];

              dragEntityPosition = {
                x: lib.array.closest([..._.times(bayCount, n => (n * 25) + relativeOffset.x)], dragEntityPosition.x),
                y: -24 + relativeOffset.y
              };
            }
            else if (Product.getIsSnapToTopBacksplashComponent({product: dragItem.props})) {
              dragEntityPosition.y = dropzoneInset.y;
            }

            dragEntityPosition = lib.object.sum(dragEntityPosition, this.state.viewOffset, offset);
          }
          else if (snapToFloor) {
            let height;

            if (dragItem.resourceKey === 'archElement') {
              height = ArchElement.getSize({archElement: dragItem.props, viewKey: 'front'}).height;
            }
            else {
              height = dimensions.height;
            }

            dragEntityPosition.y = nearestEntity.offset.y + this.state.viewOffset.y - height;

            dragEntityRotation = lib.trig.normalize({degrees: Elevation.getRotation({elevation: nearestEntity.entity})});
          }
        }

        dragData = {...nextState, dragEntityPosition, dragEntityRotation, nearestEntity, parentOrigin};
      }
    }

    this.setState(dragData);
  };

  showAddMenu = () => {
    this.setState({projectTreeIsShowing: false});
    this.setDragData({isAdding: true});
  };

  hideAddMenu = () => {
    this.setState({isAdding: false});
  };

  showProjectTree = () => {
    this.setState({isAdding: false, projectTreeIsShowing: true});
  };

  hideProjectTree = () => {
    this.setState({projectTreeIsShowing: false});
  };

  showParameterEditor = () => {
    this.toggleParameterEditor(true);
  };

  hideParameterEditor = () => {
    this.toggleParameterEditor(false);
  };

  toggleParameterEditor = (value) => {
    this.setState({parameterEditorIsShowing: value});
  };

  handleCanvasDataChange = (canvasData) => {
    this.canvasData = canvasData; //WARNING hack to get setDragData access to canvasData
  };

  renderDragCanvasComponent = () => {
    var {dragItem, dragEntityPosition, dragEntityRotation, nearestEntity, visibilityLayers, activeDetailLevel} = this.state;

    if (dragItem.resourceKey === 'archetype') {
      return (<>
        {!dragItem.props.isAddingArchetypeToRoom && (<
          CanvasRoom {...{room: dragItem.props, position: dragItem.props.position}} viewOffset={{x: 0, y: 0}} isDraggable={true} visibilityLayers={{wallsAndArchElements: true}} activeDetailLevel={'schematic'} renderForContextCanvas={true}
        />)}
        {_.map(dragItem.roomData.containerInstances, containerInstance => {
          return (
            <CanvasScriptObject
              key={containerInstance.id}
              script={'rect({width: \'100%\', height: \'100%\'});'}
              position={lib.object.sum(dragItem.props.plan.position, {x: containerInstance.position.x, y: containerInstance.position.z})}
              size={{height: containerInstance.dimensions.depth, width: containerInstance.dimensions.width}}
              rotation={containerInstance.rotation}
              metaProps={
                {props: {...containerInstance}, dimensions: containerInstance.dimensions}
              }
              stroke={'black'}
              isDisabled={true}
            />
          );
        })}
        {_.map(dragItem.roomData.volumes, volume => {
          return (
            <CanvasScriptObject
              key={volume.id}
              script={'rect({width: \'100%\', height: \'100%\'});'}
              position={lib.object.sum(dragItem.props.plan.position, {x: volume.position.x, y: volume.position.z})}
              size={{height: volume.dimensions.depth, width: volume.dimensions.width}}
              rotation={volume.rotation}
              metaProps={
                {props: {...volume}, dimensions: volume.dimensions}
              }
              stroke={'black'}
              isDisabled={true}
            />
          );
        })}
      </>);
    }
    else {
      if (!nearestEntity) return null;

      var viewKey = nearestEntity.resourceKey === 'room' ? 'top' : 'front';
      var roomId = viewKey === 'top' ? nearestEntity.entity.id : nearestEntity.entity.roomId;
      var scopeId = _.values(this.props.scopesByRoomId[roomId])[0].id;
      var viewOffset = lib.object.sum(nearestEntity.viewOffset, this.state.viewOffset);

      const elevation = viewKey === 'front' ? nearestEntity.entity : undefined;

      if (dragItem.resourceKey === 'container') {
        return (
          <CanvasContainer
            {...{viewKey, elevation, activeDetailLevel, showOrnamentTopIndicators: true}}
            realPosition={dragEntityPosition}
            container={{scopeId, position: {x: 0, y: 0, z: 0}, ...dragItem.props, rotation: elevation ? lib.trig.normalize({degrees: Elevation.getRotation({elevation})}) : 0}}
          />
        );
      }
      else if (dragItem.resourceKey === 'volume') {
        return (
          <CanvasVolume
            {...{viewKey, elevation, activeDetailLevel}}
            realPosition={dragEntityPosition}
            volume={{scopeId, position: {x: 0, y: 0, z: 0}, ...dragItem.props}}
          />
        );
      }
      else if (dragItem.resourceKey === 'product') {
        return (
          <CanvasProduct
            {...{viewKey, elevation, viewOffset, activeDetailLevel}}
            realPosition={dragEntityPosition}
            product={{scopeId, position: {x: 0, y: 0, z: 0}, ...dragItem.props}}
          />
        );
      }
      else if (dragItem.resourceKey === 'archElement') {
        return (
          <CanvasArchElement
            {...{viewKey, elevation, activeDetailLevel}}
            realPosition={dragEntityPosition}
            dragRotation={dragEntityRotation}
            archElement={{roomId, position: {x: 0, y: 0, z: 0}, ...dragItem.props}}
          />
        );
      }
    }
  };

  toggleAccessoriesView = () => {
    this.setState({showAccessoriesView: !this.state.showAccessoriesView});
  };

  toggleMaterialFactorsPopup = () => {
    this.setState({showMaterialFactorsPopup: !this.state.showMaterialFactorsPopup});
  };

  toggleShareableLinkPopup = () => {
    this.setState({showShareableLinkPopup: !this.state.showShareableLinkPopup});
  };

  static getDerivedStateFromProps(_props, state) {
    var {viewMode, activeViewEntityResourceKey, activeViewEntityId, activeEntities, isLoaded, bothIsShowingElevations, preventVisibleEntitiesUpdate, showAccessoriesView} = state;
    var visibleEntitiesDataByResourceKey = {rooms: [], elevations: [], scopes: []};
    var viewOffset = {x: 0, y: 0}, activeViewEntity, activeEntitiesData;

    if (activeViewEntityResourceKey) activeViewEntity = _.get(_props, `[${pluralize(activeViewEntityResourceKey)}][${activeViewEntityId}]`);
    if (_.size(activeEntities)) {
      if (_.size(activeEntities) === 1 && _.includes(['elevation', 'room', 'floor'], _.get(activeEntities, '0.resourceKey'))) {
        activeEntitiesData = _.filter([_.get(_props, `[${pluralize(_.get(activeEntities, '0.resourceKey'))}][${_.get(activeEntities, '0.id')}]`)]);
      }
      else {
        activeEntitiesData = [getDependencies({dependencyKeys: [_.get(activeEntities, '0.resourceKey')]}, ({state, useDependency}) => {
          return {
            [_.get(activeEntities, '0.resourceKey')]: () => _.get(state.resources, `[${pluralize(_.get(activeEntities, '0.resourceKey'))}].byId[${_.get(activeEntities, '0.id')}]`)
          };
        })[_.get(activeEntities, '0.resourceKey')]];
      }
    }

    if (!_.size(activeEntitiesData)) {
      activeEntities = [];
    }

    if (!activeViewEntity && isLoaded) {
      activeViewEntityResourceKey = {
        front: 'elevation',
        both: 'room',
        top: 'floor',
        lite: 'scope'
      }[viewMode] || 'floor';

      activeViewEntity = _.values(_props[pluralize(activeViewEntityResourceKey)])[0];
      activeViewEntityId = activeViewEntity.id;
    }

    var entityFor = ({room, elevation, position, offset, relativeOutline}) => {
      if (room) return ({outline: _.map(relativeOutline, point => lib.object.sum(position, offset, point)), position, offset, resourceKey: 'room', entity: room});
      if (elevation) return ({outline: _.map(relativeOutline, point => lib.object.sum(position, offset, point)), position, offset, resourceKey: 'elevation', entity: elevation});
    };

    var getDataForOutlines = (outlines) => {
      var points = _.flatten(outlines);
      var xs = _.map(points, 'x'), ys = _.map(points, 'y');
      var minX = _.min(xs), minY = _.min(ys);
      var maxX = _.max(xs), maxY = _.max(ys);
      var size = {width: maxX - minX, height: maxY - minY};

      return {offset: {x: lib.round((-minX - size.width * 0.5)) || 0, y: lib.round((-minY - size.height * 0.5)) || 0}, size};
    };

    if (!preventVisibleEntitiesUpdate && !showAccessoriesView) {
      if (viewMode === 'both') {
        var room = activeViewEntity;
        var elevations = [];

        try {
          elevations = Room.get('sortedElevations', {room: activeViewEntity});
        }
        catch (error) {
          global.handleError({error, info: {componentStack: error.stack}, message: `getting elevations for room ${_.get(room, 'id')}`});
        }

        var {offset: viewOffset, size: roomSize} = getDataForOutlines([_.map(room.plan.points, point => lib.object.sum(room.plan.position, point))]);

        visibleEntitiesDataByResourceKey.rooms = [entityFor({room, position: room.plan.position, offset: {x: 0, y: 0}, relativeOutline: room.plan.points})];

        if (bothIsShowingElevations) {
          var offsetsBySide = {};

          _.forEach(elevations, (elevation) => {
            try {
              var relativeOutline = Elevation.getOutline({elevation});
              var alpha = Elevation.getAlpha({elevation});
              var angle = Math.round(lib.trig.normalize({radians: alpha + Math.PI}) / Math.PI * 180 / 90) * 90;
              var axisKey = angle === 90 || angle === 270 ? 'x' : 'y';
              var otherAxisKey = axisKey === 'x' ? 'y' : 'x';
              var sizeKey = axisKey === 'x' ? 'width' : 'height';
              const bottomProjectionHeight = Elevation.bottomProjectionHeightFor({elevation, drawingsMode: state.visibilityLayers.projections ? 'production' : 'client'});
              const topProjectionHeight = Elevation.topProjectionHeightFor({elevation, drawingsMode: state.visibilityLayers.projections ? 'production' : 'client'});
              var elevationSize = {width: Elevation.getWidth({elevation}), height: Elevation.getHeight({elevation}) + topProjectionHeight + bottomProjectionHeight};
              var entitySize = offsetsBySide[angle] ? elevationSize[sizeKey] : (elevationSize[sizeKey] / 2 + roomSize[sizeKey] / 2);
              var scalar = angle === 0 || angle === 270 ? -1 : 1;
              var spacing = 100;

              var offset = offsetsBySide[angle] = {[axisKey]: scalar * (entitySize + spacing) + _.get(offsetsBySide[angle], axisKey, 0) + (axisKey === 'y' ? -30 : 0), [otherAxisKey]: 0};

              visibleEntitiesDataByResourceKey.elevations.push(entityFor({elevation, position: {x: 0, y: 0}, offset: lib.object.difference({...offset}, viewOffset, {x: lib.round(elevationSize.width / 2), y: lib.round((elevationSize.height / -2) + topProjectionHeight - bottomProjectionHeight)}), relativeOutline}));
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `elevation ${_.get(elevation, 'id')}`});
            }
          });
        }
      }
      else if (viewMode === 'lite') {
        if (activeViewEntity !== undefined) {
          if (activeViewEntityResourceKey === 'scope') {
            var scopes = [activeViewEntity];

            // var leftCumulativeScopeHeight = 0;
            // var rightCumulativeScopeHeight = 0;

            //TODO support multiple scopes in lite mode at the same time
            visibleEntitiesDataByResourceKey.scopes = _.map(scopes, (scope, i) => {
              var {containers} = Scope.get(['containers'], {scope});
              // var isLeft = i % 2 === 0;
              // var scopeHeight = (_.chunk(_.values(containers), 4).length * 200);
              // var scopePosition = {x: isLeft ? 0 : 1000, y: isLeft ? leftCumulativeScopeHeight : rightCumulativeScopeHeight};
              // var offset = {x: 0, y: -scopeHeight / 3};

              // var data = {entity: scope, position: scopePosition, offset, outline: _.map([{x: 0, y: 0}, {x: 2000, y: 0}, {x: 2000, y: scopeHeight}, {x: 0, y: scopeHeight}], point => lib.object.sum(scopePosition, offset, point))}

              // if (isLeft) {
              //   leftCumulativeScopeHeight += scopeHeight;
              // }
              // else {
              //   rightCumulativeScopeHeight += scopeHeight;
              // }

              var cellHeight = _.max([..._.map(containers, 'dimensions.height')]) + 10;
              var cellWidth = _.max([..._.map(containers, 'dimensions.width')]) + 10;

              var scopeHeight = (_.chunk(_.values(containers), 4).length * cellHeight);
              var scopePosition = {x: 0, y: 0};
              var offset = {x: 0, y: -cellHeight};

              var data = {entity: scope, position: scopePosition, offset, resourceKey: 'scope', outline: _.map([{x: 0, y: 0}, {x: 2000, y: 0}, {x: 2000, y: scopeHeight}, {x: 0, y: scopeHeight}], point => lib.object.sum(scopePosition, offset, point))};

              return data;
            });
          }
          else {
            var rooms = [];

            try {
              rooms = activeViewEntityResourceKey === 'room' ? [activeViewEntity] : _.reject(Floor.get('rooms', {floor: activeViewEntity}), {isTemporaryArchetype: 1});
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `getting rooms ${_.get(activeViewEntity, 'id')}`});
            }

            var leftCumulativeRoomHeight = 0;
            var rightCumulativeRoomHeight = 0;

            _.forEach(rooms, (room, i) => {
              try {
                var containers = Room.get('containers', {room});
                var isLeft = i % 2 === 0;
                var roomHeight = (_.chunk(containers, 4).length * 200);
                var roomPosition = {x: isLeft ? 0 : 1000, y: isLeft ? leftCumulativeRoomHeight : rightCumulativeRoomHeight};
                var offset = {x: -500, y: roomHeight / 2};

                var data = {entity: room, position: roomPosition, offset, resourceKey: 'room', outline: _.map([{x: 0, y: 0}, {x: 2000, y: 0}, {x: 2000, y: roomHeight}, {x: 0, y: roomHeight}], point => lib.object.sum(roomPosition, offset, point))};

                if (isLeft) {
                  leftCumulativeRoomHeight += roomHeight;
                }
                else {
                  rightCumulativeRoomHeight += roomHeight;
                }

                visibleEntitiesDataByResourceKey.rooms.push(data);
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: `getting data for room ${_.get(room, 'id')}`});
              }
            });
          }
        }
      }
      else if (viewMode === 'top' || viewMode === 'threeD') {
        if (activeViewEntity !== undefined) {
          var rooms = [];

          try {
            var rooms = _.reject(Floor.get('rooms', {floor: activeViewEntity}), {isTemporaryArchetype: 1});
          }
          catch (error) {
            global.handleError({error, info: {componentStack: error.stack}, message: `getting rooms for floor ${_.get(activeViewEntity, 'id')}`});
          }

          // filter out rooms based on isaddingpoints and id === activeEntityId
          //TODO in the future don't need to filter since sometimes people won't be drawing rooms
          rooms = _.filter(rooms, room => {
            return (room.id === (_.size(activeEntities) === 1 ? _.get(activeEntities, '0.id') : null) && room.plan.points.length === 0) || room.plan.points.length;
          });

          var {offset: viewOffset} = getDataForOutlines(_.map(rooms, room => _.map(room.plan.points, point => lib.object.sum(room.plan.position, point))));

          _.forEach(rooms, room => {
            try {

              visibleEntitiesDataByResourceKey.rooms.push(entityFor({room, position: room.plan.position, offset: {x: 0, y: 0}, relativeOutline: room.plan.points}));
            }
            catch (error) {
              global.handleError({error, info: {componentStack: error.stack}, message: `getting data for room ${_.get(room, 'id')}`});
            }
          });
        }
      }
      else if (viewMode === 'front') {
        var elevation = activeViewEntity;

        try {
          var relativeOutline = Elevation.getOutline({elevation});

          var {offset: viewOffset} = getDataForOutlines([relativeOutline]);

          visibleEntitiesDataByResourceKey.elevations = [entityFor({elevation, offset: {x: 0, y: 0}, relativeOutline})];
        }
        catch (error) {
          global.handleError({error, info: {componentStack: error.stack}, message: `rendering elevation ${_.get(elevation, 'id')}`});
        }
      }
    }
    else {
      if (!showAccessoriesView) {
        visibleEntitiesDataByResourceKey = state.visibleEntitiesDataByResourceKey;
        viewOffset = state.viewOffset;
      }
    }

    var nextState = {visibleEntitiesDataByResourceKey, activeEntities, activeViewEntityId, activeViewEntityResourceKey, readyForViewOffsetUpdate: false, preventVisibleEntitiesUpdate: false};

    if ((state.readyForViewOffsetUpdate || !state.viewOffset) && !_.isEqual(nextState.viewOffset, viewOffset)) {
      nextState.viewOffset = viewOffset;
    }

    return nextState;
  }

  getNearestEntity = ({position}) => {
    const {visibleEntitiesDataByResourceKey} = this.state;

    if (!this.lastMouseEvent || !this.canvasData) return undefined;

    const mousePosition = lib.object.difference(
      position || PositionHelper.toReal({x: this.lastMouseEvent.layerX, y: this.lastMouseEvent.layerY}, this.canvasData),
      this.state.viewOffset
    );

    let nearestEntity = _.sortBy([...visibleEntitiesDataByResourceKey.scopes, ...visibleEntitiesDataByResourceKey.elevations, ...visibleEntitiesDataByResourceKey.rooms], ({outline}) => {
      return _.min(_.map(outline, (point, p) => {
        return lib.trig.distance({fromPoint: mousePosition, toLine: {from: point, to: lib.array.next(outline, p)}});
      }));
    })[0];

    if (nearestEntity?.entity.id !== this.lastNearestEntity?.entity.id && nearestEntity?.entity.resourceKey !== this.lastNearestEntity?.entity.resourceKey) {
      nearestEntity = this.lastNearestEntity;
    }
    else {
      this.lastNearestEntity = nearestEntity;
    }

    return nearestEntity;
  };

  getSnapData = () => {
    const {viewMode, activeEntities, activeDimensionData, isAddingCustomDimension} = this.state;
    const nearestEntity = this.getNearestEntity({});

    let sourceEntity, sourceEntityId, sourceSnapDataSourceEntity, sourceEntityResourceKey, multipleEntitiesSelected;

    if (this.state.drawingScopeData.isDrawing) {
      return {
        candidateSnapPositions: [],
        candidateSnapAngles: [0, 90, 180, 270, 360],
        sourceSnapPositions: []
      };
    }
    else if (this.state.isDragging) {
      const {dragItem} = this.state;

      const viewKey = nearestEntity.resourceKey === 'room' ? 'top' : 'front';
      const roomId = viewKey === 'top' ? nearestEntity.entity.id : nearestEntity.entity.roomId;
      const scopeId = _.values(this.props.scopesByRoomId[roomId])[0].id;

      sourceEntityResourceKey = dragItem.resourceKey;

      if (dragItem.resourceKey === 'container' || dragItem.resourceKey === 'product' || dragItem.resourceKey === 'volume') {
        sourceEntity = {scopeId, position: this.state.dragEntityPosition, ...dragItem.props};
      }
      else if (dragItem.resourceKey === 'archElement') {
        sourceEntity = {roomId, position: this.state.dragEntityPosition, ...dragItem.props};
      }

      sourceSnapDataSourceEntity = sourceEntity;
      sourceEntity = _.omit(sourceEntity, ['position', 'size', 'customData']);
    }
    else if (activeEntities.length > 1) {
      multipleEntitiesSelected = true;
    }
    else if (activeEntities.length === 1) {
      let activeEntity = this.activeEntities[0];
      sourceSnapDataSourceEntity = activeEntity;
      sourceEntity = (viewMode === 'lite' && activeEntities[0].resourceKey === 'container') ? activeEntity : _.omit(activeEntity, ['position', 'size', 'customData']);
      sourceEntityId = activeEntities[0].id;
      sourceEntityResourceKey = activeEntities[0].resourceKey;
    }

    var dependencies = this.getSnapDependenciesFor({sourceEntity, sourceEntityResourceKey, nearestEntity, viewMode, activeDimensionData, isAddingCustomDimension});
    var candidateSnapData = this.calculateSnapData({viewMode, nearestEntity, multipleEntitiesSelected, activeDimensionData, isAddingCustomDimension, activeEntities, sourceEntityResourceKey, sourceEntityId, sourceEntity, dependencies});
    var sourceSnapData = this.calculateSourceSnapData({viewMode, nearestEntity, multipleEntitiesSelected, activeEntities, sourceEntityResourceKey, sourceEntity: sourceSnapDataSourceEntity || sourceEntity});

    return {...candidateSnapData, ...sourceSnapData};
  };

  //TODO add active dimension data when relevant
  getSnapDependenciesFor = ({nearestEntity, sourceEntity, sourceEntityResourceKey, viewMode, activeDimensionData, isAddingCustomDimension}) => {
    if (nearestEntity === undefined) return {};

    if (!sourceEntity) sourceEntity = this.activeEntity;

    var dependencies = {
      visibleEntitiesDataByResourceKey: this.state.visibleEntitiesDataByResourceKey,
      visibilityLayers: this.state.visibilityLayers
    };

    if (viewMode === 'lite') {
      if (sourceEntityResourceKey === 'container') {
        dependencies = {
          ...dependencies,
          ...Container.get(['products'], {container: sourceEntity})
        };
      }
      else if (sourceEntityResourceKey === 'product') {
        dependencies = {
          ...dependencies,
          ...Product.get(['parentProduct', 'siblings', 'container'], {product: sourceEntity})
        };
      }
    }
    else {
      if (activeDimensionData && _.includes(['room', 'elevation'], nearestEntity.resourceKey)) {
        // getElevationDimensionSets({elevation, canvasData, projectData, showProjections, activeDetailLevel, considerSummarizationChange: false});
        dependencies.dimensions = _.get(global.dimensionsByEntityType, `${nearestEntity.resourceKey}.${nearestEntity.entity.id}`)
      }

      //HINT include dxf when looking at a room or floor
      if (this.state.visibilityLayers.stencil) {
        dependencies.floor = this.floor;
      }
      //HINT dependencies when dragging in a room
      if (sourceEntityResourceKey !== 'room' && nearestEntity.resourceKey === 'room') {
        dependencies = {
          ...dependencies,
          ...Room.get(['archElements', 'containers', 'wallSets', 'volumes'], {room: nearestEntity.entity})
        };
      }
      //HINT dependencies when dragging in an elevation
      if (nearestEntity.resourceKey === 'elevation' && !(sourceEntityResourceKey === 'product' && Product.getIsDaylightIslandProduct({product: sourceEntity}))) {
        var elevationDependencies = ['archElements', 'walls', 'datums', 'xzDatums', 'volumes'];

        if (sourceEntityResourceKey === 'container') {
          elevationDependencies.push('containers');
        }

        //HINT snapping dimension
        if (isAddingCustomDimension) {
          elevationDependencies.push('products');
        }

        if (sourceEntityResourceKey === 'product') {
          dependencies = {
            ...dependencies,
            ...Product.get(['parentProduct', 'siblings', 'container'], {product: sourceEntity})
          };
        }

        if ((!sourceEntity && this.state.activeEntities.length === 0) || sourceEntityResourceKey === 'projectGraphic') {
          elevationDependencies.push('containers');
        }

        dependencies = {
          ...dependencies,
          ...Elevation.get(elevationDependencies, {elevation: nearestEntity.entity}),
        };

        if ((!sourceEntity && this.state.activeEntities.length === 0) || sourceEntityResourceKey === 'projectGraphic') {
          dependencies.products = _.filter(dependencies.products, product => !Product.getIsManaged({product}));
        }
      }

      if (sourceEntityResourceKey === 'archElement') {
        dependencies = {
          ...dependencies,
          ...ArchElement.get(['wall', 'wallSets', 'room'], {archElement: sourceEntity})
        };
      }
    }

    if (dependencies && dependencies.containers) {
      dependencies.containers = _.filter(dependencies.containers, container => !Container.getTypeDataFor({container}).isOrnament);
    }

    return dependencies;
  };

  async handleClipboardEvent({action}) {
    let copiedObjects, copiedObjectsGroupProps;

    if (_.includes(['copy', 'cut'], action)) {
      copiedObjects = _.map(this.activeEntities, (activeEntity, i) => {
        var resourceKey = this.state.activeEntities[i].resourceKey;
        var products = [];

        if (_.includes(['container', 'product'], resourceKey)) {
          var instanceProducts = resourceKey === 'container' ?
            Container.get('products', {container: activeEntity}) :
            Product.get('childProducts', {product: activeEntity});

          products = _.map(instanceProducts, product => {
            var {childProducts, productOptionInstances} = Product.get(['childProducts', 'productOptionInstances'], {product});

            return {...product, resourceKey: 'product', products: childProducts, productOptions: productOptionInstances};
          });
        }

        return {...activeEntity, resourceKey,
          products
        };
      });

      copiedObjectsGroupProps = this.getMultiSelectTransformerProps().shapeProps;
    }

    //containers
    //products
    //products

    if (action === 'copy') {
      this.copy({copiedObjects, copiedObjectsGroupProps});
    }
    else if (action === 'cut') {
      await this.cut({copiedObjects, copiedObjectsGroupProps});
    }
    else if (action === 'paste') {
      await this.paste();
    }
  }

  copy = ({copiedObjects, copiedObjectsGroupProps}) => {
    this.setState({
      ...this.state,
      copiedObjects,
      copiedObjectsGroupProps,
      isCopy: true
    });
  };

  cut = async ({copiedObjects, copiedObjectsGroupProps}) => {
    this.setState({
      ...this.state,
      copiedObjects,
      copiedObjectsGroupProps,
      isCopy: false
    });

    this.handleDeselect();

    var destructions = {
      volumes: [],
      archElements: [],
      containers: [],
      products: [],
      productOptions: [],
      projectGraphics: []
    };

    _.map(copiedObjects, (instance) => {
      var type = instance.resourceKey;

      if (type === 'product' || type === 'container' || type === 'archElement' || type === 'volume' || type === 'projectGraphic') {
        destructions[pluralize(type)].push(instance.id);

        if (instance.products && instance.products.length > 0) {
          destructions.products.push(..._.map(instance.products, 'id'));
        }

        if (instance.productOptions && instance.productOptions.length > 0) {
          destructions.productOptions.push(..._.map(instance.productOptions, 'id'));
        }
      }
    });

    _.forEach(destructions, (idsToDestroy, pluralizedResourceKey) => {
      if (idsToDestroy.length > 0) {
        this.props[`destroy${_.upperFirst(pluralizedResourceKey)}`]({ids: idsToDestroy});
      }
    });

    if (_.some(copiedObjects, instance => instance.resourceKey === 'volume' || instance.resourceKey === 'container')) {
      setTimeout(() => {
        Room.updateManagedResources({room: this.room, reduxActions: this.props});
      });
    }
  };

  createChildProductsFor = async ({instance, resourceKey, containerInstanceId, scopeId}) => {
    return await lib.async.forEach(instance.products, async product => {
      let newProduct = await lib.api.create('productInstance', {
        props: {..._.omit(product, ['id', ...(this.state.isCopy ? ['persistentId', 'lockedForProduction'] : [])]),
          containerInstanceId,
          scopeId,
          ...(resourceKey === 'product' ? {productInstanceId: instance.id} : {})
        }
      });

      var newProductOptions = [];

      if (product.productOptions && product.productOptions.length > 0) {
        await lib.async.forEach(product.productOptions, async productOption => {
          let newProductOption = await lib.api.create('productOptionInstance', {
            props: {..._.omit(productOption, ['id']),
              productInstanceId: newProduct.id,
              scopeId
            }
          });

          newProductOptions.push(newProductOption);
        });

        this.props.trackProductOptions({productOptions: newProductOptions});
      }

      newProduct = {...newProduct, products: product.products};

      this.props.trackProducts({products: [newProduct]});

      await this.createChildProductsFor({instance: newProduct, resourceKey: 'product', containerInstanceId, scopeId});
    });
  };

  paste = async () => {
    const {copiedObjects, copiedObjectsGroupProps, viewOffset, viewMode} = this.state;
    var newInstances = [];

    //in paste this.activeEntity = the thing we're trying to paste into
    //in paste this.state.copiedObjects = the things we're pasting
    //CFG.clipboard.objects = this.state.copiedObjects
    //modelKey = resourceKey

    const nearestEntity = this.getNearestEntity({}) || {};

    if (_.size(copiedObjects) === 1 && _.get(copiedObjects, '0.resourceKey') === 'room') {
      if (this.state.viewMode !== 'top') {
        alert('Rooms can only be pasted in top view.');
      }
      else {
        var roomId = copiedObjects[0].id;
        const realPosition = PositionHelper.toReal({x: this.lastMouseEvent.layerX, y: this.lastMouseEvent.layerY}, this.canvasData);

        const roomToPaste = _.cloneDeep(this.props.rooms[roomId]);
        const roomToPastePosition = lib.object.difference(realPosition, viewOffset);

        var roomProps = {
          projectId: roomToPaste.projectId,
          versionId: roomToPaste.versionId,
          floorId: this.state.activeViewEntityId,
          plan: {position: roomToPastePosition}
        };

        const apiResponse = await lib.api.request({uri: 'de-project/copy-room', body: {projectId: roomToPaste.projectId, versionId: roomToPaste.versionId, roomId: roomToPaste.id, roomProps}});
        const {
          room: newPastedRoom,
          scopes,
          elevations,
          walls,
          volumes,
          archElementInstances,
          containerInstances,
          productInstances,
          productOptionInstances,
          projectGraphics
        } = apiResponse.data;

        this.props.trackScopes({scopes});
        this.props.trackElevations({elevations});
        this.props.trackWalls({walls});
        this.props.trackVolumes({volumes});
        this.props.trackArchElements({archElements: archElementInstances});
        this.props.trackContainers({containers: containerInstances});
        this.props.trackProducts({products: productInstances});
        this.props.trackProductOptions({productOptions: productOptionInstances});
        this.props.trackProjectGraphics({projectGraphics});
        this.props.trackRooms({rooms: [newPastedRoom]});

        setTimeout(() => {
          this.setActiveEntities({entities: [{resourceKey: 'room', id: newPastedRoom.id}], isMultiSelect: false});

          Room.updateManagedResources({room: newPastedRoom, reduxActions: this.props});
        });
      }
    }
    else {
      const {entity, resourceKey} = nearestEntity;

      const roomId = resourceKey === 'room' ? entity.id : entity.roomId;
      const room = this.props.rooms[roomId];

      await lib.async.forEach(copiedObjects, async (instance) => {
        var type = instance.resourceKey;
        let newInstance;

        var canPasteInEntity = ({entity, entityResourceKey}) => {
          var canPasteInEntity = false;

          var result = getProductsByCategoryFor({
            [entityResourceKey]: entity
          });

          var productIds = _.map(_.flatMap(result, 'productTypes'), 'id');

          var isValid = _.includes(productIds, instance.productId);

          if (isValid) {
            canPasteInEntity = true;
          }

          return canPasteInEntity;
        };

        if (type === 'product') {
          var newParent = this.state.activeEntities.length === 1 ? this.activeEntities[0] : undefined;
          var newParentResourceKey = this.state.activeEntities.length === 1 ? this.state.activeEntities[0].resourceKey : undefined;

          if (newParent) {
            var canPaste = canPasteInEntity({entity: newParent, entityResourceKey: newParentResourceKey});

            if (!canPaste && newParentResourceKey === 'product') {
              const parentProduct = Product.get('parentProduct', {product: newParent});
              const parentContainer = Product.get('container', {product: newParent});
              newParent = parentProduct || parentContainer;
              newParentResourceKey = parentProduct ? 'product' : 'container';

              canPaste = canPasteInEntity({entity: newParent, entityResourceKey: newParentResourceKey});
            }

            if (canPaste) {
              var containerInstance = newParentResourceKey === 'product'
                ? Product.get('container', {product: newParent})
                : newParent;

              var containerInstanceId = containerInstance.id;

              var scopeId = Container.getScopeId({container: containerInstance, scopes: Room.get('scopes', {room})});

              var newProps = {
                ..._.omit(instance, ['id', 'managedData']),
                position: {...instance.position, x: instance.position.x + 3},
                scopeId
              };

              newProps[`${newParentResourceKey}InstanceId`] = newParent.id;

              newInstance = await lib.api.create('productInstance', {
                props: _.omit(newProps, ['products', 'resourceKey', 'deleted', ...(this.state.isCopy ? ['persistentId', 'lockedForProduction'] : [])])
              });

              this.props.trackProducts({products: [newInstance]});

              newInstance = {...newInstance, products: instance.products, productOptions: instance.productOptions, resourceKey: type};

              if (newInstance.productOptions && newInstance.productOptions.length > 0) {
                await lib.async.forEach(newInstance.productOptions, async productOption => {
                  let newProductOption = await lib.api.create('productOptionInstance', {
                    props: {..._.omit(productOption, ['id']),
                      productInstanceId: newInstance.id,
                      scopeId
                    }
                  });

                  newProductOptions.push(newProductOption);
                });

                this.props.trackProductOptions({productOptions: newProductOptions});
              }

              await this.createChildProductsFor({
                instance: newInstance, resourceKey: type, containerInstanceId, scopeId
              });

              newInstances.push({resourceKey: type, id: newInstance.id});
            }
            else {
              // alert them if they are not in the container
              // and tell them they can only paste in a container
              alert('You can only paste in a valid location.');
            }
          }
        }
        else if (_.includes(['container', 'volume'], type)) {
          const newParent = entity;
          const newParentResourceKey = resourceKey;
          const isContainer = type === 'container';
          const Resource = {
            container: Container,
            volume: Volume,
          }[type];

          if (newParent) {
            var scopeId = viewMode === 'lite' ? newParent.id : Room.get('scope', {room}).id;

            var newProps = {
              ..._.omit(instance, ['id']),
              scopeId,
              rotation: newParentResourceKey === 'elevation' ? lib.trig.normalize({degrees: Elevation.getRotation({elevation: entity})}) : instance.rotation || 0
            };

            let realPosition = PositionHelper.toReal({x: this.lastMouseEvent.layerX, y: this.lastMouseEvent.layerY}, this.canvasData);
            let positionInEntity = lib.object.difference(realPosition, nearestEntity.position, nearestEntity.offset, this.state.viewOffset);

            if (newParentResourceKey === 'elevation') {
              positionInEntity.x -= instance.dimensions.width / 2;
              positionInEntity.y -= isContainer ? (!instance.customData.preventSnapToFloor && (Container.getTypeDataFor({container: instance}).snapToFloor) ? positionInEntity.y : -instance.dimensions.height / 2) : (instance.dimensions.height / 2);

              var getPositionInWall = ({positionRelativeToInstance, positionRelativeToWall}) => {
                var orientedVertical = _.includes([90, 270], instance.rotation);
                var xOrientedNegative = _.includes([90], instance.rotation);
                var zOrientedNegative = _.includes([180], instance.rotation);

                return {
                  x: orientedVertical ? _[xOrientedNegative ? 'min' : 'max']([positionRelativeToInstance.x, positionRelativeToWall.x]) : positionRelativeToInstance.x,
                  z: orientedVertical ? positionRelativeToInstance.z : _[zOrientedNegative ? 'min' : 'max']([positionRelativeToInstance.z, positionRelativeToWall.z]),
                  y: positionRelativeToInstance.y
                };
              };

              var positionRelativeToWall = Resource.position3dTransformFor({[type]: instance, room, elevation: entity, viewKey: 'front', position2d: positionInEntity, forceSnapToWall: true});
              var transformProps = Resource.position3dTransformFor({[type]: instance, room, elevation: entity, viewKey: 'front', position2d: positionInEntity, position3d: instance.position});

              newProps.position = getPositionInWall({positionRelativeToInstance: transformProps.position3d, positionRelativeToWall});
            }
            else if (viewMode !== 'lite') {
              var orientedVertical = _.includes([90, 270], instance.rotation);
              var xNegativeFlag = _.includes([90, 180], instance.rotation) ? -1 : 1;
              var yNegativeFlag = _.includes([180, 270], instance.rotation) ? -1 : 1;
              var {depth, width} = instance.dimensions;

              var xOffsetInRoom = (xNegativeFlag * (orientedVertical ? depth : width) / 2);
              var yOffsetInRoom = (yNegativeFlag * (orientedVertical ? width : depth) / 2);

              //HINT position relative to overall copied shape
              if (copiedObjects.length > 1) {
                var originalInstancePosition = Resource.getPositionInRoom({[type]: instance});
                var originalGroupPosition = lib.object.difference(copiedObjectsGroupProps.position, room.plan.position, this.state.viewOffset);

                var originalInstancePositionInGroup = lib.object.difference(originalInstancePosition, originalGroupPosition, {x: copiedObjectsGroupProps.size.width / 2, y: copiedObjectsGroupProps.size.height / 2});

                xOffsetInRoom = -originalInstancePositionInGroup.x;
                yOffsetInRoom = -originalInstancePositionInGroup.y;
              }

              positionInEntity.x -= xOffsetInRoom;
              positionInEntity.y -= yOffsetInRoom;

              const transformProps = Resource.position3dTransformFor({[type]: instance, room, viewKey: 'top', position2d: positionInEntity, position3d: {y: instance.position.y}});

              newProps.position = transformProps.position3d;
              if (transformProps.rotation) newProps.rotation = transformProps.rotation;
            }

            if (isContainer) {
              scopeId = viewMode === 'lite' ? newParent.id : Container.getScopeId({container: _.omit(newProps, ['products', 'resourceKey', 'deleted']), scopes: Room.get('scopes', {room})});

              newInstance = await lib.api.create('containerInstance', {
                props: _.omit(newProps, ['products', 'resourceKey', 'deleted', , ...(this.state.isCopy ? ['persistentId', 'lockedForProduction'] : [])])
              });

              newInstance = {...newInstance, products: instance.products, resourceKey: 'container'};

              this.props.trackContainers({containers: [newInstance]});

              await this.createChildProductsFor({
                instance: newInstance, resourceKey: 'container', containerInstanceId: newInstance.id, scopeId
              });

              newInstances.push({resourceKey: 'container', id: newInstance.id});
            }
            else {
              newInstance = await lib.api.create('volume', {
                props: _.omit(newProps, ['products', 'resourceKey', 'deleted'])
              });

              this.props.trackVolumes({volumes: [newInstance]});

              newInstances.push({resourceKey: 'volume', id: newInstance.id});
            }
          }
        }
        else if (type === 'archElement') {
          const newParent = entity;
          const newParentResourceKey = resourceKey;

          if (newParent) {
            const scopeId = Room.get('scope', {room}).id;

            var newProps = {
              ..._.omit(instance, ['id']),
              scopeId,
            };

            let realPosition = PositionHelper.toReal({x: this.lastMouseEvent.layerX, y: this.lastMouseEvent.layerY}, this.canvasData);
            let positionInEntity = lib.object.difference(realPosition, nearestEntity.position, nearestEntity.offset, this.state.viewOffset);

            if (newParentResourceKey === 'elevation') {
              const size = ArchElement.getSize({archElement: instance, viewKey: 'front'});
              positionInEntity.x -= size.width / 2;
              positionInEntity.y += ArchElement.getTypeData({archElement: instance}).snapToFloor ? -positionInEntity.y : size.height / 2;

              let newWall = Elevation.getWallFor({elevation: newParent, x: positionInEntity.x});
              newProps.wallId = newWall.id;

              newProps.position = {
                x: positionInEntity.x - Elevation.getWallX({elevation: newParent, wall: newWall}),
                y: -positionInEntity.y
              };
            }
            else {
              const walls = Room.get('walls', {room});
              const size = ArchElement.getSize({archElement: instance, viewKey: 'front'});

              const nearestWall = _.minBy(_.values(walls), wall => lib.trig.distance({fromPoint: positionInEntity, toLine: Wall.getLine({wall}).inRoom}));
              const positionOnWall = lib.trig.nearestPoint({point: positionInEntity, onLine: Wall.getLine({wall: nearestWall}).inRoom});

              newProps.wallId = nearestWall.id;
              newProps.position = {
                x: lib.trig.distance({fromPoint: positionOnWall, toPoint: Wall.getLine({wall: nearestWall}).inRoom.from}) - (size.width / 2),
                y: instance.position.y
              };
            }

            newInstance = await lib.api.create('archElementInstance', {
              props: _.omit(newProps, ['products', 'resourceKey', 'deleted'])
            });

            this.props.trackArchElements({archElements: [newInstance]});

            newInstances.push({resourceKey: 'archElement', id: newInstance.id});
          }
        }
        else if (type === 'projectGraphic') {
          var newParent = entity;
          var newParentResourceKey = resourceKey;

          var newProps = {
            ..._.omit(_.cloneDeep(instance), ['id', 'deleted']),
            roomId,
            elevationId: newParentResourceKey === 'elevation' ? newParent.id : null, //HINT reset elevationId when pasting from elevation to room
            deleted: 0
          };

          let realPosition = PositionHelper.toReal({x: this.lastMouseEvent.layerX, y: this.lastMouseEvent.layerY}, this.canvasData);
          let positionInEntity = lib.object.difference(realPosition, nearestEntity.position, nearestEntity.offset, this.state.viewOffset);

          if (newParent && newParentResourceKey === 'elevation') {
            var elevation = newParent;
            var walls = Elevation.get('walls', {elevation});
            var wallsData = _.filter(_.map(walls, wall => {
              var wallX = Elevation.getWallX({elevation, wall});
              var outlinePoints = Wall.getOutlinePoints({elevation, wall});

              return {wall, wallX, outlinePoints};
            }), wallData => !_.isEmpty(wallData.outlinePoints));

            const fromIsInRoom = lib.polygon.pointInsidePolygon({point: entity.lineInRoom.from, polygon: room.plan.points});

            var firstWallXInset = fromIsInRoom ? 0 : _.min(_.map(wallsData, wallData => _.min(_.map(wallData.outlinePoints, 'x'))));

            positionInEntity = lib.object.difference(positionInEntity, {x: firstWallXInset});
          }

          if (instance.type === 'text') {
            positionInEntity = lib.object.difference(positionInEntity, {x: instance.data.size.width / 2, y: instance.data.size.height / 2});
            newProps.data.position = positionInEntity;
          }
          else if (instance.type === 'textPointer') {
            positionInEntity = lib.object.difference(positionInEntity, {x: instance.data.size.width / 2, y: instance.data.size.height / 2});
            newProps.data.position = positionInEntity;
            newProps.data.to = lib.object.sum(positionInEntity, lib.object.difference(instance.data.to, instance.data.position));
          }
          else if (_.includes(['line', 'arrow'], instance.type)) {
            var projectGraphicDataDistance = lib.object.difference(instance.data.to, instance.data.from);

            positionInEntity = lib.object.difference(positionInEntity, {x: projectGraphicDataDistance.x / 2, y: projectGraphicDataDistance.y / 2});

            newProps.data.from = positionInEntity;
            newProps.data.to = lib.object.sum(positionInEntity, lib.object.difference(instance.data.to, instance.data.from));
          }
          else if (instance.type === 'polygon') {
            newProps.data.position = positionInEntity;
          }
          else if (instance.type === 'rectangle') {
            positionInEntity = lib.object.difference(positionInEntity, {x: instance.data.size.width / 2, y: instance.data.size.height / 2});
            newProps.data.position = positionInEntity;
          }

          newInstance = await lib.api.create('projectGraphic', {
            props: newProps
          });

          this.props.trackProjectGraphics({projectGraphics: [newInstance]});
          newInstances.push({resourceKey: 'projectGraphic', id: newInstance.id});
        }
      });

      if (copiedObjects.length) {
        if (newInstances.length > 0) {
          this.setActiveEntities({entities: newInstances});

          this.setState({activeProjectGraphicData: null});
        }

        Room.updateManagedResources({room, reduxActions: this.props});
      }
    }
  };

  undo = async ({actions, type, eventKey, instance, data}) => {
    let reduxActions = this.props;
    var containers;
    var updatesMap = {
      productOptions: {creations: [], updates: [], deletedIds: [], tracks: []},
      products: {creations: [], updates: [], deletedIds: [], tracks: []},
      containers: {creations: [], updates: [], deletedIds: [], tracks: []},
      volumes: {creations: [], updates: [], deletedIds: [], tracks: []},
    };

    if (!actions) actions = [{type, eventKey, instance, data}];

    //HINT containers need the up to date container to adjust products, making 1 getDependencies call for performance
    var isContainerMoveUndo = _.some(actions, action => action.eventKey === 'transformEnd' && action.type === 'container');
    var isAutomatedCountertopDeleteUndo = _.some(actions, action => action.eventKey === 'destroy' &&  action.type === 'container' && _.some(_.get(action.data, 'objectsByType'), (objects, typeKey) => {
      return typeKey === 'containers' && _.some(objects, object => object.type === 'countertop' && !_.get(object, 'customData.inManualMode'));
    }));

    if (isContainerMoveUndo || isAutomatedCountertopDeleteUndo) {
      containers = getDependencies({dependencyKeys: ['containers']}, ({state, useDependency}) => {
        return {
          containers: () => _.get(state.resources, 'containers.byId')
        };
      }).containers;
    }

    _.forEach(actions, async action => {
      var {type, eventKey, instance, data} = action;

      if (eventKey === 'destroy') {
        if (_.includes(['productOption', 'product', 'container', 'volume'], type)) {
          _.forEach(data.objectsByType, (objects, typeKey) => {
            if (objects && objects.length > 0) {
              if (typeKey === 'containers') {
                _.forEach(objects, (container, i) => {
                  if (!(this.props.project.lockedForProduction && container.lockedForProduction)) {
                    var {managedUpdatesMap} = Container.updateManagedResources({container, actionKey: 'create', reduxActions: this.props, isBatched: true});

                    updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);
                  }

                  //HINT when automated countertops are deleted we turn off automated countertops
                  //for the containers they were covering
                  //if the user undoes the deletion of an automated ctop
                  //we need to turn back on the automated ctops for the covered containers
                  //so that the container isn't immediately deleted
                  if (container.type === 'countertop' && !container.customData.inManualMode) {
                    var coveringContainerIds = _.get(container.customData, 'containerIds');

                    if (coveringContainerIds) {
                      _.forEach(coveringContainerIds, coveringContainerId => {
                        var coveredContainer = _.get(containers, `${coveringContainerId}`);

                        if (coveredContainer) {
                          updatesMap.containers.updates.push({where: {id: coveredContainer.id}, props: {customData: {...coveredContainer.customData, managedCountertopsEnabled: 1}}})
                        }
                      });
                    }
                  }
                });
              }

              updatesMap[typeKey].tracks.push(...objects);

              lib.api.update(apiKeyFor({key: typeKey}), _.map(objects, object => {
                let props = {deleted: 0};

                if (typeKey === 'containers') props.customData = object.customData;

                return {where: {id: object.id}, props};
              }));
            }
          });
        }
        else {
          let pluralType = pluralize(type);

          this.props[`track${_.upperFirst(pluralType)}`]({[pluralType]: [instance]});
          lib.api.update(apiKeyFor({key: pluralType}), {where: {id: instance.id}, props: {deleted: 0}});
        }
      }
      else if (eventKey === 'transformEnd') {
        if (_.includes(['container', 'product', 'archElement', 'projectGraphic', 'volume'], type)) {
          const Resource = {
            container: Container,
            product: Product,
            archElement: ArchElement,
            projectGraphic: ProjectGraphic,
            volume: Volume
          }[type];

          var oldContainer;

          //HINT used to readjust products from resize
          if (type === 'container') {
            oldContainer = containers[instance.id];
          }

          UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, Resource.update({id: instance.id, [type]: instance, props: instance, oldContainer, reduxActions, isUndo: true, isBatched: true}));
        }
        else if (type === 'room') {
          await Room.updateManagedWalls({room: instance, reduxActions: this.props});

          this.props.updateRoom({id: instance.id, props: instance});

          var scopes = Room.get('scopes', {room: instance});

          if (_.values(scopes).length === 1) {
            this.props.updateScopes({ids: _.map(_.values(scopes), 'id'), props: {title: instance.title}});
          }

          setTimeout(() => {
            Project.autogenerateElevations({project: this.props.project, reduxActions: this.props});
          });
        }
        else if (type === 'scope') {
          var room = this.props.rooms[instance.roomId];

          if (room) {
            Scope.updateComponents({room, scope: instance, reduxActions: this.props});

            this.props.updateScope({id: instance.id, props: instance});
          }
        }
        else {
          this.props[`update${_.upperFirst(type)}`]({id: instance.id, props: instance});
        }
      }
    });

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions: this.props});

    if (_.some(actions, action => action.eventKey === 'destroy')) {
      setTimeout(() => {
        this.setActiveEntities({entities: _.flatMap(actions, action => {
          return action.instances ? action.instances : [{resourceKey: action.type, id: action.instance.id}];
        })});
      });
    }

    //HINT reset multiselect transformer
    if (actions.length > 1 || _.get(actions, 'instance.length') > 1) {
      setTimeout(() => {
        this.setState({cachedTransformerProps: undefined, cachedActiveEntities: undefined});
      });
    }

    if (_.find(actions, action => action.type === 'container')) {
      setTimeout(() => {
        var containerUpdate = _.find(actions, action => action.type === 'container');

        const room = Container.get('room', {container: containerUpdate.instance});

        Room.updateManagedResources({room, reduxActions});
      });
    }
    else if (_.find(actions, action => action.type === 'volume')) {
      setTimeout(() => {
        var volumeUpdate = _.find(actions, action => action.type === 'volume');

        const room = Volume.get('room', {volume: volumeUpdate.instance});
        Room.updateManagedResources({room, reduxActions});
      });
    }
  };

  pushToUndoQueue = (obj) => {
    this.undoQueue = [...this.undoQueue, obj];
  };

  /**
   * @returns {
     *   sourceSnapPositions - array of real positions based on the container's size (corners and center)
     *   debugModeSourceSnapPositions - array of real positions based on container's size (conrners and center) for displaying in debug mode in green dots
     * }
   */
  calculateSourceSnapData = ({nearestEntity, activeEntities, sourceEntityResourceKey, sourceEntity, viewMode}) => {
    return SnapDataHelper.calculateSourceSnapData({nearestEntity, activeEntities, sourceEntityResourceKey, sourceEntity, viewMode, getMultiSelectTransformerProps: this.getMultiSelectTransformerProps, viewOffset: this.state.viewOffset});
  };

  /**
   * @returns {
   *   candidateSnapPositions - array of real positions based on archElements, containers, and walls in current view
   *   candidateSnapAngles - array of angles in degrees based on walls in current view
   * }
   */
  calculateSnapData = ({nearestEntity, activeDimensionData, isAddingCustomDimension, sourceEntityResourceKey, sourceEntity, multipleEntitiesSelected, activeEntities, viewMode, dependencies}) => {
    return SnapDataHelper.calculateSnapData({
      nearestEntity, sourceEntityResourceKey, sourceEntity, multipleEntitiesSelected, activeEntities, viewMode, dependencies,
      activeEntity: this.activeEntity, viewOffset: this.state.viewOffset, activeDimensionData, isAddingCustomDimension
    });
  };

  handlePrecisionChange = (precision) => {
    this.setState({precision});
    this.canvasData.precision = precision;
  };

  updateVisibilityLayers = ({key, isVisible}) => {
    var {visibilityLayers} = this.state;

    visibilityLayers = {...visibilityLayers, [key]: isVisible};

    this.setState({visibilityLayers});
    global.visibilityLayers = visibilityLayers;

    if (key === 'wallsAndArchElements' && _.includes(['elevation', 'room', 'wall', 'archElementInstance'], this.state.activeEntityResourceKey)) {
      this.handleDeselect();
    }
  };

  setActiveDimensionsLayer = ({key}) => {
    this.setState({activeDimensionsLayer: key});

    global.activeDimensionsLayer = key;
  };

  setActiveDetailLevel = ({key}) => {
    var {visibilityLayers} = this.state;

    var newVisibilityLayers = {
      ...visibilityLayers,
      dimensions: _.includes(['production', 'fullDetail', 'installation'], key),
      projections: _.includes(['production'], key),
      reveals: _.includes(['production', 'installation'], key),
      unitNumbers: _.includes(['production', 'installation'], key),
      grainFlow: _.includes(['production', 'fullDetail', 'installation'], key),
    };

    var activeDimensionsLayer = getDimensionsLayerForDetailLevel({activeDetailLevel: key, project: this.props.project});

    this.setState({activeDetailLevel: key, visibilityLayers: newVisibilityLayers, activeDimensionsLayer});
    global.activeDimensionsLayer = key;
  };

  setActiveFillMode = ({key}) => this.setState({activeFillMode: key});
  setActiveUserLense = ({key}) => this.setState({activeUserLense: key});

  toggleMeasuringTapeVisibility = () => {
    this.setState({isMeasuringTapeVisible: !this.state.isMeasuringTapeVisible});
  };

  toggleCountertopSelectability = () => {
    this.setState({countertopsAreSelectable: !this.state.countertopsAreSelectable});
  };

  toggleShowingElevations = () => {
    this.setState({bothIsShowingElevations: !this.state.bothIsShowingElevations});
  };

  goToIssue = ({issue}={}) => {
    var {resourceKey, resourceId} = issue;

    //HINT not always the same as the problem instance
    //managed things will be selecting the thing managing
    var relevantResourceKey = resourceKey;
    var relevantResourceId = resourceId;

    if (resourceKey && resourceId && _.includes(['container', 'product'], resourceKey)) {
      var issueResource = getDependencies({dependencyKeys: [resourceKey]}, ({state, useDependency}) => {
        return {
          [resourceKey]: () => _.get(state.resources, `[${pluralize(resourceKey)}].byId[${resourceId}]`)
        };
      })[resourceKey];

      if (issueResource) {
        var relevantContainer = resourceKey === 'container' ? issueResource : Product.get('container', {product: issueResource});

        if (resourceKey === 'product' && Product.getIsManaged({product: issueResource}) && _.get(issueResource, 'managedData.managedKey') !== 'autofilledStorage') {
          var parentProduct = Product.get('parentProduct', {product: issueResource});

          relevantResourceKey = parentProduct ? 'product' : 'container';
          relevantResourceId = parentProduct ? parentProduct.id : relevantContainer.id;
        }

        if (relevantContainer) {
          var room = Container.get('room', {container: relevantContainer});

          if (room) {
            var elevations = Room.get('elevations', {room});

            var frontElevation = _.find(elevations, elevation => {
              var containerFrontFacing = _.includes([0, 360], lib.round(Container.getElevationTheta({container: relevantContainer, elevation}), {toNearest: 1})) || relevantContainer.type === 'countertop' || (relevantContainer.type === 'ocSolidCorner' && _.includes(['left', 'front'], Container.getSideKey({elevation, container: relevantContainer, viewKey: 'front'})));

              if (!containerFrontFacing) {
                return false;
              }
              else {
                var elevationFootprintInRoom = Elevation.getFootprintInRoom({elevation});
                var containerFootprintInRoom = Container.getFootprintInRoom({container: relevantContainer});

                var containerInElevation = relevantContainer.position && !_.isEqual(relevantContainer.position, {}) && lib.polygon.polygonsOverlap(elevationFootprintInRoom, containerFootprintInRoom);

                return containerInElevation;
              }
            });

            if (frontElevation) {
              this.setActiveViewEntityId('elevation', frontElevation.id);
            }
            else {
              this.setActiveViewEntityId('room', room.id);
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.setActiveEntities({entities: [{resourceKey: relevantResourceKey, id: relevantResourceId}], isMultiSelect: false});
    })
  }

  dismissIssue = ({issue}={}) => {
    if (issue.id) {
      var room = _.get(this.props.rooms, issue.roomId);

      this.props.updateRoom({id: room.id, props: {customData: {...(room.customData || {}), resolvedIssueIds: [..._.get(room, 'customData.resolvedIssueIds', []), issue.id]}}});

      Room.updateManagedResources({room, reduxActions: this.props, updateUnitNumbers: false, updateContainerResources: false});
    }
  };

  handleCreateScope = async ({title}) => {
    var {position, points, roomId, floorId} = this.state.drawingScopeData;
    var room = _.get(this.props.rooms, roomId);
    var {id, versionId} = this.props.project;

    position = lib.object.difference(position, room.plan.position);

    var scope = await lib.api.create('scope', {props: {title, projectId: id, versionId: versionId, roomId, plan: {closed: true, points, position}}});

    this.props.trackScopes({scopes: [scope]});

    this.updateVisibilityLayers({key: 'scopes', isVisible: true});

    Scope.updateComponents({room: _.get(this.props.rooms, roomId), scope, reduxActions: this.props});
  };

  handleScopePolygonClose = ({points, position}) => {
    this.setState({drawingScopeData: {...this.state.drawingScopeData, isDrawing: false, isShowingPopup: true, points, position}});
  };

  handleScopePopupOnClose = () => {
    this.setState({drawingScopeData: {...this.state.drawingScopeData, isShowingPopup: false}});
  };

  handleScopePopupOnCreate = ({title}) => {
    this.handleCreateScope({title});
  };

  //HINT user starts drawing the scope
  handleAddScope = ({roomId, floorId}) => {
    this.setState({drawingScopeData: {isDrawing: true, roomId, floorId}});
  };

  hideMeasuringTape = () => {
    this.setState({isMeasuringTapeVisible: false});
  };

  get selectionData() {
    const {activeEntities, activeDimensionData, activeDatumData, activeProjectGraphicData, multiSelectRectShowing} = this.state;

    const selectionData = {
      activeEntities,
      activeDimensionData,
      activeDatumData,
      activeProjectGraphicData,
      setActiveScalingToolData: this.setActiveScalingToolData,
      setActiveDimensionData: this.setActiveDimensionData,
      setActiveProjectGraphicData: this.setActiveProjectGraphicData,
      setActiveDatumData: this.setActiveDatumData,
      setActiveEntities: this.setActiveEntities,
      getIsActiveEntity: this.getIsActiveEntity,
      getIncludesActiveEntity: this.getIncludesActiveEntity,
      onDeselect: this.handleDeselect,
      handleKeyPress: this.handleKeyPress,
      drawingSelectionBox: multiSelectRectShowing
    };

    return memoObject(selectionData, '_selectionData', {cacheMap: this});
  }

  get projectData() {
    const {activeDimensionsLayer, countertopsAreSelectable, showingAlignmentIndicators} = this.state;

    const projectData = {
      ..._.pick(this.props.project, ['dimensionsData', 'companyKey', 'versionId', 'id', 'isEmployee', 'infinitePrecision', 'lockedForProduction']),
      activeDimensionsLayer,
      isEditingDimensions: this.state.editingDimensions,
      countertopsAreSelectable,
      pushToUndoQueue: this.pushToUndoQueue,
      toggleTolerancePopupShowing: this.toggleTolerancePopupShowing,
      toggleDimEditsCopyPopupShowing: this.toggleDimEditsCopyPopupShowing,
      showingAlignmentIndicators,
    };

    return memoObject(projectData, '_projectData', {cacheMap: this});
  }

  get numericInputData() {
    const {isNumericInputSubmitted, showNumericInput} = this.state;

    const numericInputData = {
      numericInputValue: this.numericInputValueRef.current,
      isNumericInputSubmitted,
      showNumericInput,
      toggleNumericInputVisibility: this.toggleNumericInputVisibility,
      disableIsNumericInputSubmitted: this.disableIsNumericInputSubmitted,
    };

    return memoObject(numericInputData, '_numericInputData', {cacheMap: this});
  }

  handleNewCustomDimension = ({from, to}) => {
    var {viewOffset} = this.state;
    const nearestEntity = this.getNearestEntity({});

    if (nearestEntity) {
      const {offset, position, entity, resourceKey} = nearestEntity;

      let additionalOffset = {x: 0, y: 0};
      // if (resourceKey === 'elevation') additionalOffset.x = Elevation.getMinWallX({elevation: entity});

      from = lib.object.difference(from, offset, position, viewOffset, additionalOffset);
      to = lib.object.difference(to, offset, position, viewOffset, additionalOffset);

      if (!this.props.project.infinitePrecision) {
        from = _.mapValues(from, value => lib.round(value, {toNearest: K.minPrecision}));
        to = _.mapValues(to, value => lib.round(value, {toNearest: K.minPrecision}));
      }

      if (resourceKey === 'elevation') {
        var threeDPositionFor = ({position2d}) => {
          var elevation = entity;

          var elevationXYOrigin = elevation.lineInRoom.from;
          var elevationXZOrigin = {x: elevationXYOrigin.x, z: elevationXYOrigin.y};

          var offsetXZPositionInElevation = {x: 0, z: 0};
          var rotatedXZPositionInElevation = lib.math.trig.rotate({point: {x: offsetXZPositionInElevation.x, y: offsetXZPositionInElevation.z}, byDegrees: -Elevation.getRotation({elevation})});

          var newXZPosition = {x: position2d.x, y: rotatedXZPositionInElevation.y};

          //convert the position back to 3d
          var rotatedXZPositionInRoom = lib.math.trig.rotate({point: newXZPosition, byDegrees: Elevation.getRotation({elevation})});
          var position3d = {x: rotatedXZPositionInRoom.x, y: -position2d.y, z: rotatedXZPositionInRoom.y};

          position3d = lib.object.sum(position3d, elevationXZOrigin);

          return position3d;
        };

        from = threeDPositionFor({position2d: from});
        to = threeDPositionFor({position2d: to});
      }

      if (!_.isEqual(from, to)) {
        var line = {from, to};
        var customDimension = {
          id: uuid(),
          line,
          contextId: entity.id,
          contextType: resourceKey,
          isRelative: true,
          offset: 0
        };

        var activeLayerDimensionsData = {
          ...this.projectData.dimensionsData[this.projectData.activeDimensionsLayer],
          customDimensionsById: {
            ...this.projectData.dimensionsData[this.projectData.activeDimensionsLayer].customDimensionsById,
            [customDimension.id]: customDimension
          },
          //HINT previously we were putting custom dims on the wrong side when moved/offset
          swapShortDimSideById: {
            ...this.projectData.dimensionsData[this.projectData.activeDimensionsLayer].swapShortDimSideById,
            [`custom-dimension-${customDimension.id}-from`]: true
          }
        };

        var updatedDimensionsData = {
          ...this.projectData.dimensionsData,
          [this.projectData.activeDimensionsLayer]: activeLayerDimensionsData
        };

        lib.api.update('projectVersion', {where: {id: this.projectData.versionId}, props: {dimensionsData: updatedDimensionsData}});

        this.props.updateProject({id: this.projectData.id, props: {dimensionsData: {...updatedDimensionsData}}, hitApi: false});
      }
    }

    this.setState({isAddingCustomDimension: false});
  };

  disableIsNumericInputSubmitted = () => {
    this.setState({
      isNumericInputSubmitted: false,
    });

    this.numericInputValueRef.current = null;
  };

  handleNumericInputDataSubmit = (e) => {
    e.preventDefault();

    var preventSubmit = false;

    if (this.numericInputRef) {
      var value = this.numericInputRef.current.value;

      try {
        var evaluatedValue = eval(value);

        this.numericInputValueRef.current = evaluatedValue;
      }
      catch (error) {
        preventSubmit = true;
      }
    }

    if (!preventSubmit && !this.state.isNumericInputSubmitted) {
      if (this.state.activeScalingToolData) {
        this.handleFinishScalingTool({value: this.numericInputValueRef.current});
      }
      else {
        this.setState({
          isNumericInputSubmitted: true,
        });
      }
    }
  };

  toggleNumericInputVisibility = (value) => {
    if (this.numericInputValueRef) {
      this.numericInputValueRef.current = null;
    }

    if (typeof value === 'boolean') {
      this.setState({
        showNumericInput: value,
      });
    }
    else {
      this.setState({
        showNumericInput: !this.state.showNumericInput,
      });
    }
  };

  handleNumericInputChange = ({value}) => {
    this.numericInputValueRef.current = value;
  };

  handleZMovementEvent = ({entity, entityResourceKey, sendTo}) => {
    var nearestEntity = this.getNearestEntity({});

    if (nearestEntity?.resourceKey === 'elevation') {
      var elevation = nearestEntity.entity;
      var {visibilityLayers} = this.state;
      var updatedZ = Elevation.getUpdatedZForSendTo({elevation, entityId: entity.id, entityResourceKey, sendTo, visibilityLayers});

      this.props.updateProjectGraphic({id: entity.id, props: {data: {...entity.data, z: updatedZ}}});
    }
  };

  transferDimensionsDataBetweenLayers = async ({dimTransferFrom, dimTransferTo}) => {
    var updateConfirmed = confirm(`Copy dimension edits from the ${dimTransferFrom} layer to the ${dimTransferTo} layer?

This will overwrite existing edits to the ${dimTransferTo} layer.`);

    if (updateConfirmed) {
      var {dimensionsData} = this.projectData;

      var updatedDimensionsData = {
        ...dimensionsData,
        [dimTransferTo]: {
          ...dimensionsData[dimTransferFrom],
        },
      };

      await lib.api.update('projectVersion', {where: {id: this.props.project.versionId}, props: {dimensionsData: updatedDimensionsData}});

      this.props.updateProject({id: this.props.project.id, props: {dimensionsData: updatedDimensionsData}, hitApi: false});
    }

    return updateConfirmed;
  };

  handleDeleteEvent = () => {
    if (this.state.activeEntities.length === 1) {
      if (this.state.activeEntities[0].resourceKey === 'product') {
        Product.destroy({product: this.activeEntities[0], reduxActions: this.props, pushToUndoQueue: this.pushToUndoQueue});
      }
      if (this.state.activeEntities[0].resourceKey === 'container') {
        Container.destroy({container: this.activeEntities[0], reduxActions: this.props, pushToUndoQueue: this.pushToUndoQueue});
      }
      if (this.state.activeEntities[0].resourceKey === 'volume') {
        Volume.destroy({volume: this.activeEntities[0], reduxActions: this.props, pushToUndoQueue: this.pushToUndoQueue});
      }

      this.handleDeselect();
    }
    else if (this.state.activeEntities.length > 1) {
      var deletedObjectsByType = {
        productOptions: [],
        products: [],
        containers: [],
        volumes: []
      };

      var updatesMap = {
        productOptions: {creations: [], updates: [], deletedIds: []},
        products: {creations: [], updates: [], deletedIds: []},
        containers: {creations: [], updates: [], deletedIds: []},
        volumes: {creations: [], updates: [], deletedIds: []}
      };

      _.forEach(this.activeEntities, (activeEntity, i) => {
        var {resourceKey} = this.state.activeEntities[i];
        var Resource = {
          container: Container,
          volume: Volume
        }[resourceKey];

        if (resourceKey === 'container') {
          var destroyData = Resource.destroy({[resourceKey]: activeEntity, isBatched: true});

          updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, destroyData.updatesMap);

          deletedObjectsByType = _.mapValues(deletedObjectsByType, (value, key) => {
            return [...value, ...(destroyData.objectsByType[key] ? destroyData.objectsByType[key] : [])];
          });
        }
        else if (resourceKey === 'volume') {
          updatesMap.volumes.deletedIds.push(activeEntity.id);

          deletedObjectsByType.volumes = [...(deletedObjectsByType.volumes || []), activeEntity];
        }
      });

      var {resourceKey} = this.state.activeEntities[0];
      var Resource = {
        container: Container,
        volume: Volume
      }[resourceKey];

      //HINT not safe to use this.room
      var room = Resource.get('room', {[resourceKey]: this.activeEntities[0]});

      UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions: this.props});
      this.pushToUndoQueue({actions: [{type: this.state.activeEntities[0].resourceKey, eventKey: 'destroy', instance: this.activeEntities[0], instances: this.state.activeEntities, data: {objectsByType: deletedObjectsByType}}]});

      this.handleDeselect();

      this.setState({cachedTransformerProps: undefined, cachedActiveEntities: undefined});

      Room.updateManagedResources({room, reduxActions: this.props});
    }
  };

  getMultiSelectTransformerProps = () => {
    var maxX, minX, maxY, minY, transformerProps;

    if (this.activeEntities && this.activeEntities.length > 1) {
      var nearestEntity = this.getNearestEntity({});

      if (nearestEntity?.resourceKey === 'elevation') {
        var elevation = nearestEntity.entity;
        var activeEntityFootprints = _.flatMap(this.activeEntities, (entity, i) => {
          var {resourceKey} = this.state.activeEntities[i];
          var Resource = {
            container: Container,
            volume: Volume
          }[resourceKey];

          return Resource.getWallprintInElevation({[resourceKey]: entity, elevation});
        });
        var walls = Elevation.get('walls', {elevation});

        maxX = _.max(_.map(activeEntityFootprints, 'x'));
        minX = _.min(_.map(activeEntityFootprints, 'x'));
        maxY = _.max(_.map(activeEntityFootprints, 'y'));
        minY = _.min(_.map(activeEntityFootprints, 'y'));

        var wallsData = _.filter(_.map(walls, wall => {
          var wallX = Elevation.getWallX({elevation, wall});
          var outlinePoints = Wall.getOutlinePoints({elevation, wall});

          return {wall, wallX, outlinePoints};
        }), wallData => !_.isEmpty(wallData.outlinePoints));

        const fromIsInRoom = lib.polygon.pointInsidePolygon({point: nearestEntity.entity.lineInRoom.from, polygon: this.room.plan.points});

        var firstWallXInset = fromIsInRoom ? 0 : _.min(_.map(wallsData, wallData => _.min(_.map(wallData.outlinePoints, 'x'))));

        transformerProps = {
          shapeProps: {
            size: {width: maxX - minX, height: maxY - minY},
            position: lib.object.sum({x: minX, y: minY - (maxY - minY)}, this.state.viewOffset, {x: firstWallXInset}),
            rotation: 0,
          },
          isRotatable: false
        };
      }
      else if (nearestEntity?.resourceKey === 'room') {
        var activeEntityFootprints = _.flatMap(this.activeEntities, (entity, i) => {
          var {resourceKey} = this.state.activeEntities[i];
          var Resource = {
            container: Container,
            volume: Volume
          }[resourceKey];

          return Resource.getFootprintInRoom({[resourceKey]: entity});
        });

        maxX = _.max(_.map(activeEntityFootprints, 'x'));
        minX = _.min(_.map(activeEntityFootprints, 'x'));
        maxY = _.max(_.map(activeEntityFootprints, 'y'));
        minY = _.min(_.map(activeEntityFootprints, 'y'));

        transformerProps = {
          shapeProps: {
            size: {width: maxX - minX, height: maxY - minY},
            position: lib.object.sum({x: minX, y: minY}, nearestEntity.entity.plan.position, this.state.viewOffset),
            rotation: 0,
          },
          isRotatable: true
        };
      }

      if (nearestEntity) {
        return transformerProps;
      }
    }

    return {shapeProps: {size: {width: 50, height: 50}, position: {x: 0, y: 0}, rotation: 0}};
  };

  getMultiSelectUpdatesFor = ({transformerProps, cachedTransformerProps, cachedActiveEntities}) => {
    var updateEntities = [];
    var {viewOffset} = this.state;
    var currentTransformerProps = cachedTransformerProps;

    var positionDelta = lib.object.difference(transformerProps.position, currentTransformerProps.position);
    var sizeDelta = lib.object.difference(transformerProps.size, currentTransformerProps.size);

    var sizeDeltaWidth = transformerProps.size.width / currentTransformerProps.size.width;
    var sizeDeltaHeight = transformerProps.size.height / currentTransformerProps.size.height;
    var wasScaled = transformerProps.anchorKey && (sizeDeltaWidth !== 1 || sizeDeltaHeight !== 1);
    var wasMoved = positionDelta.x !== 0 || positionDelta.y !== 0;
    var rotationDelta = transformerProps.rotation - currentTransformerProps.rotation;
    var nearestEntity = this.getNearestEntity({});

    _.forEach(cachedActiveEntities, (activeEntity, i) => {
      var entitySizeDelta = _.cloneDeep(sizeDelta);
      var entityPositionDelta = _.cloneDeep(positionDelta);
      var entitySizeDeltaWidth = _.clone(sizeDeltaWidth);
      var entitySizeDeltaHeight = _.clone(sizeDeltaHeight);

      if (nearestEntity?.resourceKey === 'elevation') {
        //TODO
      }
      else if (nearestEntity?.resourceKey === 'room') {
        var {resourceKey} = this.state.activeEntities[i];
        var Resource = {
          container: Container,
          volume: Volume
        }[resourceKey];

        var room = nearestEntity.entity;
        var sideKey = 'top';
        var size = {width: activeEntity.dimensions[K.sideSizeMap[sideKey].width], height: activeEntity.dimensions[K.sideSizeMap[sideKey].height]};
        var position = lib.object.sum(room.plan.position, viewOffset, {x: activeEntity.position.x, y: activeEntity.position.z});
        var widthChange = 0;
        var heightChange = 0;

        if (wasScaled) {
          var handleKeys = ['bottom', 'left', 'top', 'right'];
          var handleKey = {
            'top-center': 'top',
            'bottom-center': 'bottom',
            'middle-left': 'left',
            'middle-right': 'right'
          }[transformerProps.anchorKey] || (wasMoved ? (sizeDeltaWidth !== 1 ? 'left' : 'top') : (sizeDeltaWidth !== 1 ? 'right' : 'bottom'));
          var sideKeyIndex = _.indexOf(handleKeys, handleKey);
          let effectiveRotation = activeEntity.rotation;
          //hint not 100% sure why necessary
          if (_.includes([270, 90], activeEntity.rotation)) effectiveRotation = effectiveRotation === 90 ? 270 : 90;
          var effectiveSideKey = handleKeys[(sideKeyIndex + Math.floor(effectiveRotation / 90)) % 4];
          var depthIsBeingModified = _.includes(['top', 'bottom'], effectiveSideKey);
          //TODO if unit is locked size this should also be true
          var positionShouldTakeResize = depthIsBeingModified || _.includes(['baseFreestandingAppliance', 'tallFreestandingAppliance', 'wallFreestandingAppliance'], activeEntity.type);
          var willModifyPosition = positionShouldTakeResize || _.includes(['top', 'left'], effectiveSideKey);

          //TODO determine if there are other containers inline that need to share the transform
          //TODO if there are remaining transform needs to apply to position
          widthChange = _.includes(['left', 'right'], effectiveSideKey) ? entitySizeDelta.width + entitySizeDelta.height : 0;
          heightChange = _.includes(['bottom', 'top'], effectiveSideKey) ? entitySizeDelta.width + entitySizeDelta.height : 0;

          if (willModifyPosition) {
            var scalar = 1;

            //HINT in this condition a unit is being resized, but doesn't have anywhere to go, so we decided whether or not to
            //offset its position based on its relative distance to the adjusted handle IE if its in the bottom of the group
            // and the top handle is moved, it should not move with it, if its at the top and the top handle is moved, it should move with it
            if (positionShouldTakeResize) {
              scalar = effectiveSideKey === 'bottom' ? -1 : 1;
              var axisKey = _.includes(['left', 'right'], handleKey) ? 'x' : 'y';
              var transformerSizeKey = axisKey === 'x' ? 'width' : 'height';
              var orientedVertical = !_.includes(['left', 'right'], effectiveSideKey);
              var xNegativeFlag = _.includes([90, 180], activeEntity.rotation) ? -1 : 1;
              var yNegativeFlag = _.includes([180, 270], activeEntity.rotation) ? -1 : 1;

              var depthOffset = (axisKey === 'x' ? xNegativeFlag : yNegativeFlag) * activeEntity.dimensions[orientedVertical ? 'depth' : 'width'];

              var originalInstancePosition = Resource.getPositionInRoom({[resourceKey]: activeEntity});
              var originalGroupPosition = lib.object.difference(cachedTransformerProps.position, room.plan.position, viewOffset);
              var originalInstancePositionInGroup = lib.object.difference(originalInstancePosition, originalGroupPosition);

              var backPositionOnNumberLine = lib.number.round(originalInstancePositionInGroup[axisKey], {toNearest: K.minPrecision});
              var frontPositionOnNumberLine = backPositionOnNumberLine + depthOffset;
              var handlePositionOnNumberLine = _.includes(['right', 'bottom'], handleKey) ? cachedTransformerProps.size[transformerSizeKey] : 0;
              var minDistanceFromHandle = _.min([Math.abs(handlePositionOnNumberLine - backPositionOnNumberLine), Math.abs(handlePositionOnNumberLine - frontPositionOnNumberLine)]);
              var maxDistanceFromHandle = _.max([Math.abs(handlePositionOnNumberLine - backPositionOnNumberLine), Math.abs(handlePositionOnNumberLine - frontPositionOnNumberLine)]);

              if (minDistanceFromHandle >= 1 && maxDistanceFromHandle > cachedTransformerProps.size[transformerSizeKey] - 1) scalar = 0;

              // scalar *= (cachedTransformerProps.size[transformerSizeKey] - distanceFromHandle) / cachedTransformerProps.size[transformerSizeKey];
            }

            entityPositionDelta = lib.object.multiply(lib.trig.rotate({point: {x: widthChange, y: heightChange}, byDegrees: activeEntity.rotation}), scalar * -1);

            if (positionShouldTakeResize) {
              widthChange = 0;
              heightChange = 0;
            }
          }
          else {
            entityPositionDelta = {x: 0, y: 0};
          }
        }

        var updatedSize = {
          width: size.width + widthChange,
          height: size.height + heightChange
        };

        //HINT don't update depth for units because we almost never want
        if (depthIsBeingModified && _.find(cachedActiveEntities, sibling => sibling.rotation !== activeEntity.rotation)) {
          updatedSize = size;
        }

        let entityTransformProps = {
          size: updatedSize,
          position: lib.object.sum(position, entityPositionDelta),
          rotation: activeEntity.rotation + rotationDelta
        };

        if (rotationDelta) {
          var transformerMidPoint = lib.object.sum(currentTransformerProps.position, {x: currentTransformerProps.size.width / 2, y: currentTransformerProps.size.height / 2});

          //HINT rotate new position around center of total selection area, not just container
          entityTransformProps.position = lib.trig.rotate({point: position, byDegrees: rotationDelta, aroundOrigin: transformerMidPoint});
        }

        var updatedProps = Resource.getUpdatedPropsForTransformerProps({[resourceKey]: activeEntity, transformerProps: entityTransformProps, room, viewOffset: this.state.viewOffset, viewKey: 'top'});

        updateEntities[i] = resourceKey === 'container' ? Container.constrainProps({container: {...activeEntity, ...updatedProps}}) : {...activeEntity, ...updatedProps};
      }
    });

    return updateEntities;
  };

  handleMultiSelectTransform = (transformerProps) => {
    if (!this.state.cachedTransformerProps) {
      this.setState({cachedTransformerProps: this.getMultiSelectTransformerProps().shapeProps, cachedActiveEntities: _.clone(this.activeEntities)});
    }

    var updates = {
      containers: [],
      volumes: [],
      products: [],
      productOptions: []
    };

    var updatedEntities = this.getMultiSelectUpdatesFor({transformerProps, cachedTransformerProps: this.state.cachedTransformerProps, cachedActiveEntities: this.state.cachedActiveEntities});

    _.forEach(updatedEntities, (updatedEntity, i) => {
      var {resourceKey} = this.state.activeEntities[i];
      updates[pluralize(resourceKey)].push({where: {id: updatedEntity.id}, props: {...updatedEntity, eventType: 'transform'}});

      if (resourceKey === 'container') {
        var dependentUpdates = Container.getDependentProductUpdates({container: updatedEntity, oldContainer: this.activeEntities[i], actionKey: 'transform'});

        updates.products.push(...dependentUpdates.products.updates);
        updates.productOptions.push(...dependentUpdates.productOptions.updates);
      }
    });

    _.forEach(updates, (updateArray, resourceKey) => {
      if (updateArray.length > 0) {
        this.props[`update${_.upperFirst(resourceKey)}`]({updates: updateArray, hitApi: false});
      }
    });
  };

  handleMultiSelectTransformEnd = (transformerProps) => {
    var updatesMap = {
      productOptions: {creations: [], updates: [], deletedIds: []},
      products: {creations: [], updates: [], deletedIds: []},
      containers: {creations: [], updates: [], deletedIds: []},
      volumes: {creations: [], updates: [], deletedIds: []}
    };

    var activeEntities = _.cloneDeep(this.activeEntities);
    var cachedActiveEntities = this.state.cachedActiveEntities;

    if (transformerProps) {
      var {viewOffset} = this.state;
      var currentTransformerProps = this.getMultiSelectTransformerProps().shapeProps;
      cachedActiveEntities = activeEntities;

      activeEntities = this.getMultiSelectUpdatesFor({transformerProps, cachedTransformerProps: currentTransformerProps, cachedActiveEntities});
    }

    _.forEach(activeEntities, (activeEntity, i) => {
      var {resourceKey} = this.state.activeEntities[i];
      var Resource = {
        container: Container,
        volume: Volume
      }[resourceKey];
      updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, Resource.update({[resourceKey]: _.omit(activeEntity, ['eventType']), props: _.omit(activeEntity, ['eventType']), isBatched: true}));
    });
    ///> batch update countertops

    //HINT this is debounced so its ok to call it before redux is updated
    //Important that this is above redux update so that countertops remain hidden until we finish recalculating them
    Room.updateManagedResources({room: this.room, reduxActions: this.props});

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions: this.props});

    this.pushToUndoQueue({actions: _.map(cachedActiveEntities, (cachedActiveEntity, i) => {
      return {type: this.state.activeEntities[i].resourceKey, eventKey: 'transformEnd', instance: cachedActiveEntity};
    })});

    this.setState({cachedTransformerProps: undefined, cachedActiveEntities: undefined});
  };

  setColorKeyPopupIsVisible = ({value}) => {
    this.setState({colorKeyPopupIsVisible: value});
  };

  handleAddMenuMouseUp = async (dragItem) => {
    if (dragItem && this.state.viewMode === 'lite' && !this.state.creatingLiteModeEntity) {
      this.setState({creatingLiteModeEntity: true});
      var {viewMode, activeViewEntityId, activeViewEntityResourceKey} = this.state;
      var {activeEntities, activeViewEntity} = this;
      var {project} = this.props;

      var projectId = project.id;
      var {versionId} = project;

      if (dragItem.resourceKey === 'archetype') {
        //TODO
        //Will be tricky bc of relative positions of containers and volumes
        //when added not spacially
      } else {
        var container, product, scopeId, roomId;

        if (activeViewEntityResourceKey === 'scope') {
          scopeId = activeViewEntityId;
          roomId = activeViewEntity.roomId;
        }

        if (dragItem.resourceKey === 'container') {
          let container = await Container.create({props: {...dragItem.props, scopeId, projectId, versionId, position: {}}, reduxActions: this.props});

          setTimeout(() => {
            this.setActiveEntities({entities: [{resourceKey: 'container', id: container.id}], isMultiSelect: false});
          });
        }
        else if (dragItem.resourceKey === 'product') {
          var container = dragItem.props.productInstanceId ? Product.get('container', {product: activeEntities[0]}) : activeEntities[0];

          var parentProduct = dragItem.props.productInstanceId ? activeEntities[0] : undefined;
          var siblingProducts = parentProduct ? Product.get('childProducts', {product: activeEntities[0]}) : Container.get('unmanagedProductInstances', {container});

          function findAvailableSpaces(maxRangeTo, ranges) {
            let available = [];
            let currentTo = 0;

            for (var range of ranges) {
              if (currentTo < range.from) {
                available.push({from: currentTo, to: range.from});
              }
              currentTo = Math.max(currentTo, range.to);
            }

            // If there's space between the last range's to and maxRangeTo
            if (currentTo < maxRangeTo) {
              available.push({from: currentTo, to: maxRangeTo});
            }

            return available;
          }

          var productXranges = _.sortBy( _.map(siblingProducts, product => Product.getXRange({product})), 'from');
          var dropzoneSize = Product.getDropzoneSize({product: dragItem.props, container, viewKey: 'front', parentProduct});
          var productDimensions = _.defaults(Product.getDefaultDimensions({product: {...dragItem.props}, container, viewMode: 'lite'}), {width: 12, height: 10, depth: 10});

          if (dragItem.props.productId === 516) {
            productDimensions = {width: 15, height: container.dimensions.height, depth: container.dimensions.depth};
          }

          var xRanges = findAvailableSpaces(dropzoneSize.width, productXranges);

          if (Product.getIsBarblockComponent({product: dragItem.props})) xRanges = _.filter(xRanges, xRange => xRange.to - xRange.from > 0.75);

          var leftMostXRange = _.find(xRanges, xRange => {
            return xRange.to - xRange.from >= productDimensions.width;
          });

          var productX;

          if (leftMostXRange) productX = leftMostXRange.from;

          if (!productX && productX !== 0) {
            productX = _.min([dropzoneSize.width, _.max(_.map(xRanges, 'from'))]);
            //TODO when all xs are filled use grid to position in the available Y space
          }

          //HINT add barblock spacing
          if (Product.getIsBarblockComponent({product: dragItem.props}) && productX !== 0) productX += 0.75;

          var productPosition = {x: productX, y: 0, z: 0};

          var productProps = {scopeId, projectId, versionId,
            ...dragItem.props, primaryAssociationKey: dragItem.props.productInstanceId ? 'productInstance' : 'containerInstance',
            position: productPosition,
            dimensions: productDimensions,
          };

          var additionalUpdates;

          if (!(dragItem.props.productInstanceId && !Product.getIsBarblockComponent({product: dragItem.props})) && !container.customData.isLocked && productX + productDimensions.width > dropzoneSize.width) {
            var widthChange = productX + productDimensions.width - dropzoneSize.width;

            var newContainerWidth = container.dimensions.width + widthChange;

            var updatedDimensions = Container.constrainProps({container: {...container, dimensions: {...container.dimensions, width: newContainerWidth}}}).dimensions;

            additionalUpdates = {containers: {updates: [{where: {id: container.id}, props: {dimensions: updatedDimensions}}]}};

            if (Product.getIsBarblockComponent({product: dragItem.props})) {
              var constraints = Product.getConstraints({product: parentProduct});

              const constrainer = new lib.DimensionConstrainer({constraints});
              var updatedDimensions = constrainer.constrain({dimensions: {...parentProduct.dimensions, width: parentProduct.dimensions.width + widthChange}});

              additionalUpdates = {
                ...additionalUpdates,
                products: {updates: [{where: {id: parentProduct.id}, props: {dimensions: updatedDimensions}}]},
              };
            }
          }

          let product = await Product.create({props: productProps, additionalUpdates, reduxActions: this.props});

          // setTimeout(() => {
          //   this.setActiveEntities({entities: [{resourceKey: 'product', id: product.id}], isMultiSelect: false});
          // });
        }
      }

      if (_.includes(['container', 'product'], dragItem.resourceKey)) {
        setTimeout(() => {
          updateProductionIds({project, reduxActions: this.props});
        }, 1000);
      }

      this.setState({creatingLiteModeEntity: false});
    }
    else if (this.state.isDragging && this.state.isDraggingOnCanvas) {
      dragItem = this.state.dragItem;
      var {dragEntityPosition, parentOrigin, viewOffset, viewMode, activeViewEntityId} = this.state;
      var {activeEntities} = this;
      var {project} = this.props;
      var nearestEntity = this.getNearestEntity({});

      this.setDragData({isDragging: false, loadingDrop: true});

      var projectId = project.id;
      var {versionId} = project;

      var archetypeIsReplacingRoom = false;

      //HINT if adding to a room that hasn't yet been drawn, replace the room with the archetype
      if (dragItem.resourceKey === 'archetype' && dragItem.props.isAddingArchetypeToRoom) {
        var roomId = viewMode === 'top' ? (_.get(activeEntities, '[0].id') || nearestEntity.entity.id) : activeViewEntityId;
        var room = this.props.rooms[roomId];

        if (room && !_.get(room, 'plan.closed')) {
          archetypeIsReplacingRoom = true;
          roomToDelete = room;
          dragItem.props.isAddingArchetypeToRoom = false;

          if (room.title) dragItem.props.title = room.title;
          dragItem.props.rank = room.rank;
        }
      }

      if (dragItem.resourceKey === 'archetype' && !dragItem.props.isAddingArchetypeToRoom) {
        var minX = _.minBy(dragItem.props.plan.points, 'x').x;
        var minY = _.minBy(dragItem.props.plan.points, 'y').y;
        var maxX = _.maxBy(dragItem.props.plan.points, 'x').x;
        var maxY = _.maxBy(dragItem.props.plan.points, 'y').y;

        dragItem.props.plan = {...dragItem.props.plan, position: lib.object.difference(dragItem.props.plan.position, viewOffset)};

        this.props.updateRoom({id: dragItem.props.id, props: {plan: dragItem.props.plan, isTemporaryArchetype: 0}});
        // console.log(dragItem.props);
        // const apiResponse = await lib.api.request({uri: 'de-project/create-archetype', body: {projectId: dragItem.props.projectId, versionId: dragItem.props.versionId, archetypeId: dragItem.id, roomProps: dragItem.props}});
        // const {
        //   room,
        //   scopes,
        //   elevations,
        //   walls,
        //   volumes,
        //   archElementInstances,
        //   containerInstances,
        //   productInstances,
        //   productOptionInstances,
        //   projectGraphics
        // } = apiResponse.data;

        // var currentRooms = _.values(this.props.rooms) || [];
        // var roomToDelete;

        // if (currentRooms.length === 1 && !roomToDelete) {
        //   if (!currentRooms[0].title && !_.get(currentRooms, '[0].plan.closed')) {
        //     roomToDelete = currentRooms[0];
        //   }
        // }

        // this.props.trackScopes({scopes});
        // this.props.trackElevations({elevations});
        // this.props.trackWalls({walls});
        // this.props.trackVolumes({volumes});
        // this.props.trackArchElements({archElements: archElementInstances});
        // this.props.trackContainers({containers: containerInstances});
        // this.props.trackProducts({products: productInstances});
        // this.props.trackProductOptions({productOptions: productOptionInstances});
        // this.props.trackProjectGraphics({projectGraphics});
        // this.props.trackRooms({rooms: [room]});

        // setTimeout(() => {
        //   if (archetypeIsReplacingRoom) {
        //     this.setActiveViewEntityId('room', room.id);
        //   }
        //   else {
        //     this.setActiveEntities({entities: [{resourceKey: 'room', id: room.id}], isMultiSelect: false});
        //   }
        //   // this.props.setActiveVisibilityMode({key: 'schematic'});

        //   Room.updateManagedResources({room, reduxActions: this.props});

        //   if (roomToDelete) {
        //     Room.destroy({room: roomToDelete, reduxActions: this.props});
        //   }
        // });
      }
      else if (dragItem.resourceKey === 'archetype' && dragItem.props.isAddingArchetypeToRoom) {
        var minX = _.minBy(dragItem.props.plan.points, 'x').x;
        var minY = _.minBy(dragItem.props.plan.points, 'y').y;
        var maxX = _.maxBy(dragItem.props.plan.points, 'x').x;
        var maxY = _.maxBy(dragItem.props.plan.points, 'y').y;

        dragItem.props.plan = {...dragItem.props.plan, position: lib.object.difference(dragItem.props.plan.position, {x: -(maxX + minX) / 2, y: -(maxY + minY) / 2}, viewOffset)};

        var roomId = dragItem.targetRoomId;
        var room = this.props.rooms[roomId];

        const apiResponse = await lib.api.request({uri: 'de-project/create-archetype', body: {projectId: project.id, versionId: project.versionId, archetypeId: dragItem.id, usingTemporaryArchetype: true, temporaryArchetypeRoomId: dragItem.props.id, roomProps: dragItem.props, scopeProps: _.first(_.values(this.props.scopesByRoomId[roomId])), addingToExistingRoom: true, roomId}});
        var {
          volumes,
          containerInstances,
          productInstances,
          productOptionInstances,
          elevations,
          roomUpdates
        } = apiResponse.data;

        var minX, maxX, minY, maxY;

        var volumeFootprints = _.map(volumes, volume => Volume.getFootprintInRoom({volume}));
        var containerFootprints = _.map(containerInstances, container => Container.getFootprintInRoom({container}));

        var entityFootprints = _.concat(volumeFootprints, containerFootprints);
        var flattenedFootprints = _.flatten(entityFootprints);

        maxX = _.max(_.map(flattenedFootprints, 'x'));
        minX = _.min(_.map(flattenedFootprints, 'x'));
        maxY = _.max(_.map(flattenedFootprints, 'y'));
        minY = _.min(_.map(flattenedFootprints, 'y'));

        var scopeOutline = {
          size: {width: Math.abs(maxX - minX), height: Math.abs(maxY - minY)},
          position: {x: minX, y: minY},
        };

        var volumeDeltas = _.map(volumes, volume => {
          return {x: volume.position.x - scopeOutline.position.x - scopeOutline.size.width / 2, z: volume.position.z - scopeOutline.position.y - scopeOutline.size.height / 2};
        });

        var containerDeltas = _.map(containerInstances, container => {
          return {x: container.position.x - scopeOutline.position.x - scopeOutline.size.width / 2, z: container.position.z - scopeOutline.position.y - scopeOutline.size.height / 2};
        });

        var mousePosition = lib.object.sum(lib.object.difference(dragItem.props.plan.position, nearestEntity.position, nearestEntity.offset));

        volumes = _.map(volumes, (volume, index) => {
          var volumeDelta = volumeDeltas[index];

          return {...volume,
            position: lib.object.sum({y: volume.position.y, x: mousePosition.x, z: mousePosition.y}, volumeDelta)
          };
        });

        // do the same like above for containers
        containerInstances = _.map(containerInstances, (container, index) => {
          var containerDelta = containerDeltas[index];

          return {...container,
            position: _.mapValues(lib.object.sum({y: container.position.y, x: mousePosition.x, z: mousePosition.y}, containerDelta), value => lib.number.round(value, {toNearest: 1 / 16}))
          };
        });

        elevations = _.map(elevations, elevation => {
          return {
            ...elevation,
            lineInRoom: {
              from: lib.object.sum(mousePosition, {x: elevation.lineInRoom.from.x - scopeOutline.position.x - scopeOutline.size.width / 2, y: elevation.lineInRoom.from.y - scopeOutline.position.y - scopeOutline.size.height / 2}),
              to: lib.object.sum(mousePosition, {x: elevation.lineInRoom.to.x - scopeOutline.position.x - scopeOutline.size.width / 2, y: elevation.lineInRoom.to.y - scopeOutline.position.y - scopeOutline.size.height / 2}),
            }
          }
        });

        if (volumes.length) {
          lib.api.update('volumes', _.map(volumes, volume => {
            return {where: {id: volume.id}, props: {position: volume.position}};
          }));
        }
        if (containerInstances.length) {
          lib.api.update('containerInstances', _.map(containerInstances, container => {
            return {where: {id: container.id}, props: {position: container.position}};
          }));
        }
        if (elevations.length) {
          lib.api.update('elevations', _.map(elevations, elevation => {
            return {where: {id: elevation.id}, props: {lineInRoom: elevation.lineInRoom}};
          }));
        }

        // var updatesMap = {
        //   productOptions: {creations: [], updates: [], deletedIds: [], tracks: productOptionInstances || []},
        //   products: {creations: [], updates: [], deletedIds: [], tracks: productInstances || []},
        //   containers: {creations: [], updates: [], deletedIds: [], tracks: []},
        //   volumes: {creations: [], updates: [], deletedIds: [], tracks: volumes || []},
        //   archElements: {creations: [], updates: [], deletedIds: [], tracks: containerInstances || []},
        //   projectGraphics: {creations: [], updates: [], deletedIds: [], tracks: []},
        //   elevations: {creations: [], updates: [], deletedIds: [], tracks: []},
        //   walls: {creations: [], updates: [], deletedIds: [], tracks: []},
        //   rooms: {creations: [], updates: [], deletedIds: [], tracks: []},
        // };

        // if (roomUpdates) {
        //   updatesMap.rooms.updates.push({where: {id: room.id}, props: roomUpdates, hitApi: false});
        // }

        this.props.trackVolumes({volumes});
        this.props.trackContainers({containers: containerInstances});
        this.props.trackProducts({products: productInstances});
        this.props.trackProductOptions({productOptions: productOptionInstances});
        this.props.trackElevations({elevations});

        if (roomUpdates) {
          this.props.updateRoom({id: room.id, props: roomUpdates, hitApi: false});
        }

        // var roomDestructions = Room.destroy({room: dragItem.props, reduxActions: this.props, updateProjectLevelStuff: false});

        // _.forEach(roomDestructions, (destroyedIds, resourceKey) => {
        //   updatesMap[resourceKey].deletedIds = [...(updatesMap[resourceKey].deletedIds || []), ...(destroyedIds || [])];
        // });

        // UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions: this.props});

        setTimeout(() => {
          Room.destroy({room: dragItem.props, reduxActions: this.props, updateProjectLevelStuff: false});

          this.setActiveEntities({entities: [
            ..._.map(_.filter(containerInstances, container => !(container.type === 'countertop' && container.customData.inManualMode === 0)), container => ({id: container.id, resourceKey: 'container'})),
            ..._.map(volumes, volume => ({id: volume.id, resourceKey: 'volume'}))
          ], isMultiSelect: [...containerInstances, ...volumes].length > 1});

          Scope.updateComponents({room, reduxActions: this.props});

          Room.updateManagedResources({room, reduxActions: this.props, setIssuesData: this.props.setIssuesData});
        });
      }
      else {
        var roomId = nearestEntity.resourceKey === 'room' ? nearestEntity.entity.id : nearestEntity.entity.roomId;
        var scopeId = _.values(this.props.scopesByRoomId[roomId])[0].id;
        var position, container, archElement, volume, product, rotation = 0;
        var snapToWall = true;

        if (nearestEntity.resourceKey === 'room') {
          var positionInRoom = lib.object.difference(dragEntityPosition, nearestEntity.position, nearestEntity.offset, viewOffset);

          position = {x: positionInRoom.x, z: positionInRoom.y, y: 0}; //TODO undefined var

          if (dragItem.resourceKey === 'container') {
            position = {x: positionInRoom.x, z: positionInRoom.y, y: Container.getDefaultY({container: dragItem.props})};
            var scopeId = Container.getScopeId({container: {...dragItem.props, projectId, versionId, position}, scopes: Room.get('scopes', {roomId})});

            var container;
            if (dragItem.isNonSpacial) {
              var customData = {...dragItem.props.customData};

              if (customData.isLocked) customData.isLocked = false;

              Container.update({container: dragItem.props, props: {scopeId, position, customData}, reduxActions: this.props});

              container = dragItem.props;
            }
            else {
              container = await Container.create({props: {...dragItem.props, scopeId, projectId, versionId, position}, reduxActions: this.props});
            }


            if (Container.getTypeDataFor({container:container}).isOrnament) this.updateVisibilityLayers({key: 'ornamentTopIndicators', isVisible: true});


            setTimeout(() => {
              this.setActiveEntities({entities: [{resourceKey: 'container', id: container.id}], isMultiSelect: false});
            });
          }
          else if (dragItem.resourceKey === 'volume') {
            position = {x: positionInRoom.x, z: positionInRoom.y, y: Container.getDefaultY({container: dragItem.props})};

            volume = await Volume.create({props: {...dragItem.props, scopeId, position, projectId, versionId, rotation}, reduxActions: this.props});

            setTimeout(() => {
              this.setActiveEntities({entities: [{resourceKey: 'volume', id: volume.id}], isMultiSelect: false});
            });
          }
          else if (dragItem.resourceKey === 'archElement') {
            if (snapToWall) {
              var walls = Room.get('walls', {room: nearestEntity.entity});
              var nearestWall = _.minBy(_.values(walls), wall => lib.trig.distance({fromPoint: positionInRoom, toLine: Wall.getLine({wall}).inRoom}));
              var positionOnWall = lib.trig.nearestPoint({point: positionInRoom, onLine: Wall.getLine({wall: nearestWall}).inRoom});
              let width = ArchElement.getSize({archElement: dragItem.props, viewKey: 'top'}).width;
              width += (dragItem.props.customData.leftTrimWidth || 0) + (dragItem.props.customData.rightTrimWidth || 0);
              width -= (_.get(dragItem.props, 'customData.dimensions.width', 0)) - (dragItem.props.customData.width || 0);

              position = {x: lib.trig.distance({fromPoint: positionOnWall, toPoint: Wall.getLine({wall: nearestWall}).inRoom.from}) - width, y: ArchElement.getTypeData({archElement: dragItem.props}).defaultY || 0};
            }

            archElement = await lib.api.create('archElementInstance', {props: {...dragItem.props, roomId: nearestEntity.entity.id, ...(snapToWall ? {position, wallId: nearestWall.id} : {position}), projectId, versionId}});

            this.props.trackArchElements({archElements: [archElement]});

            setTimeout(() => {
              this.setActiveEntities({entities: [{resourceKey: 'archElement', id: archElement.id}], isMultiSelect: false});
            });
          }
        }
        else if (nearestEntity.resourceKey === 'elevation') {
          var relativePosition = lib.object.difference(dragEntityPosition, viewOffset, parentOrigin || lib.object.sum(nearestEntity.offset, nearestEntity.position));
          var elevation = nearestEntity.entity;
          var position2d = relativePosition;

          if (_.includes(['container', 'volume'], dragItem.resourceKey)) {
            position2d.y += dragItem.props.dimensions.height;

            //snap to nearest wall
            if (snapToWall) {
              var wall = Elevation.getWallFor({elevation, x: position2d.x, position: position2d});
              var volume = Elevation.getVolumeFor({elevation, position: {...position2d, y: -position2d.y}});

              if (volume) {
                var xRelativeToWall = position2d.x - Volume.getPositionInElevation({volume, elevation}).x;

                var unitPositionXZInWall = lib.trig.rotate({point: {x: xRelativeToWall, y: 0}, byRadians: Elevation.getAlpha({elevation}) + Math.PI});
                var volumeLinesInRoom = Volume.getFootprintLines({volume});
                var relevantLineInRoom = volumeLinesInRoom[Volume.getSideKey({volume, elevation, viewKey: 'front'})];

                var volumePositionXZ = lib.object.sum(relevantLineInRoom.from, unitPositionXZInWall);

                position = {x: volumePositionXZ.x, z: volumePositionXZ.y, y: -position2d.y};
              }
              else if (wall) {
                var xRelativeToWall = position2d.x - Wall.getXOffsetInElevation({wall, elevation});

                var wallPositionXZInWall = lib.trig.rotate({point: {x: xRelativeToWall, y: 0}, byRadians: Elevation.getAlpha({elevation}) + Math.PI});
                var wallPositionXZ = lib.object.sum(Wall.getLine({wall}).inRoom.from, wallPositionXZInWall);

                position = {x: wallPositionXZ.x, z: wallPositionXZ.y, y: -position2d.y};
              }
            }
            else {
              //convert the existing position3d to a position on the wall, so it is effectively 2d
              var elevationXYOrigin = elevation.lineInRoom.from;
              var elevationXZOrigin = {x: elevationXYOrigin.x, z: elevationXYOrigin.y};

              var offsetXZPositionInElevation = lib.object.difference(position, elevationXZOrigin);
              var rotatedXZPositionInElevation = lib.math.trig.rotate({point: {x: offsetXZPositionInElevation.x, y: offsetXZPositionInElevation.z}, byRadians: -Elevation.getAlpha({elevation}) + Math.PI});

              var newXZPosition = {x: position2d.x, y: rotatedXZPositionInElevation.y};

              //convert the position back to 3d
              var rotatedXZPositionInRoom = lib.math.trig.rotate({point: newXZPosition, byRadians: Elevation.getAlpha({elevation}) + Math.PI});

              position = {x: rotatedXZPositionInRoom.x, z: rotatedXZPositionInRoom.y, y: -position2d.y};
              position = lib.object.sum(position, elevationXZOrigin);
            }
          }

          if (dragItem.resourceKey === 'container') {
            rotation = lib.trig.normalize({degrees: Elevation.getRotation({elevation})});

            var scopeId = Container.getScopeId({container: {...dragItem.props, projectId, versionId, position, rotation}, scopes: Room.get('scopes', {roomId})});

            var container;

            if (!position) {
              alert('please add container from plan or an elevation with a wall.')
            }
            else {
              try {
                if (dragItem.isNonSpacial) {
                  Container.update({container: dragItem.props, props: {scopeId, position, rotation}, reduxActions: this.props});

                  container = dragItem.props;
                }
                else {
                  container = await Container.create({props: {...dragItem.props, scopeId, position, projectId, versionId, rotation}, reduxActions: this.props});
                }
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: 'adding container'});
              }

              setTimeout(() => {
                this.setActiveEntities({entities: [{resourceKey: 'container', id: container.id}], isMultiSelect: false});
              });
            }
          }
          else if (dragItem.resourceKey === 'volume') {
            rotation = lib.trig.normalize({degrees: Elevation.getRotation({elevation})});

            if (!position) {
              alert('please add volume from plan or an elevation with a wall.')
            }
            else {
              try {
                volume = await Volume.create({props: {...dragItem.props, scopeId, position, projectId, versionId, rotation}, reduxActions: this.props});
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: 'adding volume'});
              }

              setTimeout(() => {
                this.setActiveEntities({entities: [{resourceKey: 'volume', id: volume.id}], isMultiSelect: false});
              });
            }
          }
          else if (dragItem.resourceKey === 'archElement') {
            var wall = Elevation.getWallFor({elevation, x: position2d.x});
            var room = Elevation.get('room', {elevation});

            position2d.y = -position2d.y - ArchElement.getSize({archElement: dragItem.props, viewKey: 'front'}).height;
            position2d.x -= (dragItem.props.customData.leftTrimWidth || 0) + (dragItem.props.customData.rightTrimWidth || 0);

            if (!wall || !_.isNumber(position2d.y) || !_.isNumber(position2d.x)) {
              alert('please add arch element from plan or an elevation with a wall.')
            }
            else {
              try {
                archElement = await lib.api.create('archElementInstance', {props: {...dragItem.props, roomId: room.id, wallId: wall.id, position: position2d, projectId, versionId}});
              }
              catch (error) {
                global.handleError({error, info: {componentStack: error.stack}, message: 'adding arch element'});
              }

              this.props.trackArchElements({archElements: [archElement]});

              setTimeout(() => {
                this.setActiveEntities({entities: [{resourceKey: 'archElement', id: archElement.id}], isMultiSelect: false});
              });
            }
          }
          else if (dragItem.resourceKey === 'product') {
            var container = dragItem.props.productInstanceId ? Product.get('container', {product: activeEntities[0]}) : activeEntities[0];
            scopeId = Container.getScopeId({container, scopes: Room.get('scopes', {roomId})});

            var productProps = {scopeId, projectId, versionId,
              ...dragItem.props, primaryAssociationKey: dragItem.props.productInstanceId ? 'productInstance' : 'containerInstance',
              position: {x: relativePosition.x, y: relativePosition.y + dragItem.props.dimensions.height, z: dragItem.props.productId === 1455 ? 7/8 : 0},
              dimensions: _.defaults(Product.getDefaultDimensions({product: {...dragItem.props}, container}), {width: 12, height: 10, depth: 10})
            };

            if (dragItem.props.productId === 516) {
              productProps.dimensions = {width: 15, height: container.dimensions.height, depth: container.dimensions.depth};
            }

            let product = await Product.create({props: productProps, reduxActions: this.props});

            setTimeout(() => {
              this.setActiveEntities({entities: [{resourceKey: 'product', id: product.id}], isMultiSelect: false});
            });
          }
        }
      }

      if (dragItem.resourceKey === 'container' || dragItem.resourceKey === 'volume') {
        setTimeout(() => {
          Project.autogenerateElevations({project, reduxActions: this.props});
        });
      }
      if (_.includes(['container', 'product'], dragItem.resourceKey)) {
        setTimeout(() => {
          updateProductionIds({project, reduxActions: this.props});
        }, 1000);
      }

      this.setDragData({loadingDrop: false});
    }
    else if (this.state.isDragging) {
      this.setDragData({isDragging: false});
    }
  };

  showAddingElevation = () => {
    this.setState({isAddingElevation: true});
  };

  handleAddElevation = ({from, to, otherProps, normalizePosition=true}) => {
    var {projectData, room} = this;
    var {offset, position} = this.getNearestEntity({});
    var {viewOffset} = this.state;

    var elevationFrom, elevationTo;

    if (normalizePosition) {
      elevationFrom = lib.object.difference(from, offset, position, viewOffset);
      elevationTo = lib.object.difference(to, offset, position, viewOffset);
    }
    else {
      elevationFrom = from;
      elevationTo = to;
    }

    if (!this.props.project.infinitePrecision) {
      elevationFrom = _.mapValues(elevationFrom, value => lib.round(value, {toNearest: K.minPrecision}));
      elevationTo = _.mapValues(elevationTo, value => lib.round(value, {toNearest: K.minPrecision}));
    }

    if (!room) {
      alert('Please select a room to add Elevations');
    }

    var elevations = Room.get('elevations', {room});

    var rank = _.every(elevations, elevation => _.isNumber(elevation.rank)) ? _.max(_.map(elevations, elevation => elevation.rank)) + 1 : undefined;

    var elevationProps = {props: {
      projectId: projectData.id,
      versionId: projectData.versionId,
      roomId: room.id,
      title: '',
      viewDepth: 50,
      sectionPositions: [],
      customData: {},
      lineInRoom: {from: elevationFrom, to: elevationTo},
      isAutomatic: 0, rank, generatedId: {},
      ...otherProps
    }};

    this.updateVisibilityLayers({key: 'elevationLines', isVisible: true});
    this.props.createElevations({propsSets: [elevationProps]});
    this.setState({isAddingElevation: false});
  };

  addProjectGraphic = (type) => this.setState({isAddingProjectGraphic: true, addingProjectGraphicType: type});

  handleAddProjectGraphic = async ({from, to, type, polygonPosition, polygonPoints = []} = {}) => {
    if (!this.state.isCreatingProjectGraphic) {
      this.setState({isCreatingProjectGraphic: true});

      if (!type) type = this.state.addingProjectGraphicType;
      var {viewMode, viewOffset, activeViewEntityId, activeViewEntityResourceKey, activeEntities, activeViewEntityResourceKey} = this.state;
      var {room, getIncludesActiveEntity, projectData, activeViewEntity} = this;
      var {offset, position} = this.getNearestEntity({});

      var isElevationGraphic = (activeViewEntityResourceKey === 'elevation');
      var roomId = isElevationGraphic ? (viewMode === 'front' ? activeViewEntity.roomId : activeViewEntityId) : room.id;
      var elevationId = isElevationGraphic ? (viewMode === 'front') ? activeViewEntityId : _.size(activeEntities) === 1 : null;

      var additionalOffset = isElevationGraphic ? {x: Elevation.getFirstWallXInset({elevation: activeViewEntity})} : {};

      var positionFrom = lib.object.difference(from, offset, position, viewOffset, additionalOffset);
      var positionTo = lib.object.difference(to, offset, position, viewOffset, additionalOffset);

      if (!this.props.project.infinitePrecision) {
        positionFrom = _.mapValues(positionFrom, value => lib.round(value, {toNearest: K.minPrecision}));
        positionTo = _.mapValues(positionTo, value => lib.round(value, {toNearest: K.minPrecision}));
      }

      var data;
      if (type === 'text') {
        data = { text: 'placeholder', position: positionFrom, fontSize: 9, size: {width: 50, height: 10}};
      }
      else if (type === 'rectangle') {
        data = {
          position: positionFrom,
          size: {width: 50, height: 50}, opacity: 1, strokeColor: 'black',
          strokeWidth: 1, strokeOpacity: 1, fillOpacity: 1, fillType: 'color', fill: 'transparent',
          fillTexture: {
            url: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
            mediumId: 'brick', size: {width: 100, height: 60}, delta: {x: 0, y: 0}, rotation: 0, opacity: 1
          }
        };
      }
      else if (type === 'circle') {
        data = {
          position: {...positionFrom, x: positionFrom.x + 25, y: positionFrom.y + 25},
          size: {width: 50, height: 50, radius: 25},
          opacity: 1, strokeColor: 'black',
          strokeWidth: 1, strokeOpacity: 1, fillOpacity: 1, fillType: 'color', fill: 'transparent',
          fillTexture: {
            url: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
            mediumId: 'brick', size: {width: 100, height: 60}, delta: {x: 0, y: 0}, rotation: 0, opacity: 1
          }

        };
      }
      else if (type === 'polygon') {
        polygonPosition = lib.object.difference(polygonPosition, offset, position, additionalOffset);

        if (!this.props.project.infinitePrecision) {
          polygonPosition = _.mapValues(polygonPosition, value => lib.round(value, {toNearest: K.minPrecision}));
        }

        data = {
          closed: true,
          position: polygonPosition,
          dragPosition: undefined,
          points: _.map(polygonPoints, point => ({x: point.x, y: point.y})),
          size: {width: 50, height: 50},
          opacity: 1,
          strokeColor: '#000000',
          strokeWidth: 1,
          strokeOpacity: 1,
          fillOpacity: 1,
          fillType: 'color',
          fill: 'transparent',
          fillTexture: {
            url: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
            mediumId: 'brick', size: {width: 100, height: 60}, delta: {x: 0, y: 0}, rotation: 0, opacity: 1
          }
        };
      }
      else if (type === 'textPointer') {
        data = {text: 'placeholder', position: positionTo, fontSize: 9, size: {width: 25, height: 10}, to: positionFrom, showArrow: true};
      }
      else {
        data = {from: positionFrom, to: positionTo};
      }

      var layer = this.state.activeDetailLevel;

      var {activeDetailLevel} = this.state;

      if (_.includes(['rendering', 'schematic'], activeDetailLevel)) {
        data = {
          ...data,
          hideOnInstallation: 1,
          hideOnProduction: 1,
          hideOnIntermediate: 1,
          hideOnFullDetail: 1
        };
      }
      else {
        data = {
          ...data,
          hideOnRendering: 1,
          hideOnSchematic: 1
        };
      }

      var projectGraphic = await lib.api.create('projectGraphic', {props: {projectId: projectData.id, versionId: projectData.versionId, type: type, roomId, elevationId, deleted: 0, isBindingDimension: 1, data, layer}});

      this.props.trackProjectGraphics({projectGraphics: [projectGraphic]});

      this.setState({isAddingProjectGraphic: false, addingProjectGraphicType: ''});

      setTimeout(() => {
        this.setActiveEntities({entities: [{resourceKey: 'projectGraphic', id: projectGraphic.id}], isMultiSelect: false});
      });
    }

    this.setState({isCreatingProjectGraphic: false});
  };

  handleIssuesButtonPress = ({value}) => {
    if ((this.state.activeEntities && this.state.activeEntities.length > 0)) {
      this.setState({showIssues: true});
      setTimeout(() => this.handleDeselect({updateIssues: false}));
    }
    else {
      this.setState({showIssues: value});
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className={`cfg-editor-page`} style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em', textTransform: 'uppercase', marginBottom: '0.5rem'}}>
            {this.state.loadingMessage}
          </div>
          <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
        </div>
      )
    }

    const {viewMode, activeViewEntityId, activeViewEntityResourceKey, viewOffset,
      visibleEntitiesDataByResourceKey, activeDimensionsLayer, activeDetailLevel, activeFillMode, editingDimensions, visibilityLayers, countertopsAreSelectable, showAccessoriesView, bothIsShowingElevations, isMeasuringTapeVisible, threeDMode} = this.state;
    const {projectData, selectionData, numericInputData, room, pushToUndoQueue, activeViewEntity, activeEntity, toggleParameterEditor} = this;
    var {hudIsHidden, isAdding, projectTreeIsShowing, showingPriceData, loadingDrop, isOrthographicLockEnabled, activeEntities, activeUserLense, showIssues} = this.state;
    var {project, archetypes, rooms} = this.props;

    var {isEmployee} = projectData;

    if (viewMode === 'front' || viewMode === 'lite') {
      var activeViewContextEntity = this.props.rooms[activeViewEntity.roomId];
    }

    var floor;

    if (_.includes(['top', 'both'], viewMode)) {
      floor = viewMode === 'top' ? activeViewEntity : this.props.floors[activeViewEntity.floorId];
    }

    var roomIsArchetype = room && _.includes(_.map(archetypes, 'roomId'), room.id);

    var inLiteMode = viewMode === 'lite';
    var shouldShowShareableLink = _.some(rooms, r => r.archetypeId);

    return (
      <div className={`cfg-editor-page${loadingDrop ? ' loading' : ''}`} style={{height: '100%'}}>
        <Header
          {..._.pick(this.props, ['project'])}
          {...{shouldShowShareableLink, activeUserLense, showIssues}}
          {..._.pick(this, ['setActiveUserLense', 'toggleAccessoriesView', 'toggleMaterialFactorsPopup', 'toggleShareableLinkPopup', 'handleIssuesButtonPress'])}
        />
        <div style={{height: 'calc(100% - 50px)', display: 'flex', position: 'relative'}}>
          {showAccessoriesView
            ? <>
              <AccessoriesView isEmployee={isEmployee} media={this.state.media} toggleAccessoriesView={this.toggleAccessoriesView} isAdding={this.state.isAdding} />
              {!this.state.isAdding && (
                <div
                  onClick={() => {
                    this.setDragData({isAdding: !this.state.isAdding});
                  }}
                  style={{position: 'absolute', bottom: K.spacing * 2, left: K.spacing * 2, borderRadius: 75, width: 50, height: 50, minHeight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', color: 'white'}}
                >
                  <img src={createIcon} style={{width: 20, height: 20}}/>
                </div>
              )}
            </>
            : (
              <>
                {!!threeDMode && (
                  <CanvasEntities3D
                    {...{viewOffset, activeDetailLevel, activeFillMode, viewMode, [activeViewEntityResourceKey]: activeViewEntity}}
                    threeDMode={threeDMode}
                    isArchetype={false}
                    includeRoomPosition
                    showWallsAndArchElements={visibilityLayers.wallsAndArchElements}
                  />
                )}
                {viewMode === 'lite' && (
                  <CanvasView
                    ref={(ref) => { if (!this.state.canvasViewRef) this.setState({canvasViewRef: ref});}}
                    {...{selectionData, projectData, numericInputData, viewMode, isOrthographicLockEnabled}}
                    onMultiSelectDelete={this.handleDeleteEvent}
                    getSnapData={this.getSnapData}
                    onCanvasDataChange={this.handleCanvasDataChange}
                    precision={this.state.precision}
                  >
                    {_.map(visibleEntitiesDataByResourceKey.rooms, ({entity: room, position, offset}) => (
                      <CanvasRoomGrid
                        key={room.id}
                        {...{position, room, visibilityLayers, activeDetailLevel, activeFillMode, viewOffset: memoObject(lib.object.sum(offset, this.state.viewOffset), `roomViewOffset${room.id}`)}}
                        setActiveViewEntityId={this.setActiveViewEntityId}
                      />
                    ))}
                    {_.map(visibleEntitiesDataByResourceKey.scopes, ({entity: scope, position, offset}) => (
                      <CanvasScopeGrid
                        key={scope.id}
                        {...{position, scope, visibilityLayers, activeDetailLevel, activeFillMode, activeUserLense, viewOffset: memoObject(lib.object.sum(offset, this.state.viewOffset), `roomViewOffset${room.id}`)}}
                        setActiveViewEntityId={this.setActiveViewEntityId}
                      />
                    ))}
                    {this.state.isMeasuringTapeVisible && this.lastMouseEvent && <MeasuringTapeCanvasObject hideMeasuringTape={this.hideMeasuringTape}/>}
                  </CanvasView>
                )}
                {!_.includes(['lite'], viewMode) && !threeDMode &&
                (<>
                  <CanvasView
                    ref={(ref) => { if (!this.state.canvasViewRef) this.setState({canvasViewRef: ref});}}
                    {...{selectionData, projectData, numericInputData, viewMode, isOrthographicLockEnabled}}
                    onMultiSelectDelete={this.handleDeleteEvent}
                    getSnapData={this.getSnapData}
                    onCanvasDataChange={this.handleCanvasDataChange}
                    onMouseDown={this.handleCanvasMouseDown}
                    onMouseUp={this.handleCanvasMouseUp}
                    onMouseMove={this.handleCanvasMouseMove}
                    precision={this.state.precision}
                    infinitePrecision={this.props.project.infinitePrecision}
                    onCanvasViewDidMount={() => this.setState({canvasViewLoaded: true})}
                  >
                    {(this.state.canvasViewLoaded && (
                      <>
                        {_.get(this, 'activeEntities.length') > 1 && (
                        <CanvasMultiselectTransformer
                          onTransform={this.handleMultiSelectTransform}
                          onTransformEnd={this.handleMultiSelectTransformEnd}
                          isSelected={true}
                          hideSelectFill={true}
                          {...{activeEntitiesData: this.state.activeEntities, viewOffset, nearestEntity: this.getNearestEntity({}), room}}
                        />
                      )}
                      {(_.includes(['top', 'both'], viewMode) && activeViewEntity) && (
                        <CanvasFloor {...{floor, viewOffset, stencilVisible: visibilityLayers.stencil, backgroundsVisible: visibilityLayers.backgroundsVisible, backgroundsSelectable: visibilityLayers.backgroundsSelectable}}/>
                      )}
                      {_.map(visibleEntitiesDataByResourceKey.rooms, ({entity: room, position, offset}) => (
                        <CanvasRoom
                          key={room.id}
                          {...{position, room, visibilityLayers, activeDetailLevel, activeFillMode, viewOffset: memoObject(lib.object.sum(offset, this.state.viewOffset), `roomViewOffset${room.id}`)}}
                          isDraggable={viewMode === 'top'}
                          setActiveViewEntityId={this.setActiveViewEntityId}
                          handleAddElevation={this.handleAddElevation}
                        />
                      ))}
                      {_.map(visibleEntitiesDataByResourceKey.elevations, ({entity: elevation, position, offset}) => (
                        <CanvasElevation key={`${elevation.id}-${JSON.stringify(elevation.lineInRoom)}-${elevation.viewDepth}`} {...{position, elevation, visibilityLayers, activeDetailLevel, activeFillMode, activeUserLense, viewOffset: memoObject(lib.object.sum(offset, this.state.viewOffset), `roomViewOffset${elevation.id}`)}}/>
                      ))}
                      {this.state.drawingScopeData.isDrawing && <EditableCanvasPolyline isSelected points={[]} stroke={'#f2c983'} offset={viewOffset} onClose={this.handleScopePolygonClose}/>}
                      {this.state.isDragging && this.state.isDraggingOnCanvas && this.renderDragCanvasComponent()}
                      {this.state.isMeasuringTapeVisible && this.lastMouseEvent && <MeasuringTapeCanvasObject hideMeasuringTape={this.hideMeasuringTape}/>}
                      {this.state.isAddingElevation && this.lastMouseEvent && <MeasuringTapeCanvasObject hideMeasuringTape={this.handleAddElevation}/>}
                      {this.state.isAddingProjectGraphic && this.lastMouseEvent && this.state.addingProjectGraphicType !== 'polygon' && <MeasuringTapeCanvasObject hideMeasuringTape={this.handleAddProjectGraphic} singleClickCapture={_.includes(['rectangle', 'circle', 'text'], this.state.addingProjectGraphicType)}/>}
                      {this.state.isAddingProjectGraphic && this.lastMouseEvent && this.state.addingProjectGraphicType === 'polygon' && <EditableCanvasPolyline isSelected points={[]} stroke={'#f2c983'} offset={viewOffset} onClose={({points, position}) => this.handleAddProjectGraphic({polygonPoints: points, polygonPosition: position})}/>}
                      {this.state.isAddingCustomDimension && this.lastMouseEvent && (<DimensionCreatorCanvasObject {...{numericInputData}} handleCreateDimension={this.handleNewCustomDimension}/>)}
                      {this.state.isDrawingScalingLine && this.lastMouseEvent && <MeasuringTapeCanvasObject ignoreSnaps hideMeasuringTape={this.handleScalingToolLineDraw}/>}
                      <CanvasRect fill="#9BCCE1" opacity={0.5} innerRef={this.freehandSelectionRectRef} />
                    </>))}
                  </CanvasView>
                </>)}
                {this.state.drawingScopeData.isShowingPopup && (
                  <AddProjectTreeNodePopup onClose={this.handleScopePopupOnClose} handleAddProjectTreeNode={({scopeTitle})=> this.handleScopePopupOnCreate({title: scopeTitle})} type={'scope'} />
                )}
                {!_.includes(['lite'], viewMode) && room && _.get(room, 'plan.closed') && !threeDMode && (
                  <ContextHudElement
                    {...{hasActiveEntities: _.size(this.activeEntities), selectionData: {}, projectData: _.omit(projectData, ['dimensionsData']), showWallsAndArchElements: visibilityLayers.wallsAndArchElements, activeViewContextEntity: room, visibleElevations: visibleEntitiesDataByResourceKey.elevations, room, viewMode, activeDetailLevel, activeFillMode: 'grayscale'}}
                  />
                )}
                {!hudIsHidden && (
                  <VisibilityLayersHudElement
                    {..._.pick(this, ['floor', 'updateVisibilityLayers', 'setActiveDimensionsLayer', 'setActiveDetailLevel', 'setActiveFillMode', 'setColorKeyPopupIsVisible', 'toggleDimEditsCopyPopupShowing'])}
                    {...{inLiteMode, visibilityLayers, viewMode, activeDimensionsLayer, bothIsShowingElevations, activeDetailLevel, activeFillMode, room, project, companyKey: project.companyKey, colorKeyPopupIsVisible: this.state.colorKeyPopupIsVisible, threeDMode}}
                  />
                )}
                {!(hudIsHidden || isAdding || projectTreeIsShowing) && (
                  <div style={{position: 'absolute', paddingLeft: 20, paddingTop: 15}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10,}}>
                      <div style={{fontSize: 17, fontWeight: 'bold'}}>{`${project.companyKey === 'hb' ? 'HB' : 'ST'} for ${project.title || project.clientName}`}</div>
                      <div style={{fontSize: '0.75rem', opacity: 0.6, marginLeft: K.spacing}}>#{project.id}</div>
                    </div>
                    <div style={{width: '10vw', textAlign: 'center'}} >
                      <PriceElement {...{showingPriceData, onPriceElementClick: () => {
                        if (this.props.onIsClientFacingChange) this.props.onIsClientFacingChange(!!showingPriceData);
                        this.setState({showingPriceData: !showingPriceData});
                      }}}/>
                    </div>
                  </div>
                )}
                <CanvasControlsHudElement
                  precision = {this.state.precision}
                  onPrecisionChange = {this.handlePrecisionChange}
                  canvasViewRef={this.state.canvasViewRef}
                  setThreeDMode={this.setThreeDMode}
                  threeDMode={threeDMode}
                  {...{viewMode, activeEntities: this.activeEntities, activeViewEntityResourceKey, editingDimensions, projectData, room, activeUserLense, activeViewEntityId, activeViewEntity, bothIsShowingElevations, isOrthographicLockEnabled}}
                  {..._.pick(this, ['setViewMode', 'getIncludesActiveEntity', 'updateVisibilityLayers', 'toggleIsOrthographicLockEnabled'])}
                  {..._.pick(this.props, ['project'])}
                  onAddElevation={this.showAddingElevation}
                  toggleMeasuringTapeVisibility={this.toggleMeasuringTapeVisibility}
                  toggleDatumPopup={this.toggleDatumPopup}
                  toggleDimensionEditing={this.toggleDimensionEditing}
                  toggleCountertopSelectability={this.toggleCountertopSelectability}
                  toggleShowingElevations={this.toggleShowingElevations}
                  onAddProjectGraphic={this.addProjectGraphic}
                />
                <EditorMenu
                  {...{inLiteMode, isEmployee, activeEntitiesData: this.state.activeEntities, activeViewEntityId, pushToUndoQueue, activeViewEntityResourceKey, activeViewEntity, activeDetailLevel, projectData, bothIsShowingElevations, viewMode, companyKey: project.companyKey, nearestEntity: this.getNearestEntity({}), showWallsAndArchElements: visibilityLayers.wallsAndArchElements, getProductsByCategoryFor}}
                  {..._.pick(this.state, ['projectTreeIsShowing', 'isAdding', 'isViewingArchetypePopup'])}
                  {..._.pick(this, ['setDragData', 'setActiveEntities', 'setActiveViewEntityId', 'getIncludesActiveEntity', 'getIsActiveEntity', 'setActiveDetailLevel', 'updateVisibilityLayers', 'toggleInfoPopupShowing', 'handleAddMenuMouseUp', 'room', 'hideAddMenu', 'showAddMenu', 'hideProjectTree', 'showProjectTree', 'handleDeselect', 'handleAddScope', 'tentativeScope'])}
                  showAccessoriesView={this.state.showAccessoriesView}
                  onAddElevation={this.showAddingElevation}
                  setThreeDMode={this.setThreeDMode}
                  isDragging={this.state.isDragging}
                  isDraggingOnCanvas={this.state.isDraggingOnCanvas}
                  lastMouseEvent={this.lastMouseEvent}
                  dragItem={this.props.dragItem}
                  onDeselect={this.handleDeselect}
                  onAddScope={this.handleAddScope}
                  focusMenuSearch={this.state.focusMenuSearch}
                  handleSetFocusMenuSearch={this.handleSetFocusMenuSearch}
                  toggleIsViewingArchetypesPopup={({value}) => this.setState({isViewingArchetypePopup: value})}
                />
                {roomIsArchetype && (
                  <ParameterEditorHudElement
                    {..._.pick(this.state, ['parameterEditorIsShowing'])}
                    {..._.pick(this, ['room'])}
                    hide={this.hideParameterEditor}
                    onDeselect={this.handleDeselect}
                    show={this.showParameterEditor}
                    isVisible={this.state.parameterEditorIsShowing}
                    activeEntitiesData={this.state.activeEntities}
                  />
                )}
                {((_.size(activeEntities) === 1 && _.size(this.activeEntities) === 1 && activeEntities[0].resourceKey !== 'scope')
                || (this.state.showIssues))
                && (
                  <PropertiesViewHudElement
                    {...{activeEntitiesData: this.state.activeEntities, getIncludesActiveEntity: this.getIncludesActiveEntity, toggleParameterEditor, activeDetailLevel, viewMode, activeFillMode, activeUserLense, showIssues: this.state.showIssues, activeViewEntityResourceKey, activeViewEntityId, viewKey: (viewMode === 'front' || viewMode === 'lite') ? 'front' : 'top', elevation: activeViewEntityResourceKey === 'elevation' ? this.activeViewEntity : {}}}
                    hide={() => this.handleDeselect()}
                    {..._.pick(this.props, ['project'])}
                    {..._.pick(this, ['setActiveEntities', 'goToIssue', 'dismissIssue'])}
                  />
                )}
                <ActiveItemHudElement
                  {..._.pick(this.props, ['updateContainer', 'updateProduct', 'updateArchElement'])}
                  {..._.pick(this, ['handleActiveHudElementIteration', 'activeViewEntity', 'setActiveEntities', 'setActiveViewEntityId', 'setActiveScalingToolData', 'getIncludesActiveEntity', 'toggleInfoPopupShowing', 'handleDeleteEvent', 'handleZMovementEvent'])}
                  {...{viewMode, activeEntitiesData: this.state.activeEntities, activeViewEntityResourceKey, iterable: this.hudListArray.length > 1}}
                  parameterEditorIsShowing={this.state.parameterEditorIsShowing}
                />
              </>
            )}
          {this.state.showMaterialFactorsPopup && <MaterialFactorsPopup toggleMaterialFactorsPopup={this.toggleMaterialFactorsPopup} companyKey={project.companyKey} />}
          {this.state.showShareableLinkPopup && <ShareableLinkPopup toggleShareableLinkPopup={this.toggleShareableLinkPopup} project={project}/>}
          {(this.state.colorKeyPopupIsVisible && _.includes(['materialColors', 'materialHatches', 'unitType'], activeFillMode) && (_.includes(['front', 'both'], viewMode) && !(viewMode === 'both' && !bothIsShowingElevations))) && (
            <ColorKeyPopup onClose={() => this.setColorKeyPopupIsVisible({value: false})} {...{activeFillMode, activeDetailLevel, viewMode, room: this.room, visibleEntitiesDataByResourceKey, project, activeEntities: this.state.activeEntities}}/>
          )}
          {this.state.datumPopupShowing && _.includes(['both', 'top'], this.state.viewMode) && (
            <AddDatumPopup
              toggleDatumPopup={this.toggleDatumPopup}
              room={this.room}
              updateRoom={this.props.updateRoom}
            />
          )}
          {this.state.infoPopupShowing && (
            <ProductInfoHudElement
              onClose={this.toggleInfoPopupShowing}
              product={this.state.productInfoTarget}
              isEmployee={isEmployee}
              project={project}
              resourceKey={this.state.productInfoResourceKey}
            />
          )}
          {this.state.tolerancePopupShowing && (
            <ToleranceSelectPopup
              tolerance={this.state.tolerance}
              shouldHoldTo={this.state.shouldHoldTo}
              close={({tolerance, shouldHoldTo}) => this.handleDimensionToleranceChange({tolerance, shouldHoldTo})}
            />
          )}
          {this.state.copyDimEditsPopupShowing && (
            <CopyDimEditsPopup
              transferDimensionsDataBetweenLayers={this.transferDimensionsDataBetweenLayers}
              close={() => this.setState({copyDimEditsPopupShowing: false})}
              dimTransferFrom={this.state.dimTransferFrom}
              dimTransferTo={this.state.dimTransferTo}
            />
          )}
          {this.state.showNumericInput ? (
            <form onSubmit={this.handleNumericInputDataSubmit}>
              <NumberInput
                autoFocus
                continuouslyFocus
                ref={this.numericInputRef}
                placeholder="Start typing..."
                style={{
                  top: '95%',
                  right: K.paneWidth + K.spacing,
                  position: 'absolute',
                  zIndex: 1,
                  backgroundColor: 'rgb(245, 245, 245)',
                  color: 'rgb(0, 0, 0)',
                  width: '150px',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
                  overflow: 'overlay',
                }}
                value={this.numericInputValueRef.current}
                onChange={this.handleNumericInputChange}
              />
            </form>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect({
  mapState: state => {
    return {
      projects: state.resources.projects.byId,
      floors: state.resources.floors.byId,
      rooms: state.resources.rooms.byId,
      elevations: state.resources.elevations.byId,
      scopes: state.resources.scopes.byId,
      scopesByRoomId: _.get(state.resources.scopes, 'byFieldKeyIndex.roomId'),
      containerTypes: state.resources.containerTypes.byId,
      productTypes: state.resources.productTypes.byId,
      project: _.values(state.resources.projects.byId)[0],
      archetypes: state.resources.archetypes.byId,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['trackProjects', 'updateProject']),
    ..._.pick(resourceActions.floors, ['trackFloors']),
    ..._.pick(resourceActions.rooms, ['trackRooms', 'updateRoom', 'modifyRooms', 'destroyRooms', 'destroyRoom']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope', 'updateScopes', 'destroyScopes']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'updateWall', 'modifyWalls', 'destroyWalls']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainer', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.elevations, ['trackElevations', 'updateElevations', 'updateElevation', 'createElevations', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'updateArchElement', 'destroyArchElement', 'destroyArchElements', 'modifyArchElements']),
    ..._.pick(resourceActions.products, ['trackProducts', 'createProduct', 'createProducts', 'updateProduct', 'updateProducts', 'destroyProduct', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productTypes, ['trackProductTypes']),
    ..._.pick(resourceActions.models, ['trackModels']),
    ..._.pick(resourceActions.appliances, ['trackAppliances']),
    ..._.pick(resourceActions.pulls, ['trackPulls']),
    ..._.pick(resourceActions.materialClasses, ['trackMaterialClasses']),
    ..._.pick(resourceActions.materialTypes, ['trackMaterialTypes']),
    ..._.pick(resourceActions.materials, ['trackMaterials']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.pricingRules, ['trackPricingRules']),
    ..._.pick(resourceActions.productOptionTypes, ['trackProductOptionTypes']),
    ..._.pick(resourceActions.projectGraphics, ['createProjectGraphic', 'trackProjectGraphics', 'updateProjectGraphic', 'updateProjectGraphics', 'destroyProjectGraphic', 'destroyProjectGraphics', 'modifyProjectGraphics']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.archetypes, ['trackArchetypes', 'createArchetype', 'updateArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'updateVolume', 'updateVolumes', 'destroyVolume', 'destroyVolumes', 'modifyVolumes']),
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','trackParts', 'updateParts']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
  }
})(withUseParams(EditorPage));
