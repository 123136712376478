import _ from 'lodash';
import Product from 'project-helpers/product';
import K from 'k';

export default function getProductOptionIncompatibilityRules({allPotentialProductOptions, product, dependencies}) {
  var productOptionIncompatibilityRules = [
    {
      productOptionIds: [78],
      condition: ({product}) => (product.dimensions.depth < 18),
      message: 'Internal lighting incompatible with units less than 18" deep'
    },
    {
      productOptionIds: [128, 129, 130, 131],
      condition: ({product}) => (Product.getHasDoorStorage({product})),
      message: 'adjustable tray dividers incompatible with back of door storage'
    },
    {
      productOptionIds: [4, 14, 121, 122, 123, 124, 125, 126, 127],
      condition: ({product}) => {
        var invalidProductOptionIds = [36, 37, 128, 129, 130, 131]

        return _.some(invalidProductOptionIds, (productOptionId) => {
          return _.get(product, `customData.productOptions.${productOptionId}.enabled`) === 1;
        });
      },
      message: 'back of door storage incompatible with adjustable tray dividers'
    },
    {
      productOptionIds: [128, 129, 130, 131],
      condition: ({product}) => (product.dimensions.width > 32),
      message: 'adjustable tray dividers incompatible with products with width greater than 32"'
    },
    {
      productOptionIds: [128, 129],
      condition: ({product}) => (product.dimensions.width > 18),
      nonIssue: true
    },
    {
      productOptionIds: [130, 131],
      condition: ({product}) => (product.dimensions.width <= 18),
      nonIssue: true
    },
    {
      productOptionIds: [129, 131],
      condition: ({product}) => (!_.includes([24.625, 25.125], product.dimensions.depth)),
      message: 'product depth incompatible with short adjustable tray dividers'
    },
    {
      productOptionIds: [128, 130],
      condition: ({product}) => (!_.includes([18, 18.5, 24.625, 25.125], product.dimensions.depth)),
      message: 'product depth incompatible with tall adjustable tray dividers'
    },
    {
      productOptionIds: [36, 37, 128, 130, 129, 131],
      condition: ({product, productOption}) => {
        var productOptionIdsNotToFlagAsInvalid = _.includes([128, 130], productOption.id) ? [128, 130] : _.includes([129, 131], productOption.id) ? [129, 131] : [productOption.id];
        var invalidProductOptionIds = _.reject([36, 37, 128, 130, 129, 131], (id) => _.includes(productOptionIdsNotToFlagAsInvalid, id));

        return _.some(invalidProductOptionIds, (productOptionId) => {
          return _.get(product, `customData.productOptions.${productOptionId}.enabled`) === 1;
        });
      },
      message: 'cannot have both tall and short adjustable tray dividers'
    },
    {
      productOptionIds: [129, 131],
      condition: ({product}) => {
        var applicableProductIds = {
          baseUnit: [6, 11, 12, 13, 22, 24, 25],
          upperUnit: [
            121, 122, 123, 124, 125, 126, 1234, 1235, 1239, 1240, 1426, 1427, //depends on leaf door bay
            135, 136, 141, 145, 146, 394, 181, 1531, 1532
          ],
          pantryWithShelvesAboveDivider: [104, 105, 106, 107, 400, 401, 707, 708, 709, 710, 1034],
          pantryWithRolloutAboveDivider: [108, 109, 110, 111, 112, 113, 402, 403, 711, 712, 713, 714, 715, 716],
          topPantry: [127, 128],
          topPantryWithRollout: [129, 130, 131, 132, 133],
          incompatible: [
            1344, 1345, 1348, 1349, 1350, 1351,
            117, 118, 137, 138, 139, 140, 143, 144, 147,
            548, 549, 550, 551, 552, 553, 554, 555, 556, 557,
            1295, 1296, 1297, 1298, 1299, 1300, 1301, 1127, 1155, ...K.hb.ids.tallCustomApplianceFrameProductIds
          ]
        }

        var height = product.dimensions.height;

        if (product.productId === 1426) {
          var appliancesData = Product.getAppliancesDisplayData({product});

          height = product.dimensions.height - (30 + _.get(appliancesData, '[0].dimensions.height', 30));
        }

        return (
          (_.includes(applicableProductIds.baseUnit, product.productId) && height < 30) ||
          (_.includes(applicableProductIds.upperUnit, product.productId) && height < 29) ||
          (_.includes(applicableProductIds.pantryWithRolloutAboveDivider, product.productId) && height < 73) ||
          (_.includes(applicableProductIds.topPantryWithRollout, product.productId) && height < 42) ||
          (_.includes(applicableProductIds.incompatible, product.productId))
        )
      },
      message: 'product height incompatible with short adjustable tray dividers'
    },
    {
      productOptionIds: [128, 130],
      condition: ({product}) => {
        var applicableProductIds = {
          baseUnit: [6, 11, 12, 13, 22, 24, 25], //base unit
          upperUnit: [
            121, 122, 123, 124, 125, 126, 1234, 1235, 1239, 1240, 1426, 1427, //depends on leaf door bay
            135, 136, 141, 145, 146, 394, 181, 1531, 1532
          ], //wall unit
          pantryWithShelvesAboveDivider: [104, 105, 106, 107, 400, 401, 707, 708, 709, 710, 1034], //tall pantry no internal drawers
          pantryWithRolloutAboveDivider: [108, 109, 110, 111, 112, 113, 402, 403, 711, 712, 713, 714, 715, 716], //tall pantry with internal drawers
          topPantry: [127, 128], //top pantry
          topPantryWithRollout: [129, 130, 131, 132, 133], //top pantry with internal drawers
          incompatible: [
            1344, 1345, 1348, 1349, 1350, 1351,
            117, 118, 137, 138, 139, 140, 143, 144, 147,
            548, 549, 550, 551, 552, 553, 554, 555, 556, 557,
            1295, 1296, 1297, 1298, 1299, 1300, 1301, 1127, 1155, ...K.hb.ids.tallCustomApplianceFrameProductIds
          ]
        }

        var height = product.dimensions.height;

        if (product.productId === 1426) {
          var appliancesData = Product.getAppliancesDisplayData({product});

          height = product.dimensions.height - (30 + _.get(appliancesData, '[0].dimensions.height', 30));
        }

        return (
          (_.includes(applicableProductIds.upperUnit, product.productId) && height < 35) ||
          (_.includes(applicableProductIds.pantryWithShelvesAboveDivider, product.productId) && height < 65) ||
          (_.includes(applicableProductIds.pantryWithRolloutAboveDivider, product.productId) && height < 80) ||
          (_.includes(applicableProductIds.topPantry, product.productId) && height < 35) ||
          (_.includes(applicableProductIds.topPantryWithRollout, product.productId) && height < 48) ||
          (_.includes(applicableProductIds.incompatible, product.productId))
        )
      },
      message: 'product height incompatible with tall adjustable tray dividers'
    },
    {
      productOptionIds: [4, 14, 121, 122, 123, 124, 125, 126, 127],
      condition: ({product, productOption}) => {
        var invalidProductOptionIds = _.reject([4, 14, 121, 122, 123, 124, 125, 126, 127], (id) => id === productOption.id);

        return _.some(invalidProductOptionIds, (productOptionId) => {
          return _.get(product, `customData.productOptions.${productOptionId}.enabled`) === 1;
        });
      },
      message: 'cannot have multiple types of door storage'
    },
    //HINT kitchen width min is 19.75
    {
      productOptionIds: [4, 14, 123, 124, 125, 126, 127],
      condition: () => {
        var productType = Product.get('productType', {product});
        var isLeafDoorDouble = _.includes(_.split(productType.title, ' '), 'double');
        var width = isLeafDoorDouble ? product.dimensions.width / 2 : product.dimensions.width;

        return width < 19.75;
      },
      message: `back of door storage min carcass width 19.75"`
    },
    //HINT wardrobe width min is 15.75
    {
      productOptionIds: [121, 122],
      condition: () => {
        var productType = Product.get('productType', {product});
        var isLeafDoorDouble = _.includes(_.split(productType.title, ' '), 'double');
        var width = isLeafDoorDouble ? product.dimensions.width / 2 : product.dimensions.width;

        return width < 15.75;
      },
      message: `back of door storage min carcass width 15.75"`
    },
    {
      productOptionIds: [4, 14, 121, 122, 123, 124, 125, 126, 127],
      condition: () => {
        var productType = Product.get('productType', {product});
        //TODO utility split is not split vertically, LOOK OUT
        var isTallSplitLeafDoor = _.includes(_.split(productType.title, ' '), 'split');
        var height = isTallSplitLeafDoor ? product.dimensions.height - 30.25 : product.dimensions.height;

        return height < 36;
      },
      message: `back of door storage min door height is 36"`
    },
    {
      productOptionIds: [4, 14, 121, 122, 123, 124, 125, 126, 127],
      condition: () => {
        var frontPanelType = _.get(product, 'details.frontPanelType.id', 'flat');

        return frontPanelType !== 'flat'
      },
      message: `back of door storage incompatible with frame and panel`
    },
    {
      productOptionIds: [4, 14, 121, 122, 123, 124, 125, 126, 127],
      condition: () => {
        var primedForPaintMaterialIds = [72, 305, 409];

        return _.includes(primedForPaintMaterialIds, product.details.frontMaterial.id);
      },
      message: `back of door storage incompatible primed for paint materials`
    },
    {
      productOptionIds: [4, 14],
      condition: () => {
        return true;
      },
      message: `option discontinued in DE, deselect and enable the relevant more accurate door storage option.`
    },
    {
      productOptionIds: [36, 37],
      condition: () => {
        return true;
      },
      message: `option discontinued in DE, deselect and enable the relevant more accurate adjustable tray dividers option.`
    },
    {
      productOptionIds: [65],
      condition: () => (product.dimensions.depth <= 18),
      message: 'adjustable shelf with hangrod only available for products with a depth over 18".'
    },
    {
      productOptionIds: [65],
      condition: () => (Product.getHasInternalLighting({product})),
      message: 'adjustable shelf with hangrod incompatible with internal lighting.'
    },
    {
      productOptionIds: [13, 15, 17, 26, 27, 28, 29, 30, 74, 86, 104],
      condition: () => (true),
      message: 'discontinued product option'
    },
    {
      productOptionIds: [79],
      condition: () => (true),
      message: 'discontinued product option - use product \'Brass and Glass Leaf Door Double\' instead'
    }
  ]

  var incompatibleProductOptions = [];
  var currentlyCompatibleProductOptions = [];

  _.forEach(allPotentialProductOptions, productOption => {
    var filteredProductOptionIncompatibilityRules = _.filter(productOptionIncompatibilityRules, (data, key) => {
      var {productOptionIds = [], productIds = [], productCondition = () => {}} = data;

      if (_.includes(productOptionIds, productOption.id)) {
        return true
      }
    });

    _.forEach(filteredProductOptionIncompatibilityRules, (data, key) => {
      if (data.condition({product, productOption})) {
        incompatibleProductOptions.push({productOption, message: data.message, id: productOption.id});
      }
    });
  });

  currentlyCompatibleProductOptions = _.filter(allPotentialProductOptions, ({id}) => !_.includes(_.map(incompatibleProductOptions, 'id'), id));

  return {currentlyCompatibleProductOptions, incompatibleProductOptions};
};