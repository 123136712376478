import Product from 'project-helpers/product';

const getMinDepthInternalLightingIssues = ({products}) => {
  var issues = [];

  _.forEach(_.filter(products, product => Product.getHasInternalLighting({product})), product => {
    if (product.dimensions.depth < 18) {
      issues.push({
        id: `product-${product.id}-internal-lighting-min-depth`,
        message: `${product.productionId || Product.get('productType', {product}).title} - 18" minimum depth for internal lighting`,
        resourceKey: 'product',
        resourceId: product.id,
        type: 'product-option-compatibility',
        level: 1
      })
    }
  })

  return issues;
};

export default getMinDepthInternalLightingIssues;
