import React, {useState, useEffect} from 'react';
import lib from 'lib';
import _ from 'lodash';
import K from 'k';

import withUseParams from 'hooks/with-use-params';

import TextInput from '../../components/text-input';
import Dropdown from '../../components/dropdown';
import Popup from '../../components/popup';
import {AddButton} from '@henrybuilt/react-lib'

import arrowIconBlack from '../../assets/controls-hud-element/arrow-icon-black.png';

var ConfiguratorProjectsIndexPage = ({match}) => {
  var [searchInput, setSearchInput] = useState('');
  var [hoveredProjectId, setHoveredProjectId] = useState();
  var [activeProjectId, setActiveProjectId] = useState();
  var [projects, setProjects] = useState([]);
  var [clickableLinkInputIsVisible, setClickableLinkInputIsVisible] = useState(false);
  var [clickableLinkInputData, setClickableLinkInputData] = useState({});
  var [activeTextInputData, setActiveTextInputData] = useState({});

  useEffect(() => {
    if (window.location.hash) {
      global.apiToken = _.trimStart(window.location.hash, '#');
    }
  }, []);

  var getConfiguratorProjects = async () => {
    var {groupId} = match.params;

    var apiResponse = await lib.api.request({uri: 'de-project/get-configurator-projects', body: {groupId}});
    var projects = _.keyBy(_.get(apiResponse, 'data.projects', []), 'id');

    setProjects(projects);
  }

  var createConfiguratorProject = async ({projectKey}) => {
    var {groupId} = match.params;

    var lotNumber = await prompt('Please enter the lot number below');

    if (lotNumber) {
      var apiResponse = await lib.api.request({uri: 'de-project/configurator/create-project', body: {groupId: _.toNumber(groupId), lotNumber, projectKey}});

      setProjects({...projects, [apiResponse.data.id]: apiResponse.data})
    }
  }

  var updateConfiguratorProject = async ({updates, projectId}) => {
    var {groupId} = match.params;

    await lib.api.request({uri: 'de-project/configurator/update-project', body: {updates, projectId, groupId}});

    setProjects({...projects, [projectId]: {...projects[projectId], ...updates}});
  }

  useEffect(() => {
    setProjects(getConfiguratorProjects());
  }, []);

  var styles = {fontWeight: 400, fontFamily: 'Open Sans', fontSize: 12}
  var headerColumns = [
    {
      key: 'title', title: 'Unit',
      columnStyle: {minWidth: 176, width: 200, ...styles}
    },
    {
      key: 'clientName', title: 'Member',
      columnStyle: {minWidth: 200, ...styles}
    },
    {
      key: 'properties.model', title: 'Model',
      columnStyle: {minWidth: 150, ...styles},
      options: [
        {value: 'vaccaro', label: 'Vacarro'},
        {value: 'hamlin', label: 'Hamlin'},
        {value: 'avalon', label: 'Avalon'},
        // {value: 'avalonAlternate', label: 'Avalon - alternate'},
        {value: 'caraCara', label: 'Cara Cara'},
        // {value: 'caraCaraAlternate', label: 'Cara Cara - alternate'},
      ],
      defaultValue: ''
    },
    {
      key: 'properties.status', title: 'Status',
      columnStyle: {minWidth: 115, ...styles},
      options: [{value: 'depositDue', title: 'Deposit Due'}, {value: 'preProduction', title: 'Pre Production'}, {value: 'inProduction', title: 'In Production'}, {value: 'readyToShip', title: 'Ready To Ship'}, {value: 'delivered', title: 'Delivered'}],
      defaultValue: 'in-design'
    },
    {
      key: 'properties.owner', title: 'Owner',
      columnStyle: {minWidth: 200, ...styles}
    },
    {
      key: 'properties.paymentUrl', title: 'Payment',
      columnStyle: {minWidth: 90, ...styles}
    },
    {
      key: 'properties.drawingUrl', title: 'Drawing',
      columnStyle: {minWidth: 90, ...styles}
    },
    {
      key: 'pricingTool', title: 'HB Team',
      columnStyle: {minWidth: 90, ...styles}
    }
  ];

  var searchInputStyles = {width: '100%', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', borderRadius: 33, fontStyle: 'italic', fontSize: 12, height: K.spacing * 3, paddingLeft: K.spacing, letterSpacing: 0.1, alignSelf: 'center'};

  var handleSearchInput = (value) => {
    setSearchInput(value);

    if (value !== '') {
      var filteredProjects = _.filter(projects, ({title}) => {
        return _.every(_.lowerCase(value).split(' '), word => _.includes(_.lowerCase(title), word));
      })

      setProjects(filteredProjects);
    }
    else {
      setProjects(getConfiguratorProjects);
    }
  }

  var handleSetClickableLinkInputUrl = ({value}) => {
    var propertyKey = _.split(clickableLinkInputData.key, '.')[1];

    updateConfiguratorProject({updates: {properties: {...clickableLinkInputData.project.properties, [propertyKey]: value}}, projectId: clickableLinkInputData.project.id});
    setClickableLinkInputData({});
    setClickableLinkInputIsVisible(false);
  }

  var openProjectInConfigurator = async ({projectId}) => {
    var apiResponse = await lib.api.request({uri: 'de-project/generate-token', body: {projectId, access: ['configurator'], useActualErrorMessages: true}});

    window.open(apiResponse.data.shareableLink, '_blank');
  }

  var sortedProjects = _.reverse(_.sortBy(projects, 'id'));
  // set up in a way to use for other projects which may not have house model
  var archetypeOptions = [
    {value: 'vaccaro', label: 'Vacarro'},
    {value: 'hamlin', label: 'Hamlin'},
    {value: 'avalon', label: 'Avalon'},
    // {value: 'avalonAlternate', label: 'Avalon - alternate'},
    {value: 'caraCara', label: 'Cara Cara'},
    // {value: 'caraCaraAlternate', label: 'Cara Cara - alternate'},
  ];

  var archetypeOptionButtonStyles = ({index}) => {
    var styles = {
      width: 200,
      marginLeft: -160,
      ...(index === 0 ? {borderTopLeftRadius: 19, borderTopRightRadius: 0} : {})
    }

    return styles;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <div style={{minWidth: '80%', margin: 'auto', marginTop: 115}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <TextInput
            placeholder={'Search'}
            style={searchInputStyles}
            value={searchInput}
            onInput={(value) => handleSearchInput(value)}
          />
          <div style={{paddingLeft: K.spacing * 2}}>
            <AddButton
              onClick={(value) => createConfiguratorProject({projectKey: value})}
              options={_.map(archetypeOptions, ({value, label}) => {
                return {
                  value,
                  label: <div style={{}}>{label}</div>,
                  getButtonStyles: (index) => (archetypeOptionButtonStyles({index}))
                }
              })}
              style={{}}
              addButtonStyles={{width: 200}}
            />
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', paddingTop: K.spacing * 4}}>
          {_.map(headerColumns, ({key, title, columnStyle}) => (
            <div key={key} style={{...columnStyle, fontWeight: 600, textTransform: 'uppercase', alignText: 'left'}}>
              <div style={{padding: K.spacing}}>{title}</div>
            </div>
          ))}
        </div>
        <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
          {clickableLinkInputIsVisible && (
            <Popup onClose={() => setClickableLinkInputIsVisible(false)} style={{backgroundColor: 'white'}}>
              <div style={{paddingLeft: K.spacing, paddingBottom: K.spacing * 2, fontSize: 12, fontFamily: 'Open Sans'}}>{_.upperCase(_.split(clickableLinkInputData.key, '.')[1])}</div>
              <TextInput
                value={_.get(clickableLinkInputData.project, clickableLinkInputData.key)}
                onChange={({value}) => handleSetClickableLinkInputUrl({value})}
              />
            </Popup>
          )}
          {_.map(sortedProjects, project => (
            <div
              key={project.id}
              style={{
                display: 'flex', flexDirection: 'row', width: '100%', borderRadius: 33, backgroundColor: K.colors.active, alignItems: 'center', marginTop: K.spacing,
                ...(activeProjectId === project.id ? {backgroundColor: '#eee'} : hoveredProjectId === project.id ? {backgroundColor: '#eee'} : {color: 'rgba(0, 0, 0, 0.5)'}),
              }}
              onMouseEnter={() => setHoveredProjectId(project.id)}
              onMouseLeave={() => setHoveredProjectId(-1)}
            >
              {_.map(headerColumns, ({key, columnStyle}) => (
                <div key={`${project.id}-${key}`} style={{...columnStyle, fontWeight: 400, zIndex: 1}}>
                  <div
                    style={{
                      display: 'flex', flexDirection: 'row',
                      alignItems: 'center',
                      ...(key === 'title' ? {color: 'rgba(0, 0, 0, 0.8)'} : {color: 'rgba(0, 0, 0, 0.5)'}),
                    }}
                    data-conditional-opacity-parent={1}
                  >
                    {_.includes(['title', 'clientName'], key) && (
                       <div
                        onClick={() => {
                          if (key === 'title') openProjectInConfigurator({projectId: project.id});
                          else if (key === 'clientName') setActiveTextInputData({key, project})
                        }}
                       >
                        {activeTextInputData.key === key && activeTextInputData.project === project
                          ? <TextInput
                              value={project[key]}
                              onChange={({value}) => {
                                updateConfiguratorProject({updates: {[key]: value}, projectId: project.id});
                                setActiveTextInputData({});
                              }}
                              style={{background: 'transparent', ...styles, width: 180}}
                            />
                          : <div
                              style={{
                                ...columnStyle, textWrap: 'pretty',
                                paddingLeft: K.spacing,
                                marginTop: 2,
                                flexShrink: 1,
                                cursor: 'pointer', ...(_.isEmpty(project[key]) ? {height: 20} : {}),
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: K.spacing / 3, marginBottom: K.spacing / 3,
                                ...styles,
                              }}
                            >
                              {project[key]}
                              <div
                                data-conditional-opacity={1}
                                style={{position: 'relative', paddingLeft: K.spacing, flex: 1, alignSelf: 'center'}}
                              >
                                <img
                                  src={arrowIconBlack}
                                  style={{transform: 'rotate(-45deg)', height: 12, width: 12, marginTop: 2, alignSelf: 'center', cursor: 'pointer'}}
                                />
                              </div>
                            </div>
                        }
                       </div>

                    )}
                    {_.includes(['properties.status', 'properties.model'], key) && (
                      <Dropdown
                        options={_.find(headerColumns, {key}).options}
                        value={_.get(project, key) || _.find(headerColumns, {key}).defaultValue || ''}
                        selectedValue={_.find(_.find(headerColumns, {key}).options, {title: _.get(project, key)})}
                        onChange={(value) => updateConfiguratorProject({updates: {properties: {...project.properties, [_.split(key, '.')[1]]: value}}, projectId: project.id})}
                        style={{zIndex: 1, paddingLeft: K.spacing}}
                        contentContainerStyle={{zIndex: 10, opacity: 1, textTransform: 'uppercase', marginLeft: -5, fontSize: 12}}
                        showCaret={false}
                      />
                    )}
                    {_.includes(['properties.owner'], key) && (
                      <TextInput
                        value={_.get(project, key)}
                        noFill
                        onChange={({value}) => {
                          var propertyKey = _.split(key, '.')[1];

                          updateConfiguratorProject({updates: {properties: {...project.properties, [propertyKey]: value}}, projectId: project.id})
                        }}
                      />
                    )}
                    {_.includes(['properties.paymentUrl', 'properties.drawingUrl'], key) && (
                      <div style={{height: 20, width: 20, paddingLeft: 30}}>
                        <img
                          src={arrowIconBlack}
                          style={{transform: 'rotate(-45deg)', height: 20, width: 20, alignSelf: 'center', cursor: 'pointer', opacity: _.get(project, key) ? 1 : 0.3}}
                          onClick={() => {
                            _.get(project, key) ? window.open(_.get(project, key), '_blank') : null;
                          }}
                          onContextMenu={(event) => {
                            event.preventDefault();
                            setClickableLinkInputData({key, project});
                            setClickableLinkInputIsVisible(true);
                          }}
                        />
                      </div>
                    )}
                    {_.includes(['pricingTool'], key) && (
                      <div style={{height: 20, width: 20, paddingLeft: 30}}>
                        <img
                          src={arrowIconBlack}
                          style={{transform: 'rotate(-45deg)', height: 20, width: 20, alignSelf: 'center', cursor: 'pointer', opacity: _.get(project, key) ? 1 : 0.3}}
                          onClick={() => window.open(`https://apps.henrybuilt.com/pricing_tool/projects/${project.id}`, '_blank')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default withUseParams(ConfiguratorProjectsIndexPage);
