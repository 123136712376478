import _ from 'lodash';
import lib from 'lib';
import K from 'k';

import Container from 'project-helpers/container/index';
import Product from 'project-helpers/product';

var ScribesHelper = {
  //TODO
  // scribesTo({sourceContainer, container}) {
  //   return Container.verticallyOverlapsWith({sourceContainer, container}) && Container.distanceTo({sourceContainer, container}) <= K.scribeThreshold;
  // },

  getScribesData: ({container, elevation, resources}) => {
    if (!Container.getSupportsScribe({container})) return {};

    var scribeSideKeys = container.type === 'capPanel' ? ['top'] : ['left', 'right', 'top'];

    if (container.type === 'assembly') scribeSideKeys = ['left', 'right'];

    //HINT if all sides of scribe are turned off, no need to calculate
    if (_.every(scribeSideKeys, sideKey => {
      var isEnabled = _.get(container, `customData.disabledScribe.${sideKey}`, 0) === 0;

      return !isEnabled;
    })) {
      return {};
    }

    var showWallsAndArchElements = global.visibilityLayers.wallsAndArchElements;
    if (!resources) resources = Container.get(['computedWalls', 'dependencies', 'unmanagedProductInstances', 'companyKey', 'volumes', 'siblings', 'walls', 'room'], {container});

    var {computedWalls, dependencies, unmanagedProductInstances, volumes, companyKey, siblings, walls, room} = resources;
    var soffit = showWallsAndArchElements ? Container.getSoffit({container}) : undefined;
    var soffitVolume = Container.getSoffitVolume({container});
    var unfilteredAdjacentElementsData = Container.getUnfilteredAdjacentElementsData({container, siblings, volumes, walls});
    var computedWall = showWallsAndArchElements ? Container.getComputedWall({container, computedWalls}) : [];
    var volume = Container.getVolume({container, volumes});

    if (!showWallsAndArchElements) unfilteredAdjacentElementsData = _.mapValues(unfilteredAdjacentElementsData, adjacentElementsData => {
      return _.filter(adjacentElementsData, adjacentElement => adjacentElement.type !== 'wall');
    });

    var scribeMaterialId = _.get(container, 'details.scribeMaterial.id');
    var leftScribeMaterialId = _.get(container, 'details.leftScribeMaterial.id');
    var rightScribeMaterialId = _.get(container, 'details.rightScribeMaterial.id');
    var topScribeMaterialId = _.get(container, 'details.topScribeMaterial.id');

    var flattenedMaterials = _.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials);

    var materialsMap = {
      left: _.find(flattenedMaterials, {id: leftScribeMaterialId}),
      right: _.find(flattenedMaterials, {id: rightScribeMaterialId}),
      top: _.find(flattenedMaterials, {id: topScribeMaterialId}),
      default: _.find(flattenedMaterials, {id: scribeMaterialId})
    };

    var scribesData = lib.memo(Container.calcScribesData, {
      data: {
        elevationId: _.get(elevation, 'id'),
        containerResourceProps: container,
        wallsResourceProps: computedWall ? computedWall.walls : [],
        soffitDepth: soffitVolume ? soffitVolume.dimensions.depth : _.get(soffit, 'customData.dimensions.depth', 0),
        unfilteredAdjacentElementsData: _.mapValues(unfilteredAdjacentElementsData, adjacentElementsData => {
          return _.map(adjacentElementsData, data => {
            return {..._.omit(data, ['model', 'container', 'wall', 'volume']), resourceProps: data.model};
          });
        }),
        products: _.map(unmanagedProductInstances, p => _.pick(p, ['position', 'dimensions'])),
        companyKey: companyKey,
        materialsMap,
        showWallsAndArchElements,
        volume
      },
      refs: {
        scribeSideKeys,
        container,
        elevation,
        room,
        unfilteredAdjacentElementsData,
      }
    });

    return scribesData;
  },

  getScribeHeight({container, kickExtending}) {
    var kickHeight = Container.getKickHeight({container});
    var subcounterHeight = Container.getSubcounterHeight({container});

    if (!kickExtending) kickHeight = 0;

    return container.dimensions.height - (kickHeight + (subcounterHeight));
  },

  getAdjacentScribeHeight({container, room, siblings}) {
    var kickHeight = Container.getKickHeight({container});

    var countertopHeight = 0;

    if (Container.getSupportsCountertop({container})) {
      var countertops = Container.getCountertops({container, room, siblings});

      if (countertops.length > 0) {
        countertopHeight = _.max(_.map(countertops, countertop => countertop.dimensions.height));
      }
    }

    return countertopHeight + container.dimensions.height - kickHeight;
  },

  //scribe is material that connects a container to another container or wall to hide a gap/opening
  //this method computes top, left, and right scribe
  //left and right scribe behave one way, while top behaves another way
  //scribe types:
  //- recessed - container is running into perpendicular wall or inline container or ceiling
  //- corner scribe - container is in a condition where it needs a 90-degee angle scribe (i.e. open corner situation, where you really need two pieces of wood to hide the open corner)
  //- panel - container is running into the ceiling but its too far away for a horizontal strip of panelling, so it needs to be panels which are more like products with prices/unit #s
  //top scribes can be recessed or panel, while left/right can be recessed or corner
  //https://apps.henrybuilt.com/documentation/documents/256/base-corner-scribe
  //https://apps.henrybuilt.com/documentation/documents/257/scribes-and-kicks
  //https://apps.henrybuilt.com/documentation/documents/258/universal-corner-scribe
  //https://apps.henrybuilt.com/documentation/documents/259/upper-corner-scribe
  calcScribesData({data, refs}) {
    var scribesData = {};
    var {soffitDepth, containerResourceProps, companyKey, materialsMap, showWallsAndArchElements} = data;
    var {container, elevation, room, siblings, unfilteredAdjacentElementsData: rangedAdjacentElementsData, scribeSideKeys: sideKeys} = refs;

    if (Container.getSupportsScribe({container})) {
      var wrapSizes = Container.getWrapSizes({container});
      var wallprint = Container.getWallprint({container, elevation});
      var scribeArchPoints = showWallsAndArchElements ? Container.getArchPoints({container, elevation}) : [];

      //HINT in the condition where there is an end panel and no cap panel we need to calculate the top scribe first
      //when there isn't an end panel though, we need to calculate that side first so that we can offset the width of the top scribe
      if (_.includes(sideKeys, 'top') && !wrapSizes.top) {
        if (wrapSizes.left && wrapSizes.right) sideKeys = ['top', 'left', 'right'];
        else if (wrapSizes.left && !wrapSizes.right) sideKeys = ['right', 'top', 'left'];
        else if (wrapSizes.right && !wrapSizes.left) sideKeys = ['left', 'top', 'right'];
      }

      if (!scribeArchPoints || _.some(scribeArchPoints, point => !point)) scribeArchPoints = [];

      var containerPoints = {left: wallprint[1], right: wallprint[2]};
      var normalizedArchPoints = _.map(scribeArchPoints, point => ({x: point.x, y: -point.y}));

      //ceiling scribe gets masked to the ceiling, which is what some of these helps are for
      var updatePathPoints = ({operation, pathPoints}) => {
        var offset = operation === 'add' ? 0.001 : -0.001;

        pathPoints[0].y = pathPoints[0].y + offset;
        pathPoints[3].y = pathPoints[3].y + offset;
      };

      var pathsDataFor = ({mask = false, width, pathPoints}) => {
        if (mask && normalizedArchPoints.length > 0) {
          updatePathPoints({operation: 'subtract', pathPoints});

          var maskedPathPointPolygons = lib.math.polygon.overlappingPolygonFrom({
            p1: pathPoints, p2: normalizedArchPoints
          });

          updatePathPoints({operation: 'add', pathPoints});

          if (maskedPathPointPolygons.length > 0) {
            pathPoints = maskedPathPointPolygons[0];
          }
          else {
            pathPoints = _.map(pathPoints, point => ({x: point.x, y: _.min([point.y, _.max(_.map(normalizedArchPoints, 'y'))])}));
          }
        }

        var offset = lib.object.min(..._.filter(pathPoints, point => !point.filterFromOffset));

        pathPoints = _.map(pathPoints, point => {
          point = lib.object.difference(point, offset);

          point.y *= -1;

          return point;
        });

        return {points: pathPoints, width};
      };

      _.forEach(sideKeys, sideKey => {
        var isEnabled = _.get(containerResourceProps, `customData.disabledScribe.${sideKey}`, 0) === 0; //Users can override autogenerated scribe by turning it off
        //var isWrapped = _.get(containerResourceProps.customData, `wrap.${sideKey}`, 0);

        if (isEnabled) {
          var data = {sideKey, pathsData: [], productInstancesData: []};
          var customScribeType = _.get(container, `customData.scribeType.${sideKey}`, 'automatic');
          var customScribeWidth = customScribeType !== 'automatic' ? _.get(container, `customData.scribeWidth.${sideKey}`, 0) : 0;

          if (sideKey === 'top') {
            if (Container.getSupportsTopScribe({container})) {
              //Gather up ceiling points which we'll be masking to
              var ceilingPoints = _.filter(scribeArchPoints, point => -point.y > 50);
              var maskedCeilingPoints = [];

              //using try catch because overlappingPolygonFrom throws errors when given non-polygon shapes
              try {
                var maskedPolygon = lib.math.polygon.overlappingPolygonFrom({
                  p1: _.map(wallprint, point => ({x: point.x, y: point.y === _.min(_.map(wallprint, 'y')) ? point.y : 1000000})),
                  p2: normalizedArchPoints
                });

                var maskedCeilingPoints = _.map(_.filter(_.get(maskedPolygon, '[0]'), point => point.y >= _.max(_.map(wallprint, 'y'))), point => ({x: point.x, y: -point.y}));
              }
              catch (e) {}

              var relevantCeilingPoints = maskedCeilingPoints.length ? maskedCeilingPoints : ceilingPoints;
              var allCeilingDistances = _.map(relevantCeilingPoints, point => -point.y - containerPoints.left.y);

              var ceilingDistances = {highest: _.max(allCeilingDistances), lowest: _.min(allCeilingDistances)};

              data.distance = ceilingDistances.highest + 1;
              data.soffitDepth = soffitDepth;

              //if it's within a valid range, determine type based on distance
              if (ceilingDistances.highest >= 0.25 && ceilingDistances.highest <= 94) {
                data.type = ceilingDistances.highest <= 2 ? 'recessed' : 'panel';
              }

              if (customScribeWidth) {
                data.type = _.get(container, `customData.scribeType.${sideKey}`, 'automatic');
                data.distance = _.get(container, `customData.scribeWidth.${sideKey}`, 0) + 1;
              }

              if (data.type) {
                if (customScribeType !== 'automatic') {
                  data.type = customScribeType;
                }

                //need to consider side scribes when showing top scribe because it affects their starting x position (goes on top of side scribes rather than in between them)
                var sideScribeDistances = lib.object.fromKeys(['left', 'right'], sideKey => {
                  var scribeEnabled = _.get(containerResourceProps, `customData.disabledScribe.${sideKey}`, 0) === 0;

                  if (!scribeEnabled) {
                    return 0;
                  }
                  else {
                    var scribeDistance = _.get(scribesData, `${sideKey}.distance`, 0);

                    if (_.get(container, `customData.scribeType.${sideKey}`, 'automatic') !== 'automatic') scribeDistance = _.get(container, `customData.scribeWidth.${sideKey}`, 0);

                    if (_.get(scribesData, `${sideKey}.model.type`) === 'base') scribeDistance = 0;

                    if (!wrapSizes.top && wrapSizes[sideKey]) scribeDistance = 0;

                    return scribeDistance;
                  }
                });

                var containerWidth = container.dimensions.width - ((wrapSizes.top) ? 0 : wrapSizes.left) - ((wrapSizes.top) ? 0 : wrapSizes.right);
                var netCeilingScribeWidth = containerWidth + _.sum(_.values(sideScribeDistances));
                var maxPanelWidth = data.type === 'panel' ? 47 : 95;

                let panelWidths = lib.number.evenlyDistributedDistancesFor({
                  distance: netCeilingScribeWidth, maxDistance: maxPanelWidth, precision: 1 / 16
                });

                if (_.get(container, `customData.customScribeBreaks.${sideKey}`) && _.get(container, `customData.customScribeBreaks.${sideKey}`) !== '0') {
                  var customPanelWidths = _.map(_.split(_.get(container, `customData.customScribeBreaks.${sideKey}`), ','), breakpoint => _.toNumber(breakpoint));

                  if (_.sum(customPanelWidths) <= netCeilingScribeWidth) panelWidths = customPanelWidths;
                }

                data.xOrigin = -sideScribeDistances.left;

                var x = data.xOrigin;

                var combinedPath = [];

                _.forEach(panelWidths, (panelWidth) => {
                  var y1 = containerPoints.left.y, y2 = y1 + (customScribeWidth ? customScribeWidth : 10000);
                  var x1 = _.max([containerPoints.left.x, 0]) + x, x2 = x1 + panelWidth;

                  var pathPoints = [{x: x1, y: y1}, {x: x1, y: y2}, {x: x2, y: y2}, {x: x2, y: y1}];

                  x += panelWidth;

                  var productId = data.type === 'corner'
                    ? K[companyKey].ids.scribe.productIdTypeMap['universal']
                    : data.type === 'recessed'
                      ? K[companyKey].ids.scribe.productIdTypeMap['recessed']
                      : K[companyKey].ids.scribe.productIdTypeMap['panel'];

                  data.productInstancesData.push({
                    productId,
                    dimensions: {height: customScribeWidth ? data.distance : (data.distance - 1), width: panelWidth, depth: 0.75},
                    type: data.type,
                    sideKey
                  });

                  if (combinedPath.length === 0) {
                    combinedPath = pathPoints;
                  }
                  else {
                    combinedPath = lib.math.polygon.union({
                      p1: combinedPath,
                      p2: pathPoints
                    });
                  }

                  data.pathsData.push(pathsDataFor({mask: true, width: panelWidth, pathPoints}));
                });

                if (combinedPath.length > 0) {
                  data.pathData = pathsDataFor({mask: true, width: _.max(_.map(combinedPath, 'x')) - _.min(_.map(combinedPath, 'x')), pathPoints: combinedPath})
                }
              }
            }
          }
          else {
            var rangeKey = sideKey === 'right' ? 'to' : 'from';

            //determine valid candidates to scribe to
            var adjacentElementsData = lib.waterfall(rangedAdjacentElementsData[rangeKey], [
              [_.sortBy, 'distance'],
              [_.filter, ({type, model, ...data}) => {
                var isWall = type === 'wall';
                var isVolume = type === 'volume';
                var isInvalidContainerType = _.includes(['countertop', 'capPanel', 'backsplash'], model.type);
                var isCornerCondition = data.orientation === 'corner';

                //HINT this is to capture conditions where a tall unit has a base container that is slightly closer than a wall
                //and we want to scribe to the wall above the base container
                var isTallerAndShouldScribeToWall = false;

                //HINT this is to capture conditions where tall containers meet a closer wall/corner counter container,
                //but there is also a base container that is slightly farther away that should be scribed to
                var isTallerAndShouldScribeToBase = false;

                if (type === 'container') {
                  isTallerAndShouldScribeToWall = (data.condition === 'childPocket' || data.condition === 'open') && container.dimensions.height > model.dimensions.height;
                  if (customScribeWidth && customScribeWidth > data.distance) isTallerAndShouldScribeToWall = container.dimensions.height > model.dimensions.height;
                  var noWallToScribeTo = !_.some(rangedAdjacentElementsData[rangeKey], {type: 'wall'});
                  //HINT only scribe to wall if its closer than back of container
                  var wallDistance = _.get(_.find(rangedAdjacentElementsData[rangeKey], {type: 'wall'}), 'distance');
                  var wallTooFarAway = (wallDistance - data.distance) >= model.dimensions.depth;

                  if (customScribeWidth && wallTooFarAway) {
                    wallTooFarAway = customScribeWidth <= data.distance;
                  }

                  //HINT can only scribe to wall if there is a wall
                  if (isTallerAndShouldScribeToWall && (noWallToScribeTo || wallTooFarAway)) {
                    isTallerAndShouldScribeToWall = false;
                  }

                  var hasNearbyBaseContainer = _.find(_.sortBy(rangedAdjacentElementsData[rangeKey], 'distance'), adjacentElement => {
                    return adjacentElement.type === 'container' && adjacentElement.model.type === 'base' && adjacentElement.distance <= 6;
                  });

                  isTallerAndShouldScribeToBase = container.type === 'tall' && _.includes(['cornerCounterTransition', 'wall'], model.type) && hasNearbyBaseContainer;
                }

                var isInline = container.type !== 'endPanel' && data.orientation === 'inline' && !isTallerAndShouldScribeToBase && _.some(rangedAdjacentElementsData[rangeKey], {orientation: 'corner'});

                return !isTallerAndShouldScribeToBase && !isTallerAndShouldScribeToWall && !isInvalidContainerType && (isInline || isWall || isVolume || data.condition === 'parentPocket' || (Container.getSupportsScribe({container: model}) && isCornerCondition));
              }]
            ]);

            var adjacentElement = adjacentElementsData[0];

            if (adjacentElement || customScribeWidth) {
              if (customScribeWidth && !adjacentElement) adjacentElement = {};
              var containers = [adjacentElement.model, container];
              var products = Container.get('unmanagedProductInstances', {container});

              //condition & distance
              if (adjacentElement.type === 'container') {
                if (adjacentElement.orientation === 'corner') {
                  data.condition = adjacentElement.condition;
                }
              }
              else {
                data.condition = 'wall';
              }

              data.distance = adjacentElement.distance;

              var isTallerScribingToBaseCorner = container.type === 'tall' && adjacentElement.type === 'container' && adjacentElement.model.type === 'base' && adjacentElement.condition !== 'inline' && adjacentElement.distance <= 6;
              var kickExtending = (adjacentElement.type === 'container' && Container.getHasKick({container: adjacentElement.model})) || !wrapSizes[sideKey];

              if (!_.includes(['inline', 'childPocket'], data.condition) || customScribeWidth/* && !(data.condition === 'wall' && data.distance > 6)*/) {
                if (data.condition === 'open' && sideKey === 'left') {
                  data.distance += K.scribeThickness;
                }

                //length
                if (adjacentElement.type === 'container') {
                  var lengthContainer = _.minBy(containers, c1 => Container.getScribeHeight({container: c1, kickExtending}));
                  var isLengthContainer = lengthContainer.id === container.id || lengthContainer.dimensions.height === container.dimensions.height;

                  //HINT sensing situations where there are multiple containers on top of eachother, so scribe should continue
                  if (_.filter(adjacentElementsData, a1 => a1.distance === data.distance).length > 1) {
                    isLengthContainer = true;
                  }

                  data.length = isLengthContainer ? Container.getScribeHeight({container, kickExtending}) : Container.getAdjacentScribeHeight({container: lengthContainer, room, siblings});
                }
                else {
                  data.length = Container.getScribeHeight({container, sideKey, wrapSizes, kickExtending});
                }

                var lengthOffset = (!isTallerScribingToBaseCorner && !wrapSizes[sideKey]) ? (wrapSizes.top + wrapSizes.bottom) : 0;

                if (wrapSizes[sideKey] && !wrapSizes.top && !isTallerScribingToBaseCorner && _.get(scribesData, `top.distance`, 0)) lengthOffset -= (_.get(scribesData, `top.distance`, 0) - 1);

                data.length = K.round(data.length - lengthOffset);

                //type
                if (data.distance >= 0.125) {
                  if (customScribeType && customScribeType !== 'automatic') {
                    data.type = customScribeType;
                  }
                  else if (adjacentElement.type === 'container') {
                    data.type = data.distance > 6 ? 'panel' : 'corner';
                  }
                  else {
                    data.type = data.distance < 1.25 ? 'recessed' : 'corner';

                    if (products.length > 0) {
                      //paneled appliances (i.e. fridge) always get corner scribe
                      var hasPaneledApplianceAtEndOfContainer = _.some(products, product => {
                        var productX = product.position.x + (rangeKey === 'from' ? 0 : product.dimensions.width);
                        var containerX = rangeKey === 'from' ? 0 : Container.getInnerWidth({container});

                        // var applianceInstancesData = Product.getApplianceInstancesData({product});

                        // var appliances = _.flatMap(applianceInstancesData, 'filteredCompatibleAppliances');

                        var productIsPaneledAppliance = _.includes(['refrigerator', 'dishwasher'], Product.getApplianceType({product}));
                        // var productIsPaneledAppliance = _.some(product.appliancesData, applianceData => {
                        //   if (!_.isEmpty(applianceData)) {
                        //     var appliance = _.find(appliances, {id: applianceData.id});

                        //     return appliance && _.includes(['refrigerator', 'dishwasher'], appliance.type);
                        //   }
                        // });

                        return productIsPaneledAppliance && productX === containerX;
                      });

                      if (hasPaneledApplianceAtEndOfContainer) {
                        data.type = 'corner';
                      }
                    }
                  }
                }

                if (customScribeWidth) {
                  data.type = customScribeType;
                  data.distance = customScribeWidth;
                }

                //inset
                data.inset = data.type === 'recessed' ? 7 / 8 : 0;

                data.lengths = [data.length];

                var maxPanelHeight = 95;

                if (companyKey === 'hb') {
                  var material = materialsMap[sideKey] || materialsMap.default;
                  var isVeneer = _.includes(K[companyKey].materialIds.endPanelVeneer, _.get(material, 'id')) || (_.includes([1, 22], _.get(material, 'materialTypeId')));

                  if (isVeneer) {
                    maxPanelHeight = 119;
                  }
                }

                if (data.length > maxPanelHeight || _.get(container, `customData.customScribeBreaks.${sideKey}`)) {
                  var allBottomProducts = _.filter(Container.get('unmanagedProductInstances', {container}), product => product.position.y === 0);
                  var bottomLeftSideProduct = _.minBy(allBottomProducts, product => Product.getXRange({product}).from);
                  var bottomRightSideProduct = _.maxBy(allBottomProducts, product => Product.getXRange({product}).to);
                  var bottomSideProduct = sideKey === 'left' ? bottomLeftSideProduct : bottomRightSideProduct;
                  var breakingHeight = data.length / 2;

                  if (bottomSideProduct) {
                    breakingHeight = bottomSideProduct.dimensions.height;
                  }

                  if (_.get(container, `customData.customScribeBreaks.${sideKey}`)) {
                    breakingHeight = _.toNumber(_.get(container, `customData.customScribeBreaks.${sideKey}`));
                  }

                  data.lengths = [breakingHeight, data.length - breakingHeight];
                }

                //scribe product instance
                data.productId = K[companyKey].ids.scribe.defaultProductId;

                if (_.some(containers, c1 => _.includes(['base', 'baseWithChase'], _.get(c1, 'type')))) data.productId = K[companyKey].ids.scribe.productIdTypeMap['base'];
                if (_.some(containers, c1 => _.includes(['floatingBase', 'vanity'], _.get(c1, 'type')))) data.productId = K[companyKey].ids.scribe.productIdTypeMap['floatingBase'];
                if (_.some(containers, {type: 'wall'})) data.productId = K[companyKey].ids.scribe.productIdTypeMap['wall'];

                if (data.type === 'recessed') data.productId = K[companyKey].ids.scribe.productIdTypeMap['recessed'];
                else if (data.type === 'panel') data.productId = K[companyKey].ids.scribe.productIdTypeMap['panel'];

                data.productInstancesData.push({
                  productId: data.productId,
                  dimensions: {height: data.length, width: data.distance, depth: 3.4},
                  type: data.type,
                  lengths: data.lengths,
                  sideKey
                });

                //path
                var kickHeight = kickExtending ? Container.getKickHeight({container}) : 0;
                var x1 = containerPoints[sideKey].x + (sideKey === 'left' ? -data.distance : 0), x2 = x1 + data.distance;
                var y1 = container.position.y + kickHeight, y2 = y1 + data.length; // + (scribesData.top && data.condition !== 'pocketAdjacent' ? 10000 : 0); // TODO

                var adjacentCountertopHeight = 0;
                var adjacentSubcounterHeight = isTallerScribingToBaseCorner ? Container.getSubcounterHeight({container: adjacentElement.model}) : 0;

                if (isTallerScribingToBaseCorner && Container.getSupportsCountertop({container: adjacentElement.model})) {
                  var countertops = Container.getCountertops({container: adjacentElement.model, room, siblings});

                  if (countertops.length > 0) {
                    adjacentCountertopHeight = _.max(_.map(countertops, countertop => countertop.dimensions.height));
                  }
                }

                var pathPoints = [
                  {x: x1, y: y1},
                  ...(isTallerScribingToBaseCorner && sideKey === 'left' ? [
                    {x: x1, y: y2 - adjacentCountertopHeight - adjacentSubcounterHeight},
                    {x: x1 - adjacentSubcounterHeight, y: y2 - adjacentCountertopHeight - adjacentSubcounterHeight, filterFromOffset: true},
                    {x: x1 - adjacentSubcounterHeight, y: y2 - adjacentCountertopHeight, filterFromOffset: true},
                    {x: x1, y: y2 - adjacentCountertopHeight},
                  ] : []),
                  {x: x1, y: y2},
                  {x: x2, y: y2},
                  ...(isTallerScribingToBaseCorner && sideKey === 'right' ? [
                    {x: x2, y: y2 - adjacentCountertopHeight},
                    {x: x2 + adjacentSubcounterHeight, y: y2 - adjacentCountertopHeight},
                    {x: x2 + adjacentSubcounterHeight, y: y2 - adjacentCountertopHeight - adjacentSubcounterHeight},
                    {x: x2, y: y2 - adjacentCountertopHeight - adjacentSubcounterHeight},
                  ] : []),
                  {x: x2, y: y1}
                ];

                data.pathsData.push(pathsDataFor({
                  mask: false, //scribesData.top !== undefined && data.distance >= 0.25,
                  width: data.distance, pathPoints
                }));

                data = {
                  ...data,
                  isTallerScribingToBaseCorner,
                  adjacentSubcounterHeight: adjacentSubcounterHeight,
                  adjacentSubcounterY: -(y2 - adjacentCountertopHeight - adjacentSubcounterHeight),
                  adjacentType: adjacentElement.type,
                  //HINT hack to help with scripts
                  model: {
                    ...adjacentElement.model,
                    kickExtending,
                    hasKick: adjacentElement.type === 'container' ? Container.getHasKick({container: adjacentElement.model}) : false,
                    hasSubcounter: adjacentElement.type === 'container' ? Container.getHasSubcounter({container: adjacentElement.model}) : false,
                  }
                };
              }
            }
          }

          if (data.type) {
            scribesData[sideKey] = data;
          }
        }
      });
    }

    return scribesData;
  }
};

export default ScribesHelper;
