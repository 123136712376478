import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import SnapDataHelper from '../snap-data-helper';

const getCooktopIssues = ({
  project,
  products
}) => {
  var issues = [];
  // var solidSurfaceAcrylicMaterialIds = [79, 80, 81, 82, 83, 242, 243, 244, 260, 323];
  // var wolfApplianceIds = [
  //   163, 164, 193, 194, 195, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 249, 280, 347, 366, 367, 395, 409, 423, 428, 463, 464, 465, 481, 489, 516, 517, 518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532, 533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552, 553, 601
  // ];

  var appliances = [];

  if (products.length > 0) {
    appliances = Product.get('appliances', {product: products[0]});
  }

  var solidSurfaceAcrylicCountertopProductIds = [1017, 360, 802];

  _.forEach(products, product => {
    if (Product.getIsCooktop({product})) {
      var isWolfCooktop = false;
      var applianceInstancesData = Product.getApplianceInstancesData({product});
      const customData = _.get(product, 'customData', {});
      const productData = Product.getProductData({product});
      var applianceFit = Product.getApplianceFit({product});

      _.forEach(product.appliancesData, (applianceData, index) => {
        var isSTDoubleAppliance = product.productId === 1132;
        //HINT handle swap product bug where appliance data persisted on product even though the product doesn't have an appliance
        var isValidAppliance = (Product.getIsAppliance({product}) || Product.getHasSink({product})) && applianceInstancesData[index];

        if (isValidAppliance && product.productId !== 1529 && !(product.productId === 1586 && index + 1 > _.get(product, 'customData.applianceQuantity', 1)) && !(isSTDoubleAppliance && index !== 0)) {
          let appliance = (applianceData.isCustomAppliance && !customData.isPreCustomApplianceUpdate) ? undefined : _.cloneDeep(_.find(appliances, {id: applianceData.id}));

          var applianceType = Product.getApplianceType({product, index, showDisplayedApplianceType: true});

          if (_.includes(_.toLower(applianceType), 'cooktop')) {
            var isFittedNonCustomAppliance = !customData.isPreCustomApplianceUpdate && (applianceFit === 'fitted' && !applianceData.isCustomAppliance);

            if (!isFittedNonCustomAppliance && (applianceData.customModelNumber || applianceData.modelNumber || applianceData.customVendor)) {
              appliance = {vendor: applianceData.customVendor || applianceData.vendor, modelNumber: applianceData.customModelNumber || applianceData.modelNumber};
            }

            if (appliance && _.toLower(appliance.vendor) === 'wolf') {
              isWolfCooktop = true;
            }
          }
        }
      });

      if (isWolfCooktop) {
        var countertops = Product.getCountertopContainers({product});

        var invalidCountertop = _.find(countertops, countertop => {
          //HINT: product id 802 can be paperstone instead of solid surface
          var isPaperstone = (
            _.get(countertop, 'customData.productId') === 802
            && _.get(countertop, 'details.countertopMaterial.id') === 245
          );

          return _.includes(solidSurfaceAcrylicCountertopProductIds, _.get(countertop, 'customData.productId')) && !isPaperstone;
        });

        if (invalidCountertop) { 
          issues.push({
            id: `product-${product.id}-container-${invalidCountertop.id}-wolf-cooktop-countertop-surface-material-incompatibility`,
            message: `${product.productionId || Product.get('productType', {product}).title} - Wolf cooktops incompatible with solid surface acrylic countertops`,
            resourceKey: 'product',
            resourceId: product.id,
            type: 'general-issues',
            level: 1
          });
          issues.push({
            id: `container-${invalidCountertop.id}-product-${product.id}-wolf-cooktop-countertop-surface-material-incompatibility`,
            message: `Countertop - Wolf cooktops incompatible with solid surface acrylic countertops`,
            resourceKey: 'container',
            resourceId: invalidCountertop.id,
            type: 'general-issues',
            level: 1
          });
        }
      }
    }
  });

  return issues;
};

export default getCooktopIssues;
