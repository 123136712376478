import React, { useContext } from 'react';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Room from 'project-helpers/room';
import Project from 'project-helpers/project';
import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import Elevation from 'project-helpers/elevation';
import CanvasContainer from './canvas-container';
import CanvasArchElement from './canvas-arch-element';
import CanvasDatum from './canvas-datum';
import CanvasDimensionLine from 'canvas/dimensions/canvas-dimension-line';
import CanvasProjectGraphic from '../project-components/canvas-project-graphic';
import CanvasVolume from './canvas-volume';
import CanvasScope from 'project-components/canvas-scope';
import getRoomDimensionSets from 'dimensions/room/getRoomDimensionSets';

import { withErrorBoundary } from 'react-error-boundary';

import { EditableCanvasPolyline, EditableCanvasLine, CanvasDataContext, CanvasSelectionContext, CanvasElevationIndicator, CanvasLine, CanvasErrorFallback } from 'canvas';
import { connect } from 'redux/index.js';
import { resourceActions, issuesDataActions } from 'redux/index.js';
import ArchElement from 'project-helpers/arch-element';
import ProjectDataContext from 'contexts/project-data-context';

class CanvasRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.shouldUpdateDimensionSets = props.visibilityLayers.dimensions && !props.renderForContextCanvas;
  }

  shouldComponentUpdate(nextProps, nextState) {
    var {renderForContextCanvas, room, canvasData, projectData, renderForDrawings} = this.props;

    var selectedEntitiesChanged = nextProps.selectionData?.activeEntities && this.props.selectionData?.activeEntities && (_.size(nextProps.selectionData.activeEntities) !== _.size(this.props.selectionData.activeEntities) || this.difference(nextProps.selectionData.activeEntities, this.props.selectionData.activeEntities).length > 0);

    this.shouldUpdateDimensionSets = (projectData && !selectedEntitiesChanged && !renderForContextCanvas && !this.state.preventDimsCalculation && nextProps.visibilityLayers?.dimensions) && (!global.dimensionsByEntityType.room[room.id] || canvasData.scale === nextProps.canvasData?.scale || renderForDrawings);

    if (nextState.isTransforming !== this.state.isTransforming) return true;

    if (!this.props.room || !this.props.room.id) return true;

    if (this.props.renderForDrawings || nextProps.renderForDrawings) return true;

    var preventUpdate = false;

    const hasRoomChanged = this.difference(nextProps.room, this.props.room).length > 0;
    var haveDimensionsUpdated = nextProps.projectData.activeDimensionsLayer !== this.props.projectData.activeDimensionsLayer || _.some(_.get(nextProps.projectData, `dimensionsData[${this.props.projectData.activeDimensionsLayer}]`), (dimensionData, dimensionDataKey) => {
      var currentDimensionData = this.props.projectData.dimensionsData[this.props.projectData.activeDimensionsLayer][dimensionDataKey];
      var shouldCauseRerender = false;

      if (dimensionDataKey === 'additiveDimensionsEditing') {
        shouldCauseRerender = currentDimensionData !== dimensionData;
      }
      else {
        var dataDifferent = false;

        if (_.isArray(dimensionData) || _.isArray(currentDimensionData)) {
          dataDifferent = _.flatMap(dimensionData, (data, index) => this.difference(data, _.get(currentDimensionData, `[${index}]`))).length > 0;
        }
        else {
          dataDifferent = this.difference(dimensionData, currentDimensionData).length > 0;
        }

        shouldCauseRerender = _.size(dimensionData) !== _.size(currentDimensionData) || dataDifferent;
      }

      return shouldCauseRerender;
    });

    if (hasRoomChanged || haveDimensionsUpdated || this.props.room.eventType === 'transform') return true;

    var hasReduxStateUpdated = false;
    var manuallyHandledPropKeys = ['room', 'volumes', 'containers', 'archElements', 'sortedElevations', 'projectGraphics', 'scopes', 'products'];
    var manuallyHandledObjectPropKeys = ['volumes', 'containers', 'archElements', 'sortedElevations', 'projectGraphics', 'scopes', 'products'];

    _.forEach(manuallyHandledObjectPropKeys, key => {
      if (!hasReduxStateUpdated) {
        var sizeDifferent = _.size(nextProps[key]) !== _.size(this.props[key]);
        var resourcesDifferent = _.flatMap(nextProps[key], (resource, index) => this.difference(resource, _.get(this.props, `${key}[${index}]`))).length > 0;

        if (sizeDifferent || resourcesDifferent) {
          hasReduxStateUpdated = true;
        }
      }
    });

    const haveNonReduxPropsUpdated = !_.isEqual(_.omit(nextProps, manuallyHandledPropKeys), _.omit(this.props, manuallyHandledPropKeys));

    return (haveNonReduxPropsUpdated || hasReduxStateUpdated) && !preventUpdate;
  }

  difference = (obj1, obj2) => {
    if (!obj2) return [undefined];

    const diffProperties = _.reduce(obj1, (result, value, key) => {
      return _.isEqual(value, obj2[key]) ?
        result : result.concat(key);
    }, []);

    return diffProperties || [];
  };

  handleChange = async ({hitApi = true, props}) => {//eslint-ignore-line
    const {cachedRoom} = this.state;
    this.setState({preventDimsCalculation: true});

    if (!hitApi && !this.state.isTransforming) {
      this.setState({cachedRoom: this.props.room, isTransforming: true});
    }
    else if (hitApi) {
      this.props.projectData.pushToUndoQueue({type: 'room', eventKey: 'transformEnd', instance: cachedRoom || this.props.room});
      this.setState({cachedRoom: undefined, isTransforming: false});

      let room = {...this.props.room, plan: {...this.props.room.plan, ...props}};

      Room.updateManagedWalls({room, reduxActions: this.props});
    }

    this.props.updateRoom({id: this.props.room.id, hitApi: false, props: {plan: {...this.props.room.plan, ...props}}});

    this.setState({preventDimsCalculation: false});
    if (hitApi) {
      await lib.api.update('room', {where: {id: this.props.room.id}, props: {plan: {...this.props.room.plan, ...props}}});

      setTimeout(() => {
        Project.autogenerateElevations({project: Room.get('project', {room: this.props.room}), reduxActions: this.props});
      });
    }
  };

  handlePointsChange = (points, hitApi = true) => {//eslint-ignore-line
    this.handleChange({props: {points: _.map(points, point => _.mapValues(point, (value, key) => {
      return _.includes(['x', 'y'], key) ? (this.props.projectData.infinitePrecision ? value : lib.round(value, {toNearest: K.minPrecision})) : value}))}, hitApi});
  };

  handlePositionChange = ({position}) => {//eslint-ignore-error
    this.handleChange({props: {position}});
  };

  handleClosedChange = (closed) => {//eslint-ignore-error
    this.handleChange({props: {closed}});
  };

  handleAddPoint = ({points}) => {//eslint-ignore-error
    this.handleChange({props: {points}});
  };

  handleOpen = () => {//eslint-ignore-error
    const points = _.cloneDeep(this.props.room.plan.points);

    points.pop();

    this.handleChange({props: {points, closed: false}});
  };

  handleClose = ({position, points}) => {//eslint-ignore-error
    //TODO normalize room outline when drawn
    //move min absolute value point to 0,0
    //by subtracting that amnt from all points and setting position to that point
    //subtract that value from all container, volume, project graphic, scope, elevation, datum, and custom dimension positions

    var minVertex = {x: _.minBy(points, 'x').x, y: _.minBy(points, 'y').y};
    var maxVertex = {x: _.maxBy(points, 'x').x, y: _.maxBy(points, 'y').y};

    var offsetFromCenter = 17;

    var minX = minVertex.x;
    var minY = minVertex.y;
    var maxX = maxVertex.x;
    var maxY = maxVertex.y;

    var roomWidth = Math.abs(maxX - minX);
    var roomHeight = Math.abs(maxY - minY);

    var centerPoint = {
      x: roomWidth / 2,
      y: roomHeight / 2
    }

    var extension = 5;

    var cardinalLines = [
      {from : {x: -extension, y: centerPoint.y - offsetFromCenter}, to: {x: roomWidth + extension, y: centerPoint.y - offsetFromCenter}},
      {from : {x: centerPoint.x + offsetFromCenter, y: -extension}, to: {x: centerPoint.x + offsetFromCenter, y: roomHeight + extension}},
      {from : {x: roomWidth + extension, y: centerPoint.y + offsetFromCenter}, to: {x: -extension, y: centerPoint.y + offsetFromCenter}},
      {from : {x: centerPoint.x - offsetFromCenter, y: roomHeight + extension}, to: {x: centerPoint.x - offsetFromCenter, y: -extension}}
    ];

    var offset = {x: minX, y: minY};

    var offsetCardinalLines = _.map(cardinalLines, ({from, to}) => {
      var updatedCoordinates = {
        from: lib.object.sum(from, offset),
        to: lib.object.sum(to, offset)
      }

      return updatedCoordinates;
    })

    _.forEach(offsetCardinalLines, (line, i) => {
      this.props.handleAddElevation({...line, otherProps: {rank: i}, normalizePosition: false});
    })

    this.handleChange({props: {closed: true, position, points}});
  };

  handleDelete = () => {
    let {room} = this.props;

    if (!_.find(Room.get('rooms', {room}), r1 => r1.id !== room.id && r1.floorId === room.floorId)) {
      alert('You must keep at least one room.');

      return;
    }

    if (_.lowerCase(prompt(`Please enter "ok" to confirm deletion of ${room.title || 'Untitled Room'}. This can't be undone.`)) === 'ok') {
      Room.destroy({room, reduxActions: this.props});
    }
  };

  handleDragEnd = (position) => {//eslint-ignore-error
    this.handleChange({props: {position: this.props.projectData.infinitePrecision ? position : _.mapValues(position, point => lib.round(point, {toNearest: K.minPrecision}))}});
  };

  handleDeletePoint = (points) => {//eslint-ignore-error
    this.handlePointsChange(points);
  };

  handlePointDragMove = (points) => {
    this.handlePointsChange(points, false);
  };

  handlePointDragEnd = (points) => {//eslint-ignore-error
    this.handlePointsChange(points);
  };

  handleSelect = () => {//eslint-ignore-error
    var {room, selectionData} = this.props;
    var {activeEntities, setActiveEntities} = selectionData;

    if (_.get(activeEntities, '0.id') !== room.id) {
      setActiveEntities({entities: [{id: room.id, resourceKey: 'room'}], isMultiSelect: false});
    }
  };

  handleElevationLineClick = (id) => {
    var {selectionData} = this.props;
    var {activeEntities, setActiveEntities} = selectionData;

    if (_.get(activeEntities, '0.id') !== id) {
      setActiveEntities({entities: [{resourceKey: 'elevation', id}]});
    }
  };

  handleDatumChange = ({datum, updatedProps}) => {
    var clone = _.cloneDeep(this.props.room.xzDatums);
    var indexOf = datum ? _.findIndex(clone, ({from, to}) => datum.from.x === from.x && datum.from.y === from.y && datum.to.x === to.x && datum.to.y === to.y) : -1;

    if (datum && indexOf > -1) {
      clone[indexOf] = {...datum, ...updatedProps};
    }
    else {
      clone.push({...datum, ...updatedProps});
    }

    this.props.projectData.pushToUndoQueue({type: 'room', eventKey: 'transformEnd', instance: this.props.room});
    this.props.updateRoom({id: this.props.room.id, props: {xzDatums: clone}});
  };

  handleDatumSelect = (index) => {
    this.props.selectionData.setActiveDatumData({selectedDatumIndex: index, roomId: this.props.room.id});
  };

  handleElevationDisabledChange = (id, isDisabled) => {
    this.props.updateElevation({id, props: {isDisabled}});
  };

  handleElevationTransformEnd = (elevation) => (props) => {
    const {from, to} = props;
    const {projectData} = this.props;
    let cachedElevation = _.cloneDeep(elevation);

    if (!_.isEmpty(props.from) && !_.isEmpty(props.to)) {
      props.lineInRoom = {from: _.mapValues(from, value => lib.round(value, {toNearest: 1 / 16})), to: _.mapValues(to, value => lib.round(value, {toNearest: 1 / 16}))};

      delete props.from;
      delete props.to;
    }

    Elevation.update({elevation, cachedElevation, pushToUndoQueue: projectData.pushToUndoQueue, props, reduxActions: this.props});
  };

  handleDeleteElevation = (elevation) => {
    if (this.props.selectionData.getIsActiveEntity({resourceKey: 'elevation', id: elevation.id})) {
      this.props.selectionData.setActiveEntities({entities: []});

      Elevation.destroy({elevation, reduxActions: this.props, pushToUndoQueue: this.props.projectData.pushToUndoQueue});
    }
  };

  handleDeleteXZDatum = (datum) => {
    var {from, to} = datum;
    var clonedXZDatums = _.cloneDeep(this.props.room.xzDatums);

    _.remove(clonedXZDatums, (current) => current.from.x === from.x && current.from.y === from.y && current.to.x === to.x && current.to.y === to.y);

    this.props.projectData.pushToUndoQueue({type: 'room', eventKey: 'transformEnd', instance: this.props.room});
    this.props.updateRoom({id: this.props.room.id, props: {xzDatums: clonedXZDatums}});
    this.props.selectionData.setActiveDatumData(null);
  };

  render() {
    var {room, viewOffset, visibilityLayers, selectionData, renderForContextCanvas, canvasData, containers, volumes, archElements, sortedElevations: elevations, projectGraphics, activeDetailLevel, activeFillMode, projectData, renderForDrawings, preventEditing, isExportingSvg} = this.props;
    var {activeEntities} = selectionData;
    var {countertopsAreSelectable, showingAlignmentIndicators} = projectData;
    var position = room.plan.position;
    var isSelected = (_.size(selectionData) && selectionData.getIsActiveEntity) ? selectionData.getIsActiveEntity({resourceKey: 'room', id: room.id}) : false;
    var strokeWidth = K.drawingsStrokeWidths.heavy;
    var origin = lib.object.sum(position, viewOffset);

    var {
      reveals: showRevealSymbols,
      projectGraphics: showProjectGraphics,
      wallsAndArchElements: showWallsAndArchElements,
      dimensions: showDimensions,
      bindingDimensions: showBindingDimensions,
      datums: showDatums,
      elevationLines: showElevationLines,
      scopes: showScopes,
      unitLabels: showUnitLabels,
      ornamentTopIndicators: showOrnamentTopIndicators,
    } = visibilityLayers;

    var isDisabled = canvasData.isStatic || preventEditing;
    var alignmentIndicatorLines;

    if (!renderForContextCanvas && showingAlignmentIndicators) {
      alignmentIndicatorLines = Room.getAlignmentIndicatorLines({room});

      alignmentIndicatorLines = _.map(alignmentIndicatorLines, alignmentIndicatorLine => {
        return {
          ...alignmentIndicatorLine,
          offset: lib.object.sum(alignmentIndicatorLine.offset, room.plan.position, viewOffset)
        };
      });
    }

    if (this.shouldUpdateDimensionSets) {
      global.dimensionsByEntityType.room[room.id] = getRoomDimensionSets({room, projectData, canvasData, showWallsAndArchElements, cachedDimensionSets: global.dimensionsByEntityType.room[room.id], considerSummarizationChange: !renderForDrawings && !renderForContextCanvas, reduxActions: this.props});
    }

    var filteredProjectGraphics = [];

    //TODO
    // if (renderForDrawings && renderingBindingDimensions) {
    //   filteredProjectGraphics = _.filter(filteredProjectGraphics, projectGraphic => projectGraphic.isBindingDimension === 1);
    // }

    if (!renderForContextCanvas && showDimensions) {
      var activeContainer = _.size(activeEntities) === 1 && selectionData.getIncludesActiveEntity({resourceKey: 'container'}) && _.find(containers, {id: _.get(activeEntities, '0.id')});

      //TODO
      // roomDimensionLines = RoomDimensions.getAllDimensionLines({room,
      //   showWallsAndArchElements,
      //   activeDetailLevel, activeFillMode,
      //   //HINT used to hide countertop dims while transforming
      //   isTransforming: _.some(containers, container => container.eventType === 'transform') || Room.waitingForUpdateManagedResources,
      //   activeCountertopId: (activeContainer && activeContainer.type === 'countertop') ? activeContainer.id : undefined,
      //   ..._.pick(projectData, ['dimensionsData', 'activeDimensionsLayer', 'tentativeCustomDimension'])
      // });
    }

    if (!renderForContextCanvas) {
      filteredProjectGraphics = _.filter(projectGraphics, projectGraphic => {
        var detailKey = `hideOn${_.upperFirst(activeDetailLevel)}`;
        var isHidden = false;

        if (projectGraphic.data[detailKey] === 1) {
          isHidden = true;
        }

        if (_.get(projectGraphic, 'data.isGrainflowIndicator') && !visibilityLayers.grainFlow) isHidden = true;

        if (projectGraphic.type === 'background') isHidden = !visibilityLayers.backgroundsVisible;

        return !isHidden;
      });
    }

    return (<>
      {!renderForContextCanvas && _.map(_.filter(filteredProjectGraphics, {type: 'background'}), projectGraphic => (
        <CanvasProjectGraphic
          preventEditing={preventEditing || !visibilityLayers.backgroundsSelectable}
          {...{id: projectGraphic.id, ...(projectGraphic.floorId ? {} : {room}), viewOffset: {x: 0, y: 0}, selectionData, renderForDrawings, isExportingSvg}}
          key={projectGraphic.id}
          viewKey={'top'}
        />
      ))}
      {showScopes && !renderForDrawings && _.map(_.filter(this.props.scopes, scope => !_.isEmpty(scope.plan) && !_.isEmpty(scope.plan.points)), (scope) => {
        return (
          <CanvasScope
            key={scope.id}
            {...{scope, viewOffset, room, isDisabled}}
          />
        );
      })}
      {showWallsAndArchElements && !_.get(room, 'customData.volumesOnly') && (
        <EditableCanvasPolyline
          {...{...room.plan, isSelected, renderForDrawings}}
          strokeWidth={renderForDrawings ? (strokeWidth + 0.75) : (strokeWidth)}
          shouldScaleStrokeWidth={!renderForDrawings}
          offset={viewOffset}
          permitPseudoPoints={true}
          isDraggable={this.props.isDraggable}
          isEnabled={!isDisabled} //TODO set using state
          onPointsChange={this.handlePointsChange}
          onAddPoint={this.handleAddPoint}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          onDelete={this.handleDelete}
          onDragEnd={this.handleDragEnd}
          onDeletePoint={this.handleDeletePoint}
          onPointDragMove={this.handlePointDragMove}
          onPointDragEnd={this.handlePointDragEnd}
          onSelect={this.handleSelect}
        />
      )}
      {!visibilityLayers.architectureOnly && _.map(_.orderBy(_.values(_.filter(containers, container => !_.includes(['pivotDoor', 'door', 'window'], container.type))), container => Container.getZIndex({container, viewKey: 'top', showOrnamentTopIndicators, countertopsAreSelectable})), container => (
        <CanvasContainer
          viewKey='top'
          key={container.id}
          id={container.id}
          {...{room, viewOffset, renderForContextCanvas, showRevealSymbols, showUnitLabels, activeDetailLevel, activeFillMode: 'grayscale', renderForDrawings, preventEditing, showWallsAndArchElements, showOrnamentTopIndicators}}
        />
      ))}
      {_.map(_.orderBy(_.values(volumes), volume => Volume.getZIndex({volume, viewKey: 'top'})), volume => (
        <CanvasVolume
          viewKey='top'
          key={volume.id}
          id={volume.id}
          {...{room, viewOffset, renderForContextCanvas, showRevealSymbols, activeDetailLevel, activeFillMode: 'grayscale', renderForDrawings, preventEditing}} //TODO: when volume is schematic check activeFillMode
        />
      ))}
      {!visibilityLayers.architectureOnly && _.map(_.orderBy(_.values(_.filter(containers, container => _.includes(['pivotDoor', 'door', 'window'], container.type))), container => Container.getZIndex({container, viewKey: 'top'})), container => (
        <CanvasContainer
          viewKey='top'
          key={container.id}
          id={container.id}
          {...{room, viewOffset, renderForContextCanvas, showRevealSymbols, showUnitLabels, activeDetailLevel, activeFillMode: 'grayscale', renderForDrawings, preventEditing, showWallsAndArchElements, showOrnamentTopIndicators}}
        />
      ))}
      {showWallsAndArchElements && !renderForContextCanvas && _.map(_.filter(archElements, archElement => _.includes(['floor', 'hybrid'], ArchElement.get('typeData', {archElement}).classification)), archElement => (
        <CanvasArchElement
          key={archElement.id}
          viewKey={'top'}
          {...{id: archElement.id, viewOffset, activeDetailLevel, activeFillMode: 'grayscale', renderForDrawings, preventEditing}}
        />
      ))}
      {!renderForContextCanvas && _.map(_.reject(filteredProjectGraphics, {type: 'background'}), projectGraphic => (
        <CanvasProjectGraphic
          {...{id: projectGraphic.id, room, viewOffset, selectionData, renderForDrawings, preventEditing, isExportingSvg}}
          key={projectGraphic.id}
          viewKey={'top'}
        />
      ))}
      {!renderForContextCanvas && showingAlignmentIndicators && _.map(alignmentIndicatorLines, (alignmentIndicatorLine, index) => (
        <EditableCanvasLine key={index} {...alignmentIndicatorLine}/>
      ))}
      {!renderForContextCanvas && showDimensions && !visibilityLayers.isExportingDxf && (
        _.map(global.dimensionsByEntityType.room[room.id], dimensionSet => (
          <CanvasDimensionLine key={dimensionSet.id} {...{room, viewOffset, dimensionSet, parentOffset: room.plan.position, viewKey: 'top', showBindingDimensions, renderForDrawings, isExportingSvg}}/>
        ))
      )}
      {showDatums && _.map(room.xzDatums, (datum, index) => {
        let activeDatumData = _.get(this.props, 'selectionData.activeDatumData') || {};
        return (
          <CanvasDatum
            key={index}
            {...{datum, room, viewOffset, preventEditing}}
            type={'xz'}
            viewKey={'top'}
            handleDatumChange={this.handleDatumChange}
            onClick={() => this.handleDatumSelect(index)}
            onDelete={() => this.handleDeleteXZDatum(datum)}
            isSelected={activeDatumData.roomId === room.id && activeDatumData.selectedDatumIndex === index}
          />
        );
      })}
      {showElevationLines && !visibilityLayers.isExportingDxf && _.map(_.uniqBy(elevations, 'id'), (elevation, i) => {
        const {lineInRoom, isDisabled, viewDepth} = elevation;
        const isSelected = selectionData?.getIsActiveEntity && selectionData.getIsActiveEntity({resourceKey: 'elevation', id: elevation.id});

        return (
          <React.Fragment key={elevation.id}>
            <CanvasElevationIndicator
              {...{room, elevation, offset: origin, renderForContextCanvas, hideElevationLine: true}}
              onClick={() => this.handleElevationLineClick(elevation.id)}
              onDblClick={() => this.props.setActiveViewEntityId('elevation', elevation.id)}
            />
            <EditableCanvasLine
              {...{...lineInRoom, viewDepth, isSelected, opacity: isDisabled ? 0.3 : 1, stroke: K.colors.elevationStroke}}
              isDisabled={canvasData.isStatic || preventEditing}
              isEditable
              offset={origin} //HINT offset by room position
              onClick={() => this.handleElevationLineClick(elevation.id)}
              onDelete={() => this.handleDeleteElevation(elevation)}
              onDisabledChange={(disabled) => this.handleElevationDisabledChange(elevation.id, disabled)}
              onTransformEnd={this.handleElevationTransformEnd(elevation)}
            />
          </React.Fragment>
        );
      })}
      {!visibilityLayers.isExportingDxf && _.map(visibilityLayers.elevationIndicators, ({entity: elevation, showViewDepth=false}) => {
        return elevation ? (
          <CanvasElevationIndicator key={elevation.id} {...{room, elevation, showViewDepth, offset: origin, renderForContextCanvas, hideElevationLine: renderForDrawings}}/>
        ) : (false);
      })}
    </>);
  }
}

function CanvasRoomWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  var selectionData = useContext(CanvasSelectionContext);
  var projectData = useContext(ProjectDataContext);

  if (props.renderForContextCanvas) {
    projectData = _.omit(projectData, ['dimensionsData']);
    selectionData = {};
  }
  else if (props.renderForDrawings) {
    selectionData = {};
  }

  return <CanvasRoom {...props} {...{canvasData: _.pick(canvasData, ['isStatic', 'layer', 'scale', 'infinitePrecision']), selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {room} = ownProps;
    var props = {};

    if (room && room.id) {
      //HINT products aren't used, but are included to trigger re-render when products change
      props = {
        ...Room.get(['containers', 'volumes', 'archElements', 'sortedElevations', 'projectGraphics', 'scope', 'scopes', 'products'], {room, state})
      };

      props.containers = _.filter(props.containers, container => container.position && !_.isEqual(container.position, {}));
    }

    return props;
  },
  mapDispatch: {
    ...resourceActions.elevations,
    ..._.pick(resourceActions.projects, ['updateProject']),
    ..._.pick(resourceActions.scopes, ['updateScope', 'updateScopes', 'destroyScopes']),
    ..._.pick(resourceActions.rooms, ['destroyRoom', 'updateRoom']),
    ..._.pick(resourceActions.walls, ['destroyWall', 'destroyWalls', 'updateWall', 'updateWalls', 'createWall', 'createWalls', 'modifyWalls']),
    ..._.pick(resourceActions.containers, ['destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.products, ['destroyProducts', 'updateProduct', 'updateProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.archElements, ['destroyArchElements']),
    ..._.pick(resourceActions.projectGraphics, ['destroyProjectGraphics']),
    ..._.pick(resourceActions.volumes, ['destroyVolumes']),
    ..._.pick(resourceActions.parts, ['createParts', 'destroyParts', 'modifyParts','updateParts']),
    ..._.pick(issuesDataActions, ['setIssuesData']),
  }
})(CanvasRoomWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasRoom'})
});
