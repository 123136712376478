import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import DetailsHelper from 'helpers/details-helper';
import Room from 'project-helpers/room';
import Floor from 'project-helpers/floor';
import Project from 'project-helpers/project';
import updateProductionIds from 'helpers/update-production-ids-helper';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import { handleContainerPropertyChange } from './container-properties-view-helpers';
import getMaterialOptionsForArchetypeParameter from 'helpers/get-material-options-for-archetype-parameter';
import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';

function getRoomFieldGroups({room}) {
  var {project, floor, dependencies} = Room.get(['project', 'floor', 'dependencies'], {room});
  var {companyKey} = project;

  var fieldSetGroups = [
    {title: 'General', properties: []},
    {title: 'Materials', properties: []},
    {title: 'Pulls', properties: []},
    {title: 'Archetypes', properties: []}
  ];

  var generalGroupIndex = _.findIndex(fieldSetGroups, {title: 'General'});
  var materialGroupIndex = _.findIndex(fieldSetGroups, {title: 'Materials'});
  var pullGroupIndex = _.findIndex(fieldSetGroups, {title: 'Pulls'});
  var archetypeGroupIndex = _.findIndex(fieldSetGroups, {title: 'Archetypes'});

  var isArchetype = _.some(dependencies.archetypes.byId, archetype => archetype.roomId === room.id);

  if (room.archetypeId) {
    var archetypeParameterGroups = [];

    var roomParameters = _.flatMap(_.get(room, `parameters.${room.archetypeId}`, []));
    var {containers, products} = Room.get(['containers', 'products'], {room});
    var {pulls, materialClasses} = dependencies;

    _.forEach(_.sortBy(roomParameters, ({key}) => _.indexOf([
      'material', 'pull', 'conditionalResources'
    ], key)), (parameter, index) => {
      var {id, title, type, options = [], instances} = parameter;
      // var inputType = 'select'; //TODO handle other input types
      var noThumbnail = true;
      var hasOptionGroups = false;

      //HINT don't render if this is a pull or material parameter applying to nothing in the room
      if (type === 'pull' || type === 'material') {
        var containerInstancesPresent = _.some(instances, instance => instance.resourceKey === 'container' && _.find(containers, {persistentId: instance.resourcePersistentId}));
        var productInstancesPresent = _.some(instances, instance => instance.resourceKey === 'product' && _.find(products, {persistentId: instance.resourcePersistentId}));

        if (!containerInstancesPresent && !productInstancesPresent) {
          return null;
        }
      }

      if (type === 'pull') {
        var pullTypeOptions = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullType'});

        if (pullTypeOptions.length > 0) {
          _.map(['pullType', 'pullMaterial'], (path, index) => {
            if (path === 'pullType') {
              if (_.get(room, `selectedParameters.${room.archetypeId}.${id}.${path}`) === undefined) {
                pullTypeOptions.unshift({value: undefined, title: 'select'});
              }

              //TODO default pullType?
              fieldSetGroups[archetypeGroupIndex].properties.push({
                id,
                title: `${title !== 'Pulls' ? `${title} ` : ''} Type`,
                type: 'select', options: pullTypeOptions,
                noThumbnail, hasOptionGroups, index,
                path: `selectedParameters.${room.archetypeId}.${id}.${path}`,
                parameter, ...(pullTypeOptions[0].value === undefined ? {inputStyle: {color: '#626FD0'}} : {}),
              })
            }
            else if (path === 'pullMaterial') {
              //HINT get compatible materials based on selected pull type
              var selectedPullTypeId = _.get(room, `selectedParameters.${room.archetypeId}.${id}.pullType`);

              if (selectedPullTypeId) {
                var selectedPull = _.get(pulls, `byId[${selectedPullTypeId}]`);
                var compatibleMaterials = _.get(materialClasses, `${selectedPull.materialClassId}.materials`);

                if (parameter.compatibleMaterialIds) { //HINT filter compatible materials to those set manually on the parameter
                  compatibleMaterials = _.filter(compatibleMaterials, ({id}) => {
                    return _.includes(parameter.compatibleMaterialIds, id);
                  });
                }

                //HINT only show property field if there are compatible materials
                if (compatibleMaterials.length > 1) {
                  options = _.map(compatibleMaterials, ({id, title, materialTypeId}) => {
                    return {
                      value: id, title,
                      thumbnail: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/${id}.jpg`,
                      materialTypeId
                    };
                  });

                  if (_.get(room, `selectedParameters.${room.archetypeId}.${id}.${path}`) === undefined) {
                    options.unshift({value: undefined, title: 'select'});
                  }

                  fieldSetGroups[archetypeGroupIndex].properties.push({
                    id, path: `selectedParameters.${room.archetypeId}.${id}${path}`,
                    title: `${parameter.title !== 'Pulls' ? `${parameter.title} ` : ''} Material`,
                    type: select, options, hasOptionGroups, index, noThumbnail: false,
                    parameter, ...(options[0].value === undefined ? {inputStyle: {color: '#626FD0'}} : {}),
                  })
                }
              }
            }
          })
        }
      }
      else {
        if (type === 'material') {
          hasOptionGroups = true;
          noThumbnail = false;

          options = getMaterialOptionsForArchetypeParameter({parameter, room});
        }
        else if (type === 'conditionalResources') {

        }

        if (options.length > 0) {
          if (_.get(room, `selectedParameters.${room.archetypeId}.${id}`) === undefined) {
            options = [{value: undefined, title: 'select'}, ...options];
          }

          fieldSetGroups[archetypeGroupIndex].properties.push({
            id, title, type: 'select', options,
            views: ['top', 'front'],
            path: `selectedParameters.${room.archetypeId}.${id}`,
            parameter,
            ...(options[0].value === undefined ? {inputStyle: {color: '#626FD0'}} : {}),
            // noThumbnail, hasOptionGroups,
            // index
            // path
          })
        }
      }
    })
  }

  fieldSetGroups[archetypeGroupIndex].properties.push({
    path: 'isArchetype',
    type: 'checkbox',
    defaultTrue: isArchetype,
    title: isArchetype ? 'Remove from Configuration Library' : 'Add to Configuration Library',
    views: ['top', 'front']
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    path: 'ceilingHeight',
    type: 'number',
    title: 'Ceiling Height',
    views: ['top', 'front'],
    userLenses: ['design', 'sales', 'engineering']
  });


  fieldSetGroups[generalGroupIndex].properties.push({
    path: 'datums',
    type: 'text',
    title: 'Datum Lines',
    placeholder: 'i.e. 35, 60',
    views: ['front']
  });

  let sortedRooms = _.sortBy(Floor.get('rooms', {floor}), 'rank');

  if (companyKey === 'hb') {
    fieldSetGroups[generalGroupIndex].properties.push({
      path: 'customData.pullAlignment',
      type: 'size',
      title: 'Pull Alignment (distance from floor to center of pull, only for full height tall units)',
      views: ['top', 'front']
    });
  }

  fieldSetGroups[generalGroupIndex].properties.push({
    path: 'productionId',
    type: 'text',
    title: `Custom Unit Prefix (CANNOT INCLUDE NUMBERS or '.')`,
    placeholder: lib.string.numberToLetters(_.findIndex(sortedRooms, {id: room.id}) + 1),
    views: ['top']
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    key: 'customData.preventAutoGeneration',
    title: 'Prevent Auto Generation',
    type: 'checkbox',
    views: ['front', 'top', 'bottom', 'left', 'right'],
    userLenses: ['design', 'sales', 'engineering']
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    key: 'customData.roomResetParts',
    title: 'Recalculate Room Parts',
    type: 'checkbox',
    views: ['top'],
    userLenses: ['engineering'],
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    key: 'customData.draftResetParts',
    title: 'Recalculate Draft Parts',
    type: 'checkbox',
    views: ['top'],
    userLenses: ['engineering'],
  });

  // fieldSetGroups[generalGroupIndex].properties.push({
  //   key: 'customData.volumesOnly',
  //   title: 'No Walls',
  //   type: 'checkbox',
  //   views: ['front', 'top', 'bottom', 'left', 'right'],
  //   userLenses: ['design', 'sales', 'engineering']
  // });

  if (companyKey === 'vp') {
    fieldSetGroups[generalGroupIndex].properties.push({
      path: 'customData.grainFlowEnabled',
      title: 'Show Grainflow',
      type: 'checkbox',
      views: ['top', 'front']
    });
  }

  _.forEach(DetailsHelper.getCompatibleDetailsFor({room}), detail => {
    if (detail.type === 'material') {
      var relevantGroupIndex = _.includes(detail.key, 'pullMaterial') ? pullGroupIndex : materialGroupIndex;

      fieldSetGroups[relevantGroupIndex].properties[_.includes(['frontMaterial', 'boxMaterial', 'panelMaterial'], detail.key) ? 'unshift' : 'push']({
        path: `details.${detail.key}.id`,
        noThumbnail: false,
        type: 'radio',
        hasOptionGroups: true,
        optionGroups: detail.optionGroups,
        title: detail.title,
        views: ['top', 'front'],
        userLenses: ['design', 'sales', 'engineering']
      });
    }
    else {
      var relevantGroupIndex = (_.includes(detail.key, 'grain') || _.includes(detail.key, 'frontPanelType')) ? materialGroupIndex : pullGroupIndex;

      fieldSetGroups[relevantGroupIndex].properties[_.includes(detail.key, 'pullType') ? 'unshift' : 'push']({
        path: `details.${detail.key}.id`,
        noThumbnail: true,
        type: 'radio',
        options: detail.options,
        title: detail.title,
        views: ['top', 'front'],
        userLenses: ['design', 'sales', 'engineering']
      });
    }
  });

  return fieldSetGroups;
}

async function handleRoomPropertyChange({activeEntityId, activeEntity, path, reduxActions, value, parameter}) {
  if (_.includes(path, 'selectedParameters') && parameter) {
    var cleanedPath = _.replace(path, `selectedParameters.${_.get(activeEntity, 'archetypeId')}.${parameter.id}`, '');

    if (cleanedPath[0] === '.') cleanedPath = _.replace(cleanedPath, '.', '');

    applyArchetypeParameterSelection({
      room: activeEntity,
      archetypeId: _.get(activeEntity, 'archetypeId'),
      parameter,
      value,
      //HINT apply archetype parameter selection is expecting a path without the selectedParameters and archetype id prefix
      path: cleanedPath,
      reduxActions
    })
  }
  else if (path === 'isArchetype') {
    var {dependencies, floor, project} = Room.get(['dependencies', 'floor', 'project'], {room: activeEntity});

    if (_.find(dependencies.archetypes.byId, {roomId: activeEntityId})) {
      //delete archetype
    }
    else {
      //create a new archetype with room, floor, project, project version , and company key
      //roomId, projectId, versionId, companyKey, deleted
      var {projectId, versionId} = activeEntity;
      var {companyKey} = project;

      reduxActions.createArchetype({props: {roomId: activeEntityId, projectId, versionId, companyKey, deleted: 0, parameters: []}});

      reduxActions.toggleParameterEditor(true);
    }
  }
  else if (path === 'customData.draftResetParts' || path === 'customData.roomResetParts') {
    var {project} = Room.get(['project'], {room: activeEntity});

    if (project.lockedForProduction) {
      alert ('this project is locked for production, its parts cannot be reset.');
    }
    else {
      var shouldReset = await confirm(`Are you sure you want to reset the parts on this ${path === 'customData.draftResetParts' ? 'draft' : 'room'}? Only engineering should do this.`);

      if (shouldReset) {
        await lib.api.request({uri: 'de-project/reset-parts', body: {projectId: project.id, versionId: project.versionId, resourceId: path === 'customData.draftResetParts' ? project.versionId : activeEntity.id, resourceKey: path === 'customData.draftResetParts' ? 'projectVersion' : 'room'}});

        alert('parts reset! refresh the project manager to see updates.');
      }
    }
  }
  else {
    if (_.split(path, '.').length > 1) {
      var topLevelKey = _.split(path, '.')[0];

      _.set(activeEntity, path, value);

      var props = {[topLevelKey]: activeEntity[topLevelKey]};

      if (_.includes(path, 'details')) {
        props.details = _.mapValues(props.details, detail => {
          return _.pick(detail, ['id', 'settings']);
        });

        var updatesMap = {
          rooms: {creations: [], deletedIds: [], updates: [{where: {id: activeEntityId}, props}]},
          containers: {creations: [], deletedIds: [], updates: []},
          products: {creations: [], deletedIds: [], updates: []},
          productOptions: {creations: [], deletedIds: [], updates: []}
        };

        var children = DetailsHelper.getDetailsChildrenFor({room: activeEntity});

        if (children) _.forEach(children, child => {
          UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleContainerPropertyChange({activeEntityId: child.id, activeEntity: child, path, reduxActions, value, isBatched: true}));
        });

        UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, reduxActions});

        Room.updateManagedResources({room: activeEntity, reduxActions});
      }
      else {
        reduxActions.updateRoom({id: activeEntityId, props});
      }
    }
    else {
      reduxActions.updateRoom({id: activeEntityId, props: {[path]: value}});
    }

    if (_.includes(path, 'productionId')) {
      setTimeout(() => updateProductionIds({project: Room.get('project', {room: activeEntity}), reduxActions}));
    }

    if (_.includes(path, 'ceilingHeight')) {
      var ceilingHeight = value;
      var wallUpdates = [];

      _.forEach(Room.get('walls', {room: activeEntity}), wall => {
        var {points} = wall.outline;
        var wasUpdated = false;

        points = _.map(points, point => {
          if (point.y < -30) {
            point = {...point, y: -ceilingHeight};

            wasUpdated = true;
          }

          return point;
        });

        if (wasUpdated) {
          wallUpdates.push({where: {id: wall.id}, props: {
            outline: {
              ..._.get(wall, 'outline', {}),
              height: ceilingHeight,
              points
            }
          }});
        }
      });

      if (wallUpdates.length > 0) {
        reduxActions.updateWalls({updates: wallUpdates});
      }
    }
  }
}

export {getRoomFieldGroups, handleRoomPropertyChange};
